import { Box, BoxProps, Divider, makeStyles, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import clsx from "clsx";
import React, { forwardRef } from "react";

const useStyles = makeStyles(theme => ({
  disabled: {
    opacity: 0.3,
    pointerEvents: "none",
  },
  section: {
    "&:first-child": {
      marginTop: 0,
    },
    "maxWidth": "100%",
    "padding": theme.spacing(1, 2),
    "transition": theme.transitions.create(["all"]),
    [theme.breakpoints.only("xs")]: {
      backgroundColor: theme.palette.background.default,
      padding: theme.spacing(1, 0),
    },
  },
  sectionDivider: {
    color: theme.palette.background.paper,
    marginTop: theme.spacing(3),
  },
  sectionHeader: {
    height: 24,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.only("xs")]: {
      marginTop: theme.spacing(1),
    },
  },
  sectionHeaderText: {
    fontWeight: "bold",
  },
}));

type InfoSectionProps = {
  className?: string;
  children?: React.ReactNode;
  disabled?: boolean;
  headerActions?: React.ReactNode;
  headerActionsProps?: BoxProps;
  loading?: boolean;
  title?: React.ReactNode;
};

export const InfoSection = forwardRef<HTMLDivElement, InfoSectionProps>(
  ({ className, children, disabled = false, headerActions, headerActionsProps, loading, title }, ref) => {
    const classes = useStyles();
    let body;

    if (loading) {
      body = <Skeleton height={50} variant="rect" />;
    } else {
      body = children;
    }

    const actions = <Box {...headerActionsProps}>{headerActions}</Box>;

    return (
      <div className={clsx(classes.section, { [classes.disabled]: disabled }, className)} ref={ref}>
        <Box alignItems="center" className={classes.sectionHeader} display="flex" justifyContent="space-between">
          <Typography className={classes.sectionHeaderText} suppressHydrationWarning variant="body2">
            {title}
          </Typography>
          {!loading ? actions : <Skeleton>{actions}</Skeleton>}
        </Box>
        {body}
        <Divider className={classes.sectionDivider} />
      </div>
    );
  },
);

export default InfoSection;
