import { OnWheelBlur } from "@arowana/ui";
import { Box, Button, InputAdornment, makeStyles, TextField } from "@material-ui/core";
import { useEffect, useState } from "react";

import DropdownSelect from "./DropdownSelect";

const useStyles = makeStyles(theme => ({
  textInput: { margin: 0 },
  textInputSection: {
    alignItems: "center",
    display: "flex",
    gap: theme.spacing(1),
  },
}));

interface NetTermProps {
  fullWidth?: boolean;
  loading: boolean;
  onChange: (netTerm: number) => void;
  value: number;
}

const NET_TERM_OPTIONS = [
  { label: "Cash on Delivery / Cash and Carry", value: 0 },
  { label: "Net 7", value: 7 },
  { label: "Net 14", value: 14 },
  { label: "Net 21", value: 21 },
  { label: "Net 30", value: 30 },
  { label: "Other", value: -1 },
];
const NET_TERM_SET = new Set([0, 7, 14, 21, 30]);

const NetTerms = ({ fullWidth = false, loading, onChange, value }: NetTermProps) => {
  const classes = useStyles();
  const [netTerm, setNetTerm] = useState<number>(value);
  useEffect(() => setNetTerm(value), [value]);
  const [showDropdown, setShowDropdown] = useState<boolean>(true);

  let options = NET_TERM_OPTIONS;

  if (!NET_TERM_SET.has(value)) {
    options = [
      ...NET_TERM_OPTIONS.slice(0, NET_TERM_OPTIONS.length - 1),
      { label: `Net ${value}`, value },
      { label: "Other", value: -1 },
    ];
  }

  const handleChangeNetTerm = (netTerm: number) => {
    if (netTerm === -1) {
      setShowDropdown(false);
    } else {
      setShowDropdown(true);
      setNetTerm(netTerm);
      onChange(netTerm);
    }
  };

  const handleCancel = () => {
    setNetTerm(value);
    setShowDropdown(true);
  };

  const handleChangeCustomNetTerm = (e: React.ChangeEvent<HTMLInputElement>) => {
    let netTerm = parseInt(e.target.value);

    if (netTerm > 365) netTerm = 365;

    if (netTerm < 0) netTerm = 0;

    setNetTerm(netTerm);
  };

  const handlePressEnterKey = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      handleChangeNetTerm(netTerm);
    }
  };

  if (!showDropdown) {
    return (
      <Box className={classes.textInputSection}>
        <TextField
          className={classes.textInput}
          fullWidth={fullWidth}
          InputProps={{
            inputProps: { max: 30, min: 0 },
            onWheel: OnWheelBlur,
            startAdornment: <InputAdornment position="start">Net</InputAdornment>,
          }}
          margin="dense"
          onChange={handleChangeCustomNetTerm}
          onKeyUp={handlePressEnterKey}
          type="number"
          value={netTerm}
          variant="outlined"
        />
        <Button color="primary" onClick={handleCancel} variant="outlined">
          Cancel
        </Button>
        <Button color="primary" onClick={() => handleChangeNetTerm(netTerm)} variant="contained">
          Save
        </Button>
      </Box>
    );
  }

  return (
    <DropdownSelect
      disabled={loading}
      dropdownMargin="dense"
      fullWidth={fullWidth}
      onChange={e => handleChangeNetTerm(e.target.value)}
      options={options}
      value={`${value}`}
    />
  );
};

export default NetTerms;
