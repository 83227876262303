import { gql, useMutation, useQuery } from "@apollo/client";
import { FEATURE_SUPPLIER_SETTINGS_NAVBAR_VIBRANT } from "@arowana/flags";
import { DATALAYER } from "@arowana/util";
import { Button, Divider, Grid, makeStyles, Typography, useTheme } from "@material-ui/core";
import { useContext, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";

import { notificationVar } from "../../../cache/notificationPolicy";
import DropdownSelect from "../../../components/DropdownSelect";
import FormCard from "../../../components/FormCard";
import FormTooltip from "../../../components/FormTooltip";
import Loader from "../../../components/Loader";
import PageHeader from "../../../components/PageHeader";
import supplierUpdateSettings from "../../../queries/supplierUpdateSettings";
import { FlagsmithContext } from "../../context/FlagsmithContext";
import Banner from "../components/Banner";
import Carousel from "../components/Carousel";
import ColorPicker from "../components/ColorPicker";
import DynamicLinks from "../components/DynamicLinks";
import FaqSection from "../components/FaqSection";
import RadioButtons from "../components/RadioButtons";
import SwitchToggle from "../components/SwitchToggle";

const UI_SETTINGS = gql`
  query UISettings {
    currentSupplier {
      id
      uiSettings {
        banner {
          isEnabled
          message
          title
        }
        carousel {
          active
          style
          slides {
            image {
              original
              x256
              x512
              x896
              x1664
            }
            header
            description
            link {
              id
              title #name
              url #value
            }
          }
        }
        colors {
          primary
        }
        darkModeEnabled
        navbarStyle
        navLinks {
          id
          title #name
          url #value
        }
        faq {
          question
          answer
        }
        footerLinks {
          id
          title #name
          url #value
        }
        fontFamily
      }
    }
  }
`;

const FONTS = [
  {
    fallback: "sans-serif",
    label: "Inter",
  },
  {
    fallback: "sans-serif",
    label: "Lato",
  },
  {
    fallback: "serif",
    label: "Lora",
  },
  {
    fallback: "serif",
    label: "Merriweather",
  },
  {
    fallback: "sans-serif",
    label: "Open Sans",
  },
];

const FONT_OPTIONS = FONTS.map(({ label, fallback }) => ({
  label,
  style: { fontFamily: `'${label}', ${fallback}` },
  value: label,
}));

const FONT_LINK = `https://fonts.googleapis.com/css2?${FONT_OPTIONS.map(
  ({ value }) => `family=${value.replace(" ", "+")}`,
).join("&")}&display=swap`;

const useStyles = makeStyles(theme => ({
  colorPicker: {
    height: 60,
    width: "40%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  darkModeToggler: {
    marginLeft: 0,
  },
  divider: {
    margin: theme.spacing(4, 0),
  },
  fontFamilyDropdown: {
    width: 150,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  footerLinksContainer: {
    marginTop: theme.spacing(4),
  },
  navbarImage: {
    maxWidth: 250,
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      width: "50%",
    },
  },
  navbarToggle: {
    marginBottom: theme.spacing(2),
  },
}));

const Design = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [isLoading, setLoading] = useState(true);
  const flagsmith = useContext(FlagsmithContext);
  const hasRetail = flagsmith.hasFeature("b2c");
  const hasVibrantOption = flagsmith.hasFeature(FEATURE_SUPPLIER_SETTINGS_NAVBAR_VIBRANT);

  const { control, errors, formState, handleSubmit, reset, watch } = useForm({
    defaultValues: {
      banner: {
        isEnabled: false,
        message: "",
        title: "",
      },
      carousel: {
        display: false,
        slides: [],
        style: "full",
      },
      darkModeEnabled: false,
      faq: [],
      fontFamily: "Inter",
      footerLinks: [],
      navLinks: [],
      navbarStyle: "default",
      primaryColor: theme.palette.primary.main,
    },
  });
  const { data, loading: isFetching } = useQuery(UI_SETTINGS, {
    context: { source: DATALAYER },
  });
  const supplier = data?.currentSupplier;
  const uiSettings = supplier?.uiSettings;

  const [supplierUpdate, { loading: isSaving }] = useMutation(supplierUpdateSettings, {
    onCompleted: () => {
      notificationVar({
        message: "Update successfully!",
        severity: "success",
      });
    },
    refetchQueries: ["supplierUISettings"],
  });

  useEffect(() => {
    if (!isFetching && uiSettings) {
      reset({
        banner: {
          isEnabled: Boolean(uiSettings.banner?.isEnabled),
          message: uiSettings.banner?.message || "",
          title: uiSettings.banner?.title || "",
        },
        carousel: {
          display: Boolean(uiSettings.carousel?.active),
          slides:
            uiSettings.carousel?.slides?.map(slide => ({
              description: slide.description,

              // only for backend
              header: slide.header,

              imageObject: {
                file: null,
                image: slide.image?.x1664,
              },

              link: {
                text: slide.link?.title ?? "",
                url: slide.link?.url ?? "",
              },
              // backend requires the originalImageInput though frontend does not need it
              originalImageInput: slide.image,
            })) ?? [],
          style: uiSettings.carousel?.style ?? "full",
        },
        darkModeEnabled: Boolean(uiSettings.darkModeEnabled),
        faq: uiSettings.faq?.map(qa => ({ answer: qa.answer, question: qa.question })) ?? [],
        fontFamily: uiSettings.fontFamily ?? "Inter",
        footerLinks: uiSettings.footerLinks?.map(link => ({ name: link.title, value: link.url })) ?? [],
        navLinks: uiSettings.navLinks?.map(link => ({ name: link.title, value: link.url })) ?? [],
        navbarStyle: uiSettings.navbarStyle,
        primaryColor: uiSettings.colors?.primary ?? theme.palette.primary.main,
      });

      // manually control loading after reset is done
      setTimeout(() => setLoading(false), 200);
    }
  }, [isFetching, reset, uiSettings, theme.palette.primary.main]);

  const handleDiscardClick = () => reset();
  const onSubmit = formData => {
    const input = {
      uiSettings: {
        banner: {
          isEnabled: formData.banner.isEnabled,
          message: formData.banner.message,
          title: formData.banner.title,
        },
        colors: {
          primary: formData.primaryColor,
        },
        darkModeEnabled: formData.darkModeEnabled,
        faq: formData.faq,
        fontFamily: formData.fontFamily,
        footerLinks: formData.footerLinks,
        navLinks: formData.navLinks,
        navbarStyle: formData.navbarStyle,
      },
    };
    const variables = {
      input,
      supplierId: supplier?.id,
    };

    if (formData.carousel) {
      input.uiSettings["carousel"] = {
        active: formData.carousel?.display,
        slides: formData.carousel?.slides.map(slide => {
          const hasFile = Boolean(slide.imageObject?.file);
          const hasLink = Boolean(slide.link?.text && slide.link?.url);

          return {
            description: slide.description,
            hasFile,
            header: slide.header,
            link: hasLink
              ? {
                  name: slide.link.text,
                  value: slide.link.url,
                }
              : null,
            ...(!hasFile && { image: slide.originalImageInput }),
          };
        }),
        style: formData.carousel?.style,
      };
      variables["carouselImages"] = formData.carousel.slides?.map(s => s.imageObject?.file).filter(file => file);
    }
    supplierUpdate({ variables });
  };

  const selectedFontValue = watch("fontFamily");
  const selectedFontOption = useMemo(
    () => FONT_OPTIONS.find(({ value }) => selectedFontValue === value),
    [selectedFontValue],
  );

  const darkModeLabel = watch("darkModeEnabled") ? "Dark" : "Light";

  return (
    <>
      {(isLoading || isSaving) && <Loader />}
      <PageHeader
        primaryActions={
          <>
            <Button
              color="primary"
              disabled={!formState?.isDirty || isFetching}
              onClick={handleDiscardClick}
              variant="outlined"
            >
              Discard
            </Button>
            <Button
              color="primary"
              disabled={!formState?.isDirty || isFetching}
              onClick={handleSubmit(onSubmit)}
              variant="contained"
            >
              Save
            </Button>
          </>
        }
        stickyHeader
        title="Appearance"
      />
      <link href={FONT_LINK} rel="stylesheet" />
      <FormCard title="Theme">
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <Typography gutterBottom variant="subtitle1">
              Primary Color{" "}
              <FormTooltip content="Change the look of your buttons and links by choosing your store color." />
            </Typography>
            <Controller
              as={<ColorPicker />}
              className={classes.colorPicker}
              control={control}
              helperText={errors?.primaryColor?.message}
              name="primaryColor"
              rules={{ required: "*required" }}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Controller
              as={<DropdownSelect />}
              className={classes.fontFamilyDropdown}
              control={control}
              helperText={errors?.fontFamily?.message}
              label={
                <>
                  Font{" "}
                  <FormTooltip content="Change the font style used across your store to create a customized look that aligns with your brand." />
                </>
              }
              name="fontFamily"
              options={FONT_OPTIONS}
              renderValue={() => <Typography style={selectedFontOption.style}>{selectedFontOption.label}</Typography>}
              rules={{ required: "*required" }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography gutterBottom variant="subtitle1">
              Dark Mode{" "}
              <FormTooltip content="Use a dark color palette for all screens, views, and controls, instead of using a light or white palette." />
            </Typography>
            <Controller
              as={<SwitchToggle />}
              className={classes.darkModeToggler}
              control={control}
              label={darkModeLabel}
              name="darkModeEnabled"
              type="checkbox"
            />
            <Typography color="textSecondary" variant="body2">
              Dark mode alters your store's user interface to grey and black sections with contrasting light text.
            </Typography>
          </Grid>
        </Grid>
      </FormCard>

      <div style={{ display: !hasRetail ? "none" : "block", marginBottom: theme.spacing(2) }}>
        <FormCard title="Navigation">
          <Controller
            as={<RadioButtons />}
            className={classes.navbarToggle}
            control={control}
            name="navbarStyle"
            options={[
              {
                image: <img alt="Default" className={classes.navbarImage} src="/images/navbar_default.svg" />,
                label: "Default",
                value: "default",
              },
              {
                image: <img alt="Center" className={classes.navbarImage} src="/images/navbar_center.svg" />,
                label: "Center",
                value: "center",
              },
              {
                hide: !hasVibrantOption,
                image: <img alt="Vibrant" className={classes.navbarImage} src="/images/navbar_vibrant.svg" />,
                label: "Vibrant",
                value: "vibrant",
              },
            ]}
          />
          <Divider className={classes.divider} />
          <Typography color="textSecondary" variant="body1" gutterBottom>
            Help customers navigate your store or highlight specific pages through navigation links. These links will be
            displayed on your store's navigation bar. Drag items to rearrange the displayed order.
          </Typography>
          <Controller as={<DynamicLinks />} control={control} label="Navigation bar links" name="navLinks" />
          <Divider className={classes.divider} />
          <Typography color="textSecondary" variant="body1" gutterBottom>
            Help customers navigate your store by adding footer links. These links will be displayed on the bottom
            footer section. Drag items to rearrange the displayed order.
          </Typography>
          <Controller
            as={<DynamicLinks />}
            className={classes.footerLinksContainer}
            control={control}
            label="Footer links"
            name="footerLinks"
          />
        </FormCard>

        <FormCard title="Announcement bar">
          <Controller
            as={<Banner />}
            control={control}
            helperText={errors?.banner}
            name="banner"
            rules={{
              validate: {
                requiredBannerMessage: banner => !banner.isEnabled || Boolean(banner.message) || "*required",
                requiredBannerTitle: banner => !banner.isEnabled || Boolean(banner.title) || "*required",
              },
            }}
          />
        </FormCard>

        <FormCard title="Carousel">
          <Carousel control={control} watch={watch} />
        </FormCard>
      </div>

      <FormCard title="Frequently asked questions">
        <Controller as={<FaqSection />} control={control} name="faq" />
      </FormCard>
    </>
  );
};

export default Design;
