import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import PropTypes from "prop-types";

const RadioSelector = ({ className, onChange, options, value }) => {
  const handleChange = e => onChange(e.target.value);

  return (
    <RadioGroup name="method" onChange={handleChange} value={value}>
      {options.map(option => (
        <FormControlLabel
          className={className}
          control={<Radio color="primary" />}
          key={option.value}
          label={option.label}
          value={option.value}
          disabled={option?.disabled}
        />
      ))}
    </RadioGroup>
  );
};

RadioSelector.defaultProps = {
  className: "",
  onChange: () => {},
  options: [],
  value: "delivery",
};

RadioSelector.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      disabled: PropTypes.bool,
      label: PropTypes.node,
      value: PropTypes.string,
    }),
  ),
  value: PropTypes.string,
};

export default RadioSelector;
