import { DateRange } from "@arowana/graphql";
import { SupplierContext } from "@arowana/ui";
import {
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Switch,
  TextField,
} from "@material-ui/core";
import moment, { Moment } from "moment-timezone";
import { useContext, useEffect, useState } from "react";
import { start } from "repl";

export type DateRangeFormValue = DateRange & {
  idx?: number;
};

type DateRangeFormDialogProps = {
  value?: DateRangeFormValue; // utc timestamps
  onCancel: () => void;
  onConfirm: (date: DateRangeFormValue) => void;
};

const toLocalDate = (d: Date, tz: string) => moment.utc(d).tz(tz).format("YYYY-MM-DD");

const DateRangeFormDialog = ({ value = null, onCancel, onConfirm }: DateRangeFormDialogProps) => {
  const [startDate, setStartDate] = useState<string>(""); // YYYY-MM-DD
  const [endDate, setEndDate] = useState<string>(""); // YYYY-MM-DD
  const [error, setError] = useState("");

  const { timezone } = useContext(SupplierContext);

  useEffect(() => {
    if (value) {
      const parsedStart = toLocalDate(value.start, timezone);
      const parsedEnd = toLocalDate(value.end, timezone);

      setStartDate(parsedStart);
      setEndDate(parsedEnd);
    } else {
      setStartDate("");
      setEndDate("");
    }
  }, [value]);

  const onDoneClick = () => {
    setError("");
    const start = moment.tz(startDate, timezone).startOf("day");
    const end = moment.tz(endDate, timezone).endOf("day");

    if (!start.isValid()) {
      setError("Start date is invalid");

      return;
    }

    if (!end.isValid()) {
      setError("End date is invalid");

      return;
    }

    if (start.isAfter(end)) {
      setError("Start date cannot be greater than end date");

      return;
    }

    onConfirm({
      end: end.endOf("day").utc().format(),
      idx: value?.idx,
      start: start.utc().format(),
    });
  };

  return (
    <Dialog open={Boolean(value)} fullWidth maxWidth="xs" BackdropProps={{ mountOnEnter: true, unmountOnExit: true }}>
      <DialogTitle>{isNaN(value?.idx) ? "Add" : "Edit"} dates</DialogTitle>
      <DialogContent>
        <FormControl fullWidth margin="dense">
          <FormLabel>{"Start date"}</FormLabel>
          <TextField
            variant="outlined"
            size="small"
            type="date"
            margin="dense"
            value={startDate}
            onChange={e => setStartDate(e.target.value)}
            inputProps={{
              // Don't allow setting dates in the past.
              min: moment().format("YYYY-MM-DD"),
            }}
          />
        </FormControl>
        <FormControl fullWidth margin="dense">
          <FormLabel>End date</FormLabel>
          <TextField
            variant="outlined"
            size="small"
            margin="dense"
            type="date"
            value={endDate}
            inputProps={{
              min: startDate,
            }}
            onChange={e => setEndDate(e.target.value)}
          />
        </FormControl>
        {error && <FormHelperText error={error}>{error}</FormHelperText>}
      </DialogContent>

      <DialogActions>
        <Button color="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button color="primary" variant="contained" onClick={onDoneClick}>
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DateRangeFormDialog;
