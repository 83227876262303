import { gql, useMutation, useQuery } from "@apollo/client";
import { DATALAYER } from "@arowana/util";
import { Box, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

import { notificationVar } from "../../../cache/notificationPolicy";
import WholesaleListAutocomplete, { WholesaleList } from "./WholesaleListAutocomplete";

const DEFAULT_WHOLESALE_LIST = gql`
  query DefaultWholesaleList {
    currentSupplier {
      id
      defaultWholesaleList {
        id
        name
      }
    }
  }
`;

const SEARCH_WHOLESALE_LISTS = gql`
  query WholesaleLists($filter: WholesaleListsFilterInput, $sort: WholesaleListsSortInput, $page: PaginationInput) {
    currentSupplier {
      id
      wholesaleLists(filter: $filter, sort: $sort, page: $page) {
        edges {
          cursor
          node {
            id
            name
          }
        }
        pageInfo {
          count
          endCursor
          hasNextPage
          totalCount
        }
      }
    }
  }
`;

const SET_DEFAULT_WHOLESALE_LIST = gql`
  mutation SupplierSetDefaultWholesaleList($wholesaleListID: ID) {
    supplierSetDefaultWholesaleList(wholesaleListID: $wholesaleListID) {
      id
    }
  }
`;

const DefaultWholesaleList = () => {
  const { data, loading: fetchingDefaultList } = useQuery(DEFAULT_WHOLESALE_LIST, {
    context: { source: DATALAYER },
    fetchPolicy: "cache-and-network",
  });
  const { data: allLists, loading: fetchingAllLists } = useQuery(SEARCH_WHOLESALE_LISTS, {
    context: { source: DATALAYER },
    fetchPolicy: "cache-and-network",
    variables: { page: { first: 100 } },
  });
  const wholesaleLists =
    allLists?.currentSupplier?.wholesaleLists?.edges?.reduce((acc: WholesaleList[], { node }) => {
      acc.push(node);

      return acc;
    }, []) ?? [];

  const defaultWholesaleList = data?.currentSupplier?.defaultWholesaleList;

  const [setDefaultWholesaleList, { loading: updatingDefaultList }] = useMutation(SET_DEFAULT_WHOLESALE_LIST, {
    context: { source: DATALAYER },
    refetchQueries: ["DefaultWholesaleList"],
  });

  const onSelectDefaultList = (listToAdd: WholesaleList) => {
    setDefaultWholesaleList({ variables: { wholesaleListID: listToAdd.id } }).then(() => {
      notificationVar({
        message: "Default price list assigned!",
        severity: "success",
      });
    });
  };

  const onRemoveDefaultList = () => {
    setDefaultWholesaleList({ variables: { wholesaleListID: null } }).then(() => {
      notificationVar({
        message: "Default price list removed!",
        severity: "success",
      });
    });
  };

  return (
    <Box display="flex" alignItems="center">
      <Box mr="auto">
        <Typography component="p" variant="body2" gutterBottom>
          This will be the default list of items shown to visitors that don't have an active ordering account. Prices
          will not be displayed to these users.
        </Typography>
      </Box>
      <Box alignSelf="flex-end" minWidth={250}>
        {fetchingDefaultList || fetchingAllLists || updatingDefaultList ? (
          <Skeleton animation="wave" width="100%" />
        ) : (
          <WholesaleListAutocomplete
            defaultWholesaleList={defaultWholesaleList}
            onChange={onSelectDefaultList}
            onRemoveDefaultList={onRemoveDefaultList}
            wholesaleLists={wholesaleLists}
          />
        )}
      </Box>
    </Box>
  );
};

export default DefaultWholesaleList;
