import { OnWheelBlur } from "@arowana/ui";
import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  Link,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import clsx from "clsx";
import React, { useEffect, useState } from "react";

enum Fee {
  deliveryFee = "deliveryFee",
  handlingFee = "handlingFee",
  shippingFee = "shippingFee",
  taxFee = "taxFee",
  tipFee = "tipFee",
}

const FEE_NAMES: { [key in Fee]: string } = {
  [Fee.deliveryFee]: "Delivery fee",
  [Fee.handlingFee]: "Handling fee",
  [Fee.shippingFee]: "Shipping fee",
  [Fee.taxFee]: "Taxes",
  [Fee.tipFee]: "Tip",
};

const useStyles = makeStyles(() =>
  createStyles({
    hideFee: { display: "none" },
    removeButton: {
      justifySelf: "flex-start",
      marginRight: "auto",
    },
  }),
);

const FeeItem = ({
  currencyFormatter,
  index,
  onChange: setFee,
  onRemove,
  open: initiallyOpen = false,
  order,
  value: fee,
  isNew,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isXS = useMediaQuery(theme.breakpoints.down("sm"));

  const originalAmount = (fee.amount / 100).toFixed(2);
  const [amount, setAmount] = useState(originalAmount);
  const [description, setDescription] = useState<string | undefined>(fee.description);
  const [feeType, setFeeType] = useState<string | undefined>(fee.feeType);
  const [open, setOpen] = useState(initiallyOpen);

  useEffect(() => {
    setAmount(originalAmount);
    setDescription(fee.description);
    setFeeType(fee.feeType);
  }, [fee, originalAmount]);

  const handleAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAmount(event.target.value);
  };
  const handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDescription(event.target.value);
  };
  const handleFeeTypeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setFeeType(event.target.value as string);
  };

  const handleOpen = () => setOpen(true);
  const handleCancel = () => {
    if (isNew) {
      onRemove();
    }

    setOpen(false);
  };
  const handleDone = () => {
    setOpen(false);
    setFee({
      _remove: false,
      amount: Math.round(parseFloat(amount) * 100),
      description,
      feeType,
      id: fee.id,
    });
  };
  const handleRemove = () => {
    if (!fee.id) {
      onRemove();
    } else {
      setFee({
        _remove: true,
        amount: 0,
        description,
        feeType,
        id: fee.id,
      });
    }

    setOpen(false);
  };

  return (
    <div className={clsx({ [classes.hideFee]: fee._remove })}>
      <ListItem dense disableGutters>
        <ListItemText>
          <Typography component="span" variant="body2">
            {FEE_NAMES[fee.feeType]}
          </Typography>
          {fee.description && (
            <Typography color="textSecondary" component="span" variant="body2">
              {" "}
              ({fee.description})
            </Typography>
          )}{" "}
          {order.isEditable && (
            <Link component="button" onClick={handleOpen} variant="body2">
              Edit
            </Link>
          )}
        </ListItemText>
        <ListItemSecondaryAction>{currencyFormatter.format(parseFloat(originalAmount))}</ListItemSecondaryAction>
      </ListItem>

      <Dialog
        open={open}
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            setOpen(false);
          }
        }}
        aria-labelledby={`form-dialog-title-${index}`}
        fullScreen={isXS}
      >
        <DialogTitle id={`form-dialog-title-${index}`}>Edit fee</DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <TextField
                InputProps={{
                  onWheel: OnWheelBlur,
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
                autoFocus
                label="Amount"
                fullWidth
                size="small"
                type="number"
                variant="outlined"
                value={amount}
                required
                onChange={handleAmountChange}
              />
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth size="small" variant="outlined" required>
                <InputLabel id="typeLabel" required variant="outlined">
                  Type
                </InputLabel>
                <Select required labelId="typeLabel" label="Type" value={feeType} onChange={handleFeeTypeChange}>
                  {Object.entries(FEE_NAMES).map(([key, value]) => (
                    <MenuItem key={key} value={key}>
                      {value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Description"
                fullWidth
                size="small"
                multiline={true}
                type="text"
                variant="outlined"
                value={description}
                onChange={handleDescriptionChange}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {!isNew && (
            <Button
              className={classes.removeButton}
              onClick={handleRemove}
              color="secondary"
              variant="outlined"
              tabIndex="-1"
            >
              Remove
            </Button>
          )}
          <Button onClick={handleCancel} color="primary" variant="outlined" tabIndex="0">
            Cancel
          </Button>
          <Button onClick={handleDone} color="primary" variant="contained" tabIndex="0">
            Done
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default FeeItem;
