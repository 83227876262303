import { isLoggedInVar } from "../../cache/authPolicy";
import history from "../../history";
import client from "../../services/apollo";

const logout = async () => {
  localStorage.removeItem("auth_token");
  localStorage.removeItem("supplier_account");
  localStorage.removeItem("supplier_info");
  isLoggedInVar(false);
  await client.clearStore();
  history.push("/login");
};

export default logout;
