import { gql, useMutation, useQuery } from "@apollo/client";
import { FEATURE_SUPPLIER_STRIPE_INVOICES } from "@arowana/flags";
import {
  FulfillmentMethod,
  Order,
  OrderAudience,
  OrdersSortField,
  RecurringOrder,
  RecurringOrderCancelInput,
  RecurringOrderUpdateInput,
} from "@arowana/graphql";
import { Dollar, PaginatedTable, usePagination } from "@arowana/ui";
import { CancelRecurringOrderDialog, SkipRecurringOrderDialog, UpdateRecurringOrderDialog } from "@arowana/ui";
import { DATALAYER } from "@arowana/util";
import {
  Box,
  Button,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  Link,
  makeStyles,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { GridSortDirection } from "@material-ui/data-grid";
import { Cancel, Edit, PlayArrow, SkipNext } from "@material-ui/icons";
import { Skeleton } from "@material-ui/lab";
import moment from "moment-timezone";
import { useContext, useMemo, useState } from "react";
import { Link as RouterLink } from "react-router-dom";

import { notificationVar } from "../../../cache/notificationPolicy";
import FieldTitle from "../../../components/FieldTitle";
import FormCard from "../../../components/FormCard";
import FormTooltip from "../../../components/FormTooltip";
import FulfillmentChip from "../../../components/FulfillmentChip";
import Loader from "../../../components/Loader";
import PageHeader from "../../../components/PageHeader";
import PaymentCard from "../../../components/PaymentCard";
import Quantity from "../../../components/Quantity";
import Routes from "../../../Constants/Routes";
import { AUDIENCE_DISPLAY } from "../../../utils/audienceDisplay";
import { AccountContext } from "../../context/AccountContext";
import { FlagsmithContext } from "../../context/FlagsmithContext";
import Statuses from "../../orders/constants/orderStatus";

const ADDRESS_FRAGMENT = gql`
  fragment RecurringOrderAddressFragment on Address {
    id
    address1
    address2
    city
    country
    formattedAddress
    label
    notes
    postalCode
    region
    sublocality
  }
`;

const RECURRING_ORDER = gql`
  query RecurringOrder($id: ID!, $filter: OrdersFilterInput, $sort: OrdersSortInput, $page: PaginationInput) {
    recurringOrder(id: $id) {
      id
      account {
        id
        addresses {
          ...RecurringOrderAddressFragment
        }
        email
        name
        paymentMethods {
          id
          card {
            brand
            expiryMonth
            expiryYear
            last4
          }
        }
      }
      client {
        id
        name
        phone
        address {
          ...RecurringOrderAddressFragment
        }
        owner {
          id
          email
        }
      }
      active
      address {
        id
        formattedAddress
      }
      audience
      client {
        id
        name
        phone
      }
      createdAt
      fulfillmentMethod
      lineItems {
        overrideId
        overridingProduct {
          product {
            id
            name
            variants {
              id
              caseSize
              name
              price
              unit
            }
          }
        }
        quantity
      }
      nextFulfillmentDate
      orders(filter: $filter, sort: $sort, page: $page) {
        edges {
          cursor
          node {
            id
            createdAt
            fulfillmentDate
            invoiceNumber
            status
            total
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          totalCount
        }
      }
      paymentMethod {
        brand
        last4
      }
      pickupLocation {
        id
        address {
          formattedAddress
        }
      }
      pickupLocationId
      recurringOrderList {
        id
        fulfillmentMethods
        name
        subscriberSkippable
      }
      skipNext
      startsAt
      updatedAt
    }
  }
  ${ADDRESS_FRAGMENT}
`;

const UPDATE_REUCRRING_ORDER = gql`
  mutation UpdateRecurringOrder($input: RecurringOrderUpdateInput!) {
    recurringOrderUpdate(input: $input) {
      id
    }
  }
`;

const CANCEL_RECURRING_ORDER = gql`
  mutation CancelRecurringOrder($input: RecurringOrderCancelInput!) {
    recurringOrderCancel(input: $input) {
      id
    }
  }
`;

const DEBUG_TO_ORDER = gql`
  mutation ToOrder($id: ID!) {
    debugRecurringOrderToOrder(id: $id) {
      id
    }
  }
`;

const useStyles = makeStyles(theme => ({
  bottomSpacing: {
    marginBottom: theme.spacing(2),
  },
  dates: {
    "&:not(:last-child)": {
      marginBottom: theme.spacing(0.5),
    },
    "display": "flex",
    "gap": theme.spacing(1),
    "justifyContent": "space-between",
  },
  smallCard: {
    height: "100%",
  },
  statusFilter: {
    marginBottom: theme.spacing(2),
  },
  title: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
  },
  titleText: {
    marginRight: theme.spacing(1),
  },
}));

const ROWS_PER_PAGE = 10;

const PRODUCT_COLUMNS = [
  {
    field: "id",
    hide: true,
  },
  {
    field: "productName",
    headerName: "Name",
    renderCell: params => (
      <Box display="flex" flexDirection="column" lineHeight="normal">
        <Link component={RouterLink} to={Routes.PRODUCT.replace(":id", params.row.productId)}>
          {params.value}
        </Link>
        <Typography variant="caption">{params.row.subname}</Typography>
      </Box>
    ),
    width: 300,
  },
  {
    field: "quantity",
    headerName: "Quantity",
    renderCell: params => <Quantity amount={params.value} unit={params.row.unit} />,
    sortable: false,
    width: 150,
  },
  {
    field: "price",
    headerName: "Price",
    renderCell: params => <Dollar amount={params.value} units={params.row.unit} />,
    sortable: false,
    width: 150,
  },
  {
    field: "caseSize",
    headerName: "Case Size",
    renderCell: params => (params.value ? <Quantity amount={params.value} unit={params.row.unit} /> : "N/A"),
    sortable: false,
    width: 150,
  },
];

const getOrderColumns = timezone => [
  {
    field: "id",
    hide: true,
  },
  {
    field: OrdersSortField.INVOICE_NUMBER,
    headerName: "Order #",
    renderCell: param => (
      <Link component={RouterLink} to={Routes.ORDER_DETAILS.replace(":id", param.row.id)}>
        {param.value}
      </Link>
    ),
    valueGetter: param => param.row.invoiceNumber,
    width: 120,
  },
  {
    field: "status",
    headerName: "Status",
    sortable: false,
    valueGetter: param => Statuses.find(status => status.id === param.value)?.label ?? "N/A",
    width: 140,
  },
  {
    field: OrdersSortField.FULFILLMENT_DATE,
    headerName: "Fulfillment date",
    valueGetter: param => moment.tz(param.row.fulfillmentDate, timezone).format("L"),
    width: 160,
  },
  {
    field: OrdersSortField.CREATED_AT,
    headerName: "Created at",
    valueGetter: param => moment.tz(param.row.createdAt, timezone).format("L"),
    width: 160,
  },
  {
    field: "total",
    headerName: "Total",
    renderCell: param => <Dollar amount={param.value} />,
    sortable: false,
    width: 150,
  },
];

const DEFAULT_SORT_STATE = {
  field: OrdersSortField.INVOICE_NUMBER,
  sort: "desc" as GridSortDirection,
};

enum Filterable {
  status = "status",
}

const RecurringOrderDetails = ({ match, history }) => {
  const recurringOrderId = match?.params?.id;

  const classes = useStyles();

  const { supplier } = useContext(AccountContext);
  const { timezone } = supplier;

  const flagsmith = useContext(FlagsmithContext);
  const hasInvoices = flagsmith.hasFeature(FEATURE_SUPPLIER_STRIPE_INVOICES);

  const [sortState, setSortState] = useState(DEFAULT_SORT_STATE);
  const [orderStates, setOrderStates] = useState({
    [Filterable.status]: "all",
  });
  const { status } = orderStates;

  const { page: pageProduct, onPageChange: onPageChangeProduct } = usePagination(ROWS_PER_PAGE);
  const {
    page: pageOrder,
    onPageChange: onPageChangeOrder,
    resetPagination,
    setPageInfo,
    pagination,
  } = usePagination(ROWS_PER_PAGE);

  const { data, loading, refetch, called } = useQuery<{ recurringOrder: RecurringOrder }>(RECURRING_ORDER, {
    context: { source: DATALAYER },
    fetchPolicy: "no-cache", // quick fix for avoiding nested product being overriden, DO NOT CHANGE
    onCompleted: ({ recurringOrder }) => setPageInfo(recurringOrder.orders.pageInfo),
    variables: {
      filter: {
        ...(status !== "all" && { statuses: [status] }),
      },
      id: recurringOrderId,
      page: pagination,
      sort: sortState && {
        field: sortState.field,
        order: sortState.sort === "desc" ? -1 : 1,
      },
    },
  });

  const { recurringOrder } = data ?? {};

  const title = `Subscription #${recurringOrder?.id?.substring(18) ?? ""}`;
  const isActive = recurringOrder?.active;
  const isPickup = recurringOrder?.fulfillmentMethod === FulfillmentMethod.pickup;
  const isRetail = recurringOrder?.audience === OrderAudience.MARKETPLACE;
  const isWholesale = recurringOrder?.audience === OrderAudience.WHOLESALE;

  const { customerId, customerName, customerInfo, customerUrl } = useMemo(
    () =>
      isWholesale
        ? {
            customerId: recurringOrder?.client?.id,
            customerInfo: recurringOrder?.client?.phone,
            customerName: recurringOrder?.client?.name,
            customerUrl: Routes.WHOLESALE_CLIENT_DETAILS.replace(":id", recurringOrder?.client?.id),
          }
        : {
            customerId: recurringOrder?.account?.id,
            customerInfo: recurringOrder?.account?.email,
            customerName: recurringOrder?.account?.name,
            customerUrl: Routes.CUSTOMER_DETAILS.replace(":id", recurringOrder?.account?.id),
          },
    [recurringOrder],
  );

  const address = useMemo(
    () =>
      isPickup ? recurringOrder?.pickupLocation?.address?.formattedAddress : recurringOrder?.address?.formattedAddress,
    [recurringOrder, isPickup],
  );

  const products = useMemo(() => {
    const collected = [];
    recurringOrder?.lineItems?.forEach(({ overrideId: id, overridingProduct, quantity }) => {
      if (!overridingProduct?.product) {
        return;
      }

      const { id: productId, name, variants } = overridingProduct.product;

      collected.push({
        caseSize: variants[0].caseSize,
        id,
        price: variants[0].price,
        productId,
        productName: name,
        quantity,
        subname: variants[0].name,
        unit: variants[0].unit,
      });
    });

    return collected;
  }, [recurringOrder]);

  const totalOrdersCount = recurringOrder?.orders?.pageInfo?.totalCount ?? 0;
  const orders = recurringOrder?.orders?.edges.map(({ node }) => node) ?? [];
  const orderColumns = useMemo(() => getOrderColumns(timezone), [timezone]);
  const sortModel = sortState ? [sortState] : [];

  const onSortChange = ({ sortModel }) => {
    resetPagination();
    setSortState(sortModel[0]);
  };

  const updateOrderStates = (keyValue: { [key in Filterable]?: string }) => {
    setOrderStates({
      ...orderStates,
      ...keyValue,
    });
    resetPagination();
  };

  const onStatusChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    updateOrderStates({ [Filterable.status]: event.target.value });

  // Update
  const [showUpdate, setShowUpdate] = useState(false);
  const onUpdateClose = (_, reason) => {
    if (reason !== "backdropClick") {
      setShowUpdate(false);
    }
  };
  const [update, { loading: updating }] = useMutation<
    { recurringOrderUpdate: RecurringOrder },
    { input: RecurringOrderUpdateInput }
  >(UPDATE_REUCRRING_ORDER, {
    context: { source: DATALAYER },
    refetchQueries: ["RecurringOrder"],
  });
  const onUpdateSave = value => {
    update({
      onCompleted: () => {
        setShowUpdate(false);
        notificationVar({
          message: "Subscription updated!",
          severity: "success",
        });
      },
      variables: {
        input: {
          ...value,
          id: recurringOrderId,
        },
      },
    });
  };

  // Skip next
  const [showSkipNext, setShowSkipNext] = useState(false);
  const onSkipNextClose = (_, reason) => {
    if (reason !== "backdropClick") {
      setShowSkipNext(false);
    }
  };
  const onSkipNextConfirm = () => {
    update({
      onCompleted: () => {
        setShowSkipNext(false);
        notificationVar({
          message: recurringOrder?.skipNext ? "Subscription resumed!" : "Subscription paused!",
          severity: "success",
        });
      },
      variables: {
        input: {
          id: recurringOrderId,
          skipNext: !recurringOrder?.skipNext,
        },
      },
    });
  };

  // Cancel
  const [showCancel, setShowCancel] = useState(false);
  const [cancel, { loading: cancelling }] = useMutation<
    { recurringOrderCancel: RecurringOrder },
    { input: RecurringOrderCancelInput }
  >(CANCEL_RECURRING_ORDER, {
    context: { source: DATALAYER },
    refetchQueries: ["RecurringOrder"],
  });
  const onCancelClose = (_, reason) => {
    if (reason !== "backdropClick") {
      setShowCancel(false);
    }
  };
  const onCancelConfirm = () => {
    cancel({
      onCompleted: () => {
        setShowCancel(false);
        notificationVar({
          message: "Subscription cancelled!",
          severity: "success",
        });
      },
      variables: {
        input: {
          id: recurringOrderId,
          reason: "cancelled by supplier",
        },
      },
    });
  };

  // DEBUG only
  const [toOrder, { loading: generatingOrder }] = useMutation<{ debugRecurringOrderToOrder: Order }, { id: ID }>(
    DEBUG_TO_ORDER,
    {
      context: { source: DATALAYER },
      onCompleted: data => history.push(Routes.ORDER_DETAILS.replace(":id", data?.debugRecurringOrderToOrder?.id)),
      variables: {
        id: recurringOrderId,
      },
    },
  );

  return (
    <>
      <Loader loading={!called && loading} />
      <PageHeader
        stickyHeader
        documentTitle={title}
        title={
          recurringOrder ? (
            <>
              <Typography className={classes.title} variant="h5">
                <strong className={classes.titleText}>{title}</strong>
                <Chip
                  color={isActive ? "primary" : "secondary"}
                  label={isActive ? "Active" : "Inactive"}
                  size="small"
                  variant="outlined"
                />
                <FulfillmentChip fulfillmentType={recurringOrder.fulfillmentMethod} />
                <Chip
                  label={AUDIENCE_DISPLAY[recurringOrder.audience]}
                  size="small"
                  variant="outlined"
                  color="primary"
                />
              </Typography>
            </>
          ) : (
            <Skeleton animation="wave" height="40px" width="300px" />
          )
        }
        primaryActions={
          <>
            {process.env.FRESHLINE_ENVIRONMENT !== "production" && (
              <Button disabled={generatingOrder} variant="outlined" color="default" onClick={toOrder}>
                [DEBUG] Create order
              </Button>
            )}
            <Button
              color="primary"
              disabled={!recurringOrder?.active}
              onClick={() => setShowUpdate(true)}
              startIcon={<Edit />}
              variant="contained"
            >
              Edit
            </Button>
          </>
        }
      >
        <Box
          display="grid"
          width="100%"
          gridTemplateColumns="auto min-content min-content"
          alignItems="center"
          gridGap={8}
        >
          {recurringOrder ? (
            <div>
              <Typography
                color="textSecondary"
                title={moment.utc(recurringOrder.updatedAt).format("LL")}
                variant="body2"
              >
                Created at: {moment.utc(recurringOrder.createdAt).tz(timezone).format("LLL")}
              </Typography>
              <Typography
                color="textSecondary"
                title={moment.utc(recurringOrder.updatedAt).format("LLL")}
                variant="body2"
              >
                Last updated: {moment.utc(recurringOrder.updatedAt).fromNow()}
              </Typography>
            </div>
          ) : (
            <Skeleton animation="wave" className={classes.bottomSpacing} width="300px" />
          )}
          <Tooltip
            title={
              recurringOrder?.recurringOrderList?.subscriberSkippable
                ? "Skip next order"
                : "This collection is not skippable"
            }
          >
            <span>
              <Button
                color="primary"
                disabled={!recurringOrder?.recurringOrderList?.subscriberSkippable || !recurringOrder?.active}
                onClick={() => setShowSkipNext(true)}
                startIcon={recurringOrder?.skipNext ? <PlayArrow /> : <SkipNext />}
                variant="outlined"
              >
                {recurringOrder?.skipNext ? "Resume" : "Skip"}
              </Button>
            </span>
          </Tooltip>
          <Button
            color="secondary"
            disabled={!recurringOrder?.active}
            onClick={() => setShowCancel(true)}
            startIcon={<Cancel />}
            variant="contained"
          >
            {recurringOrder?.active ? "Cancel" : "Cancelled"}
          </Button>
        </Box>
      </PageHeader>

      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <FormCard className={classes.smallCard} title={isWholesale ? "Business" : "Customer"}>
            {recurringOrder ? (
              <>
                <FieldTitle title={customerName} />
                <Typography gutterBottom>{customerInfo}</Typography>
                <Link component={RouterLink} to={customerUrl}>
                  View {isWholesale ? "Business" : "Customer"}
                </Link>
              </>
            ) : (
              <Skeleton animation="wave" width="100%" />
            )}
          </FormCard>
        </Grid>

        <Grid item xs={12} md={4}>
          <FormCard className={classes.smallCard} title="Fulfillment">
            {recurringOrder ? (
              <>
                <Box mb={2}>
                  <FieldTitle title="Next Fulfillment" />

                  <Typography variant="body2" gutterBottom>
                    {recurringOrder.nextFulfillmentDate ? (
                      <>
                        {moment.utc(recurringOrder.nextFulfillmentDate).fromNow()} (
                        <time>{moment.utc(recurringOrder.nextFulfillmentDate).tz(timezone).format("LL")}</time>)
                      </>
                    ) : (
                      "N/A"
                    )}
                  </Typography>
                </Box>

                <FieldTitle
                  tooltip={
                    !isPickup && (
                      <FormTooltip content="Address changes made on customer/business are not synced to subscriptions, please manually reselect address in Edit modal" />
                    )
                  }
                  title={isPickup ? "Pickup address" : "Address "}
                />
                <Typography variant="body2">{address || "N/A"}</Typography>
              </>
            ) : (
              <Skeleton animation="wave" height="80px" width="100%" />
            )}
          </FormCard>
        </Grid>

        <Grid item xs={12} md={4}>
          <FormCard className={classes.smallCard} title="Payment">
            {recurringOrder ? (
              isRetail ? (
                recurringOrder?.paymentMethod && (
                  <PaymentCard
                    last4={recurringOrder?.paymentMethod?.last4}
                    type={recurringOrder?.paymentMethod?.brand}
                  />
                )
              ) : hasInvoices ? (
                <Typography>Invoice will be generated as during order fulfillment</Typography>
              ) : (
                <Typography>Manual collection</Typography>
              )
            ) : (
              <Skeleton animation="wave" height="80px" width="100%" />
            )}
          </FormCard>
        </Grid>

        <Grid item xs={12}>
          <FormCard
            title="Subscribed products"
            action={
              <Button
                color="primary"
                variant="outlined"
                component={RouterLink}
                size="small"
                to={Routes.SUBSCRIPTIONS_COLLECTIONS_DETAILS.replace(":id", recurringOrder?.recurringOrderList?.id)}
              >
                View collection
              </Button>
            }
          >
            <PaginatedTable
              columns={PRODUCT_COLUMNS}
              onPageChange={onPageChangeProduct}
              page={pageProduct}
              paginationMode="client"
              rows={products}
              sortingMode="client"
            />
          </FormCard>
        </Grid>

        <Grid item xs={12}>
          <FormCard
            title="Orders"
            action={
              <FormControl variant="outlined" margin="dense">
                <InputLabel htmlFor="status-select">Status</InputLabel>
                <Select
                  defaultValue="all"
                  inputProps={{
                    id: "status-select",
                  }}
                  label="Status"
                  onChange={onStatusChange}
                  title="Status"
                  value={status}
                  variant="outlined"
                >
                  {Statuses.map(({ id, label }) => (
                    <MenuItem key={id} value={id}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            }
          >
            <PaginatedTable
              columns={orderColumns}
              defaultContent="No orders"
              onPageChange={onPageChangeOrder}
              onSortModelChange={onSortChange}
              page={pageOrder}
              pageSize={ROWS_PER_PAGE}
              rows={orders}
              sortModel={sortModel}
              totalCount={totalOrdersCount}
            />
          </FormCard>
        </Grid>
      </Grid>

      {recurringOrder && (
        <>
          <UpdateRecurringOrderDialog
            accountId={isRetail ? recurringOrder.account.id : null}
            client={recurringOrder.client}
            loading={updating}
            notificationVar={notificationVar}
            recurringOrder={recurringOrder}
            refetchAccount={refetch}
            onClose={onUpdateClose}
            onSave={onUpdateSave}
            open={showUpdate}
            skipAccountQuery={isRetail ? false : true}
          />

          <SkipRecurringOrderDialog
            loading={updating}
            onClose={onSkipNextClose}
            onConfirm={onSkipNextConfirm}
            open={showSkipNext}
            skipNext={recurringOrder.skipNext}
          />

          <CancelRecurringOrderDialog
            loading={cancelling}
            onClose={onCancelClose}
            onConfirm={onCancelConfirm}
            open={showCancel}
          />
        </>
      )}
    </>
  );
};

export default RecurringOrderDetails;
