import { ApolloClient, ApolloLink, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { DATALAYER } from "@arowana/util";
import { createUploadLink } from "apollo-upload-client";
import omitDeep from "omit-deep-lodash";

import cache from "../cache";
import { notificationVar } from "../cache/notificationPolicy";
import Routes from "../Constants/Routes";
import history from "../history";
import logout from "../modules/auth/logout";

const removeTypenameFromVariablesLink = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    // eslint-disable-next-line no-param-reassign
    operation.variables = omitDeep(operation.variables, "__typename");
  }

  return forward(operation).map(data => data);
});
const backendLink = createUploadLink({ uri: "/graphql" });
const datalayerLink = createHttpLink({
  credentials: "same-origin",
  uri: "/api/v1/query",
});
const authLink = setContext((_, { headers }) => {
  const authToken = localStorage.getItem("auth_token");

  return {
    headers: {
      ...headers,
      Authorization: authToken ? `Bearer ${authToken}` : "",
    },
  };
});
// Has any 401 error
const UNAUTHORIZED_ERROR_MESSAGE = "You are not authorized";
const errorLink = onError(({ networkError, graphQLErrors, response, operation }) => {
  const context = operation.getContext();
  const {
    response: { headers },
  } = context;

  if (headers.get("freshline-clear-auth") === "true") {
    logout();

    return;
  }

  const errorMessage = new Set();

  if (networkError) {
    const message = networkError.result?.message ?? "Something went wrong.";
    errorMessage.add(typeof message === "string" ? message : JSON.stringify(message));
  }

  if (graphQLErrors) {
    graphQLErrors.forEach(graphQLError => {
      const message = graphQLError?.message ?? "Something went wrong.";
      errorMessage.add(typeof message === "string" ? message : JSON.stringify(message));

      if (message == UNAUTHORIZED_ERROR_MESSAGE) {
        history.replace(Routes.UNAUTHORIZED);
      }
    });
  }

  if (response) {
    const errors = response?.errors || [];
    errors.forEach(responseError => {
      const message = responseError?.message ?? "Something went wrong.";
      errorMessage.add(typeof message === "string" ? message : JSON.stringify(message));
    });
  }

  if (errorMessage.size > 0) {
    notificationVar({
      message: Array.from(errorMessage).join("\n"),
      severity: "error",
    });
  }
});
const client = new ApolloClient({
  cache,
  link: ApolloLink.from([
    removeTypenameFromVariablesLink,
    authLink,
    errorLink,
    ApolloLink.split(op => op.getContext().source === DATALAYER, datalayerLink, backendLink),
  ]),
});

export default client;
