import { Box, IconButton, makeStyles, Tooltip, Typography } from "@material-ui/core";
import { DeleteOutline as DeleteIcon, DragIndicator as DragIcon, Edit as EditIcon } from "@material-ui/icons";
import React from "react";

const useStyles = makeStyles(theme => ({
  helperButton: {
    marginRight: theme.spacing(1),
  },
}));
const TooltipButton = ({ disabled, icon, tooltip, ...props }) => {
  if (disabled) {
    return (
      <Tooltip title={tooltip}>
        <span>
          <IconButton color="primary" disabled size="small" {...props}>
            {icon}
          </IconButton>
        </span>
      </Tooltip>
    );
  }

  return (
    <IconButton color="primary" size="small" {...props}>
      {icon}
    </IconButton>
  );
};
const NavLink = ({ linkEditing, link, onEditLink, onRemoveLink, dragHandleProps }) => {
  const classes = useStyles();

  return (
    <Box alignItems="flex-end" display="flex" justifyContent="space-between">
      <Typography gutterBottom variant="subtitle2">
        {link.name}
      </Typography>
      <Box>
        <TooltipButton
          aria-label="edit"
          className={classes.helperButton}
          disabled={linkEditing}
          icon={<EditIcon />}
          onClick={onEditLink}
          tooltip="Please save current updates before editing this link."
        />
        <TooltipButton
          aria-label="delete"
          className={classes.helperButton}
          disabled={linkEditing}
          icon={<DeleteIcon />}
          onClick={onRemoveLink}
          tooltip="Please save current updates before deleting this link."
        />
        <TooltipButton
          aria-label="drag"
          disabled={linkEditing}
          icon={<DragIcon />}
          tooltip="Please save current updates before rearranging this link."
          {...dragHandleProps}
        />
      </Box>
    </Box>
  );
};

export default NavLink;
