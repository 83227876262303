import { Box, Button, CircularProgress, Collapse, makeStyles } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  buttons: {
    "& > :not(:first-child)": {
      marginLeft: theme.spacing(2),
    },
    "display": "flex",
    "justifyContent": "flex-end",
  },
}));

export type FormButtonsProps = {
  className?: string;
  discardDisabled: boolean;
  saveDisabled: boolean;
  saving: boolean;
  show: boolean;
  onDiscard: () => void;
};

// Basic save/discard buttons for forms
export const FormButtons = ({
  className,
  discardDisabled,
  saveDisabled,
  saving,
  show,
  onDiscard,
}: FormButtonsProps) => {
  const classes = useStyles();

  return (
    <Collapse in={show}>
      <Box className={clsx(classes.buttons, className)}>
        <Button onClick={onDiscard} disabled={discardDisabled} variant="outlined" color="primary">
          Discard
        </Button>
        <Button type="submit" disabled={saveDisabled || saving} variant="contained" color="primary">
          {saving ? <CircularProgress color="inherit" size={22} /> : "Save"}
        </Button>
      </Box>
    </Collapse>
  );
};

export default FormButtons;
