import { Card, CardProps, makeStyles, Theme, Typography } from "@material-ui/core";
import clsx from "clsx";
import { ReactNode } from "react";

const useStyles = makeStyles<Theme>(theme => ({
  contentContainer: {
    "&:not(:last-child)": {
      marginBottom: theme.spacing(2),
    },
    "border": `1px solid ${theme.palette.grey[300]}`,
    "borderRadius": theme.shape.borderRadiusExtra,
    "padding": theme.spacing(1.5, 2.5, 2),
    [theme.breakpoints.only("xs")]: { padding: theme.spacing(2) },
  },
  disabled: {
    opacity: 0.7,
    pointerEvents: "none",
  },
  noTitle: {
    paddingTop: theme.spacing(2),
  },
  title: {
    fontSize: "1.05rem",
    fontWeight: 600,
    letterSpacing: theme.typography.h6.letterSpacing,
    lineHeight: theme.typography.h6.lineHeight,
    margin: theme.spacing(0.5, 0, 1.5),
  },
}));

export type FormCardProps = {
  title?: ReactNode;
  disabled?: boolean;
} & CardProps;

export const FormCard = ({ children, className, disabled, title, variant = "outlined", ...rest }: FormCardProps) => {
  const classes = useStyles();
  const noTitle = !title;

  return (
    <Card
      className={clsx(classes.contentContainer, className, {
        [classes.noTitle]: noTitle,
        [classes.disabled]: disabled,
      })}
      variant={variant}
      {...rest}
    >
      {title && (
        <Typography className={classes.title} variant="h3">
          {title}
        </Typography>
      )}
      {children}
    </Card>
  );
};

export default FormCard;
