import { Button, Grid, makeStyles, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import FaqDisplay from "./FaqDisplay";
import FaqForm from "./FaqForm";

const useStyles = makeStyles(theme => ({
  expandFaqButton: {
    float: "right",
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(1.5),
      width: "100%",
    },
  },
  spacing: {
    marginBottom: theme.spacing(2),
  },
}));
const FaqSection = ({ value, onChange }) => {
  const faq = value.slice();
  const classes = useStyles();
  const [editFaqIdx, setEditFaq] = useState(-1);
  const [addFaq, setAddFaq] = useState(false);
  const showFaq = addFaq || faq.length > 0;
  const handleDeleteFaq = idx => {
    faq.splice(idx, 1);

    if (editFaqIdx > -1) {
      if (idx === editFaqIdx) {
        setEditFaq(-1);
      } else if (idx < editFaqIdx) {
        setEditFaq(editFaqIdx - 1);
      }
    }

    if (faq.length === 0) {
      setAddFaq(false);
    }
    onChange(faq);
  };
  const handleEditFaq = idx => setEditFaq(idx);
  const handleCancelEditFaq = () => setEditFaq(-1);
  const handleAddFaqSubmit = data => {
    faq.push(data);
    setEditFaq(-1);
    onChange(faq);
  };
  const handleEditFaqSubmit = editedFaq => {
    faq[editFaqIdx] = editedFaq;
    setEditFaq(-1);
    onChange(faq);
  };
  const handleReorderFaq = result => {
    // illegal drop
    if (!result?.destination || !result?.source || result?.reason === "CANCEL") return;

    const sourceIdx = result.source.index ?? -1;
    const destIdx = result.destination.index ?? -1;

    // illegal drop or drop to the original place
    if (sourceIdx < 0 || destIdx < 0 || sourceIdx === destIdx) return;

    const movedFaq = faq.splice(sourceIdx, 1)[0];
    faq.splice(destIdx, 0, movedFaq);

    // if there is a faq being edited
    if (editFaqIdx > -1) {
      if (sourceIdx === editFaqIdx) {
        // if moving the being-edited faq
        setEditFaq(destIdx);
      } else if (sourceIdx > editFaqIdx && destIdx <= editFaqIdx) {
        // if moving a faq in front of the being-edited faq
        setEditFaq(editFaqIdx + 1);
      } else if (sourceIdx < editFaqIdx && destIdx >= editFaqIdx) {
        // if moving a faq behind the being-edited faq
        setEditFaq(editFaqIdx - 1);
      }
    }
    onChange(faq);
  };
  const handleAddFaq = () => setAddFaq(true);
  const addedFaq = Array.isArray(faq) && (
    <DragDropContext onDragEnd={handleReorderFaq}>
      <Droppable droppableId="faq_container">
        {providedFromContainer => (
          <div {...providedFromContainer.droppableProps} ref={providedFromContainer.innerRef}>
            {faq.map((qaObj, idx) => (
              <Draggable draggableId={`${idx}_dragged_item`} index={idx} key={`${idx}_drag_faq`}>
                {providedFromItem => (
                  <div
                    ref={providedFromItem.innerRef}
                    {...providedFromItem.draggableProps}
                    {...providedFromItem.dragHandleProps}
                  >
                    {idx === editFaqIdx ? (
                      <FaqForm
                        faq={qaObj}
                        key={`${idx}_faq_edit`}
                        onCancelEditFaq={handleCancelEditFaq}
                        onSubmit={handleEditFaqSubmit}
                      />
                    ) : (
                      <FaqDisplay
                        faq={qaObj}
                        key={`${idx}_faq_display`}
                        onDeleteFaq={() => handleDeleteFaq(idx)}
                        onEditFaq={() => handleEditFaq(idx)}
                      />
                    )}
                  </div>
                )}
              </Draggable>
            ))}
            {providedFromContainer.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
  let renderedFaq;

  if (showFaq) {
    renderedFaq = (
      <>
        {addedFaq}

        <FaqForm isAddFaq onSubmit={handleAddFaqSubmit} />
      </>
    );
  } else {
    renderedFaq = (
      <Grid alignItems="center" container spacing={1}>
        <Grid item lg={10} md={9} xs={12}>
          <Typography color="textSecondary" variant="body2">
            Decrease customer inquires by adding a frequently asked questions section to your store.
          </Typography>
        </Grid>
        <Grid item lg={2} md={3} xs={12}>
          <Button className={classes.expandFaqButton} color="primary" onClick={handleAddFaq} variant="contained">
            Add
          </Button>
        </Grid>
      </Grid>
    );
  }

  return renderedFaq;
};

FaqSection.defaultProps = {
  onChange: () => {},
  value: [],
};

FaqSection.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.array,
};

export default FaqSection;
