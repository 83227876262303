import { useMutation, useQuery } from "@apollo/client";
import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  Link,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import moment from "moment-timezone";
import { useContext, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Link as RouterLink } from "react-router-dom";
import slugify from "slugify";

import { notificationVar } from "../../../cache/notificationPolicy";
import ConfirmationModal from "../../../components/ConfirmationModal";
import FormCard from "../../../components/FormCard";
import Loader from "../../../components/Loader";
import PageHeader from "../../../components/PageHeader";
import RichTextFormComponent from "../../../components/RichTextFormComponent";
import Routes from "../../../Constants/Routes";
import list from "../../../queries/list";
import listCreate from "../../../queries/listCreate";
import listDelete from "../../../queries/listDelete";
import listUpdate from "../../../queries/listUpdate";
import LogoUpload from "../../account/components/LogoUpload";
import { AccountContext } from "../../context/AccountContext";
import AddProductInputList from "../components/AddProductInputList";

const useStyles = makeStyles(theme => ({
  quill: {
    marginBottom: theme.spacing(0.5),
    marginTop: theme.spacing(1),
  },
}));
const ListDetails = ({ history, match }) => {
  const classes = useStyles();
  const isCreate = match.path === Routes.CREATE_LIST;
  const listId = match.params?.id;
  const { supplier } = useContext(AccountContext);
  const { id: supplierId, timezone, url } = supplier;
  const [openDeleteListModal, setOpenDeleteListModal] = useState(false);
  const [isLoading, setLoading] = useState(!isCreate);
  const { handleSubmit, errors, control, formState, register, reset, watch } = useForm({
    defaultValues: {
      description: "",
      image: {},
      name: "",
      products: [],
    },
  });
  const { data, loading: isFetching } = useQuery(list, {
    skip: isCreate,
    variables: { listId },
  });
  const [createList, { loading: isCreating }] = useMutation(listCreate, {
    onCompleted: response => {
      notificationVar({
        message: "List created!",
        severity: "success",
      });
      history.replace(Routes.EDIT_LIST.replace(":id", response.listCreate._id));
    },
  });
  const [updateList, { loading: isUpdating }] = useMutation(listUpdate, {
    onCompleted: () => {
      notificationVar({
        message: "List updated!",
        severity: "success",
      });
    },
  });
  const [deleteList, { loading: isDeleting }] = useMutation(listDelete, {
    onCompleted: () => {
      notificationVar({
        message: "List deleted!",
        severity: "success",
      });
      history.push(Routes.LISTS);
    },
  });

  useEffect(() => {
    if (!isCreate && !isFetching && data) {
      const list = data.list ?? {};

      reset({
        description: list.description ?? "",
        image: list.image ? { image: list.image.original } : {},
        name: list.name ?? "",
        products: list.products?.map(({ __typename, ...rest }) => ({ ...rest })) ?? [],
      });

      // manually control loading after reset is done
      // or user will see blank forms flashing
      setLoading(false);
    }
  }, [data, isCreate, isFetching, reset]);

  const handleDiscardClick = () => reset();
  const handleOpenDeleteListModal = () => setOpenDeleteListModal(true);
  const handleCloseDeleteListModal = () => setOpenDeleteListModal(false);
  const handleDeleteList = () => {
    handleCloseDeleteListModal();
    deleteList({ update: handleClearCache, variables: { listId } });
  };
  const handleClearCache = cache =>
    Object.keys(cache?.data?.data ?? {}).forEach(key => key.match(/^Supplier/) && cache.data.delete(key));
  const onSubmit = formData => {
    const input = {
      description: formData.description,
      name: formData.name,
      products: formData.products.map(({ id }) => id),
      supplier: supplierId,
    };
    const image = formData.image?.file;

    if (isCreate) {
      createList({
        update: handleClearCache,
        variables: {
          image,
          input,
        },
      });
    } else {
      updateList({
        update: handleClearCache,
        variables: {
          image,
          input: {
            ...input,
            _id: listId,
          },
        },
      });
    }
  };

  const listName = watch("name", "");
  const listUrl = useMemo(() => {
    const slug = data?.list?.identifier ?? slugify(listName, { lower: true, strict: true });

    return `${url}/lists/${slug}`;
  }, [data?.list?.identifier, listName, url]);

  return (
    <>
      {(isLoading || isCreating || isUpdating || isDeleting) && <Loader />}
      <PageHeader
        primaryActions={
          <>
            <Button color="primary" disabled={!formState?.isDirty} onClick={handleDiscardClick} variant="outlined">
              Discard
            </Button>
            <Button color="primary" disabled={!formState?.isDirty} onClick={handleSubmit(onSubmit)} variant="contained">
              Save
            </Button>
          </>
        }
        stickyHeader
        title={isCreate ? "New collection" : data?.list?.name ?? "Edit collection"}
      >
        {!isCreate && (
          <Grid alignItems="center" container justifyContent="space-between">
            <Grid item>
              <Typography title={moment.tz(data?.list?.updatedAt, timezone).format("lll")} variant="colorTextSecondary">
                Updated {moment.tz(data?.list?.updatedAt, timezone).fromNow()}
              </Typography>
            </Grid>
            <Grid item>
              <Grid container justifyContent="flex-end">
                <Button color="secondary" onClick={handleOpenDeleteListModal} variant="text">
                  Delete
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
      </PageHeader>

      <FormCard>
        <FormControl fullWidth margin="normal">
          <FormLabel htmlFor="name">Name</FormLabel>
          <TextField
            aria-describedby="name-helper"
            error={Boolean(errors?.name)}
            fullWidth
            helperText={errors?.name?.message}
            id="name"
            inputRef={register({ required: "*required" })}
            margin="dense"
            name="name"
            variant="outlined"
          />
          <FormHelperText id="name-helper">
            Add a descriptive name to easily distingush the collection's use case (e.g. "Taxable Collection or Christmas
            Sale Collection").
          </FormHelperText>
        </FormControl>

        <FormControl fullWidth margin="normal">
          <FormLabel htmlFor="url">Sharable link</FormLabel>
          <TextField
            aria-describedby="url-helper"
            disabled
            id="url"
            margin="dense"
            onClick={event => event.target.select()}
            onFocus={event => event.target.select()}
            value={listUrl}
            variant="outlined"
          />
          <FormHelperText id="url-helper">
            Use this sharable URL to share this collection or{" "}
            <Link component={RouterLink} to={Routes.STORE_APPEARANCE}>
              add it to your Navigation bar
            </Link>
            .
          </FormHelperText>
        </FormControl>

        <FormControl fullWidth margin="normal">
          <FormLabel htmlFor="image">Image</FormLabel>
          <Controller
            as={<LogoUpload />}
            control={control}
            helperText={errors?.image?.message}
            id="image"
            name="image"
          />
        </FormControl>

        <FormControl fullWidth margin="normal">
          <FormLabel htmlFor="description">Description</FormLabel>
          <RichTextFormComponent
            aria-describedby="description-helper"
            className={classes.quill}
            control={control}
            helperText={errors?.description?.message}
            id="description"
            name="description"
            required
          />
          <FormHelperText id="description-helper">
            This description will be displayed alongside the collection name as customers visit the collection URL.
          </FormHelperText>
        </FormControl>
      </FormCard>
      <FormCard title="Products in collection">
        <Controller
          as={AddProductInputList}
          control={control}
          error={Boolean(errors?.products)}
          helperText={errors?.products?.message}
          name="products"
        />
      </FormCard>

      <ConfirmationModal
        cancelRequestButtonText="Cancel"
        confirmRequestButtonText="Delete List"
        isDangerAction
        modalContent="Are you sure you want to delete the list?"
        modalNote="Note: This action cannot be reversed."
        modalTitle="Delete List"
        onCloseModal={handleCloseDeleteListModal}
        onConfirmClick={handleDeleteList}
        shouldOpenModal={openDeleteListModal}
      />
    </>
  );
};

export default ListDetails;
