import { usePrevious } from "@arowana/ui";
import {
  Checkbox,
  Collapse,
  Fade,
  Grid,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Typography,
} from "@material-ui/core";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import clsx from "clsx";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { DragDropContext, Draggable, DragUpdate, Droppable, DropResult } from "react-beautiful-dnd";

import ReportField from "../types/ReportField";

const useStyles = makeStyles(theme => ({
  list: {
    border: `1px solid #AAA`,
    borderRadius: "5px",
    height: 640,
    overflow: "scroll",
    overscrollBehaviorX: "none",
    position: "relative",
    scrollBehavior: "smooth",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      height: "50vh",
    },
  },
  reoderList: {
    padding: theme.spacing(2, 3),
  },
  reorderItem: {
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.grey[400]}`,
    borderRadius: "5px",
    flexGrow: 1,
  },
  reorderItemHandle: {
    minWidth: 0,
  },
  reorderItemIndex: {
    flexBasis: "40px",
    flexGrow: 0,
    flexShrink: 0,
  },
  reorderItemRoot: {
    alignItems: "center",
    display: "flex",
    marginBottom: theme.spacing(2),
    transition: theme.transitions.create(["opacity", "color"]),
  },
  reorderItemRootDragging: {
    color: theme.palette.primary.main,
    opacity: 0.8,
  },
  reorderItemSecondaryText: {
    textTransform: "capitalize",
  },
  reorderItemTextRoot: {
    margin: 0,
  },
  selectHeader: {
    backgroundColor: theme.palette.grey[50],
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    textTransform: "capitalize",
    zIndex: 2,
  },
  selectItem: {
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(1),
  },
  selectRoot: {
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      paddingBottom: theme.spacing(2),
      paddingRight: "unset",
    },
  },
}));

type SelectItemProps = {
  className: string;
  checked: boolean;
  path: string;
  label: string;
  onClick: (path: string) => void;
};

const SelectItem = ({ className, checked, path, label, onClick, loading }: SelectItemProps) => (
  <ListItem className={className} key={path} button onClick={() => onClick(path)}>
    <ListItemIcon>
      <Checkbox
        disableRipple
        checked={checked}
        color="primary"
        edge="end"
        tabIndex={-1}
        inputProps={{ "aria-labelledby": path }}
        disabled={loading}
      />
    </ListItemIcon>
    <ListItemText id={path} primary={label} />
  </ListItem>
);

const resourceToFieldList = (merged, field) => {
  const resoureKey = field?.resource;
  const list: ReportField[] = merged[resoureKey] || [];

  return {
    ...merged,
    [resoureKey]: [...list, field],
  };
};

type SelectionListProps = {
  fields: ReportField[];
  value: Set<string>;
  onChange: (newValue: Set<string>) => void;
  loading: boolean;
};

const SelectionList = ({ fields, value, onChange, loading }: SelectionListProps) => {
  const classes = useStyles();
  // resource -> list of fields
  const fieldsBySection: Record<string, ReportField[]> = useMemo(
    () => fields.reduce<Record<string, ReportField[]>>(resourceToFieldList, {}),
    [fields],
  );
  // resource -> boolean (all checked)
  const sectionChecked: Record<string, boolean> = useMemo(
    () =>
      Object.keys(fieldsBySection).reduce(
        (merged, resource) => ({
          ...merged,
          [resource]: fieldsBySection[resource].every(field => value.has(field.path)),
        }),
        {},
      ),
    [fieldsBySection, value],
  );

  const onHeaderClicked = (resource: string) => {
    const newSet = new Set(value);

    if (sectionChecked[resource]) {
      fieldsBySection[resource].forEach(({ path }) => newSet.delete(path));
    } else {
      fieldsBySection[resource].forEach(({ path }) => newSet.add(path));
    }

    onChange(newSet);
  };

  const onItemClicked = (path: string) => {
    const newSet = new Set(value);

    if (newSet.has(path)) {
      newSet.delete(path);
    } else {
      newSet.add(path);
    }

    onChange(newSet);
  };

  return (
    <List className={classes.list} subheader={<li />}>
      <Collapse in={loading} unmountOnExit>
        <LinearProgress />
      </Collapse>
      {Object.keys(fieldsBySection)?.map(resource => (
        <li key={`section-${resource}`}>
          <ListItem className={classes.selectHeader} button onClick={() => onHeaderClicked(resource)}>
            <ListItemIcon>
              <Checkbox
                checked={sectionChecked[resource]}
                color="primary"
                tabIndex={-1}
                disableRipple
                disabled={loading}
                inputProps={{ "aria-labelledby": resource }}
              />
            </ListItemIcon>
            <ListItemText id={resource} primary={resource} />
          </ListItem>
          {fieldsBySection[resource].map(({ path, label }) => (
            <SelectItem
              key={path}
              className={classes.selectItem}
              checked={value.has(path)}
              path={path}
              label={label}
              loading={loading}
              onClick={onItemClicked}
            />
          ))}
        </li>
      ))}
    </List>
  );
};

function reorder<T>(list: T[], startIndex: number, endIndex: number) {
  const result = [...list];
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
}

function calculateNewIndicies<T>(
  items: T[],
  currentIndicies: number[],
  sourceIndex: number,
  destinationIndex: number,
): number[] {
  return items.map((_, idx) => {
    if (idx === sourceIndex) {
      return destinationIndex;
    } else if (currentIndicies[idx] === destinationIndex) {
      return currentIndicies[idx] - destinationIndex + currentIndicies[sourceIndex];
    } else {
      return currentIndicies[idx];
    }
  });
}

type ReorderListProps = {
  value: ReportField[];
  onChange: (value: ReportField[]) => void;
};

const ReorderList = ({ value, onChange }: ReorderListProps) => {
  const classes = useStyles();

  const [displayIndicies, setDisplayIndicies] = useState(Array.from(value.keys()));
  const previousCount = usePrevious(value.length) ?? 0;
  const listRef = useRef<HTMLElement | null>(null);

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const newValue = reorder(value, result.source.index, result.destination.index);
    onChange(newValue);
    setDisplayIndicies(Array.from(newValue.keys()));
  };

  const onDragUpdate = (result: DragUpdate) => {
    if (!result.destination) {
      return;
    }

    setDisplayIndicies((indicies: number[]) =>
      calculateNewIndicies(value, indicies, result.source?.index ?? 0, result.destination?.index ?? 0),
    );
  };

  useEffect(() => {
    setDisplayIndicies(Array.from(value.keys()));

    const listEl = listRef?.current;

    if (listEl && value.length > previousCount) {
      // smooth scroll to bottom, whenever there's more value
      listEl.scrollTop = listEl.scrollHeight;
    }
  }, [previousCount, value]);

  return (
    <DragDropContext onDragEnd={onDragEnd} onDragUpdate={onDragUpdate}>
      <Droppable droppableId="list">
        {provided => (
          <List
            className={clsx(classes.list, classes.reoderList)}
            ref={ref => {
              listRef.current = ref;
              provided.innerRef(ref);
            }}
            {...provided.droppableProps}
          >
            {value.map(({ path, label, resource }, index) => (
              <Draggable key={`drag-${path}`} draggableId={`drag-${path}`} index={index}>
                {(provided, snapshot) => (
                  <Fade in timeout={300}>
                    <div
                      className={clsx(classes.reorderItemRoot, {
                        [classes.reorderItemRootDragging]: snapshot.isDragging,
                      })}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                    >
                      <Typography className={classes.reorderItemIndex} variant="subtitle2">
                        {displayIndicies[index] + 1}.
                      </Typography>
                      <ListItem classes={{ container: classes.reorderItem }}>
                        <ListItemText
                          classes={{ root: classes.reorderItemTextRoot, secondary: classes.reorderItemSecondaryText }}
                          id={path}
                          primary={label}
                          secondary={resource}
                          autoCapitalize="on"
                        />
                        <ListItemSecondaryAction>
                          <IconButton aria-label="drag" title="drag" {...provided.dragHandleProps}>
                            <DragIndicatorIcon color="primary" />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    </div>
                  </Fade>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </List>
        )}
      </Droppable>
    </DragDropContext>
  );
};

type ExportColumnSelectionProps = {
  fields: ReportField[];
  value: ReportField[];
  onChange: (value: ReportField[]) => void;
  loading: boolean;
};

const fieldPathToField = (merged, field) => ({
  ...merged,
  [field.path]: field,
});

const ExportColumnSelection = ({ fields, value, onChange, loading }: ExportColumnSelectionProps) => {
  const fieldsLookup: Record<string, ReportField> = useMemo(() => fields.reduce(fieldPathToField, {}), [fields]);
  const selectionValue = new Set(value.map(field => field.path));
  const onSelectionChange = (pathSet: Set<string>) => {
    const selectedFields = [...pathSet].map(path => fieldsLookup[path]);
    onChange(selectedFields);
  };

  return (
    <Grid container spacing={2}>
      <Grid item md={6} xs={12}>
        <Typography gutterBottom variant="subtitle2">
          Select columns:
        </Typography>
        <SelectionList loading={loading} fields={fields} value={selectionValue} onChange={onSelectionChange} />
      </Grid>
      <Grid item md={6} xs={12}>
        <Typography gutterBottom variant="subtitle2">
          Order columns:
        </Typography>
        <ReorderList value={value} onChange={onChange} />
      </Grid>
    </Grid>
  );
};

export default ExportColumnSelection;
