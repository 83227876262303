import { FormControl, FormLabel, Grid, Link, makeStyles, TextField, Typography } from "@material-ui/core";
import React from "react";

import FormTooltip from "../../../components/FormTooltip";
import AppForm from "../components/AppForm";

const defaultValues = {
  id: "",
};
const useStyles = makeStyles(theme => ({
  instructionSection: {
    backgroundColor: theme.palette.grey[50],
    borderRadius: theme.shape.borderRadius,
    marginTop: theme.spacing(6),
    padding: theme.spacing(2),
  },
}));

const GoogleTagManager = () => {
  const classes = useStyles();
  const isInstalled = fields => Boolean(fields?.id);

  return (
    <AppForm
      appId="googleTagManager"
      defaultValues={defaultValues}
      header="Google Tag Manager ID"
      isInstalled={isInstalled}
    >
      {({ formMethods: { errors, register }, installed }) => (
        <FormControl component="fieldset" fullWidth margin="normal">
          <FormLabel component="legend" htmlFor="id">
            Google Tag Manager ID{" "}
            <FormTooltip content="Google Tag Manager is a tag management system that includes the same functionality as global site tags, and lets you configure and instantly deploy tags on your website or mobile app from an easy to use web-based user interface." />
          </FormLabel>
          <TextField
            disabled={installed}
            error={Boolean(errors?.id)}
            helperText={errors?.id?.message}
            id="id"
            inputRef={register({
              required: "*required",
            })}
            margin="dense"
            name="id"
            variant="outlined"
          />
          <Typography color="textSecondary" variant="caption">
            i.e. GTM-5G6WJ1F (GTM-XXXXXXX format)
          </Typography>
          <Grid className={classes.instructionSection} xs={12}>
            <Typography gutterBottom variant="subtitle2">
              Installation Guide
            </Typography>
            <Typography color="textSecondary" variant="body2">
              <ol>
                <li>
                  <Link href="https://tagmanager.google.com/" target="_blank">
                    Sign in to your Tag Manager account
                  </Link>
                </li>
                <li>Click Workspace.</li>
                <li>Near the top of the window, find your container ID, formatted as "GTM-XXXXXX".</li>
                <li>Copy this container ID and paste it into the ID input on this app page.</li>
                <li>Click Save to finalize your details.</li>
              </ol>
            </Typography>
          </Grid>
        </FormControl>
      )}
    </AppForm>
  );
};

export default GoogleTagManager;
