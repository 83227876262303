/* eslint-disable @typescript-eslint/no-explicit-any */
import { Category } from "@arowana/graphql";
import { Chip, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  tag: {
    "&:not(:last-child)": { marginRight: theme.spacing(1) },
    "backgroundColor": theme.palette.grey[theme.palette.type === "light" ? 300 : 600],
    "marginBottom": theme.spacing(1),
    "padding": theme.spacing(1),
  },
}));

interface ProductTagsProps {
  category: Category;
  onTagFilter?: (props: any) => void;
  tags: string[];
}

const ProductTags = ({ category, onTagFilter, tags }: ProductTagsProps) => {
  const classes = useStyles();
  const productTagsSet = new Set(tags);

  return (
    <>
      {(category?.tags?.filter(({ value }) => productTagsSet.has(value)) ?? []).map(({ value, label }) => (
        <Chip
          className={classes.tag}
          key={value}
          label={label}
          onClick={
            onTagFilter
              ? () =>
                  onTagFilter({
                    categoryId: category?.id,
                    tagValues: [value],
                  })
              : undefined
          }
          size="small"
        />
      ))}
    </>
  );
};

export default ProductTags;
