import { DateRange } from "@arowana/graphql";
import { SupplierContext } from "@arowana/ui";
import {
  Box,
  Button,
  Fade,
  FormHelperText,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Add, Clear, Edit } from "@material-ui/icons";
import moment from "moment-timezone";
import { forwardRef, useContext, useMemo, useState } from "react";

import DateRangeFormDialog, { DateRangeFormValue } from "./DateRangeFormDialog";

const DISPLAY_FMT = "YYYY-MM-DD (ddd)";

type DateRangeListItemProps = {
  value: DateRange;
  onEdit: () => void;
  onRemove: () => void;
};

const DateRangeListItem = ({ value, onEdit, onRemove }: DateRangeListItemProps) => {
  const { timezone } = useContext(SupplierContext);
  const { start, end, idx } = value;

  // render time range in supplier's timezone
  const label = useMemo(() => {
    const startDate = moment(start).tz(timezone);
    const endDate = moment(end).tz(timezone);

    return startDate.isSame(endDate, "date")
      ? startDate.format(DISPLAY_FMT)
      : `${startDate.format(DISPLAY_FMT)} — ${endDate.format(DISPLAY_FMT)}`;
  }, [start, end]);

  return (
    <ListItem divider disableGutters>
      <ListItemText>{label}</ListItemText>
      <ListItemSecondaryAction>
        <Tooltip title="Edit">
          <IconButton aria-label="edit" onClick={onEdit} color="primary">
            <Edit />
          </IconButton>
        </Tooltip>
        <Tooltip title="Clear">
          <IconButton aria-label="Clear" edge="end" onClick={onRemove}>
            <Clear />
          </IconButton>
        </Tooltip>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

type DateRangesInputProps = {
  value: Array<DateRange>;
  onChange: (dates: Array<DateRange>) => void;
  errorMsg?: string;
};

const DateRangesInput = forwardRef(({ value, onChange, errorMsg }: DateRangesInputProps, ref) => {
  // this controls date input dialog
  const [dateInEdit, setDateInEdit] = useState<DateRangeFormValue>(null);

  const onAddClick = () => {
    setDateInEdit({
      endDate: moment().startOf("day").format(),
      startDate: moment().endOf("day").format(),
    });
  };

  const onConfirm = (date: DateRangeFormValue) => {
    const { idx, ...input } = date;

    if (isNaN(idx)) {
      // If no idx is included, then we are appending to the list
      onChange([...value, input]);
    } else {
      // else update value @ idx
      const updateDates = [...value];
      updateDates[idx] = { ...input };
      onChange(updateDates);
    }

    setDateInEdit(null);
  };

  const onRemove = idx => onChange(value.filter((v, i) => i !== idx));

  const onCancel = () => setDateInEdit(null);

  const onClearAll = () => onChange([]);

  return (
    <div ref={ref}>
      <List>
        {value.map(({ start, end }, idx) => (
          <DateRangeListItem
            key={`${idx}_${start}_${end}`}
            value={{ end, idx, start }}
            onEdit={() => setDateInEdit({ end, idx, start })}
            onRemove={() => onRemove(idx)}
          />
        ))}
        {value.length === 0 && <Typography align="center">No dates</Typography>}
      </List>
      {errorMsg && <FormHelperText error>{errorMsg}</FormHelperText>}
      <Box display="flex" justifyContent="flex-end">
        <Fade in={value.length > 0}>
          <Button style={{ marginRight: 8 }} color="secondary" onClick={onClearAll} size="small">
            Clear All
          </Button>
        </Fade>
        <Button onClick={onAddClick} startIcon={<Add />} variant="outlined" color="primary" size="small">
          Add Dates
        </Button>
      </Box>
      <DateRangeFormDialog value={dateInEdit} onConfirm={onConfirm} onCancel={onCancel} />
    </div>
  );
});

export default DateRangesInput;
