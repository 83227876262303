/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormControl, FormHelperText, FormLabel, makeStyles, MenuItem, Select } from "@material-ui/core";
import clsx from "clsx";
import React from "react";

const useStyles = makeStyles(() => ({
  container: {
    maxWidth: "100%",
  },
}));

interface Option {
  disabled?: boolean;
  label: React.ReactNode;
  style?: any;
  value: string | number;
}

interface DropdownSelectProps {
  ariaDescribedby?: string;
  autoWidth?: boolean;
  className?: string;
  disabled?: boolean;
  disableUnderline?: boolean;
  dropdownMargin?: string;
  error?: boolean;
  fullWidth?: boolean;
  helperText?: string;
  label?: string;
  onChange?: (value: any) => void;
  options?: Option[];
  renderValue?: (value: unknown) => React.ReactNode;
  value?: string;
  variant?: string;
}

const DropdownSelect = React.forwardRef<HTMLDivElement, DropdownSelectProps>(
  (
    {
      ariaDescribedby = "dropdown-select",
      autoWidth = false,
      disabled = false,
      disableUnderline = false,
      dropdownMargin = "none",
      error = false,
      fullWidth = false,
      options = [],
      onChange,
      value,
      renderValue,
      className,
      label,
      helperText,
      variant = "outlined",
    },
    ref,
  ) => {
    const classes = useStyles();

    return (
      <FormControl className={clsx(classes.container, className)} error={error} fullWidth={fullWidth}>
        {label && <FormLabel style={{ marginBottom: 4 }}>{label}</FormLabel>}
        <Select
          aria-describedby={ariaDescribedby}
          autoWidth={autoWidth}
          disabled={disabled}
          disableUnderline={disableUnderline}
          inputRef={ref}
          margin={dropdownMargin as "none" | "dense"}
          onChange={onChange}
          renderValue={renderValue}
          value={value || ""}
          variant={variant as "filled" | "outlined" | "standard"}
        >
          {options.map(option => (
            <MenuItem key={option.value} disabled={Boolean(option.disabled)} style={option.style} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
        {helperText && (
          <FormHelperText error id={ariaDescribedby}>
            {helperText}
          </FormHelperText>
        )}
      </FormControl>
    );
  },
);

export default DropdownSelect;
