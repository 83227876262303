import { gql, useMutation, useQuery } from "@apollo/client";
import { FEATURE_B2B_SHOW_PAYMENT_SETTINGS } from "@arowana/flags";
import { Supplier } from "@arowana/graphql";
import { OnWheelBlur } from "@arowana/ui";
import { DATALAYER } from "@arowana/util";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  Link,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Link as RouterLink } from "react-router-dom";

import { notificationVar } from "../../../cache/notificationPolicy";
import ConfirmationModal from "../../../components/ConfirmationModal";
import DropdownSelect from "../../../components/DropdownSelect";
import FormCard from "../../../components/FormCard";
import FormTooltip from "../../../components/FormTooltip";
import Loader from "../../../components/Loader";
import NetTerms from "../../../components/NetTerms";
import PageHeader from "../../../components/PageHeader";
import Routes from "../../../Constants/Routes";
import Blacklist from "../../account/components/Blacklist";
import { AccountContext } from "../../context/AccountContext";
import { FlagsmithContext } from "../../context/FlagsmithContext";

const STRIPE_DASHBOARD_URL = "https://dashboard.stripe.com";

const CONNECT_STRIPE = gql`
  mutation connectStripe {
    supplierConnectStripe
  }
`;

const PAYMENT_SETTINGS = gql`
  query PaymentSettings {
    currentSupplier {
      id
      currency
      defaultNetTerms
      locations {
        id
        cutOffHour
        orderChargeTime
      }
      paymentSettings {
        stripeAccountId
        retailProcessingPercent
        wholesaleProcessingPercent
      }
      paymentHoldMultiplier
      taxableProductsList {
        id
        name
      }
      wholesalePaymentSettings {
        creditCardEnabled
        creditCardRequiredOnSignup
      }
    }
  }
`;

const UPDATE_PAYMENT_SETTINGS = gql`
  mutation UpdatePaymentSettings($input: PaymentSettingsInput!) {
    supplierUpdatePaymentSettings(input: $input) {
      id
      locations {
        id
        cutOffHour
        orderChargeTime
      }
      paymentSettings {
        stripeAccountId
        retailProcessingPercent
        wholesaleProcessingPercent
      }
      paymentHoldMultiplier
      taxableProductsList {
        id
        name
      }
    }
  }
`;

const UPDATE_WHOLESALE_PAYMENT_SETTINGS = gql`
  mutation UpdateWholesalePaymentSettings($input: WholesalePaymentSettingsInput!) {
    supplierUpdateWholesalePaymentSettings(input: $input) {
      creditCardEnabled
      creditCardRequiredOnSignup
    }
  }
`;

const HOUR_OPTIONS = [...Array(12).keys()].map(i => ({ label: i + 1, value: i + 1 }));
const MINUTE_OPTIONS = [{ label: "00", value: "00" }];
for (let i = 1; i < 4; i++) {
  const value = `${15 * i}`;
  MINUTE_OPTIONS.push({ label: value, value });
}
const CLOCK_OPTIONS = [
  { label: "AM", value: "AM" },
  { label: "PM", value: "PM" },
];
const getOrderChargeTime = (time: string): string[] => {
  const [hour, minuteAndClock] = time.split(":");
  const minute = minuteAndClock.substring(0, 2);
  const clock = minuteAndClock.substring(2);

  return [hour, minute, clock];
};

const useStyles = makeStyles(theme => ({
  hideB2C: { display: "none" },
  orderChargeTime: {
    alignItems: "center",
    display: "flex",
    gap: theme.spacing(1),
  },
  spacing: { marginTop: theme.spacing(2) },
  timeSplit: { fontSize: theme.typography.h6.fontSize },
}));

const Payment = () => {
  const classes = useStyles();
  const [openWarningModal, setOpenWarningModal] = useState(false);
  const {
    control,
    register,
    handleSubmit,
    errors,
    formState: { isDirty, dirtyFields },
    reset,
    getValues,
  } = useForm({
    defaultValues: {
      creditCardEnabled: false,
      creditCardRequiredOnSignup: false,
      currency: "",
      orderChargeClock: "",
      orderChargeHour: "",
      orderChargeMinute: "",
      paymentHoldMultiplier: "",
      retailProcessingPercent: 0,
      taxableProductsList: {},
      wholesaleProcessingPercent: 0,
    },
  });
  const flagsmith = useContext(FlagsmithContext);
  const hasRetail = flagsmith.hasFeature("b2c");
  const hasWholesale = flagsmith.hasFeature("b2b");
  const showWholesalePaymentSettings = flagsmith.hasFeature(FEATURE_B2B_SHOW_PAYMENT_SETTINGS);

  const [savePayment, { loading: isSavingPayment }] = useMutation(UPDATE_PAYMENT_SETTINGS, {
    context: { source: DATALAYER },
    onCompleted: () => {
      notificationVar({
        message: "Settings updated!",
        severity: "success",
      });
    },
    refetchQueries: ["PaymentSettings"],
  });

  const { data: paymentSettingsData, loading: isLoadingPaymentSettings } = useQuery<{ currentSupplier?: Supplier }>(
    PAYMENT_SETTINGS,
    {
      context: { source: DATALAYER },
    },
  );
  const hasStripe = Boolean(paymentSettingsData?.currentSupplier?.paymentSettings?.stripeAccountId);
  const hasLocation = paymentSettingsData?.currentSupplier?.locations?.length > 0;
  const maxCutOffHour = hasLocation
    ? Math.max(...paymentSettingsData.currentSupplier.locations.map(({ cutOffHour }) => cutOffHour))
    : null;

  const [updateWholesaleSettings, { loading: isSavingWholesale }] = useMutation(UPDATE_WHOLESALE_PAYMENT_SETTINGS, {
    context: { source: DATALAYER },
    refetchQueries: ["PaymentSettings"],
  });

  useEffect(() => {
    const data = paymentSettingsData?.currentSupplier;

    if (data) {
      const [orderChargeHour, orderChargeMinute, orderChargeClock] = getOrderChargeTime(
        data.locations?.[0]?.orderChargeTime,
      );

      reset(
        {
          creditCardEnabled: Boolean(data.wholesalePaymentSettings?.creditCardEnabled),
          creditCardRequiredOnSignup: Boolean(data.wholesalePaymentSettings?.creditCardRequiredOnSignup),
          currency: data.currency?.toUpperCase() ?? "",
          orderChargeClock,
          orderChargeHour,
          orderChargeMinute,
          paymentHoldMultiplier: data.paymentHoldMultiplier ?? "",
          retailProcessingPercent: data.paymentSettings.retailProcessingPercent,
          taxableProductsList: data.taxableProductsList ?? {},
          wholesaleProcessingPercent: data.paymentSettings.wholesaleProcessingPercent,
        },
        {
          dirtyFields: false,
        },
      );
    }
  }, [reset, paymentSettingsData]);

  const handleDiscardClick = () => reset();
  const onSubmit = data => {
    const { orderChargeHour, orderChargeMinute, orderChargeClock, paymentHoldMultiplier, taxableProductsList } =
      dirtyFields;
    const orderChargeTimeChanged = orderChargeHour || orderChargeMinute || orderChargeClock;

    savePayment({
      variables: {
        input: {
          ...(orderChargeTimeChanged && {
            orderChargeTime: `${data.orderChargeHour}:${data.orderChargeMinute}${data.orderChargeClock}`,
          }),
          ...(paymentHoldMultiplier && { paymentHoldMultiplier: parseFloat(data.paymentHoldMultiplier) }),
          ...(taxableProductsList && {
            taxableProductsList: {
              _remove: !data.taxableProductsList.id,
              taxableProductsListId: data.taxableProductsList.id ?? null,
            },
          }),
          retailProcessingPercent: data.retailProcessingPercent,
          wholesaleProcessingPercent: data.wholesaleProcessingPercent,
        },
      },
    });

    if (hasWholesale) {
      updateWholesaleSettings({
        variables: {
          input: {
            creditCardEnabled: data.creditCardEnabled,
            creditCardRequiredOnSignup: data.creditCardRequiredOnSignup,
          },
        },
      });
    }
  };

  const onPreSubmit = () => {
    const { orderChargeHour, orderChargeMinute, orderChargeClock } = dirtyFields;
    const orderChargeTimeChanged = orderChargeHour || orderChargeMinute || orderChargeClock;

    if (orderChargeTimeChanged && hasLocation) {
      // show warning if order charge hour is before some location's cut off hour
      const orderChargeHourValue = getValues("orderChargeHour");
      const orderChargeClockValue = getValues("orderChargeClock");
      const chargeHour = parseInt(moment(`${orderChargeHourValue}${orderChargeClockValue}`, ["hA"]).format("H"));

      if (chargeHour >= maxCutOffHour) {
        setOpenWarningModal(true);
      } else {
        handleSubmit(onSubmit)();
      }
    } else {
      handleSubmit(onSubmit)();
    }
  };

  const onConfirmSubmit = () => {
    setOpenWarningModal(false);
    handleSubmit(onSubmit)();
  };

  const [connectStripe, { loading }] = useMutation(CONNECT_STRIPE, {
    context: { source: DATALAYER },
    onCompleted: data => {
      const url = data.supplierConnectStripe;

      if (url) {
        window.location.href = url;
      }
    },
  });

  const handleChangeDefaultNetTerms = (defaultNetTerms: number) => {
    const variables = {
      input: {
        defaultNetTerms,
      },
    };
    savePayment({ variables });
  };

  return (
    <>
      <Loader loading={isSavingPayment || isLoadingPaymentSettings || isSavingWholesale} />
      <PageHeader
        primaryActions={
          <>
            <Button color="primary" disabled={!isDirty} onClick={handleDiscardClick} variant="outlined">
              Discard
            </Button>
            <Button color="primary" disabled={!isDirty} onClick={onPreSubmit} variant="contained">
              Save
            </Button>
          </>
        }
        stickyHeader
        title="Payments"
      />
      <Grid container spacing={2}>
        <Grid item md={8} xs={12}>
          <FormCard title="General">
            <Grid container spacing={2}>
              <Grid item md={6} xs={12}>
                <FormControl fullWidth margin="none">
                  <FormLabel htmlFor="currency">Store currency</FormLabel>
                  <TextField
                    aria-describedby="currency-helper"
                    disabled
                    id="currency"
                    inputRef={register}
                    margin="normal"
                    name="currency"
                    size="small"
                    variant="outlined"
                  />
                  <FormHelperText id="currency-helper">
                    This is the currency your customers will be charged in.
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item md={6} xs={12} className={!hasRetail ? classes.hideB2C : ""}>
                <FormControl fullWidth margin="none">
                  <FormLabel htmlFor="paymentHoldMultiplier">Payment hold multiplier</FormLabel>
                  <TextField
                    InputProps={{
                      inputProps: {
                        min: 1,
                        step: 0.01,
                      },
                      onWheel: OnWheelBlur,
                    }}
                    aria-describedby="paymentHoldMultiplier-helper"
                    error={Boolean(errors?.paymentHoldMultiplier)}
                    helperText={errors?.paymentHoldMultiplier?.message}
                    id="paymentHoldMultiplier"
                    inputRef={register({
                      validate: value => parseFloat(value) >= 1 || "*the multiplier must be at least 1",
                    })}
                    margin="normal"
                    name="paymentHoldMultiplier"
                    size="small"
                    type="number"
                    variant="outlined"
                  />
                  <FormHelperText id="paymentHoldMultiplier-helper">
                    All customer order totals will be multiplied by this amount when placing a hold on their card to
                    account for weight adjustments that occur after the order.
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
          </FormCard>

          <FormCard title="Order processing fees">
            <Grid container spacing={2}>
              {hasRetail && (
                <Grid item md={6} xs={12}>
                  <FormControl margin="none">
                    <FormLabel htmlFor="retailProcessingPercent">Percentage for retail orders</FormLabel>
                    <TextField
                      error={Boolean(errors?.retailProcessingPercent)}
                      helperText={errors?.retailProcessingPercent?.message}
                      id="retailProcessingPercent"
                      inputRef={register({
                        max: {
                          message: "Percentage should be under 100",
                          value: 100,
                        },
                        min: {
                          message: "Percentage should be above 0",
                          value: 0,
                        },
                        required: "*required",
                        valueAsNumber: true,
                      })}
                      InputProps={{
                        endAdornment: "%",
                        inputProps: {
                          step: 1,
                        },
                        onWheel: OnWheelBlur,
                      }}
                      margin="normal"
                      name="retailProcessingPercent"
                      size="small"
                      type="number"
                      variant="outlined"
                    />
                  </FormControl>
                </Grid>
              )}
              {hasWholesale && (
                <Grid item md={6} xs={12}>
                  <FormControl margin="none">
                    <FormLabel htmlFor="wholesaleProcessingPercent">Percentage for wholesale orders</FormLabel>
                    <TextField
                      error={Boolean(errors?.wholesaleProcessingPercent)}
                      helperText={errors?.wholesaleProcessingPercent?.message}
                      id="wholesaleProcessingPercent"
                      inputRef={register({
                        max: {
                          message: "Percentage should be under 100",
                          value: 100,
                        },
                        min: {
                          message: "Percentage should be above 100",
                          value: 0,
                        },
                        required: "*required",
                        valueAsNumber: true,
                      })}
                      InputProps={{
                        endAdornment: "%",
                        inputProps: {
                          step: 1,
                        },
                        onWheel: OnWheelBlur,
                      }}
                      margin="normal"
                      name="wholesaleProcessingPercent"
                      size="small"
                      type="number"
                      variant="outlined"
                    />
                  </FormControl>
                </Grid>
              )}
              <Grid item xs={12}>
                <Typography color="textSecondary" variant="caption">
                  This fee will be added on all orders and calculated based on the cart subtotal. This can be used for
                  charging your customers credit card processing fees, percentage-based handling fees, etc.
                </Typography>
              </Grid>
            </Grid>
          </FormCard>

          {hasWholesale && showWholesalePaymentSettings && (
            <FormCard title="Accepted wholesale payment methods">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Payment methods</TableCell>
                    <TableCell align="center">Enabled</TableCell>
                    <TableCell align="center">Require on registration</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>Credit Card</TableCell>
                    <TableCell align="center">
                      <Controller
                        control={control}
                        defaultValue={false}
                        name="creditCardEnabled"
                        render={props => (
                          <Checkbox
                            {...props}
                            checked={props.value}
                            onChange={e => props.onChange(e.currentTarget.checked)}
                            color="primary"
                          />
                        )}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <Controller
                        control={control}
                        defaultValue={false}
                        name="creditCardRequiredOnSignup"
                        render={props => (
                          <Checkbox
                            {...props}
                            checked={props.value}
                            onChange={e => props.onChange(e.currentTarget.checked)}
                            color="primary"
                          />
                        )}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <Box mt={2}>
                <Typography color="textSecondary" variant="caption">
                  Choose which payment methods to enable for your wholesale store. Enable{" "}
                  <strong>Require on registration</strong> to require specific methods during wholesale customer
                  registration.
                </Typography>
              </Box>
            </FormCard>
          )}

          <FormCard className={!hasRetail ? classes.hideB2C : ""} title="Taxable products">
            <Typography gutterBottom>
              Select a{" "}
              <Link component={RouterLink} to={Routes.LISTS}>
                collection of products to apply regional tax rules to.
              </Link>{" "}
              <FormTooltip content="Add products to your taxable collection to automatically compute regional taxes for that item on checkout." />
            </Typography>
            <Controller as={<Blacklist />} control={control} name="taxableProductsList" />
          </FormCard>
        </Grid>

        <Grid item md={4} xs={12}>
          <FormCard title="Subscriptions and billing">
            <Typography>
              Looking to modify or cancel your Freshline subscription? Please email{" "}
              <Link href="mailto:support@freshline.io?subject=Account modification requested!">
                support@freshline.io.
              </Link>
            </Typography>
          </FormCard>

          <FormCard title="Stripe connection">
            <Typography gutterBottom>
              Connect or create a Stripe Account to start accepting payments and receive bank payouts
            </Typography>

            <Grid container spacing={2}>
              <Grid item>
                <Button
                  onClick={() => connectStripe()}
                  disabled={loading}
                  endIcon={loading ? <CircularProgress color="inherit" size={22} /> : <ExitToAppIcon />}
                  variant="contained"
                >
                  {hasStripe ? "Update Stripe connection" : "Connect with Stripe"}
                </Button>
              </Grid>
              {hasStripe && (
                <Grid item>
                  <Button
                    component="a"
                    endIcon={<ExitToAppIcon />}
                    href={STRIPE_DASHBOARD_URL}
                    target="_blank"
                    variant="contained"
                  >
                    Stripe Dashboard
                  </Button>
                </Grid>
              )}
            </Grid>
          </FormCard>
          <FormCard title="Payment charge time">
            <Box className={classes.orderChargeTime}>
              <Controller
                as={<DropdownSelect />}
                control={control}
                name="orderChargeHour"
                options={HOUR_OPTIONS}
                rules={{ required: "*required" }}
              />
              <strong className={classes.timeSplit}> &#58; </strong>
              <Controller
                as={<DropdownSelect />}
                control={control}
                name="orderChargeMinute"
                options={MINUTE_OPTIONS}
                rules={{ required: "*required" }}
              />
              <Controller
                as={<DropdownSelect />}
                control={control}
                name="orderChargeClock"
                options={CLOCK_OPTIONS}
                rules={{ required: "*required" }}
              />
            </Box>
            <Box mt={2}>
              <Typography color="textSecondary" variant="caption">
                Choose what time pre-authorized orders should be charged. Please note that automated charges are only
                available for retail (B2C) orders.
              </Typography>
            </Box>
          </FormCard>
          {hasWholesale && (
            <FormCard title="Default net terms">
              <NetTerms
                fullWidth
                loading={isSavingPayment}
                onChange={handleChangeDefaultNetTerms}
                value={paymentSettingsData?.currentSupplier?.defaultNetTerms ?? ""}
              />
              <Box mt={2}>
                <Typography color="textSecondary" variant="caption">
                  Specify the default net terms applied to your wholesale customers. Please note that you can customize
                  this value for each wholesale customer via the{" "}
                  <Link component={RouterLink} to={Routes.WHOLESALE_CLIENTS}>
                    Businesses
                  </Link>{" "}
                  page.
                </Typography>
              </Box>
            </FormCard>
          )}
        </Grid>
      </Grid>

      <ConfirmationModal
        cancelRequestButtonText="Cancel"
        confirmRequestButtonText="Save Changes"
        isDangerAction
        modalTitle="Warning"
        onCloseModal={() => setOpenWarningModal(false)}
        onConfirmClick={onConfirmSubmit}
        shouldOpenModal={openWarningModal}
      >
        <Typography>
          The chosen charge time is earlier than the order cutoff time for one or more service locations. This may lead
          to late payment processing.
        </Typography>
      </ConfirmationModal>
    </>
  );
};

export default Payment;
