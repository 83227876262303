/* eslint-disable @typescript-eslint/no-explicit-any */
import { OnWheelBlur } from "@arowana/ui";
import { unitLabel } from "@arowana/util";
import {
  alpha,
  Box,
  Checkbox,
  IconButton,
  InputAdornment,
  Link,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  ArrowDownward as ArrowDownwardIcon,
  ArrowUpward as ArrowUpwardIcon,
  Check,
  Delete as DeleteIcon,
} from "@material-ui/icons";
import clsx from "clsx";
import { forwardRef, useCallback, useEffect, useMemo, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { FormProvider, useFormContext, UseFormMethods } from "react-hook-form";
import { Link as RouterLink, useHistory } from "react-router-dom";

import FormTooltip from "../../../components/FormTooltip";
import Routes from "../../../Constants/Routes";
import VariantAutocomplete, { VariantSearch } from "../../orders/component/VariantAutocomplete";
import { RecurringOverrideFieldArrayProps } from "../../recurring/pages/RecurringListDetails";
import { OverrideFieldArrayProps, OverrideFormProps } from "../pages/List";

export enum FormMode {
  RECURRING_ORDER_CREATE,
  RECURRING_ORDER_UPDATE,
  WHOLESALE,
}
const useStyles = makeStyles(theme => ({
  "@keyframes blinking": {
    "50%": { backgroundColor: alpha(theme.palette.primary.main, 0.4) },
  },
  "empty": {
    backgroundColor: theme.palette.grey[200],
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1, 0),
  },
  "highlight": {
    animation: "$blinking 1s ease",
    animationIterationCount: 6,
  },
  "lastRow": {
    "&:last-child td": {
      borderBottom: "none",
    },
  },
  "searchProducts": { marginBottom: theme.spacing(2) },
  "sortIcon": {
    "&:hover": {
      cursor: "pointer",
    },
    "verticalAlign": "sub",
  },
  "sortableField": {
    color: theme.palette.primary.main,
  },
  "tableContainer": {
    maxHeight: 500,
  },
}));

type ProductRowProps = {
  errors: UseFormMethods["errors"];
  mode: FormMode;
  index: number;
  product: OverrideFieldArrayProps | RecurringOverrideFieldArrayProps;
  removeProduct: (index: number) => void;
  showHighlight: boolean;
};

const ProductRow = forwardRef(function ProductRow(
  { errors, index, mode, product, removeProduct, showHighlight = false, ...props }: ProductRowProps,
  ref,
) {
  const classes = useStyles();
  const { register } = useFormContext();
  const onRemove = useCallback(() => removeProduct(index), [removeProduct, index]);
  const allowDelete = mode === FormMode.WHOLESALE || !product.overrideId;

  return (
    <TableRow
      id={product.overrideId ?? `new-item-${index}`}
      className={clsx(classes.lastRow, { [classes.highlight]: showHighlight })}
      ref={ref}
      {...props}
    >
      <input
        type="hidden"
        inputRef={register({
          valueAsNumber: true,
        })}
        name={`overrides[${index}].sortOrder`}
        defaultValue={index}
      />
      <TableCell style={{ minWidth: 150 }}>
        <Link component={RouterLink} to={Routes.PRODUCT.replace(":id", product.productId)}>
          <Typography>{product.productName}</Typography>
        </Link>
        <Typography variant="caption">{product.variantName}</Typography>
      </TableCell>

      {mode === FormMode.WHOLESALE && (
        <TableCell>
          <Checkbox
            color="primary"
            defaultChecked={Boolean((product as OverrideFieldArrayProps).featured)}
            inputRef={register()}
            name={`overrides[${index}].featured`}
          />
        </TableCell>
      )}

      {mode === FormMode.WHOLESALE && (
        <TableCell>
          <Checkbox
            color="primary"
            defaultChecked={Boolean((product as OverrideFieldArrayProps).hidePrice)}
            inputRef={register()}
            name={`overrides[${index}].hidePrice`}
          />
        </TableCell>
      )}

      <TableCell style={{ minWidth: 220, width: 220 }}>
        <TextField
          error={Boolean(errors?.overrides?.[index]?.price)}
          InputProps={{
            endAdornment: <InputAdornment position="end">per {unitLabel(product.unit)}</InputAdornment>,
            inputProps: {
              min: 0,
              step: 0.01,
            },
            onWheel: OnWheelBlur,
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          defaultValue={product.price ?? 0}
          fullWidth
          helperText={errors?.overrides?.[index]?.price?.message}
          inputRef={register({
            required: "*required",
            valueAsNumber: true,
          })}
          name={`overrides[${index}].price`}
          size="small"
          type="number"
          variant="outlined"
        />
      </TableCell>

      <TableCell>
        <Typography>{product.caseSize}</Typography>
      </TableCell>

      <TableCell>{product.active && <Check color="primary" />}</TableCell>

      <TableCell align="center">
        {allowDelete && (
          <IconButton edge="end" onClick={onRemove} title="Remove newly added">
            <DeleteIcon fontSize="small" />
          </IconButton>
        )}
      </TableCell>

      {/* hidden fields for useFieldArray field registration */}
      {/* using Controller can get rid of these but will cause a laggy form update! */}
      <td style={{ display: "none" }}>
        <input
          type="checkbox"
          name={`overrides[${index}].active`}
          ref={register()}
          defaultChecked={product.active ?? false}
        />
        <input
          type="number"
          name={`overrides[${index}].caseSize`}
          ref={register()}
          defaultValue={product.caseSize ?? 0}
        />
        <input
          type="text"
          name={`overrides[${index}].overrideId`}
          ref={register()}
          defaultValue={product.overrideId ?? ""}
        />
        <input
          type="text"
          name={`overrides[${index}].productId`}
          ref={register()}
          defaultValue={product.productId ?? ""}
        />
        <input
          type="text"
          name={`overrides[${index}].productName`}
          ref={register()}
          defaultValue={product.productName ?? ""}
        />
        <input type="text" name={`overrides[${index}].unit`} ref={register()} defaultValue={product.unit ?? ""} />
        <input
          type="text"
          name={`overrides[${index}].variantId`}
          ref={register()}
          defaultValue={product.variantId ?? ""}
        />
        <input
          type="text"
          name={`overrides[${index}].variantName`}
          ref={register()}
          defaultValue={product.variantName ?? ""}
        />
      </td>
    </TableRow>
  );
});

type OverridesListProps = {
  errors: UseFormMethods["errors"];
  mode: FormMode;
  overrides: (OverrideFieldArrayProps | RecurringOverrideFieldArrayProps)[];
  prepend: (override: OverrideFormProps) => void;
  register: UseFormMethods["register"];
  remove: (index?: number) => void;
};

const OverridesList = ({ errors, mode, overrides, prepend, register, remove, move }: OverridesListProps) => {
  const classes = useStyles();
  const filterIds = new Set(overrides.map(({ variantId }) => variantId));
  const fieldIdIndexMap = useMemo(() => {
    const record = new Map();
    overrides.forEach(({ fieldId }, index) => record.set(fieldId, index));

    return record;
  }, [overrides]);
  const handleDrag = ({ source, destination }) => {
    if (destination) {
      move(source.index, destination.index);
    }
  };

  const onAddProduct = (productToAdd: VariantSearch): void => {
    const {
      active,
      case_size: caseSize,
      id: variantId,
      name: variantName,
      price,
      product_id: productId,
      product_name: productName,
      unit,
    } = productToAdd;

    prepend({
      active,
      caseSize,
      ...(mode === FormMode.WHOLESALE && { hidePrice: false }),
      overrideId: undefined,
      price: parseFloat((price / 100).toFixed(2)),
      productId,
      productName,
      unit,
      variantId,
      variantName,
    });
  };

  const history = useHistory();
  const hashId = history?.location?.hash;
  const hasHash = Boolean(history?.location?.hash);

  useEffect(() => {
    if (hasHash && overrides?.length > 0) {
      const listEl = document.querySelector("#override-list");

      if (listEl) {
        listEl.scrollIntoView({ block: "end" });
        listEl.querySelector(`.${classes.highlight}`)?.scrollIntoView({ block: "end" });
      }

      setTimeout(() => {
        history.replace({ search: null });
      }, 5000);
    }
  }, [history, hasHash, overrides]);

  const targetText: { [key in FormMode]: string } = {
    [FormMode.RECURRING_ORDER_CREATE]: "subscription collection",
    [FormMode.RECURRING_ORDER_UPDATE]: "subscription collection",
    [FormMode.WHOLESALE]: "price list",
  };

  return (
    <>
      <VariantAutocomplete
        className={classes.searchProducts}
        excludeVariantIds={filterIds}
        helperText={`Search for a product and select it from the dropdown to add it to your ${targetText[mode]}.`}
        label={`Add products to ${targetText[mode]}`}
        onChange={onAddProduct}
      />

      <DragDropContext onDragEnd={handleDrag}>
        <TableContainer id="override-list" className={classes.tableContainer}>
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Product</TableCell>
                {mode === FormMode.WHOLESALE && <TableCell>Featured item</TableCell>}
                {mode === FormMode.WHOLESALE && (
                  <TableCell>
                    Market price item
                    <FormTooltip content="Checking this box will replace the item's unit price with “Market Price” on your store. The finalized price, confirmed via the order details page, will be shown to customers after the order is fulfilled." />
                  </TableCell>
                )}
                <TableCell>Price</TableCell>
                <TableCell>Case size</TableCell>
                <TableCell>Display on store</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <FormProvider register={register}>
              <Droppable droppableId="pvo-list">
                {(provided, snapshot) => (
                  <TableBody {...provided.droppableProps} ref={provided.innerRef}>
                    {overrides.map((override, index) => {
                      return (
                        <Draggable key={override.fieldId} draggableId={override.fieldId} index={index}>
                          {(provided, snapshot) => (
                            <ProductRow
                              errors={errors}
                              index={index}
                              key={override.fieldId}
                              mode={mode}
                              product={override}
                              removeProduct={remove}
                              showHighlight={hashId?.indexOf(override.overrideId) > 0}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            />
                          )}
                        </Draggable>
                      );
                    })}
                  </TableBody>
                )}
              </Droppable>
            </FormProvider>
          </Table>
          {overrides.length === 0 && (
            <Box className={classes.empty} textAlign="center">
              <Typography>No products selected</Typography>
            </Box>
          )}
        </TableContainer>
      </DragDropContext>
    </>
  );
};

export default OverridesList;
