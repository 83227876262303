import { gql, useMutation, useQuery } from "@apollo/client";
import { DATALAYER } from "@arowana/util";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Fade,
  LinearProgress,
  Link,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import moment from "moment-timezone";
import { useContext, useMemo, useRef, useState } from "react";
import { Link as RouterLink } from "react-router-dom";

import { notificationVar } from "../../../cache/notificationPolicy";
import ConfirmationModal from "../../../components/ConfirmationModal";
import FormCard from "../../../components/FormCard";
import FormTooltip from "../../../components/FormTooltip";
import PageHeader from "../../../components/PageHeader";
import TipBanner from "../../../components/TipBanner";
import Routes from "../../../Constants/Routes";
import { AccountContext } from "../../context/AccountContext";
import { FlagsmithContext } from "../../context/FlagsmithContext";
import ListAutocomplete from "../components/ListAutocomplete";

const LISTS = gql`
  query SupplierLists($pagination: PaginationInput) {
    currentSupplier {
      id
      homepageListId
      lists(page: $pagination) {
        edges {
          cursor
          node {
            id
            name
            description
            updatedAt
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          totalCount
        }
      }
    }
  }
`;

const UPDATE_HOMEPAGE_LIST = gql`
  mutation UpdateHomepageList($listId: ID) {
    supplierUpdateHomepageList(id: $listId) {
      id
      name
    }
  }
`;

const useStyles = makeStyles(theme => ({
  empty: {
    backgroundColor: theme.palette.grey[200],
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1, 0),
  },
  table: {
    minWidth: 500,
  },
  tableContainer: {
    margin: theme.spacing(2, 0),
    maxHeight: 500,
  },
}));
const ROWS_PER_PAGE = 20;

const ListRow = ({ list, timezone, isFeatured, loading, onFeatureChecked }) => {
  const onCheckboxChange = () => onFeatureChecked(isFeatured ? null : list);

  return (
    <TableRow>
      <TableCell>
        <Typography variant="subtitle2">
          <Link component={RouterLink} to={`/lists/${list.id}`}>
            {list.name}
          </Link>
        </Typography>
      </TableCell>
      <TableCell align="center">
        {loading ? (
          <Box alignItems="center" display="flex" height={42} justifyContent="center">
            <CircularProgress size={23} />
          </Box>
        ) : (
          <Checkbox checked={isFeatured} color="primary" onChange={onCheckboxChange} />
        )}
      </TableCell>
      <TableCell>{moment.tz(list.updatedAt, timezone).format("lll")}</TableCell>
    </TableRow>
  );
};

const ListList = ({ history }) => {
  const classes = useStyles();
  const tableRef = useRef(null);
  const { supplier } = useContext(AccountContext);
  const flagsmith = useContext(FlagsmithContext);
  const hasRetail = flagsmith.hasFeature("b2c");
  const { timezone } = supplier;

  const [showHomepageConfirmation, setShowHomepageConfirmation] = useState(false);
  const [selectedHomepageList, setSelectedHomepageList] = useState();

  const { data, fetchMore, loading } = useQuery(LISTS, {
    context: { source: DATALAYER },
    fetchPolicy: "cache-and-network",
    onCompleted: response => {
      const { lists, homepageListId } = response?.currentSupplier ?? {};
      setSelectedHomepageList(lists?.edges?.find(({ node }) => node.id === homepageListId)?.node);
    },
    variables: {
      pagination: {
        first: ROWS_PER_PAGE,
      },
    },
  });

  const { listsInTable, hasNextPage, endCursor } = useMemo(() => {
    const { lists } = data?.currentSupplier ?? {};
    const listsInTable = lists?.edges?.map(({ node }) => node) ?? [];
    const { endCursor, hasNextPage } = lists?.pageInfo ?? {};

    return {
      endCursor,
      hasNextPage,
      listsInTable,
    };
  }, [data]);
  const handleViewMoreClick = async () => {
    await fetchMore({
      variables: {
        pagination: {
          after: endCursor,
          first: ROWS_PER_PAGE,
        },
      },
    });

    tableRef.current?.scrollIntoView({ behavior: "smooth", block: "end" });
  };

  const [updateHomepageList, { loading: updating }] = useMutation(UPDATE_HOMEPAGE_LIST, {
    context: { source: DATALAYER },
    onCompleted: response => {
      const name = response?.supplierUpdateHomepageList?.name;

      notificationVar({
        message: name ? `${name} is now featured on Homepage` : `No collection is featured on Homepage`,
        severity: "success",
      });
    },
    refetchQueries: ["SupplierLists"],
  });
  const closeHomepageConfirmation = () => setShowHomepageConfirmation(false);
  const onFeatureChecked = list => {
    setSelectedHomepageList(list);
    setShowHomepageConfirmation(true);
  };
  const onCancelHomepageList = () => {
    setSelectedHomepageList(listsInTable?.find(({ id }) => id === data?.currentSupplier?.homepageListId));
    setShowHomepageConfirmation(false);
  };
  const onConfirmHomepageList = () => {
    setShowHomepageConfirmation(false);
    updateHomepageList({
      variables: {
        listId: selectedHomepageList?.id,
      },
    });
  };

  return (
    <>
      <PageHeader
        primaryActions={
          <Button color="primary" component={RouterLink} to={Routes.CREATE_LIST} variant="contained">
            Create Collection
          </Button>
        }
        stickyHeader
        title="Collections"
      />
      {!hasRetail && (
        <TipBanner
          headerText="Collections are only used for your retail online store."
          bodyText="Please note that if you do not have a retail online store, the collections created won't be accessible to shoppers."
        />
      )}
      <FormCard>
        <ListAutocomplete onChange={list => history.push(`/lists/${list.id}`)} />
        <TableContainer className={classes.tableContainer}>
          <Fade in={loading}>
            <LinearProgress />
          </Fade>
          <Table className={classes.table} ref={tableRef} stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell align="center">
                  Featured on homepage
                  <FormTooltip content="The selected collection will replace the default store home page layout." />
                </TableCell>
                <TableCell>Last updated</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {listsInTable.map(list => (
                <ListRow
                  isFeatured={selectedHomepageList?.id === list.id}
                  key={list.id}
                  list={list}
                  loading={updating && selectedHomepageList?.id === list.id}
                  onFeatureChecked={onFeatureChecked}
                  timezone={timezone}
                />
              ))}
            </TableBody>
          </Table>
          {!loading && listsInTable.length === 0 && (
            <Box className={classes.empty} textAlign="center">
              <Typography>No collections found</Typography>
            </Box>
          )}
        </TableContainer>
        {hasNextPage && (
          <Box textAlign="center">
            <Button color="primary" disabled={loading} onClick={handleViewMoreClick}>
              Load more
            </Button>
          </Box>
        )}
      </FormCard>
      <ConfirmationModal
        cancelRequestButtonText="Cancel"
        confirmRequestButtonText="OK"
        isDangerAction
        modalContent={
          <>
            Are you sure you want to {selectedHomepageList ? "feature" : "unfeature collection"}
            <strong> {selectedHomepageList?.name}</strong> on homepage?
          </>
        }
        modalTitle="Feature on homepage"
        onCancelClick={onCancelHomepageList}
        onCloseModal={closeHomepageConfirmation}
        onConfirmClick={onConfirmHomepageList}
        shouldOpenModal={showHomepageConfirmation}
      />
    </>
  );
};

export default ListList;
