import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { useContext } from "react";

import { SupplierContext } from "../context";
import { SupplierLogo } from "../SupplierLogo";

const useStyles = makeStyles(theme => ({
  actionButton: { width: "50%" },
  cancelOrderContainer: {
    padding: theme.spacing(4),
    [theme.breakpoints.only("xs")]: { padding: theme.spacing(4, 2) },
  },
  cancelOrderDialog: { textAlign: "center" },
  logo: { margin: "auto" },
}));

interface CancelOrderModalProps {
  open: boolean;
  onClose: () => void;
  onCancelOrder: () => void;
}

export const CancelOrderModal = ({ open, onClose, onCancelOrder }: CancelOrderModalProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const supplierData = useContext(SupplierContext);
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));

  return (
    <Dialog
      classes={{ paper: classes.cancelOrderContainer, root: classes.cancelOrderDialog }}
      disableEscapeKeyDown
      fullScreen={isMobile}
      fullWidth
      maxWidth="sm"
      onClose={(_, reason) => {
        if (reason !== "backdropClick") {
          onClose();
        }
      }}
      open={open}
    >
      <SupplierLogo className={classes.logo} src={supplierData?.uiSettings?.logoUrl} />
      <DialogTitle>Cancel Order</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please note that it may take 1-2 business days for the pre-authorized hold to disappear from your statement.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus className={classes.actionButton} color="primary" onClick={onClose} variant="outlined">
          Keep Order
        </Button>
        <Button className={classes.actionButton} color="secondary" onClick={onCancelOrder} variant="contained">
          Cancel Order
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CancelOrderModal;
