import { Redirect, Route, Switch } from "react-router-dom";

import Routes from "../../Constants/Routes";
import Payment from "./pages/Payment";
import PaymentStripeCancel from "./pages/PaymentStripeCancel";
import PaymentStripeSuccess from "./pages/PaymentStripeSuccess";

const Payments = () => (
  <Switch>
    <Route component={Payment} path={Routes.PAYMENTS} />
    <Route component={PaymentStripeCancel} path={Routes.PAYMENTS_STRIPE_CANCEL} />
    <Route component={PaymentStripeSuccess} path={Routes.PAYMENTS_STRIPE_SUCCESS} />
    <Redirect from="/payments/payment" exact to={Routes.PAYMENTS} />
    <Redirect from="/payments/payment/stripe/success" exact to={Routes.PAYMENTS_STRIPE_CANCEL} />
    <Redirect from="/payments/payment/stripe/cancel" exact to={Routes.PAYMENTS_STRIPE_SUCCESS} />
    <Redirect from="*" to={Routes.PAYMENTS} />
  </Switch>
);

export default Payments;
