/* eslint-disable @typescript-eslint/no-explicit-any */
import { TextField } from "@material-ui/core";
import { Autocomplete, AutocompleteChangeDetails, AutocompleteChangeReason, AutocompleteProps } from "@material-ui/lab";
import MeiliSearch, { SearchParams } from "meilisearch";
import { ChangeEvent, ForwardedRef, forwardRef, useEffect, useState } from "react";
import { useDebounce } from "use-debounce";

import SearchAdornment from "./SearchAdornment";

const MEILISEARCH_API_KEY =
  process.env.MEILISEARCH_API_KEY ??
  process.env.NEXT_PUBLIC_MEILISEARCH_API_KEY ??
  process.env.REACT_APP_MEILISEARCH_API_KEY;
const MEILISEARCH_HOST =
  process.env.MEILISEARCH_HOST ?? process.env.NEXT_PUBLIC_MEILISEARCH_HOST ?? process.env.REACT_APP_MEILISEARCH_HOST;
const client = MEILISEARCH_HOST
  ? new MeiliSearch({
      apiKey: MEILISEARCH_API_KEY,
      host: MEILISEARCH_HOST,
    })
  : null;

export type QuickSearchProps<T> = Omit<AutocompleteProps<T, false, false, false>, "onChange"> & {
  index: string;
  label?: string;
  margin?: string;
  searchParams?: SearchParams;
  supplierId?: string;
  onChange: (value: T) => unknown;
} & any;

function identity<T>(value: T): T {
  return value;
}

function QuickSearchInner<T extends Record<string, any>>(
  {
    index,
    label = "Search",
    margin = "normal",
    noOptionsText = "No results found",
    onChange,
    placeholder = "Search here...",
    searchParams = { limit: 5 },
    supplierId,
    ...props
  }: QuickSearchProps<T>,
  ref: ForwardedRef<typeof Autocomplete>,
) {
  const [options, setOptions] = useState<T[]>([]);
  const [inputValue, setInputValue] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [debouncedInput] = useDebounce(inputValue, 300);

  const handleChange = (
    _event: ChangeEvent<HTMLInputElement>,
    value: T,
    reason: AutocompleteChangeReason,
    _details?: AutocompleteChangeDetails<T>,
  ) => {
    if (value && reason === "select-option") {
      onChange(value);
      setInputValue("");
    } else {
      setOptions([]);
    }
  };
  const handleInputChange = (_event: ChangeEvent<HTMLInputElement>, query: string) => setInputValue(query);

  useEffect(() => {
    if (!debouncedInput) {
      setOptions([]);

      return;
    }
    setLoading(true);

    if (!client) {
      console.log("unable to initialize MeiliSearch!");
    }

    client
      .index(index)
      .search(debouncedInput, searchParams)
      .then(({ hits }) => {
        // Ensure search results only return things relevant to the current supplier
        // if `supplierId` was passed to the initializer and "supplier_id" is one of
        // the properties of the hits being returned.
        let searchHits = hits;

        if (supplierId) {
          searchHits = searchHits.filter(h => !("supplier_id" in h) || h?.supplier_id === supplierId);
        }
        setOptions(searchHits as T[]);
      })
      .finally(() => setLoading(false));
  }, [index, debouncedInput, searchParams, supplierId]);

  return (
    <Autocomplete
      ref={ref}
      autoHighlight
      clearOnEscape
      filterOptions={identity} // weird hack, has to do with value being null
      options={options}
      onChange={handleChange}
      onInputChange={handleInputChange}
      inputValue={inputValue}
      noOptionsText={inputValue ? noOptionsText : "Start typing..."}
      loading={loading}
      renderInput={props => (
        <TextField
          {...props}
          variant="outlined"
          label={label}
          margin={margin}
          placeholder={placeholder}
          InputProps={{ ...props.InputProps, startAdornment: <SearchAdornment loading={loading} /> }}
        />
      )}
      value={null}
      {...props}
    />
  );
}

export const QuickSearch = forwardRef(QuickSearchInner);

export default QuickSearch;
