import { getAddressLabels } from "@arowana/util";
import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import { Control, Controller, FieldErrors, useWatch } from "react-hook-form";

import GooglePlacesField from "./GooglePlacesField";

export type AddressFormFields = {
  address1: string;
  address2?: string;
  sublocality?: string;
  city: string;
  country: string;
  label: string;
  notes?: string;
  postalCode: string;
  region: string;
};

type AddressFormProps = {
  addresskey?: string;
  className?: string;
  control: Control<AddressFormFields>;
  country?: string;
  countryCode?: string;
  errors?: FieldErrors;
  htmlId?: string;
  onReset: () => void;
  setValue: (name: string, value: unknown, options?) => void;
  inputClearText?: string;
};

const COUNTRY = {
  CA: "Canada",
  NZ: "New Zealand",
  US: "United States",
};

const getFieldName = (addresskey: string, field: string) => (addresskey ? `${addresskey}.${field}` : field);

export const AddressForm = ({
  addresskey,
  className,
  control,
  country,
  countryCode,
  errors,
  htmlId = "create",
  onReset,
  setValue,
  inputClearText,
}: AddressFormProps) => {
  const defaultCountry = country ?? COUNTRY[countryCode] ?? "";

  const { postalLabel, provinceLabel, sublocalityLabel } = getAddressLabels(
    useWatch({
      control,
      defaultValue: defaultCountry,
      name: getFieldName(addresskey, "country"),
    }),
  );

  const handleSelect = location => {
    setValue(getFieldName(addresskey, "country"), location.country, { shouldDirty: true });
    setValue(getFieldName(addresskey, "address1"), location.streetAddress, { shouldDirty: true });
    setValue(getFieldName(addresskey, "city"), location.city, { shouldDirty: true });
    setValue(getFieldName(addresskey, "postalCode"), location.postalCode, { shouldDirty: true });

    const labels = getAddressLabels(location.country);
    setValue(getFieldName(addresskey, "region"), labels.provinceLabel ? location.province : "", { shouldDirty: true });
    setValue(getFieldName(addresskey, "sublocality"), labels.sublocalityLabel ? location.sublocality : "", {
      shouldDirty: true,
    });
  };

  return (
    <Grid container spacing={2} className={className}>
      <Grid item xs={12}>
        <Controller
          as={TextField}
          control={control}
          fullWidth
          label="Label"
          name={getFieldName(addresskey, "label")}
          required
          rules={{ required: "*required" }}
          variant="outlined"
          defaultValue="Default"
          error={Boolean(errors?.label)}
          helperText={errors?.label?.message}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          as={GooglePlacesField}
          control={control}
          label="Street address"
          name={getFieldName(addresskey, "address1")}
          onReset={onReset}
          onSelect={handleSelect}
          required
          rules={{ required: "*required" }}
          defaultValue=""
          error={Boolean(errors?.address1)}
          helperText={errors?.address1?.message}
          clearText={inputClearText}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          as={TextField}
          control={control}
          fullWidth
          label="Address line 2 (optional)"
          name={getFieldName(addresskey, "address2")}
          variant="outlined"
          defaultValue=""
        />
      </Grid>
      <Grid item xs={6} style={{ display: sublocalityLabel ? "block" : "none" }}>
        <Controller
          as={TextField}
          InputLabelProps={{ shrink: true }}
          control={control}
          fullWidth
          label={sublocalityLabel}
          name={getFieldName(addresskey, "sublocality")}
          variant="outlined"
          defaultValue=""
        />
      </Grid>
      <Grid item xs={6}>
        <Controller
          as={TextField}
          InputLabelProps={{ shrink: true }}
          control={control}
          fullWidth
          label="City"
          name={getFieldName(addresskey, "city")}
          required
          variant="outlined"
          defaultValue=""
        />
      </Grid>
      <Grid item xs={6} style={{ display: provinceLabel ? "block" : "none" }}>
        <Controller
          as={TextField}
          InputLabelProps={{ shrink: true }}
          control={control}
          fullWidth
          label={provinceLabel}
          name={getFieldName(addresskey, "region")}
          required={Boolean(provinceLabel)}
          variant="outlined"
          defaultValue=""
        />
      </Grid>
      <Grid item xs={6}>
        <Controller
          as={TextField}
          InputLabelProps={{ shrink: true }}
          control={control}
          fullWidth
          label={postalLabel}
          name={getFieldName(addresskey, "postalCode")}
          required
          variant="outlined"
          defaultValue=""
        />
      </Grid>
      <Grid item xs={6}>
        <FormControl fullWidth variant="outlined">
          <InputLabel id={`address-country-select-label-${htmlId}`} required>
            Country
          </InputLabel>
          <Controller
            as={
              <Select
                labelId={`address-country-select-label-${htmlId}`}
                id={`address-country-select-${htmlId}`}
                label="Country"
                required
              >
                <MenuItem value={COUNTRY.CA}>{COUNTRY.CA}</MenuItem>
                <MenuItem value={COUNTRY.NZ}>{COUNTRY.NZ}</MenuItem>
                <MenuItem value={COUNTRY.US}>{COUNTRY.US}</MenuItem>
              </Select>
            }
            control={control}
            name={getFieldName(addresskey, "country")}
            defaultValue={defaultCountry}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <Controller
          as={TextField}
          control={control}
          fullWidth
          label="Instructions and notes (optional)"
          maxRows={6}
          multiline
          name={getFieldName(addresskey, "notes")}
          variant="outlined"
          defaultValue=""
        />
      </Grid>
    </Grid>
  );
};

export default AddressForm;
