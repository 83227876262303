import { SupplierUISettingsFaq } from "@arowana/graphql";
import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  makeStyles,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useMemo } from "react";
import ReactHtmlParser from "react-html-parser";

export type FAQAccordionProps = {
  faq: SupplierUISettingsFaq;
} & Omit<AccordionProps, "children">;

const useStyles = makeStyles(theme => ({
  faqAnswer: {
    backgroundColor: theme.palette.action.selected,
  },
  faqContainer: {
    "&:before": { content: "none" },
    "border": "none",
    "borderRadius": theme.shape.borderRadius,
    "boxShadow": "none",
    "marginTop": theme.spacing(1),
    "overflow": "hidden",
  },
  faqTitleBlock: {
    backgroundColor: theme.palette.action.disabledBackground,
  },
}));

export const FAQAccordion = ({ faq, ...props }: FAQAccordionProps) => {
  const classes = useStyles();
  const { answer = "", question = "" } = faq;
  const parsedAnswer = useMemo(() => ReactHtmlParser(answer), [answer]);

  return (
    <Accordion className={classes.faqContainer} {...props}>
      <AccordionSummary className={classes.faqTitleBlock} expandIcon={<ExpandMoreIcon />}>
        <Typography variant="subtitle2">{question}</Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.faqAnswer}>
        <Typography className="ql-content" variant="body2">
          {answer ? parsedAnswer : null}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};

export default FAQAccordion;
