import { makeStyles } from "@material-ui/core";
import { ChevronLeft } from "@material-ui/icons";

import Link, { LinkProps } from "./Link";

type ReturnLinkProps = LinkProps & {
  label: string;
};

const useStyles = makeStyles(theme => ({
  returnLink: {
    "&:hover": {
      color: theme.palette.primary.main,
    },
    "display": "inline-flex",
    "marginBottom": theme.spacing(2),
  },
}));

export const ReturnLink = ({ label, ...rest }: ReturnLinkProps) => {
  const { returnLink } = useStyles();

  return (
    <Link className={returnLink} color="textSecondary" underline="none" {...rest}>
      <ChevronLeft fontSize="small" /> {label}
    </Link>
  );
};

export default ReturnLink;
