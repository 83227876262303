/* eslint-disable @typescript-eslint/no-explicit-any */
import { Link, makeStyles, useTheme } from "@material-ui/core";
import { GoogleMap, InfoBox, MarkerF as Marker, Polygon } from "@react-google-maps/api";
import useResizeObserver from "@react-hook/resize-observer";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDebouncedCallback } from "use-debounce";

const pickTextColorBasedOnBgColor = (bgColor: string, lightColor: string, darkColor: string): string => {
  const color = bgColor.charAt(0) === "#" ? bgColor.substring(1, 7) : bgColor;
  const r = parseInt(color.substring(0, 2), 16); // hexToR
  const g = parseInt(color.substring(2, 4), 16); // hexToG
  const b = parseInt(color.substring(4, 6), 16); // hexToB
  const uicolors = [r / 255, g / 255, b / 255];
  const c = uicolors.map(col => {
    if (col <= 0.03928) {
      return col / 12.92;
    }

    return Math.pow((col + 0.055) / 1.055, 2.4);
  });
  const L = 0.2126 * c[0] + 0.7152 * c[1] + 0.0722 * c[2];

  return L > 0.179 ? darkColor : lightColor;
};

const useStyles = makeStyles(theme => ({
  markerPin: {
    "& > a": {
      color: pickTextColorBasedOnBgColor(theme.palette.primary.main, "white", "black"),
    },
    "&:after": {
      // inner triangle
      borderLeft: "4px solid transparent",
      borderRight: "4px solid transparent",
      borderTop: `4px solid ${theme.palette.primary.main}`,
      content: '""',
      height: 0,
      left: "calc(50% - 4px)",
      position: "absolute",
      top: "calc(100% - 1px)",
      transition: theme.transitions.create("border"),
      width: 0,
      zIndex: 2,
    },
    "&:before": {
      // outter triangle
      borderLeft: "5px solid transparent",
      borderRight: "5px solid transparent",
      borderTop: "5px solid white",
      content: '""',
      height: 0,
      left: "calc(50% - 5px)",
      position: "absolute",
      top: "100%",
      width: 0,
    },
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
      filter: `drop-shadow(0 6px 4px rgba(0, 0, 0, 0.5))`,
      transform: "scale(1.3) translateY(-8px)",
    },
    "&:hover:after": {
      borderTopColor: theme.palette.primary.light,
    },
    "backgroundColor": theme.palette.primary.main,
    "border": "1px solid white",
    "borderRadius": theme.shape.borderRadius,
    "color": pickTextColorBasedOnBgColor(theme.palette.primary.main, "white", "black"),
    "filter": `drop-shadow(0 4px 2px rgba(0, 0, 0, 0.5))`,
    "fontFamily": theme.typography.fontFamily,
    "fontSize": "12px",
    "marginBottom": theme.spacing(2),
    "overflow": "visible",
    "padding": theme.spacing(0.5),
    "position": "relative",
    "textAlign": "center",
    "transform": "scale(1.1)",
    "transition": theme.transitions.create("all"),
  },
}));

interface GeoPoint {
  lat: number;
  lng: number;
}

interface MapProps {
  markerPosition?: GeoPoint;
  markerAddress?: string;
  markerLabel?: React.ReactNode;
  polygonPaths: GeoPoint[];
  containerClassName?: string;
  containerStyle?: React.CSSProperties;
  options?: google.maps.MapOptions;
}

declare global {
  interface Window {
    google: any;
  }
}

const DEAFAULT_ZOOM = 15;

export const Map = ({
  containerClassName = "",
  containerStyle,
  options,
  markerAddress,
  markerPosition,
  markerLabel = "Pickup",
  polygonPaths,
}: MapProps) => {
  const theme = useTheme();
  const classes = useStyles();
  const isClient = typeof window !== "undefined";
  const pixelOffset = isClient && new window.google.maps.Size(-23, -27);
  const marker = isClient && {
    path: window.google.maps.SymbolPath.CIRCLE,
    scale: 0,
  };
  const ref = useRef<GoogleMap>();
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const bounds = useMemo(() => {
    if (polygonPaths) {
      const bounds: google.maps.LatLngBounds = new window.google.maps.LatLngBounds();
      polygonPaths.forEach(p => bounds.extend(p));

      if (markerPosition) {
        bounds.extend(markerPosition);
      }

      return bounds;
    }

    return null;
  }, [markerPosition, polygonPaths]);

  const onMapUnmounted = useCallback(() => setMap(null), []);

  const { callback: fitBounds } = useDebouncedCallback(() => {
    if (map) {
      if (bounds) {
        map.fitBounds(bounds, 1);
      } else if (markerPosition) {
        map.setCenter(markerPosition);
        map.setZoom(DEAFAULT_ZOOM);
      }
    }
  }, 300);

  // refit map upon container resize
  useResizeObserver(ref.current?.mapRef, fitBounds);

  // refit map when bounds (polygon) has changed
  useEffect(() => fitBounds(), [bounds, fitBounds]);

  return (
    <GoogleMap
      center={markerPosition}
      mapContainerClassName={containerClassName}
      mapContainerStyle={containerClassName ? null : containerStyle}
      onLoad={setMap}
      onUnmount={onMapUnmounted}
      ref={ref}
      options={{
        gestureHandling: "greedy",
        mapTypeControl: false,
        ...options,
      }}
      zoom={DEAFAULT_ZOOM}
    >
      {polygonPaths && (
        <Polygon
          options={{
            clickable: false,
            fillColor: theme.palette.grey[500],
            strokeColor: theme.palette.primary.main,
          }}
          paths={polygonPaths}
        />
      )}

      {isClient && markerPosition && (
        <Marker icon={marker} position={markerPosition}>
          <InfoBox
            options={{
              boxStyle: { overflow: "visible" },
              closeBoxURL: "",
              pixelOffset,
            }}
          >
            <Link
              underline="none"
              target="_blank"
              rel="noreferrer"
              href={markerAddress ? `https://maps.google.com?q=${encodeURIComponent(markerAddress)}` : null}
            >
              <div className={classes.markerPin} title={markerAddress}>
                {markerLabel}
              </div>
            </Link>
          </InfoBox>
        </Marker>
      )}
    </GoogleMap>
  );
};

export default Map;
