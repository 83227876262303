import { gql, useQuery } from "@apollo/client";
import { FEATURE_SUPPLIER_RECURRING_ORDERS_EXPORT } from "@arowana/flags";
import { OrderAudience, RecurringOrdersSortField, Supplier } from "@arowana/graphql";
import { PaginatedTable, usePagination } from "@arowana/ui";
import { DATALAYER } from "@arowana/util";
import { Button, Chip, CircularProgress, Link, Typography } from "@material-ui/core";
import { GridSortDirection } from "@material-ui/data-grid";
import { Check } from "@material-ui/icons";
import moment from "moment";
import qs from "qs";
import { useContext, useMemo, useState } from "react";
import { Link as RouterLink } from "react-router-dom";

import PageHeader from "../../../components/PageHeader";
import Routes from "../../../Constants/Routes";
import useExportResource from "../../../hooks/useExportResource";
import { AUDIENCE_DISPLAY } from "../../../utils/audienceDisplay";
import { FlagsmithContext } from "../../context/FlagsmithContext";
import RecurringOrderFilters from "../components/RecurringOrderFilters";

const RECURRING_ORDERS = gql`
  query RecurringOrders($filter: RecurringOrdersFilterInput, $sort: RecurringOrdersSortInput, $page: PaginationInput) {
    currentSupplier {
      id
      recurringOrders(filter: $filter, sort: $sort, page: $page) {
        edges {
          cursor
          node {
            id
            account {
              id
              email
              name
            }
            active
            audience
            client {
              id
              name
              phone
            }
            createdAt
            recurringOrderList {
              id
              name
            }
            skipNext
            updatedAt
          }
        }
        pageInfo {
          count
          endCursor
          hasNextPage
          totalCount
        }
      }
    }
  }
`;

const EXPORT_RECURRING_ORDERS = gql`
  mutation ExportRecurringOrders {
    url: exportRecurringOrders
  }
`;

const COLUMNS = [
  {
    field: "id",
    headerName: "ID",
    renderCell: param => {
      return (
        <Link component={RouterLink} to={Routes.SUBSCRIPTIONS_DETAILS.replace(":id", param.value)}>
          {param.value.substring(18)}
        </Link>
      );
    },
    sortable: false,
    width: 100,
  },
  {
    field: "recurringOrderList",
    headerName: "Collection",
    renderCell: param => {
      return (
        <Link component={RouterLink} to={Routes.SUBSCRIPTIONS_COLLECTIONS_DETAILS.replace(":id", param.value.id)}>
          {param.value.name}
        </Link>
      );
    },
    sortable: false,
    width: 200,
  },
  {
    field: "account",
    headerName: "Customer",
    renderCell: param => {
      const isWholesale = param.row.audience === OrderAudience.WHOLESALE;
      let name = param.value.name;
      let info = param.value.email;

      if (isWholesale) {
        name = param.row.client.name;
        info = param.row.client.phone;
      }

      return (
        <Link
          component={RouterLink}
          to={
            isWholesale
              ? Routes.WHOLESALE_CLIENT_DETAILS.replace(":id", param.row.client.id)
              : Routes.CUSTOMER_DETAILS.replace(":id", param.value.id)
          }
        >
          <Typography gutterBottom variant="body2">
            {name}
          </Typography>
          <Typography component="p" variant="caption">
            {info}
          </Typography>
        </Link>
      );
    },
    sortable: false,
    width: 200,
  },
  {
    field: "active",
    headerName: "Status",
    renderCell: param => (
      <Chip color={param.value ? "primary" : "secondary"} label={param.value ? "Active" : "Inactive"} size="small" />
    ),
    sortable: false,
    width: 100,
  },
  {
    field: "skipNext",
    headerName: "Skipped",
    renderCell: param => (param.value ? <Check color="primary" /> : " "),
    sortable: false,
    width: 100,
  },
  {
    field: "audience",
    headerName: "Audience",
    renderCell: param => AUDIENCE_DISPLAY[param.value],
    sortable: false,
    width: 100,
  },
  {
    field: RecurringOrdersSortField.CREATED_AT,
    headerName: "Created on",
    sortable: true,
    valueGetter: param => moment(param.row.createdAt).format("LL"),
    width: 180,
  },
  {
    field: RecurringOrdersSortField.UPDATED_AT,
    flex: 1,
    headerName: "Last updated on",
    sortable: true,
    valueGetter: param => moment(param.row.updatedAt).format("LLL"),
  },
];

const defaultSortState = {
  field: RecurringOrdersSortField.UPDATED_AT,
  sort: "desc" as GridSortDirection,
};

const ROW_PER_PAGE = 100;

export enum FilterParams {
  active = "active",
  audience = "audience",
  recurringOrderListId = "recurringOrderListId",
}

const RecurringOrders = ({ history }) => {
  const flagsmith = useContext(FlagsmithContext);
  const exportEnabled = flagsmith.hasFeature(FEATURE_SUPPLIER_RECURRING_ORDERS_EXPORT);

  const params: { [key in FilterParams]: string } = qs.parse(history.location.search, { ignoreQueryPrefix: true });
  const { active = "ALL", audience = "ALL", recurringOrderListId = "ALL" } = params ?? {};

  const filter = {
    ...(active !== "ALL" && { active: active === "true" }),
    ...(audience !== "ALL" && { audience }),
    ...(recurringOrderListId !== "ALL" && { recurringOrderListId }),
  };

  const [sortState, setSortState] = useState(defaultSortState);
  const { setPageInfo, resetPagination, pagination, page, onPageChange } = usePagination(ROW_PER_PAGE);
  const sort = {
    field: sortState?.field,
    order: sortState?.sort === "desc" ? -1 : 1,
  };

  const { loading, data } = useQuery<{ currentSupplier: Supplier }>(RECURRING_ORDERS, {
    context: { source: DATALAYER },
    fetchPolicy: "network-only",
    onCompleted: response => {
      setPageInfo(response?.currentSupplier?.recurringOrders?.pageInfo);
    },
    onError: resetPagination,
    variables: {
      filter,
      page: pagination,
      sort,
    },
  });

  const totalCount = data?.currentSupplier?.recurringOrders?.pageInfo?.totalCount ?? 0;

  const onSortChange = ({ sortModel }) => {
    resetPagination();
    setSortState(sortModel[0]);
  };

  const rows = useMemo(
    () => data?.currentSupplier?.recurringOrders?.edges?.map(({ node }) => node) ?? [],
    [data?.currentSupplier?.recurringOrders],
  );

  const sortModel = sortState ? [sortState] : [];

  const onPageChangeExtra = params => {
    window.scrollTo(0, 0);
    onPageChange(params);
  };

  const updateFilter = (keyValue: { [key in FilterParams]?: string }) => {
    const query = {
      ...params,
      ...keyValue,
    };

    history.replace({
      search: qs.stringify(query),
    });
    resetPagination();
  };

  const { ExportDownloadBanner, loadingExport, onExportClick } = useExportResource(EXPORT_RECURRING_ORDERS, {
    filter,
    sort,
  });

  return (
    <>
      <PageHeader
        stickyHeader
        title="Subscriptions"
        primaryActions={
          <>
            {exportEnabled && (
              <Button
                aria-controls="subscriptions-export-menu"
                aria-haspopup="true"
                color="primary"
                disabled={loadingExport}
                endIcon={loadingExport && <CircularProgress color="inherit" size={24} />}
                onClick={onExportClick()}
                variant="outlined"
              >
                Export
              </Button>
            )}
            <Button variant="contained" color="primary" component={RouterLink} to={Routes.SUBSCRIPTIONS_CREATE}>
              Create Subscription
            </Button>
          </>
        }
      />
      {exportEnabled && ExportDownloadBanner}
      <RecurringOrderFilters filterParams={params} updateFilter={updateFilter} />

      <PaginatedTable
        columns={COLUMNS}
        defaultContent="No subscriptions"
        loading={loading}
        onPageChange={onPageChangeExtra}
        onSortModelChange={onSortChange}
        page={page}
        pageSize={ROW_PER_PAGE}
        rows={rows}
        sortModel={sortModel}
        totalCount={totalCount}
      />
    </>
  );
};

export default RecurringOrders;
