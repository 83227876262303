import { gql, useMutation, useQuery } from "@apollo/client";
import { Client, ClientCreateInput } from "@arowana/graphql";
import {
  FormCard,
  PaymentCardElement,
  WholesaleAccountForm,
  WholesaleAccountFormDefaultValues,
  WholesaleAccountFormFields,
  WholesaleClientForm,
  WholesaleClientFormDefaultValues,
  WholesaleClientFormFields,
} from "@arowana/ui";
import { DATALAYER } from "@arowana/util";
import { DevTool } from "@hookform/devtools";
import { Box, Button, Checkbox, FormControl, FormLabel, Grid, ListItemText, Typography } from "@material-ui/core";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useContext, useMemo } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";

import { notificationVar } from "../../../cache/notificationPolicy";
import DropdownSelect from "../../../components/DropdownSelect";
import Loader from "../../../components/Loader";
import PageHeader from "../../../components/PageHeader";
import Routes from "../../../Constants/Routes";
import SwitchToggle from "../../account/components/SwitchToggle";
import { AccountContext } from "../../context/AccountContext";
import { NilID } from "./Client";

const CREATE_CLIENT = gql`
  mutation ClientCreate($input: ClientCreateInput!) {
    clientCreate(input: $input) {
      id
    }
  }
`;

const CREDIT_CARD_ENABLED = gql`
  query CreditCardEnabled {
    currentSupplier {
      id
      wholesalePaymentSettings {
        creditCardEnabled
      }
    }
  }
`;

interface WholesaleRepFormFields {
  repAccountId: string;
}

const defaultValues: WholesaleAccountFormFields & WholesaleClientFormFields & WholesaleRepFormFields = {
  ...WholesaleAccountFormDefaultValues,
  ...WholesaleClientFormDefaultValues,
  repAccountId: NilID,
};

const ClientCreate = ({ history }) => {
  const { supplier } = useContext(AccountContext);

  const repAccountOptions = useMemo(() => {
    const options =
      supplier?.accounts?.map(supplierAccount => {
        const {
          account: { id, email, name },
        } = supplierAccount;

        return {
          label: <ListItemText primary={name} secondary={email} />,
          value: id,
        };
      }) ?? [];
    options.push({ label: <em>None</em>, value: NilID });

    return options;
  }, []);

  const creditCardEnabled = supplier?.wholesalePaymentSettings?.creditCardEnabled;
  const stripe = useStripe();
  const elements = useElements();

  const methods = useForm<WholesaleAccountFormFields & WholesaleClientFormFields & WholesaleRepFormFields>({
    defaultValues,
  });
  const { control, handleSubmit, reset, formState } = methods;
  const isDirty = Object.keys(formState?.dirtyFields).length > 0;

  const [submit, { loading }] = useMutation<{ clientCreate: Client }, { input: ClientCreateInput }>(CREATE_CLIENT, {
    context: { source: DATALAYER },
    onCompleted: data => {
      history.replace(Routes.WHOLESALE_CLIENT_DETAILS.replace(":id", data.clientCreate.id));

      notificationVar({
        message: "Business created!",
        severity: "success",
      });
    },
  });

  const onSubmit = async formData => {
    const { active, accountName, clientName, email, phone, password, repAccountId, ...address } = formData;
    const variables = {
      input: {
        accountName,
        active,
        address,
        email,
        name: clientName,
        password,
        phone,
        ...(repAccountId !== NilID && { repAccountId }),
      } as ClientCreateInput,
    };

    if (creditCardEnabled) {
      // `card` will always be an object unless there's no stripe element is in DOM
      const card = elements.getElement(CardElement);
      const { paymentMethod, error } = await stripe.createPaymentMethod({
        card,
        type: "card",
      });

      // Since paymentMethodId is optional, ignore incomplete card error
      if (error && error.code !== "incomplete_number") {
        notificationVar({
          message: error.message,
          severity: "error",
        });

        return;
      } else if (paymentMethod?.id) {
        variables.input.paymentMethodId = paymentMethod.id;
      }
    }

    submit({ variables });
  };
  const onReset = () => reset(defaultValues);

  return (
    <>
      <Loader loading={loading} />
      <PageHeader
        stickyHeader
        title="Create business"
        primaryActions={
          <>
            <Button color="primary" disabled={!isDirty || loading} onClick={onReset} variant="outlined">
              Discard
            </Button>
            <Button color="primary" disabled={!isDirty || loading} onClick={handleSubmit(onSubmit)} variant="contained">
              Create
            </Button>
          </>
        }
      />
      <FormProvider {...methods}>
        <WholesaleAccountForm forSupplierSignup saving={loading} />
        <WholesaleClientForm saving={loading}>
          <Box marginTop={2}>
            <FormControl fullWidth margin="none">
              <Controller
                as={<SwitchToggle />}
                component={<Checkbox color="primary" />}
                control={control}
                defaultValue={false}
                id="active"
                label={
                  <>
                    <FormLabel htmlFor="active">Access</FormLabel>
                    <Typography variant="caption" component="p">
                      Disabling access will prevent the client from viewing prices or creating orders.
                    </Typography>
                  </>
                }
                labelPlacement="end"
                name="active"
                type="checkbox"
              />
            </FormControl>
          </Box>
        </WholesaleClientForm>
        <FormCard title="Optional">
          <Grid container spacing={2}>
            {creditCardEnabled && (
              <Grid item xs={12} sm={6}>
                <PaymentCardElement label="Payment method" />
              </Grid>
            )}
            <Grid item xs={12} sm={6}>
              <Controller
                label="Rep account"
                as={<DropdownSelect />}
                control={control}
                autoWidth={false}
                dropdownMargin="dense"
                id="rep-account"
                name="repAccountId"
                options={repAccountOptions}
                fullWidth
                rules={{ required: "*required" }}
              />
            </Grid>
          </Grid>
        </FormCard>
      </FormProvider>
      {process.env.NODE_ENV === "development" && <DevTool control={control} />}
    </>
  );
};

export default ClientCreate;
