import { Grid, TextField } from "@material-ui/core";
import EmailValidator from "email-validator";
import { ReactNode } from "react";
import { useFormContext } from "react-hook-form";

import FormCard, { FormCardProps } from "../Form/FormCard";
import { usePasswordToggle } from "../hooks";

export type WholesaleAccountFormFields = {
  accountName: string;
  email: string;
  password: string;
} & FormCardProps;

export const WholesaleAccountFormDefaultValues: WholesaleAccountFormFields = {
  accountName: "",
  email: "",
  password: "",
};

type WholesaleAccountFormProps = {
  saving?: boolean;
  forSupplierSignup?: boolean;
  children?: ReactNode;
} & FormCardProps;

export const WholesaleAccountForm = ({
  saving = false,
  forSupplierSignup = false,
  children,
  ...props
}: WholesaleAccountFormProps) => {
  const { errors, register, watch } = useFormContext<WholesaleAccountFormFields>();
  const { PasswordToggle, passwordInputType } = usePasswordToggle(watch);

  return (
    <FormCard disabled={saving} title="Account info" {...props}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            autoFocus
            fullWidth
            inputRef={register({
              required: "*required",
            })}
            label="Name"
            name="accountName"
            type="text"
            variant="outlined"
            placeholder={forSupplierSignup ? "Owner's name" : "Your name"}
            required
            InputLabelProps={{
              shrink: true,
            }}
            error={!!errors.accountName}
            helperText={errors?.accountName?.message}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            inputRef={register({
              required: "*required",
              validate: value => EmailValidator.validate(value) || "*invalid email address",
            })}
            label="Email"
            name="email"
            type="email"
            variant="outlined"
            disabled={!forSupplierSignup}
            required
            InputLabelProps={{
              shrink: true,
            }}
            error={!!errors.email}
            helperText={errors?.email?.message}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            inputRef={register({
              minLength: {
                message: "Password must have at least 6 characters",
                value: 6,
              },
            })}
            label="Password"
            name="password"
            type={passwordInputType}
            variant="outlined"
            placeholder={forSupplierSignup ? "Leave blank for generated password" : "Leave blank if unchanged"}
            InputLabelProps={{
              shrink: true,
            }}
            error={Boolean(errors?.password)}
            helperText={errors?.password?.message}
            InputProps={{ endAdornment: PasswordToggle }}
          />
        </Grid>
      </Grid>
      {children}
    </FormCard>
  );
};
