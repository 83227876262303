import { FulfillmentMethod, Location } from "@arowana/graphql";
import { Chip, Grid, Link, makeStyles, Typography } from "@material-ui/core";
import clsx from "clsx";
import moment from "moment";
import { useMemo } from "react";
import ReactHtmlParser from "react-html-parser";

import Currency from "../Currency";

const useStyles = makeStyles(theme => ({
  closedChip: {
    height: 18,
    marginLeft: theme.spacing(0.5),
  },
  infoContainer: {
    borderBottomRightRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
    padding: theme.spacing(1, 2),
    transition: theme.transitions.create("all"),
  },
  label: {
    fontWeight: 500,
    marginTop: theme.spacing(2),
  },
  locationContainer: {
    aspectRatio: "1 / 1",
  },
  map: {
    maxHeight: 400,
    maxWidth: 400,
    width: "100%",
  },
  root: {
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: theme.shape.borderRadius,
  },
  title: {
    alignItems: "center",
    display: "inline-flex",
  },
}));

type LocationItemProps = {
  className?: string;
  location: Partial<Location>;
};

export const LocationItem = ({ className, location }: LocationItemProps) => {
  const classes = useStyles();
  const {
    active,
    address,
    cutOffHour,
    deliveryFee,
    description,
    discountedDeliveryFee,
    discountedDeliveryFeeThreshold,
    freeDeliveryAmount,
    fulfillmentMethod,
    fulfillmentTimes,
    leadTimes,
    minimumOrderAmount,
    name,
    staticMapUrl,
  } = location ?? {};

  const isShipping = fulfillmentMethod === FulfillmentMethod.shipping;
  const isPickup = fulfillmentMethod === FulfillmentMethod.pickup;

  const formattedDeliveryDays = useMemo(
    () =>
      Object.keys(leadTimes)
        .map(day => moment().isoWeekday(parseInt(day, 10)).format("ddd"))
        .join(", "),
    [leadTimes],
  );

  const formattedAddress = address?.formattedAddress;

  const shippingOrDeliveryFees = (
    <>
      <Typography className={classes.label} variant="subtitle1" component="div">
        {isPickup ? "Pickup" : "Delivery"} fee
      </Typography>
      <Typography component="div" gutterBottom variant="body2">
        {isShipping ? (
          // always show this message if fulfillmentMethod is shipping
          "Calculated automatically by weight and location"
        ) : // show the fee if fulfillmentMethod is pickup or delivery
        !deliveryFee ? (
          "Free"
        ) : (
          <Currency cents={deliveryFee} />
        )}
      </Typography>
    </>
  );

  return (
    <Grid className={clsx(classes.root, className)} container>
      {staticMapUrl && (
        <Grid item xs={4} className={classes.locationContainer}>
          <img className={classes.map} src={staticMapUrl} alt="Map of location" />
        </Grid>
      )}
      <Grid
        xs={8}
        className={classes.infoContainer}
        container
        item
        direction="row"
        alignContent="flex-start"
        alignItems="flex-start"
      >
        <Grid item xs={12} zeroMinWidth>
          <Typography component="p" className={classes.title} variant="h6">
            {name}
            {!active && (
              <Chip
                className={classes.closedChip}
                color="secondary"
                component="span"
                label="Closed"
                size="small"
                variant="default"
              />
            )}
          </Typography>
        </Grid>
        {/* LEFT COLUMN */}
        <Grid container item sm={12} md={6} alignContent="flex-start" alignItems="flex-start">
          <Grid item xs={12}>
            <Typography className={classes.label} variant="subtitle1" component="div">
              Fulfillment days
            </Typography>
            <Typography component="div" gutterBottom variant="body2">
              {formattedDeliveryDays}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.label} variant="subtitle1" component="div">
              Order cutoff time
            </Typography>
            <Typography component="div" gutterBottom variant="body2">
              {moment().hour(cutOffHour).format("ha")}
            </Typography>
          </Grid>
          {!isShipping && fulfillmentTimes?.length > 0 && (
            <Grid item xs={12}>
              <Typography className={classes.label} variant="subtitle1" component="div">
                {isPickup ? "Pickup" : "Delivery"} window
              </Typography>
              {fulfillmentTimes.map(({ id, label }) => (
                <Typography key={id} variant="body2">
                  {label}
                </Typography>
              ))}
            </Grid>
          )}
        </Grid>
        {/* RIGHT COLUMN */}
        <Grid container item sm={12} md={6} alignContent="flex-start" alignItems="flex-start">
          <Grid item xs={12}>
            <Typography className={classes.label} variant="subtitle1" component="div">
              Minimum order
            </Typography>
            <Typography component="div" gutterBottom variant="body2">
              <Currency cents={minimumOrderAmount} />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {shippingOrDeliveryFees}
          </Grid>
          {freeDeliveryAmount !== null && (
            <Grid item xs={12}>
              <Typography className={classes.label} variant="subtitle1" component="div">
                Free delivery above <Currency cents={freeDeliveryAmount} />
              </Typography>
            </Grid>
          )}
          {Boolean(discountedDeliveryFeeThreshold) && discountedDeliveryFee > 0 && (
            <Grid item xs={12}>
              <Typography className={classes.label} variant="subtitle1" component="div">
                Discounted delivery fee above <Currency cents={discountedDeliveryFeeThreshold} />
              </Typography>
              <Typography component="div" gutterBottom variant="body2">
                <Currency cents={discountedDeliveryFee} />
              </Typography>
            </Grid>
          )}
        </Grid>
        {isPickup && (
          <Grid item xs={12}>
            <Typography className={classes.label} variant="subtitle1" component="div">
              Pickup location
            </Typography>
            <Typography component="div" gutterBottom variant="body2">
              {formattedAddress ? (
                <>
                  <Typography gutterBottom variant="body2">
                    <Link
                      target="_blank"
                      rel="noreferrer"
                      href={`https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(
                        formattedAddress,
                      )}`}
                    >
                      {formattedAddress}
                    </Link>
                  </Typography>
                  {description && (
                    <Typography className={classes.label} variant="subtitle1" component="div">
                      Pickup instructions:
                      <Typography component="div" className="ql-content" variant="body2">
                        {ReactHtmlParser(description)}
                      </Typography>
                    </Typography>
                  )}
                </>
              ) : (
                <Typography component="span" variant="body2">
                  N/A
                </Typography>
              )}
            </Typography>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default LocationItem;
