import { Backdrop, CircularProgress, Fade, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  loaderBackdrop: {
    backgroundColor: "rgba(0,0,0,0.3) !important",
    zIndex: 99999999,
  },
});

const Loader = ({ loading = true }: { loading?: boolean }) => {
  const classes = useStyles();

  return (
    <Fade in={loading} unmountOnExit>
      <Backdrop className={classes.loaderBackdrop} open>
        <CircularProgress size={60} thickness={5} />
      </Backdrop>
    </Fade>
  );
};

export default Loader;
