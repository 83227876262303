import { CircularProgress, LinearProgress, makeStyles, Typography } from "@material-ui/core";
import {
  DataGrid,
  DataGridProps,
  GridColumns,
  GridFeatureMode,
  GridOverlay,
  GridPageChangeParams,
  GridRowsProp,
  GridSelectionModelChangeParams,
  GridSortModel,
  GridSortModelParams,
} from "@material-ui/data-grid";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  table: {
    "& .MuiDataGrid-cell": {
      overflowX: "clip",
    },
    "& .MuiDataGrid-colCellWrapper": {
      background: theme.palette.grey[50],
    },
    "& .MuiDataGrid-columnHeader:focus": {
      outline: "none",
    },
    "& .MuiDataGrid-columnHeader--sortable": {
      color: theme.palette.primary.main,
    },
    "& .MuiDataGrid-columnHeader--sortable:hover": {
      background: theme.palette.background.paper,
    },
    "& .MuiDataGrid-columnSeparator": {
      display: "none",
    },
    "& .MuiDataGrid-columnsContainer, & .MuiDataGrid-colCellWrapper": {
      borderTopLeftRadius: theme.shape.borderRadius,
      borderTopRightRadius: theme.shape.borderRadius,
    },
    "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus-within":
      {
        outline: "none",
      },
  },
  tableContainer: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(2),
    width: "100%",
  },
}));

export const LoadingOverlay = () => (
  <GridOverlay>
    <div style={{ position: "absolute", top: 0, width: "100%" }}>
      <LinearProgress color="primary" />
    </div>
    <div style={{ bottom: -44, left: 16, position: "absolute", width: "100%" }}>
      <CircularProgress color="primary" size={24} />
    </div>
  </GridOverlay>
);

export const NoRowsOverlay = ({ message, hasRows }) =>
  !hasRows && (
    <GridOverlay>
      <Typography variant="h6">{message}</Typography>
    </GridOverlay>
  );

type PaginatedTableProps = {
  columns: GridColumns;
  checkboxSelection?: boolean;
  className?: string;
  dataGridClasses?: DataGridProps["classes"];
  defaultContent?: string;
  getRowId?: (row) => string;
  loading?: boolean;
  onPageChange?: (params: GridPageChangeParams) => void;
  onSortModelChange?: (params: GridSortModelParams) => void;
  page?: number;
  paginationMode?: GridFeatureMode;
  pageSize?: number;
  rows: GridRowsProp;
  rowHeight?: number;
  selectionModel?: GridSelectionModelChangeParams["selectionModel"];
  sortModel?: GridSortModel;
  sortingMode?: GridFeatureMode;
  totalCount?: number;
  onSelectionModelChange?: (params: GridSelectionModelChangeParams) => void;
};

export const PaginatedTable = ({
  columns,
  checkboxSelection = false,
  className = "",
  dataGridClasses,
  defaultContent = "No data",
  getRowId = undefined,
  loading = false,
  onPageChange,
  onSortModelChange,
  page = 1,
  paginationMode = "server",
  pageSize = 100,
  rows,
  rowHeight = 52,
  selectionModel = [],
  sortModel = [],
  sortingMode = "server",
  totalCount,
  onSelectionModelChange,
  ...rest
}: PaginatedTableProps) => {
  const classes = useStyles();
  const hasRows = rows?.length > 0;

  return (
    <div className={clsx(classes.tableContainer, className)}>
      <DataGrid
        autoHeight
        checkboxSelection={checkboxSelection}
        className={classes.table}
        columns={columns}
        components={{
          LoadingOverlay,
          NoRowsOverlay,
        }}
        classes={dataGridClasses}
        componentsProps={{ noRowsOverlay: { hasRows, message: defaultContent } }}
        disableColumnFilter
        disableColumnMenu
        disableColumnSelector
        disableSelectionOnClick
        getRowId={getRowId}
        hideFooterPagination={totalCount === 0}
        loading={loading}
        onPageChange={onPageChange}
        onSelectionModelChange={onSelectionModelChange}
        onSortModelChange={onSortModelChange}
        page={page}
        pageSize={pageSize}
        paginationMode={paginationMode}
        rowCount={totalCount ?? rows.length}
        rowHeight={rowHeight}
        rows={rows}
        rowsPerPageOptions={[]}
        selectionModel={selectionModel}
        sortModel={sortModel}
        sortingMode={sortingMode}
        sortingOrder={["desc", "asc"]}
        {...rest}
      />
    </div>
  );
};

export default PaginatedTable;
