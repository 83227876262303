import { makeStyles, Tooltip, Typography } from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

const useStyles = makeStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.grey[300]}`,
    color: theme.palette.text.primary,
    padding: theme.spacing(1),
  },
  tooltipArrow: {
    "&:before": {
      border: `1px solid ${theme.palette.grey[300]}`,
    },
    "color": theme.palette.background.paper,
  },
  tooltipIcon: {
    color: theme.palette.grey[500],
    cursor: "pointer",
    verticalAlign: "text-bottom",
  },
}));
const FormTooltip = ({ content }) => {
  const classes = useStyles();

  return (
    <Tooltip
      arrow
      classes={{
        arrow: classes.tooltipArrow,
        tooltip: classes.tooltip,
      }}
      title={<Typography variant="body2">{content}</Typography>}
    >
      <InfoOutlinedIcon className={classes.tooltipIcon} fontSize="small" />
    </Tooltip>
  );
};

export default FormTooltip;
