import { makeStyles, Typography } from "@material-ui/core";

import AuthForm, { FormMode } from "../components/AuthForm";

const useStyles = makeStyles(theme => ({
  loginMessage: {
    marginBottom: theme.spacing(4),
  },
  welcomeMessage: {
    paddingTop: theme.spacing(3),
  },
}));

const ResetPasswordPage = () => {
  const classes = useStyles();

  return (
    <AuthForm formMode={FormMode.ResetPassword}>
      <Typography className={classes.welcomeMessage} variant="h6">
        Forgot your password? Tired of typing?
      </Typography>
      <Typography className={classes.loginMessage} variant="subtitle1">
        Enter your email to get a magic link to sign in instantly!
      </Typography>
    </AuthForm>
  );
};

export default ResetPasswordPage;
