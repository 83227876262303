import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/styles";
import PropTypes from "prop-types";

const useStyles = makeStyles(() => ({
  buttonSpacing: {
    "@media print": {
      display: "none",
    },
  },
}));

const PrintPageButton = ({ children, ...props }) => {
  const styles = useStyles();

  return (
    <Button className={styles.buttonSpacing} variant="outlined" {...props} onClick={() => window.print()}>
      {children}
    </Button>
  );
};

PrintPageButton.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PrintPageButton;
