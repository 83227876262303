import { TextField } from "@material-ui/core";
import React, { forwardRef, ForwardRefExoticComponent } from "react";
import NumberFormat from "react-number-format";

type MaskedFieldProps = {
  onChange?: (value: string) => void;
  value?: string;
  format?: string;
  allowEmptyFormatting?: boolean;
  displayType?: "input" | "text";
  error?: boolean;
  fullWidth?: boolean;
  helperText?: string;
  isNumericString?: boolean;
  mask?: string;
  type?: "text" | "tel" | "password";
};

export const MaskedField: ForwardRefExoticComponent<MaskedFieldProps> = forwardRef(
  ({ onChange, value, format, ...props }: MaskedFieldProps, ref) => {
    // Values consisted of: formattedValue, value, & floatValue
    const onValueChange = values => onChange(values.value);

    return (
      <NumberFormat
        {...props}
        customInput={TextField}
        format={value ? format : undefined}
        getInputRef={ref}
        onValueChange={onValueChange}
        value={value}
        variant="outlined"
      />
    );
  },
);

export * from "./Masks";

export default MaskedField;
