/* eslint-disable no-unused-vars */
import { InMemoryCache } from "@apollo/client";

import addressPolicy from "./addressPolicy";
import authPolicy from "./authPolicy";
import categoryPolicy from "./categoryPolicy";
import listPolicy from "./listPolicy";
import notificationPolicy from "./notificationPolicy";
import productVariantPolicy from "./productVariantPolicy";
import supplierPolicy from "./supplierPolicy";

export default new InMemoryCache({
  typePolicies: {
    ...addressPolicy,
    ...categoryPolicy,
    ...listPolicy,
    ...supplierPolicy,
    ...productVariantPolicy,
    Consumer: {
      fields: {
        addresses: {
          merge(existing = [], incoming = []) {
            return incoming;
          },
        },
      },
    },
    Mutation: {
      fields: {
        updateSupplierProductVariant: {
          merge: false,
        },
      },
    },
    Product: {
      fields: {
        fulfillmentDays: {
          merge(existing = [], incoming = []) {
            return incoming;
          },
        },
        images: {
          merge(existing = [], incoming = []) {
            return incoming;
          },
        },
        lists: {
          merge(existing = [], incoming = []) {
            return incoming;
          },
        },
        tags: {
          merge(existing = [], incoming = []) {
            return incoming;
          },
        },
        variants: {
          merge(existing = [], incoming = []) {
            return incoming;
          },
        },
      },
    },
    Query: {
      fields: {
        ...authPolicy,
        ...notificationPolicy,
        getSupplierCategories: {
          merge(existing = [], incoming = []) {
            return incoming;
          },
        },
        getSupplierProductVariants: {
          merge: false,
        },
      },
    },
  },
});
