import {
  Checkbox,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import moment, { MomentInput } from "moment";
import { useContext } from "react";

import FulfillmentChip from "../../../components/FulfillmentChip";
import { FlagsmithContext } from "../../context/FlagsmithContext";
import Audiences from "../../orders/constants/orderAudience";
import { Location } from "../pages/OrdersPicklist/OrdersPicklist";

const useStyles = makeStyles(theme => ({
  chip: {
    margin: theme.spacing(0, 0.5, 0, 0),
  },
  chips: {
    display: "flex",
    flexWrap: "nowrap",
  },
  header: {
    "@media print": {
      display: "none",
    },
    "marginBottom": theme.spacing(2),
  },
}));

type OperationsFiltersProps = {
  audience: string | qs.ParsedQs | string[] | qs.ParsedQs[];
  bulkControls?: React.ReactNode;
  endDate: string | qs.ParsedQs | string[] | qs.ParsedQs[];
  extraControls?: React.ReactNode[];
  locationIds: string[] | qs.ParsedQs[];
  locations: Location[];
  onAudienceChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
  onStartChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
  onEndChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
  onLocationChange: (locationsIds: string[]) => void;
  startDate: string | qs.ParsedQs | string[] | qs.ParsedQs[];
};

const OperationsFilters = ({
  audience,
  bulkControls,
  endDate,
  extraControls,
  locationIds,
  locations,
  onAudienceChange,
  onStartChange,
  onEndChange,
  onLocationChange,
  startDate,
}: OperationsFiltersProps) => {
  const classes = useStyles();
  const parsedStart = moment(startDate as MomentInput);
  const invalidStart = !parsedStart.isValid();
  const parsedEnd = moment(endDate as MomentInput);
  const invalidEnd = !parsedEnd.isValid();
  const flagsmith = useContext(FlagsmithContext);
  const hasWholesale = flagsmith.hasFeature("b2b");
  const handleLocationChange = (event: React.ChangeEvent<{ value: string[] }>) => {
    onLocationChange(event.target.value);
  };

  return (
    <Grid alignItems="center" container spacing={2} className={classes.header}>
      <Grid item xs={12} sm={6} md={3} lg={2}>
        <TextField
          error={invalidStart}
          fullWidth
          helperText={invalidStart && "Invalid start date!"}
          label="Start fulfillment date"
          onChange={onStartChange}
          type="date"
          value={startDate}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3} lg={2}>
        <TextField
          error={invalidEnd}
          fullWidth
          helperText={invalidEnd && "Invalid end date!"}
          inputProps={{
            min: startDate,
          }}
          label="End fulfillment date"
          onChange={onEndChange}
          type="date"
          value={endDate}
          variant="outlined"
        />
      </Grid>
      {hasWholesale && (
        <Grid item xs={12} sm={6} md={3} lg={2}>
          <FormControl fullWidth variant="outlined">
            <InputLabel htmlFor="audience-select">Audience</InputLabel>
            <Select
              defaultValue="ALL"
              inputProps={{
                id: "audience-select",
              }}
              label="Audience"
              onChange={onAudienceChange}
              title="Audience"
              value={audience}
              variant="outlined"
            >
              {Audiences.map(({ id, label }) => (
                <MenuItem key={id} value={id}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      )}
      <Grid item xs={12} sm={6} md={3} lg={2}>
        <FormControl fullWidth variant="outlined">
          <InputLabel htmlFor="locations-select">Location</InputLabel>
          <Select
            inputProps={{
              id: "locations-select",
            }}
            onChange={handleLocationChange}
            value={locationIds}
            variant="outlined"
            multiple
            renderValue={(selected: string[]) =>
              selected.length ? (
                <div className={classes.chips}>
                  {locations
                    .filter(({ id }) => selected.includes(id))
                    .map(({ id, fulfillmentMethod, name }) => (
                      <Chip className={classes.chip} key={id} label={name} size="small" variant="outlined" />
                    ))}
                </div>
              ) : (
                <Typography variant="body1" component="div">
                  All
                </Typography>
              )
            }
          >
            {locations.map(({ id, name, fulfillmentMethod }) => (
              <MenuItem key={id} value={id}>
                <Checkbox checked={locationIds.includes(id)} />
                <ListItemText primary={name} />
                <ListItemSecondaryAction>
                  <FulfillmentChip fulfillmentType={fulfillmentMethod} />
                </ListItemSecondaryAction>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      {extraControls?.map((extraControl, idx) => (
        <Grid key={`extra-control-${idx}`} item xs={12} sm={6} md={3} lg={2}>
          {extraControl}
        </Grid>
      ))}
      {bulkControls && (
        <Grid item xs={12} sm={6} md={3} lg={2}>
          {bulkControls}
        </Grid>
      )}
    </Grid>
  );
};

export default OperationsFilters;
