import { Typography } from "@material-ui/core";

import PageHeader from "../../../components/PageHeader";
import PaywallCard from "../../../components/PaywallCard";

const RecurringFeatureNotEnabled = () => {
  return (
    <>
      <PageHeader stickyHeader title="Subscriptions" />

      <PaywallCard title="Streamline your regular customers' workflows with recurring orders.">
        <Typography variant="body1">
          Customize hand-crafted lists of products that your customers can subscribe to. Freshline will automatically
          create and fulfill these orders on a schedule that you specify.
        </Typography>
      </PaywallCard>
    </>
  );
};

export default RecurringFeatureNotEnabled;
