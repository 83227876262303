import { Address } from "@arowana/graphql";
import { ListItemText, makeStyles, MenuItem, Select, SelectProps, Theme } from "@material-ui/core";

const useStyles = makeStyles<Theme>(() => ({
  addressSecondary: {
    maxWidth: "100%",
    whiteSpace: "initial",
  },
  addressSelect: {
    margin: 0,
    overflowX: "hidden",
  },
}));

type AddressSelectProps = SelectProps & {
  addresses: Pick<Address, "id" | "label" | "formattedAddress">[];
  showAddOption?: boolean;
};

export const AddressSelect = ({ addresses, showAddOption = true, ...props }: AddressSelectProps) => {
  const classes = useStyles();

  return (
    <Select fullWidth margin="dense" variant="outlined" displayEmpty {...props}>
      <MenuItem value="" disabled>
        Select an address
      </MenuItem>
      {addresses?.map(({ id, label, formattedAddress }) => (
        <MenuItem divider key={id} value={id}>
          <ListItemText
            classes={{ root: classes.addressSelect, secondary: classes.addressSecondary }}
            primary={label}
            secondary={formattedAddress}
          />
        </MenuItem>
      ))}
      {showAddOption && <MenuItem value="add">Add an address</MenuItem>}
    </Select>
  );
};

export default AddressSelect;
