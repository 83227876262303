import { Box, Button, CircularProgress } from "@material-ui/core";

import { PaymentCardElement } from ".";

type PaymentMethodFormProps = {
  adding?: boolean;
  onAddClick: () => void;
  onAddCancel?: () => void;
};

export const PaymentMethodForm = ({ adding, onAddClick, onAddCancel }: PaymentMethodFormProps) => {
  return (
    <>
      <PaymentCardElement />
      <Box marginTop={1} display="flex" justifyContent="flex-end">
        {onAddCancel && (
          <Button disabled={adding} style={{ marginRight: 8 }} variant="outlined" color="primary" onClick={onAddCancel}>
            Cancel
          </Button>
        )}
        <Button variant="contained" color="primary" onClick={onAddClick} disabled={adding}>
          {adding ? <CircularProgress color="inherit" size={24} /> : "Save"}
        </Button>
      </Box>
    </>
  );
};

export default PaymentMethodForm;
