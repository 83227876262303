import { ReactiveVar, useReactiveVar } from "@apollo/client";
import { makeStyles, Snackbar, SnackbarCloseReason } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import clsx from "clsx";
import { ReactNode, useEffect, useState } from "react";

const useStyles = makeStyles(theme => ({
  alertError: {
    backgroundColor: theme.palette.secondary.main,
  },
  alertRoot: {
    boxShadow: theme.shadows[4],
  },
  alertSuccess: {
    backgroundColor: theme.palette.primary.main,
  },
  snackBar: {
    [theme.breakpoints.down("xs")]: {
      bottom: 70,
    },
  },
  snackBarHidden: {
    pointerEvents: "none",
  },
}));

export type NotificationVarType = {
  duration?: number;
  message: ReactNode;
  severity: "error" | "info" | "success" | "warning";
};

type NotificationProps = {
  vertical?: "bottom" | "top";
  horizontal?: "center" | "left" | "right";
  alertVariant?: "filled" | "outlined" | "standard";
  autoHideDuration?: number;
  notificationVar?: ReactiveVar<NotificationVarType>;
};
/**
 * Notification component (notice: this component depends on a local query)
 * @param {string} vertical optional field, the vertical position of the notification
 * @param {string} horizontal optional field, the horizontal position of the notification
 * @param {string} alertVariant optional field, the variant of the alert inside notification
 * @param {number} autoHideDuration optional field, how many milliseconds until the notification is auto dismissed
 * @return {element} the Notification component
 */
export const Notification = ({
  vertical = "bottom",
  horizontal = "right",
  alertVariant = "filled",
  autoHideDuration = 15000,
  notificationVar = null,
}: NotificationProps) => {
  const classes = useStyles();
  const notification = useReactiveVar(notificationVar);
  const [show, setShow] = useState(false);

  useEffect(() => {
    const hasMessage = notification?.severity && notification?.message;

    if (hasMessage) {
      setShow(true);
    }
  }, [notification, setShow]);

  const onClose = (_: React.SyntheticEvent, reason: SnackbarCloseReason) => {
    if (reason === "clickaway") {
      return;
    }

    setShow(false);
  };

  // Clear the message upon exit, ensuring no werid transition
  const onExited = () => notificationVar(null);

  return (
    <Snackbar
      TransitionProps={{ onExited }}
      anchorOrigin={{ horizontal, vertical }}
      autoHideDuration={notification?.duration ?? autoHideDuration}
      className={clsx(classes.snackBar, { [classes.snackBarHidden]: !show })}
      onClose={onClose}
      open={show}
    >
      <Alert
        classes={{ filledError: classes.alertError, filledSuccess: classes.alertSuccess, root: classes.alertRoot }}
        onClose={() => setShow(false)}
        severity={notification?.severity}
        variant={alertVariant}
      >
        {notification?.message}
      </Alert>
    </Snackbar>
  );
};
export default Notification;
