import { createSvgIcon } from "@material-ui/core/utils";
import React from "react";

export const Login = createSvgIcon(
  <g>
    <path d="M9 12C11.21 12 13 10.21 13 8C13 5.79 11.21 4 9 4C6.79 4 5 5.79 5 8C5 10.21 6.79 12 9 12ZM9 14C6.33 14 1 15.34 1 18V20H17V18C17 15.34 11.67 14 9 14Z" />
    <path d="M19.3175 14.4425L20.375 15.5L24.125 11.75L20.375 8L19.3175 9.0575L21.2525 11H14V12.5H21.2525L19.3175 14.4425Z" />
  </g>,
  "Login",
);

export default Login;
