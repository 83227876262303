import { gql, useQuery } from "@apollo/client";
import { PromotionsSortField } from "@arowana/graphql";
import { Dollar, usePagination } from "@arowana/ui";
import { DATALAYER } from "@arowana/util";
import { Box, Button, Chip, Link } from "@material-ui/core";
import moment from "moment-timezone";
import qs from "qs";
import { useContext, useState } from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";

import PageHeader from "../../../components/PageHeader";
import PaginatedTable from "../../../components/PaginatedTable";
import TipBanner from "../../../components/TipBanner";
import Routes from "../../../Constants/Routes";
import { FlagsmithContext } from "../../context/FlagsmithContext";
import PromotionsSearch from "../component/PromotionsSearch";

const PROMOTIONS = gql`
  query Promotions($page: PaginationInput, $sort: PromotionsSortInput, $filter: PromotionsFilterInput) {
    currentSupplier {
      promotions(page: $page, sort: $sort, filter: $filter) {
        edges {
          cursor
          node {
            id
            code
            disabled
            expiresAt
            maxAmountToUse
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          totalCount
        }
      }
    }
  }
`;

const columns = [
  {
    field: "id",
    hide: true,
  },
  {
    field: PromotionsSortField.CODE,
    headerName: "Discount code",
    renderCell: param => (
      <Link component={RouterLink} to={Routes.DISCOUNT_DETAILS.replace(":id", param.row.id)}>
        {param.value}
      </Link>
    ),
    sortable: true,
    valueGetter: param => param.row.code,
    width: 280,
  },
  {
    field: "maxAmountToUse",
    headerName: "Budget",
    renderCell: param => <Dollar amount={param.value} />,
    sortable: false,
    width: 150,
  },
  {
    field: PromotionsSortField.DISABLED,
    headerName: "Status",
    renderCell: param => (
      <Chip color={!param.value ? "primary" : "secondary"} label={!param.value ? "Active" : "Inactive"} />
    ),
    sortable: true,
    valueGetter: param => param.row.disabled,
    width: 150,
  },

  {
    field: PromotionsSortField.EXPIRES_AT,
    flex: 1,
    headerName: "Expiry date",
    sortable: true,
    valueGetter: param => moment(param.row.expiresAt).format("YYYY-MM-DD"),
  },
];

const ROW_PER_PAGE = 25;

const defaultSortState = {
  field: PromotionsSortField.EXPIRES_AT,
  sort: "desc",
};

const Promotions = () => {
  const history = useHistory();
  const queryParams = qs.parse(history.location.search, { ignoreQueryPrefix: true });
  const { query = "" } = queryParams;
  const [sortState, setSortState] = useState(defaultSortState);
  const { setPageInfo, resetPagination, pagination, page, onPageChange } = usePagination(ROW_PER_PAGE);
  const flagsmith = useContext(FlagsmithContext);
  const hasRetail = flagsmith.hasFeature("b2c");

  const { loading, data } = useQuery(PROMOTIONS, {
    context: { source: DATALAYER },
    fetchPolicy: "network-only",
    onCompleted: response => {
      setPageInfo(response?.currentSupplier?.promotions?.pageInfo);
    },
    onError: resetPagination,
    variables: {
      filter: {
        ...(query && { query }),
        type: ["amount", "item", "percentage"],
      },
      page: pagination,
      sort: sortState && {
        field: sortState.field,
        order: sortState.sort === "desc" ? -1 : 1,
      },
    },
  });

  const totalCount = data?.currentSupplier?.promotions?.pageInfo?.totalCount ?? 0;
  const rows = data?.currentSupplier?.promotions?.edges.map(({ node }) => node) ?? [];

  const handleSortChange = ({ sortModel }) => {
    resetPagination();
    setSortState(sortModel[0]);
  };

  const handleUpdateQuery = keyValue => {
    history.replace({
      search: qs.stringify({
        ...queryParams,
        ...keyValue,
      }),
    });
    setSortState(defaultSortState);
    resetPagination();
  };

  return (
    <>
      <PageHeader
        primaryActions={
          <Button color="primary" component={RouterLink} to={Routes.CREATE_DISCOUNT} variant="contained">
            Create Promotion
          </Button>
        }
        stickyHeader
        title="Promotions"
      />
      {!hasRetail && (
        <TipBanner
          headerText="Promotions are only used for your retail online store."
          bodyText="Please note that if you do not have a retail online store, Promotions won't be available for use on checkout."
        />
      )}
      <Box mb={2}>
        <PromotionsSearch onUpdateQuery={handleUpdateQuery} placeholder="Search discount codes" query={query} />
      </Box>
      <PaginatedTable
        columns={columns}
        defaultContent="No discount codes"
        loading={loading}
        onPageChange={onPageChange}
        onSortModelChange={handleSortChange}
        page={page}
        pageSize={ROW_PER_PAGE}
        rows={rows}
        sortModel={sortState ? [sortState] : []}
        totalCount={totalCount}
      />
    </>
  );
};

export default Promotions;
