import { useQuery } from "@apollo/client";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment-timezone";
import qs from "qs";
import { useContext, useEffect, useMemo } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import Routes from "../../../Constants/Routes";
import { supplierLocationIDsAndNames } from "../../../queries/supplier";
import { AccountContext } from "../../context/AccountContext";
import ExportSheet from "./ExportSheet";
import OrdersPicklist from "./OrdersPicklist";
import ProductsPicklist from "./ProductsPicklist";

const Operations = ({ location, history }) => {
  const { supplier } = useContext(AccountContext);
  const { timezone, locations } = supplier;
  const params = qs.parse(history.location.search, { ignoreQueryPrefix: true });
  const paramsLength = Object.keys(params).length;
  const { audience = "ALL", from: startDate, fulfilled = "ALL", locationIds = [], to: endDate } = params;
  const onOrdersPicklist = useMemo(() => location.pathname === Routes.ORDERS_PICKLIST, [location.pathname]);

  useEffect(() => {
    if (paramsLength === 0) {
      const query = {
        audience,
        from: moment().format("YYYY-MM-DD"),
        ...(onOrdersPicklist && { fulfilled }),
        locationIds: [],
        to: moment().format("YYYY-MM-DD"),
      };

      history.replace({
        search: qs.stringify(query),
      });
    }
  }, [history, onOrdersPicklist, paramsLength]);

  const allLocations = locations.map(row => ({
    fulfillmentMethod: row.fulfillmentMethod,
    id: row.id,
    name: row.name,
  }));
  const onLocationChange = locationIds => {
    history.push({
      search: qs.stringify({
        audience,
        from: startDate,
        locationIds,
        to: endDate,
        ...(onOrdersPicklist && { fulfilled }),
      }),
    });
  };
  const parseDate = event => {
    const parsed = moment.tz(event.target.value, timezone);

    return {
      parsed,
      value: parsed.isValid() ? parsed.format("YYYY-MM-DD") : event.target.value,
    };
  };
  const onStartChange = e => {
    const { parsed, value } = parseDate(e);
    const parsedEndDate = moment.tz(endDate, timezone);

    if (parsed.isValid() && parsedEndDate.isValid() && parsedEndDate.isBefore(parsed)) {
      history.push({
        search: qs.stringify({
          audience,
          from: value,
          ...(onOrdersPicklist && { fulfilled }),
          locationIds,
          to: parsed.format("YYYY-MM-DD"),
        }),
      });
    } else {
      history.push({
        search: qs.stringify({
          audience,
          from: value,
          ...(onOrdersPicklist && { fulfilled }),
          locationIds,
          to: endDate,
        }),
      });
    }
  };
  const onEndChange = e => {
    history.push({
      search: qs.stringify({
        audience,
        from: startDate,
        ...(onOrdersPicklist && { fulfilled }),
        locationIds,
        to: parseDate(e).value,
      }),
    });
  };
  const onAudienceChange = e => {
    history.push({
      search: qs.stringify({
        audience: e.target.value,
        from: startDate,
        ...(onOrdersPicklist && { fulfilled }),
        locationIds,
        to: endDate,
      }),
    });
  };

  const onFilterByFulfilledChange = e => {
    history.push({
      search: qs.stringify({
        audience,
        from: startDate,
        fulfilled: e.target.value,
        locationIds,
        to: endDate,
      }),
    });
  };

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Switch>
        <Route component={ExportSheet} path={Routes.ORDERS_PICKLIST_EXPORT} />
        <Route path={Routes.ORDERS_PICKLIST}>
          <OrdersPicklist
            audience={audience}
            endDate={endDate}
            fulfilled={fulfilled}
            locationIds={locationIds}
            locations={allLocations}
            onAudienceChange={onAudienceChange}
            onEndChange={onEndChange}
            onFilterByFulfilledChange={onFilterByFulfilledChange}
            onStartChange={onStartChange}
            onLocationChange={onLocationChange}
            startDate={startDate}
            timezone={timezone}
          />
        </Route>
        <Route path={Routes.PRODUCTS_PICKLIST}>
          <ProductsPicklist
            audience={audience}
            endDate={endDate}
            locationIds={locationIds}
            locations={allLocations}
            onAudienceChange={onAudienceChange}
            onEndChange={onEndChange}
            onStartChange={onStartChange}
            onLocationChange={onLocationChange}
            startDate={startDate}
            timezone={timezone}
          />
        </Route>
        <Redirect to={Routes.ORDERS_PICKLIST} />
        <Redirect from="/operations/ordersPicklist" to={Routes.ORDERS_PICKLIST} />
        <Redirect from="/operations/productsPicklist" to={Routes.PRODUCTS_PICKLIST} />
      </Switch>
    </MuiPickersUtilsProvider>
  );
};

export default Operations;
