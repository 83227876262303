const prefix = "data:image/svg+xml;base64,";

const padding = 5;

export const svgProcessing = (svgURI: string, className: string) => {
  const svgString = atob(svgURI.substring(prefix.length));

  const temp = document.querySelector("#temp");

  if (!temp) {
    console.error("unable to find temp area for svg processing");
  }

  // inject svg to DOM for processing
  temp.innerHTML += svgString;
  const svg = temp.querySelector("svg");

  // adjust viewBox to fit signature properly
  const bbox: DOMRect = svg.getBBox();
  const x = (bbox.x - padding).toFixed();
  const y = (bbox.y - padding).toFixed();
  const width = (bbox.width + 4 * padding).toFixed(3);
  const height = (bbox.height + 4 * padding).toFixed(3);
  const newViewBox = `${x} ${y} ${width} ${height}`;

  svg.setAttribute("class", className);
  svg.setAttribute("viewBox", newViewBox);
  svg.setAttribute("width", width);
  svg.setAttribute("height", height);

  // remove redundant path attributes
  svg.querySelectorAll("path").forEach(p => {
    p.removeAttribute("stroke");
    p.removeAttribute("fill");
    p.removeAttribute("stroke-linecap");
  });
  // inject scoped styles to reduce file size
  svg.insertAdjacentHTML(
    "afterbegin",
    `<style>.${className} > path {stroke-linecap:round;stroke:#000;fill:none;}</style>`,
  );

  const formattedSvgString = prefix + btoa(temp.innerHTML);
  svg.remove();

  return formattedSvgString;
};

export default svgProcessing;
