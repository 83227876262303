import { Grid, TextField, Typography } from "@material-ui/core";
import { useFormContext } from "react-hook-form";

import { AddressForm, AddressFormFields } from "../Address";
import FormCard, { FormCardProps } from "../Form/FormCard";

export type WholesaleClientFormFields = {
  active: boolean;
  clientName: string;
  phone: string;
} & AddressFormFields;

export const WholesaleClientFormDefaultValues: WholesaleClientFormFields = {
  active: true,
  address1: "",
  address2: "",
  city: "",
  clientName: "",
  country: "",
  label: "Default",
  notes: "",
  phone: "",
  postalCode: "",
  region: "",
  sublocality: "",
};

type WholesaleClientFormProps = {
  saving?: boolean;
} & FormCardProps;

export const WholesaleClientForm = ({ saving = false, children, ...props }: WholesaleClientFormProps) => {
  const { control, errors, setValue, register } = useFormContext<WholesaleClientFormFields>();

  const onAddressReset = () =>
    Object.keys(WholesaleClientFormDefaultValues).forEach(key => setValue(key as keyof WholesaleClientFormFields, ""));

  return (
    <FormCard disabled={saving} title="Business info" {...props}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            autoFocus
            fullWidth
            inputRef={register({
              required: "*required",
            })}
            label="Business name"
            name="clientName"
            type="text"
            variant="outlined"
            placeholder="Restaurant / establishment name"
            required
            InputLabelProps={{
              shrink: true,
            }}
            error={!!errors.clientName}
            helperText={errors?.clientName?.message}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            inputRef={register({
              required: "*required",
            })}
            label="Business phone number"
            name="phone"
            type="tel"
            variant="outlined"
            required
            InputLabelProps={{
              shrink: true,
            }}
            error={!!errors.phone}
            helperText={errors?.phone?.message}
          />
        </Grid>
      </Grid>
      <Typography style={{ marginBottom: 8, marginTop: 8 }}>Address</Typography>
      <AddressForm onReset={onAddressReset} control={control} errors={errors} setValue={setValue} />
      {children}
    </FormCard>
  );
};

export default WholesaleClientForm;
