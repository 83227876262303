import { Fade, IconButton, IconButtonProps, Tooltip } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";

export type PasswordToggleProps = {
  visible: boolean;
  showPassword: boolean;
  onToggle: () => void;
  buttonProps: IconButtonProps;
};

export const PasswordToggle = ({ visible, showPassword, onToggle, buttonProps }) => {
  const passwordTooltipText = showPassword ? "Hide" : "Show";
  const VisbilityIcon = showPassword ? VisibilityOff : Visibility;

  return (
    <Fade in={visible}>
      <Tooltip title={passwordTooltipText}>
        <IconButton onClick={onToggle} {...buttonProps}>
          <VisbilityIcon />
        </IconButton>
      </Tooltip>
    </Fade>
  );
};

export default PasswordToggle;
