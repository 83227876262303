import { FormControl, FormHelperText, makeStyles, MenuItem, Select, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";

const options = [
  { imgSrc: "/images/fedex_logo.svg", label: "FedEx" },
  { imgSrc: "/images/purolator_logo.svg", label: "Purolator" },
  { imgSrc: "/images/ups_logo.svg", label: "UPS" },
];
const useStyles = makeStyles(theme => ({
  dropdownOption: {
    alignItems: "center",
    display: "flex",
  },
  logo: {
    height: "100%",
    marginRight: theme.spacing(0.5),
    maxHeight: 40,
    maxWidth: 80,
    objectFit: "cover",
    width: "100%",
  },
}));
const CarrierSelect = ({ value, onChange }) => {
  const classes = useStyles();

  return (
    <FormControl>
      <Typography gutterBottom variant="subtitle1">
        Choose your carrier
      </Typography>
      <Select
        autoWidth
        classes={{ selectMenu: classes.dropdownOption }}
        onChange={onChange}
        value={value}
        variant="outlined"
      >
        {options.map(option => (
          <MenuItem key={option.label} value={option.label.toLowerCase()}>
            <img alt="logo" className={classes.logo} src={option.imgSrc} /> {option.label}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>Please note you may only choose one carrier for your account.</FormHelperText>
    </FormControl>
  );
};

CarrierSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOf(["fedex", "ups", "purolator"]).isRequired,
};

export default CarrierSelect;
