import { useMutation } from "@apollo/client";
import PropTypes from "prop-types";
import { useEffect } from "react";

import { isLoggedInVar } from "../../../cache/authPolicy";
import Routes from "../../../Constants/Routes";
import CreateSupplierAccount from "../../../mutations/CreateSupplierAccount";
import SignupSection from "../components/SignupSection";

const SignupPage = ({ history }) => {
  useEffect(() => {
    if (isLoggedInVar() || localStorage.getItem("auth_token")) {
      history.push(Routes.PRODUCT_LIST);
    }
  }, [history]);

  const [supplierSignupFunction, { loading }] = useMutation(CreateSupplierAccount, {
    onCompleted({ supplierCreateAccount: token }) {
      if (token) {
        window.location.href = "/";
      }
    },
  });

  return <SignupSection handleSignup={supplierSignupFunction} loading={loading} />;
};

SignupPage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default SignupPage;
