import { Address as AddressType, FulfillmentMethod } from "@arowana/graphql";
import { OnWheelBlur } from "@arowana/ui";
import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  InputAdornment,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import moment from "moment";
import { useContext, useState } from "react";
import { Controller, UseFormMethods } from "react-hook-form";

import FormTooltip from "../../../components/FormTooltip";
import { FlagsmithContext } from "../../context/FlagsmithContext";
import Address from "./Address";
import BaseWeight from "./BaseWeight";
import CutoffHour from "./CutoffHour";
import FulfillmentTimes from "./FulfillmentTimes";
import LocationLeadTimes from "./LocationLeadTimes";
import SwitchToggle from "./SwitchToggle";

const useStyles = makeStyles(theme => ({
  subheading: {
    "& hr": {
      margin: theme.spacing(0, -4, 0),
    },
    "margin": theme.spacing(4, -2.5, 2),
    "overflow": "hidden",
    "paddingLeft": theme.spacing(2),
    "paddingRight": theme.spacing(2),
  },
  tipToggler: {
    marginLeft: 0,
  },
}));

const DiscountedDeliveryFee = ({ fulfillmentMethod, register }) => (
  <Grid item md={6} xs={12}>
    <Typography gutterBottom variant="subtitle1">
      Discounted {fulfillmentMethod} fee{" "}
      <Typography color="textSecondary" variant="caption">
        (optional)
      </Typography>
    </Typography>
    <TextField
      InputProps={{
        inputProps: {
          min: 0,
          step: 0.01,
        },
        onWheel: OnWheelBlur,
        startAdornment: <InputAdornment position="start">$</InputAdornment>,
      }}
      fullWidth
      inputRef={register({
        min: {
          message: "must be at least 0",
          value: 0,
        },
        valueAsNumber: true,
      })}
      name="discountedDeliveryFee"
      type="number"
      variant="outlined"
    />
    <Typography color="textSecondary" variant="caption">
      {fulfillmentMethod.replace(/^\w/, letter => letter.toUpperCase())} fee when the customer's cart sub-total reaches
      the minimum purchase value.
    </Typography>
  </Grid>
);

const DiscountedDeliveryFeeThreshold = ({ fulfillmentMethod, register }) => (
  <Grid item md={6} xs={12}>
    <Typography gutterBottom variant="subtitle1">
      Minimum purchase value for discounted {fulfillmentMethod}{" "}
      <Typography color="textSecondary" variant="caption">
        (optional)
      </Typography>
    </Typography>
    <TextField
      InputProps={{
        inputProps: {
          min: 0,
          step: 0.01,
        },
        onWheel: OnWheelBlur,
        startAdornment: <InputAdornment position="start">$</InputAdornment>,
      }}
      fullWidth
      inputRef={register({
        min: {
          message: "must be at least 0",
          value: 0,
        },
        valueAsNumber: true,
      })}
      name="discountedDeliveryFeeThreshold"
      type="number"
      variant="outlined"
    />
    <Typography color="textSecondary" variant="caption">
      When the customer's cart sub-total reaches this value, they will receive the discounted {fulfillmentMethod} fee.
    </Typography>
  </Grid>
);

interface FulfillmentDetailsProps {
  address: AddressType;
  clearErrors: UseFormMethods["clearErrors"];
  control: UseFormMethods["control"];
  discountedDeliveryFee: number;
  discountedDeliveryFeeThreshold: number;
  errors: UseFormMethods["errors"];
  fulfillmentMethod: FulfillmentMethod;
  register: UseFormMethods["register"];
  setValue: UseFormMethods["setValue"];
}

const FulfillmentDetails = ({
  address,
  clearErrors,
  control,
  discountedDeliveryFee,
  discountedDeliveryFeeThreshold,
  errors,
  fulfillmentMethod,
  register,
  setValue,
}: FulfillmentDetailsProps) => {
  const classes = useStyles();
  const flagsmith = useContext(FlagsmithContext);
  const hasRetail = flagsmith.hasFeature("b2c");
  const isDelivery = fulfillmentMethod === FulfillmentMethod.delivery;
  const isShipping = fulfillmentMethod === FulfillmentMethod.shipping;
  const isPickup = fulfillmentMethod === FulfillmentMethod.pickup;

  const [enableDiscountedDelivery, setEnableDiscountedDelivery] = useState(
    Number.isFinite(discountedDeliveryFee) && Number.isFinite(discountedDeliveryFeeThreshold),
  );

  const handleChangeEnableDiscountedDelivery = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEnableDiscountedDelivery(event.target.checked);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <div className={classes.subheading}>
          <Typography gutterBottom variant="h5" component="h2">
            Fulfillment address
          </Typography>
          <Divider variant="fullWidth" />
        </div>
      </Grid>

      <Grid item xs={12}>
        <Address
          clearErrors={clearErrors}
          control={control}
          errors={errors}
          register={register}
          required={!isDelivery}
          setValue={setValue}
          streetAddressLabel={isPickup ? "Pick up from" : isShipping ? "Shipping from" : "Shipping from (optional)"}
        />
      </Grid>

      <Grid item xs={12}>
        <div className={classes.subheading}>
          <Typography gutterBottom variant="h5" component="h2">
            Time & date
          </Typography>
          <Divider variant="fullWidth" />
        </div>
      </Grid>

      <Grid container spacing={2} xs={12}>
        <Grid item md={6} xs={12}>
          <Typography gutterBottom variant="subtitle1">
            Order cutoff hour{" "}
            <FormTooltip content="Any orders placed after this cutoff time will be assigned to the next available cutoff date." />
          </Typography>
          <Controller as={<CutoffHour />} control={control} name="cutOffHour" />
        </Grid>

        <Grid item md={6} xs={12}>
          <Typography gutterBottom variant="subtitle1">
            {`${isPickup ? "Pickup" : "Delivery"} windows`}{" "}
            <Typography color="textSecondary" variant="caption">
              (optional)
            </Typography>
          </Typography>
          <Controller
            as={<FulfillmentTimes />}
            control={control}
            helperText={errors?.fulfillmentTimes?.message}
            name="fulfillmentTimes"
            rules={{
              validate: value =>
                value.every(
                  ({ startHour, startClock, endHour, endClock }) =>
                    Number.isInteger(startHour) &&
                    Number.isInteger(endHour) &&
                    moment(`${startHour}${startClock}`, ["hA"]).hour() < moment(`${endHour}${endClock}`, ["hA"]).hour(),
                ) ||
                "make sure both start time and end time are provided and each start time is before the corresponding end time",
            }}
          />
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <div className={classes.subheading}>
          <Typography gutterBottom variant="h5" component="h2">
            Lead times
          </Typography>
          <Divider variant="fullWidth" />
        </div>
        <Controller
          as={<LocationLeadTimes />}
          control={control}
          helperText={errors?.leadTimes?.message}
          name="leadTimes"
          rules={{ validate: value => Object.keys(value).length > 0 || "please choose at least one fulfillment date" }}
        />
      </Grid>

      <Grid item xs={12}>
        <div className={classes.subheading}>
          <Typography gutterBottom variant="h5" component="h2">
            Fees & amounts
          </Typography>
          <Divider variant="fullWidth" />
        </div>
      </Grid>

      <Grid container item spacing={2} xs={12}>
        <Grid item md={6} xs={12}>
          <Typography gutterBottom variant="subtitle1">
            Minimum purchase to place an order
          </Typography>
          <TextField
            InputProps={{
              inputProps: {
                min: 0,
                step: 0.01,
              },
              onWheel: OnWheelBlur,
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            error={Boolean(errors?.minimumOrderAmount)}
            fullWidth
            helperText={errors?.minimumOrderAmount?.message}
            inputRef={register({
              min: {
                message: "must be at least 0",
                value: 0,
              },
              required: "*required",
              valueAsNumber: true,
            })}
            name="minimumOrderAmount"
            type="number"
            variant="outlined"
          />
        </Grid>

        <Grid item md={6} xs={12}>
          {hasRetail && (
            <>
              <Controller
                as={<SwitchToggle />}
                className={classes.tipToggler}
                control={control}
                label="Enable tipping at checkout"
                name="tipsEnabled"
              />
              <Typography color="textSecondary" component="p">
                Tip percentages are calculated based on the order total.{" "}
              </Typography>
            </>
          )}
        </Grid>

        <Grid item md={6} xs={12}>
          <Typography gutterBottom variant="subtitle1">
            {isDelivery ? "Delivery fee " : isPickup ? "Pickup fee " : "Flat rate shipping fee "}
            <Typography color="textSecondary" variant="caption">
              (optional)
            </Typography>
          </Typography>
          <TextField
            InputProps={{
              inputProps: {
                min: 0,
                step: 0.01,
              },
              onWheel: OnWheelBlur,
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            error={Boolean(errors?.deliveryFee)}
            fullWidth
            helperText={errors?.deliveryFee?.message}
            inputRef={register({
              min: {
                message: "must be at least 0",
                value: 0,
              },
              valueAsNumber: true,
            })}
            name="deliveryFee"
            type="number"
            variant="outlined"
          />
          {!isPickup && (
            <Typography color="textSecondary" variant="caption">
              Override carrier-estimated shipping rates on checkout.
            </Typography>
          )}
        </Grid>

        <Grid item md={6} xs={12}>
          <Typography gutterBottom variant="subtitle1">
            {`Minimum purchase for free ${fulfillmentMethod} `}
            <Typography color="textSecondary" variant="caption">
              (optional)
            </Typography>
          </Typography>
          <TextField
            InputProps={{
              inputProps: {
                min: 0,
                step: 0.01,
              },
              onWheel: OnWheelBlur,
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            error={Boolean(errors?.freeDeliveryAmount)}
            fullWidth
            helperText={errors?.freeDeliveryAmount?.message}
            inputRef={register({
              min: {
                message: "must be at least 0",
                value: 0,
              },
              valueAsNumber: true,
            })}
            name="freeDeliveryAmount"
            type="number"
            variant="outlined"
          />
        </Grid>

        <Grid container item xs={12}>
          <Grid item xs={12}>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={enableDiscountedDelivery}
                    color="primary"
                    name="enable-discounted-delivery"
                    onChange={handleChangeEnableDiscountedDelivery}
                  />
                }
                label={`Enable discounted ${fulfillmentMethod}?`}
              />
            </FormGroup>
          </Grid>
          <Box marginBottom={2}>
            <Typography color="textSecondary" variant="caption">
              Discounted {fulfillmentMethod} allows your customers to receive a reduced {fulfillmentMethod} fee if their
              cart sub-total reaches a certain value.
            </Typography>
          </Box>
          {enableDiscountedDelivery && (
            <Grid container item spacing={2} xs={12}>
              <DiscountedDeliveryFee fulfillmentMethod={fulfillmentMethod} register={register} />
              <DiscountedDeliveryFeeThreshold fulfillmentMethod={fulfillmentMethod} register={register} />
            </Grid>
          )}
        </Grid>
      </Grid>

      {isShipping && (
        <>
          <Grid item xs={12}>
            <div className={classes.subheading}>
              <Typography gutterBottom variant="h5" component="h2">
                Shipping details
              </Typography>
              <Divider variant="fullWidth" />
            </div>
          </Grid>

          <Grid container item spacing={2} xs={12}>
            <Controller
              as={<BaseWeight />}
              control={control}
              helperText={errors?.shippingBaseWeightObject?.message}
              name="shippingBaseWeightObject"
              rules={{ validate: value => value.shippingBaseWeight > 0 || "must be greater than 0" }}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default FulfillmentDetails;
