const FULL_SUPPLIER = `
  id
  defaultLocationId
  defaultNetTerms
  orderDaysInAdvance
  primaryUser
  name
  urlName
  currency
  paymentHoldMultiplier
  takePercentage
  locations {
    _id
    cutOffHour
    deliveryFee
    discountedDeliveryFee
    discountedDeliveryThreshold
    geometry {
      coordinates
    }
    fulfillmentMethod
    leadTimes
    minimumOrder
    name
    pickupAddress {
      _id
      current
      displayName
      addressLine
      postalCode
      country
      city
      province
      timezone
      lnglat {
        type
        coordinates
      }
      deliveryInstructions
      unitNumber
    }
    sameDayCutoff
    shippingBaseWeight
    shippingBaseWeightUnit
    tipsEnabled
  }
  notificationSettings {
    emails
    phoneNumbers
  }
  stripeSettings {
    stripeConnectToken
  }
  uiSettings {
    darkModeEnabled
    fontFamily
    logo
    description
    banner {
      isEnabled
      message
      title
    }
    carousel {
      active
      style
      slides {
        image {
          original
          x256
          x512
          x896
          x1664
        }
        header
        description
        link {
          name
          value
        }
      }
    }
    navLinks {
      name
      value
    }
    footerLinks {
      name
      value
    }
    colors {
      primary
      secondary
    }
    faq {
      question
      answer
    }
  }
  active
  timeZone
  createdAt
  updatedAt
  supportPhoneNumber
  supportEmail
  facebookLink
  twitterLink
  instagramLink
  youtubeLink
  homepageProducts {
    _id
  }
  taxableProducts {
    _id
    name
  }
  categories {
    id
    name
    tags {
      label
      value
    }
  }
`;

export default FULL_SUPPLIER;
