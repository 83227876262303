import {
  Box,
  IconButton,
  Link,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import {
  ArrowDownward as ArrowDownwardIcon,
  ArrowUpward as ArrowUpwardIcon,
  Delete as DeleteIcon,
} from "@material-ui/icons";
import { useMemo, useState } from "react";
import { Link as RouterLink } from "react-router-dom";

import Routes from "../../../Constants/Routes";
import ClientAutocomplete from "../components/ClientAutocomplete";
import { ClientProps } from "../pages/List";

const useStyles = makeStyles(theme => ({
  empty: {
    backgroundColor: theme.palette.grey[200],
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1, 0),
  },
  lastRow: {
    "&:last-child td": {
      borderBottom: "none",
    },
  },
  searchClient: { marginBottom: theme.spacing(2) },
  sortIcon: {
    "&:hover": {
      cursor: "pointer",
    },
    "verticalAlign": "sub",
  },
  sortableField: {
    color: theme.palette.primary.main,
  },
  table: {
    borderRadius: theme.shape.borderRadius,
    maxHeight: 500,
  },
}));

const ClientRow = ({ client, removeClient }) => {
  const classes = useStyles();

  return (
    <TableRow className={classes.lastRow}>
      <TableCell style={{ minWidth: 150 }}>
        <Link component={RouterLink} to={Routes.WHOLESALE_CLIENT_DETAILS.replace(":id", client.clientId)}>
          <Typography>{client.name}</Typography>
        </Link>
      </TableCell>

      <TableCell style={{ minWidth: 100 }}>
        <Typography>{client.phone}</Typography>
      </TableCell>

      <TableCell>
        <Typography>{client.repAccountId ? "✔︎" : ""}</Typography>
      </TableCell>

      <TableCell style={{ minWidth: 100 }} align="center">
        <IconButton edge="end" onClick={removeClient}>
          <DeleteIcon fontSize="small" />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export interface ClientSearchType {
  id: string;
  name: string;
  phone: string;
  supplier_id: string;
  rep_account_id: string;
}

interface ClientsListProps {
  onChange: (clients: ClientProps[]) => void;
  value: ClientProps[];
}

const ClientsList = ({ onChange, value }: ClientsListProps) => {
  const classes = useStyles();
  const [sortDirection, setSortDirection] = useState("asc");
  const clientIdIndexMap = useMemo(() => {
    const record = new Map();
    value?.forEach(({ clientId }, index) => record.set(clientId, index));

    return record;
  }, [value]);

  const sortedClients: ClientProps[] = useMemo(() => {
    return value?.slice()?.sort((pre, next) => {
      const comp = pre.name.localeCompare(next.name);

      if (sortDirection === "asc") {
        return comp;
      } else {
        return -comp;
      }
    });
  }, [sortDirection, value]);

  const onAddClient = (clientToAdd: ClientSearchType) => {
    const { id: clientId, name, phone, rep_account_id } = clientToAdd;

    onChange([
      ...value,
      {
        clientId,
        name,
        phone,
        repAccountId: rep_account_id,
      },
    ]);
  };

  const onRemoveClient = index => {
    onChange([...value.slice(0, index), ...value.slice(index + 1)]);
  };

  const handleSort = () => setSortDirection(sortDirection === "asc" ? "desc" : "asc");

  return (
    <>
      <ClientAutocomplete
        className={classes.searchClient}
        filterIds={new Set(value?.map(({ clientId }) => clientId))}
        helperText="Search for a business and select it from the dropdown to grant them access to this price list."
        label="Add business to price list"
        onChange={onAddClient}
      />

      <TableContainer className={classes.table}>
        <Table size="small" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell className={classes.sortableField}>
                Business name{" "}
                {sortDirection === "asc" ? (
                  <ArrowUpwardIcon fontSize="small" className={classes.sortIcon} onClick={handleSort} />
                ) : (
                  <ArrowDownwardIcon fontSize="small" className={classes.sortIcon} onClick={handleSort} />
                )}
              </TableCell>
              <TableCell>Phone</TableCell>
              <TableCell>Has rep?</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedClients?.map(client => (
              <ClientRow
                key={client.clientId}
                client={client}
                removeClient={() => onRemoveClient(clientIdIndexMap.get(client.clientId))}
              />
            ))}
          </TableBody>
        </Table>
        {value?.length === 0 && (
          <Box className={classes.empty} textAlign="center">
            <Typography>No businesses selected</Typography>
          </Box>
        )}
      </TableContainer>
    </>
  );
};

export default ClientsList;
