import { makeStyles, MenuItem, Select } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";

const useStyles = makeStyles(theme => ({
  dropdown: {
    "&:not(:last-child)": { marginRight: theme.spacing(2) },
    "width": theme.spacing(10),
  },
}));
const getHour = fullHour => {
  // fullHour's range is [0, 24], don't understand why 24 is there since it's the same as 0
  if (fullHour === 0 || fullHour === 24) {
    return [12, "AM"];
  }

  if (fullHour === 12) {
    return [12, "PM"];
  }

  if (fullHour >= 1 && fullHour <= 11) {
    return [fullHour, "AM"];
  }

  return [fullHour - 12, "PM"];
};
const toFullHour = (hour, period) => {
  if (hour === 12) {
    if (period === "AM") {
      return 0;
    }

    return 12;
  }

  if (period === "AM") {
    return hour;
  }

  return hour + 12;
};
const CutoffHour = ({ value, onChange }) => {
  const classes = useStyles();
  const [hour, period] = getHour(value);
  const handleChangeHour = e => {
    onChange(toFullHour(e.target.value, period));
  };
  const handleChangePeriod = e => {
    onChange(toFullHour(hour, e.target.value));
  };

  return (
    <>
      <Select className={classes.dropdown} onChange={handleChangeHour} value={hour} variant="outlined">
        {[...Array(13).keys()].slice(1).map(option => (
          <MenuItem key={`${option}-cutoff`} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
      <Select className={classes.dropdown} onChange={handleChangePeriod} value={period} variant="outlined">
        <MenuItem value="AM">AM</MenuItem>
        <MenuItem value="PM">PM</MenuItem>
      </Select>
    </>
  );
};

CutoffHour.defaultProps = {
  onChange: () => {},
  value: 0,
};

CutoffHour.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.number,
};

export default CutoffHour;
