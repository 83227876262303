import PropTypes from "prop-types";
import React from "react";
import { Droppable } from "react-beautiful-dnd";

import Image from "./Image";

const ImageGallery = ({ images, deleteImage, droppableId }) => (
  <Droppable direction="horizontal" droppableId={droppableId}>
    {provided => (
      <div style={{ display: "flex", flexWrap: "wrap" }} {...provided.droppableProps} ref={provided.innerRef}>
        {images.map((image, index) => (
          <Image deleteImage={deleteImage} index={index} key={image.original} original={image.original} />
        ))}
        {provided.placeholder}
      </div>
    )}
  </Droppable>
);

ImageGallery.propTypes = {
  deleteImage: PropTypes.func.isRequired,
  droppableId: PropTypes.string.isRequired,
  images: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ImageGallery;
