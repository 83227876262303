import { gql } from "@apollo/client";

export const ListAddProducts = gql`
  mutation ListAddProductions($_id: ID!, $productIds: [ID!]!) {
    listAddProducts(_id: $_id, productIds: $productIds) {
      _id
    }
  }
`;

export const ListRemoveProducts = gql`
  mutation ListRemoveProductions($_id: ID!, $productIds: [ID!]!) {
    listRemoveProducts(_id: $_id, productIds: $productIds) {
      _id
    }
  }
`;
