import { useMutation } from "@apollo/client";
import { default as React, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { notificationVar } from "../../../cache/notificationPolicy";
import Routes from "../../../Constants/Routes";
import supplierPaymentAttachFromSession from "../../../queries/supplierPaymentAttachFromSession";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const PaymentStripeSuccess = () => {
  const history = useHistory();
  const query = useQuery();
  const sessionId = query.get("sessionId");
  const [attach] = useMutation(supplierPaymentAttachFromSession, {
    async onCompleted() {
      notificationVar({
        message: "Payment successfully attached!",
        severity: "info",
      });
      history.push(Routes.PAYMENTS);
    },
  });

  useEffect(() => {
    attach({
      variables: { sessionId },
    });
  }, [attach, sessionId]);

  return (
    <div>
      <h1>Success!</h1>
      <p>You are being redirected.</p>
    </div>
  );
};

export default PaymentStripeSuccess;
