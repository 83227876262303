import { useMutation, useQuery } from "@apollo/client";
import { ProductUnit, ShippingWeightUnit } from "@arowana/graphql";
import { DATALAYER } from "@arowana/util";
import {
  Button,
  Grid,
  Link,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { camelCase } from "lodash";
import { useContext, useMemo, useState } from "react";
import CSVReader from "react-csv-reader";
import slugify from "slugify";

import { notificationVar } from "../../../cache/notificationPolicy";
import Loader from "../../../components/Loader";
import PageHeader from "../../../components/PageHeader";
import ProductCreateMany from "../../../queries/productCreateMany";
import { AccountContext } from "../../context/AccountContext";
import { CATEGORIES } from "./CategoryList";

const useStyles = makeStyles(theme => ({
  container: {
    maxHeight: "100%",
  },
  csvInput: {
    border: "1px solid #ccc",
    borderRadius: theme.shape.borderRadius,
    margin: theme.spacing(2, 0),
    padding: theme.spacing(2),
  },
  infoIcon: {
    color: "#757575",
    marginLeft: theme.spacing(1),
  },
  link: {
    color: theme.palette.primary,
    textDecoration: "none",
  },
}));

let currentRowCategory = "";
const checkExistence = text => Boolean(text);
const checkCategory = (productCategories, inputCategory) => {
  const result = productCategories.some(({ name }) => name === inputCategory);

  if (result) {
    currentRowCategory = inputCategory;
  }

  return result;
};
const checkTag = (productCategories, tags) => {
  if (!tags) {
    return false;
  }

  const inputTagsArray = tags.split(",").map(tag => slugify(tag.trim(), { lower: true, strict: true }));
  const currentRowCategoryTags = productCategories
    .find(({ name }) => name === currentRowCategory)
    ?.tags?.map(({ value }) => value);

  if (!currentRowCategoryTags) {
    return false;
  }

  const result = inputTagsArray.every(element => currentRowCategoryTags.includes(element));

  return result;
};
const cleanImages = images => images.replace(/\s+/g, "").split(",");
const checkProductUnits = productUnit => productUnit in ProductUnit;
const checkShippingUnits = shippingUnit => shippingUnit in ShippingWeightUnit;
const checkIsNumber = val => typeof val === "number";
const checkIsBoolean = val => typeof val === "boolean";
const checkHeaders = (inputHeaders, headersSet) => Object.keys(inputHeaders).every(header => headersSet.has(header));

const parserOptions = {
  dynamicTyping: {
    active: true,
    caseSize: true,
    category: true,
    description: true,
    featured: true,
    images: true,
    inventoryAmount: true,
    inventoryCode: false,
    name: true,
    price: true,
    shippingWeight: true,
    shippingWeightUnit: true,
    subName: true,
    tags: true,
    units: true,
  },
  header: true,
  skipEmptyLines: true,
  transformHeader: header => camelCase(header),
};
const Error = ({ errorMessage }) => (
  <Typography color="error" variant="body2">
    {errorMessage}
  </Typography>
);
const convertToMutationVariables = (products, categoryNameIdMap) =>
  products.map(product => ({
    active: product.active,
    categoryId: categoryNameIdMap[product.category],
    description: product.description,
    featured: product.featured,
    images: product.images.split(",").map(img => img.trim()),
    name: product.name,
    tags: product.tags.split(",").map(tag => slugify(tag.trim(), { lower: true, strict: true })),
    variants: [
      {
        caseSize: product.caseSize,
        name: product.subName,
        outOfStock: false,
        price: Math.round(product.price * 100),
        quantity: Number.isNaN(parseFloat(product.inventoryAmount)) ? null : product.inventoryAmount,
        shippingWeight: product.shippingWeight,
        shippingWeightUnit: product.shippingWeightUnit.toUpperCase().trim(),
        sku: product.inventoryCode || null,
        unit: product.units.toUpperCase().trim(),
      },
    ],
  }));

const BulkImportProducts = () => {
  const {
    supplier: { id: supplierId },
  } = useContext(AccountContext);
  const { data, loading: fetching } = useQuery(CATEGORIES, {
    context: { source: DATALAYER },
    fetchPolicy: "cache-and-network",
    variables: { supplierId },
  });
  const classes = useStyles();
  const [csvData, setCSVData] = useState([]);
  const [disableSubmit, setDisableSubmit] = useState(true);
  const productUnitOptions = Object.values(ProductUnit).join(", ");
  const shippingUnitOptions = Object.values(ShippingWeightUnit).join(", ");
  const [categories, categoryNameIdMap, productCategoryOptions] = useMemo(() => {
    const categories = data?.categories ?? [];
    const nameIdMap = categories.reduce((acc, cur) => ({ ...acc, [cur.name]: cur.id }), {});
    const options = categories.map(obj => obj.name)?.join(", ");

    return [categories, nameIdMap, options];
  }, [data]);

  const columns = [
    {
      format: value => (checkExistence(value) ? value : <Error errorMessage="Error: product name cannot be empty" />),
      id: "name",
      label: "Name",
      minWidth: "150px",
    },
    {
      format: value => (checkExistence(value) ? value : <Error errorMessage="Error: subname cannot be empty" />),
      id: "subName",
      label: "Subname",
      minWidth: "150px",
    },
    {
      align: "left",
      format: value =>
        checkExistence(value) ? (
          cleanImages(value)?.map((image, index) => (
            <img alt="product-img" height="45" key={`product-img-${index}`} src={image} width="45" />
          ))
        ) : (
          <Error errorMessage="Error: image cannot be empty" />
        ),
      id: "images",
      label: "Images",
      minWidth: "150px",
    },
    {
      align: "left",
      format: value =>
        checkIsBoolean(value) ? value.toString() : <Error errorMessage="Error: featured must be true or false" />,
      id: "featured",
      label: "Featured",
      minWidth: "150px",
    },
    {
      align: "left",
      format: value =>
        checkIsBoolean(value) ? value.toString() : <Error errorMessage="Error: active must be true or false" />,
      id: "active",
      label: "Active",
      minWidth: "150px",
    },
    {
      align: "left",
      id: "inventoryCode",
      label: "SKU (Optional)",
      minWidth: "150px",
    },
    {
      align: "left",
      format: value =>
        value !== null && checkIsNumber(value)
          ? value.toString()
          : value && <Error errorMessage="Error: inventory amount (optional) must be a number if provided" />,
      id: "inventoryAmount",
      label: "Inventory (Optional)",
      minWidth: "150px",
    },
    {
      align: "left",
      format: value =>
        checkProductUnits(value.toUpperCase().trim()) ? (
          value.toUpperCase().trim()
        ) : (
          <Error errorMessage="Error: invalid product unit" />
        ),
      id: "units",
      label: (
        <Grid alignItems="center" container item>
          Unit
          <Tooltip arrow title={`Unit must be one of: ${productUnitOptions}`}>
            <InfoOutlinedIcon className={classes.infoIcon} fontSize="small" />
          </Tooltip>
        </Grid>
      ),
      minWidth: "150px",
    },
    {
      align: "left",
      format: value =>
        checkIsNumber(value) ? value.toString() : <Error errorMessage="Error: case size must be a number" />,
      id: "caseSize",
      label: "Case Size",
      minWidth: "150px",
    },
    {
      align: "left",
      format: value =>
        checkShippingUnits(value.toUpperCase().trim()) ? (
          value.toUpperCase().trim()
        ) : (
          <Error errorMessage="Error: invalid shipping unit" />
        ),
      id: "shippingWeightUnit",
      label: (
        <Grid alignItems="center" container item>
          Shipping Unit
          <Tooltip arrow title={`Unit must be one of: ${shippingUnitOptions}`}>
            <InfoOutlinedIcon className={classes.infoIcon} fontSize="small" />
          </Tooltip>
        </Grid>
      ),
      minWidth: "150px",
    },
    {
      align: "left",
      format: value =>
        checkIsNumber(value) ? value.toString() : <Error errorMessage="Error: shipping weight must be a number" />,
      id: "shippingWeight",
      label: "Shipping Weight",
      minWidth: "150px",
    },
    {
      align: "left",
      format: value => (checkIsNumber(value) ? value : <Error errorMessage="Error: price must be a number" />),
      id: "price",
      label: "Price",
      minWidth: "150px",
    },
    {
      align: "left",
      format: value => (checkExistence(value) ? value : <Error errorMessage="Error: description cannot be empty" />),
      id: "description",
      label: "Description",
      minWidth: "150px",
    },
    {
      align: "left",
      format: value => (checkCategory(categories, value) ? value : <Error errorMessage="Error: invalid category" />),
      id: "category",
      label: (
        <Grid alignItems="center" container item>
          Category
          <Tooltip arrow title={`Categories are case sensitive and must be one of: ${productCategoryOptions}`}>
            <InfoOutlinedIcon className={classes.infoIcon} fontSize="small" />
          </Tooltip>
        </Grid>
      ),
      minWidth: "150px",
    },
    {
      align: "left",
      format: value =>
        checkTag(categories, value) ? value : <Error errorMessage="Error: some tag doesn't exist in category" />,
      id: "tags",
      label: (
        <Grid alignItems="center" container item>
          Tags
          <Tooltip arrow title="Tags are case sensitive and must belong to the corresponding category">
            <InfoOutlinedIcon className={classes.infoIcon} fontSize="small" />
          </Tooltip>
        </Grid>
      ),
      minWidth: "150px",
    },
  ];
  const [productCreateMany, { loading: uploadLoading }] = useMutation(ProductCreateMany, {
    onCompleted: () => {
      notificationVar({
        message: "Products uploaded!",
        severity: "success",
      });
      setCSVData([]);
    },
    onError: () => {
      setCSVData([]);
    },
  });
  const handleProductSubmit = () => {
    const input = convertToMutationVariables(csvData, categoryNameIdMap);
    productCreateMany({
      variables: {
        input,
      },
    });
  };
  const handleFileLoaded = data => {
    if (checkHeaders(data[0], new Set(columns.map(({ id }) => id)))) {
      setCSVData(data);
      setDisableSubmit(false);
    } else {
      notificationVar({
        message: "Incorrect CSV Format. Make sure your columns are the same as the sample.",
        severity: "error",
      });
    }
  };

  if (fetching || uploadLoading) {
    return <Loader />;
  }

  return (
    <>
      <PageHeader
        primaryActions={
          <Button
            color="primary"
            disabled={disableSubmit || uploadLoading}
            onClick={handleProductSubmit}
            variant="contained"
          >
            Upload Products
          </Button>
        }
        stickyHeader
        title="Bulk Product Upload"
      />

      <Typography variant="body2">
        To get started, upload a CSV of the products you want to import. Refer to the following template when uploading:{" "}
        <Link
          className={classes.link}
          download
          href="https://freshlinestatic.com/sampleProductUpload.csv"
          target="_blank"
        >
          sample CSV
        </Link>
        .
      </Typography>

      <CSVReader
        cssInputClass={classes.csvInput}
        inputId="productData"
        onFileLoaded={handleFileLoaded}
        parserOptions={parserOptions}
      />

      <Paper>
        <TableContainer className={classes.container}>
          <Table aria-label="sticky table" stickyHeader>
            <TableHead>
              <TableRow>
                {columns.map(column => (
                  <TableCell align={column.align} key={column.id} style={{ maxWidth: column.width }}>
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {csvData.map(row => (
                <TableRow hover key={row.name} role="checkbox" tabIndex={-1}>
                  {columns.map(column => {
                    const value = row[column.id];

                    return (
                      <TableCell align={column.align} key={column.id} style={{ width: column.width }}>
                        {column.format ? column.format(value) : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};

export default BulkImportProducts;
