import { FormControl, FormLabel, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import highlightMatch from "autosuggest-highlight/match";
import highlightParse from "autosuggest-highlight/parse";

export interface WholesaleList {
  id: string;
  name: string;
}

interface WholesaleListAutocompleteProps {
  defaultWholesaleList?: WholesaleList;
  label?: string;
  onChange: (list: WholesaleList) => void;
  onRemoveDefaultList: () => void;
  wholesaleLists: WholesaleList[];
}

const WholesaleListAutocomplete = ({
  defaultWholesaleList,
  label,
  onChange,
  onRemoveDefaultList,
  wholesaleLists,
}: WholesaleListAutocompleteProps) => {
  return (
    <FormControl component="fieldset" fullWidth>
      {label && <FormLabel component="legend">{label}</FormLabel>}
      <Autocomplete
        autoHighlight
        clearOnBlur
        getOptionLabel={option => option?.name ?? ""}
        getOptionSelected={(option, selectedValue) => option.id === selectedValue?.id}
        handleHomeEndKeys
        id="default-pricelist-select"
        noOptionsText="No wholesale lists found"
        onChange={(_, newValue: WholesaleList, reason) => {
          if (reason === "select-option") {
            onChange(newValue);
          } else if (reason === "clear") {
            onRemoveDefaultList();
          }
        }}
        options={wholesaleLists}
        renderInput={params => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              "aria-describedby": "default-pricelist-select-helper",
            }}
            margin="dense"
            label="Choose a price list"
            placeholder="Start searching for a price list"
            variant="outlined"
          />
        )}
        renderOption={(option, { inputValue }) => {
          const matches = highlightMatch(option.name, inputValue);
          const parts = highlightParse(option.name, matches);

          return (
            <span style={{ flexGrow: 1 }}>
              {parts.map((part, index) => (
                <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                  {part.text}
                </span>
              ))}
            </span>
          );
        }}
        selectOnFocus
        size="small"
        value={defaultWholesaleList}
      />
    </FormControl>
  );
};

export default WholesaleListAutocomplete;
