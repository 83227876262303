import { Chip, TextField, Theme } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { createStyles, makeStyles } from "@material-ui/styles";
import highlightMatch from "autosuggest-highlight/match";
import highlightParse from "autosuggest-highlight/parse";
import clsx from "clsx";
import capitalize from "lodash/capitalize";
import startCase from "lodash/startCase";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chip: {
      fontSize: "0.6rem",
      marginLeft: theme.spacing(1),
    },
    delivery: {
      borderColor: theme.palette.delivery.main,
      color: theme.palette.delivery.main,
    },
    option: {
      '&[aria-selected="true"]': {
        backgroundColor: "transparent",
      },
      '&[data-focus="true"]': {
        backgroundColor: theme.palette.action.hover,
      },
      "alignItems": "flex-start",
      "minHeight": "auto",
      "padding": theme.spacing(1),
    },
    optionText: {
      flexGrow: 1,
    },
    pickup: {
      borderColor: theme.palette.pickup.main,
      color: theme.palette.pickup.main,
    },
    shipping: {
      borderColor: theme.palette.shipping.main,
      color: theme.palette.shipping.main,
    },
  }),
);

const ServiceLocation = ({
  disabled,
  loading,
  locations,
  orderLocation,
  setOrderLocation,
  setStatesDirty,
  setValue,
}) => {
  const classes = useStyles();
  const handleLocationChange = (_, location) => {
    setOrderLocation(location);

    setValue("fulfillmentMethod", location.fulfillmentMethod);
    // don't proivide "undefined" as a value for setValue!
    setValue("fulfillmentTime", location.fulfillmentTimes?.[0]?.id ?? "");

    if (location.fulfillmentMethod === "pickup") {
      setValue("address", location.address);
    }

    // Set default value for "label" field if not present on address (or if address doesn't exist).
    if (!location.address?.label) {
      setValue("address.label", `${startCase(location.fulfillmentMethod)} address`);
    }

    setStatesDirty(true);
  };

  return (
    <Autocomplete
      autoHighlight
      classes={{
        option: classes.option,
      }}
      clearOnBlur
      disableClearable
      disabled={disabled}
      getOptionLabel={option => option.name}
      getOptionSelected={(option, value) => option._id === value._id}
      handleHomeEndKeys
      id="service-location-select"
      loading={loading}
      noOptionsText="No locations found"
      onChange={handleLocationChange}
      options={locations}
      renderInput={params => (
        <>
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              "aria-describedby": "service-location-select-helper",
              "autoComplete": "new-password",
            }}
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {orderLocation && (
                    <Chip
                      className={clsx(classes.chip, classes[orderLocation.fulfillmentMethod])}
                      label={capitalize(orderLocation.fulfillmentMethod)}
                      size="small"
                      variant="outlined"
                    />
                  )}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        </>
      )}
      renderOption={(option, { inputValue }) => {
        const matches = highlightMatch(option.name, inputValue);
        const parts = highlightParse(option.name, matches);

        return (
          <>
            <span className={classes.optionText}>
              {parts.map((part, index) => (
                <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                  {part.text}
                </span>
              ))}
            </span>
            <Chip
              className={clsx(classes.chip, classes[option.fulfillmentMethod])}
              label={capitalize(option.fulfillmentMethod)}
              size="small"
              variant="outlined"
            />
          </>
        );
      }}
      selectOnFocus
      size="small"
      value={orderLocation}
    />
  );
};

export default ServiceLocation;
