import { Box, Button, Card, Chip, Grid, makeStyles, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";

import FormCard from "../../../components/FormCard";

const DEMO_CATEGORIES = ["Fresh", "Japan", "Sashimi"];
const useStyles = makeStyles(theme => ({
  addCategoryButton: {
    marginTop: theme.spacing(2),
  },
  category: {
    marginBottom: theme.spacing(1.5),
  },
  chips: {
    "&:not(:last-child)": { marginRight: theme.spacing(1) },
    "marginTop": theme.spacing(1.5),
  },
  demoContainer: {
    padding: theme.spacing(2),
  },
  noCategoryContainer: {
    backgroundColor: theme.palette.background.paper,
  },
}));
const NoCategoryCard = ({ onClickAddCategoryButton }) => {
  const classes = useStyles();

  return (
    <FormCard className={classes.noCategoryContainer}>
      <Grid alignItems="center" container spacing={2}>
        <Grid item md={6} xs={12}>
          <Typography gutterBottom variant="h6">
            Create product categories
          </Typography>
          <Typography variant="body2">
            Organize your products into a categories to make it easier for customers to browse and navigate your store.
          </Typography>
          <Button
            className={classes.addCategoryButton}
            color="primary"
            onClick={onClickAddCategoryButton}
            variant="contained"
          >
            Add Category
          </Button>
        </Grid>

        <Grid item md={6} xs={12}>
          <Card className={classes.demoContainer} elevation={0}>
            <Box display="flex">
              <Box mr={2}>
                <Typography className={classes.category} variant="subtitle2">
                  Category
                </Typography>
                <Typography variant="subtitle1">Seafood</Typography>
              </Box>

              <Box>
                <Typography variant="subtitle2">Subcategory tags</Typography>
                {DEMO_CATEGORIES.map(category => (
                  <Chip className={classes.chips} color="primary" key={category} label={category} variant="default" />
                ))}
              </Box>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </FormCard>
  );
};

NoCategoryCard.propTypes = {
  onClickAddCategoryButton: PropTypes.func.isRequired,
};

export default NoCategoryCard;
