import { gql } from "@apollo/client";

const supplierPaymentAttachFromSession = gql`
  mutation SupplierPaymentAttachSession($sessionId: String!) {
    supplierPaymentAttachFromSession(id: $sessionId) {
      id
    }
  }
`;

export default supplierPaymentAttachFromSession;
