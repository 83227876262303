import { Box, Card, Fade, LinearProgress, makeStyles, Typography } from "@material-ui/core";
import clsx from "clsx";
import { ReactElement } from "react";

const useStyles = makeStyles(theme => ({
  contentContainer: {
    "&:not(:last-child)": {
      marginBottom: theme.spacing(2),
    },
    "border": `1px solid ${theme.palette.grey[300]}`,
    "borderRadius": theme.shape.borderRadius,
    "padding": theme.spacing(1.5, 2.5, 2),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  noTitle: {
    paddingTop: theme.spacing(2),
  },
  title: {
    fontSize: "1.05rem",
    fontWeight: 600,
    letterSpacing: theme.typography.h6.letterSpacing,
    lineHeight: theme.typography.h6.lineHeight,
  },
}));

type FormCardProps = {
  action?: ReactElement;
  children: ReactElement | ReactElement[];
  className?: string;
  title?: ReactElement | string;
  variant?: "outlined" | "elevation";
  loading?: boolean;
};

const FormCard = ({
  action,
  children,
  className,
  title,
  variant = "outlined",
  loading = false,
  ...rest
}: FormCardProps) => {
  const classes = useStyles();

  return (
    <Card
      className={clsx(classes.contentContainer, className, title ? null : classes.noTitle)}
      variant={variant}
      {...rest}
    >
      <Box marginBottom={title || action ? 2 : 0}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          {title && (
            <Typography className={classes.title} variant="h3">
              {title}
            </Typography>
          )}
          {action}
        </Box>
        <Fade in={loading} mountOnEnter unmountOnExit>
          <LinearProgress />
        </Fade>
      </Box>
      {children}
    </Card>
  );
};

export default FormCard;
