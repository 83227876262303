import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import Routes from "../../../Constants/Routes";
import Drift from "./Drift";
import Facebook from "./Facebook";
import GoogleAnalytics from "./GoogleAnalytics";
import GoogleTagManager from "./GoogleTagManager";
import Hotjar from "./Hotjar";
import MailChimp from "./MailChimp";
import Shipping from "./Shipping";
import ShippingRates from "./ShippingRates";
import Wise from "./Wise";

const AppDetails = () => {
  return (
    <Switch>
      <Route component={Shipping} exact path={`${Routes.APPS}/shipping`} />
      <Route component={ShippingRates} exact path={`${Routes.APPS}/shipping/:id`} />
      <Route component={Drift} exact path={`${Routes.APPS}/drift`} />
      <Route component={Facebook} exact path={`${Routes.APPS}/facebook`} />
      <Route component={GoogleAnalytics} exact path={`${Routes.APPS}/googleAnalytics`} />
      <Route component={GoogleTagManager} exact path={`${Routes.APPS}/googleTagManager`} />
      <Route component={Hotjar} exact path={`${Routes.APPS}/hotjar`} />
      <Route component={MailChimp} exact path={`${Routes.APPS}/mailchimp`} />
      <Route component={Wise} exact path={`${Routes.APPS}/wise`} />
      <Redirect to={Routes.APPS} />
    </Switch>
  );
};

export default AppDetails;
