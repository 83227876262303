import { Product } from "@arowana/graphql";
import { IMG_NOT_FOUND } from "@arowana/util";
import { Button, Chip, Grid, makeStyles, Typography } from "@material-ui/core";
import { useMemo, useState } from "react";
import useDeepCompareEffect from "use-deep-compare-effect";

import Currency from "../Currency";
import Carousel from "./Carousel";
import SSRImg from "./SSRImg";

const responsive = {
  default: {
    breakpoint: { max: 5000, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
};

const useStyles = makeStyles(theme => ({
  carouselImg: {
    borderRadius: 0,
    maxHeight: 450,
    minHeight: 250,
    objectFit: "cover",
    width: "100%",
  },
  imgContainer: {
    marginBottom: theme.spacing(1),
    position: "relative",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      display: "block",
    },
  },
  productImg: {
    borderRadius: theme.shape.borderRadius,
    maxHeight: 500,
    objectFit: "cover",
    width: "100%",
  },
  promo: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.background.paper,
    left: theme.spacing(1),
    position: "absolute",
    top: theme.spacing(1),
    zIndex: 1,
  },
  thumbnail: {
    "&:not(:last-child)": { marginBottom: theme.spacing(1) },
    "cursor": "pointer",
    "width": "100%",
  },
  thumbnailImg: {
    borderRadius: theme.shape.borderRadius,
    height: 50,
    objectFit: "cover",
    width: "100%",
  },
  thumbnailRipple: {
    color: theme.palette.primary.main,
  },
}));

interface ProductImageProps {
  isMobile: boolean;
  product: Product;
}
const ProductImage = ({ isMobile, product }: ProductImageProps) => {
  const { name, images, featured, displayPrice, onSale, originalPrice, variants } = product;
  const isOnSale = onSale && originalPrice;
  const isSingleVariant = variants.length === 1;

  const classes = useStyles();
  const imageUrls = useMemo(
    () =>
      images?.length > 0
        ? images
        : [{ original: IMG_NOT_FOUND, x256: IMG_NOT_FOUND, x512: IMG_NOT_FOUND, x896: IMG_NOT_FOUND }],
    [images],
  );

  const [imageInView, setImageInView] = useState<string>(imageUrls?.[0]?.x896 ?? IMG_NOT_FOUND);
  useDeepCompareEffect(() => {
    setImageInView(imageUrls?.[0]?.x896 ?? IMG_NOT_FOUND);
  }, [imageUrls]);

  let promoInfo: React.ReactElement;

  if (isOnSale) {
    promoInfo = (
      <Currency
        cents={Math.abs(originalPrice - displayPrice)}
        prefix={isSingleVariant ? "" : "From"}
        suffix="OFF"
        variant="subtitle2"
      />
    );
  }

  if (featured) {
    promoInfo = <Typography variant="subtitle2">Promo</Typography>;
  }

  return isMobile ? (
    <Grid className={classes.imgContainer} item xs={12}>
      {promoInfo && <Chip className={classes.promo} label={promoInfo} />}
      <Carousel responsive={responsive}>
        {imageUrls.map((urlObj, idx) => (
          <SSRImg alt={name} className={classes.carouselImg} key={`carousel_${idx}`} src={urlObj.x896} />
        ))}
      </Carousel>
    </Grid>
  ) : (
    <Grid container item justifyContent="center" sm={6}>
      <Grid className={classes.imgContainer} item>
        {promoInfo && <Chip className={classes.promo} label={promoInfo} />}
        <SSRImg alt={name} className={classes.productImg} src={imageInView} />
      </Grid>
      <Grid container item spacing={1}>
        {imageUrls.map((urlObj, idx) => (
          <Grid item key={`tb_${idx}`} sm={2}>
            <Button
              TouchRippleProps={{ className: classes.thumbnailRipple }}
              className={classes.thumbnail}
              disabled={urlObj.x896 === imageInView}
              onClick={() => setImageInView(urlObj.x896)}
            >
              <SSRImg alt={name} className={classes.thumbnailImg} draggable={false} src={urlObj.x256} />
            </Button>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default ProductImage;
