import { IMG_NOT_FOUND } from "@arowana/util";
import Head from "next/head";
import { NextSeo } from "next-seo";
import { useContext } from "react";

import { SupplierContext } from "../context";

type PageHeadProps = {
  description?: string;
  image?: string;
  title?: string;
};

export const PageHead = ({ title, description: providedDescription, image }: PageHeadProps) => {
  const { name, uiSettings } = useContext(SupplierContext);
  const formattedTitle = title ? `${name} | ${title}` : name;
  const description = providedDescription || uiSettings?.descriptionText;

  return (
    <>
      <Head>
        <title>{formattedTitle}</title>
      </Head>

      <NextSeo
        description={description}
        openGraph={{
          description: description,
          images: [
            {
              alt: "logo",
              url: image || (uiSettings?.logoUrl ?? IMG_NOT_FOUND),
            },
          ],
          site_name: name,
          title: formattedTitle,
        }}
        title={formattedTitle}
      />
    </>
  );
};

export default PageHead;
