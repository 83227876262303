import { Location } from "@arowana/graphql";
import { SupplierContext } from "@arowana/ui";
import {
  Box,
  Button,
  Fade,
  FormHelperText,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Clear, DoneAll } from "@material-ui/icons";
import { forwardRef, useContext, useMemo } from "react";

import FulfillmentChip from "../../../components/FulfillmentChip";
import ServiceLocation from "../../orders/pages/OrderDetails/ServiceLocation";
import ListAutocomplete from "../../products/components/ListAutocomplete";

export type LocationValue = Pick<Location, "id" | "name" | "fulfillmentMethod">;

type LocationsInputProps = {
  locations: Pick<Location, "id" | "name" | "fulfillmentMethod">[];
  value: Array<LocationValue>;
  onChange: (values: Array<LocationValue>) => void;
  errorMsg?: string;
};

const LocationsInput = forwardRef(({ value, onChange, errorMsg }: LocationsInputProps, ref) => {
  const { locations } = useContext(SupplierContext);

  const valueIdSet = useMemo<Set<string>>(() => {
    return new Set<string>(value.map(({ id }) => id));
  }, [value]);
  const allSelected = value.length === locations.length;

  const onSelectChange = e => {
    const { id, name, fulfillmentMethod } = locations.find(({ id }) => e.target.value === id);
    onChange([
      ...value,
      {
        fulfillmentMethod,
        id,
        name,
      },
    ]);
  };

  const onSelectAll = () =>
    onChange(locations.map(({ id, name, fulfillmentMethod }) => ({ fulfillmentMethod, id, name })));

  const onClearAll = () => onChange([]);

  return (
    <div ref={ref}>
      <Select
        disabled={allSelected}
        style={{ marginTop: 8 }}
        displayEmpty
        fullWidth
        margin="dense"
        variant="outlined"
        value=""
        onChange={onSelectChange}
      >
        <MenuItem value={""}>Add a location</MenuItem>
        {locations?.map(({ id, name, fulfillmentMethod }) => {
          return valueIdSet.has(id) ? null : (
            <MenuItem divider key={id} value={id}>
              <ListItemText>{name}</ListItemText>
              <FulfillmentChip fulfillmentType={fulfillmentMethod} />
            </MenuItem>
          );
        })}
      </Select>
      <List>
        {value.map(({ id, name, fulfillmentMethod }) => (
          <ListItem key={id} divider disableGutters>
            <ListItemText primary={name} />
            <ListItemSecondaryAction>
              <FulfillmentChip fulfillmentType={fulfillmentMethod} />
              <Tooltip title="Clear">
                <IconButton aria-label="clear" edge="end" onClick={() => onChange(value.filter(v => id !== v.id))}>
                  <Clear />
                </IconButton>
              </Tooltip>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
        {value.length === 0 && (
          <Typography align="center" style={{ marginTop: 4 }}>
            No locations
          </Typography>
        )}
      </List>
      {errorMsg && <FormHelperText error>{errorMsg}</FormHelperText>}
      <Box display="flex" justifyContent="flex-end">
        <Fade in={value.length > 0}>
          <Button style={{ marginRight: 8 }} color="secondary" onClick={onClearAll} size="small">
            Clear All
          </Button>
        </Fade>
        <Button
          disabled={allSelected}
          variant="outlined"
          color="primary"
          onClick={onSelectAll}
          startIcon={<DoneAll />}
          size="small"
        >
          Select All
        </Button>
      </Box>
    </div>
  );
});

export default LocationsInput;
