/* eslint-disable @typescript-eslint/no-explicit-any */
import { Order, OrderAudience, OrderLineItem } from "@arowana/graphql";
import { Divider, List, makeStyles } from "@material-ui/core";
import React, { useMemo } from "react";

import PVOAutocomplete, { PVOSearch } from "../../component/PVOAutocomplete";
import VariantAutocomplete, { VariantSearch } from "../../component/VariantAutocomplete";
import LineItem from "./LineItem";

const useStyles = makeStyles(theme => ({
  addProduct: { marginBottom: theme.spacing(2) },
}));

interface LineItemsProps {
  currencyFormatter: Intl.NumberFormat;
  onChange: (lineItems: any) => void;
  onRefundLineItem: (amount: string, description: string) => void;
  order: Order;
  value: OrderLineItem[];
}

const LineItems = ({ currencyFormatter, onChange, onRefundLineItem, order, value }: LineItemsProps) => {
  const classes = useStyles();
  const isWholesaleOrder = order?.audience === OrderAudience.WHOLESALE;

  const handleAdjustItem = (index: number, val: any, key: string) =>
    onChange([...value.slice(0, index), { ...value[index], [key]: val }, ...value.slice(index + 1)]);

  const { filteredVariantIds, exisitingVariantIds } = useMemo(
    () => ({
      exisitingVariantIds: new Set(value.map(lineItem => lineItem.variantId)),
      filteredVariantIds: new Set(
        value.filter((lineItem: OrderLineItem) => lineItem.quantity > 0).map(lineItem => lineItem.variantId),
      ),
    }),
    [value],
  );

  const addProduct = ({
    cases,
    caseSize,
    pricePerUnit,
    productId,
    productName,
    productVariantOverrideId,
    sku,
    subName,
    thumbnail,
    unit,
    variantId,
  }) => {
    if (exisitingVariantIds.has(variantId)) {
      const index = value.findIndex(lineItem => lineItem.variantId === variantId);

      onChange([{ ...value[index], cases, quantity: caseSize }, ...value.slice(0, index), ...value.slice(index + 1)]);
    } else {
      onChange([
        {
          caseSize,
          cases,
          pricePerUnit,
          productId,
          productImage: thumbnail,
          productName,
          productVariantOverrideId,
          quantity: caseSize,
          sku,
          subName,
          unit,
          variantId,
        },
        ...value,
      ]);
    }
  };

  const handleAddRetailProduct = (product: VariantSearch) => {
    const {
      case_size: caseSize,
      id: variantId,
      name: subName,
      price: pricePerUnit,
      product_id: productId,
      product_name: productName,
      sku,
      thumbnail,
      unit,
    } = product;

    addProduct({
      caseSize,
      cases: 1,
      pricePerUnit,
      productId,
      productName,
      productVariantOverrideId: null,
      sku,
      subName,
      thumbnail,
      unit,
      variantId,
    });
  };

  const handleAddWholesaleProduct = (product: PVOSearch) => {
    const {
      id: productVariantOverrideId,
      case_size: caseSize,
      price: pricePerUnit,
      product_id: productId,
      product_name: productName,
      sku,
      thumbnail,
      unit,
      variant_id: variantId,
      variant_name: subName,
    } = product;

    addProduct({
      caseSize,
      cases: 1,
      pricePerUnit,
      productId,
      productName,
      productVariantOverrideId,
      sku,
      subName,
      thumbnail,
      unit,
      variantId,
    });
  };

  return (
    <>
      {isWholesaleOrder ? (
        <PVOAutocomplete
          className={classes.addProduct}
          excludeVariantIds={filteredVariantIds}
          noOptionsText="No products match your search parameters, or they have already been added to this order."
          onChange={handleAddWholesaleProduct}
          placeholder="Add a product to this order..."
          wholesaleListId={order?.client?.wholesaleListId}
        />
      ) : (
        <VariantAutocomplete
          className={classes.addProduct}
          excludeVariantIds={filteredVariantIds}
          noOptionsText="No products match your search parameters, or they have already been added to this order."
          onChange={handleAddRetailProduct}
          placeholder="Add a product to this order..."
        />
      )}

      <List disablePadding>
        {value.map((item, index) => {
          const hasNext = index < value.length - 1;

          return (
            <React.Fragment key={item.variantId}>
              <LineItem
                currencyFormatter={currencyFormatter}
                index={index}
                item={item}
                order={order}
                onRefundLineItem={onRefundLineItem}
                onAdjustItem={handleAdjustItem}
              />
              {hasNext && <Divider variant="inset" component="li" key={`divider-${item.variantId}`} />}
            </React.Fragment>
          );
        })}
      </List>
    </>
  );
};

export default LineItems;
