import { gql, ReactiveVar, useMutation } from "@apollo/client";
import { PaymentMethod } from "@arowana/graphql";
import { DATALAYER } from "@arowana/util";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useState } from "react";

import { PaymentMethodForm } from ".";

const PAYMENT_SETUP_INTENT = gql`
  mutation AccountSetupIntent($input: AccountSetupIntentInput!) {
    accountSetupIntent(input: $input) {
      clientSecret
    }
  }
`;

const PAYMENT_CREATE = gql`
  mutation AccountPaymentMethodCreate($id: ID!, $input: AccountPaymentMethodCreateInput!) {
    accountPaymentMethodCreate(id: $id, input: $input) {
      id
      card {
        brand
        expiryMonth
        expiryYear
        last4
      }
    }
  }
`;

type AccountPaymentMethodFormProps = {
  account: { id?: string };
  onAdd?: (paymentMethod: Partial<PaymentMethod>) => void;
  onAddCancel?: () => void;
  notificationVar: ReactiveVar<unknown>;
};

export const AccountPaymentMethodForm = ({
  account,
  onAdd,
  onAddCancel,
  notificationVar,
}: AccountPaymentMethodFormProps) => {
  const [loading, setLoading] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  const [accountSetupIntent] = useMutation(PAYMENT_SETUP_INTENT, {
    context: { source: DATALAYER },
    onError() {
      if (onAddCancel) {
        onAddCancel();
      }

      setLoading(false);
    },
  });

  const [accountPaymentMethodCreate] = useMutation(PAYMENT_CREATE, {
    context: { source: DATALAYER },
    onCompleted: data => {
      setLoading(false);

      if (notificationVar) {
        notificationVar({
          message: "Payment method added!",
          severity: "success",
        });
      }

      if (onAddCancel) {
        onAddCancel();
      }

      if (onAdd) {
        onAdd(data?.accountPaymentMethodCreate);
      }
    },
    onError: () => {
      setLoading(false);

      if (onAddCancel) {
        onAddCancel();
      }
    },
    update: (cache, { data: { accountPaymentMethodCreate: paymentMethod } }) => {
      const result = cache.readQuery<{ currentAccount }>({
        query: gql`
          query CurrentAccount {
            currentAccount {
              id
              __typename
            }
          }
        `,
      });

      if (result?.currentAccount) {
        cache.modify({
          fields: {
            paymentMethods: (existingPaymentMethodRefs = []) => {
              const paymentMethodRef = cache.writeFragment({
                data: paymentMethod,
                fragment: gql`
                  fragment NewPaymentMethod on PaymentMethod {
                    id
                    __typename
                  }
                `,
              });

              return [...existingPaymentMethodRefs, paymentMethodRef];
            },
          },
          id: cache.identify(result.currentAccount),
        });
      }
    },
  });

  const handleSaveCreditCard = async () => {
    if (!elements || !stripe) {
      return;
    }
    setLoading(true);

    const { data: accountSetupIntentResponse } = await accountSetupIntent({
      variables: {
        input: {
          id: account.id,
        },
      },
    });

    const { error, setupIntent } = await stripe.confirmCardSetup(
      accountSetupIntentResponse?.accountSetupIntent?.clientSecret,
      {
        payment_method: {
          card: elements.getElement(CardElement),
        },
      },
    );

    if (error) {
      setLoading(false);

      if (notificationVar) {
        notificationVar({
          message: error.message,
          severity: "error",
        });
      }

      if (onAddCancel) {
        onAddCancel();
      }
    } else if (setupIntent.status === "succeeded") {
      accountPaymentMethodCreate({
        variables: {
          id: account.id,
          input: { paymentMethodId: setupIntent.payment_method },
        },
      });
    }
  };

  return <PaymentMethodForm adding={loading} onAddClick={handleSaveCreditCard} onAddCancel={onAddCancel} />;
};

export default AccountPaymentMethodForm;
