/* eslint-disable @typescript-eslint/no-explicit-any */
import { gql, useQuery } from "@apollo/client";
import { FulfillmentMethod, Location, Supplier } from "@arowana/graphql";
import { DATALAYER } from "@arowana/util";
import {
  Box,
  Chip,
  FormControl,
  InputLabel,
  ListItemText,
  makeStyles,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { useRouter } from "next/router";
import { useEffect, useMemo, useState } from "react";

import { LocationItem } from "../Location";
import PageHead from "../PageHead";

const LOCATIONS_QUERY = gql`
  query LocationsQuery {
    currentSupplier {
      id
      locations {
        id
        active
        address {
          id
          formattedAddress
          lngLat {
            coordinates
          }
        }
        cutOffHour
        deliveryFee
        description
        discountedDeliveryFee
        discountedDeliveryFeeThreshold
        freeDeliveryAmount
        fulfillmentMethod
        fulfillmentTimes {
          id
          label
        }
        geometry {
          coordinates
        }
        leadTimes
        minimumOrderAmount
        name
        staticMapUrl
      }
    }
  }
`;

const useStyles = makeStyles(theme => ({
  closedChip: {
    height: 18,
    marginLeft: theme.spacing(0.5),
  },
  item: {
    marginBottom: theme.spacing(2),
  },
  label: {
    margin: 0,
  },
  loading: {
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(2),
  },
  select: {
    [theme.breakpoints.only("xs")]: {
      flex: "0 0 100%",
      margin: theme.spacing(2, 0, 1),
    },
  },
  title: {
    [theme.breakpoints.only("xs")]: {
      flex: "0 0 100%",
    },
  },
}));

export const LocationPage = () => {
  const classes = useStyles();
  const router = useRouter();
  const [locationInView, setLocationInView] = useState<Partial<Location> | null>();

  const { data, loading } = useQuery<{ currentSupplier: Partial<Supplier> }>(LOCATIONS_QUERY, {
    context: { source: DATALAYER },
  });

  const { i: locationIdParam = "all" } = router.query;
  const locations = useMemo(
    () => data?.currentSupplier?.locations.filter(location => location.active) ?? [],
    [data?.currentSupplier?.locations],
  );

  useEffect(() => {
    if (locations.length > 0) {
      const loc = locationIdParam ? locations.find(({ id }) => id === locationIdParam) : null;
      setLocationInView(loc ?? null);
    }
  }, [router, locationIdParam, locations]);

  const onLocationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const id = event.target.value;

    router.replace(
      {
        pathname: "/locations",
        query:
          id !== "all"
            ? {
                i: event.target.value,
              }
            : null,
      },
      null,
      {
        shallow: true,
      },
    );
  };

  let content;

  if (loading) {
    content = (
      <>
        <Skeleton className={classes.loading} variant="rect" width="100%" height={250} />
        <Skeleton className={classes.loading} variant="rect" width="100%" height={250} />
        <Skeleton className={classes.loading} variant="rect" width="100%" height={250} />
      </>
    );
  } else if (locations.length === 0) {
    content = <Typography>Sorry, no delivery locations are available.</Typography>;
  } else if (locationInView) {
    content = <LocationItem location={locationInView} />;
  } else if (locationIdParam === "all") {
    content = locations.map(loc => <LocationItem className={classes.item} key={loc.id} location={loc} />);
  } else {
    content = <Typography>Sorry, the location is not available. Please choose another one.</Typography>;
  }

  const selectValue = locationIdParam === "all" ? "all" : locationInView?.id ?? "";

  return (
    <>
      <PageHead description={locationInView?.description} title="Service Locations" />
      <Box display="flex" flexWrap="wrap" alignItems="center" justifyContent="space-between" marginBottom={2}>
        <Typography className={classes.title} variant="h4">
          Service locations
        </Typography>
        <FormControl className={classes.select} margin="dense" variant="outlined">
          <InputLabel shrink id="loc-select-label">
            Location
          </InputLabel>
          <Select
            labelId="loc-select-label"
            id="loc-select"
            onChange={onLocationChange}
            value={selectValue}
            input={<OutlinedInput notched label="Location" />}
            displayEmpty
          >
            <MenuItem value="" disabled>
              Select a location
            </MenuItem>
            <MenuItem value="all">
              <ListItemText className={classes.label} primary={<strong>All</strong>} secondary="See all locations" />
            </MenuItem>
            {locations.map(({ active, id, name, fulfillmentMethod }) => (
              <MenuItem key={id} value={id} divider>
                <ListItemText
                  className={classes.label}
                  primary={name}
                  secondary={
                    <>
                      {fulfillmentMethod === FulfillmentMethod.pickup ? "Pickup" : "Delivery"}{" "}
                      {!active && (
                        <Chip
                          className={classes.closedChip}
                          color="secondary"
                          component="span"
                          label="Closed"
                          size="small"
                        />
                      )}
                    </>
                  }
                />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      {content}
    </>
  );
};

export default LocationPage;
