import { gql, useQuery } from "@apollo/client";
import { FulfillmentMethod, RecurringOrderListsSortField, Supplier } from "@arowana/graphql";
import { PaginatedTable, usePagination } from "@arowana/ui";
import { DATALAYER } from "@arowana/util";
import { Button, Chip, Link } from "@material-ui/core";
import { GridSortDirection } from "@material-ui/data-grid";
import moment from "moment";
import qs from "qs";
import { useMemo, useState } from "react";
import { Link as RouterLink } from "react-router-dom";

import FulfillmentChip from "../../../components/FulfillmentChip";
import PageHeader from "../../../components/PageHeader";
import Routes from "../../../Constants/Routes";
import { AUDIENCE_DISPLAY } from "../../../utils/audienceDisplay";
import Filters from "../components/Filters";

const RECURRING_ORDER_LISTS = gql`
  query RecurringOrderLists(
    $filter: RecurringOrderListsFilterInput
    $sort: RecurringOrderListsSortInput
    $page: PaginationInput
  ) {
    currentSupplier {
      id
      recurringOrderLists(filter: $filter, sort: $sort, page: $page) {
        edges {
          cursor
          node {
            id
            active
            audience
            createdAt
            fulfillmentMethods
            name
            public
            updatedAt
          }
        }
        pageInfo {
          count
          endCursor
          hasNextPage
          totalCount
        }
      }
    }
  }
`;

const COLUMNS = [
  {
    field: "id",
    hide: true,
  },
  {
    field: RecurringOrderListsSortField.NAME,
    headerName: "Name",
    renderCell: param => (
      <Link component={RouterLink} to={Routes.SUBSCRIPTIONS_COLLECTIONS_DETAILS.replace(":id", param.row.id)}>
        {param.value}
      </Link>
    ),
    sortable: true,
    valueGetter: param => param.row.name,
    width: 250,
  },
  {
    field: "active",
    headerName: "Status",
    renderCell: param => (
      <Chip color={param.value ? "primary" : "secondary"} label={param.value ? "Active" : "Inactive"} size="small" />
    ),
    sortable: false,
    width: 100,
  },
  {
    field: "public",
    headerName: "Visibility",
    renderCell: param => (
      <Chip color={param.value ? "primary" : "secondary"} label={param.value ? "Public" : "Private"} size="small" />
    ),
    sortable: false,
    width: 100,
  },
  {
    field: "fulfillmentMethods",
    headerName: "Fulfillment methods",
    renderCell: param =>
      param.value.map((fulfillmentMethod: FulfillmentMethod) => (
        <FulfillmentChip key={fulfillmentMethod} fulfillmentType={fulfillmentMethod} />
      )),
    sortable: false,
    width: 250,
  },
  {
    field: "audience",
    headerName: "Audience",
    renderCell: param => AUDIENCE_DISPLAY[param.value],
    sortable: false,
    width: 100,
  },
  {
    field: RecurringOrderListsSortField.CREATED_AT,
    headerName: "Created on",
    sortable: true,
    valueGetter: param => moment(param.row.createdAt).format("LL"),
    width: 180,
  },
  {
    field: RecurringOrderListsSortField.UPDATED_AT,
    flex: 1,
    headerName: "Last updated on",
    sortable: true,
    valueGetter: param => moment(param.row.updatedAt).format("LLL"),
  },
];

const defaultSortState = {
  field: RecurringOrderListsSortField.NAME,
  sort: "desc" as GridSortDirection,
};

const ROW_PER_PAGE = 100;

export enum FilterParams {
  active = "active",
  audience = "audience",
  fulfillmentMethod = "fulfillmentMethod",
  public = "public",
}

const RecurringLists = ({ history }) => {
  const params: { [key in FilterParams]: string } = qs.parse(history.location.search, { ignoreQueryPrefix: true });
  const { active = "ALL", audience = "ALL", fulfillmentMethod = "ALL", public: isPublic = "ALL" } = params ?? {};

  const filter = {
    ...(active !== "ALL" && { active: active === "true" }),
    ...(audience !== "ALL" && { audience }),
    ...(isPublic !== "ALL" && { public: isPublic === "true" }),
    ...(fulfillmentMethod !== "ALL" && { fulfillmentMethods: [fulfillmentMethod] }),
  };

  const [sortState, setSortState] = useState(defaultSortState);
  const { setPageInfo, resetPagination, pagination, page, onPageChange } = usePagination(ROW_PER_PAGE);

  const { loading, data } = useQuery<{ currentSupplier: Supplier }>(RECURRING_ORDER_LISTS, {
    context: { source: DATALAYER },
    fetchPolicy: "network-only",
    onCompleted: response => {
      setPageInfo(response?.currentSupplier?.recurringOrderLists?.pageInfo);
    },
    onError: resetPagination,
    variables: {
      filter,
      page: pagination,
      sort: {
        field: sortState?.field,
        order: sortState?.sort === "desc" ? -1 : 1,
      },
    },
  });

  const totalCount = data?.currentSupplier?.recurringOrderLists?.pageInfo?.totalCount ?? 0;

  const onSortChange = ({ sortModel }) => {
    resetPagination();
    setSortState(sortModel[0]);
  };

  const rows = useMemo(
    () => data?.currentSupplier?.recurringOrderLists?.edges?.map(({ node }) => node) ?? [],
    [data?.currentSupplier?.recurringOrderLists],
  );

  const sortModel = sortState ? [sortState] : [];

  const onPageChangeExtra = params => {
    window.scrollTo(0, 0);
    onPageChange(params);
  };

  const onCreateClick = () => history.push(Routes.SUBSCRIPTIONS_COLLECTIONS_CREATE);

  const updateFilter = (keyValue: { [key in FilterParams]?: string }) => {
    const query = {
      ...params,
      ...keyValue,
    };

    history.replace({
      search: qs.stringify(query),
    });
    resetPagination();
  };

  return (
    <>
      <PageHeader
        primaryActions={
          <Button color="primary" onClick={onCreateClick} variant="contained">
            Create Subscription Collection
          </Button>
        }
        stickyHeader
        title="Subscription collections"
      />

      <Filters filterParams={params} updateFilter={updateFilter} />

      <PaginatedTable
        columns={COLUMNS}
        defaultContent="No subscription collections"
        loading={loading}
        onPageChange={onPageChangeExtra}
        onSortModelChange={onSortChange}
        page={page}
        pageSize={ROW_PER_PAGE}
        rows={rows}
        sortModel={sortModel}
        totalCount={totalCount}
      />
    </>
  );
};

export default RecurringLists;
