import { gql, useMutation } from "@apollo/client";
import { FEATURE_SUPPLIER_STRIPE_INVOICES } from "@arowana/flags";
import { MutationToOrderFulfillManyArgs, Order, OrderStatus } from "@arowana/graphql";
import { DATALAYER } from "@arowana/util";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, Tooltip } from "@material-ui/core";
import { DoneAll } from "@material-ui/icons";
import { useContext, useState } from "react";

import { notificationVar } from "../../../cache/notificationPolicy";
import { FlagsmithContext } from "../../context/FlagsmithContext";

const FULFILL_ALL = gql`
  mutation FulfillAll($orderIds: [ID!]!, $input: OrderFulfillInput!) {
    orderFulfillMany(orderIds: $orderIds, input: $input) {
      id
      fulfilled
      status
    }
  }
`;

const useStyles = makeStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.grey[300]}`,
    color: theme.palette.text.primary,
    padding: theme.spacing(1),
  },
  tooltipArrow: {
    "&:before": {
      border: `1px solid ${theme.palette.grey[300]}`,
    },
    "color": theme.palette.background.paper,
  },
}));

interface BulkFulFillOrdersProps {
  fulfillableOrderIds: string[];
}

const BulkFulFillOrders = ({ fulfillableOrderIds }: BulkFulFillOrdersProps) => {
  const classes = useStyles();
  const numOrders = fulfillableOrderIds.length;

  const flagsmith = useContext(FlagsmithContext);
  const hasInvoices = flagsmith.hasFeature(FEATURE_SUPPLIER_STRIPE_INVOICES);

  const [fulfillOrders, { loading: fulfillOrdersLoading }] = useMutation<
    { orderFulfillMany: Order[] },
    MutationToOrderFulfillManyArgs
  >(FULFILL_ALL, {
    context: { source: DATALAYER },
    fetchPolicy: "no-cache",
    onCompleted: () => {
      notificationVar({
        message: "Orders fulfilled!",
        severity: "success",
      });
    },
    onError: () => {
      notificationVar({
        message: "Unable to update order fulfillment status",
        severity: "error",
      });
    },
    refetchQueries: ["OrdersPicklist"],
  });
  const [open, setOpen] = useState<boolean>(false);

  const handleFulfillAllClick = () => {
    setOpen(!open);
  };
  const handleBulkUpdate = () => {
    const input = {
      sendInvoice: hasInvoices ? true : false,
      status: hasInvoices ? OrderStatus.completed : null,
    };

    fulfillOrders({
      variables: {
        input,
        orderIds: fulfillableOrderIds,
      },
    });
    setOpen(false);
  };

  return (
    <>
      <Tooltip
        arrow
        classes={{
          arrow: classes.tooltipArrow,
          tooltip: classes.tooltip,
        }}
        title={`Fulfill all ${numOrders} outstanding orders displayed and notify customers with an email update`}
      >
        <>
          <Tooltip arrow title="This will mark all orders as completed.">
            <Button
              color="primary"
              disabled={numOrders === 0}
              onClick={handleFulfillAllClick}
              startIcon={<DoneAll />}
              variant="outlined"
              size="large"
            >
              Fulfill All
            </Button>
          </Tooltip>
        </>
      </Tooltip>
      <Dialog maxWidth="xs" open={open}>
        <DialogTitle>Fulfill {numOrders} orders</DialogTitle>
        <DialogContent dividers>Are you sure you want to fulfill all orders?</DialogContent>
        <DialogActions>
          <Button autoFocus color="primary" onClick={() => setOpen(false)} variant="outlined">
            Cancel
          </Button>
          <Button color="primary" onClick={handleBulkUpdate} variant="contained">
            Fulfill All
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default BulkFulFillOrders;
