import { Card, CardContent, Chip, Grid, makeStyles, Typography } from "@material-ui/core";
import PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
  userTipCard: {
    borderColor: theme.palette.warning.dark,
    marginBottom: theme.spacing(2),
  },
  userTipCardChip: {
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.text.primary,
    marginRight: theme.spacing(2),
  },
  userTipCardContent: {
    display: "flex",
    flexDirection: "row",
  },
}));

const TipBanner = ({ headerText = "Header Text", bodyText = "Body Text" }) => {
  const classes = useStyles();

  return (
    <Card className={classes.userTipCard} elevation={0} variant="outlined">
      <CardContent className={classes.userTipCardContent}>
        <Chip className={classes.userTipCardChip} label="Tip" />
        <Grid>
          <Typography variant="subtitle2">{headerText}</Typography>
          <Typography variant="caption">{bodyText}</Typography>
        </Grid>
      </CardContent>
    </Card>
  );
};

TipBanner.propTypes = {
  bodyText: PropTypes.string,
  headerText: PropTypes.string,
};

TipBanner.defaultProps = {
  bodyText: "Body Text",
  headerText: "Header Text",
};

export default TipBanner;
