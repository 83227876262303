import { gql } from "@apollo/client";

const appShippingCarrierServices = gql`
  query AppShippingCarrierServices($id: String!) {
    appShippingCarrierServices(id: $id) {
      name
      serviceCode
    }
  }
`;

export default appShippingCarrierServices;
