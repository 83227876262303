import PropTypes from "prop-types";
import { Redirect, Route } from "react-router-dom";

import { isLoggedInVar } from "../cache/authPolicy";

function PrivateRoute({ component: Component, ...props }) {
  return (
    <Route
      {...props}
      render={routeProps => {
        if (isLoggedInVar()) {
          return <Component {...routeProps} />;
        }

        return <Redirect to="/login" />;
      }}
    />
  );
}

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
};
export default PrivateRoute;
