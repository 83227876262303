import { Button, Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { Link as RouterLink } from "react-router-dom";

import Routes from "../../../Constants/Routes";

const useStyles = makeStyles(theme => ({
  button: {
    width: theme.spacing(10),
    [theme.breakpoints.only("xs")]: {
      width: "100%",
    },
  },
  buttonContainer: {
    textAlign: "end",
    [theme.breakpoints.down("sm")]: {
      textAlign: "start",
    },
  },
  logo: {
    height: "100%",
    maxHeight: 80,
    maxWidth: 150,
    objectFit: "cover",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      maxHeight: 50,
      maxWidth: 120,
    },
  },
  textContainer: {
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(1),
    },
  },
}));
const AppCard = ({ appId, carrierId, installed, header, imgSrc, defaultServiceCode, subheader }) => {
  const classes = useStyles();

  return (
    <Grid alignItems="center" container spacing={1}>
      <Grid container justifyContent="center" item md={3} xs={12}>
        <img alt="logo" className={classes.logo} src={imgSrc} />
      </Grid>
      <Grid className={classes.textContainer} item md={7} xs={12}>
        <Typography gutterBottom variant="subtitle1">
          {header}
        </Typography>
        <Typography color="textSecondary" variant="body2">
          {subheader}
        </Typography>
      </Grid>
      <Grid className={classes.buttonContainer} item md={2} xs={12}>
        <Button
          className={classes.button}
          color="primary"
          component={RouterLink}
          to={{
            pathname: `${Routes.APPS}/${appId}${carrierId ? `/${carrierId}` : ""}`,
            state: { carrierName: header, defaultServiceCode },
          }}
          variant={installed ? "outlined" : "contained"}
        >
          {installed ? "Edit" : "Install"}
        </Button>
      </Grid>
    </Grid>
  );
};

export default AppCard;
