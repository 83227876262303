import {
  Button,
  Checkbox,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { Link as LinkIcon } from "@material-ui/icons";
import { useState } from "react";

import FormCard from "../../components/FormCard";
import PageHeader from "../../components/PageHeader";
import Routes from "../../Constants/Routes";

const useStyles = makeStyles(theme => ({
  root: {
    "&$checked": {
      color: theme.palette.primary.main,
    },
  },
  viewMoreButton: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
}));

const HELP_INFO = [
  {
    actionLink: Routes.PAYMENTS,
    key: "help-guide-payments",
    subtitle: "Securely connect or create a Stripe Account to start accepting payments and receive bank payouts.",
    title: "Enable payments and payouts",
  },
  {
    key: "help-guide-transfer-products",
    subtitle: "Send an optional export of your existing products to support@freshline.io for automatic upload.",
    title: "Transfer your products",
  },
  {
    actionLink: Routes.STORE_LOCATIONS,
    key: "help-guide-delivery-zones",
    subtitle:
      "Set up custom delivery, shipping, or pickup boundaries, alongside location-specific schedules and rules.",
    title: "Create your service locations",
  },
  {
    actionLink: Routes.CATEGORIES,
    key: "help-guide-categories",
    subtitle: "Create and organize your categories and tags help streamline internal and external navigation.",
    title: "Configure your categories",
  },
  {
    actionLink: Routes.LISTS,
    key: "help-guide-collections",
    subtitle: "Create and group collections of related products together for functional or navigation purposes.",
    title: "Set up product collections",
  },
  {
    actionLink: Routes.WHOLESALE_LISTS,
    key: "help-guide-price-lists",
    subtitle: "Manage wholesale access and assign customer-specific product pricing and availability.",
    title: "Create and manage wholesale price lists",
  },
  {
    actionLink: Routes.WHOLESALE_CLIENTS,
    key: "help-guide-website-businesses",
    subtitle: "Manage wholesale customer details while managing store permissions and access.",
    title: "Create and manage wholesale customers",
  },
  {
    actionLink: Routes.APPS,
    key: "help-guide-website-integrations",
    subtitle: "Apps can be used to enable shipping estimates, analytics, and marketing tracking.",
    title: "Configure your store apps",
  },
  {
    actionLink: Routes.STORE_APPEARANCE,
    key: "help-guide-website-design",
    subtitle: "Pick your store layout and provide a branded shopping experience for your customers.",
    title: "Customize your store appearance",
  },
  {
    key: "help-guide-website-url",
    subtitle:
      "Choose the URL where you would like to host your store. Send this to support@freshline.io for final setup.",
    title: "Choose your website URL link",
  },
];

const Help = () => {
  const classes = useStyles();
  const [completeStatus, setCompleteStatus] = useState(
    HELP_INFO.reduce((acc, cur) => ({ ...acc, [cur.key]: localStorage.getItem(cur.key) === "true" }), {}),
  );

  const handleChangeCompleteStatus = (e: React.ChangeEvent<HTMLInputElement>, key: string): void => {
    localStorage.setItem(key, `${e.target.checked}`);
    setCompleteStatus({ ...completeStatus, [key]: e.target.checked });
  };

  return (
    <>
      <PageHeader stickyHeader title="Welcome to Freshline 🎉" />

      <Grid container spacing={2}>
        <Grid item xs={12} sm={8}>
          <FormCard title="Let's get your business set up">
            <Typography gutterBottom>
              Use the checklist below to keep track of your launch progress. Once done, you're ready to start selling!
            </Typography>
            <List className={classes.root}>
              {HELP_INFO.map(({ actionLink, key, subtitle, title }) => {
                const labelId = `checkbox-list-label-${key}`;

                return (
                  <ListItem dense key={key} role={undefined}>
                    <ListItemIcon>
                      <Checkbox
                        checked={completeStatus[key]}
                        color="primary"
                        disableRipple
                        edge="start"
                        inputProps={{ "aria-labelledby": labelId }}
                        onChange={e => handleChangeCompleteStatus(e, key)}
                        tabIndex={-1}
                      />
                    </ListItemIcon>
                    <ListItemText id={labelId} primary={title} secondary={subtitle ? subtitle : null} />
                    {actionLink && (
                      <ListItemSecondaryAction>
                        <IconButton aria-label="comments" edge="end" href={actionLink} rel="noreferrer" target="_blank">
                          <LinkIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    )}
                  </ListItem>
                );
              })}
            </List>
          </FormCard>
        </Grid>
        <Grid xs={12} sm={4} item>
          <FormCard title="Resources and tips">
            <Typography>How to guides, best practices, and tips to grow your online business.</Typography>
            <Button
              className={classes.viewMoreButton}
              color="primary"
              href="https://blog.freshline.io/tag/resources/"
              rel="noreferrer"
              target="_blank"
              variant="outlined"
            >
              View Resources
            </Button>
          </FormCard>
          <FormCard title="Getting started guides">
            <Typography>
              Learn how to use all of Freshline's functionality through written guides and video tutorials.
            </Typography>
            <Button
              className={classes.viewMoreButton}
              color="primary"
              href="https://blog.freshline.io/help-home/"
              rel="noreferrer"
              target="_blank"
              variant="outlined"
            >
              Read Guides
            </Button>
            <Button
              className={classes.viewMoreButton}
              color="primary"
              href="https://blog.freshline.io/video-guides"
              rel="noreferrer"
              target="_blank"
              variant="outlined"
            >
              View Videos
            </Button>
          </FormCard>
        </Grid>
      </Grid>
    </>
  );
};

export default Help;
