import { gql, useMutation, useQuery } from "@apollo/client";
import { Supplier } from "@arowana/graphql";
import { PaginatedTable, usePagination } from "@arowana/ui";
import { DATALAYER } from "@arowana/util";
import { Button, Chip, CircularProgress, IconButton, Link } from "@material-ui/core";
import { GridAlignment } from "@material-ui/data-grid";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import moment from "moment";
import { useMemo, useState } from "react";
import { Link as RouterLink } from "react-router-dom";

import { notificationVar } from "../../../cache/notificationPolicy";
import PageHeader from "../../../components/PageHeader";
import Routes from "../../../Constants/Routes";
import AddToStaffAccount from "../components/AddToStaffAccount";

const STAFF = gql`
  query Staff {
    currentSupplier {
      id
      accounts {
        account {
          id
          createdAt
          email
          name
        }
        roleId
      }
      roles {
        id
        description
        name
      }
    }
  }
`;

const REMOVE_STAFF = gql`
  mutation RemoveStaff($id: ID!) {
    staffAccountDelete(id: $id) {
      id
    }
  }
`;

const getColumns = (removeStaff: (accountId: string) => void, removingId: string) => [
  {
    field: "id",
    hide: true,
  },
  {
    field: "name",
    headerName: "Name",
    renderCell: param => (
      <Link component={RouterLink} to={Routes.STAFF.replace(":id", param.row.id)}>
        {param.value}
      </Link>
    ),
    width: 220,
  },
  {
    field: "email",
    headerName: "Email",
    width: 300,
  },
  {
    field: "permission",
    headerName: "Permission",
    renderCell: param => <Chip color="primary" label={param.value} variant="outlined" size="small" />,
    width: 150,
  },
  {
    field: "createdAt",
    headerName: "Created on",
    renderCell: param => moment(param.value).format("LL"),
    width: 150,
  },
  {
    align: "right" as GridAlignment,
    field: "disabled",
    flex: 1,
    headerName: " ",
    renderCell: param => (
      <IconButton
        aria-label="delete"
        color="secondary"
        disabled={removingId === param.row.id}
        onClick={() => removeStaff(param.row.id)}
      >
        {removingId === param.row.id ? (
          <CircularProgress color="inherit" size={24} />
        ) : (
          <DeleteOutlineIcon fontSize="small" />
        )}
      </IconButton>
    ),
    sortable: false,
  },
];

const ROWS_PER_PAGE = 50;

const StaffList = ({ history }) => {
  const [removingId, setRemovingId] = useState<string>("");
  const { page, onPageChange } = usePagination(ROWS_PER_PAGE);
  const { data, loading } = useQuery<{ currentSupplier: Supplier }>(STAFF, {
    context: { source: DATALAYER },
    fetchPolicy: "cache-and-network",
  });

  const rows = useMemo(() => {
    if (data?.currentSupplier) {
      const { accounts, roles } = data.currentSupplier;
      const roleNameMap = {};
      roles.forEach(({ id, name }) => {
        roleNameMap[id] = name;
      });

      return accounts.map(({ account, roleId }) => ({ ...account, permission: roleNameMap[roleId] || "N/A" }));
    }

    return [];
  }, [data?.currentSupplier]);

  const [removeStaff] = useMutation(REMOVE_STAFF, {
    context: { source: DATALAYER },
    onCompleted: () => {
      notificationVar({
        message: "Staff account removed!",
        severity: "success",
      });
    },
    refetchQueries: ["Staff"],
  });

  const onRemoveStaff = (id: string): void => {
    setRemovingId(id);
    removeStaff({ variables: { id } }).finally(() => setRemovingId(""));
  };

  const handleCreateClick = () => history.push(Routes.STAFF_CREATE);

  return (
    <>
      <PageHeader
        primaryActions={
          <Button color="primary" onClick={handleCreateClick} variant="contained">
            Create
          </Button>
        }
        stickyHeader
        title="Staff accounts"
      />

      <AddToStaffAccount data={data?.currentSupplier} loading={loading} />

      <PaginatedTable
        columns={getColumns(onRemoveStaff, removingId)}
        onPageChange={onPageChange}
        page={page}
        paginationMode="client"
        rows={rows}
        sortingMode="client"
      />
    </>
  );
};

export default StaffList;
