import { gql, useMutation } from "@apollo/client";
import { AccountCreateInput } from "@arowana/graphql";
import { DATALAYER } from "@arowana/util";
import { Button } from "@material-ui/core";
import { useForm } from "react-hook-form";

import { notificationVar } from "../../../cache/notificationPolicy";
import FormCard from "../../../components/FormCard";
import Loader from "../../../components/Loader";
import PageHeader from "../../../components/PageHeader";
import Routes from "../../../Constants/Routes";
import AccountCreateForm from "../components/AccountCreateForm";

const ACCOUNT_CREATE = gql`
  mutation AccountCreate($input: AccountCreateInput!) {
    accountCreate(input: $input)
  }
`;

export const CustomerCreateDefaultValues: AccountCreateInput = {
  email: "",
  name: "",
  password: "",
  phone: "",
};

const CustomerCreate = ({ history }) => {
  const formMethods = useForm({ defaultValues: CustomerCreateDefaultValues });
  const {
    formState: { isDirty },
    handleSubmit,
    reset,
  } = formMethods;

  const [accountCreate, { loading }] = useMutation(ACCOUNT_CREATE, {
    context: { source: DATALAYER },
    onCompleted: () => {
      notificationVar({
        message: "Customer created!",
        severity: "success",
      });
      history.push(Routes.CUSTOMERS);
    },
    refetchQueries: ["Customers"],
  });

  const handleDiscardClick = () => reset();
  const onSubmit = (input: AccountCreateInput) => accountCreate({ variables: { input } });

  return (
    <>
      <Loader loading={loading} />
      <PageHeader
        primaryActions={
          <>
            <Button color="primary" disabled={!isDirty} onClick={handleDiscardClick} variant="outlined">
              Discard
            </Button>
            <Button color="primary" disabled={!isDirty} onClick={handleSubmit(onSubmit)} variant="contained">
              Save
            </Button>
          </>
        }
        stickyHeader
        title="Create customer"
      />
      <FormCard title="Account details">
        <AccountCreateForm formMethods={formMethods} />
      </FormCard>
    </>
  );
};

export default CustomerCreate;
