import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Cancel from "@material-ui/icons/Cancel";
import { makeStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import React from "react";
import { Draggable } from "react-beautiful-dnd";

const useStyles = makeStyles(theme => ({
  image: {
    borderRadius: theme.shape.borderRadius,
    display: "block",
    objectFit: "cover",
  },
  imageCancel: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: "50%",
    color: theme.palette.background.paper,
    cursor: "pointer",
    left: 0,
    position: "absolute",
    top: 0,
  },
  imageContainer: {
    margin: theme.spacing(0, 1, 1),
    position: "relative",
  },
}));
const Image = ({ original, index, deleteImage }) => {
  const classes = useStyles();

  return (
    <Draggable draggableId={original} index={index} key={original}>
      {provided => (
        <Grid innerRef={provided.innerRef} item {...provided.draggableProps} {...provided.dragHandleProps}>
          <Box className={classes.imageContainer}>
            <img alt={`Pic ${index}`} className={classes.image} height="80" src={original} width="80" />
            <Cancel className={classes.imageCancel} color="primary" onClick={() => deleteImage(original)} />
          </Box>
        </Grid>
      )}
    </Draggable>
  );
};

Image.propTypes = {
  deleteImage: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  original: PropTypes.string.isRequired,
};

export default Image;
