/* eslint-disable @typescript-eslint/no-explicit-any */
import { ProductUnit } from "@arowana/graphql";
import { Dollar } from "@arowana/ui";
import {
  Fade,
  FormControl,
  FormHelperText,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { Check, Delete, DragIndicator } from "@material-ui/icons";
import { useRef } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Link } from "react-router-dom";

import ProductAutocomplete, { ProductSearch } from "../../orders/component/ProductAutocomplete";

const useStyles = makeStyles(theme => ({
  link: {
    color: theme.palette.primary.main,
    textDecoration: "none",
  },
  table: {
    minWidth: 500,
  },
  tableContainer: {
    margin: theme.spacing(2, 0),
    maxHeight: 500,
  },
}));

interface ProductRowProps extends ListProductProps {
  onRemove: (id: string) => void;
  dragHandleProps: any;
}

const ProductRow = ({
  id,
  name,
  subName,
  displayPrice,
  displayUnit,
  active,
  stockStatus,
  onRemove,
  dragHandleProps,
}: ProductRowProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const onProductRemoved = () => onRemove(id);

  return (
    <>
      <TableCell style={{ minWidth: 120 }}>
        <Link className={classes.link} target="_blank" to={`/products/${id}`}>
          <Typography variant="subtitle2">{name}</Typography>
        </Link>
        <Typography variant="subtitle2">{subName}</Typography>
      </TableCell>
      <TableCell style={{ minWidth: 120 }}>
        From <Dollar amount={displayPrice} units={displayUnit} variant="body2" />
      </TableCell>
      <TableCell align="center" style={{ width: 120 }}>
        {active && <Check />}
      </TableCell>
      <TableCell align="center" style={{ width: 120 }}>
        {stockStatus}
      </TableCell>
      <TableCell align="center" style={{ width: 50 }}>
        <IconButton aria-label="delete" color="primary" onClick={onProductRemoved} size={isMobile ? "small" : "medium"}>
          <Delete />
        </IconButton>
      </TableCell>
      <TableCell align="right" style={{ width: 50 }}>
        <IconButton
          aria-label="drag"
          color="primary"
          size={isMobile ? "small" : "medium"}
          title="drag"
          {...dragHandleProps}
        >
          <DragIndicator />
        </IconButton>
      </TableCell>
    </>
  );
};

interface ListProductProps {
  active: boolean;
  displayPrice: number;
  displayUnit: ProductUnit;
  id: string;
  name: string;
  stockStatus: string;
  subName: string;
}

interface AddProductInputListProps {
  error: boolean;
  helperText?: string;
  onChange: (products: ListProductProps[]) => void;
  value: ListProductProps[];
}

const AddProductInputList = ({ error, helperText, onChange, value }: AddProductInputListProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const tableContainerRef = useRef(null);

  const onProductAdd = (toAdd: ProductSearch): void => {
    const {
      active,
      display_price: displayPrice,
      display_unit: displayUnit,
      id,
      name,
      stock_status: stockStatus,
      display_sub_name: subName,
    } = toAdd;
    onChange([...value, { active, displayPrice, displayUnit, id, name, stockStatus, subName }]);

    setTimeout(() => {
      const tableContainerEl = tableContainerRef?.current;

      if (tableContainerEl) {
        tableContainerEl.scrollTop = tableContainerEl.scrollHeight;
      }
    }, 100);
  };

  const onProductRemove = (id: string): void => {
    const newValue = value.filter(({ id: productId }) => productId !== id);
    onChange(newValue);
  };

  const handleReorderProducts = result => {
    // illegal drop
    if (!result?.destination || !result?.source || result?.reason === "CANCEL") return;

    const sourceIdx = result.source.index ?? -1;
    const destIdx = result.destination.index ?? -1;

    // illegal drop or drop to the original place
    if (sourceIdx < 0 || destIdx < 0 || sourceIdx === destIdx) return;

    const newValue = value.slice();
    const movedProduct = newValue.splice(sourceIdx, 1)[0];
    newValue.splice(destIdx, 0, movedProduct);
    onChange(newValue);
  };

  const getItemStyle = (isDragging, draggableStyle) => ({
    ...draggableStyle,
    background: isDragging ? theme.palette.disabled.main : theme.palette.background.default,
    display: isDragging ? "table" : "table-row",
    position: isDragging ? "fixed" : "inherit",
    userSelect: "none",
  });

  const filterIds = new Set(value?.map(({ id }) => id));

  return (
    <>
      <FormControl fullWidth margin="normal">
        <ProductAutocomplete
          aria-describedby="search-helper"
          error={error}
          excludeProductIds={filterIds}
          helperText={helperText}
          label="Add products to collection"
          onChange={onProductAdd}
        />
        <FormHelperText id="search-helper">
          Search for a product and select it from the dropdown to add it to your collection.
        </FormHelperText>
      </FormControl>

      <TableContainer className={classes.tableContainer} ref={tableContainerRef}>
        <Table className={classes.table} stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Product</TableCell>
              <TableCell>Price Per Unit</TableCell>
              <TableCell align="center">On Display</TableCell>
              <TableCell align="center">Stock Status</TableCell>
              <TableCell style={{ width: 0 }} />
              <TableCell style={{ width: 0 }} />
            </TableRow>
          </TableHead>
          <DragDropContext onDragEnd={handleReorderProducts}>
            <Droppable droppableId="product_table">
              {providedFromContainer => (
                <TableBody {...providedFromContainer.droppableProps} ref={providedFromContainer.innerRef}>
                  {value.map((product, idx) => (
                    <Draggable draggableId={`${idx}_dragged_item`} index={idx} key={`${idx}_drag_product`}>
                      {(providedFromItem, snapshot) => (
                        <Fade in key={product.id} timeout={500}>
                          <TableRow
                            ref={providedFromItem.innerRef}
                            {...providedFromItem.draggableProps}
                            style={getItemStyle(snapshot.isDragging, providedFromItem.draggableProps.style)}
                          >
                            <ProductRow
                              active={product.active}
                              dragHandleProps={providedFromItem.dragHandleProps}
                              id={product.id}
                              name={product.name}
                              onRemove={onProductRemove}
                              displayPrice={product.displayPrice}
                              stockStatus={product.stockStatus}
                              subName={product.subName}
                              displayUnit={product.displayUnit}
                            />
                          </TableRow>
                        </Fade>
                      )}
                    </Draggable>
                  ))}
                  {providedFromContainer.placeholder}
                </TableBody>
              )}
            </Droppable>
          </DragDropContext>
        </Table>
      </TableContainer>
      <Typography variant="subtitle2">Total: {value?.length ?? 0}</Typography>
    </>
  );
};

export default AddProductInputList;
