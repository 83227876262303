import { FormControl, FormHelperText, MenuItem, Select } from "@material-ui/core";
import PropTypes from "prop-types";

import { UNIT_OPTIONS } from "../../pages/ProductDetails";

const ProductUnitComponent = ({ helperText, value, onChange }) => {
  return (
    <FormControl fullWidth size="small">
      <Select defaultValue={value} margin="none" onChange={onChange} value={value} variant="standard">
        {UNIT_OPTIONS.map(unit => (
          <MenuItem key={unit.value} value={unit.value}>
            {unit.label}
          </MenuItem>
        ))}
      </Select>
      {helperText && <FormHelperText error>{helperText}</FormHelperText>}
    </FormControl>
  );
};

ProductUnitComponent.propTypes = {
  helperText: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
};

ProductUnitComponent.defaultProps = {
  helperText: "",
  onChange: undefined,
  value: "",
};

export default ProductUnitComponent;
