import { gql, useMutation, useQuery } from "@apollo/client";
import { AppFieldResourceType, ProductUnit, ShippingWeightUnit } from "@arowana/graphql";
import { DATALAYER, unitLabel } from "@arowana/util";
import { DevTool } from "@hookform/devtools";
import { Button, CircularProgress, Grid, makeStyles, Typography } from "@material-ui/core";
import { pick } from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import { useContext, useEffect, useMemo, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";

import { notificationVar } from "../../../cache/notificationPolicy";
import AppFields from "../../../components/AppFields";
import ConfirmationModal from "../../../components/ConfirmationModal";
import Loader from "../../../components/Loader";
import PageHeader from "../../../components/PageHeader";
import Routes from "../../../Constants/Routes";
import useExportResource from "../../../hooks/useExportResource";
import { ProductCreate, ProductQuery, ProductUpdate } from "../../../queries/products";
import amountUtility from "../../../utils/amountUtility";
import { getDefaultFulfillmentDays, populateFulfillmentDays } from "../../../utils/getDefaultFulfillmentDays";
import { AccountContext } from "../../context/AccountContext";
import { FlagsmithContext } from "../../context/FlagsmithContext";
import Cancellation from "../components/FormComponents/Cancellation";
import DisplayAvailability from "../components/FormComponents/DisplayAvailability";
import Fulfillment from "../components/FormComponents/Fulfillment";
import InventoryShipping from "../components/FormComponents/InventoryShipping";
import Options from "../components/FormComponents/Options";
import ProductSummary from "../components/FormComponents/ProductSummary";
import Wholesale from "../components/FormComponents/Wholesale";
import { CATEGORIES } from "./CategoryList";

const PRODUCT_EXPORT = gql`
  mutation ProductExport($id: ID!) {
    url: exportProduct(id: $id)
  }
`;
const PRODUCT_DELETE = gql`
  mutation ProductDelete($id: ID!) {
    product: productDelete(id: $id) {
      id
    }
  }
`;

const useStyles = makeStyles(theme => ({
  duplicateButton: {
    marginLeft: "auto",
  },
  lastUpdated: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    margin: theme.spacing(-1, 0, 1, 0),
  },
}));

type Variants = {
  id?: string;
  caseSize: number | string;
  cost?: number | string;
  name: string;
  outOfStock: boolean;
  price: number | string;
  quantity?: number | string;
  shippingWeight: number | string;
  shippingWeightUnit: string;
  sku?: string;
  unit: ProductUnit;
};

export const VARIANT_FIELDS: Variants = {
  caseSize: "",
  cost: "",
  id: "",
  name: "",
  outOfStock: false,
  price: 0,
  quantity: "",
  shippingWeight: 1,
  shippingWeightUnit: Object.values(ShippingWeightUnit)[0],
  sku: "",
  unit: "CASE" as ProductUnit,
};

const MONETARY_FIELD = new Set(["cost", "price"]);

const resetFormWithProduct = (product, reset) => {
  const { __typename, categoryId, originalPrice, updatedAt, images, variants, tags, fulfillmentDays, lists, ...rest } =
    product;

  const defaultVariants = variants.map(variant =>
    Object.keys(VARIANT_FIELDS).reduce((merged, key) => {
      let value = variant[key];

      if (MONETARY_FIELD.has(key)) {
        value = Number.isNaN(parseFloat(value)) ? "" : amountUtility.amountToDollarAndCents(value);
      }

      return {
        ...merged,
        [key]: value ?? VARIANT_FIELDS[key],
      };
    }, {}),
  );

  const hasMoreThanOne = defaultVariants.length > 1;

  reset({
    ...rest,
    categoryAndTags: {
      categoryId,
      tags,
    },
    fulfillmentDays: populateFulfillmentDays(fulfillmentDays),
    lists: lists?.map(({ _id, name }) => ({ id: _id, name })) ?? [],
    originalPrice: Number.isNaN(parseFloat(originalPrice)) ? "" : amountUtility.amountToDollarAndCents(originalPrice),
    productImages: {
      files: [],
      images,
    },
    variant: hasMoreThanOne ? VARIANT_FIELDS : defaultVariants[0],
    variants: hasMoreThanOne ? defaultVariants : [],
  });
};

const formDataToMutationVariables = (productId, data, isCreateProduct, initialListsForUpdate) => {
  const {
    originalPrice,
    productImages,
    categoryAndTags,
    fulfillmentDays,
    updatedAt,
    variants,
    variant,
    lists,
    ...rest
  } = data;

  const updatedVariants = (variants?.length > 0 ? variants : [variant]).map(
    ({ caseSize, quantity, shippingWeight, cost, price, sku, id, ...rest }) => ({
      caseSize: parseFloat(caseSize ?? 0),
      cost: Number.isNaN(parseFloat(cost)) ? null : amountUtility.dollarsAndCentsToAmount(cost),
      price: amountUtility.dollarsAndCentsToAmount(price),
      quantity: Number.isNaN(parseFloat(quantity)) ? null : parseFloat(quantity),
      shippingWeight: parseFloat(shippingWeight),
      sku: sku || null,
      ...(!isCreateProduct && { id }),
      ...rest,
    }),
  );

  const input = {
    addToLists: isCreateProduct
      ? lists.map(list => list.id)
      : lists
          .filter(list => !initialListsForUpdate.some(initialList => initialList.id === list.id))
          .map(list => list.id),
    categoryId: categoryAndTags.categoryId,
    fulfillmentDays: fulfillmentDays.filter(({ checked }) => checked).map(day => pick(day, "orderBy", "fulfilledOn")),
    originalPrice: Number.isNaN(parseFloat(originalPrice))
      ? null
      : amountUtility.dollarsAndCentsToAmount(originalPrice),
    tags: categoryAndTags.tags,
    variants: updatedVariants,
    ...rest,
  };

  if (!isCreateProduct) {
    input.deleteFromLists = initialListsForUpdate
      .filter(list => !lists.some(currentList => currentList.id === list.id))
      .map(list => list.id);
  }

  if (productId) {
    input.id = productId;
    input.images = productImages.images.filter(({ original }) => !original.startsWith("blob:"));
  }

  return {
    images: productImages.files,
    input,
  };
};

const resetFormWithDuplicateProduct = (duplicateInputForm, setValue) => {
  Object.entries(duplicateInputForm).map(([key, value]) => setValue(key, value));
  setValue("name", `${duplicateInputForm?.name} - copy`);
  setValue("productImages", { files: [], images: [] });
  setValue("lists", []);

  if (duplicateInputForm.variants?.length > 0) {
    setValue(
      "variants",
      duplicateInputForm.variants.map(({ id, ...rest }) => ({
        ...rest,
      })),
    );
  }
};

export const UNIT_OPTIONS = Object.values(ProductUnit)
  .sort((pre, next) => pre.localeCompare(next))
  .map(unit => ({
    label: unitLabel(unit),
    value: unit,
  }));

const ProductDetails = ({ match, history }) => {
  const flagsmith = useContext(FlagsmithContext);
  const hasWholesale = flagsmith.hasFeature("b2b");
  const hasRetail = flagsmith.hasFeature("b2c");
  const classes = useStyles();
  const { supplier } = useContext(AccountContext);
  const { id: supplierId, homepageProducts, url } = supplier;
  const productId = match?.params?.id;
  const isCreateProduct = match?.url === Routes.PRODUCT_CREATE_NEW || match?.url === Routes.CREATE_PRODUCT;
  const [openDeleteProductModal, setOpenDeleteProductModal] = useState(false);
  const [duplicateInputForm, setDuplicateInputForm] = useState(null);

  const { data: categoriesData, loading: getCategoriesLoading } = useQuery(CATEGORIES, {
    context: { source: DATALAYER },
    fetchPolicy: "cache-and-network",
    variables: { supplierId },
  });
  const categories = categoriesData?.categories ?? [];

  const {
    control,
    formState: { errors, isDirty },
    handleSubmit,
    register,
    reset,
    setError,
    setValue,
    getValues,
  } = useForm({
    defaultValues: {
      active: false,
      categoryAndTags: {
        categoryId: "",
        tags: [],
      },
      daysToCancel: 1,
      description: "",
      featured: false,
      fulfillmentDays: getDefaultFulfillmentDays(),
      lists: isCreateProduct
        ? homepageProducts
          ? [{ id: homepageProducts.id, name: homepageProducts.name }]
          : []
        : [],
      name: "",
      onSale: false,
      originalPrice: "",
      productImages: {
        files: [],
        images: [],
      },
      showInventory: false,
      trackInventory: false,
      variant: VARIANT_FIELDS, // singlular variant
      variants: [],
    },
  });

  const { append, fields, remove, swap } = useFieldArray({
    control,
    keyName: "key", // override default key `id`, since it conflicts with variant id
    name: "variants",
  });
  const hideFirstOption = useMemo(() => fields.length > 1, [fields]);

  const { data: productData, loading: getProductLoading } = useQuery(ProductQuery, {
    fetchPolicy: "cache-and-network",
    onCompleted: res => {
      if (res.product) {
        resetFormWithProduct(res.product, reset);
      } else {
        history.push(Routes.PRODUCT_LIST);
      }
    },
    onError: () => history.push(Routes.PRODUCT_LIST),
    skip: isCreateProduct,
    variables: { id: productId },
  });
  const product = productData?.product;
  const initialListsForUpdate = useMemo(
    () => product?.lists?.map(list => ({ id: list._id, name: list.name })) ?? [],
    [product],
  );

  useEffect(() => {
    if (duplicateInputForm) {
      resetFormWithDuplicateProduct(duplicateInputForm, setValue);
    }
  }, [duplicateInputForm, setValue]);

  const [createProduct, { loading: createProductLoading }] = useMutation(ProductCreate, {
    onCompleted: res => {
      const id = res.productCreate.id;
      history.replace(Routes.PRODUCT.replace(":id", id));
      notificationVar({
        message: "Product created!",
        severity: "success",
      });
    },
    onError: () => {},
  });
  const [updateProduct, { loading: updateProductLoading }] = useMutation(ProductUpdate, {
    onCompleted: () => {
      notificationVar({
        message: "Product updated!",
        severity: "success",
      });
    },
    onError: () => {},
    refetchQueries: ["Product"],
  });
  const [deleteProduct, { loading: deleteSupplierProductLoading }] = useMutation(PRODUCT_DELETE, {
    context: { source: DATALAYER },
    onCompleted: () => {
      history.push(Routes.PRODUCT_LIST);
      notificationVar({
        message: "Product deleted!",
        severity: "success",
      });
    },
    onError: () => {},
  });

  useEffect(() => {
    // resinitialize single variant fields!!
    if (fields.length === 1) {
      const defaultVariant = { ...fields[0] };
      remove();
      Object.keys(defaultVariant).forEach(key => setValue(`variant.${key}`, defaultVariant[key]));
      window.scrollTo(0, 0);
    }
  }, [fields, remove, setValue]);

  const handleAddOption = () => {
    if (fields.length === 0) {
      append(getValues().variant);
    }
    append(VARIANT_FIELDS);
  };
  const handleRemoveOptions = () => {
    for (let i = fields.length - 1; i >= 1; i--) {
      remove(i);
    }
  };
  const handleOpenDeleteProductModal = () => setOpenDeleteProductModal(true);
  const handleCloseDeleteProductModal = () => setOpenDeleteProductModal(false);
  const handleDiscardClick = () => (isCreateProduct ? reset() : resetFormWithProduct(product, reset));

  const handleDeleteProduct = () => {
    handleCloseDeleteProductModal();
    deleteProduct({
      variables: {
        id: productId,
      },
    });
  };

  const handleDuplicateProduct = () => {
    const formValues = getValues();
    setDuplicateInputForm(formValues);
    history.push(Routes.PRODUCT_CREATE_NEW);
  };

  const onSubmit = data => {
    const variables = formDataToMutationVariables(productId, data, isCreateProduct, initialListsForUpdate);
    const { variants, trackInventory } = variables.input;

    if (trackInventory) {
      const invalidQuantityIndex = [];

      variants.forEach(({ quantity }, index) => {
        if (Number.isNaN(parseFloat(quantity))) {
          invalidQuantityIndex.push(index);
        }
      });

      if (invalidQuantityIndex.length > 0) {
        if (variants.length === 1) {
          setError(`variant.quantity`, {
            message: "*required",
            type: "manual",
          });
        } else {
          invalidQuantityIndex.forEach(index =>
            setError(`variants[${index}].quantity`, {
              message: "*required",
              type: "manual",
            }),
          );
        }

        return;
      }
    }

    if (isCreateProduct) {
      createProduct({ variables });
    } else {
      updateProduct({ variables });
    }
  };

  // Export
  const { ExportDownloadBanner, loadingExport, onExportClick } = useExportResource(PRODUCT_EXPORT, {
    id: product?.id,
  });

  const updating = createProductLoading || updateProductLoading;
  const loading =
    getProductLoading ||
    getCategoriesLoading ||
    deleteSupplierProductLoading ||
    (!isCreateProduct && (!product || Object.keys(product).length === 0));

  if (loading) return <Loader />;

  return (
    <>
      <PageHeader
        homePage={Routes.PRODUCT_LIST}
        primaryActions={
          <>
            <Button color="primary" disabled={!isDirty || updating} onClick={handleDiscardClick} variant="outlined">
              Discard
            </Button>
            <Button
              color="primary"
              disabled={!isDirty || updating}
              onClick={handleSubmit(onSubmit)}
              variant="contained"
            >
              {updating ? <CircularProgress color="inherit" size={24} /> : "Save"}
            </Button>
          </>
        }
        stickyHeader
        title={isCreateProduct ? "New product" : product?.name ?? "Edit product"}
      >
        {!isCreateProduct && (
          <Grid alignItems="center" container justifyContent="space-between">
            <Grid item xs={3} md={4}>
              <Typography title={moment.utc(product?.updatedAt).format("LL")} color="textSecondary" variant="body2">
                Last updated {moment.utc(product?.updatedAt).fromNow()}
              </Typography>
            </Grid>
            <Grid item container justifyContent="flex-end" xs={9} md={8} spacing={1}>
              {hasRetail && (
                <Grid item>
                  <Button color="primary" href={`${url}/products/${productId}`} target="_blank">
                    View on Retail
                  </Button>
                </Grid>
              )}
              {hasWholesale && (
                <Grid item>
                  <Button color="primary" href={`${url}/b2b/products/${productId}`} target="_blank">
                    View on Wholesale
                  </Button>
                </Grid>
              )}
              <Grid item>
                <Button
                  color="primary"
                  disabled={loadingExport}
                  endIcon={loadingExport && <CircularProgress color="inherit" size={24} />}
                  onClick={onExportClick()}
                  variant="text"
                >
                  Export
                </Button>
              </Grid>
              <Grid item>
                <Button color="primary" onClick={handleDuplicateProduct} className={classes.duplicateButton}>
                  Duplicate
                </Button>
              </Grid>
              <Grid item>
                <Button color="secondary" onClick={handleOpenDeleteProductModal}>
                  Delete
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
      </PageHeader>

      {ExportDownloadBanner}

      <ConfirmationModal
        cancelRequestButtonText="Cancel"
        confirmRequestButtonText="Delete"
        isDangerAction
        modalContent="Are you sure you want to delete the product?"
        modalNote="Note: This action cannot be reversed."
        modalTitle={`Delete ${product?.name ?? "Product"}`}
        onCloseModal={handleCloseDeleteProductModal}
        onConfirmClick={handleDeleteProduct}
        shouldOpenModal={openDeleteProductModal}
      />

      <Grid container spacing={2}>
        <Grid item md={7} xs={12}>
          <ProductSummary
            control={control}
            errors={errors}
            showFirstOption={!hideFirstOption}
            register={register}
            unitOptions={UNIT_OPTIONS}
          />
          <Options
            includeId={!isCreateProduct}
            control={control}
            errors={errors}
            fields={fields}
            onAddOption={handleAddOption}
            onRemoveOptions={handleRemoveOptions}
            register={register}
            remove={remove}
            swap={swap}
            showOptionsTable={hideFirstOption}
            unitOptions={UNIT_OPTIONS}
          />
          <Fulfillment control={control} errors={errors} />

          {!isCreateProduct && <AppFields id={productId} resourceType={AppFieldResourceType.product} />}
        </Grid>
        <Grid item md={5} xs={12}>
          <DisplayAvailability control={control} errors={errors} productCategories={categories} />
          {hasWholesale && <Wholesale disabled={isCreateProduct} />}
          <InventoryShipping control={control} errors={errors} showFirstOption={!hideFirstOption} register={register} />
          <Cancellation control={control} />
        </Grid>
      </Grid>
      {process.env.NODE_ENV === "development" && <DevTool control={control} />}
    </>
  );
};

ProductDetails.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    replace: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
    url: PropTypes.string,
  }),
};

ProductDetails.defaultProps = {
  match: {
    params: {
      id: null,
    },
    url: "",
  },
};

export default ProductDetails;
