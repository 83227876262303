import { useMemo } from "react";
import ReactHtmlParser from "react-html-parser";

const htmlToText = node => {
  if (node.type === "text") {
    return node.data.trim();
  }

  if (node.children.length) {
    return node.children.map(childNode => htmlToText(childNode)).join("");
  }

  return "";
};

export const useEscapedHtml = (htmlText: string | undefined) => {
  return useMemo(() => {
    return ReactHtmlParser(htmlText, { transform: htmlToText })
      .filter(str => typeof str === "string")
      .join(" ");
  }, [htmlText]);
};

type AddressLabelType = {
  postalLabel: string;
  provinceLabel?: string;
  sublocalityLabel?: string;
};

export const getAddressLabels = (country = ""): AddressLabelType => {
  const formattedCountry = country?.trim()?.toLowerCase();

  if (!formattedCountry) {
    return { postalLabel: "Postal/Zip code", provinceLabel: "Province/State" };
  }

  if (formattedCountry === "united states" || formattedCountry === "us" || formattedCountry === "usa") {
    return { postalLabel: "Zip code", provinceLabel: "State" };
  }

  if (formattedCountry === "new zealand" || formattedCountry == "nz" || formattedCountry == "nzl") {
    return { postalLabel: "Postal code", sublocalityLabel: "Suburb" };
  }

  return { postalLabel: "Postal code", provinceLabel: "Province" };
};
