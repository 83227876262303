import {
  Box,
  Chip,
  Collapse,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  makeStyles,
  MenuItem,
  Select,
} from "@material-ui/core";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

const useStyles = makeStyles(theme => ({
  labelWithIcon: {
    alignItems: "center",
    display: "flex",
  },
  selectedTag: {
    margin: theme.spacing(0.5),
  },
}));

const CategoryAndTags = React.forwardRef(({ value, onChange, categories, helperText }, ref) => {
  const classes = useStyles();
  const [tags, setTags] = useState([]);
  const [categoryId, setCategoryId] = useState("");
  const [availableTags, setAvailableTags] = useState([]);
  const noTagsLeft = availableTags?.length === 0;

  useEffect(() => {
    setTags(value.tags);
    setCategoryId(value.categoryId);
    setAvailableTags(
      categories.find(c => c?.id === value?.categoryId)?.tags?.filter(t => !value?.tags?.includes(t.value)) ?? [],
    );
  }, [categories, value]);

  const handleSelectProductCategory = event => {
    const category = categories.find(c => c?.id === event.target.value);
    setAvailableTags(category.tags);
    setTags([]);
    setCategoryId(event.target.value);

    onChange({ categoryId: event.target.value, tags: [] });
  };
  const handleTagsChanged = event => {
    const numTags = event?.target?.value?.length;

    if (!numTags) return;

    const tagValue = event.target.value[numTags - 1];
    const tagIdx = availableTags.findIndex(t => t?.value === tagValue);

    if (tagIdx > -1) {
      const currentTags = [...tags];
      currentTags.push(tagValue);

      const currentAvailTags = [...availableTags];
      currentAvailTags.splice(tagIdx, 1);

      setTags(currentTags);
      setAvailableTags(currentAvailTags);
      onChange({ categoryId, tags: currentTags });
    }
  };
  const handleDeleteTag = index => {
    const currentTags = [...tags];
    const currentAvailTags = [...availableTags];
    const tagValue = currentTags[index];
    const category = categories.find(c => c?.id === categoryId);
    const tag = category?.tags?.find(t => t?.value === tagValue);

    if (tag) {
      currentAvailTags.push(tag);
    }
    currentTags.splice(index, 1);

    setTags(currentTags);
    setAvailableTags(currentAvailTags);
    onChange({ categoryId, tags: currentTags });
  };

  return (
    <Grid container direction="column" spacing={3}>
      <Grid item xs={12}>
        <FormLabel htmlFor="category" required>
          Category
        </FormLabel>
        <FormControl error={helperText?.type === "category"} fullWidth margin="dense" size="small">
          <Select
            inputProps={{ id: "category" }}
            onChange={handleSelectProductCategory}
            value={categoryId}
            variant="outlined"
          >
            {categories.map(category => (
              <MenuItem key={category.id} value={category.id}>
                {category.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {helperText?.type === "category" && <FormHelperText error>{helperText?.message}</FormHelperText>}
      </Grid>

      <Grid item xs={12}>
        <FormLabel htmlFor="tags" required>
          Tags
        </FormLabel>
        <FormControl error={helperText?.type === "tags"} fullWidth margin="dense" size="small">
          <Select
            disabled={!categoryId}
            inputProps={{ id: "tags" }}
            inputRef={ref}
            multiple
            onChange={handleTagsChanged}
            value={tags}
            variant="outlined"
            displayEmpty
            disabled={noTagsLeft}
            renderValue={() => (noTagsLeft ? "All tags selceted" : "Select applicable tags")}
          >
            {noTagsLeft && (
              <MenuItem value="" disabled>
                <em>No more tags</em>
              </MenuItem>
            )}
            {availableTags.map(tag => (
              <MenuItem key={tag.value} value={tag.value}>
                {tag.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {helperText?.type === "tags" && <FormHelperText error>{helperText?.message}</FormHelperText>}

        <Collapse in={tags.length > 0}>
          <Box mt={0.5}>
            {tags.map((tagValue, index) => (
              <Chip
                className={classes.selectedTag}
                key={tagValue}
                label={tagValue}
                onDelete={() => handleDeleteTag(index)}
              />
            ))}
          </Box>
        </Collapse>
      </Grid>
    </Grid>
  );
});

CategoryAndTags.propTypes = {
  categories: PropTypes.array,
  helperText: PropTypes.object,
  onChange: PropTypes.func,
  value: PropTypes.object,
};

CategoryAndTags.defaultProps = {
  categories: [],
  helperText: undefined,
  onChange: undefined,
  value: {
    categoryId: "",
    tags: [],
  },
};

export default CategoryAndTags;
