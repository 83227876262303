import { gql } from "@apollo/client";

import FULL_SUPPLIER from "../fragment/fullSupplier";

const supplierUpdateSettings = gql`
  mutation SupplierUpdateSettings(
    $supplierId: ID!,
    $logo: FileUpload,
    $carouselImages: [FileUpload],
    $input: SupplierUpdateSettingsInput!
    ) {
    supplierUpdateSettings(
      supplierId: $supplierId,
      logo: $logo,
      carouselImages: $carouselImages,
      input: $input
      ) {
      ${FULL_SUPPLIER}
    }
  }
`;

export default supplierUpdateSettings;
