import { gql, useMutation, useQuery } from "@apollo/client";
import { AppFieldResourceType, OrdersSortField } from "@arowana/graphql";
import { Dollar, PaginatedTable, usePagination, usePasswordToggle } from "@arowana/ui";
import { DATALAYER } from "@arowana/util";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Fade,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  InputLabel,
  Link,
  List,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { GridAlignment, GridSortItem } from "@material-ui/data-grid";
import { Close, Search } from "@material-ui/icons";
import moment from "moment";
import PropTypes from "prop-types";
import { useContext, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { Link as RouterLink } from "react-router-dom";

import { notificationVar } from "../../../cache/notificationPolicy";
import AccountAddressItem from "../../../components/AccountAddressItem";
import AppFields from "../../../components/AppFields";
import FormCard from "../../../components/FormCard";
import Loader from "../../../components/Loader";
import PageHeader from "../../../components/PageHeader";
import PaymentMethodForm from "../../../components/PaymentMethodForm";
import PaymentMethodItem from "../../../components/PaymentMethodItem";
import Routes from "../../../Constants/Routes";
import { AccountContext } from "../../context/AccountContext";
import { FlagsmithContext } from "../../context/FlagsmithContext";
import Audiences from "../../orders/constants/orderAudience";
import Statuses from "../../orders/constants/orderStatus";

const useStyles = makeStyles(theme => ({
  dialogContainer: {
    [theme.breakpoints.only("xs")]: {
      paddingTop: theme.spacing(7),
    },
  },
  spacing: {
    marginTop: theme.spacing(2),
  },
}));

const ACCOUNT = gql`
  query Account($id: ID!, $filter: OrdersFilterInput, $page: PaginationInput!, $sort: OrdersSortInput) {
    account(id: $id) {
      id
      addresses {
        id
        address1
        address2
        city
        country
        formattedAddress
        label
        notes
        postalCode
        region
        sublocality
      }
      client {
        id
        name
      }
      createdAt
      email
      name
      notes
      orders(filter: $filter, sort: $sort, page: $page) {
        edges {
          cursor
          node {
            id
            fulfillmentDate
            invoiceNumber
            status
            total
          }
        }
        pageInfo {
          count
          endCursor
          hasNextPage
          totalCount
        }
      }
      paymentMethods {
        id
        card {
          brand
          expiryMonth
          expiryYear
          last4
        }
      }
      phone
      updatedAt
    }
  }
`;

const ACCOUNT_UPDATE = gql`
  mutation AccountUpdate($id: ID!, $input: AccountUpdateInput!) {
    accountUpdate(id: $id, input: $input) {
      id
      email
      name
      notes
      phone
    }
  }
`;

const ROWS_PER_PAGE = 20;

const getColumns = ({ query, timezone }) => [
  {
    field: "id",
    hide: true,
  },
  {
    field: OrdersSortField.INVOICE_NUMBER,
    headerName: "Order #",
    renderCell: param => (
      <Link component={RouterLink} to={Routes.ORDER_DETAILS.replace(":id", param.row.id)}>
        {param.value}
      </Link>
    ),
    sortable: !query,
    valueGetter: param => param.row.invoiceNumber,
    width: 120,
  },
  {
    field: "status",
    headerName: "Status",
    sortable: false,
    valueGetter: param => Statuses.find(status => status.id === param.value)?.label,
    width: 140,
  },
  {
    field: OrdersSortField.FULFILLMENT_DATE,
    headerName: "Fulfillment date",
    sortable: !query,
    valueGetter: param => moment.tz(param.row.fulfillmentDate, timezone).format("L"),
    width: 190,
  },
  {
    align: "right" as GridAlignment,
    field: "total",
    headerName: "Total",
    renderCell: param => <Dollar amount={param.value} />,
    sortable: false,
    type: "number",
  },
];

const defaultSortState: GridSortItem = {
  field: OrdersSortField.INVOICE_NUMBER,
  sort: "desc",
};

const CustomerDetails = ({ match, history }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { id } = match.params;

  const { supplier } = useContext(AccountContext);
  const { timezone } = supplier;
  const flagsmith = useContext(FlagsmithContext);
  const hasWholesale = flagsmith.hasFeature("b2b");
  const hasRetail = flagsmith.hasFeature("b2c");

  const [addAddress, setAddAddress] = useState(false);
  const [addPayment, setAddPayment] = useState(false);
  const [sortState, setSortState] = useState(defaultSortState);
  const [orderStates, setOrderStates] = useState({
    audience: "ALL",
    query: undefined,
    status: "all",
  });
  const { audience, query, status } = orderStates;
  const { setPageInfo, resetPagination, pagination, page, onPageChange } = usePagination(ROWS_PER_PAGE);

  const { errors, formState, handleSubmit, register, reset, setValue, watch } = useForm({
    defaultValues: {
      email: "",
      name: "",
      notes: "",
      password: "",
      phone: "",
    },
  });

  const { data, loading } = useQuery(ACCOUNT, {
    context: { source: DATALAYER },
    onCompleted: ({ account }) => {
      reset({
        email: account.email,
        name: account.name,
        notes: account.notes,
        password: "",
        phone: account.phone,
      });

      setPageInfo(account.orders.pageInfo);
    },
    onError: () => history.push(Routes.CUSTOMERS),
    variables: {
      filter: {
        ...(audience !== "ALL" && { audience }),
        ...(query && { query }),
        ...(status !== "all" && { statuses: [status] }),
      },
      id,
      page: pagination,
      sort: sortState && {
        field: sortState.field,
        order: sortState.sort === "desc" ? -1 : 1,
      },
    },
  });

  const [accountUpdate, { loading: accountUpdateLoading }] = useMutation(ACCOUNT_UPDATE, {
    context: { source: DATALAYER },
    onCompleted: () => {
      setValue("password", "", { shouldDirty: false });
      notificationVar({
        message: "Customer updated!",
        severity: "success",
      });
    },
  });

  const { PasswordToggle, passwordInputType } = usePasswordToggle(watch, "password", { size: "small" });

  const handleDiscardClick = () => reset();
  const onSubmit = formData => {
    const input = {
      email: formData.email,
      name: formData.name,
      notes: formData.notes,
      phone: formData.phone,
      ...(formData.password && formData.password.trim().length && { password: formData.password }),
    };

    accountUpdate({
      variables: {
        id: data.account.id,
        input,
      },
    });
  };

  const { client, createdAt } = data?.account ?? {};
  const totalCount = data?.account?.orders.pageInfo.totalCount ?? 0;
  const rows = data?.account?.orders.edges.map(({ node }) => node) ?? [];

  // Table sort / pagination
  const columns = useMemo(() => getColumns({ query, timezone }), [query, timezone]);
  // search does not support soritng
  const sortModel = !query && sortState ? [sortState] : [];

  const onSortChange = ({ sortModel }) => {
    resetPagination();
    setSortState(sortModel[0]);
  };

  const updateOrderStates = keyValue => {
    setOrderStates({
      ...orderStates,
      ...keyValue,
    });
    resetPagination();
  };

  const onAudienceChange = event => updateOrderStates({ audience: event.target.value });
  const onStatusChange = event => updateOrderStates({ status: event.target.value });

  const [searchInput, setSearchInput] = useState(query);
  const onSearchInputChange = event => setSearchInput(event.target.value);
  const onSearchSubmit = ({ type, key }) => {
    if (type === "keydown" && key === "Enter") {
      updateOrderStates({ query: searchInput });
    }
  };
  const onSearchClear = () => {
    setSearchInput("");
    updateOrderStates({ query: undefined });
  };

  return (
    <>
      <Loader loading={loading || accountUpdateLoading} />

      <PageHeader
        primaryActions={
          <>
            <Button color="primary" disabled={!formState?.isDirty} onClick={handleDiscardClick} variant="outlined">
              Discard
            </Button>
            <Button color="primary" disabled={!formState?.isDirty} onClick={handleSubmit(onSubmit)} variant="contained">
              Save
            </Button>
          </>
        }
        stickyHeader
        title={data?.account?.name ?? "Customer details"}
      >
        <Grid alignItems="center" container justifyContent="space-between">
          <Grid item>
            <Typography title={moment.utc(createdAt).format("LL")} color="textSecondary">
              Customer for {moment.utc(createdAt).fromNow(true)}
            </Typography>
          </Grid>
          {hasRetail && (
            <Grid item>
              <Grid container justifyContent="flex-end">
                <Button color="primary" component={RouterLink} to={`${Routes.ORDER_CREATE}?customerId=${id}`}>
                  Create Order
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
      </PageHeader>

      <Grid container direction="row" spacing={2}>
        <Grid item md={8} xs={12}>
          <FormCard title={hasRetail ? "Customer details" : "Account details"}>
            <FormControl fullWidth margin="normal">
              <FormLabel htmlFor="name">Name</FormLabel>
              <TextField
                autoComplete="off"
                error={Boolean(errors?.name)}
                fullWidth
                helperText={errors?.name?.message}
                id="name"
                inputRef={register}
                margin="dense"
                name="name"
                required
                variant="outlined"
              />
            </FormControl>

            <FormControl fullWidth margin="normal">
              <FormLabel htmlFor="email">Email</FormLabel>
              <TextField
                autoComplete="off"
                error={Boolean(errors?.email)}
                fullWidth
                helperText={errors?.email?.message}
                id="email"
                inputRef={register}
                margin="dense"
                name="email"
                required
                type="email"
                variant="outlined"
              />
            </FormControl>

            <FormControl fullWidth margin="normal">
              <FormLabel htmlFor="phone">Phone</FormLabel>
              <TextField
                autoComplete="off"
                error={Boolean(errors?.phone)}
                fullWidth
                helperText={errors?.phone?.message}
                id="phone"
                inputRef={register}
                margin="dense"
                name="phone"
                required
                type="tel"
                variant="outlined"
              />
            </FormControl>

            <FormControl fullWidth margin="normal">
              <FormLabel htmlFor="password">Password</FormLabel>
              <TextField
                autoComplete="off"
                error={Boolean(errors?.password)}
                fullWidth
                helperText={errors?.password?.message}
                id="password"
                inputRef={register}
                InputProps={{ endAdornment: PasswordToggle }}
                margin="dense"
                name="password"
                placeholder="Leave blank if unchanged"
                required
                type={passwordInputType}
                variant="outlined"
              />
            </FormControl>

            <FormControl fullWidth margin="normal">
              <FormLabel htmlFor="notes">Notes</FormLabel>
              <TextField
                autoComplete="off"
                error={Boolean(errors?.notes)}
                fullWidth
                helperText={errors?.notes?.message}
                id="notes"
                inputRef={register}
                margin="dense"
                multiline
                name="notes"
                rows={2}
                variant="outlined"
              />
            </FormControl>
          </FormCard>

          <FormCard title={hasRetail ? "Customer orders" : "Account orders"}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={hasWholesale ? 12 : 6}>
                <TextField
                  InputProps={{
                    endAdornment: (
                      <Fade in={searchInput?.length > 0} unmountOnExit>
                        <IconButton aria-label="search" id="search-submit" onClick={onSearchClear} size="small">
                          <Close />
                        </IconButton>
                      </Fade>
                    ),
                    startAdornment: <Search />,
                  }}
                  fullWidth
                  label="Search"
                  onChange={onSearchInputChange}
                  onKeyDown={onSearchSubmit}
                  placeholder="Search orders"
                  value={searchInput}
                  variant="outlined"
                />
              </Grid>
              {hasWholesale && (
                <Grid item sm={6} xs={12}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel htmlFor="audience-select">Audience</InputLabel>
                    <Select
                      defaultValue="ALL"
                      inputProps={{
                        id: "audience-select",
                      }}
                      label="Audience"
                      onChange={onAudienceChange}
                      title="Audience"
                      value={audience}
                      variant="outlined"
                    >
                      {Audiences.map(({ id, label }) => (
                        <MenuItem key={id} value={id}>
                          {label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
              <Grid item sm={6} xs={12}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel htmlFor="status-select">Status</InputLabel>
                  <Select
                    defaultValue="all"
                    inputProps={{
                      id: "status-select",
                    }}
                    label="Status"
                    onChange={onStatusChange}
                    title="Status"
                    value={status}
                    variant="outlined"
                  >
                    {Statuses.map(({ id, label }) => (
                      <MenuItem key={id} value={id}>
                        {label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <PaginatedTable
              className={classes.spacing}
              columns={columns}
              defaultContent="No orders"
              loading={loading}
              onPageChange={onPageChange}
              onSortModelChange={onSortChange}
              page={page}
              pageSize={ROWS_PER_PAGE}
              rows={rows}
              sortModel={sortModel}
              totalCount={totalCount}
            />
          </FormCard>

          <AppFields id={id} resourceType={AppFieldResourceType.account} />
        </Grid>

        <Grid item md={4} xs={12}>
          <FormCard title="Addresses">
            {data?.account?.addresses?.length ? (
              <List disablePadding>
                {data?.account?.addresses.map(address => (
                  <AccountAddressItem account={data.account} address={address} key={address.id} />
                ))}
              </List>
            ) : (
              <Typography>No addresses</Typography>
            )}

            {addAddress && data?.account && (
              <AccountAddressItem
                account={data.account}
                address={{}}
                onCancel={() => setAddAddress(false)}
                onCreate={() => setAddAddress(false)}
                open
              />
            )}

            <Grid className={classes.spacing} container justifyContent="flex-end">
              <Button color="primary" onClick={() => setAddAddress(true)} variant="outlined">
                Add Address
              </Button>
            </Grid>
          </FormCard>

          <FormCard title="Payment methods">
            {data?.account?.paymentMethods?.length ? (
              <List disablePadding>
                {data?.account?.paymentMethods.map(paymentMethod => (
                  <PaymentMethodItem account={data.account} key={paymentMethod.id} paymentMethod={paymentMethod} />
                ))}
              </List>
            ) : (
              <Typography>No payment methods</Typography>
            )}

            <Dialog
              aria-labelledby="form-dialog-title-add-payment"
              className={classes.dialogContainer}
              fullScreen={useMediaQuery(theme.breakpoints.only("xs"))}
              fullWidth
              maxWidth="sm"
              onClose={(_, reason) => {
                if (reason !== "backdropClick") {
                  setAddPayment(false);
                }
              }}
              open={addPayment}
            >
              <DialogTitle id={`form-dialog-title-add-payment`}>Add payment method</DialogTitle>
              <DialogContent dividers>
                <PaymentMethodForm account={data?.account} onCancelAddCreditCard={() => setAddPayment(false)} />
              </DialogContent>
            </Dialog>
            <Grid className={classes.spacing} container justifyContent="flex-end">
              <Button color="primary" onClick={() => setAddPayment(true)} variant="outlined">
                Add Payment Method
              </Button>
            </Grid>
          </FormCard>

          {client && (
            <FormCard title="Business">
              <Link component={RouterLink} to={Routes.WHOLESALE_CLIENT_DETAILS.replace(":id", client.id)}>
                {client.name}
              </Link>
            </FormCard>
          )}
        </Grid>
      </Grid>
    </>
  );
};

CustomerDetails.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default CustomerDetails;
