import { gql } from "@apollo/client";

import LIST_FRAGMENT from "../fragment/list";

const listDelete = gql`
  mutation ListDelete($listId: ID!) {
    listDelete(_id: $listId) {
      ...ListFragment
    }
  }
  ${LIST_FRAGMENT}
`;

export default listDelete;
