import { FormControl, FormLabel, Grid, Link, makeStyles, TextField, Typography } from "@material-ui/core";
import React from "react";

import FormTooltip from "../../../components/FormTooltip";
import AppForm from "../components/AppForm";

const defaultValues = {
  embedId: "",
};
const useStyles = makeStyles(theme => ({
  instructionSection: {
    backgroundColor: theme.palette.grey[50],
    borderRadius: theme.shape.borderRadius,
    marginTop: theme.spacing(6),
    padding: theme.spacing(2),
  },
}));

const Drift = () => {
  const classes = useStyles();
  const isInstalled = fields => Boolean(fields?.embedId);

  return (
    <AppForm appId="drift" defaultValues={defaultValues} header="Drift" isInstalled={isInstalled}>
      {({ formMethods: { errors, register }, installed }) => (
        <FormControl component="fieldset" fullWidth margin="normal">
          <FormLabel component="legend" htmlFor="embedId">
            Drift ID{" "}
            <FormTooltip content="Enhance your website and customer service with a live chat service for your customers." />
          </FormLabel>
          <TextField
            disabled={installed}
            error={Boolean(errors?.embedId)}
            helperText={errors?.embedId?.message}
            id="embedId"
            inputProps={{
              maxLength: 15,
            }}
            inputRef={register({
              required: "*required",
            })}
            margin="dense"
            multiline
            name="embedId"
            variant="outlined"
          />
          <Typography color="textSecondary" variant="caption">
            i.e. 2xebfbfgswcw
          </Typography>
          <Grid className={classes.instructionSection} xs={12}>
            <Typography gutterBottom variant="subtitle2">
              Installation Guide
            </Typography>
            <Typography color="textSecondary" variant="body2">
              <ol>
                <li>
                  <Link href="https://app.drift.com" target="_blank">
                    Sign in to your Drift account
                  </Link>
                </li>
                <li>
                  <Link href="https://app.drift.com/settings2/widget" target="_blank">
                    Navigate to Settings → App Widget.
                  </Link>
                </li>
                <li>Click Install → JavaScript → Copy.</li>
                <li>
                  Paste the code onto notepad or word document. Scroll to the bottom and copy the unique key value
                  immediately after <b>drift.load</b>. For example, this will look like drift.load('<b>2crndffgxwed</b>
                  '). Copy the bolded value.
                </li>
                <li>Paste the code into the Drift Key input.</li>
                <li>Click Save to finalize your details.</li>
              </ol>
            </Typography>
          </Grid>
        </FormControl>
      )}
    </AppForm>
  );
};

export default Drift;
