import { unitLabel } from "@arowana/util";

const Quantity = ({ amount, unit = null }) => {
  if (!/^\d+(?:\.\d*)?$/.test(amount)) {
    return amount;
  }

  const rounded = parseFloat(amount)
    .toFixed(2)
    .replace(/\.00?$/, "");

  if (unit === null) {
    return rounded;
  }

  return unitLabel(unit, Number(rounded));
};

export default Quantity;
