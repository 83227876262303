import { gql, useMutation, useQuery } from "@apollo/client";
import { Account, AccountUpdateInput } from "@arowana/graphql";
import { usePasswordToggle } from "@arowana/ui";
import { DATALAYER } from "@arowana/util";
import { Button, FormControl, FormLabel, TextField, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { notificationVar } from "../../../cache/notificationPolicy";
import FormCard from "../../../components/FormCard";
import Loader from "../../../components/Loader";
import PageHeader from "../../../components/PageHeader";

const CURRENT_ACCOUNT = gql`
  query CurrentAccount {
    account: currentAccount {
      createdAt
      email
      id
      name
      phone
      updatedAt
    }
  }
`;

const ACCOUNT_UPDATE = gql`
  mutation AccountUpdate($id: ID!, $input: AccountUpdateInput!) {
    account: accountUpdate(id: $id, input: $input) {
      createdAt
      email
      id
      name
      phone
      updatedAt
    }
  }
`;

function AccountDetails() {
  const [account, setAccount] = useState<Account | undefined>();
  const { data, loading } = useQuery(CURRENT_ACCOUNT, {
    context: { source: DATALAYER },
  });
  const { handleSubmit, errors, formState, watch, register, reset } = useForm<AccountUpdateInput>({
    defaultValues: {
      email: "",
      name: "",
      password: "",
    },
  });

  const [updateAccount, { loading: isUpdating }] = useMutation(ACCOUNT_UPDATE, {
    context: { source: DATALAYER },
    onCompleted: data => {
      notificationVar({
        message: "Account updated!",
        severity: "success",
      });
      setAccount(data.account);
    },
  });
  const handleDiscardClick = () => reset();
  const { PasswordToggle, passwordInputType } = usePasswordToggle(watch);

  const onSubmit = formData => {
    const input: AccountUpdateInput = {
      email: formData.email,
      name: formData.name,
    };

    if (formData.password) {
      input.password = formData.password;
    }

    updateAccount({
      variables: {
        id: account.id,
        input,
      },
    });
  };

  useEffect(() => {
    if (!loading && data?.account) {
      reset({
        email: data.account.email,
        name: data.account.name,
      });
      setAccount(data.account);
    }
  }, [data, loading, reset]);

  if (loading) {
    return (
      <>
        <Typography variant="h3">
          <Skeleton />
        </Typography>
        <Typography variant="body1">
          <Skeleton />
        </Typography>
        <Typography variant="body1">
          <Skeleton />
        </Typography>
        <Typography variant="body1">
          <Skeleton />
        </Typography>
      </>
    );
  }

  const title = account?.name ?? "Account details";

  return (
    <>
      <Loader loading={loading || isUpdating} />

      <PageHeader
        primaryActions={
          <>
            <Button color="primary" disabled={!formState?.isDirty} onClick={handleDiscardClick} variant="outlined">
              Discard
            </Button>
            <Button color="primary" disabled={!formState?.isDirty} onClick={handleSubmit(onSubmit)} variant="contained">
              Save
            </Button>
          </>
        }
        documentTitle={title}
        stickyHeader
        title={title}
      />

      <FormCard title="Account details">
        <FormControl fullWidth margin="normal">
          <FormLabel htmlFor="name">Name</FormLabel>
          <TextField
            error={Boolean(errors?.name)}
            fullWidth
            helperText={errors?.name?.message}
            id="name"
            inputRef={register({ required: "*required" })}
            margin="dense"
            name="name"
            variant="outlined"
          />
        </FormControl>

        <FormControl fullWidth margin="normal">
          <FormLabel htmlFor="email">Email</FormLabel>
          <TextField
            error={Boolean(errors?.email)}
            fullWidth
            helperText={errors?.email?.message}
            id="email"
            inputRef={register({ required: "*required" })}
            margin="dense"
            name="email"
            type="email"
            variant="outlined"
          />
        </FormControl>

        <FormControl fullWidth margin="normal">
          <FormLabel htmlFor="password">Update password</FormLabel>
          <TextField
            error={Boolean(errors?.password)}
            fullWidth
            type={passwordInputType}
            helperText={errors?.password?.message}
            id="password"
            inputRef={register()}
            margin="dense"
            name="password"
            placeholder="Leave blank to keep your current password"
            variant="outlined"
            InputProps={{ endAdornment: PasswordToggle }}
          />
        </FormControl>
      </FormCard>
    </>
  );
}

export default AccountDetails;
