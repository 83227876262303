import "react-multi-carousel/lib/styles.css";

import { Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import { useState } from "react";

import CarouselForm from "./CarouselForm";
import Slide from "./Slide";

const SlidesList = ({ value, onChange, displayType }) => {
  const allSlides = value.slice();
  const [editSlideIndex, setEditSlideIndex] = useState(-1);
  const handleUpdateSlide = (updatedSlide, index) => {
    allSlides[index] = {
      ...allSlides[index],
      ...updatedSlide,
    };

    setEditSlideIndex(-1);

    onChange(allSlides);
  };
  const handleDeleteSlide = index => {
    allSlides.splice(index, 1);

    // if there is a slide being edited
    if (editSlideIndex > -1 && index < editSlideIndex) {
      // if deleting a slide in front of the being-edited slide
      setEditSlideIndex(editSlideIndex - 1);
    }

    onChange(allSlides);
  };
  const handleMoveUpSlide = index => {
    [allSlides[index - 1], allSlides[index]] = [allSlides[index], allSlides[index - 1]];

    // if there is a slide being edited
    if (editSlideIndex > -1 && index === editSlideIndex + 1) {
      // if moving a slide to the front of the being-edited slide
      setEditSlideIndex(editSlideIndex + 1);
    }

    onChange(allSlides);
  };
  const handleMoveDownSlide = index => {
    [allSlides[index], allSlides[index + 1]] = [allSlides[index + 1], allSlides[index]];

    // if there is a slide being edited
    if (editSlideIndex > -1 && index === editSlideIndex - 1) {
      // if moving a slide to the behind of the being-edited slide
      setEditSlideIndex(editSlideIndex - 1);
    }

    onChange(allSlides);
  };
  const handleAddSlide = slide => {
    allSlides.push(slide);

    onChange(allSlides);
  };
  const handleOpenSlideForm = index => setEditSlideIndex(index);
  const handleCloseSlideForm = () => setEditSlideIndex(-1);

  return (
    <>
      {allSlides.map((slide, index) => (
        <Slide
          displayType={displayType}
          index={index}
          isEditing={index === editSlideIndex}
          isLastSlide={index === allSlides.length - 1}
          key={`slide_${index}`}
          onCloseSlideForm={handleCloseSlideForm}
          onDeleteSlide={handleDeleteSlide}
          onMoveDownSlide={handleMoveDownSlide}
          onMoveUpSlide={handleMoveUpSlide}
          onOpenSlideForm={handleOpenSlideForm}
          onUpdateSlide={handleUpdateSlide}
          slide={slide}
        />
      ))}

      {allSlides.length < 5 && (
        <>
          <Typography color="textSecondary" gutterBottom variant="body2">
            Slide {allSlides.length + 1}
          </Typography>
          <CarouselForm isCreate onSubmit={handleAddSlide} />
        </>
      )}
    </>
  );
};

SlidesList.defaultProps = {
  displayType: "full",
  onChange: () => {},
  value: [],
};

SlidesList.propTypes = {
  displayType: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.array,
};

export default SlidesList;
