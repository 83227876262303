import {
  Button,
  Card,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Typography,
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  contentContainer: {
    "&:not(:last-child)": {
      marginBottom: theme.spacing(2),
    },
    "background-image": "linear-gradient(to right, #fef2f2, #edfff3)",
    "border": `1px solid ${theme.palette.grey[300]}`,
    "borderRadius": theme.shape.borderRadius,
    "padding": theme.spacing(1.5, 2.5, 2),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  noPadding: {
    padding: 0,
  },
  noTitle: {
    paddingTop: theme.spacing(2),
  },
  productImage: {
    maxWidth: 400,
    objectFit: "contain",
    padding: theme.spacing(5, 1),
    width: "100%",
  },
  title: {
    fontWeight: 600,
    letterSpacing: theme.typography.h6.letterSpacing,
    lineHeight: theme.typography.h6.lineHeight,
    margin: theme.spacing(0.5, 0, 1.5),
  },
  viewMoreButton: {
    marginTop: theme.spacing(2),
  },
}));

type PaywallCardProps = {
  imageUrl?: string;
  children: JSX.Element | JSX.Element[];
  className?: string;
  title?: string | JSX.Element | JSX.Element[];
  variant?: "outlined" | "elevation";
};

const PaywallCard = ({ children, className, title, imageUrl, variant = "outlined", ...rest }: PaywallCardProps) => {
  const classes = useStyles();

  return (
    <Card
      className={clsx(classes.contentContainer, { [classes.noTitle]: !title }, className)}
      variant={variant}
      {...rest}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          {title && (
            <Typography className={classes.title} variant="h5">
              {title}
            </Typography>
          )}
          {children}
          <Typography variant="body1" className={classes.noTitle}>
            What else you get when you upgrade:
          </Typography>
          <List>
            <ListItem className={classes.noPadding}>
              <ListItemIcon>
                <CheckIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary="Wholesale ordering portal" />
            </ListItem>
            <ListItem className={classes.noPadding}>
              <ListItemIcon>
                <CheckIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary="Custom pricing and availability by group" />
            </ListItem>
            <ListItem className={classes.noPadding}>
              <ListItemIcon>
                <CheckIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary="Invoicing and digital signatures" />
            </ListItem>
            <ListItem className={classes.noPadding}>
              <ListItemIcon>
                <CheckIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary="Customizable home page" />
            </ListItem>
          </List>
          <Button
            className={classes.viewMoreButton}
            color="primary"
            href="mailto:support@freshline.io?subject=Requesting upgrade to Freshline Advanced"
            rel="noreferrer"
            target="_blank"
            variant="contained"
          >
            Upgrade to Freshline Advanced
          </Button>
        </Grid>
        {imageUrl && (
          <Grid item xs={12} sm={6}>
            <img alt="productImage" className={classes.productImage} src={imageUrl} />
          </Grid>
        )}
      </Grid>
    </Card>
  );
};

export default PaywallCard;
