import { gql } from "@apollo/client";

const SupplierProductVariants = gql`
  query SupplierProductVariants($filter: ProductsFilterInput, $sort: ProductsSortInput, $page: PaginationInput) {
    supplier: currentSupplier {
      products(filter: $filter, sort: $sort, page: $page) {
        edges {
          cursor
          node {
            id
            name
            subName: subname
            active
            tags
            stockStatus
            displayPrice
            displayUnit
            updatedAt
            featured
            totalInventory
            trackInventory
            variants {
              id
              cost
              caseSize
              name
              outOfStock
              price
              quantity
              unit
              shippingWeight
              shippingWeightUnit
              sku
            }
            categoryId
            category {
              id
              name
              tags {
                label
                value
              }
            }
          }
        }
        pageInfo {
          count
          endCursor
          hasNextPage
          totalCount
        }
      }
    }
  }
`;

export default SupplierProductVariants;
