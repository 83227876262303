import { gql } from "@apollo/client";

const appShippingConnectPurolator = gql`
  mutation AppShippingConnectPurolator($input: ShippingConnectPurolatorInput!) {
    appShippingConnectPurolator(input: $input) {
      id
      name
      defaultServiceCode
      region
    }
  }
`;

export default appShippingConnectPurolator;
