import { gql } from "@apollo/client";

const appShippingConnectFedex = gql`
  mutation AppShippingConnectFedex($input: ShippingConnectFedexInput!) {
    appShippingConnectFedex(input: $input) {
      id
      name
      defaultServiceCode
      region
    }
  }
`;

export default appShippingConnectFedex;
