import { Drawer, IconButton, makeStyles, Typography } from "@material-ui/core";
import { Close as CloseIcon, Store as StoreIcon } from "@material-ui/icons";
import Link from "next/link";
import { useRouter } from "next/router";
import React from "react";

const useStyles = makeStyles(theme => ({
  closeButton: {
    left: theme.spacing(1),
    position: "absolute",
    top: theme.spacing(1),
  },
  content: {
    backgroundColor: theme.palette.background.paper,
    height: "100%",
    overflowY: "auto",
    paddingBottom: theme.spacing(2),
  },
  drawer: {
    width: 550,
    [theme.breakpoints.only("xs")]: { width: "100%" },
  },
  footer: {
    backgroundColor: theme.palette.background.default,
    marginTop: "auto",
    padding: theme.spacing(2),
  },
  header: {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(2, 0),
    position: "relative",
    textAlign: "center",
  },
  marketButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
}));

interface DrawerWrapperProps {
  anchor?: "bottom" | "left" | "right" | "top";
  children: React.ReactNode;
  footer?: React.ReactNode;
  onClose: () => void;
  open: boolean;
  title: string;
}

export const DrawerWrapper = ({ anchor = "right", children, footer, onClose, open, title }: DrawerWrapperProps) => {
  const router = useRouter();
  const classes = useStyles();
  const renderButton = router?.pathname === "/";
  const marketButton = (
    <IconButton className={classes.marketButton} color="primary" onClick={onClose}>
      <StoreIcon />
    </IconButton>
  );

  return (
    <Drawer
      anchor={anchor}
      classes={{
        paperAnchorLeft: classes.drawer,
        paperAnchorRight: classes.drawer,
      }}
      onClose={onClose}
      open={open}
    >
      <header className={classes.header}>
        <Typography variant="h6">{title}</Typography>
        <IconButton className={classes.closeButton} color="primary" onClick={onClose}>
          <CloseIcon />
        </IconButton>
        {renderButton ? (
          marketButton
        ) : (
          <Link href="/" passHref>
            {marketButton}
          </Link>
        )}
      </header>
      <main className={classes.content}>{children}</main>
      {footer && <footer className={classes.footer}>{footer}</footer>}
    </Drawer>
  );
};

export default DrawerWrapper;
