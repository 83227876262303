import { gql, useMutation, useQuery } from "@apollo/client";
import { FulfillmentMethod, WholesaleCart, WholesaleCartLineItemInput } from "@arowana/graphql";
import { CalculationLine, SupplierContext } from "@arowana/ui";
import { DATALAYER } from "@arowana/util";
import {
  Box,
  Button,
  Collapse,
  Fade,
  FormControl,
  FormLabel,
  Grid,
  Link,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { Alert, Skeleton } from "@material-ui/lab";
import moment from "moment";
import qs from "qs";
import { useContext, useEffect, useMemo, useState } from "react";
import { Prompt } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";

import { notificationVar } from "../../../cache/notificationPolicy";
import FormCard from "../../../components/FormCard";
import FulfillmentChip from "../../../components/FulfillmentChip";
import Loader from "../../../components/Loader";
import PageHeader from "../../../components/PageHeader";
import Routes from "../../../Constants/Routes";
import RadioSelector from "../../account/components/RadioSelector";
import ClientAutocomplete from "../../wholesale/components/ClientAutocomplete";
import WholesaleOrderProductInputList from "../component/WholesaleOrderProductInputList";

const CLIENT = gql`
  query Client($id: ID!) {
    client(id: $id) {
      id
      name
      phone
      owner {
        id
        name
        email
      }
      address {
        formattedAddress
      }
      active
      wholesaleList {
        id
        name
      }
    }
    currentSupplier {
      id
      locations {
        id
        active
        address {
          formattedAddress
        }
        cutOffHour
        fulfillmentMethod
        fulfillmentTimes {
          id
          label
        }
        name
      }
    }
  }
`;

export const WHOLESALE_CART_FRAGMENT = gql`
  fragment WholesaleCartFields on WholesaleCart {
    id
    fulfillmentDate
    notes
    fulfillmentWindow {
      id
    }
    location {
      id
      name
      cutOffHour
      fulfillmentMethod
    }
    lineItems {
      overrideId
      product {
        id
        active
        outOfStock
        images {
          original
          x256
          x512
          x896
          x1664
        }
        name
        variants {
          id
          caseSize
          name
          price
          unit
        }
      }
      quantity
      totalAmount
    }
    priceBreakdown {
      fees {
        items {
          amount
          description
          itemType
        }
        total
      }
      subTotal
      total
    }
  }
`;

const WHOLESALE_CART = gql`
  ${WHOLESALE_CART_FRAGMENT}
  query WholesaleCart($clientId: ID!) {
    wholesaleCartForClient(clientId: $clientId) {
      ...WholesaleCartFields
    }
  }
`;

const WHOLESALE_CART_UPDATE = gql`
  ${WHOLESALE_CART_FRAGMENT}
  mutation WholesaleCartUpdate($input: WholesaleCartUpdateInput!) {
    wholesaleCartUpdate(input: $input) {
      ...WholesaleCartFields
    }
  }
`;

const CHECKOUT = gql`
  mutation Checkout($id: ID!) {
    wholesaleCartCheckout(id: $id) {
      id
      invoiceNumber
      total
    }
  }
`;

const DELIVERY_SHIPPING = FulfillmentMethod.delivery + ":" + FulfillmentMethod.shipping;

const WholesaleOrderCreate = ({ history }) => {
  const params = qs.parse(history.location.search, { ignoreQueryPrefix: true });
  const { timezone } = useContext(SupplierContext);

  const clientId = params?.clientId;
  const onClientChange = ({ id: clientId }) =>
    history.replace({
      search: qs.stringify({
        ...params,
        clientId,
      }),
    });
  const onClientRemove = () =>
    history.replace({
      search: null,
    });

  const { data: clientData } = useQuery(CLIENT, {
    context: { source: DATALAYER },
    fetchPolicy: "cache-and-network",
    skip: !clientId,
    variables: {
      id: clientId,
    },
  });

  const client = clientData?.client;
  const supplier = clientData?.currentSupplier;
  const [pickupLocations, deliveryShippingLocations] = useMemo(() => {
    const pickup = [];
    const deliveryShipping = [];

    supplier?.locations?.forEach(loc => {
      if (loc.fulfillmentMethod === FulfillmentMethod.pickup) {
        pickup.push(loc);
      } else {
        deliveryShipping.push(loc);
      }
    });

    return [pickup, deliveryShipping];
  }, [supplier?.locations]);
  const fulfillmentOptions = useMemo(() => {
    return [
      {
        disabled: deliveryShippingLocations.length === 0,
        label: "Delivery/Shipping",
        value: DELIVERY_SHIPPING,
      },
      { disabled: pickupLocations.length === 0, label: "Pickup", value: FulfillmentMethod.pickup },
    ];
  }, [supplier?.locations, pickupLocations]);
  const { wholesaleList, active, owner = {} } = client ?? {};
  const clientURL = Routes.WHOLESALE_CLIENT_DETAILS.replace(":id", clientId);

  const { data: cartData, loading: loadingCart } = useQuery<{ wholesaleCartForClient: WholesaleCart }>(WHOLESALE_CART, {
    context: { source: DATALAYER },
    fetchPolicy: "cache-and-network",
    skip: !clientId || !active,
    variables: {
      clientId,
    },
  });
  const cart = cartData?.wholesaleCartForClient;
  const { lineItems = [], notes = "", location: cartLocation } = cart ?? {};
  const { fees = [], subTotal = 0, total = 0, discounts = [] } = cart?.priceBreakdown ?? {};
  const hasLineItems = lineItems.length > 0;

  const [cartUpdate] = useMutation(WHOLESALE_CART_UPDATE, {
    context: { source: DATALAYER },
    refetchQueries: ["WholesaleCart"],
  });

  const [submitOrder, { data: orderData, loading: isSubmitting, error }] = useMutation(CHECKOUT, {
    context: { source: DATALAYER },
    onCompleted: ({ wholesaleCartCheckout }) => {
      history.push(Routes.ORDER_DETAILS.replace(":id", wholesaleCartCheckout.id));
    },
    variables: {
      id: cart?.id,
    },
  });
  const orderSuccess = Boolean(orderData?.wholesaleCartCheckout?.id);

  // LineItems CRUD
  const onProductAdd = overrideId =>
    cartUpdate({
      variables: {
        input: {
          id: cart?.id,
          lineItems: [{ overrideId, quantity: 1 }],
        },
      },
    });

  const onProductChange = (overrideId, lineItem) =>
    cartUpdate({
      variables: {
        input: {
          id: cart?.id,
          lineItems: [{ overrideId, ...lineItem }],
        },
      },
    });

  const [removingAll, setRemovingAll] = useState(false);
  const onRemoveAllProduct = () => {
    setRemovingAll(true);
    cartUpdate({
      variables: {
        input: {
          id: cart?.id,
          lineItems: lineItems.map(({ overrideId, quantity }) => ({ overrideId, quantity: -quantity })),
        },
      },
    }).finally(() => setRemovingAll(false));
  };

  // Remove lineItem where the product/PVO no longer exists
  const itemsWithNullProducts = useMemo<WholesaleCartLineItemInput[]>(
    () =>
      cart?.lineItems?.reduce((list, { overrideId, quantity, product }) => {
        if (!product) {
          list.push({
            overrideId,
            quantity: -quantity,
          });
        }

        return list;
      }, []) ?? [],
    [cart?.lineItems],
  );

  useEffect(() => {
    if (itemsWithNullProducts.length > 0) {
      cartUpdate({
        onCompleted: () =>
          notificationVar({
            message: "Some item(s) were removed since they don't exist in the current price list.",
            severity: "warning",
          }),
        variables: {
          input: {
            id: cart.id,
            lineItems: itemsWithNullProducts,
          },
        },
      });
    }
  }, [cartUpdate, cart?.id, itemsWithNullProducts]);

  // Fulfillment Location
  const isPickup = cartLocation?.fulfillmentMethod === FulfillmentMethod.pickup;
  const fulfillmentMethodRadioValue = useMemo(() => {
    if (isPickup) {
      return cartLocation?.fulfillmentMethod;
    } else {
      return DELIVERY_SHIPPING;
    }
  }, [cartLocation?.fulfillmentMethod]);
  const fulfillmentLocations = isPickup ? pickupLocations : deliveryShippingLocations;

  const onFulfillmentMethodToggle = value => {
    let variables;
    switch (value) {
      case DELIVERY_SHIPPING: {
        variables = {
          input: {
            id: cart?.id,
            setToDelivery: true,
          },
        };
        break;
      }

      case FulfillmentMethod.pickup: {
        variables = {
          input: {
            id: cart?.id,
            locationId: pickupLocations?.[0]?.id,
          },
        };
      }
    }

    cartUpdate({ variables });
  };

  const locationId = cart?.location?.id ?? "";
  const onLocationChange = event => {
    cartUpdate({
      variables: {
        input: {
          id: cart?.id,
          locationId: event?.target?.value,
        },
      },
    });
  };

  // Fulfillment Time
  const fulfillmentWindowId = cart?.fulfillmentWindow?.id ?? "";
  const fulfillmentTimes = useMemo(
    () => supplier?.locations?.find(({ id }) => id === locationId)?.fulfillmentTimes ?? [],
    [supplier?.locations, locationId],
  );
  const onTimeChange = event => {
    cartUpdate({
      variables: {
        input: {
          fulfillmentWindow: {
            id: event?.target?.value,
          },
          id: cart?.id,
        },
      },
    });
  };

  // Fulfillment Date
  const fulfillmentDate = cart?.fulfillmentDate
    ? moment.tz(cart?.fulfillmentDate, timezone).format("YYYY-MM-DD")
    : null;
  const { callback: onDateChange } = useDebouncedCallback(
    event =>
      cartUpdate({
        variables: {
          input: {
            fulfillmentDate: moment.tz(event.target.value, timezone).hour(0).utc().toISOString(),
            id: cart?.id,
          },
        },
      }),
    500,
  );
  useEffect(() => {
    if (cart?.id && !fulfillmentDate) {
      cartUpdate({
        variables: {
          input: {
            fulfillmentDate: moment().hour(0).utc().toISOString(),
            id: cart?.id,
          },
        },
      });
    }
  }, [cart?.id, fulfillmentDate]);

  // Notes
  const { callback: onNoteChange } = useDebouncedCallback(event => {
    cartUpdate({
      variables: {
        input: {
          id: cart?.id,
          notes: event.target.value,
        },
      },
    });
  }, 500);

  // Checkout
  const canCheckout = cart?.id && cart?.location?.id && cart?.lineItems?.length > 0 && cart?.fulfillmentDate;
  const hasInactiveOrOutofStock = useMemo(
    () => hasLineItems && lineItems.some(({ product }) => product?.outOfStock || !product?.active),
    [hasLineItems, lineItems],
  );

  let controls;

  if (!clientId) {
    controls = null;
  } else if (!client?.active) {
    controls = (
      <FormCard>
        <Typography variant="h6">
          Business is not{" "}
          <Link component={RouterLink} to={clientURL}>
            approved to order
          </Link>
          .
        </Typography>
      </FormCard>
    );
  } else if (!client?.wholesaleList) {
    controls = (
      <FormCard>
        <Typography variant="h6">
          Business is not{" "}
          <Link component={RouterLink} to={clientURL}>
            assigned to a price list
          </Link>
          .
        </Typography>
      </FormCard>
    );
  } else {
    controls = (
      <>
        <FormCard
          title="Products"
          action={
            <Fade in={lineItems.length > 0}>
              <Button
                color="secondary"
                variant="outlined"
                size="small"
                onClick={onRemoveAllProduct}
                disabled={removingAll}
              >
                Remove all
              </Button>
            </Fade>
          }
        >
          <WholesaleOrderProductInputList
            value={lineItems}
            wholesaleList={wholesaleList}
            onProductAdd={onProductAdd}
            onProductChange={onProductChange}
          />
          <Collapse in={hasInactiveOrOutofStock}>
            <Alert severity="warning">Some item(s) are inactive or out of stock, please proceed with caution</Alert>
          </Collapse>
        </FormCard>

        <FormCard title="Fulfillment">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <RadioSelector
                options={fulfillmentOptions}
                value={fulfillmentMethodRadioValue}
                onChange={onFulfillmentMethodToggle}
              />
            </Grid>
            {!isPickup && (
              <Grid item xs={12}>
                <FormLabel id="locaiton-label" component="legend" htmlFor="location" style={{ marginBottom: 8 }}>
                  Address
                </FormLabel>
                {client?.address?.formattedAddress}
              </Grid>
            )}
            <Grid item xs={12}>
              <FormControl component="fieldset" fullWidth margin="dense" variant="outlined" disabled={!isPickup}>
                <FormLabel id="locaiton-label" component="legend" htmlFor="location" style={{ marginBottom: 8 }}>
                  Service location
                </FormLabel>
                <Select labelId="location-label" id="location" value={locationId} onChange={onLocationChange}>
                  {fulfillmentLocations?.map(({ id, name, fulfillmentMethod, address }) => (
                    <MenuItem value={id} key={id}>
                      <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                        <div>
                          <Typography>{name}</Typography>
                          <Typography variant="body2">{address?.formattedAddress}</Typography>
                        </div>
                        <FulfillmentChip fulfillmentType={fulfillmentMethod} />
                      </Box>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl component="fieldset" fullWidth margin="dense" variant="outlined">
                <FormLabel id="date-label" component="legend" htmlFor="date">
                  Fulfillment Date
                </FormLabel>
                {fulfillmentDate ? (
                  <TextField
                    id="date"
                    type="date"
                    variant="outlined"
                    margin="dense"
                    defaultValue={fulfillmentDate}
                    onChange={onDateChange}
                  />
                ) : (
                  <Skeleton variant="rect" width="100%" height={38} />
                )}
              </FormControl>
            </Grid>
            {fulfillmentTimes.length > 0 && (
              <Grid item xs={6}>
                <FormControl component="fieldset" fullWidth margin="dense" variant="outlined">
                  <FormLabel id="time-label" component="legend" htmlFor="location" style={{ marginBottom: 8 }}>
                    Time window
                  </FormLabel>
                  <Select
                    margin="dense"
                    labelId="time-label"
                    id="time"
                    value={fulfillmentWindowId}
                    onChange={onTimeChange}
                  >
                    {fulfillmentTimes.map(({ id, label }) => {
                      return (
                        <MenuItem key={id} value={id}>
                          {label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
            )}
            <Grid item xs={12}>
              <FormControl component="fieldset" fullWidth margin="dense" variant="outlined">
                <FormLabel id="notes-label" component="legend" htmlFor="notes">
                  Order notes (optional)
                </FormLabel>
                <TextField
                  id="notes"
                  fullWidth
                  maxRows={10}
                  minRows={5}
                  multiline
                  defaultValue={notes}
                  onChange={onNoteChange}
                  margin="dense"
                  variant="outlined"
                />
              </FormControl>
            </Grid>
          </Grid>
        </FormCard>
      </>
    );
  }

  return (
    <>
      <Loader loading={isSubmitting && !error} />
      <Prompt
        message="The order is incomplete, are you sure you want to leave?"
        when={Boolean(cart) && !orderSuccess}
      />
      <PageHeader
        primaryActions={
          <Button color="primary" disabled={!canCheckout} onClick={submitOrder} variant="contained">
            Create
          </Button>
        }
        stickyHeader
        title="Create wholesale order"
      />
      <Grid container spacing={2} style={{ position: "relative" }}>
        <Grid item md={9} xs={12}>
          <FormCard
            title="Business"
            action={
              <Fade in={Boolean(clientId)}>
                <Button size="small" variant="outlined" color="secondary" onClick={onClientRemove}>
                  Remove
                </Button>
              </Fade>
            }
          >
            <Collapse in={!clientId}>
              <Typography gutterBottom>Please select a business to continue ordering</Typography>
              <ClientAutocomplete disableInactive onChange={onClientChange} noOptionsText={"No businesses found"} />
            </Collapse>
            <Collapse in={Boolean(client)}>
              <Typography variant="body1">
                <Link component={RouterLink} to={clientURL}>
                  {client?.name}
                </Link>{" "}
                • {client?.phone}
              </Typography>
              <Typography variant="body1">
                {owner?.name} • {owner?.email}
              </Typography>
            </Collapse>
          </FormCard>
          {controls}
        </Grid>
        <Grid item md={3} xs={12} style={{ position: "sticky" }}>
          <FormCard title="Summary">
            <CalculationLine label="Subtotal" loading={loadingCart} cents={subTotal} />
            {fees?.items?.map(({ amount, itemType, description }, idx) => (
              <CalculationLine key={`${idx}_${itemType}`} label={description} loading={loadingCart} cents={amount} />
            ))}
            {discounts?.items?.map(({ amount, itemType, description }, idx) => (
              <CalculationLine key={`${idx}_${itemType}`} label={description} loading={loadingCart} cents={amount} />
            ))}
            <CalculationLine label="Total" loading={loadingCart} cents={total} variant="h6" />
          </FormCard>
        </Grid>
      </Grid>
    </>
  );
};

export default WholesaleOrderCreate;
