export const FEATURE_B2B_SHOW_PAYMENT_SETTINGS = "b2b:show-payment-settings";

export const FEATURE_B2C_CATEGORY_FILTER = "b2c:category-filter";

export const FEATURE_B2B_CHECKOUT_DISCOUNT_CODE = "b2b:checkout-discount-code";

export const FEATURE_B2C_RECURRING_ORDERS = "b2c:recurring-orders";

export const FEATURE_B2C_YAMA_HOMEPAGE = "b2c:yama-homepage-enabled";

export const FEATURE_SUPPLIER_ADD_ACCOUNT_TO_BUSINESS = "supplier-add-account-to-business";

export const FEATURE_SUPPLIER_APP_FIELDS = "supplier-app-fields";

export const FEATURE_SUPPLIER_APPS_WISE = "supplier-apps-wise";

export const FEATURE_SUPPLIER_AVAILABILITY_SCHEDULE = "supplier-availability-schedule";

export const FEATURE_SUPPLIER_CREATE_WHOLESALE_ORDER = "supplier-create-wholesale-order";

export const FEATURE_SUPPLIER_EXPORT_ORDERS_MINOTAUR = "supplier-export-orders-minotaur";

export const FEATURE_SUPPLIER_EXPORT_ORDERS_SEASOFT_TSV = "supplier-export-orders-seasoft-tsv";

export const FEATURE_SUPPLIER_ORDER_SIGNATURE = "supplier-order-signature";

export const FEATURE_SUPPLIER_RECURRING_ORDERS = "supplier-recurring-orders";

export const FEATURE_SUPPLIER_RECURRING_ORDERS_EXPORT = "supplier-recurring-orders-export";

export const FEATURE_SUPPLIER_RECURRING_ORDERS_FOR_B2B = "supplier-recurring-orders-for-b2b";

export const FEATURE_SUPPLIER_RETAIL_ORDER_SIGNATURE = "supplier-retail-order-signature";

export const FEATURE_SUPPLIER_SETTINGS_NAVBAR_VIBRANT = "supplier-settings-navbar-vibrant";

export const FEATURE_SUPPLIER_STRIPE_INVOICES = "supplier-stripe-invoices";

export const FEAT_SUPPLIER_NEW_CREATE_RETAIL_ORDER = "supplier-new-create-retail-order";
