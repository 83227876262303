import { Box, Button, FormControlLabel, Grid, makeStyles, Switch, TextField, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

const useStyles = makeStyles(theme => ({
  bannerSpacing: {
    marginBottom: theme.spacing(2),
  },
  bannerToggler: {
    marginLeft: 0,
  },
  expandBannerButton: {
    float: "right",
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(1.5),
      width: "100%",
    },
  },
  previewContainer: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.background.default,
    padding: theme.spacing(2),
    textAlign: "center",
  },
}));
const Banner = ({ helperText, onChange, value }) => {
  const banner = { ...value };
  const classes = useStyles();
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    if (banner.isEnabled) {
      setShowBanner(true);
    }
  }, [banner.isEnabled]);

  const handleAddBanner = () => setShowBanner(true);
  const handleToggleDisplayBanner = e => {
    onChange({ ...banner, isEnabled: e.target.checked });
  };
  const handleDeleteBanner = () => {
    setShowBanner(false);
    onChange({ isEnabled: false, message: "", title: "" });
  };
  const handleBannerChange = (e, key) => {
    const bannerContent = e.target.value;
    onChange({ ...banner, [key]: bannerContent });
  };
  let renderedBanner;

  if (showBanner) {
    renderedBanner = (
      <>
        <Box mb={2}>
          <Box className={classes.bannerSpacing}>
            <Typography color="textSecondary" variant="body2">
              Share important updates with customers with an announcement bar on your store.
            </Typography>
          </Box>
          <Box display="flex" flexWrap="wrap" justifyContent="space-between" mb={2}>
            <FormControlLabel
              checked={Boolean(banner.isEnabled)}
              className={classes.bannerToggler}
              control={<Switch color="primary" />}
              label="Display on shop"
              labelPlacement="start"
              onChange={handleToggleDisplayBanner}
            />
            <Button onClick={handleDeleteBanner}>Delete Banner</Button>
          </Box>

          <Box mb={2}>
            <Typography gutterBottom variant="subtitle1">
              Title
            </Typography>
            <TextField
              fullWidth
              onChange={e => handleBannerChange(e, "title")}
              value={banner.title}
              variant="outlined"
            />
            {helperText.type === "requiredBannerTitle" && (
              <Typography color="secondary" variant="caption">
                {helperText.message}
              </Typography>
            )}
            <Typography variant="caption">Add a captivating hook to draw customer interest.</Typography>
          </Box>

          <Box>
            <Typography gutterBottom variant="subtitle1">
              Message
            </Typography>
            <TextField
              fullWidth
              onChange={e => handleBannerChange(e, "message")}
              value={banner.message}
              variant="outlined"
            />
            {helperText.type === "requiredBannerMessage" && (
              <Typography color="secondary" variant="caption">
                {helperText.message}
              </Typography>
            )}
            <Typography variant="caption">Provide details related to your announcement title.</Typography>
          </Box>
        </Box>

        <Box>
          <Typography gutterBottom variant="subtitle1">
            Preview
          </Typography>
          <Box className={classes.previewContainer}>
            <Typography gutterBottom variant="subtitle1">
              {banner.title}
            </Typography>
            <Typography variant="body2">{banner.message}</Typography>
          </Box>
        </Box>
      </>
    );
  } else {
    renderedBanner = (
      <Grid alignItems="center" container spacing={1}>
        <Grid item lg={10} md={9} xs={12}>
          <Typography color="textSecondary" variant="body2">
            Share important updates with customers with an announcement bar on your store.
          </Typography>
        </Grid>
        <Grid item lg={2} md={3} xs={12}>
          <Button className={classes.expandBannerButton} color="primary" onClick={handleAddBanner} variant="contained">
            Add
          </Button>
        </Grid>
      </Grid>
    );
  }

  return renderedBanner;
};

Banner.defaultProps = {
  helperText: {},
  onChange: () => {},
  value: {},
};

Banner.propTypes = {
  helperText: PropTypes.object,
  onChange: PropTypes.func,
  value: PropTypes.object,
};

export default Banner;
