import { ProductUnit, ShippingWeightUnit } from "@arowana/graphql";

const UNIT_LABELS: { [key in ProductUnit | ShippingWeightUnit]: string } = {
  BAG: "bag",
  BK: "bk",
  BLOCK: "block",
  BOTTLE: "bottle",
  BOX: "box",
  BUNCH: "bunch",
  BUSHEL: "bushel",
  CARTON: "ctn",
  CASE: "case",
  COUNT: "cnt",
  DOZEN: "dz",
  EACH: "ea",
  GALLON: "gal",
  GRAM: "gram",
  HALFBUSHEL: "half bushel",
  HALFPINT: "half pint",
  HALFPOUND: "half pound",
  JAR: "jar",
  KILOGRAM: "kg",
  LITRE: "liter",
  ORDER: "order",
  OUNCE: "oz",
  PACK: "pack",
  PECK: "peck",
  PIECE: "pc",
  PINT: "pint",
  PORTION: "portion",
  POUND: "lb",
  QUART: "quart",
  QUARTERPOUND: "quarter pound",
  TIN: "tin",
  TR: "tr",
  UNIT: "unit",
};

const PLURAL_UNIT_LABELS: { [key in ProductUnit | ShippingWeightUnit]: string } = {
  BAG: "bags",
  BK: "bk",
  BLOCK: "blocks",
  BOTTLE: "bottles",
  BOX: "boxes",
  BUNCH: "bunches",
  BUSHEL: "bushels",
  CARTON: "ctns",
  CASE: "cases",
  COUNT: "cnts",
  DOZEN: "dzs",
  EACH: "ea",
  GALLON: "gals",
  GRAM: "grams",
  HALFBUSHEL: "half bushels",
  HALFPINT: "half pints",
  HALFPOUND: "half pounds",
  JAR: "jars",
  KILOGRAM: "kgs",
  LITRE: "liters",
  ORDER: "orders",
  OUNCE: "ozs",
  PACK: "packs",
  PECK: "peckd",
  PIECE: "pcs",
  PINT: "pints",
  PORTION: "portions",
  POUND: "lbs",
  QUART: "quarts",
  QUARTERPOUND: "quarter pounds",
  TIN: "tins",
  TR: "tr",
  UNIT: "units",
};

// Returns true if the given unit is something like "each" or "count".
export function isCountUnit(unit: ProductUnit | ShippingWeightUnit) {
  switch (unit) {
    case ProductUnit.COUNT:
      return true;

    case ProductUnit.EACH:
      return true;

    case ProductUnit.UNIT:
      return true;

    default:
      return false;
  }
}

export function unitLabel(unit: ProductUnit | ShippingWeightUnit = null, count: number = null): string {
  if (!unit) {
    return null;
  }

  const mappedUnit = UNIT_LABELS[unit] || unit;
  const mappedPluralUnit = PLURAL_UNIT_LABELS[unit] || unit;

  if (typeof count !== "number") {
    return mappedUnit;
  } else if (Number.isInteger(count)) {
    return `${count} ${count == 1 ? mappedUnit : mappedPluralUnit}`;
  } else {
    const roundedCount = count.toFixed(2);

    return `${roundedCount} ${roundedCount == "1.00" ? mappedUnit : mappedPluralUnit}`;
  }
}
