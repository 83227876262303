import { Redirect, Route, Switch } from "react-router-dom";

import Routes from "../../Constants/Routes";
import AvailabilitiesDetails from "../availabilities/AvailabilitiesDetails";
import AvailabilitiesList from "../availabilities/AvailabilitiesList";
import Communication from "./pages/Communication";
import Design from "./pages/Design";
import General from "./pages/General";
import ZoneDetail from "./pages/ZoneDetail";
import Zones from "./pages/Zones";

const Store = () => (
  <Switch>
    <Route component={AvailabilitiesList} exact path={Routes.STORE_AVAILABILITIES} />
    <Route component={AvailabilitiesDetails} exact path={Routes.STORE_AVAILABILITIES_CREATE} />
    <Route component={AvailabilitiesDetails} exact path={Routes.STORE_AVAILABILITIES_DETAILS} />
    <Route component={General} exact path={Routes.STORE_GENERAL_SETTINGS} />
    <Route component={ZoneDetail} exact path={Routes.STORE_LOCATIONS_CREATE} />
    <Route component={ZoneDetail} exact path={Routes.STORE_LOCATIONS_DETAIL} />
    <Route component={Zones} exact path={Routes.STORE_LOCATIONS} />
    <Route component={Design} exact path={Routes.STORE_APPEARANCE} />
    <Route component={Communication} exact path={Routes.STORE_COMMUNICATION} />
    {/* Legacy redirects */}
    <Redirect from="/account/zones/create-zone" to={Routes.STORE_LOCATIONS_CREATE} />
    <Redirect from="/account/general" to={Routes.STORE_GENERAL_SETTINGS} />
    <Redirect from="/account/zones" to={Routes.STORE_LOCATIONS} />
    <Redirect from="/account/ui-design" to={Routes.STORE_APPEARANCE} />
    <Redirect from="/account/zones/:id" to={Routes.STORE_LOCATIONS_DETAIL} />
    <Redirect from="/account/payment" to={Routes.PAYMENTS} />
    <Redirect from="/account/*" to={Routes.STORE_GENERAL_SETTINGS} />
    <Redirect from="*" to={Routes.STORE_GENERAL_SETTINGS} />
  </Switch>
);

export default Store;
