import { FulfillmentMethod } from "@arowana/graphql";
import { Chip, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { startCase, toLower } from "lodash";

const useStyles = makeStyles(theme => ({
  delivery: {
    borderColor: theme.palette.delivery.main,
    color: theme.palette.delivery.main,
  },
  pickup: {
    borderColor: theme.palette.pickup.main,
    color: theme.palette.pickup.main,
  },
  root: {
    background: theme.palette.background.paper,
    fontWeight: "normal",
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
  shipping: {
    borderColor: theme.palette.shipping.main,
    color: theme.palette.shipping.main,
  },
}));

const FulfillmentChip = ({ fulfillmentType }: { fulfillmentType: FulfillmentMethod }) => {
  const classes = useStyles();

  if (!fulfillmentType) {
    return null;
  }

  return (
    <Chip
      className={clsx(classes[toLower(fulfillmentType)], classes.root)}
      label={startCase(toLower(fulfillmentType))}
      size="small"
      variant="outlined"
    />
  );
};

export default FulfillmentChip;
