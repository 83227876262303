import { useCombinedRefs } from "@arowana/ui";
import { Chip, makeStyles, TextField } from "@material-ui/core";
import EmailValidator from "email-validator";
import PropTypes from "prop-types";
import React, { useRef, useState } from "react";

const useStyles = makeStyles(theme => ({
  chipDelete: {
    "&:hover": {
      color: theme.palette.grey[400],
    },
    "color": theme.palette.grey[300],
  },
  chips: {
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
    "margin": theme.spacing(1, 0.5),
  },
  input: {
    flex: "1 0 200px",
  },
  inputRoot: {
    flexWrap: "wrap",
  },
}));
const hasValue = (items, target) => items.map(item => item.toLowerCase()).includes(target.toLowerCase());

const MultiChips = React.forwardRef(({ value, margin, onChange, size, type, helperText, error, fullWidth }, ref) => {
  const items = value.slice();
  const classes = useStyles();
  const fieldRef = useRef(null);
  const combinedRef = useCombinedRefs(ref, fieldRef);
  const [inputError, setInputError] = useState("");
  const validateItem = event => {
    const item = event.target.value;

    if (item === "") return;

    if (hasValue(items, item)) {
      setInputError(`"${item}" already exists!`);

      return;
    }

    const isValid = type === "email" ? EmailValidator.validate(item) : true;

    if (isValid) {
      items.push(item);
      onChange(items);
      fieldRef.current.value = "";
    } else {
      setInputError(`"${item}" is not a valid email!`);
    }
  };
  const handleDeleteItem = item => {
    const index = items.indexOf(item);

    if (index > -1) {
      items.splice(index, 1);
      onChange(items);
    }
  };
  const handleKeyUpEvent = event => {
    if (inputError) {
      setInputError("");
    }

    const { key } = event;

    if (key === "Enter") {
      validateItem(event);
    }
  };

  return (
    <TextField
      InputProps={{
        classes: {
          input: classes.input,
          root: classes.inputRoot,
        },
        startAdornment: items.map(item => (
          <Chip
            classes={{
              deleteIcon: classes.chipDelete,
              root: classes.chips,
            }}
            color="primary"
            key={item}
            label={item}
            onDelete={() => handleDeleteItem(item)}
            variant="default"
          />
        )),
      }}
      error={Boolean(inputError) || error}
      fullWidth={fullWidth}
      helperText={inputError || helperText}
      inputRef={combinedRef}
      margin={margin}
      onBlur={validateItem}
      onKeyUp={handleKeyUpEvent}
      size={size}
      type={type}
      variant="outlined"
    />
  );
});

MultiChips.defaultProps = {
  error: false,
  helperText: undefined,
  margin: "dense",
  onChange: undefined,
  size: "medium",
  type: undefined,
  value: [],
};

MultiChips.propTypes = {
  error: PropTypes.bool,
  helperText: PropTypes.string,
  margin: PropTypes.string,
  onChange: PropTypes.func,
  size: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.arrayOf(PropTypes.string),
};

export default MultiChips;
