import { useMutation, useQuery } from "@apollo/client";
import { Button, Divider, makeStyles, Typography } from "@material-ui/core";
import moment from "moment-timezone";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { notificationVar } from "../../../cache/notificationPolicy";
import ConfirmationModal from "../../../components/ConfirmationModal";
import FormCard from "../../../components/FormCard";
import Loader from "../../../components/Loader";
import PageHeader from "../../../components/PageHeader";
import TipBanner from "../../../components/TipBanner";
import Routes from "../../../Constants/Routes";
import appShippingConnectFedex from "../../../queries/appShippingConnectFedex";
import appShippingConnectPurolator from "../../../queries/appShippingConnectPurolator";
import appShippingConnectUps from "../../../queries/appShippingConnectUps";
import { supplierIntegrations } from "../../../queries/supplier";
import amountUtility from "../../../utils/amountUtility";
import { AccountContext } from "../../context/AccountContext";
import { FlagsmithContext } from "../../context/FlagsmithContext";
import CarrierForm from "../components/CarrierForm";
import CarrierSelect from "../components/CarrierSelect";

const displayName = {
  fedex: "FedEx",
  purolator: "Purolator",
  ups: "UPS",
};
const commonFields = {
  accountNumber: "",
  address: {
    addressLine: "",
    city: "",
    country: "",
    countryCode: "",
    lnglat: {
      lat: "",
      lng: "",
    },
    postalCode: "",
    province: "",
    provinceCode: "",
    unitNumber: "",
  },
  company: "",
  email: "",
  firstName: "",
  lastName: "",
  phone: "",
};
const formFields = {
  fedex: {
    ...commonFields,
    agreeToEula: false,
    meterNumber: "",
  },
  purolator: {
    accountNumber: "",
    activationKey: "",
  },
  ups: {
    ...commonFields,
    agreeToTechnologyAgreement: false,
    controlId: "",
    enableNegotiatedRates: false,
    invoiceAmount: "",
    invoiceDate: "",
    invoiceNumber: "",
  },
};
const useStyles = makeStyles(theme => ({
  appCard: {
    padding: theme.spacing(2),
  },
}));

const redirectToShippingRatePage = (data, carrierName, history) => {
  notificationVar({
    message: `${carrierName} installed! Please select a service and save.`,
    severity: "success",
  });
  history.push({
    pathname: `${Routes.APPS}/shipping/${data.id}`,
    state: {
      carrierName,
      defaultServiceCode: data.defaultServiceCode,
    },
  });
};

const stripSpace = str => str.replace(/\s+/g, "");
const getFedExInfo = data => {
  return {
    input: {
      accountNumber: data.accountNumber,
      address1: data.address.addressLine,
      ...(data.address.unitNumber && { address2: data.address.unitNumber }),
      agreeToEula: data.agreeToEula,
      city: data.address.city,
      company: data.company,
      countryCode: data.address.countryCode,
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      meterNumber: data.meterNumber,
      phone: data.phone,
      postalCode: stripSpace(data.address.postalCode),
      state: data.address.provinceCode,
    },
  };
};

const getUPSInfo = (data, timezone) => {
  return {
    input: {
      accountCountryCode: data.address.countryCode,
      accountNumber: data.accountNumber,
      accountPostalCode: stripSpace(data.address.postalCode),
      address1: data.address.addressLine,
      ...(data.address.unitNumber && { address2: data.address.unitNumber }),
      agreeToTechnologyAgreement: data.agreeToTechnologyAgreement,
      city: data.address.city,
      company: data.company,
      countryCode: data.address.countryCode,
      email: data.email,
      firstName: data.firstName,
      ...(data.enableNegotiatedRates && {
        invoice: {
          controlId: data.controlId,
          invoiceAmount: amountUtility.dollarsAndCentsToAmount(data.invoiceAmount),
          invoiceDate: moment.tz(data.invoiceDate, timezone).utc().toISOString(),
          invoiceNumber: data.invoiceNumber,
        },
      }),
      lastName: data.lastName,
      phone: data.phone,
      postalCode: stripSpace(data.address.postalCode),
      state: data.address.provinceCode,
    },
  };
};

const Shipping = ({ history }) => {
  const classes = useStyles();
  const [carrier, setCarrier] = useState("fedex");
  const [openModal, setOpenModal] = useState(false);
  const { supplier } = useContext(AccountContext);
  const flagsmith = useContext(FlagsmithContext);
  const hasRetail = flagsmith.hasFeature("b2c");
  const { timezone } = supplier;
  const { data, loading: isFetching } = useQuery(supplierIntegrations);
  const [addFedEx, { loading: isAddingFedEx }] = useMutation(appShippingConnectFedex, {
    onCompleted: data => redirectToShippingRatePage(data.appShippingConnectFedex, displayName[carrier], history),
    refetchQueries: ["GetSupplierIntegrations"],
  });
  const [addPurolator, { loading: isAddingPurolator }] = useMutation(appShippingConnectPurolator, {
    onCompleted: data => redirectToShippingRatePage(data.appShippingConnectPurolator, displayName[carrier], history),
    refetchQueries: ["GetSupplierIntegrations"],
  });
  const [addUPS, { loading: isAddingUPS }] = useMutation(appShippingConnectUps, {
    onCompleted: data => redirectToShippingRatePage(data.appShippingConnectUps, displayName[carrier], history),
    refetchQueries: ["GetSupplierIntegrations"],
  });

  useEffect(() => {
    if (!isFetching && data) {
      setCarrier(data.getSupplierAccount?.integrations?.shipengine?.carriers?.[0]?.name ?? "fedex");
    }
  }, [isFetching, data]);
  const {
    clearErrors,
    control,
    errors,
    formState,
    getValues,
    handleSubmit,
    register,
    reset,
    setError,
    setValue,
    trigger,
    watch,
  } = useForm({
    defaultValues: formFields[carrier],
  });
  useEffect(() => reset(formFields[carrier]), [carrier, reset]);
  const handleDiscardClick = () => reset();
  const handleOpenModal = async () => {
    const fieldsLegal = await trigger();

    if (carrier === "ups") {
      const { controlId, enableNegotiatedRates, invoiceAmount, invoiceDate, invoiceNumber } = getValues([
        "controlId",
        "enableNegotiatedRates",
        "invoiceAmount",
        "invoiceDate",
        "invoiceNumber",
      ]);

      if (enableNegotiatedRates && (!invoiceNumber || !invoiceAmount || !invoiceDate || !controlId)) {
        if (!invoiceNumber) {
          setError("invoiceNumber", {
            message: "*required",
            type: "manual",
          });
        }

        if (!invoiceAmount) {
          setError("invoiceAmount", {
            message: "*required",
            type: "manual",
          });
        }

        if (!invoiceDate) {
          setError("invoiceDate", {
            message: "*required",
            type: "manual",
          });
        }

        if (!controlId) {
          setError("controlId", {
            message: "*required",
            type: "manual",
          });
        }

        return;
      }
    }

    if (fieldsLegal) setOpenModal(true);
  };
  const handleCloseModal = () => setOpenModal(false);
  const handleChangeCarrier = e => setCarrier(e.target.value);
  const onSubmit = data => {
    // required by the shipengine api:
    // postalCode must NOT contain any space
    // countryCode.length must be 2
    // state.length must be 2
    if (carrier === "fedex") {
      const variables = getFedExInfo(data);
      addFedEx({ variables });
    } else if (carrier === "ups") {
      const variables = getUPSInfo(data, timezone);
      addUPS({ variables });
    } else if (carrier === "purolator") {
      const variables = {
        input: {
          accountNumber: data.accountNumber,
          activationKey: data.activationKey,
        },
      };
      addPurolator({ variables });
    }

    handleCloseModal();
  };

  return (
    <>
      {(isFetching || isAddingFedEx || isAddingPurolator || isAddingUPS) && <Loader />}
      <PageHeader
        homePage={Routes.APPS}
        primaryActions={
          <>
            <Button color="primary" disabled={!formState?.isDirty} onClick={handleDiscardClick} variant="outlined">
              Discard
            </Button>
            <Button color="primary" disabled={!formState?.isDirty} onClick={handleOpenModal} variant="contained">
              Install
            </Button>
          </>
        }
        stickyHeader
        title="Freshline Shipping App"
      />
      {!hasRetail && (
        <TipBanner
          headerText="This application is only for your retail online store."
          bodyText="Shipping estimates by weight and distance are only supported for retail sales, not wholesale."
        />
      )}
      <FormCard className={classes.appCard}>
        <CarrierSelect onChange={handleChangeCarrier} value={carrier} />
      </FormCard>
      <FormCard className={classes.appCard}>
        <Typography variant="h6">{displayName[carrier]} Account Information</Typography>
        <Divider />
        <CarrierForm
          carrier={carrier}
          clearErrors={clearErrors}
          control={control}
          errors={errors}
          register={register}
          setValue={setValue}
          watch={watch}
        />
      </FormCard>

      <ConfirmationModal
        cancelRequestButtonText="Cancel"
        confirmRequestButtonText="Install"
        modalContent={`Are you sure you want to submit your ${displayName[carrier]} information?`}
        modalNote={`Note: In order to edit this information, you will have to reinstall ${displayName[carrier]}`}
        modalTitle="Submit account information"
        onCloseModal={handleCloseModal}
        onConfirmClick={handleSubmit(onSubmit)}
        shouldOpenModal={openModal}
      />
    </>
  );
};

export default Shipping;
