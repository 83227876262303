import { Button, FormHelperText, Grid, IconButton, makeStyles, MenuItem, Select } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";

const HOUR_OPTIONS = [...Array(12).keys()].map(i => ({ label: i + 1, value: i + 1 }));

const useStyles = makeStyles(theme => ({
  dropdown: {
    "&:not(:last-child)": { marginRight: theme.spacing(1) },
    "width": theme.spacing(9),
  },
  text: {
    marginRight: theme.spacing(1),
  },
}));

interface FulfillmentTime {
  endClock: string;
  endHour: number | string;
  startClock: string;
  startHour: number | string;
}

interface FulfillmentTimesProps {
  value?: FulfillmentTime[];
  onChange?: (val: FulfillmentTime[]) => void;
  helperText?: string;
}

const FulfillmentTimes = ({ value, onChange, helperText }: FulfillmentTimesProps) => {
  const classes = useStyles();

  const handleAddWindow = () => {
    onChange([...value, { endClock: "", endHour: "", startClock: "", startHour: "" }]);
  };
  const handleChangeWindow = (event, index, key) => {
    onChange([...value.slice(0, index), { ...value[index], [key]: event.target.value }, ...value.slice(index + 1)]);
  };
  const handleDeleteWindow = index => {
    onChange([...value.slice(0, index), ...value.slice(index + 1)]);
  };

  return (
    <Grid container direction="column" spacing={2}>
      {helperText && (
        <Grid item>
          <FormHelperText error>{helperText}</FormHelperText>
        </Grid>
      )}
      {value.map(({ startHour, startClock, endHour, endClock }, index) => (
        <Grid item key={`fulfillment-time-window-${index}`}>
          <Select
            className={classes.dropdown}
            onChange={event => handleChangeWindow(event, index, "startHour")}
            value={startHour}
            variant="outlined"
          >
            {HOUR_OPTIONS.map(({ label, value }) => (
              <MenuItem key={label} value={value}>
                {label}
              </MenuItem>
            ))}
          </Select>
          <Select
            className={classes.dropdown}
            onChange={event => handleChangeWindow(event, index, "startClock")}
            value={startClock}
            variant="outlined"
          >
            <MenuItem value="AM">AM</MenuItem>
            <MenuItem value="PM">PM</MenuItem>
          </Select>{" "}
          <span className={classes.text}>to</span>
          <Select
            className={classes.dropdown}
            onChange={event => handleChangeWindow(event, index, "endHour")}
            value={endHour}
            variant="outlined"
          >
            {HOUR_OPTIONS.map(({ label, value }) => (
              <MenuItem key={label} value={value}>
                {label}
              </MenuItem>
            ))}
          </Select>
          <Select
            className={classes.dropdown}
            onChange={event => handleChangeWindow(event, index, "endClock")}
            value={endClock}
            variant="outlined"
          >
            <MenuItem value="AM">AM</MenuItem>
            <MenuItem value="PM">PM</MenuItem>
          </Select>{" "}
          <IconButton onClick={() => handleDeleteWindow(index)}>
            <DeleteIcon />
          </IconButton>
        </Grid>
      ))}
      <Grid item>
        <Button color="primary" variant="contained" onClick={handleAddWindow} size="large">
          Add a Window
        </Button>
      </Grid>
    </Grid>
  );
};

export default FulfillmentTimes;
