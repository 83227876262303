import { gql, useMutation } from "@apollo/client";
import { GiftcardCreateInput } from "@arowana/graphql";
import { FormCard } from "@arowana/ui";
import { DATALAYER } from "@arowana/util";
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import { notificationVar } from "apps/supplier/src/cache/notificationPolicy";
import PageHeader from "apps/supplier/src/components/PageHeader";
import Routes from "apps/supplier/src/Constants/Routes";
import { useContext } from "react";
import { Controller, useForm } from "react-hook-form";
import { RouteComponentProps } from "react-router-dom";

import { AccountContext } from "../../context/AccountContext";

const GIFTCARD_CREATE = gql`
  mutation GiftcardCreate($input: GiftcardCreateInput!) {
    giftcardCreate(input: $input) {
      id
    }
  }
`;

type FormValues = Omit<GiftcardCreateInput, "paymentMethodId">;

const GiftcardCreate: React.FC<RouteComponentProps> = ({ history }) => {
  const {
    supplier: { name, supportEmail },
  } = useContext(AccountContext);

  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    reset,
  } = useForm<FormValues>({
    defaultValues: {
      amount: 0,
      email: "",
      message: "",
      name: "",
      sendEmail: true,
      senderEmail: supportEmail,
      senderName: name,
    },
  });

  const [createGiftcard, { loading }] = useMutation(GIFTCARD_CREATE, {
    context: { source: DATALAYER },
    onCompleted: ({ giftcardCreate: { id } }) => {
      history.replace(Routes.GIFTCARD_DETAILS.replace(":id", id));
      notificationVar({
        message: "Giftcard created!",
        severity: "success",
      });
    },
    onError: () => {},
  });
  const handleDiscardClick = () => reset();
  const onSubmit = async (data: FormValues) => {
    createGiftcard({
      variables: {
        input: {
          ...data,
          amount: parseInt(String(data.amount * 100)),
        },
      },
    });
  };

  return (
    <section>
      <PageHeader
        homePage={Routes.GIFTCARDS}
        primaryActions={
          <>
            <Button color="primary" disabled={loading} onClick={handleDiscardClick} variant="outlined">
              Discard
            </Button>
            <Button color="primary" disabled={loading} onClick={handleSubmit(onSubmit)} variant="contained">
              {loading ? <CircularProgress color="inherit" size={24} /> : "Create"}
            </Button>
          </>
        }
        stickyHeader
        title="New Giftcard"
      />
      <FormCard>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth margin="normal" required>
              <FormLabel htmlFor="name">Recipient Name</FormLabel>
              <TextField
                autoComplete="off"
                error={Boolean(errors?.name)}
                fullWidth
                helperText={errors?.name?.message}
                id="name"
                inputRef={register({ required: "*required" })}
                margin="dense"
                name="name"
                placeholder="First Last"
                variant="outlined"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth margin="normal" required>
              <FormLabel htmlFor="email">Recipient Email</FormLabel>
              <TextField
                autoComplete="off"
                error={Boolean(errors?.email)}
                fullWidth
                helperText={errors?.email?.message}
                id="email"
                inputRef={register({ required: "*required" })}
                margin="dense"
                name="email"
                placeholder="email@example.org"
                variant="outlined"
                type="email"
              />
            </FormControl>
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth margin="normal">
              <FormLabel htmlFor="senderName">Sender Name</FormLabel>
              <TextField
                autoComplete="off"
                error={Boolean(errors?.senderName)}
                fullWidth
                helperText={errors?.senderName?.message}
                id="senderName"
                inputRef={register()}
                margin="dense"
                name="senderName"
                placeholder="First Last"
                variant="outlined"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth margin="normal">
              <FormLabel htmlFor="email">Sender Email</FormLabel>
              <TextField
                autoComplete="off"
                error={Boolean(errors?.senderEmail)}
                fullWidth
                helperText={errors?.senderEmail?.message}
                id="senderEmail"
                inputRef={register({ required: "*required" })}
                margin="dense"
                name="senderEmail"
                placeholder="email@example.org"
                variant="outlined"
                type="email"
              />
            </FormControl>
          </Grid>
        </Grid>

        <FormControl fullWidth margin="normal" required>
          <FormLabel htmlFor="amount">Amount</FormLabel>
          <TextField
            autoComplete="off"
            error={Boolean(errors?.amount)}
            fullWidth
            helperText={errors?.amount?.message}
            id="amount"
            inputRef={register({ required: "*required", valueAsNumber: true })}
            margin="dense"
            name="amount"
            placeholder="Dollar amount"
            variant="outlined"
            type="number"
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
          />
        </FormControl>

        <FormControl fullWidth margin="normal">
          <FormLabel htmlFor="message">Message</FormLabel>
          <TextField
            aria-describedby="message-helper"
            autoComplete="off"
            error={Boolean(errors?.message)}
            fullWidth
            helperText={errors?.message?.message}
            id="message"
            inputRef={register({})}
            margin="dense"
            multiline
            name="message"
            rows={2}
            variant="outlined"
            placeholder="Enter your message here"
          />
        </FormControl>

        <FormGroup row>
          <Controller
            control={control}
            name="sendEmail"
            render={({ onBlur, onChange, name, ref, value }) => (
              <FormControlLabel
                control={
                  <Checkbox
                    name={name}
                    ref={ref}
                    color="primary"
                    checked={value}
                    onBlur={onBlur}
                    onChange={e => onChange(e.target.checked)}
                  />
                }
                label="Send email to recipient"
              />
            )}
          />
        </FormGroup>
      </FormCard>
    </section>
  );
};

export default GiftcardCreate;
