import { FulfillmentMethod } from "@arowana/graphql";
import { Box, Grid, Link, makeStyles, TextField, Typography } from "@material-ui/core";
import { useContext, useMemo } from "react";
import { Controller, UseFormMethods, useWatch } from "react-hook-form";
import { Link as RouterLink } from "react-router-dom";

import FormCard from "../../../components/FormCard";
import RichTextFormComponent from "../../../components/RichTextFormComponent";
import Routes from "../../../Constants/Routes";
import { AccountContext } from "../../context/AccountContext";
import RadioSelector from "./RadioSelector";
import SwitchToggle from "./SwitchToggle";

const DEFAULT_FULFILLMENT_METHOD_OPTIONS = [
  {
    label: (
      <>
        <Typography variant="body2">Pick up</Typography>
        <Typography color="textSecondary" variant="caption">
          Select for local pick up for your store. Create as many pick up locations as you'd like.
        </Typography>
      </>
    ),
    value: FulfillmentMethod.pickup,
  },
  {
    label: (
      <>
        <Typography variant="body2">Delivery</Typography>
        <Typography color="textSecondary" variant="caption">
          Select for home delivery. Create specific zones to help organize your operations.
        </Typography>
      </>
    ),
    value: FulfillmentMethod.delivery,
  },
];

const SHIPPING_OPTION = {
  label: (
    <>
      <Typography variant="body2">Shipping</Typography>
      <Typography color="textSecondary" variant="caption">
        Select to expand your market. Create zones that reach more customers. The shipping rate will be set
        automatically by a third party carrier and will be shipped through those providers.
      </Typography>
    </>
  ),
  value: FulfillmentMethod.shipping,
};

const useStyles = makeStyles(() => ({
  locationToggler: {
    marginLeft: 0,
  },
}));

interface LocationSummaryProps {
  control: UseFormMethods["control"];
  errors: UseFormMethods["errors"];
  register: UseFormMethods["register"];
}

const LocationSummary = ({ control, errors, register }: LocationSummaryProps) => {
  const classes = useStyles();
  const { supplier } = useContext(AccountContext);
  const shippingEnabled = supplier?.shippingEnabled;

  const fulfillmentMethod = useWatch({
    control,
    defaultValue: FulfillmentMethod.delivery,
    name: "fulfillmentMethod",
  });

  const isPickup = fulfillmentMethod === FulfillmentMethod.pickup;

  const fulfillmentMethodOptions = useMemo(() => {
    if (shippingEnabled) {
      return [...DEFAULT_FULFILLMENT_METHOD_OPTIONS, SHIPPING_OPTION];
    }

    return [
      ...DEFAULT_FULFILLMENT_METHOD_OPTIONS,
      {
        disabled: true,
        ...SHIPPING_OPTION,
      },
    ];
  }, [shippingEnabled]);

  return (
    <FormCard>
      <Grid alignItems="center" container spacing={2}>
        <Grid item md={8} xs={12}>
          <Typography gutterBottom variant="subtitle1">
            Service location name
          </Typography>
          <TextField
            error={Boolean(errors?.name)}
            fullWidth
            helperText={errors?.name?.message}
            inputRef={register({ required: "*required" })}
            name="name"
            variant="outlined"
            size="small"
          />
          <Typography color="textSecondary" variant="caption">
            This name will be displayed to customers on your shop.
          </Typography>
        </Grid>
        <Grid item md={4} xs={12}>
          <Controller
            as={<SwitchToggle />}
            className={classes.locationToggler}
            control={control}
            label="Enable/disable location"
            name="active"
          />
          <Typography color="textSecondary" component="p" variant="caption">
            Turning this off will make this location unavailable to customers.
          </Typography>
        </Grid>
      </Grid>
      <Box my={2}>
        <Typography gutterBottom variant="subtitle1">
          Fulfillment method
        </Typography>
        <Controller
          as={<RadioSelector />}
          control={control}
          name="fulfillmentMethod"
          options={fulfillmentMethodOptions}
        />
      </Box>
      <Box my={2}>
        <Typography color="textSecondary" variant="caption">
          NOTE: To choose carrier shipping via FedEx, UPS, or Purolator, you must first enable
          <Link component={RouterLink} to={`${Routes.APPS}/shipping`}>
            {" "}
            Freshline Shipping
          </Link>
          .
        </Typography>
      </Box>
      <Box>
        <Typography gutterBottom variant="subtitle1">
          {isPickup ? "Pickup" : "Delivery"} instructions
        </Typography>
        <RichTextFormComponent control={control} name="deliveryInstructions" />
        <Typography color="textSecondary" variant="caption">
          Additional {isPickup ? "pickup" : "delivery"} information displayed to customers. For example, hours of
          operation.
        </Typography>
      </Box>
    </FormCard>
  );
};

export default LocationSummary;
