import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  FormHelperText,
  Grid,
  makeStyles,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import moment from "moment";
import React from "react";

const useStyles = makeStyles(theme => ({
  dayChip: {
    width: 100,
  },
  dayDropdown: {
    padding: theme.spacing(1),
    width: 90,
  },
  deliveryRow: {
    marginTop: theme.spacing(2),
  },
}));

export type FulfillmentDaysType = {
  checked: boolean;
  fulfilledOn: number;
  orderBy: number;
  orderByOptions: number[];
};

type FulfillmentDaysProps = {
  helperText?: string;
  onChange?: (value: FulfillmentDaysType[]) => void;
  value?: FulfillmentDaysType[];
};

const FulfillmentDays = React.forwardRef(({ helperText, onChange, value }: FulfillmentDaysProps, ref) => {
  const classes = useStyles();
  const rows = value.slice();
  const handleClickDeliveryOn = index => {
    rows[index] = { ...rows[index], checked: !rows[index].checked };
    onChange(rows);
  };
  const handleClickOrderBy = (e, index) => {
    rows[index] = { ...rows[index], orderBy: e.target.value };
    onChange(rows);
  };
  const handleToggleEveryday = e => onChange(rows.map(row => ({ ...row, checked: e.target.checked })));

  return (
    <>
      <Typography>
        Create a pre-order or same-day fulfillment schedule by customizing the "Order by" and "Fulfilled on" dates.
      </Typography>
      {helperText && <FormHelperText error>{helperText}</FormHelperText>}

      <FormControlLabel
        control={<Checkbox color="primary" />}
        label="Enable everyday fulfillment"
        labelPlacement="end"
        onChange={handleToggleEveryday}
        checked={rows.every(row => row.checked)}
      />

      <Box my={2}>
        <Divider />
      </Box>

      <Grid container>
        <Grid item xs={6}>
          <Typography>Fulfilled on</Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography>Order by</Typography>
        </Grid>

        {rows.map((row, index) => (
          <Grid className={classes.deliveryRow} container item xs={12} key={`${index}-fulfillment-day`}>
            <Grid item xs={6}>
              <FormControlLabel
                control={<Checkbox color="primary" />}
                label={moment().isoWeekday(row.fulfilledOn).format("dddd")}
                labelPlacement="end"
                onChange={() => handleClickDeliveryOn(index)}
                checked={row.checked}
              />
            </Grid>
            <Grid item xs={6}>
              <Select
                classes={{ select: classes.dayDropdown }}
                disabled={!row.checked}
                onChange={e => handleClickOrderBy(e, index)}
                value={row.orderBy}
                variant="outlined"
                ref={ref}
              >
                {row.orderByOptions.map(option => (
                  <MenuItem key={`${option}-orderBy`} value={option}>
                    <Typography>{moment().isoWeekday(option).format("dddd")}</Typography>
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </>
  );
});

export default FulfillmentDays;
