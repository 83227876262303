import { ShippingWeightUnit } from "@arowana/graphql";
import { OnWheelBlur } from "@arowana/ui";
import { unitLabel } from "@arowana/util";
import {
  Checkbox,
  FormHelperText,
  FormLabel,
  Grid,
  InputAdornment,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import { Controller, useWatch } from "react-hook-form";

import DropdownSelect from "../../../../components/DropdownSelect";
import FormCard from "../../../../components/FormCard";
import FormTooltip from "../../../../components/FormTooltip";
import SwitchToggle from "../../../account/components/SwitchToggle";
import { VARIANT_FIELDS } from "../../pages/ProductDetails";

const useStyles = makeStyles(theme => ({
  checkbox: {
    marginLeft: theme.spacing(-1.5),
    marginRight: 0,
  },
}));

export const SHIPPING_WEIGHT_UNITS = Object.values(ShippingWeightUnit).map(unit => ({
  label: unitLabel(unit),
  value: unit,
}));

const InventoryShipping = ({ control, errors, register, showFirstOption }) => {
  const classes = useStyles();

  const defaultVariant = useWatch({
    control,
    defaultValue: VARIANT_FIELDS,
    name: "variant",
  });

  return (
    <FormCard title={`Inventory${showFirstOption ? " & shipping" : ""}`}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Controller
            as={<SwitchToggle />}
            className={classes.checkbox}
            component={<Checkbox color="primary" />}
            control={control}
            label={
              <>
                Show inventory{" "}
                <FormTooltip content='Enable this option to display the on-hand inventory (e.g. "180lb remaining") for each product on your online store.' />
              </>
            }
            labelPlacement="end"
            name="showInventory"
          />
        </Grid>

        <Grid item xs={12}>
          <Controller
            as={<SwitchToggle />}
            className={classes.checkbox}
            component={<Checkbox color="primary" />}
            control={control}
            label={
              <>
                Track inventory{" "}
                <FormTooltip content="Enable this option to automatically deduct and track inventory as orders are placed. Disable to allow for uncapped ordering of this product." />
              </>
            }
            labelPlacement="end"
            name="trackInventory"
          />
        </Grid>

        {showFirstOption && (
          <>
            <Grid item xs={12}>
              <FormLabel htmlFor="variant-quantity">
                Total inventory{" "}
                <FormTooltip content="Enter the total weight of your inventory. If you have 10 × salmon fillets and your case size is 2, you would enter 20. Required if track inventory is enabled." />
              </FormLabel>
              <TextField
                InputProps={{
                  endAdornment: defaultVariant.unit && (
                    <InputAdornment position="end">
                      <Typography color="textSecondary">{unitLabel(defaultVariant.unit)}</Typography>
                    </InputAdornment>
                  ),
                  inputProps: {
                    min: 0,
                    step: 0.01,
                  },
                  onWheel: OnWheelBlur,
                }}
                aria-describedby="variant-quantity-helper"
                defaultValue={defaultVariant.quantity}
                error={Boolean(errors?.variant?.quantity)}
                fullWidth
                helperText={errors?.variant?.quantity?.message}
                margin="dense"
                size="small"
                inputRef={register({
                  min: {
                    message: "invalid value",
                    value: 0,
                  },
                })}
                name="variant.quantity"
                type="number"
                variant="outlined"
                id="variant-quantity"
              />
            </Grid>

            <Grid item xs={12}>
              <FormLabel htmlFor="variant-sku">SKU</FormLabel>
              <TextField
                aria-describedby="variant-sku-helper"
                defaultValue={defaultVariant.sku}
                error={Boolean(errors?.variant?.sku)}
                fullWidth
                helperText={errors?.variant?.sku?.message}
                inputRef={register()}
                margin="dense"
                name="variant.sku"
                variant="outlined"
                id="variant-sku"
                size="small"
              />
              <FormHelperText id="variant-sku-helper">Stock Keeping Unit or Inventory Code.</FormHelperText>
            </Grid>

            <Grid item xs={12}>
              <Controller
                aria-describedby="variant-outofstock-helper"
                id="variant-outofstock"
                as={<SwitchToggle />}
                className={classes.checkbox}
                component={<Checkbox color="primary" />}
                control={control}
                defaultValue={defaultVariant.outOfStock}
                label={
                  <>
                    Out of stock{" "}
                    <FormTooltip content="Out of stock items can be viewed but won't be available for purchase." />
                  </>
                }
                labelPlacement="end"
                name="variant.outOfStock"
              />
            </Grid>

            <Grid item xs={12}>
              <FormLabel htmlFor="variant-shippingweight" required>
                Shipping weight{" "}
                <FormTooltip
                  content="Only used for third-party shipping estimates. This weight will be used to calculate shipping rates at
                checkout for your carrier shipping locations."
                />
              </FormLabel>
              <TextField
                defaultValue={defaultVariant.shippingWeight}
                id="variant-shippingweight"
                size="small"
                margin="dense"
                error={Boolean(errors?.variant?.shippingWeight) || Boolean(errors?.variant?.shippingWeightUnit)}
                fullWidth
                helperText={errors?.variant?.shippingWeight?.message ?? errors?.variant?.shippingWeightUnit?.message}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Controller
                        as={<DropdownSelect />}
                        control={control}
                        variant="standard"
                        defaultValue={defaultVariant.shippingWeightUnit}
                        fullWidth
                        name="variant.shippingWeightUnit"
                        options={SHIPPING_WEIGHT_UNITS}
                        rules={{ required: "*required" }}
                      />
                    </InputAdornment>
                  ),
                  inputProps: {
                    min: 0,
                    step: 0.1,
                  },
                  onWheel: OnWheelBlur,
                }}
                inputRef={register({
                  required: "*required",
                })}
                name="variant.shippingWeight"
                type="number"
                variant="outlined"
              />
            </Grid>
          </>
        )}
      </Grid>
    </FormCard>
  );
};

export default InventoryShipping;
