import { Chip, makeStyles } from "@material-ui/core";

import Statuses from "../modules/orders/constants/orderStatus";

const useStyles = makeStyles(theme => ({
  root: {
    fontWeight: "normal",
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
}));

const StatusChip = ({ status: orderStatus }: { status: string | undefined }) => {
  const classes = useStyles();

  if (!orderStatus) {
    return null;
  }

  const status = Statuses.find(({ id }) => id === orderStatus);

  return <Chip className={classes.root} color={status.color} label={status.label} size="small" />;
};

export default StatusChip;
