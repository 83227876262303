import { gql } from "@apollo/client";

const appShippingUpdate = gql`
  mutation AppShippingUpdate($input: ShippingUpdateInput!) {
    appShippingUpdate(input: $input) {
      name
      defaultServiceCode
    }
  }
`;

export default appShippingUpdate;
