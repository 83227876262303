import { grey } from "@material-ui/core/colors";
import { createTheme, responsiveFontSizes } from "@material-ui/core/styles";
import { alpha } from "@material-ui/core/styles";

declare module "@material-ui/core/styles/createPalette" {
  interface Palette {
    default?: SimplePaletteColorOptions;
    delivery?: SimplePaletteColorOptions;
    disabled?: SimplePaletteColorOptions;
    pickup?: SimplePaletteColorOptions;
    shipping?: SimplePaletteColorOptions;
  }

  interface PaletteOptions {
    default?: PaletteColorOptions;
    delivery?: PaletteColorOptions;
    disabled?: PaletteColorOptions;
    pickup?: PaletteColorOptions;
    shipping?: PaletteColorOptions;
  }
}

declare module "@material-ui/core/styles/shape" {
  interface Shape {
    borderRadius: number;
    borderRadiusExtra: number;
  }
}

export const muiTheme = ({
  colors = {
    primary: "#126D49",
    secondary: "#B00020",
  },
  darkModeEnabled = false,
  fontFamily = "Inter",
}) =>
  responsiveFontSizes(
    createTheme({
      overrides: {
        MuiAppBar: {
          colorPrimary: {
            backgroundColor: "#FFFFFF",
            color: grey[900],
          },
        },
        MuiButton: {
          root: {
            borderRadius: 4,
            textTransform: "none",
          },
        },
        MuiChip: {
          colorPrimary: {
            color: "#FFFFFF",
          },
        },
        MuiCssBaseline: {
          "@global": {
            ".MuiOutlinedInput-multiline": {
              fontSize: "16px",
            },
            ".MuiPickersCalendar-transitionContainer": {
              minHeight: "244px !important",
            },
            ".ql-content > p": {
              margin: 0,
              padding: 0,
            },
            ".ql-size-huge": {
              fontSize: "3rem",
            },
            ".ql-size-large": {
              fontSize: "1.5rem",
            },
            "address": {
              fontStyle: "normal",
            },
            "html": {
              "-webkit-tap-highlight-color": alpha(colors.primary, 0.2),
              "scrollBehavior": "smooth",
            },
            "input[type='email']": {
              fontSize: "16px",
            },
            "input[type='number']": {
              fontSize: "16px",
            },
            "input[type='password']": {
              fontSize: "16px",
            },
            "input[type='tel']": {
              fontSize: "16px",
            },
            "input[type='text']": {
              fontSize: "16px",
            },
            "textarea": {
              fontSize: "16px",
            },
          },
        },
        MuiFab: {
          root: {
            backgroundColor: "#FFFFFF",
          },
        },
        MuiFormHelperText: {
          contained: {
            marginLeft: 0,
          },
          root: {
            marginLeft: 0,
          },
        },
        MuiInputBase: {
          input: {
            "&::-webkit-inner-spin-button": {
              "-webkit-appearance": "none",
              "margin": 0,
            },
            "&::-webkit-outer-spin-button": {
              "-webkit-appearance": "none",
              "margin": 0,
            },
            "&[type=number]": {
              "-moz-appearance": "textfield",
            },
          },
        },
        MuiLink: {
          root: {
            "&[disabled]": {
              "&:hover": {
                textDecoration: "none",
              },
              "color": "grey",
              "cursor": "not-allowed",
              "opacity": "0.8",
            },
            "cursor": "pointer",
          },
        },
        MuiTable: {
          root: {
            borderColor: grey[300],
          },
        },
        MuiTableCell: {
          head: {
            backgroundColor: "transparent",
          },
          root: {
            borderColor: grey[300],
          },
          sizeSmall: {
            padding: "8px",
          },
          stickyHeader: {
            fontWeight: 500,
          },
        },
        MuiTableContainer: {
          root: {
            borderBottom: "none",
            borderRadius: 8,
          },
        },
        MuiTooltip: {
          arrow: {
            color: "#FFFFFF",
          },
          tooltip: {
            backgroundColor: "#FFFFFF",
            borderRadius: 3,
            color: "rgba(0, 0, 0, 0.87)",
            filter: "drop-shadow(rgba(0, 0, 0, 0.3) 0 2px 3px)",
            fontSize: 12,
            fontWeight: 400,
          },
        },
      },
      palette: {
        default: {
          main: "#757575",
        },
        delivery: {
          main: "#8f2d56",
        },
        disabled: {
          main: "rgba(0, 0, 0, 0.26)",
        },
        error: {
          main: "#B00020",
        },
        pickup: {
          main: "#ffbc42",
        },
        primary: {
          main: colors.primary,
        },
        secondary: {
          main: "#B00020",
        },
        shipping: {
          main: "#0496ff",
        },
        type: darkModeEnabled ? "dark" : "light",
      },
      props: {
        MuiButton: { disableElevation: true },
      },
      shape: { borderRadius: 8, borderRadiusExtra: 12 },
      typography: {
        fontFamily: [
          fontFamily,
          "system-ui",
          "-apple-system",
          "BlinkMacSystemFont",
          '"Segoe UI"',
          "Roboto",
          '"Helvetica Neue"',
          "Arial",
          "sans-serif",
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(","),
        fontSize: 13,
        h2: {
          fontSize: "1.85rem",
          fontWeight: 600,
          letterSpacing: -0.5,
        },
      },
    }),
  );

export default muiTheme;
