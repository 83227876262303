import { gql, useMutation, useQuery } from "@apollo/client";
import { DATALAYER } from "@arowana/util";
import { Button, Divider, makeStyles, Typography } from "@material-ui/core";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

import { notificationVar } from "../../../cache/notificationPolicy";
import ConfirmationModal from "../../../components/ConfirmationModal";
import FormCard from "../../../components/FormCard";
import Loader from "../../../components/Loader";
import PageHeader from "../../../components/PageHeader";
import Routes from "../../../Constants/Routes";
import SupplierRemoveIntegration from "../../../mutations/SupplierRemoveIntegration";
import { supplierIntegrations } from "../../../queries/supplier";
import supplierUpdateSettings from "../../../queries/supplierUpdateSettings";
import { INTEGRATIONS, INTEGRATIONS_FRAGMENT } from "../pages/Apps";

const useStyles = makeStyles(theme => ({
  bottomUninstall: {
    marginTop: theme.spacing(2),
  },
  discardBtn: {
    marginRight: theme.spacing(1),
  },
}));

const INTEGRATION_UPDATE = gql`
  mutation updateIntegration($input: SupplierIntegrationsInput!) {
    supplierUpdateIntegration(input: $input) {
      ...IntegrationsFragment
    }
  }
  ${INTEGRATIONS_FRAGMENT}
`;

const INTEGRATION_REMOVE = gql`
  mutation removeIntegration($key: IntegrationKey!) {
    supplierRemoveIntegration(key: $key) {
      ...IntegrationsFragment
    }
  }
  ${INTEGRATIONS_FRAGMENT}
`;

const AppForm = ({
  appId,
  defaultValues,
  header,
  isInstalled,
  children,
  uninstallPrompt = "Are you sure you want to uninstall this app?",
}) => {
  const mailchimpForm = appId === "mailchimp";
  const history = useHistory();
  const classes = useStyles();
  const [openModal, setOpenModal] = useState(false);
  const formMethods = useForm({ defaultValues });
  const { handleSubmit, formState, reset } = formMethods;
  const { data, loading: isFetching } = useQuery(INTEGRATIONS, {
    context: { source: DATALAYER },
    fetchPolicy: "cache-and-network",
    onCompleted: () => {
      const fields = data.currentSupplier?.integrations?.[appId];
      reset({ ...fields });
    },
  });
  const installed = isInstalled(data?.currentSupplier?.integrations?.[appId]);
  const mailchimpUpdate = mailchimpForm && installed;
  const [update, { loading: isSaving }] = useMutation(INTEGRATION_UPDATE, {
    context: { source: DATALAYER },
    onCompleted: () => {
      notificationVar({
        message: `${header} ${mailchimpUpdate ? "updated" : "installed"}!`,
        severity: "success",
      });

      if (!mailchimpUpdate) {
        history.push(Routes.APPS);
      }
    },
    refetchQueries: ["SupplierIntegrations"],
  });
  const [remove, { loading: isRemoving }] = useMutation(INTEGRATION_REMOVE, {
    context: { source: DATALAYER },
    onCompleted: () => {
      notificationVar({
        message: `${header} uninstalled!`,
        severity: "success",
      });
      history.push(Routes.APPS);
    },
    refetchQueries: ["SupplierIntegrations"],
  });
  const handleDiscardClick = () => reset();
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const handleDeleteClick = () => {
    handleCloseModal();
    remove({
      variables: {
        key: appId,
      },
    });
  };
  const onSubmit = formData => {
    const variables = {
      input: {
        [appId]: formData,
      },
    };
    update({ variables });
  };
  let actionButtons;

  if (mailchimpForm || !installed) {
    actionButtons = (
      <>
        <Button color="primary" disabled={!formState?.isDirty} onClick={handleDiscardClick} variant="outlined">
          Discard
        </Button>
        <Button color="primary" disabled={!formState?.isDirty} onClick={handleSubmit(onSubmit)} variant="contained">
          Save
        </Button>
      </>
    );
  } else {
    actionButtons = (
      <Button color="secondary" onClick={handleOpenModal} variant="outlined">
        Uninstall
      </Button>
    );
  }

  return (
    <>
      {(isFetching || isSaving || isRemoving) && <Loader />}
      <PageHeader primaryActions={actionButtons} stickyHeader title={header} />

      <FormCard>
        <Typography variant="h6">Account information</Typography>
        <Divider />
        <form>{children({ formMethods, installed })}</form>
        {mailchimpUpdate && (
          <Button className={classes.bottomUninstall} color="secondary" onClick={handleOpenModal} variant="outlined">
            Uninstall
          </Button>
        )}
      </FormCard>

      <ConfirmationModal
        cancelRequestButtonText="Cancel"
        confirmRequestButtonText="Uninstall"
        isDangerAction
        modalContent={uninstallPrompt}
        modalNote="Note: This action cannot be reversed."
        modalTitle={`Uninstall ${header}`}
        onCloseModal={handleCloseModal}
        onConfirmClick={handleDeleteClick}
        shouldOpenModal={openModal}
      />
    </>
  );
};

export default AppForm;
