import { gql, useQuery } from "@apollo/client";
import { Account, FulfillmentMethod, PaymentMethod, RecurringOrderList } from "@arowana/graphql";
import { DATALAYER } from "@arowana/util";
import { useContext, useMemo, useState } from "react";

import { SupplierContext } from "../context";

const ACCOUNT = gql`
  fragment AccountForRecurringOrderInputFragment on Account {
    id
    addresses {
      id
      label
      formattedAddress
      notes
      lngLat {
        coordinates
      }
    }
    paymentMethods {
      id
      card {
        brand
        last4
        expiryMonth
        expiryYear
      }
    }
  }

  query AccountForRecurringOrderInput($accountId: ID!, $hasAccountId: Boolean!) {
    currentAccount @skip(if: $hasAccountId) {
      ...AccountForRecurringOrderInputFragment
    }
    account(id: $accountId) @include(if: $hasAccountId) {
      ...AccountForRecurringOrderInputFragment
    }
  }
`;

type AccountArgs = {
  accountId: string;
  hasAccountId: boolean;
};

type AccountResponse = {
  currentAccount?: Pick<Account, "id" | "addresses" | "paymentMethods">;
  account?: Pick<Account, "id" | "addresses" | "paymentMethods">;
};

type HookArgs = {
  recurringOrderList: Partial<RecurringOrderList>;
  skipAccountQuery?: boolean;
  accountId?: string;
  initialDeliveryAddressId?: string;
  initialPickupLocationId?: string;
  fulfillmentMethod?: string;
  refetchAccount?: (() => void) | null;
};

export const useRecurringOrderState = ({
  recurringOrderList,
  skipAccountQuery = false,
  accountId = "",
  initialDeliveryAddressId = "",
  initialPickupLocationId = "",
  fulfillmentMethod = "",
  refetchAccount = null,
}: HookArgs) => {
  // Gloabl props
  const { locations } = useContext(SupplierContext);

  // Address states
  const [pickupLocationId, setPickupLocationId] = useState(initialPickupLocationId);
  const [showAddressForm, setShowAddressForm] = useState(false);
  const [deliveryAddressId, setDeliveryAddressId] = useState(initialDeliveryAddressId);

  // Determine what `fulfillmentMethods` does this recurringOrderList supports first
  const { fulfillmentMethods = [] } = recurringOrderList ?? {};
  const { hasPickup, hasDelivery } = useMemo(
    () => ({
      hasDelivery: fulfillmentMethods.some(
        fm => fm === FulfillmentMethod.delivery || fm === FulfillmentMethod.shipping,
      ),
      hasPickup: fulfillmentMethods.includes(FulfillmentMethod.pickup),
    }),
    [fulfillmentMethods],
  );
  // if fulfillmentMethod is "", then the hook is used for create, set isPickup by hasPickup
  const [isPickup, setIsPickup] = useState(
    fulfillmentMethod ? fulfillmentMethod === FulfillmentMethod.pickup : hasPickup,
  );

  const deliveryAvailable = useMemo(
    () =>
      locations?.some(
        ({ active, fulfillmentMethod }) =>
          (fulfillmentMethod === FulfillmentMethod.delivery || fulfillmentMethod === FulfillmentMethod.shipping) &&
          active,
      ) && hasDelivery,
    [locations, hasDelivery],
  );

  const pickupLocations = useMemo(() => {
    const locs = locations?.filter(({ fulfillmentMethod }) => fulfillmentMethod === FulfillmentMethod.pickup) ?? [];

    if (locs.length > 0) {
      setPickupLocationId(locs[0].id);
    }

    return locs;
  }, [locations]);
  const pickupAvailable = pickupLocations?.length > 0 && hasPickup;

  // Callbacks for various address input
  const onFulfillmentMethodToggle = () => {
    if (isPickup && deliveryAvailable) {
      setIsPickup(false);
    } else if (pickupAvailable) {
      setIsPickup(true);
    }
  };

  const onPickupLocationChange = event => setPickupLocationId(event.target.value);
  const onDeliveryAddressChange = event => {
    const accountAddressId = event?.target?.value;

    if (accountAddressId === "add") {
      setShowAddressForm(true);
      setDeliveryAddressId("");

      return;
    }

    setDeliveryAddressId(accountAddressId);
  };

  // Payment states
  const [paymentMethodId, setPaymentMethodId] = useState("");
  const [showPaymentForm, setShowPaymentForm] = useState(false);

  // Callbacks for payment inputs
  const onPaymentMethodChange = event => {
    const value = event.target.value;

    if (value === "add") {
      setShowPaymentForm(true);
      setPaymentMethodId("");
    } else {
      setPaymentMethodId(value);
    }
  };

  const onAddPaymentMethod = (pm: Partial<PaymentMethod>) => {
    if (typeof refetchAccount === "function") {
      refetchAccount();
    }

    setPaymentMethodId(pm.id);
  };
  const onCancelAddPaymentMethod = () => {
    if (paymentMethods.length > 0) {
      setShowPaymentForm(false);
      setPaymentMethodId(paymentMethods[0].id);
    }
  };

  const { data, loading: fetchingAccount } = useQuery<AccountResponse, AccountArgs>(ACCOUNT, {
    context: { source: DATALAYER },
    fetchPolicy: "cache-and-network",
    onCompleted: ({ account, currentAccount }) => {
      const { addresses, paymentMethods } = currentAccount ?? account;

      if (!paymentMethodId) {
        if (paymentMethods.length > 0) {
          setPaymentMethodId(paymentMethods?.[0]?.id);
        } else {
          setShowPaymentForm(true);
        }
      }

      if (!deliveryAddressId && addresses?.length > 0) {
        setDeliveryAddressId(addresses?.[0]?.id);
      }
    },
    skip: skipAccountQuery,
    ssr: false,
    variables: {
      accountId: accountId ?? "",
      hasAccountId: Boolean(accountId),
    },
  });

  const account = data?.currentAccount ?? data?.account;
  const [deliveryAddresses, paymentMethods] = useMemo(
    () => [account?.addresses ?? [], account?.paymentMethods ?? []],
    [account],
  );

  const onAddressAdded = address => {
    setShowAddressForm(false);
    setDeliveryAddressId(address.id);
  };
  const onCancelAddAddress = !deliveryAddresses ? null : () => setShowAddressForm(false);

  return {
    addressInputProps: {
      account,
      deliveryAddressId,
      deliveryAddresses,
      deliveryAvailable,
      isPickup,
      onAdded: onAddressAdded,
      onCancelAdd: onCancelAddAddress,
      onDeliveryAddressChange,
      onFulfillmentMethodToggle,
      onPickupLocationChange,
      pickupAvailable,
      pickupLocationId,
      pickupLocations,
      showAddressForm,
    },
    fetchingAccount,
    hasValidInput:
      (accountId ? Boolean(paymentMethodId) : true) &&
      (isPickup ? Boolean(pickupLocationId) : Boolean(deliveryAddressId)),
    inputState: {
      addressId: !isPickup ? deliveryAddressId : null,
      paymentMethodId,
      pickupLocationId: isPickup ? pickupLocationId : null,
    },
    paymentInputProps: {
      account,
      onAdd: onAddPaymentMethod,
      onCancelAdd: onCancelAddPaymentMethod,
      onChange: onPaymentMethodChange,
      paymentMethods,
      showPaymentForm,
      value: paymentMethodId,
    },
  };
};

export default useRecurringOrderState;
