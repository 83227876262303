import { gql, useMutation } from "@apollo/client";
import { AddressForm } from "@arowana/ui";
import { DATALAYER } from "@arowana/util";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { Edit as EditIcon } from "@material-ui/icons";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";

import { notificationVar } from "../cache/notificationPolicy";

const useStyles = makeStyles(theme => ({
  dialogContainer: {
    [theme.breakpoints.only("xs")]: {
      paddingTop: theme.spacing(7),
    },
  },
}));

type FormInputs = {
  address1: string;
  address2: string;
  sublocality: string;
  city: string;
  country: string;
  label: string;
  postalCode: string;
  region: string;
  notes: string;
};

const ADDRESS_CREATE = gql`
  mutation AccountAddressCreate($id: ID!, $input: AddressCreateInput!) {
    accountAddressCreate(id: $id, input: $input) {
      address1
      address2
      sublocality
      city
      country
      formattedAddress
      id
      label
      notes
      postalCode
      region
    }
  }
`;

const ADDRESS_UPDATE = gql`
  mutation AccountAddressUpdate($id: ID!, $input: AddressUpdateInput!) {
    accountAddressUpdate(id: $id, input: $input) {
      address1
      address2
      sublocality
      city
      country
      formattedAddress
      id
      label
      notes
      postalCode
      region
    }
  }
`;

const ADDRESS_DELETE = gql`
  mutation AccountAddressRemove($id: ID!, $input: AddressDeleteInput!) {
    accountAddressDelete(id: $id, input: $input) {
      id
    }
  }
`;

const AccountAddressItem = ({
  account,
  address,
  onCancel = undefined,
  onCreate = undefined,
  open: defaultOpen = false,
  ...props
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const _isMounted = useRef(true);
  const isNew = !address.id;
  const htmlId = address.id || "NEW";
  const [open, setOpen] = useState(defaultOpen);
  useEffect(() => {
    return () => {
      _isMounted.current = false;
    };
  }, []);
  const { control, handleSubmit, reset, setValue } = useForm<FormInputs>({
    defaultValues: {
      address1: address.address1,
      address2: address.address2,
      city: address.city,
      country: address.country,
      label: isNew ? `Home address ${(account.addresses?.length ?? 0) + 1}` : address.label,
      notes: address.notes,
      postalCode: address.postalCode,
      region: address.region,
      sublocality: address.sublocality,
    },
  });
  const closeDialog = () => {
    if (_isMounted.current) {
      setOpen(false);
    }
  };

  const [createAddress, { loading: createAddressLoading }] = useMutation(ADDRESS_CREATE, {
    context: { source: DATALAYER },
    onCompleted: data => {
      if (typeof onCreate === "function") {
        onCreate(data);
      }

      closeDialog();

      notificationVar({
        message: "Address added!",
        severity: "success",
      });
    },
    update: (cache, { data: { accountAddressCreate: address } }) => {
      const result = cache.readQuery<{ account }>({
        query: gql`
          query Account($id: ID!) {
            account(id: $id) {
              id
              __typename
            }
          }
        `,
        variables: {
          id: account.id,
        },
      });

      const id = cache.identify(result.account);

      cache.modify({
        fields: {
          addresses: (existingAddresses = []) => {
            const addressRef = cache.writeFragment({
              data: address,
              fragment: gql`
                fragment NewAddress on Address {
                  id
                  __typename
                }
              `,
            });

            return [...existingAddresses, addressRef];
          },
        },
        id,
      });
    },
  });
  const [updateAddress, { loading: updateAddressLoading }] = useMutation(ADDRESS_UPDATE, {
    context: { source: DATALAYER },
    onCompleted: () => {
      closeDialog();
      notificationVar({
        message: "Address updated!",
        severity: "success",
      });
    },
  });
  const [removeAddress, { loading: removeAddressLoading }] = useMutation(ADDRESS_DELETE, {
    context: { source: DATALAYER },
    onCompleted: () => {
      closeDialog();
      notificationVar({
        message: "Address removed!",
        severity: "success",
      });
    },
    update: cache => {
      const id = cache.identify({ __typename: "Address", id: address.id });
      cache.evict({ id });
      cache.gc();
    },
  });

  const onReset = () => {
    reset({
      address1: address.address1,
      address2: address.address2,
      city: address.city,
      country: address.country,
      label: address.label,
      notes: address.notes,
      postalCode: address.postalCode,
      region: address.region,
    });
  };

  const handleCancel = () => {
    if (typeof onCancel === "function") {
      onCancel();
    }

    onReset();

    closeDialog();
  };
  const handleRemove = () => {
    removeAddress({
      variables: {
        id: account.id,
        input: {
          id: address.id,
          reason: "Deleted by customer.",
        },
      },
    });
  };
  const onSubmit = data => {
    if (isNew) {
      createAddress({
        variables: {
          id: account.id,
          input: {
            ...data,
          },
        },
      });
    } else {
      updateAddress({
        variables: {
          id: account.id,
          input: {
            ...data,
            id: address.id,
          },
        },
      });
    }
  };

  return (
    <>
      <ListItem disableGutters {...props}>
        <ListItemText primary={address.label} secondary={address.formattedAddress} />
        <ListItemSecondaryAction>
          <IconButton edge="end" onClick={() => setOpen(true)}>
            <EditIcon fontSize="small" />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>

      <Dialog
        className={classes.dialogContainer}
        fullScreen={isMobile}
        fullWidth
        maxWidth="sm"
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            setOpen(false);
          }
        }}
        open={open}
        aria-labelledby={`form-dialog-title-${htmlId}`}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle id={`form-dialog-title-${htmlId}`}>Delivery address</DialogTitle>
          {(createAddressLoading || updateAddressLoading || removeAddressLoading) && <LinearProgress />}
          <DialogContent dividers>
            <AddressForm
              control={control}
              htmlId={htmlId}
              onReset={onReset}
              setValue={setValue}
              country={address?.country}
            />
          </DialogContent>
          <DialogActions>
            {!isNew && (
              <Button color="secondary" onClick={handleRemove} tabIndex="-1" disabled={removeAddressLoading}>
                Remove
              </Button>
            )}
            <Button onClick={handleCancel} color="primary" variant="outlined" tabIndex="0">
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              tabIndex="0"
              type="submit"
              disabled={createAddressLoading || updateAddressLoading}
            >
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default AccountAddressItem;
