import { ProductUnit } from "@arowana/graphql";
import { QuickSearch } from "@arowana/ui";
import {
  Box,
  Chip,
  FormControl,
  FormHelperText,
  FormLabel,
  ListItemText,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { useContext } from "react";

import { AccountContext } from "../../context/AccountContext";

const useStyles = makeStyles(theme => ({
  productName: {
    alignItems: "center",
    display: "flex",
    gap: theme.spacing(1),
  },
}));

// PVOSearch maps exactly to the PVO search model in datalayer
export interface PVOSearch {
  active: boolean;
  case_size: number;
  category_id: string;
  featured: boolean;
  id: string;
  on_sale: boolean;
  original_price?: number;
  out_of_stock: boolean;
  price: number;
  product_id: string;
  product_name: string;
  sku?: string;
  supplier_id: string;
  tags: string[];
  thumbnail: string;
  unit: ProductUnit;
  variant_id: string;
  variant_name: string;
}

interface PVOAutocompleteProps {
  className?: string;
  error?: boolean;
  excludeVariantIds?: Set<string>;
  helperText?: string;
  label?: string;
  noOptionsText?: string;
  onChange: (product: PVOSearch) => void;
  placeholder?: string;
  wholesaleListId: string;
}

// add product to a wholesale order details page
const PVOAutocomplete = ({
  className,
  error = false,
  excludeVariantIds = new Set(),
  helperText,
  label,
  noOptionsText = "No products match your search parameters, or they have already been added to this list.",
  onChange,
  placeholder = "Search products",
  wholesaleListId = "",
}: PVOAutocompleteProps) => {
  const classes = useStyles();
  const { supplier } = useContext(AccountContext);

  const filterVariantIds = [...excludeVariantIds].map(variantId => `variant_id != "${variantId}"`).join(" AND ");

  return (
    <FormControl className={className} component="fieldset" fullWidth>
      {label && <FormLabel component="legend">{label}</FormLabel>}

      <QuickSearch
        getOptionLabel={(option: PVOSearch) => option.product_name}
        index="product_variant_overrides"
        label=""
        margin="dense"
        noOptionsText={noOptionsText}
        onChange={onChange}
        placeholder={placeholder}
        renderOption={(option: PVOSearch) => (
          <ListItemText
            primary={
              <Box className={classes.productName}>
                <Typography variant="body2" color={option.active ? "initial" : "textSecondary"}>
                  {option.product_name}
                </Typography>
                {!option.active && <Chip color="secondary" label="Inactive" size="small" variant="outlined" />}
              </Box>
            }
            secondary={
              <Typography color={option.active ? "initial" : "textSecondary"} variant="subtitle2">
                {option.variant_name}
              </Typography>
            }
          />
        )}
        searchParams={{
          filter: [`supplier_id = "${supplier.id}"`, `wholesale_list_id = "${wholesaleListId}"`, filterVariantIds],
          limit: 10,
        }}
        supplierId={supplier.id}
      />
      <FormHelperText error={error}>{helperText}</FormHelperText>
    </FormControl>
  );
};

export default PVOAutocomplete;
