import { gql, useQuery } from "@apollo/client";
import { FEATURE_SUPPLIER_STRIPE_INVOICES } from "@arowana/flags";
import { ProductForOrders } from "@arowana/graphql";
import { PaginatedTable, usePagination } from "@arowana/ui";
import { DATALAYER, unitLabel } from "@arowana/util";
import { Box, FormControl, InputLabel, Link, MenuItem, Select, Typography } from "@material-ui/core";
import moment from "moment";
import { useContext, useMemo, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

import PageHeader from "../../../components/PageHeader";
import Quantity from "../../../components/Quantity";
import Routes from "../../../Constants/Routes";
import ExportCSVLink from "../../../utils/ExportCSVLink";
import PrintPageButton from "../../../utils/PrintPageButton";
import { FlagsmithContext } from "../../context/FlagsmithContext";
import OperationsFilters from "../components/OperationsFilters";
import { PicklistProps } from "./OrdersPicklist/OrdersPicklist";

const scrollWithOffset = el => {
  const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
  const yOffset = -130;
  window.scrollTo({ behavior: "smooth", top: yCoordinate + yOffset });
};

export const transformForExport = (data: ProductForOrders[], { hasInvoices = false }) =>
  data.map(product => ({
    "Name": product?.productName,
    "Option name": product?.subName,
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    "Category": product?.categoryName ?? "N/A",
    "SKU (Inventory code)": product?.sku,
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    "Case size": product?.caseSize,
    // eslint-disable-next-line sort-keys-fix/sort-keys-fix
    "# of cases": hasInvoices ? product?.cases : product?.totalCases,
    "Total packed": product?.totalQuantity?.toFixed(2),
    "Unit": unitLabel(product?.unit),
  }));

const PRODUCT_PICKLIST = gql`
  query ProductPicklists($filter: ProductsForOrdersFilter!) {
    productsForOrders(filter: $filter) {
      id
      categoryId
      categoryName
      productId
      productName
      subName
      cases
      caseSize
      unit
      sku
      totalCases
      totalQuantity
    }
  }
`;

function buildColumns({ hasInvoices = false }) {
  return [
    {
      field: "id",
      hide: true,
    },
    {
      field: "name",
      headerName: "Name",
      renderCell: ({ row }) => (
        <Box display="flex" flexDirection="column" lineHeight="normal">
          <Link component={RouterLink} to={Routes.PRODUCT.replace(":id", row.productId)}>
            {row.productName}
          </Link>
          <Typography variant="body2"></Typography>
          <Typography variant="caption">{row.subName}</Typography>
        </Box>
      ),
      valueGetter: param => `${param?.row?.productName} ${param?.row?.subName}`,
      width: 300,
    },
    {
      field: "categoryName",
      headerName: "Category",
      renderCell: ({ row, value }) =>
        value ? (
          <Link component={HashLink} scroll={scrollWithOffset} smooth to={`${Routes.CATEGORIES}#${row?.categoryId}`}>
            {value}
          </Link>
        ) : (
          "N/A"
        ),
      valueGetter: ({ row }) => row?.categoryName,
      width: 200,
    },
    {
      field: "inventoryCode",
      headerName: "SKU (Inventory code)",
      valueGetter: ({ row }) => row?.sku,
      width: 200,
    },
    {
      field: "caseSize",
      headerName: "Case Size",
      renderCell: ({ row }) => {
        return row?.caseSize ? <Quantity amount={row.caseSize} unit={row?.unit} /> : "N/A";
      },
      width: 150,
    },
    {
      field: "totalCases",
      headerName: "# of Cases",
      renderCell: ({ value, row }) => `× ${(hasInvoices ? row?.cases : value) ?? "N/A"}`,
      width: 150,
    },
    {
      field: "totalQuantity",
      headerName: "Total Packed",
      renderCell: ({ value: totalQuantity, row }) => (
        <strong>{totalQuantity ? <Quantity amount={totalQuantity} unit={row?.unit} /> : "N/A"}</strong>
      ),
      width: 150,
    },
  ];
}

const ROWS_PER_PAGE = 100;

const ProductsPicklist = ({
  audience,
  endDate,
  locationIds,
  locations,
  onAudienceChange,
  onStartChange,
  onEndChange,
  onLocationChange,
  startDate,
  timezone,
}: PicklistProps) => {
  const parsedStart = moment.tz(startDate, timezone).startOf("day").utc();
  const parsedEnd = moment.tz(endDate, timezone).endOf("day").utc();
  const exportFileName = `Products-Picklist-${audience}-${startDate}-to-${endDate}.csv`;
  const [fulfilledFilter, setFulfilledFilter] = useState([]);
  const flagsmith = useContext(FlagsmithContext);
  const hasInvoices = flagsmith.hasFeature(FEATURE_SUPPLIER_STRIPE_INVOICES);

  const handleFulfilledChange = event => {
    const { value } = event.target;
    setFulfilledFilter(value);
  };

  const { page, onPageChange } = usePagination(ROWS_PER_PAGE);

  let fulfilled;

  if (fulfilledFilter.length === 1) {
    fulfilled = fulfilledFilter[0] == "fulfilled";
  } else {
    fulfilled = undefined;
  }

  const { data, loading: loading } = useQuery<{ productsForOrders: ProductForOrders[] }>(PRODUCT_PICKLIST, {
    context: { source: DATALAYER },
    fetchPolicy: "no-cache",
    variables: {
      filter: {
        ...(audience !== "ALL" && { audience }),
        after: parsedStart.toISOString(),
        before: parsedEnd.toISOString(),
        fulfilled,
        locationIds,
      },
    },
  });

  const rowsForExport = useMemo(
    () => transformForExport(data?.productsForOrders ?? [], { hasInvoices }),
    [data?.productsForOrders],
  );

  return (
    <>
      <PageHeader
        primaryActions={
          <>
            <PrintPageButton color="primary">Print</PrintPageButton>
            <ExportCSVLink data={rowsForExport} disabled={loading} filename={exportFileName}>
              Export
            </ExportCSVLink>
          </>
        }
        stickyHeader
        title="Product picklist"
      />
      <OperationsFilters
        audience={audience}
        endDate={endDate}
        locationIds={locationIds}
        locations={locations}
        onAudienceChange={onAudienceChange}
        onEndChange={onEndChange}
        onStartChange={onStartChange}
        onLocationChange={onLocationChange}
        startDate={startDate}
        extraControls={[
          <FormControl variant="outlined" style={{ minWidth: "100px" }}>
            <InputLabel id="product-picklist-filter-fulfilled-label">Fulfilled</InputLabel>
            <Select
              labelId="product-picklist-filter-fulfilled-label"
              id="product-picklist-filter-fulfilled"
              multiple
              name="fulfilledFilter"
              value={fulfilledFilter}
              onChange={handleFulfilledChange}
            >
              <MenuItem value="fulfilled">Fulfilled</MenuItem>
              <MenuItem value="unfulfilled">Unfulfilled</MenuItem>
            </Select>
          </FormControl>,
        ]}
      />
      <PaginatedTable
        sortModel={[
          {
            field: "name",
            sort: "asc" as GridSortDirection,
          },
        ]}
        columns={buildColumns({ hasInvoices })}
        loading={loading}
        onPageChange={onPageChange}
        page={page}
        paginationMode="client"
        rows={data?.productsForOrders ?? []}
        sortingMode="client"
      />
    </>
  );
};

export default ProductsPicklist;
