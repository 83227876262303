import { QuickSearch } from "@arowana/ui";
import { FormControl, FormHelperText, FormLabel, ListItemText } from "@material-ui/core";
import { useContext } from "react";

import { AccountContext } from "../../context/AccountContext";

export interface AccountSearch {
  id: string;
  email: string;
  name: string;
  supplier_id: string;
}

interface AccountAutocompleteProps {
  className?: string;
  disabled?: boolean;
  filterIds?: Set<string>;
  helperText?: string;
  label?: string;
  onChange: (client: AccountSearch) => void;
  noOptionsText?: string;
}

// add accounts to a wholesale client
const AccountAutocomplete = ({
  className = "",
  disabled = false,
  filterIds = new Set(),
  helperText = "",
  label = "",
  onChange,
  noOptionsText = "No accounts match your search parameters, or they have already been added to this list",
}: AccountAutocompleteProps) => {
  const { supplier } = useContext(AccountContext);

  const filteredIds = [...filterIds].map(id => `id != "${id}"`).join(" AND ");

  return (
    <FormControl className={className} component="fieldset" fullWidth>
      {label && <FormLabel component="legend">{label}</FormLabel>}

      <QuickSearch
        disabled={disabled}
        getOptionLabel={(option: AccountSearch) => option?.name}
        index="accounts"
        label=""
        margin="dense"
        noOptionsText={noOptionsText}
        onChange={onChange}
        placeholder="Search for an account"
        renderOption={({ id, email, name }: AccountSearch) => (
          <ListItemText key={id} primary={name} secondary={email} />
        )}
        searchParams={{
          filter: [`supplier_id = "${supplier.id}"`, filteredIds],
          limit: 10,
        }}
      />

      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default AccountAutocomplete;
