import { gql, useMutation } from "@apollo/client";
import { Address } from "@arowana/graphql";
import { DATALAYER } from "@arowana/util";
import { Box, Button, CircularProgress, makeStyles, Theme } from "@material-ui/core";
import { useContext } from "react";
import { useForm } from "react-hook-form";

import { SupplierContext } from "../context";
import AddressForm, { AddressFormFields } from "./AddressForm";

const ADDRESS_CREATE = gql`
  mutation AccountAddressCreate($id: ID!, $input: AddressCreateInput!) {
    accountAddressCreate(id: $id, input: $input) {
      address1
      address2
      city
      country
      formattedAddress
      id
      label
      postalCode
      region
    }
  }
`;

const DEFAULT_ADDRESS = {
  address1: "",
  address2: "",
  city: "",
  country: "",
  label: "Home",
  notes: "",
  postalCode: "",
  region: "",
};

const useStyles = makeStyles<Theme>(theme => ({
  cancel: {
    marginRight: theme.spacing(1),
  },
}));

type NewAddressFormProps = {
  account: {
    id?: string;
  };
  hasExistingAddress: boolean;
  onSuccess: (address: Address) => void;
  onCancel: () => void;
  initialAddress?: unknown;
};

export const NewAddressForm = ({
  account,
  hasExistingAddress,
  onSuccess,
  onCancel,
  initialAddress,
}: NewAddressFormProps) => {
  const classes = useStyles();
  const { country: countryCode } = useContext(SupplierContext);

  const { control, handleSubmit, reset, setValue } = useForm<AddressFormFields>({
    defaultValues: hasExistingAddress
      ? { ...DEFAULT_ADDRESS }
      : initialAddress
      ? initialAddress
      : { ...DEFAULT_ADDRESS },
  });

  const onReset = () => {
    reset({ ...DEFAULT_ADDRESS });
  };

  const [createAddress, { loading: saving }] = useMutation(ADDRESS_CREATE, {
    context: { source: DATALAYER },
    onCompleted: data => onSuccess(data?.accountAddressCreate),
    update: (cache, { data: { accountAddressCreate: address } }) => {
      const { currentAccount } = cache.readQuery({
        query: gql`
          query CurrentAccount {
            currentAccount {
              id
              __typename
            }
          }
        `,
      });
      const id = cache.identify(currentAccount);

      cache.modify({
        fields: {
          addresses: (existingAddresses = []) => {
            const addressRef = cache.writeFragment({
              data: address,
              fragment: gql`
                fragment NewAddress on Address {
                  id
                  __typename
                }
              `,
            });

            return [...existingAddresses, addressRef];
          },
        },
        id,
      });
    },
  });

  const onSubmit = data => {
    createAddress({
      variables: {
        id: account?.id,
        input: {
          ...data,
        },
      },
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <AddressForm control={control} onReset={onReset} setValue={setValue} countryCode={countryCode} />
      <Box display="flex" justifyContent="flex-end" marginTop={2}>
        {onCancel && (
          <Button className={classes.cancel} color="primary" onClick={onCancel} variant="outlined">
            Cancel
          </Button>
        )}
        <Button color="primary" disabled={saving} tabIndex="0" type="submit" variant="contained">
          {saving ? <CircularProgress color="inherit" size={24} /> : "Save"}
        </Button>
      </Box>
    </form>
  );
};

export default NewAddressForm;
