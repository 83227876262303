import { gql, useMutation, useQuery } from "@apollo/client";
import { DATALAYER } from "@arowana/util";
import { Button, FormControl, FormHelperText, FormLabel, Grid, makeStyles, TextField } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { useContext, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";

import { notificationVar } from "../../../cache/notificationPolicy";
import ConfirmationModal from "../../../components/ConfirmationModal";
import FormCard from "../../../components/FormCard";
import Loader from "../../../components/Loader";
import MultiChips from "../../../components/MultiChips";
import PageHeader from "../../../components/PageHeader";
import TipBanner from "../../../components/TipBanner";
import Routes from "../../../Constants/Routes";
import CATEGORY_FRAGMENT from "../../../fragment/category";
import supplierDeleteCategory from "../../../queries/supplierDeleteCategory";
import { AccountContext } from "../../context/AccountContext";

const Category = gql`
  query Category($id: ID!) {
    category(id: $id) {
      ...CategoryFragment
    }
  }
  ${CATEGORY_FRAGMENT}
`;

const CategoryCreate = gql`
  mutation CategoryCreate($input: CategoryCreateInput!) {
    categoryCreate(input: $input) {
      ...CategoryFragment
    }
  }
  ${CATEGORY_FRAGMENT}
`;

export const CategoryUpdate = gql`
  mutation CategoryUpdate($input: CategoryUpdateInput!) {
    categoryUpdate(input: $input) {
      ...CategoryFragment
    }
  }
  ${CATEGORY_FRAGMENT}
`;

export const CategoryDelete = gql`
  mutation CategoryDelete($id: ID!) {
    categoryDelete(id: $id) {
      ...CategoryFragment
    }
  }
  ${CATEGORY_FRAGMENT}
`;

const useStyles = makeStyles(theme => ({
  categoryTags: {
    marginTop: theme.spacing(4),
  },
  deleteCategoryButton: {
    display: "block",
    marginBottom: theme.spacing(1),
    marginLeft: "auto",
  },
}));

const CategoryDetails = ({ history, match }) => {
  const classes = useStyles();
  const { register, handleSubmit, control, errors, clearErrors, formState, setValue, reset } = useForm({
    defaultValues: {
      name: "",
      tags: [],
    },
  });

  const { supplier } = useContext(AccountContext);
  const { id: supplierId } = supplier;
  const categoryId = match.params?.id;
  const isCreate = match.path === Routes.CREATE_CATEGORY;
  const [openDeleteCategoryModal, setOpenDeleteCategoryModal] = useState(false);

  const {
    data: categoryData,
    loading: { isFetching },
  } = useQuery(Category, {
    context: { source: DATALAYER },
    fetchPolicy: "no-cache",
    onCompleted: data => {
      reset(
        { name: data?.category?.name ?? "", tags: data?.category?.tags?.map(tag => tag.label) ?? [] },
        { dirtyFields: false },
      );
    },
    skip: isCreate,
    variables: { id: categoryId },
  });

  const [createCategory, { loading: isCreating }] = useMutation(CategoryCreate, {
    context: { source: DATALAYER },
    onCompleted: () => {
      notificationVar({
        message: "Category is created!",
        severity: "success",
      });
      history.push(Routes.CATEGORIES);
    },
    refetchQueries: ["GetSupplierCategories"],
  });

  const [updateCategory, { loading: isSaving }] = useMutation(CategoryUpdate, {
    context: { source: DATALAYER },
    onCompleted: () => {
      notificationVar({
        message: "Category is updated!",
        severity: "success",
      });
    },
    refetchQueries: ["Category"],
  });

  const [deleteCategory, { loading: isDeleting }] = useMutation(CategoryDelete, {
    context: { source: DATALAYER },
    onCompleted: () => {
      notificationVar({
        message: "Category is deleted!",
        severity: "success",
      });
      setOpenDeleteCategoryModal(false);
      history.push(Routes.CATEGORIES);
    },
    refetchQueries: ["GetSupplierCategories"],
  });

  const onSubmit = formData => {
    const input = {
      name: formData.name,
      tags: formData.tags.map(tag => ({
        label: tag,
        value: tag,
      })),
    };

    if (isCreate) {
      createCategory({
        variables: {
          input,
        },
      });
    } else {
      updateCategory({
        variables: {
          input: {
            id: categoryId,
            ...input,
          },
        },
      });
    }
  };
  const handleDeleteCategory = () => deleteCategory({ variables: { id: categoryId } });
  const handleDiscardClick = () => {
    clearErrors();
    reset();
  };
  const handleOpenDeleteCategoryModal = () => setOpenDeleteCategoryModal(true);
  const handleCloseDeleteCategoryModal = () => setOpenDeleteCategoryModal(false);

  return (
    <>
      {(isFetching || isSaving || isCreating || (!isCreate && !categoryData)) && <Loader />}
      <PageHeader
        primaryActions={
          <>
            <Button
              color="primary"
              disabled={isCreate ? false : !formState?.isDirty}
              onClick={handleDiscardClick}
              variant="outlined"
            >
              Discard
            </Button>
            <Button
              color="primary"
              disabled={isCreate ? false : !formState?.isDirty}
              onClick={handleSubmit(onSubmit)}
              variant="contained"
            >
              Save
            </Button>
          </>
        }
        secondaryActions={
          !isCreate && (
            <Button className={classes.deleteCategoryButton} color="secondary" onClick={handleOpenDeleteCategoryModal}>
              Delete
            </Button>
          )
        }
        stickyHeader
        title={isCreate ? "New category" : categoryData?.category?.name || "Edit category"}
      />
      <TipBanner
        headerText="Looking to bulk assign items to a category?"
        bodyText="Please visit the Proudct List page to see items assigned to this category.
          To bulk adjust category or tag assignments, use the Bulk Actions on that same page."
      />

      <FormCard>
        <Grid md={6} xs={12}>
          <FormControl margin="normal">
            <FormLabel htmlFor="input-name">Category name</FormLabel>
            <TextField
              aria-describedby="input-name-helper"
              error={Boolean(errors?.name)}
              helperText={errors?.name?.message}
              defaultValue=""
              id="input-name"
              inputRef={register({ required: "*required" })}
              margin="dense"
              name="name"
              variant="outlined"
            />
            <FormHelperText id="input-name-helper">
              This is displayed on the sidebar navigation of your store. Products will be categorized under this name.
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid xs={12}>
          <FormControl margin="normal">
            <FormLabel htmlFor="input-tags">Tags</FormLabel>
            <Controller
              aria-describedby="input-tags-helper"
              as={<MultiChips size="small" />}
              control={control}
              defaultValue={[]}
              error={Boolean(errors?.tags)}
              helperText={errors?.tags?.message}
              id="input-tags"
              name="tags"
              rules={{
                validate: value => value.length > 0 || "*required",
              }}
            />
            <FormHelperText id="input-tags-helper">
              Filters to organize your products under the parent category. Deleting a tag will remove it from all
              corresponding products.{" "}
            </FormHelperText>
          </FormControl>
        </Grid>
      </FormCard>

      <ConfirmationModal
        cancelRequestButtonText="Cancel"
        confirmRequestButtonText="Delete"
        confirmRequestOnGoing={isDeleting}
        isDangerAction
        modalContent="Are you sure you want to delete the category?"
        modalNote="Note: This action cannot be reversed."
        modalTitle="Delete Category"
        onCloseModal={handleCloseDeleteCategoryModal}
        onConfirmClick={handleDeleteCategory}
        shouldOpenModal={openDeleteCategoryModal}
      />
    </>
  );
};

CategoryDetails.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.object.isRequired,
    push: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.object.isRequired,
    path: PropTypes.string.isRequired,
  }).isRequired,
};

export default CategoryDetails;
