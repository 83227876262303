import { Box, Button, Grid, makeStyles, TextField, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import { Controller, useForm } from "react-hook-form";

import isValidHttpUrl from "../../../utils/isValidHttpUrl";
import LogoUpload from "./LogoUpload";

const useStyles = makeStyles(theme => ({
  cancelButton: {
    marginRight: theme.spacing(1),
    textDecoration: "none",
  },
  spacing: {
    marginBottom: theme.spacing(2),
  },
}));
const CarouselForm = ({ isCreate, onCancel, onSubmit, slide }) => {
  const classes = useStyles();
  const { handleSubmit, errors, register, control, reset, setError, clearErrors } = useForm({
    defaultValues: {
      description: slide.description,
      header: slide.header,
      imageObject: {
        file: slide.imageObject?.file,
        image: slide.imageObject?.image,
      },
      link: {
        text: slide.link?.text,
        url: slide.link?.url,
      },
    },
  });
  const handleSubmitClick = data => {
    const { imageObject, link } = data;

    if (imageObject.file?.size > 4 * 1024 * 1024) {
      setError("imageObject", {
        message: "*Please ensure the maximum image size is 4MB.",
        type: "manual",
      });

      return;
    }

    if (Boolean(link.text) && !link.url) {
      setError("link[url]", {
        message: "*Please provide a url for the link button",
        type: "manual",
      });

      return;
    }

    if (!link.text && Boolean(link.url)) {
      setError("link[text]", {
        message: "*Please provide a display text for the link button",
        type: "manual",
      });

      return;
    }

    if (link.url && !(isValidHttpUrl(link.url) || link.url.startsWith("/"))) {
      setError("link[url]", {
        message: "*Invalid URL. Please ensure links start with 'https://' or '/'",
        type: "manual",
      });

      return;
    }

    onSubmit(data);
    reset();
  };
  const handleCancelClick = () => {
    reset();

    if (!isCreate) {
      onCancel();
    }
  };

  return (
    <>
      <Grid className={classes.spacing} container spacing={1}>
        <Grid item lg={3} md={4} xs={12}>
          <Typography gutterBottom variant="subtitle1">
            Image (max: 4 MB)
          </Typography>
          <Controller
            as={<LogoUpload />}
            control={control}
            helperText={errors?.imageObject?.message}
            name="imageObject"
            rules={{ validate: value => Boolean(value.image || value.file) || "*required" }}
          />
        </Grid>

        <Grid item lg={9} md={8} xs={12}>
          <Typography gutterBottom variant="subtitle1">
            Header
          </Typography>
          <TextField className={classes.spacing} fullWidth inputRef={register} name="header" variant="outlined" />

          <Typography gutterBottom variant="subtitle1">
            Subheader
          </Typography>
          <TextField fullWidth inputRef={register} name="description" variant="outlined" />
        </Grid>

        <Grid item lg={3} md={4} xs={12}>
          <Typography gutterBottom variant="subtitle1">
            Link button{" "}
            <Typography color="textSecondary" component="span" variant="body2">
              (optional)
            </Typography>
          </Typography>
          <TextField
            error={Boolean(errors?.link?.text)}
            fullWidth
            helperText={errors?.link?.text?.message}
            inputRef={register}
            name="link[text]"
            onChange={() => clearErrors("link")}
            variant="outlined"
          />
        </Grid>
        <Grid item lg={9} md={8} xs={12}>
          <Typography gutterBottom variant="subtitle1">
            URL link{" "}
            <Typography color="textSecondary" component="span" variant="body2">
              (optional)
            </Typography>
          </Typography>
          <TextField
            error={Boolean(errors?.link?.url)}
            fullWidth
            helperText={errors?.link?.url?.message}
            inputRef={register}
            name="link[url]"
            onChange={() => clearErrors("link")}
            variant="outlined"
          />
        </Grid>
      </Grid>

      <Box align="end">
        <Button className={classes.cancelButton} color="primary" onClick={handleCancelClick}>
          Cancel
        </Button>
        <Button color="primary" onClick={handleSubmit(handleSubmitClick)} variant={isCreate ? "contained" : "outlined"}>
          {isCreate ? "Add" : "Update"} Slide
        </Button>
      </Box>
    </>
  );
};

CarouselForm.defaultProps = {
  isCreate: false,
  onCancel: () => {},
  slide: {
    description: "",
    header: "",
    imageObject: {
      file: null,
      image: "",
    },
    link: {
      text: "",
      url: "",
    },
  },
};

CarouselForm.propTypes = {
  isCreate: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  slide: PropTypes.object,
};

export default CarouselForm;
