import { Box, Button, FormControl, FormLabel, makeStyles, TextField } from "@material-ui/core";
import React from "react";
import { Controller, useForm } from "react-hook-form";

import LinkAutocomplete from "./LinkAutocomplete";

const useStyles = makeStyles(theme => ({
  cancelButton: {
    marginLeft: "auto",
    width: 100,
  },
  linkLabel: {
    marginBottom: theme.spacing(2),
    width: "50%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  saveButton: {
    marginLeft: theme.spacing(1),
    width: 100,
  },
}));

const NavLinkForm = ({ link, onCloseForm, onSubmit }) => {
  const classes = useStyles();
  const { handleSubmit, errors, register, control } = useForm({
    defaultValues: {
      name: link?.name ?? "",
      value: link?.value ?? "",
    },
  });

  return (
    <FormControl component="fieldset" fullWidth margin="normal">
      <FormLabel component="legend" htmlFor="linkLabel">
        Label
      </FormLabel>
      <TextField
        className={classes.linkLabel}
        error={Boolean(errors?.name)}
        helperText={errors?.name?.message}
        id="linkLabel"
        inputRef={register({ required: "*required" })}
        margin="dense"
        name="name"
        type="text"
        variant="outlined"
      />

      <FormLabel component="legend" htmlFor="linkValue">
        URL
      </FormLabel>
      <Controller
        as={LinkAutocomplete}
        control={control}
        error={Boolean(errors?.value)}
        helperText={errors?.value?.message}
        name="value"
        rules={{
          required: "*required",
        }}
      />

      <Box display="flex" marginTop={2}>
        <Button className={classes.cancelButton} color="primary" onClick={onCloseForm} variant="outlined">
          Cancel
        </Button>
        <Button className={classes.saveButton} color="primary" onClick={handleSubmit(onSubmit)} variant="contained">
          Save
        </Button>
      </Box>
    </FormControl>
  );
};

export default NavLinkForm;
