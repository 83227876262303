import React from "react";
import { Redirect } from "react-router-dom";

import Routes from "../../../Constants/Routes";

const PaymentStripeCancel = () => {
  return <Redirect to={Routes.PAYMENTS} />;
};

export default PaymentStripeCancel;
