import { gql } from "@apollo/client";

import LIST_FRAGMENT from "../fragment/list";

const listCreate = gql`
  mutation ListCreate($input: ListNewInput!, $image: FileUpload) {
    listCreate(input: $input, image: $image) {
      ...ListFragment
    }
  }
  ${LIST_FRAGMENT}
`;

export default listCreate;
