import { CarouselSlide } from "@arowana/ui";
import { Box, Card, IconButton, makeStyles, Menu, MenuItem, Typography } from "@material-ui/core";
import {
  DeleteOutline as DeleteOutlineIcon,
  Edit as EditIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
  MoreHoriz as MoreHorizIcon,
} from "@material-ui/icons";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useState } from "react";

import CarouselForm from "./CarouselForm";

const useStyles = makeStyles(theme => ({
  categoryMenu: {
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
    "fontSize": theme.typography.body2.fontSize,
  },
  fullMoreButton: {
    "&:hover": { backgroundColor: "rgba(245, 245, 245, 0.7)" },
    "backgroundColor": "rgba(245, 245, 245, 0.7)",
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  moreButton: {
    padding: theme.spacing(1),
    position: "absolute",
    right: 0,
    top: 0,
  },
  slideContainer: {
    backgroundColor: theme.palette.grey[100],
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
  },
  slideContent: {
    position: "relative",
  },
}));
const SLIDE_MENU_ACTION = {
  DELETE: "DELETE",
  EDIT: "EDIT",
  MOVEDOWN: "MOVEDOWN",
  MOVEUP: "MOVEUP",
};
const SlideDisplay = ({
  displayType,
  index,
  isLastSlide,
  onDeleteSlideClick,
  onEditSlideClick,
  onMoveDownSlideClick,
  onMoveUpSlideClick,
  slide,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const isFull = displayType === "full";
  const handleClickMoreButton = event => setAnchorEl(event.currentTarget);
  const handleCloseMenu = () => setAnchorEl(null);
  const handleMenuClick = actionType => {
    handleCloseMenu();

    switch (actionType) {
      case SLIDE_MENU_ACTION.DELETE:
        onDeleteSlideClick();
        break;

      case SLIDE_MENU_ACTION.EDIT:
        onEditSlideClick();
        break;

      case SLIDE_MENU_ACTION.MOVEDOWN:
        onMoveDownSlideClick();
        break;

      case SLIDE_MENU_ACTION.MOVEUP:
        onMoveUpSlideClick();
        break;

      default:
    }
  };

  return (
    <Box className={classes.slideContent}>
      <CarouselSlide displayType={displayType} slide={slide} />

      <IconButton
        className={clsx(classes.moreButton, { [classes.fullMoreButton]: isFull })}
        color="primary"
        onClick={handleClickMoreButton}
      >
        <MoreHorizIcon />
      </IconButton>

      <Menu anchorEl={anchorEl} keepMounted onClose={handleCloseMenu} open={Boolean(anchorEl)}>
        <MenuItem
          className={classes.categoryMenu}
          disabled={index === 0}
          onClick={() => handleMenuClick(SLIDE_MENU_ACTION.MOVEUP)}
        >
          <KeyboardArrowUpIcon className={classes.icon} color="primary" />
          Move up
        </MenuItem>
        <MenuItem
          className={classes.categoryMenu}
          disabled={isLastSlide}
          onClick={() => handleMenuClick(SLIDE_MENU_ACTION.MOVEDOWN)}
        >
          <KeyboardArrowDownIcon className={classes.icon} color="primary" />
          Move down
        </MenuItem>
        <MenuItem className={classes.categoryMenu} onClick={() => handleMenuClick(SLIDE_MENU_ACTION.EDIT)}>
          <EditIcon className={classes.icon} color="primary" fontSize="small" />
          Edit
        </MenuItem>
        <MenuItem className={classes.categoryMenu} onClick={() => handleMenuClick(SLIDE_MENU_ACTION.DELETE)}>
          <DeleteOutlineIcon className={classes.icon} color="primary" fontSize="small" />
          Delete
        </MenuItem>
      </Menu>
    </Box>
  );
};
const Slide = ({
  displayType,
  index,
  isEditing,
  isLastSlide,
  onCloseSlideForm,
  onDeleteSlide,
  onMoveDownSlide,
  onMoveUpSlide,
  onOpenSlideForm,
  onUpdateSlide,
  slide,
}) => {
  const classes = useStyles();
  const handleOpenSlideForm = () => onOpenSlideForm(index);
  const handleCloseSlideForm = () => onCloseSlideForm();
  const handleDeleteSlideClick = () => onDeleteSlide(index);
  const handleModeUpSlideClick = () => onMoveUpSlide(index);
  const handleModeDownSlideClick = () => onMoveDownSlide(index);
  const handleUpdateSlide = data => {
    handleCloseSlideForm();
    onUpdateSlide(data, index);
  };

  return (
    <Card className={classes.slideContainer} variant="outlined">
      {isEditing ? (
        <>
          <Typography color="textSecondary" gutterBottom variant="caption">
            Slide {index + 1}
          </Typography>
          <CarouselForm onCancel={handleCloseSlideForm} onSubmit={handleUpdateSlide} slide={slide} />
        </>
      ) : (
        <SlideDisplay
          displayType={displayType}
          index={index}
          isLastSlide={isLastSlide}
          onDeleteSlideClick={handleDeleteSlideClick}
          onEditSlideClick={handleOpenSlideForm}
          onMoveDownSlideClick={handleModeDownSlideClick}
          onMoveUpSlideClick={handleModeUpSlideClick}
          slide={slide}
        />
      )}
    </Card>
  );
};

SlideDisplay.propTypes = {
  displayType: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  isLastSlide: PropTypes.bool.isRequired,
  onDeleteSlideClick: PropTypes.func.isRequired,
  onEditSlideClick: PropTypes.func.isRequired,
  onMoveDownSlideClick: PropTypes.func.isRequired,
  onMoveUpSlideClick: PropTypes.func.isRequired,
  slide: PropTypes.object.isRequired,
};

Slide.propTypes = {
  displayType: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  isEditing: PropTypes.bool.isRequired,
  isLastSlide: PropTypes.bool.isRequired,
  onCloseSlideForm: PropTypes.func.isRequired,
  onDeleteSlide: PropTypes.func.isRequired,
  onMoveDownSlide: PropTypes.func.isRequired,
  onMoveUpSlide: PropTypes.func.isRequired,
  onOpenSlideForm: PropTypes.func.isRequired,
  onUpdateSlide: PropTypes.func.isRequired,
  slide: PropTypes.object.isRequired,
};

export default Slide;
