import { makeStyles, Typography } from "@material-ui/core";
import { ErrorOutline as ErrorOutlineIcon } from "@material-ui/icons";
import Alert from "@material-ui/lab/Alert";
import clsx from "clsx";
import React from "react";

import { Dollar } from "../Dollar";

const useStyles = makeStyles(theme => ({
  minAlert: {
    alignItems: "center",
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(0, 1),
  },
  tillMinimum: {
    fontWeight: "bold",
  },
}));

interface MinimumAlertProps {
  className?: string;
  dollarToMinimum: number;
}

export const MinimumAlert = ({ className, dollarToMinimum }: MinimumAlertProps) => {
  const classes = useStyles();
  const classnames = clsx(className, classes.minAlert);

  return (
    <Alert className={classnames} icon={<ErrorOutlineIcon />} severity="warning" variant="outlined">
      <Typography variant="subtitle2">Almost there!</Typography>
      <Typography variant="caption">
        You are{" "}
        <Dollar
          amount={dollarToMinimum}
          className={classes.tillMinimum}
          color="secondary"
          component="span"
          variant="caption"
        />{" "}
        from reaching the order minimum.
      </Typography>
    </Alert>
  );
};

export default MinimumAlert;
