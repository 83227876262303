import { ISOWeekDay } from "@arowana/graphql";
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel } from "@material-ui/core";
import moment from "moment";

interface FulfillmentDaysProps {
  disabled: boolean;
  helperText: string;
  onChange?: (value: ISOWeekDay[]) => void;
  value?: ISOWeekDay[];
}

const FulfillmentDays = ({ disabled, helperText, onChange, value }: FulfillmentDaysProps) => {
  const checked = new Set(value);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const toggledFulfillmentDay = parseInt(event.target.value as ISOWeekDay);

    if (event.target.checked) {
      checked.add(toggledFulfillmentDay);
    } else {
      checked.delete(toggledFulfillmentDay);
    }

    onChange(Array.from(checked));
  };

  return (
    <FormControl disabled={disabled} fullWidth>
      <FormLabel htmlFor="fulfillmentDays">Fulfillment days</FormLabel>
      <FormGroup row>
        {Array.from({ length: 7 }, (_, i) => i + 1).map(fulfillmentDay => (
          <FormControlLabel
            key={fulfillmentDay}
            control={
              <Checkbox
                checked={checked.has(fulfillmentDay)}
                color="primary"
                onChange={handleChange}
                value={fulfillmentDay}
              />
            }
            label={moment(fulfillmentDay, "E").format("dddd")}
          />
        ))}
      </FormGroup>
      {helperText && <FormHelperText error>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default FulfillmentDays;
