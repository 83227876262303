import { Button, makeStyles, Tooltip, Typography } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles(theme => ({
  button: {
    [theme.breakpoints.up("sm")]: { marginLeft: "auto" },
    [theme.breakpoints.only("xs")]: {
      marginTop: theme.spacing(2),
      width: "100%",
    },
  },
  tooltip: {
    backgroundColor: theme.palette.background.default,
    border: `1px solid ${theme.palette.grey[200]}`,
    color: theme.palette.text.primary,
  },
  tooltipArrow: {
    "&:before": {
      border: `1px solid ${theme.palette.grey[200]}`,
    },
    "color": theme.palette.background.default,
  },
}));

interface DisabledTooltipButtonProps {
  buttonText: string;
  content: string;
  title: string;
}

export function DisabledTooltipButton({ buttonText, content, title }: DisabledTooltipButtonProps) {
  const classes = useStyles();

  return (
    <Tooltip
      arrow
      classes={{
        arrow: classes.tooltipArrow,
        tooltip: classes.tooltip,
      }}
      enterTouchDelay={0}
      leaveDelay={3000}
      leaveTouchDelay={3000}
      title={
        <>
          <Typography gutterBottom variant="subtitle2">
            {title}
          </Typography>
          <Typography variant="body2">{content}</Typography>
        </>
      }
    >
      <Button className={classes.button} size="small" variant="outlined">
        {buttonText}
      </Button>
    </Tooltip>
  );
}

export default DisabledTooltipButton;
