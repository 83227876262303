import { Box, makeStyles, Tooltip, Typography } from "@material-ui/core";
import InputBase from "@material-ui/core/InputBase";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import SearchIcon from "@material-ui/icons/Search";
import React from "react";

const useStyles = makeStyles(theme => ({
  input: {
    display: "flex",
    flex: 12,
    flexDirection: "column",
    justifyContent: "center",
  },
  inputText: {
    fontSize: theme.typography.body2.fontSize,
  },
  search: {
    backgroundColor: "white",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    margin: theme.spacing(1),
    opacity: 0.9,
    width: "60%",
  },
  searchIcon: {
    color: theme.palette.grey[600],
    display: "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    paddingLeft: theme.spacing(1),
  },
  tooltip: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
  },
  tooltipArrow: {
    color: theme.palette.background.paper,
  },
  tooltipContainer: {
    backgroundColor: theme.palette.warning.light,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(0.5),
    zIndex: 1,
  },
  tooltipHint: {
    marginTop: theme.spacing(1.5),
  },
  tooltipIcon: {
    cursor: "pointer",
    verticalAlign: "text-bottom",
  },
}));
const CreateZoneTooltip = () => {
  const classes = useStyles();

  return (
    <Tooltip
      arrow
      classes={{
        arrow: classes.tooltipArrow,
        tooltip: classes.tooltip,
      }}
      enterTouchDelay={0}
      leaveDelay={2000}
      leaveTouchDelay={2000}
      title={
        <>
          <Typography gutterBottom variant="subtitle1">
            Creating a geofence:
          </Typography>
          <ol>
            <li>Select the draw tool to create points on the map.</li>
            <li>Draw the perimeter of the zone and ensure to close the shape.</li>
            <li>Right click to delete a specific point.</li>
            <li>Drag any points to expand or collapse your service area.</li>
          </ol>
        </>
      }
    >
      <InfoOutlinedIcon className={classes.tooltipIcon} fontSize="small" />
    </Tooltip>
  );
};
const SearchInput = () => {
  const classes = useStyles();

  return (
    <Box alignItems="center" display="flex">
      <div className={classes.search}>
        <div className={classes.searchIcon}>
          <SearchIcon />
        </div>
        <div className={classes.input}>
          <InputBase className={classes.inputText} placeholder="Search region" type="search" />
        </div>
      </div>
      <div className={classes.tooltipContainer}>
        <CreateZoneTooltip />
      </div>
    </Box>
  );
};

export default SearchInput;
