import { gql, useMutation } from "@apollo/client";
import { PaymentMethod } from "@arowana/graphql";
import { PaymentCardElement } from "@arowana/ui";
import { DATALAYER } from "@arowana/util";
import { Box, Button, CircularProgress, Grid } from "@material-ui/core";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useState } from "react";

import { notificationVar } from "../cache/notificationPolicy";

const PAYMENT_SETUP_INTENT = gql`
  mutation AccountSetupIntent($input: AccountSetupIntentInput!) {
    accountSetupIntent(input: $input) {
      clientSecret
    }
  }
`;

const PAYMENT_CREATE = gql`
  mutation AccountPaymentMethodCreate($id: ID!, $input: AccountPaymentMethodCreateInput!) {
    accountPaymentMethodCreate(id: $id, input: $input) {
      id
      card {
        brand
        expiryMonth
        expiryYear
        last4
      }
    }
  }
`;

type PaymentMethodFormViewProps = {
  account: { id: string };
  onAddCreditCard?: (paymentId: Partial<PaymentMethod>) => void;
  onCancelAddCreditCard?: () => void;
};

const PaymentMethodForm = ({
  account,
  className,
  onAddCreditCard,
  onCancelAddCreditCard,
}: PaymentMethodFormViewProps) => {
  const [loading, setLoading] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  const [accountSetupIntent] = useMutation(PAYMENT_SETUP_INTENT, {
    context: { source: DATALAYER },
    onError() {
      if (onCancelAddCreditCard) {
        onCancelAddCreditCard();
      }

      setLoading(false);
    },
  });

  const [accountPaymentMethodCreate] = useMutation(PAYMENT_CREATE, {
    context: { source: DATALAYER },
    onCompleted: data => {
      setLoading(false);

      if (onCancelAddCreditCard) {
        onCancelAddCreditCard();
      }

      notificationVar({
        message: "Payment method added!",
        severity: "success",
      });

      if (onAddCreditCard) {
        onAddCreditCard(data?.accountPaymentMethodCreate);
      }
    },
    onError: () => {
      setLoading(false);

      if (onCancelAddCreditCard) {
        onCancelAddCreditCard();
      }
    },
    update: (cache, { data: { accountPaymentMethodCreate: paymentMethod } }) => {
      const result = cache.readQuery<{ account }>({
        query: gql`
          query Account($id: ID!) {
            account(id: $id) {
              id
              __typename
            }
          }
        `,
        variables: {
          id: account.id,
        },
      });

      if (result?.account) {
        cache.modify({
          fields: {
            paymentMethods: (existingPaymentMethodRefs = []) => {
              const paymentMethodRef = cache.writeFragment({
                data: paymentMethod,
                fragment: gql`
                  fragment NewPaymentMethod on PaymentMethod {
                    id
                    __typename
                  }
                `,
              });

              return [...existingPaymentMethodRefs, paymentMethodRef];
            },
          },
          id: cache.identify(result.account),
        });
      }
    },
  });

  const handleSaveCreditCard = async () => {
    if (!elements || !stripe) {
      return;
    }
    setLoading(true);

    const { data: accountSetupIntentResponse } = await accountSetupIntent({
      variables: {
        input: {
          id: account.id,
        },
      },
    });

    const { error, setupIntent } = await stripe.confirmCardSetup(
      accountSetupIntentResponse?.accountSetupIntent?.clientSecret,
      {
        payment_method: {
          card: elements.getElement(CardElement),
        },
      },
    );

    if (error) {
      setLoading(false);

      if (onCancelAddCreditCard) {
        onCancelAddCreditCard();
      }

      notificationVar({
        message: error.message,
        severity: "error",
      });
    } else if (setupIntent.status === "succeeded") {
      accountPaymentMethodCreate({
        variables: {
          id: account.id,
          input: { paymentMethodId: setupIntent.payment_method },
        },
      });
    }
  };

  return (
    <>
      <PaymentCardElement />
      <Grid container spacing={1} justifyContent="flex-end">
        {onCancelAddCreditCard && (
          <Button variant="outlined" color="primary" onClick={onCancelAddCreditCard}>
            Cancel
          </Button>
        )}
        <Box ml={1}>
          <Button variant="contained" color="primary" onClick={handleSaveCreditCard} disabled={loading}>
            {loading ? <CircularProgress color="inherit" size={24} /> : "Save"}
          </Button>
        </Box>
      </Grid>
    </>
  );
};

export default PaymentMethodForm;
