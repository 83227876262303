import { gql, useMutation, useQuery } from "@apollo/client";
import { ProductUnit, WholesaleList, WholesaleListCreateInput, WholesaleListUpdateInput } from "@arowana/graphql";
import { DATALAYER } from "@arowana/util";
import { useWorker, WORKER_STATUS } from "@koale/useworker";
import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import moment from "moment";
import { useCallback, useContext, useEffect, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";

import { notificationVar } from "../../../cache/notificationPolicy";
import ConfirmationModal from "../../../components/ConfirmationModal";
import FormCard from "../../../components/FormCard";
import Loader from "../../../components/Loader";
import PageHeader from "../../../components/PageHeader";
import Routes from "../../../Constants/Routes";
import { FlagsmithContext } from "../../context/FlagsmithContext";
import ClientsList from "../components/ClientsList";
import OverridesList, { FormMode } from "../components/OverridesList";

const LIST_FRAGMENT = gql`
  fragment WholesaleListFragment on WholesaleList {
    id
    clients {
      id
      name
      phone
      repAccountId
    }
    createdAt
    description
    name
    updatedAt
    overrides {
      id
      active
      featured
      hidePrice
      name
      price
      productId
      sortOrder
      variantId
      variant {
        caseSize
        id
        name
        unit
      }
    }
  }
`;

const WHOLESALE_LIST = gql`
  query WholesaleList($id: ID!) {
    wholesaleList(id: $id) {
      ...WholesaleListFragment
    }
  }
  ${LIST_FRAGMENT}
`;

const WHOLESALE_LIST_UPDATE = gql`
  mutation WholesaleListUpdate($input: WholesaleListUpdateInput!) {
    wholesaleListUpdate(input: $input) {
      ...WholesaleListFragment
    }
  }
  ${LIST_FRAGMENT}
`;

const WHOLESALE_LIST_CREATE = gql`
  mutation WholesaleListCreate($input: WholesaleListCreateInput!) {
    wholesaleListCreate(input: $input) {
      ...WholesaleListFragment
    }
  }
  ${LIST_FRAGMENT}
`;

const WHOLESALE_LIST_DELETE = gql`
  mutation WholesaleListDelete($id: ID!) {
    wholesaleListDelete(id: $id)
  }
`;

const useStyles = makeStyles(theme => ({
  subHeader: {
    marginBottom: theme.spacing(2),
  },
}));

export type ClientProps = {
  clientId: string;
  name: string;
  phone: string;
  repAccountId: string;
};

export interface OverrideFormProps {
  active: boolean;
  caseSize: number;
  hidePrice: boolean;
  overrideId?: string;
  price: number;
  productId: string;
  productName: string;
  unit: ProductUnit;
  variantId: string;
  variantName: string;
}
export interface OverrideFieldArrayProps extends OverrideFormProps {
  fieldId: string;
}

type FormProps = {
  clients: ClientProps[];
  description: string;
  name: string;
  overrides: OverrideFormProps[];
};

const defaultValues: FormProps = {
  clients: [],
  description: "",
  name: "",
  overrides: [],
};

const toFormData = (wholesaleList: WholesaleList) => ({
  clients:
    wholesaleList.clients?.map(({ id, name, phone, repAccountId }) => ({
      clientId: id,
      name,
      phone,
      repAccountId,
    })) ?? [],
  description: wholesaleList.description,
  name: wholesaleList.name,
  overrides:
    wholesaleList.overrides?.reduce((acc, override) => {
      if (override?.name && override?.variant) {
        const {
          active,
          featured,
          hidePrice,
          id,
          name: productName,
          price,
          productId,
          sortOrder,
          variantId,
          variant,
        } = override;
        const { caseSize, name: variantName, unit } = variant ?? {};

        const formOverride: OverrideFormProps = {
          active,
          caseSize,
          featured,
          hidePrice,
          overrideId: id,
          price: parseFloat((price / 100).toFixed(2)),
          productId,
          productName,
          sortOrder,
          unit,
          variantId,
          variantName,
        };
        acc.push(formOverride);

        return acc;
      } else {
        return acc;
      }
    }, []) ?? [],
});

const formDataToCreateInput = formData => {
  return {
    variables: {
      input: {
        clientIds: formData.clients?.map(({ clientId }: ClientProps) => clientId) ?? [],
        description: formData.description,
        name: formData.name,
        overrides: formData.overrides.map(({ featured, hidePrice, price, productId, variantId }, index) => ({
          featured,
          ...(hidePrice && { hidePrice }),
          price: Math.round(price * 100),
          productId,
          sortOrder: index,
          variantId,
        })),
      } as WholesaleListCreateInput,
    },
  };
};

const formDataToUpdateInput = (data, formData) => {
  const initialOverrides =
    data?.wholesaleList?.overrides?.reduce((acc, cur) => {
      return {
        ...acc,
        [cur.id]: { hidePrice: cur.hidePrice, price: cur.price },
      };
    }, {}) ?? {};

  const overridesCreate = [];
  const overridesUpdate = [];
  const overridesDelete = [];

  formData.overrides.forEach(
    ({ featured, sortOrder, productName, variantName, overrideId, price, productId, hidePrice, variantId }, index) => {
      const priceInCents = Math.round(price * 100);

      if (!(overrideId in initialOverrides)) {
        overridesCreate.push({
          ...(hidePrice && { hidePrice }),
          price: priceInCents,
          productId,
          sortOrder: index,
          variantId,
        });
      } else if (productName === null || variantName === null) {
        // silently remove overrides that no longer have product/variant names
        overridesDelete.push({ reason: "removed due to missing product/variant", variantId });
      } else {
        const updateFields: { id: string; featured: boolean; price?: number; hidePrice?: boolean; sortOrder?: number } =
          {
            id: overrideId,
          };
        const initial = initialOverrides[overrideId];
        let dirty = false;

        if (initial.price !== priceInCents) {
          dirty = true;
          updateFields.price = priceInCents;
        }

        if (initial.featured !== featured) {
          dirty = true;
          updateFields.featured = featured;
        }

        if (initial.hidePrice !== hidePrice) {
          dirty = true;
          updateFields.hidePrice = hidePrice;
        }

        if (initial.sortOrder !== index) {
          dirty = true;
          updateFields.sortOrder = index;
        }

        if (dirty) {
          overridesUpdate.push(updateFields);
        }
      }
    },
  );

  Object.keys(initialOverrides).forEach(id => {
    if (!formData.overrides.some(({ overrideId }) => overrideId === id)) {
      overridesDelete.push({ id });
    }
  });

  return {
    variables: {
      input: {
        clientIds: formData.clients?.map(({ clientId }: ClientProps) => clientId) ?? [],
        description: formData.description,
        id: data?.wholesaleList?.id,
        name: formData.name,
        ...(overridesCreate.length > 0 && { overridesCreate }),
        ...(overridesDelete.length > 0 && { overridesDelete }),
        ...(overridesUpdate.length > 0 && { overridesUpdate }),
      } as WholesaleListUpdateInput,
    },
  };
};

const WholesaleListPage = ({ match, history }) => {
  const classes = useStyles();
  const flagsmith = useContext(FlagsmithContext);
  const hasWholesale = flagsmith.hasFeature("b2b");

  if (!hasWholesale) {
    history.push(Routes.PRODUCT_LIST);
  }

  const wholesaleListId = match?.params?.id;
  const isCreateWholesaleList = match?.url === Routes.WHOLESALE_LIST_CREATE;
  const [duplicateInputForm, setDuplicateInputForm] = useState(null);
  const [deletePromptOpen, setDeletePromptOpen] = useState(false);

  const {
    control,
    formState: { errors, isDirty },
    getValues,
    handleSubmit,
    register,
    reset,
    setValue,
  } = useForm<FormProps>({
    defaultValues,
  });

  // Delegate heavy computation to web worker
  const [populateFormData, { status: formDataStatus }] = useWorker(toFormData);
  const [populateUpdateInput, { status: updateInputStatus }] = useWorker(formDataToUpdateInput);
  const isProcessingData = formDataStatus === WORKER_STATUS.RUNNING || updateInputStatus === WORKER_STATUS.RUNNING;

  const {
    fields: overridesList,
    prepend,
    remove,
    move,
  } = useFieldArray({
    control,
    keyName: "fieldId",
    name: "overrides",
  });

  const { data, loading } = useQuery(WHOLESALE_LIST, {
    context: { source: DATALAYER },
    fetchPolicy: "network-only",
    onCompleted: ({ wholesaleList }: { wholesaleList: WholesaleList }) => populateFormData(wholesaleList).then(reset),
    skip: isCreateWholesaleList,
    variables: { id: wholesaleListId },
  });

  const [wholesaleListUpdate, { loading: wholesaleListUpdateLoading }] = useMutation(WHOLESALE_LIST_UPDATE, {
    context: { source: DATALAYER },
    onCompleted: () => {
      notificationVar({
        message: "Price list updated!",
        severity: "success",
      });
    },
  });

  const [wholesaleListCreate, { loading: wholesaleListCreateLoading }] = useMutation(WHOLESALE_LIST_CREATE, {
    context: { source: DATALAYER },
    onCompleted: data => {
      notificationVar({
        message: "Price list created!",
        severity: "success",
      });
      history.replace(Routes.WHOLESALE_LIST_DETAILS.replace(":id", data.wholesaleListCreate.id));
    },
  });

  const [wholesaleListDelete, { loading: wholesaleListDeleteLoading }] = useMutation(WHOLESALE_LIST_DELETE, {
    context: { source: DATALAYER },
    onCompleted: () => {
      notificationVar({
        message: "Price list deleted!",
        severity: "success",
      });
      history.push(Routes.WHOLESALE_LISTS);
    },
  });

  useEffect(() => {
    if (duplicateInputForm) {
      setValue("description", duplicateInputForm.description);
      setValue("clients", []);
      setValue("overrides", duplicateInputForm.overrides);
      setValue("name", `${duplicateInputForm.name} - copy`, { shouldDirty: true });
    }
  }, [duplicateInputForm, setValue]);

  const handleDuplicateWholesaleList = useCallback(() => {
    setDuplicateInputForm(getValues());
    history.push(Routes.WHOLESALE_LIST_CREATE);
  }, []);
  const handleDeleteWholesaleListClick = useCallback(
    () => wholesaleListDelete({ variables: { id: wholesaleListId } }),
    [wholesaleListId],
  );
  const onSubmit = async (formData: FormProps) => {
    if (isCreateWholesaleList) {
      const numOverrides = formData.overrides?.length;

      if (!numOverrides) {
        notificationVar({
          message: "Price list must have at least 1 item!",
          severity: "error",
        });

        return;
      }

      wholesaleListCreate(formDataToCreateInput(formData));
    } else {
      const updateData = await populateUpdateInput(data, formData);
      wholesaleListUpdate(updateData);
    }
  };

  return (
    <>
      <Loader loading={loading || isProcessingData || wholesaleListUpdateLoading} />
      <PageHeader
        stickyHeader
        title={isCreateWholesaleList ? "Create price list" : "Edit price list"}
        primaryActions={
          <>
            <Button color="primary" disabled={!isDirty} onClick={() => reset()} variant="outlined">
              Discard
            </Button>
            <Button
              color="primary"
              disabled={!isDirty || wholesaleListUpdateLoading || wholesaleListCreateLoading}
              onClick={handleSubmit(onSubmit)}
              variant="contained"
            >
              Save
            </Button>
          </>
        }
      />
      {!isCreateWholesaleList && (
        <Grid alignItems="center" container justifyContent="space-between" className={classes.subHeader}>
          <Grid item>
            <Typography
              title={moment.utc(data?.wholesaleList?.updatedAt).format("LL")}
              color="textSecondary"
              variant="body2"
            >
              Last updated: {moment.utc(data?.wholesaleList?.updatedAt).fromNow()}
            </Typography>
          </Grid>
          <Grid item>
            <Grid container justifyContent="flex-end">
              <Button color="primary" onClick={handleDuplicateWholesaleList}>
                Duplicate list
              </Button>
              <Button color="secondary" onClick={() => setDeletePromptOpen(true)}>
                Delete
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}

      <FormCard>
        <FormControl fullWidth margin="none">
          <FormLabel htmlFor="name">Name</FormLabel>
          <TextField
            autoComplete="off"
            error={Boolean(errors?.name)}
            fullWidth
            helperText={errors?.name?.message}
            id="name"
            inputRef={register({ required: "*required" })}
            margin="dense"
            name="name"
            variant="outlined"
          />
        </FormControl>

        <FormControl fullWidth margin="normal">
          <FormLabel htmlFor="description">Description</FormLabel>
          <TextField
            aria-describedby="description-helper"
            autoComplete="off"
            error={Boolean(errors?.description)}
            fullWidth
            helperText={errors?.description?.message}
            id="description"
            inputRef={register({ required: "*required" })}
            margin="dense"
            multiline
            name="description"
            rows={2}
            variant="outlined"
          />
          <FormHelperText id="description-helper">
            This description is for internal uses only. It will not be displayed to your wholesale customers.
          </FormHelperText>
        </FormControl>
      </FormCard>

      <FormCard title="Products in price list">
        <FormControl fullWidth margin="normal">
          <OverridesList
            errors={errors}
            mode={FormMode.WHOLESALE}
            overrides={overridesList as OverrideFieldArrayProps[]}
            prepend={prepend}
            register={register}
            remove={remove}
            move={move}
          />
        </FormControl>
      </FormCard>

      <FormCard title="Businesses with access">
        <FormControl fullWidth margin="normal">
          <Controller
            as={({ onChange, value }) => <ClientsList onChange={onChange} value={value} />}
            control={control}
            name="clients"
            defaultValue={[]}
          />
        </FormControl>
      </FormCard>
      <ConfirmationModal
        cancelRequestButtonText="Cancel"
        confirmRequestButtonText="Delete"
        confirmRequestOnGoing={wholesaleListDeleteLoading}
        isDangerAction
        modalContent="Are you sure you want to delete the price list?"
        modalNote="Note: This action cannot be reversed."
        modalTitle="Delete price list"
        onCloseModal={() => setDeletePromptOpen(false)}
        onConfirmClick={handleDeleteWholesaleListClick}
        shouldOpenModal={deletePromptOpen}
      />
    </>
  );
};

export default WholesaleListPage;
