import { Box, Fab, Grow, makeStyles } from "@material-ui/core";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import { forwardRef } from "react";
import MultiCarousel, { ButtonGroupProps, CarouselProps } from "react-multi-carousel";

const DEFAULT_FAB_SIZE = 26;
const OFFSET = DEFAULT_FAB_SIZE / 2;
const useStyles = makeStyles(theme => ({
  buttonContainer: {
    left: -OFFSET / 2,
    pointerEvents: "none",
    position: "absolute",
    top: `calc(50% - ${OFFSET}px)`,
    transition: "top 100ms",
    width: `calc(100% + ${OFFSET}px)`,
  },
  carouselRoot: {
    position: "relative",
  },
  fab: {
    color: theme.palette.primary.main,
    height: DEFAULT_FAB_SIZE,
    minHeight: DEFAULT_FAB_SIZE,
    pointerEvents: "auto",
    width: DEFAULT_FAB_SIZE,
  },
}));

const ButtonGroup = ({ next, previous, carouselState }: ButtonGroupProps) => {
  const { currentSlide, slidesToShow, totalItems } = carouselState;
  const classes = useStyles();
  const showPrev = currentSlide > 0;
  const showNext = currentSlide + slidesToShow < totalItems;

  return (
    <Box className={classes.buttonContainer} display="flex" justifyContent="space-between">
      <Grow in={showPrev}>
        <Fab aria-label="previous" className={classes.fab} color="default" onClick={() => previous()} size="small">
          <ChevronLeft />
        </Fab>
      </Grow>
      <Grow in={showNext}>
        <Fab aria-label="next" className={classes.fab} color="default" onClick={() => next()} size="small">
          <ChevronRight />
        </Fab>
      </Grow>
    </Box>
  );
};

export const Carousel = forwardRef<MultiCarousel, CarouselProps>(({ children, responsive, ...rest }, ref) => {
  const classes = useStyles();

  return (
    <div className={classes.carouselRoot}>
      <MultiCarousel
        arrows={false}
        customButtonGroup={<ButtonGroup />}
        draggable
        keyBoardControl={false}
        ref={ref}
        renderButtonGroupOutside
        responsive={responsive}
        swipeable
        {...rest}
      >
        {children}
      </MultiCarousel>
    </div>
  );
});

Carousel.displayName = "Carousel";

export default Carousel;
