import { List } from "@arowana/graphql";

import ListAutocomplete, { ListSearch } from "../../products/components/ListAutocomplete";

interface BlacklistProps {
  value?: List;
  onChange?: (val: List | Record<string, never>) => void;
}

const Blacklist = ({ value, onChange }: BlacklistProps) => {
  const handleChooseBlacklist = (list: ListSearch) => onChange({ id: list.id, name: list.name } as List);
  const handleUnSelect = () => onChange({});

  return <ListAutocomplete onChange={handleChooseBlacklist} onUnSelect={handleUnSelect} selectedList={value} />;
};

export default Blacklist;
