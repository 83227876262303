/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApolloCache, ApolloError, DocumentNode, gql, OperationVariables } from "@apollo/client";
import { useMutation } from "@apollo/client";
import { DATALAYER } from "@arowana/util";
import {
  Box,
  Button,
  Collapse,
  Dialog,
  DialogContent,
  DialogTitle,
  LinearProgress,
  List,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { PaymentMethod as StripePM, StripeError } from "@stripe/stripe-js";
import { useCallback, useState } from "react";

import { useSetState } from "../hooks";
import PaymentMethodForm from "./PaymentMethodForm";
import PaymentMethodItem, { PaymentMethod } from "./PaymentMethodItem";

const useStyles = makeStyles(theme => ({
  dialogContainer: {
    [theme.breakpoints.only("xs")]: {
      paddingTop: theme.spacing(7),
    },
  },
}));

type PaymentOperation = "create" | "delete";

type PaymentMethodSectionProps = {
  // text-align on the "No payment methods" Typography
  defaultMessageAlign?: "inherit" | "left" | "center" | "right" | "justify";

  maxPaymentMethods: number;

  loading?: boolean;

  // Existing payment methods from account/client
  paymentMethods: PaymentMethod[];

  // Query to look up existing payment methods, used in post-creation cache update
  cacheQuery: DocumentNode;

  // Field name to extract query data
  cacheDataField: string;

  // Variables that are needed to lookup existing payment methods
  cacheQueryVariables?: OperationVariables;

  // Mutation for setup intent
  setupMutation: DocumentNode;

  // Variables for setup intent
  setupVariables: OperationVariables;

  // Mutation for create payment
  createMutation: DocumentNode;

  // Function to generate create mutation variables
  createVariablesFn: (paymentMethodId: string | StripePM) => OperationVariables;

  // Function to extract created payment data
  createdPaymentmethodFn: (data: any) => PaymentMethod;

  // Function to extract client secret from response
  clientSecretFn: (data: any) => string;

  // Mutation for delete payment method
  deleteMutation: DocumentNode;

  // Function to generate delete mutation variables
  deleteVariablesFn: (paymentMethodId: string) => OperationVariables;

  onCompleted: (operation: PaymentOperation, data: any) => void;
  onError: (operation: PaymentOperation | "setup", error: ApolloError | StripeError | Error) => void;
};

export const PaymentMethodSection = ({
  defaultMessageAlign = "center",
  maxPaymentMethods = 100,
  loading = false,
  paymentMethods,
  cacheQuery,
  cacheDataField,
  cacheQueryVariables,
  setupMutation,
  setupVariables,
  createMutation,
  clientSecretFn,
  createVariablesFn,
  createdPaymentmethodFn,
  deleteMutation,
  deleteVariablesFn,
  onCompleted,
  onError,
}: PaymentMethodSectionProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));

  const [showDialog, setShowDialog] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const [getSetupClientSecret] = useMutation(setupMutation, {
    context: { source: DATALAYER },
    onError: err => onError("setup", err),
  });

  const [createPayment] = useMutation(createMutation, {
    context: { source: DATALAYER },
    onCompleted: data => {
      onCompleted("create", data);
      setShowDialog(false);
    },
    onError: error => onError("create", error),
    update: (cache: ApolloCache<unknown>, { data }) => {
      const result = cache.readQuery({
        query: cacheQuery,
        variables: cacheQueryVariables,
      });

      if (result?.[cacheDataField]) {
        const paymentMethod = createdPaymentmethodFn(data);
        cache.modify({
          fields: {
            paymentMethods: (existingPaymentMethodRefs = []) => {
              const paymentMethodRef = cache.writeFragment({
                data: paymentMethod,
                fragment: gql`
                  fragment NewPaymentMethod on PaymentMethod {
                    id
                    __typename
                  }
                `,
              });

              return [...existingPaymentMethodRefs, paymentMethodRef];
            },
          },
          id: cache.identify(result[cacheDataField]),
        });
      }
    },
  });

  const [creating, setCreating] = useState(false);
  const onCreate = useCallback(() => {
    if (!elements || !stripe) {
      onError("create", new Error("Unable to initiate Stripe"));

      return;
    }

    setCreating(true);
    getSetupClientSecret({
      variables: setupVariables,
    })
      .then(({ data }) => {
        const clientSecret = clientSecretFn(data);

        return stripe.confirmCardSetup(clientSecret, {
          payment_method: {
            card: elements.getElement(CardElement),
          },
        });
      })
      .then(({ setupIntent, error }) => {
        if (error) {
          throw error;
        } else if (setupIntent.status === "succeeded") {
          return createPayment({
            variables: createVariablesFn(setupIntent.payment_method),
          });
        }
      })
      .catch(error => onError("create", error))
      .finally(() => setCreating(false));
  }, [
    clientSecretFn,
    createPayment,
    createVariablesFn,
    elements,
    getSetupClientSecret,
    onError,
    setupVariables,
    stripe,
  ]);

  const [deletePayment] = useMutation(deleteMutation, {
    context: { source: DATALAYER },
    onCompleted: data => onCompleted("delete", data),
    onError: error => onError("delete", error),
  });
  const { addItem: addDeletingId, removeItem: removeDeletingId, items: deletingIds } = useSetState();
  const onDelete = useCallback(
    async id => {
      addDeletingId(id);
      deletePayment({
        update: cache => {
          cache.evict({ id: cache.identify({ __typename: "PaymentMethod", id }) });
          cache.gc();
        },
        variables: deleteVariablesFn(id),
      }).then(() => removeDeletingId(id));
    },
    [addDeletingId, deletePayment, deleteVariablesFn, removeDeletingId],
  );

  let list;

  if (paymentMethods?.length > 0) {
    list = (
      <List>
        {paymentMethods?.map(paymentMethod => (
          <PaymentMethodItem
            deleting={deletingIds.has(paymentMethod.id)}
            key={paymentMethod.id}
            paymentMethod={paymentMethod}
            onDelete={onDelete}
          />
        ))}
      </List>
    );
  } else if (!loading) {
    list = <Typography align={defaultMessageAlign}>No payment methods</Typography>;
  }

  return (
    <>
      {loading && <LinearProgress />}
      {list}
      <Dialog
        className={classes.dialogContainer}
        fullScreen={isMobile}
        fullWidth
        maxWidth="sm"
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            setShowDialog(false);
          }
        }}
        open={showDialog}
        aria-labelledby="form-dialog-title-add-payment"
      >
        <DialogTitle id={`form-dialog-title-add-payment`}>Add payment method</DialogTitle>
        <DialogContent dividers>
          <PaymentMethodForm adding={creating} onAddClick={onCreate} onAddCancel={() => setShowDialog(false)} />
        </DialogContent>
      </Dialog>
      <Collapse in={paymentMethods?.length < maxPaymentMethods}>
        <Box display="flex" justifyContent="flex-end" marginTop={2}>
          <Button color="primary" onClick={() => setShowDialog(true)} variant="outlined">
            Add Payment Method
          </Button>
        </Box>
      </Collapse>
    </>
  );
};

export default PaymentMethodSection;
