import {
  Checkbox,
  FormControl,
  FormHelperText,
  Grid,
  Link,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import React from "react";
import { Controller } from "react-hook-form";

import SwitchToggle from "../../account/components/SwitchToggle";
import GeneralFields from "./GeneralFields";
const useStyles = makeStyles(theme => ({
  switch: {
    marginLeft: 0,
  },
}));
const UPS = ({ clearErrors, control, errors, register, setValue, watch }) => {
  const classes = useStyles();
  const negotiatedRatesEnabled = watch("enableNegotiatedRates", false);

  return (
    <FormControl component="fieldset" fullWidth margin="normal">
      <Grid container spacing={2}>
        <GeneralFields
          clearErrors={clearErrors}
          control={control}
          errors={errors}
          register={register}
          setValue={setValue}
        />
        <Grid item xs={12}>
          <Controller
            as={<SwitchToggle />}
            className={classes.switch}
            control={control}
            defaultValue={false}
            label="Enable negotiated rates"
            name="enableNegotiatedRates"
            type="checkbox"
          />
        </Grid>
        {negotiatedRatesEnabled && (
          <Grid container item spacing={2}>
            <Grid item sm={6} xs={12}>
              <Typography gutterBottom variant="subtitle1">
                Invoice Number
              </Typography>
              <TextField
                error={Boolean(errors?.invoiceNumber)}
                fullWidth
                helperText={errors?.invoiceNumber?.message}
                inputRef={register}
                name="invoiceNumber"
                variant="outlined"
              />
              <Typography color="textSecondary" variant="caption">
                Enter an invoice # from the past 30 days.{" "}
                <a
                  href="https://www.ups.com/us/en/help-center/sri/bill-center-invoice-support.page"
                  rel="noreferrer"
                  target="_blank"
                >
                  Instructions here
                </a>
                .
              </Typography>
            </Grid>
            <Grid item sm={6} xs={12}>
              <Typography gutterBottom variant="subtitle1">
                Invoice Amount
              </Typography>
              <TextField
                error={Boolean(errors?.invoiceAmount)}
                fullWidth
                helperText={errors?.invoiceAmount?.message}
                inputRef={register}
                name="invoiceAmount"
                variant="outlined"
              />
              <Typography color="textSecondary" variant="caption">
                Enter the dollar value listed for the specific invoice.
              </Typography>
            </Grid>
            <Grid item sm={6} xs={12}>
              <Typography gutterBottom variant="subtitle1">
                Invoice Date
              </Typography>
              <TextField
                error={Boolean(errors?.invoiceDate)}
                fullWidth
                helperText={errors?.invoiceDate?.message}
                inputRef={register}
                name="invoiceDate"
                type="date"
                variant="outlined"
              />
              <Typography color="textSecondary" variant="caption">
                Enter the date listed for the above invoice #.
              </Typography>
            </Grid>
            <Grid item sm={6} xs={12}>
              <Typography gutterBottom variant="subtitle1">
                Control ID
              </Typography>
              <TextField
                error={Boolean(errors?.controlId)}
                fullWidth
                helperText={errors?.controlId?.message}
                inputRef={register}
                name="controlId"
                variant="outlined"
              />
              <Typography color="textSecondary" variant="caption">
                This is a 4 character value on{" "}
                <a
                  href="https://support.shippingeasy.com/hc/en-us/articles/203503969-Find-the-UPS-Control-ID-on-Invoice-How-To"
                  rel="noreferrer"
                  target="_blank"
                >
                  your invoice
                </a>
                .
              </Typography>
            </Grid>
          </Grid>
        )}
        <Grid item xs={12}>
          <Typography variant="subtitle1">Terms of service</Typography>
          <Controller
            as={<SwitchToggle />}
            className={classes.switch}
            component={<Checkbox color="primary" />}
            control={control}
            defaultValue={false}
            label={
              <Typography variant="body2">
                By setting up a UPS account, you and your users agree to the{" "}
                <Link href="https://www.ups.com/assets/resources/media/UTA_with_EUR.pdf" target="_blank">
                  UPS Technology Agreement.
                </Link>
              </Typography>
            }
            labelPlacement="end"
            name="agreeToTechnologyAgreement"
            rules={{ required: "*required" }}
            type="checkbox"
          />
          <FormHelperText error>{errors?.agreeToTechnologyAgreement?.message}</FormHelperText>
        </Grid>
      </Grid>
    </FormControl>
  );
};

export default UPS;
