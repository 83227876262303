import { Location } from "@arowana/graphql";
import { Chip, ListItemText, makeStyles, MenuItem, Select, SelectProps, Theme } from "@material-ui/core";

const useStyles = makeStyles<Theme>(() => ({
  addressSecondary: {
    maxWidth: "100%",
    whiteSpace: "initial",
  },
  addressSelect: {
    margin: 0,
    overflowX: "hidden",
  },
  closedChip: {
    height: 18,
  },
}));

type PickupLocationSelectProps = SelectProps & {
  locations: Pick<Location, "id" | "name" | "active" | "address">[];
};

export const PickupLocationSelect = ({ locations, ...props }: PickupLocationSelectProps) => {
  const classes = useStyles();

  return (
    <Select fullWidth margin="dense" variant="outlined" {...props}>
      {locations?.map(({ id, name, active, address }) => {
        const primary = (
          <>
            {!active && (
              <Chip className={classes.closedChip} color="secondary" component="span" label="Closed" size="small" />
            )}{" "}
            {name}
          </>
        );

        return (
          <MenuItem disabled={!active} divider key={id} value={id}>
            <ListItemText
              classes={{ root: classes.addressSelect, secondary: classes.addressSecondary }}
              primary={primary}
              secondary={address?.formattedAddress}
            />
          </MenuItem>
        );
      })}
    </Select>
  );
};

export default PickupLocationSelect;
