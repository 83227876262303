import { gql, useMutation, useQuery } from "@apollo/client";
import { GiftcardUpdateInput, Promotion, PromotionRuleAmount } from "@arowana/graphql";
import { FormCard } from "@arowana/ui";
import { DATALAYER } from "@arowana/util";
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  InputAdornment,
  Link,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
} from "@material-ui/core";
import { notificationVar } from "apps/supplier/src/cache/notificationPolicy";
import PageHeader from "apps/supplier/src/components/PageHeader";
import Routes from "apps/supplier/src/Constants/Routes";
import moment from "moment-timezone";
import { useContext } from "react";
import { Controller, useForm } from "react-hook-form";
import { Link as RouterLink, RouteComponentProps } from "react-router-dom";

import { AccountContext } from "../../context/AccountContext";

const GIFTCARD = gql`
  query Giftcard($id: ID!) {
    giftcard: promotion(id: $id) {
      id
      code
      createdAt
      disabled
      message
      purchaserEmail
      purchaserName
      recipientEmail
      recipientName
      rule {
        __typename
        ... on PromotionRuleAmount {
          discountAmount
        }
      }
      updatedAt
      usages {
        amount
        orderId
      }
    }
  }
`;

const GIFTCARD_UPDATE = gql`
  mutation GiftcardUpdate($input: GiftcardUpdateInput!) {
    giftcardUpdate(input: $input) {
      id
      disabled
      rule {
        __typename
        ... on PromotionRuleAmount {
          discountAmount
        }
      }
    }
  }
`;

type FormValues = GiftcardUpdateInput;
interface Query {
  giftcard: Promotion;
}
interface Route {
  id: string;
}

const GiftcardDetails: React.FC<RouteComponentProps<Route>> = ({ match }) => {
  const {
    supplier: { timezone },
  } = useContext(AccountContext);

  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    reset,
  } = useForm<FormValues>({
    defaultValues: {
      amount: 0,
      disabled: false,
    },
  });

  const { data, loading: isFetching } = useQuery<Query>(GIFTCARD, {
    context: { source: DATALAYER },
    onCompleted: ({ giftcard }) => {
      reset({
        ...giftcard,
        amount: parseFloat(((giftcard.rule as PromotionRuleAmount).discountAmount / 100).toFixed(2)),
      });
    },
    variables: {
      id: match.params.id,
    },
  });
  const giftcard = data?.giftcard ?? null;

  const [updateGiftcard, { loading: isUpdating }] = useMutation(GIFTCARD_UPDATE, {
    context: { source: DATALAYER },
    onCompleted: () => {
      notificationVar({
        message: "Giftcard updated!",
        severity: "success",
      });
    },
    onError: () => {},
  });
  const handleDiscardClick = () => reset();
  const onSubmit = async (data: FormValues) => {
    updateGiftcard({
      variables: {
        input: {
          ...data,
          amount: parseInt(String(data.amount * 100)),
          id: match.params.id,
        },
      },
    });
  };
  const loading = isUpdating || isFetching;

  return (
    <section>
      <PageHeader
        homePage={Routes.GIFTCARDS}
        primaryActions={
          <>
            <Button color="primary" disabled={loading} onClick={handleDiscardClick} variant="outlined">
              Discard
            </Button>
            <Button color="primary" disabled={loading} onClick={handleSubmit(onSubmit)} variant="contained">
              {loading ? <CircularProgress color="inherit" size={24} /> : "Update"}
            </Button>
          </>
        }
        stickyHeader
        title={giftcard?.code ?? "Giftcard"}
      />
      <Grid container spacing={2}>
        <Grid item md={7} xs={12}>
          <FormCard>
            <Grid container spacing={3} component="dl">
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle2" component="dt">
                  Recipient email
                </Typography>
                <Typography variant="body1" component="dd">
                  {giftcard?.recipientEmail}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle2" component="dt">
                  Recipient name
                </Typography>
                <Typography variant="body1" component="dd">
                  {giftcard?.recipientName}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle2" component="dt">
                  Purchaser email
                </Typography>
                <Typography variant="body1" component="dd">
                  {giftcard?.purchaserEmail}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle2" component="dt">
                  Purchaser name
                </Typography>
                <Typography variant="body1" component="dd">
                  {giftcard?.purchaserName}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle2" component="dt">
                  Code
                </Typography>
                <Typography variant="body1" component="dd">
                  {giftcard?.code}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle2" component="dt">
                  Message
                </Typography>
                <Typography variant="body1" component="dd">
                  {giftcard?.message}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle2" component="dt">
                  Created at
                </Typography>
                <Typography variant="body1" component="dd">
                  {giftcard?.createdAt && moment.tz(giftcard.createdAt, timezone).format("lll")}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle2" component="dt">
                  Updated at
                </Typography>
                <Typography variant="body1" component="dd">
                  {giftcard?.updatedAt && moment.tz(giftcard.updatedAt, timezone).format("lll")}
                </Typography>
              </Grid>
            </Grid>

            <FormControl fullWidth margin="normal" required>
              <FormLabel htmlFor="amount">Amount</FormLabel>
              <TextField
                autoComplete="off"
                error={Boolean(errors?.amount)}
                fullWidth
                helperText={errors?.amount?.message}
                id="amount"
                inputRef={register({ required: "*required", valueAsNumber: true })}
                margin="dense"
                name="amount"
                placeholder="Dollar amount"
                variant="outlined"
                type="number"
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
              />
            </FormControl>

            <FormGroup row>
              <Controller
                control={control}
                name="disabled"
                render={({ onBlur, onChange, name, ref, value }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        name={name}
                        ref={ref}
                        color="primary"
                        checked={value}
                        onBlur={onBlur}
                        onChange={e => onChange(e.target.checked)}
                      />
                    }
                    label="Disable giftcard"
                  />
                )}
              />
            </FormGroup>
          </FormCard>
        </Grid>
        <Grid item md={5} xs={12}>
          <FormCard title="Usage">
            {giftcard?.usages?.length === 0 ? (
              <Typography variant="body1">No usage yet.</Typography>
            ) : (
              <List>
                {giftcard?.usages?.map(({ amount, orderId }) => (
                  <ListItem key={orderId} disableGutters divider>
                    <ListItemText
                      primary={`Amount $${Math.abs(amount / 100).toFixed(2)}`}
                      secondary={
                        <Link component={RouterLink} to={Routes.ORDER_DETAILS.replace(":id", orderId)}>
                          View order
                        </Link>
                      }
                    />
                  </ListItem>
                ))}
              </List>
            )}
          </FormCard>
        </Grid>
      </Grid>
    </section>
  );
};

export default GiftcardDetails;
