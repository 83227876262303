import { useMutation } from "@apollo/client";
import { DATALAYER } from "@arowana/util";
import { Chip, Grid, IconButton, makeStyles, Menu, MenuItem, Typography } from "@material-ui/core";
import { DeleteOutline as DeleteOutlineIcon, Edit as EditIcon, MoreHoriz as MoreHorizIcon } from "@material-ui/icons";
import PropTypes from "prop-types";
import React, { useState } from "react";

import { notificationVar } from "../../../cache/notificationPolicy";
import ConfirmationModal from "../../../components/ConfirmationModal";
import FormCard from "../../../components/FormCard";
import Routes from "../../../Constants/Routes";
import { CategoryDelete } from "../pages/CategoryDetails";

const useStyles = makeStyles(theme => ({
  category: {
    marginBottom: theme.spacing(1.5),
  },
  categoryContainer: {
    marginBottom: theme.spacing(2),
  },
  categoryGrid: {
    position: "relative",
  },
  categoryMenu: {
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
    "fontSize": theme.typography.body2.fontSize,
  },
  chips: {
    "&:not(:last-child)": { marginRight: theme.spacing(1) },
    "marginTop": theme.spacing(1.5),
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  moreButton: {
    position: "absolute",
    right: theme.spacing(-1),
    top: theme.spacing(-1),
  },
}));

const CategoryCard = ({ category, history }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDeleteCategoryModal, setOpenDeleteCategoryModal] = useState(false);
  const [deleteCategory, { loading: isDeleting }] = useMutation(CategoryDelete, {
    context: { source: DATALAYER },
    onCompleted: () => {
      notificationVar({
        message: "Category deleted!",
        severity: "success",
      });
    },
    refetchQueries: ["GetSupplierCategories"],
  });
  const handleClickMoreButton = event => setAnchorEl(event.currentTarget);
  const handleCloseMenu = () => setAnchorEl(null);
  const handleOpenDeleteCategoryModal = () => {
    handleCloseMenu();
    setOpenDeleteCategoryModal(true);
  };
  const handleCloseDeleteCategoryModal = () => setOpenDeleteCategoryModal(false);
  const handleEditCategoty = () => {
    handleCloseMenu();
    history.push(Routes.EDIT_CATEGORY.replace(":id", category.id));
  };
  const handleDeleteCategory = () => {
    deleteCategory({ variables: { id: category.id } });
  };

  return (
    <>
      <FormCard className={classes.categoryContainer} id={category.id} key={category.id}>
        <Grid className={classes.categoryGrid} container spacing={1}>
          <Grid item md={3} xs={12}>
            <Typography className={classes.category} variant="subtitle2">
              Category
            </Typography>
            <Typography variant="subtitle1">{category.name}</Typography>
          </Grid>
          <Grid item md={9} xs={12}>
            <Typography variant="subtitle2">Subcategory tags</Typography>
            {category.tags?.map(tag => (
              <Chip className={classes.chips} color="primary" key={tag.value} label={tag.label} variant="default" />
            ))}
          </Grid>

          <IconButton className={classes.moreButton} color="primary" onClick={handleClickMoreButton}>
            <MoreHorizIcon />
          </IconButton>
          <Menu anchorEl={anchorEl} keepMounted onClose={handleCloseMenu} open={Boolean(anchorEl)}>
            <MenuItem className={classes.categoryMenu} onClick={handleEditCategoty}>
              <EditIcon className={classes.icon} color="primary" fontSize="small" />
              Edit
            </MenuItem>
            <MenuItem className={classes.categoryMenu} onClick={handleOpenDeleteCategoryModal}>
              <DeleteOutlineIcon className={classes.icon} color="primary" fontSize="small" />
              Delete
            </MenuItem>
          </Menu>
        </Grid>
      </FormCard>

      <ConfirmationModal
        cancelRequestButtonText="Cancel"
        confirmRequestButtonText="Delete Category"
        confirmRequestOnGoing={isDeleting}
        isDangerAction
        modalContent="Are you sure you want to delete the category?"
        modalNote="Note: This action cannot be reversed."
        modalTitle="Delete Category"
        onCloseModal={handleCloseDeleteCategoryModal}
        onConfirmClick={handleDeleteCategory}
        shouldOpenModal={openDeleteCategoryModal}
      />
    </>
  );
};

CategoryCard.defaultProps = {
  category: {},
};

CategoryCard.propTypes = {
  category: PropTypes.object,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default CategoryCard;
