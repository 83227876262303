import { makeStyles } from "@material-ui/core";
import clsx from "clsx";

const DEFAULT_URL =
  "https://firebasestorage.googleapis.com/v0/b/duckr-b8e76.appspot.com/o/emailTemplateResources%2FFreshline_Logo.png?alt=media&token=a80fd881-36af-4957-b311-e8b4d9e2f42b";

const useStyles = makeStyles(theme => ({
  logo: {
    height: "auto",
    maxHeight: theme.spacing(8),
    maxWidth: "100%",
    width: "auto",
  },
}));

type SupplierLogoProps = {
  alt?: string;
  src?: string;
  className?: string;
  fallbackSrc?: string;
};

export const SupplierLogo = ({
  alt = "logo",
  className,
  fallbackSrc = DEFAULT_URL,
  src = DEFAULT_URL,
}: SupplierLogoProps) => {
  const classes = useStyles();
  const classnames = clsx(classes.logo, className);

  return (
    <img
      alt={alt}
      className={classnames}
      onError={event => {
        const target = event.target as HTMLImageElement;

        if (target.src !== fallbackSrc) {
          target.src = fallbackSrc;
        }
      }}
      src={src}
    />
  );
};

export default SupplierLogo;
