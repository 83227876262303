import { gql } from "@apollo/client";

const LIST_FRAGMENT = gql`
  fragment ListFragment on List {
    _id
    createdAt
    description
    identifier
    image {
      original
    }
    name
    identifier
    products {
      id
      name
      subName
      active
      createdAt
      displayPrice
      displayUnit
      stockStatus
    }
    updatedAt
  }
`;

export default LIST_FRAGMENT;
