import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { useContext } from "react";

import { SupplierContext } from "../context";
import { SupplierLogo } from "../SupplierLogo";

const useStyles = makeStyles(theme => ({
  actionButton: { width: "50%" },
  cancelOrderContainer: {
    padding: theme.spacing(4),
    [theme.breakpoints.only("xs")]: { padding: theme.spacing(4, 2) },
  },
  cancelOrderDialog: { textAlign: "center" },
  logo: { margin: "auto" },
}));

interface ModifyOrderModalProps {
  open: boolean;
  onClose: () => void;
  onCancelOrder: () => void;
}

export const ModifyOrderModal = ({ open, onClose, onCancelOrder }: ModifyOrderModalProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const supplierData = useContext(SupplierContext);
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));

  return (
    <Dialog
      classes={{ paper: classes.cancelOrderContainer, root: classes.cancelOrderDialog }}
      disableEscapeKeyDown
      fullScreen={isMobile}
      fullWidth
      maxWidth="sm"
      onClose={(_, reason) => {
        if (reason !== "backdropClick") {
          onClose();
        }
      }}
      open={open}
    >
      <SupplierLogo className={classes.logo} src={supplierData?.uiSettings?.logoUrl} />
      <DialogTitle>Modify Order</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Click confirm to adjust your existing order with new items, quantities, or service dates. Your previous order
          will be cancelled and replaced with this order.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus className={classes.actionButton} color="primary" onClick={onClose} variant="outlined">
          Cancel
        </Button>
        <Button className={classes.actionButton} color="secondary" onClick={onCancelOrder} variant="contained">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModifyOrderModal;
