import { gql } from "@apollo/client";

import LIST_FRAGMENT from "../fragment/list";

const listUpdate = gql`
  mutation ListUpdate($input: ListUpdateInput!, $image: FileUpload) {
    listUpdate(input: $input, image: $image) {
      ...ListFragment
    }
  }
  ${LIST_FRAGMENT}
`;

export default listUpdate;
