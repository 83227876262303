import { Typography } from "@material-ui/core";
import { useContext } from "react";

import PageHeader from "../../../components/PageHeader";
import { AccountContext } from "../../context/AccountContext";

function Unauthorized() {
  const { supplier } = useContext(AccountContext);

  return (
    <>
      <PageHeader stickyHeader title="Unauthorized" />
      <Typography gutterBottom variant="h2">
        You do not have access to this area
      </Typography>
      <Typography paragraph>
        This area has been restricted based on your role & permissions. If you think this is an error please contact the
        owner at:
      </Typography>
      <ul>
        <li>Email: {supplier.supportEmail}</li>
        <li>Phone: {supplier.supportPhoneNumber}</li>
      </ul>
    </>
  );
}

export default Unauthorized;
