import {
  FormControl,
  FormControlProps,
  FormHelperText,
  makeStyles,
  MenuItem,
  Select,
  SelectProps,
  TextFieldProps,
  Typography,
} from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import PropTypes from "prop-types";
import { forwardRef } from "react";

const useStyles = makeStyles(theme => ({
  formControl: {
    paddingBottom: theme.spacing(2),
  },
}));

type CurrencyProps = {
  value: "usd" | "cad" | "nzd" | "";
  onChange?: SelectProps["onChange"];
  helperText?: React.ReactNode | undefined | null;
  error?: FormControlProps["error"];
};

const Currency = forwardRef<Select, CurrencyProps>(({ value, onChange, helperText, error }: CurrencyProps, ref) => {
  const classes = useStyles();

  return (
    <FormControl className={classes.formControl} fullWidth variant="outlined" error={error}>
      <InputLabel id="currency">Store currency</InputLabel>
      <Select ref={ref} id="currency" label="Store currency" onChange={onChange} value={value} variant="outlined">
        <MenuItem defaultValue={value} key={"usd"} value={"usd"}>
          U.S Dollar (USD)
        </MenuItem>
        <MenuItem defaultValue={value} key={"cad"} value={"cad"}>
          Canadian Dollar (CAD)
        </MenuItem>
        <MenuItem defaultValue={value} key={"nzd"} value={"nzd"}>
          New Zealand Dollar (NZD)
        </MenuItem>
      </Select>
      <FormHelperText>{helperText ?? "Used for payouts and payments."}</FormHelperText>
    </FormControl>
  );
});

Currency.displayName = "Currency";

export default Currency;
