import { GooglePlacesField } from "@arowana/ui";
import { getAddressLabels } from "@arowana/util";
import { Box, Grid, makeStyles, TextField, Typography } from "@material-ui/core";
import { Controller, UseFormMethods, useWatch } from "react-hook-form";

const useStyles = makeStyles(theme => ({
  spacing: {
    marginTop: theme.spacing(-2),
  },
}));

interface AddressProps {
  addressKey?: string;
  clearErrors: UseFormMethods["clearErrors"];
  control: UseFormMethods["control"];
  errors: UseFormMethods["errors"];
  hasCountryCode?: boolean;
  hasRegionCode?: boolean;
  hideUnitNumber?: boolean;
  hideRegion?: boolean;
  register: UseFormMethods["register"];
  required?: boolean;
  setValue: UseFormMethods["setValue"];
  streetAddressLabel?: string;
}

const Address = ({
  addressKey = "address",
  clearErrors,
  control,
  errors,
  hasCountryCode = false,
  hasRegionCode = false,
  hideUnitNumber = false,
  hideRegion = false,
  register,
  required = true,
  setValue,
  streetAddressLabel = "Address 1",
}: AddressProps) => {
  const classes = useStyles();
  const { postalLabel, provinceLabel: regionLabel } = getAddressLabels(
    useWatch({
      control,
      defaultValue: "",
      name: `${addressKey}[country]`,
    }),
  );

  const handlePlaceReset = () => {
    setValue(`${addressKey}[city]`, "");
    setValue(`${addressKey}[country]`, "");
    setValue(`${addressKey}[lngLat][coordinates][0]`, "");
    setValue(`${addressKey}[lngLat][coordinates][1]`, "");
    setValue(`${addressKey}[region]`, "");
    setValue(`${addressKey}[postalCode]`, "");
    setValue(`${addressKey}[address1]`, "");
    setValue(`${addressKey}[notes]`, "");

    if (!hideUnitNumber) {
      setValue(`${addressKey}[address2]`, "");
    }

    if (hasCountryCode) {
      setValue(`${addressKey}[countryCode]`, "");
    }

    if (hasRegionCode) {
      setValue(`${addressKey}[regionCode]`, "");
    }
  };

  const handlePlaceSelect = ({
    city,
    country,
    countryCode,
    lat,
    lng,
    postalCode,
    province: region,
    provinceCode: regionCode,
    streetAddress,
    sublocality,
  }) => {
    setValue(`${addressKey}[address1]`, streetAddress);
    setValue(`${addressKey}[city]`, city);
    setValue(`${addressKey}[country]`, country);
    setValue(`${addressKey}[lngLat][coordinates][0]`, lng);
    setValue(`${addressKey}[lngLat][coordinates][1]`, lat);
    setValue(`${addressKey}[postalCode]`, postalCode);
    setValue(`${addressKey}[region]`, region);
    setValue(`${addressKey}[sublocality]`, sublocality);

    if (hasCountryCode) {
      setValue(`${addressKey}[countryCode]`, countryCode);
    }

    if (hasRegionCode) {
      setValue(`${addressKey}[regionCode]`, regionCode);
    }

    clearErrors([
      `${addressKey}[city]`,
      `${addressKey}[country]`,
      `${addressKey}[region]`,
      `${addressKey}[postalCode]`,
      `${addressKey}[address1]`,
    ]);
  };

  return (
    <Grid className={classes.spacing} container item spacing={2}>
      <Grid item xs={hideUnitNumber ? 6 : 12}>
        <Typography gutterBottom variant="subtitle1">
          {streetAddressLabel}
        </Typography>
        <Controller
          as={GooglePlacesField}
          control={control}
          error={!!errors?.[addressKey]?.address1}
          helperText={errors?.[addressKey]?.address1?.message}
          name={`${addressKey}[address1]`}
          onReset={handlePlaceReset}
          onSelect={handlePlaceSelect}
          placeholder="Start typing to see suggested addresses"
          rules={required ? { required: "*required" } : register}
          // if you disable this, then remember to fix the onSubmit logic in ZoneDetail！
          shouldResetForEmptyValue
        />
      </Grid>

      {!hideUnitNumber && (
        <Grid item xs={6}>
          <Typography gutterBottom variant="subtitle1">
            Address 2
          </Typography>
          <Controller
            as={TextField}
            control={control}
            fullWidth
            name={`${addressKey}[address2]`}
            placeholder="Apartment, suite, unit, building, floor, etc."
            rules={register}
            variant="outlined"
          />
        </Grid>
      )}

      <Grid item xs={6}>
        <Typography gutterBottom variant="subtitle1">
          {postalLabel}
        </Typography>
        <Controller
          as={TextField}
          control={control}
          error={!!errors?.[addressKey]?.postalCode}
          fullWidth
          helperText={errors?.[addressKey]?.postalCode?.message}
          name={`${addressKey}[postalCode]`}
          placeholder={postalLabel}
          rules={required ? { required: "*required" } : register}
          variant="outlined"
        />
      </Grid>

      {!hideRegion && (
        <Grid item xs={6}>
          <Typography gutterBottom variant="subtitle1">
            {regionLabel}
          </Typography>
          <Controller
            as={TextField}
            control={control}
            error={!!errors?.[addressKey]?.region}
            fullWidth
            helperText={errors?.[addressKey]?.region?.message}
            name={`${addressKey}[region]`}
            placeholder={regionLabel}
            rules={required ? { required: "*required" } : register}
            variant="outlined"
          />
        </Grid>
      )}

      <Grid item xs={6}>
        <Typography gutterBottom variant="subtitle1">
          City
        </Typography>
        <Controller
          as={TextField}
          control={control}
          error={!!errors?.[addressKey]?.city}
          fullWidth
          helperText={errors?.[addressKey]?.city?.message}
          name={`${addressKey}[city]`}
          placeholder="City"
          rules={required ? { required: "*required" } : register}
          variant="outlined"
        />
      </Grid>

      <Grid item xs={hideRegion ? 6 : 12}>
        <Typography gutterBottom variant="subtitle1">
          Country
        </Typography>
        <Controller
          as={TextField}
          control={control}
          error={!!errors?.[addressKey]?.country}
          fullWidth
          helperText={errors?.[addressKey]?.country?.message}
          name={`${addressKey}[country]`}
          placeholder="Country"
          rules={required ? { required: "*required" } : register}
          variant="outlined"
        />
      </Grid>

      <Grid item xs={12}>
        <Typography gutterBottom variant="subtitle1">
          Notes
        </Typography>
        <Controller
          as={TextField}
          control={control}
          error={!!errors?.[addressKey]?.notes}
          fullWidth
          multiline
          helperText={errors?.[addressKey]?.notes?.message}
          name={`${addressKey}[notes]`}
          placeholder="Notes & Special Instructions"
          rules={register}
          variant="outlined"
        />
      </Grid>

      <Box display="none">
        <TextField
          fullWidth
          inputRef={register}
          name={`${addressKey}[lngLat][type]`}
          value={"Point"}
          variant="outlined"
        />
        <TextField fullWidth inputRef={register} name={`${addressKey}[lngLat][coordinates][0]`} variant="outlined" />
        <TextField fullWidth inputRef={register} name={`${addressKey}[lngLat][coordinates][1]`} variant="outlined" />
        <TextField fullWidth inputRef={register} name={`${addressKey}[sublocality]`} variant="outlined" />
        {hasCountryCode && (
          <TextField fullWidth inputRef={register} name={`${addressKey}[countryCode]`} variant="outlined" />
        )}
        {hasRegionCode && (
          <TextField fullWidth inputRef={register} name={`${addressKey}[regionCode]`} variant="outlined" />
        )}
      </Box>
    </Grid>
  );
};

export default Address;
