import { FormControl, Grid, TextField, Typography } from "@material-ui/core";
import React from "react";

const Purolator = ({ errors, register }) => {
  return (
    <FormControl component="fieldset" fullWidth margin="normal">
      <Grid container spacing={2}>
        <Grid item sm={6} xs={12}>
          <Typography gutterBottom variant="subtitle1">
            Account Number
          </Typography>
          <TextField
            error={Boolean(errors?.accountNumber)}
            fullWidth
            helperText={errors?.accountNumber?.message}
            inputRef={register({
              required: "*required",
            })}
            name="accountNumber"
            variant="outlined"
          />
          <Typography color="textSecondary" variant="caption">
            Find this information by{" "}
            <a
              href="https://help.shipengine.com/hc/en-us/articles/1260800970469-Purolator-CA-"
              rel="noreferrer"
              target="_blank"
            >
              creating or signing into your Purolator Account
            </a>
            .
          </Typography>
        </Grid>
        <Grid item sm={6} xs={12}>
          <Typography gutterBottom variant="subtitle1">
            Activation key
          </Typography>
          <TextField
            error={Boolean(errors?.activationKey)}
            fullWidth
            helperText={errors?.activationKey?.message}
            inputRef={register({
              required: "*required",
            })}
            name="activationKey"
            variant="outlined"
          />
          <Typography color="textSecondary" variant="caption">
            Find this information by{" "}
            <a
              href="https://help.shipengine.com/hc/en-us/articles/1260800970469-Purolator-CA-"
              rel="noreferrer"
              target="_blank"
            >
              creating or signing into your Purolator Account
            </a>
            .
          </Typography>
        </Grid>
      </Grid>
    </FormControl>
  );
};

export default Purolator;
