import { makeStyles, Typography } from "@material-ui/core";

import AuthForm, { FormMode } from "../components/AuthForm";

const useStyles = makeStyles(theme => ({
  loginMessage: {
    marginBottom: theme.spacing(4),
  },
  welcomeMessage: {
    paddingTop: theme.spacing(3),
  },
}));

const LoginPage = () => {
  const classes = useStyles();

  return (
    <AuthForm formMode={FormMode.Login}>
      <Typography className={classes.welcomeMessage} gutterBottom variant="h6">
        Welcome back!
      </Typography>
      <Typography className={classes.loginMessage}>Log in to your store with your email and password.</Typography>
    </AuthForm>
  );
};

export default LoginPage;
