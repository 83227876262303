export default {
  Supplier: {
    fields: {
      integrations: { merge: true },
      listsConnection: {
        keyArgs: false,
        merge(existing = {}, incoming) {
          if (!incoming) return existing;

          const existingCursors = new Set((existing.edges ?? []).map(edge => edge.cursor));
          const filteredIncomingEdges = (incoming.edges ?? []).filter(edge => !existingCursors.has(edge.cursor));
          const merged = {
            ...incoming,
            edges: [...(existing.edges ?? []), ...filteredIncomingEdges],
          };

          return merged;
        },
      },
      notificationSettings: {
        merge: true,
      },
      stripeSettings: {
        merge: true,
      },
      uiSettings: {
        merge: true,
      },
    },
  },
};
