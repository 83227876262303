import { Button, ButtonProps, CircularProgress, Menu } from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { ReactNode, useCallback, useState } from "react";

type ExportMenuProps = {
  buttonProps?: ButtonProps;
  disabled?: boolean;
  menuId: string;
  renderMenu?: (onMenuClose: () => void) => ReactNode;
  label?: string;
  loading: boolean;
};

const ExportMenu = ({
  label = "Export",
  buttonProps,
  disabled = false,
  menuId,
  loading,
  renderMenu,
}: ExportMenuProps) => {
  const [exportMenuAnchorEl, setExportMenuAnchorEl] = useState<Element | null>(null);
  const onExportMenuClick = useCallback(event => setExportMenuAnchorEl(event.currentTarget), []);
  const onExportMenuClose = useCallback(() => setExportMenuAnchorEl(null), []);

  return (
    <>
      <Button
        aria-controls={menuId}
        aria-haspopup="true"
        color="primary"
        disabled={loading || disabled}
        endIcon={loading ? <CircularProgress color="inherit" size={24} /> : <ArrowDropDownIcon />}
        onClick={onExportMenuClick}
        variant="outlined"
        {...buttonProps}
      >
        {label}
      </Button>
      <Menu
        id={menuId}
        keepMounted
        open={Boolean(exportMenuAnchorEl)}
        onClose={onExportMenuClose}
        anchorEl={exportMenuAnchorEl}
      >
        {renderMenu(onExportMenuClose)}
      </Menu>
    </>
  );
};

export default ExportMenu;
