import { PromotionType } from "@arowana/graphql";
import { OnWheelBlur } from "@arowana/ui";
import { FormControl, FormHelperText, FormLabel, Grid, InputAdornment, TextField, Typography } from "@material-ui/core";
import { UseFormMethods, useWatch } from "react-hook-form";

import FormCard from "../../../components/FormCard";

interface DiscountRulesLimitsType {
  control: UseFormMethods["control"];
  errors: UseFormMethods["errors"];
  register: UseFormMethods["register"];
}

const DiscountRulesLimits = ({ control, errors, register }: DiscountRulesLimitsType) => {
  const type = useWatch({
    control,
    defaultValue: PromotionType.percentage,
    name: "type",
  });

  const percentageDiscount = type === PromotionType.percentage;
  const amountDiscount = type === PromotionType.amount;

  return (
    <FormCard title="Discount rules & limits">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography color="textSecondary" variant="body2">
            Set per order discount rules and limits.
          </Typography>
        </Grid>
        <Grid item md={6} xs={12}>
          <FormControl fullWidth margin="none">
            <FormLabel htmlFor="discount-value">Discount amount</FormLabel>
            <TextField
              InputProps={{
                ...(percentageDiscount && { endAdornment: <InputAdornment position="end">%</InputAdornment> }),
                ...(amountDiscount && { startAdornment: <InputAdornment position="start">$</InputAdornment> }),
                inputProps: {
                  min: 0,
                  step: 0.01,
                },
                onWheel: OnWheelBlur,
              }}
              aria-describedby="discount-value-helper"
              error={Boolean(errors?.discountValue)}
              fullWidth
              helperText={errors?.discountValue?.message}
              id="discount-value"
              inputRef={register({
                min: {
                  message: "invalid value",
                  value: 0,
                },
                required: "*required",
                valueAsNumber: true,
              })}
              margin="normal"
              name="discountValue"
              size="small"
              type="number"
              variant="outlined"
            />
            <FormHelperText id="discount-value-helper">Discount value to be applied per order.</FormHelperText>
          </FormControl>
        </Grid>

        <Grid item md={6} xs={12}>
          <FormControl fullWidth margin="none">
            <FormLabel htmlFor="minimumOrderAmount">Min. purchase for discount</FormLabel>
            <TextField
              InputProps={{
                inputProps: {
                  min: 0,
                  step: 0.01,
                },
                onWheel: OnWheelBlur,
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
              aria-describedby="minimum-order-amount-helper"
              error={Boolean(errors?.minimumOrderAmount)}
              fullWidth
              helperText={errors?.minimumOrderAmount?.message}
              id="minimumOrderAmount"
              inputRef={register({
                min: {
                  message: "invalid value",
                  value: 0,
                },
                required: "*required",
                valueAsNumber: true,
              })}
              margin="normal"
              name="minimumOrderAmount"
              size="small"
              type="number"
              variant="outlined"
            />
            <FormHelperText id="minimum-order-amount-helper">
              Minimum cart subtotal to qualify for discount.
            </FormHelperText>
          </FormControl>
        </Grid>
      </Grid>
    </FormCard>
  );
};

export default DiscountRulesLimits;
