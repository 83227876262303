import { Button, Grid, makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import { Controller, useForm } from "react-hook-form";

import { getDefaultFulfillmentDays } from "../../../utils/getDefaultFulfillmentDays";
import FulfillmentDays from "./FormComponents/FulfillmentDays";

const useStyles = makeStyles(theme => ({
  actionButtons: {
    marginTop: theme.spacing(2),
  },
}));

const DeliveryScheduleForm = ({ onClose, products, updateDelivery }) => {
  const classes = useStyles();
  const { control, errors, handleSubmit, formState } = useForm({
    defaultValues: { deliveryDays: getDefaultFulfillmentDays() },
  });

  const onSubmit = data => {
    const variables = {
      input: products.map(({ id }) => ({
        fulfillmentDays: data.deliveryDays
          .filter(({ checked }) => checked)
          .map(({ fulfilledOn, orderBy }) => ({
            fulfilledOn,
            orderBy,
          })),
        id,
      })),
    };
    updateDelivery({ variables });
    onClose();
  };

  return (
    <>
      <Controller
        as={<FulfillmentDays />}
        control={control}
        helperText={errors?.deliveryDays?.message}
        name="deliveryDays"
        rules={{ validate: rows => rows.some(row => row.checked) || "*select at least one fulfillment day" }}
      />
      <Grid className={classes.actionButtons} container justifyContent="flex-end" spacing={1}>
        <Grid item md={3} sm={6} xs={12}>
          <Button color="primary" fullWidth onClick={onClose}>
            Cancel
          </Button>
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <Button
            color="primary"
            disabled={!formState?.isDirty}
            fullWidth
            onClick={handleSubmit(onSubmit)}
            variant="contained"
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

DeliveryScheduleForm.defaultProps = {
  products: [],
};

DeliveryScheduleForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  products: PropTypes.array,
  updateDelivery: PropTypes.func.isRequired,
};

export default DeliveryScheduleForm;
