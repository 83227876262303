import { FormControlLabel, makeStyles, Radio, RadioGroup, Typography } from "@material-ui/core";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";

const useStyles = makeStyles(theme => ({
  hide: {
    display: "none",
  },
  radioContainer: {
    flexDirection: "row",
    justifyContent: "space-around",
  },
  radioLabel: {
    paddingTop: theme.spacing(1),
    width: "100%",
  },
  radioRoot: {
    alignItems: "flex-start",
    [theme.breakpoints.only("xs")]: { width: "100%" },
  },
}));
const RadioButtons = ({ className, onChange, options, value }) => {
  const classes = useStyles();
  const handleChange = e => onChange(e.target.value);

  return (
    <RadioGroup className={clsx(classes.radioContainer, className)} onChange={handleChange} value={value}>
      {options.map(option => (
        <FormControlLabel
          classes={{
            label: classes.radioLabel,
            root: clsx(classes.radioRoot, { [classes.hide]: option?.hide }),
          }}
          control={<Radio color="primary" />}
          key={option.value}
          label={
            <>
              <Typography gutterBottom>{option.label}</Typography>
              {option.image}
            </>
          }
          value={option.value}
        />
      ))}
    </RadioGroup>
  );
};

RadioButtons.defaultProps = {
  onChange: () => {},
  options: [],
  value: undefined,
};

RadioButtons.propTypes = {
  onChange: PropTypes.func,
  options: PropTypes.array,
  value: PropTypes.string,
};

export default RadioButtons;
