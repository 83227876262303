import { FormControl, FormLabel, Grid, Link, makeStyles, TextField, Typography } from "@material-ui/core";
import React from "react";

import FormTooltip from "../../../components/FormTooltip";
import AppForm from "../components/AppForm";

const defaultValues = {
  googleAnalyticsKey: "",
};
const useStyles = makeStyles(theme => ({
  instructionSection: {
    backgroundColor: theme.palette.grey[50],
    borderRadius: theme.shape.borderRadius,
    marginTop: theme.spacing(6),
    padding: theme.spacing(2),
  },
}));

const GoogleAnalytics = () => {
  const classes = useStyles();
  const isInstalled = fields => Boolean(fields?.googleAnalyticsKey);

  return (
    <AppForm
      appId="googleAnalytics"
      defaultValues={defaultValues}
      header="Google Analytics ID"
      isInstalled={isInstalled}
    >
      {({ formMethods: { errors, register }, installed }) => (
        <FormControl component="fieldset" fullWidth margin="normal">
          <FormLabel component="legend" htmlFor="googleAnalyticsKey">
            Google Analytics ID{" "}
            <FormTooltip content="Adding this app enables remarketing, a way to connect with people who previously interacted with your website or mobile app. It allows you to strategically position your ads in front of these audiences as they browse Google or its partner websites, thus helping you increase your brand awareness or remind those audiences to make a purchase." />
          </FormLabel>
          <TextField
            disabled={installed}
            error={Boolean(errors?.googleAnalyticsKey)}
            helperText={errors?.googleAnalyticsKey?.message}
            id="googleAnalyticsKey"
            inputRef={register({
              required: "*required",
            })}
            margin="dense"
            name="googleAnalyticsKey"
            variant="outlined"
          />
          <Typography color="textSecondary" variant="caption">
            i.e. UA-78391231-1 or G-7KWBRJBPZC
          </Typography>
          <Grid className={classes.instructionSection} xs={12}>
            <Typography gutterBottom variant="subtitle2">
              Installation Guide
            </Typography>
            <Typography color="textSecondary" variant="body2">
              <ol>
                <li>
                  <Link href="https://analytics.google.com/" target="_blank">
                    Sign in to your Analytics account
                  </Link>
                </li>
                <li>
                  <Link href="https://support.google.com/analytics/answer/6132368" target="_blank">
                    Click Admin.
                  </Link>
                </li>
                <li>Select an account from the menu in the ACCOUNT column.</li>
                <li>Select a property from the menu in the PROPERTY column.</li>
                <li>
                  Under PROPERTY, click Tracking Info → Tracking Code. If this link does not exist, click Property
                  Settings.
                </li>
                <li>
                  Copy the Tracking ID displayed at the top of the page and paste this into the Google Analytics ID
                  input. Alternatively, copy and enter the Property ID displayed at the top right of the Property
                  details section.
                </li>
                <li>Click Save to finalize your details.</li>
              </ol>
            </Typography>
          </Grid>
        </FormControl>
      )}
    </AppForm>
  );
};

export default GoogleAnalytics;
