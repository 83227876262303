import { Box, Button, Grid, makeStyles, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Controller } from "react-hook-form";

import RadioButtons from "./RadioButtons";
import SlidesList from "./SlidesList";
import SwitchToggle from "./SwitchToggle";

const useStyles = makeStyles(theme => ({
  displayToggler: {
    marginBottom: theme.spacing(2),
    marginLeft: 0,
  },
  divider: {
    marginBottom: theme.spacing(2),
  },
  expandCarouselButton: {
    float: "right",
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(1.5),
      width: "100%",
    },
  },
}));
const Carousel = ({ control, watch }) => {
  const classes = useStyles();
  const displayCarousel = watch("carousel[display]");
  const displayType = watch("carousel[style]");
  const [expandCarouselSection, setExpandCarouselSection] = useState(false);

  useEffect(() => {
    if (displayCarousel) {
      setExpandCarouselSection(true);
    }
  }, [displayCarousel]);

  const handleClickExpandCarousel = () => setExpandCarouselSection(true);
  let renderedCarousel;

  if (expandCarouselSection) {
    renderedCarousel = (
      <>
        <Box className={classes.divider}>
          <Typography color="textSecondary" variant="body2">
            Increase audience engagement and boost sales by promoting products or showcasing discounts through carousel
            banners.
          </Typography>
        </Box>

        <Controller
          as={<SwitchToggle />}
          className={classes.displayToggler}
          control={control}
          label="Display on shop"
          name="carousel[display]"
          type="checkbox"
        />

        <Box mb={2}>
          <Typography gutterBottom variant="subtitle1">
            Carousel layout{" "}
            <Typography color="textSecondary" component="span" variant="body2">
              (Max 5 slides)
            </Typography>
          </Typography>

          <Controller
            as={<RadioButtons />}
            control={control}
            name="carousel[style]"
            options={[
              {
                label: "Full Width",
                value: "full",
              },
              {
                label: "Thin Height",
                value: "thin",
              },
              {
                label: "Half Width",
                value: "half",
              },
            ]}
          />
        </Box>

        <Controller as={<SlidesList />} control={control} displayType={displayType} name="carousel[slides]" />
      </>
    );
  } else {
    renderedCarousel = (
      <Grid alignItems="center" container spacing={1}>
        <Grid item lg={10} md={9} xs={12}>
          <Typography color="textSecondary" variant="body2">
            Increase audience engagement and boost sales by promoting products and showcasing discounts or sales using
            carousel banners.
          </Typography>
        </Grid>
        <Grid item lg={2} md={3} xs={12}>
          <Button
            className={classes.expandCarouselButton}
            color="primary"
            onClick={handleClickExpandCarousel}
            variant="contained"
          >
            Add
          </Button>
        </Grid>
      </Grid>
    );
  }

  return renderedCarousel;
};

Carousel.propTypes = {
  control: PropTypes.object.isRequired,
  watch: PropTypes.func.isRequired,
};

export default Carousel;
