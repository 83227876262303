import { STATIC_HOST } from "@arowana/util";
import {
  CircularProgress,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemProps,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
} from "@material-ui/core";
import { Delete as DeleteIcon } from "@material-ui/icons";
import { useCallback } from "react";

export enum BrandName {
  amex = "AMEX",
  cartes_bancaires = "CB",
  diners = "Diners Club",
  discover = "Discover",
  jcb = "JCB",
  mastercard = "MasterCard",
  visa = "Visa",
  unionpay = "UnionPay",
}

export type PaymentMethod = {
  id: string;
  card: {
    brand: string;
    expiryMonth: number;
    expiryYear: number;
    last4: string;
  };
};

type PaymentMethodItemProps = {
  dense?: ListItemProps["dense"];
  deleting?: boolean;
  onDelete?: (id: string) => void;
  paymentMethod: PaymentMethod;
};

const PaymentMethodItem = ({
  onDelete,
  deleting = false,
  paymentMethod: { card, id },
  dense,
}: PaymentMethodItemProps) => {
  const onDeleteClick = useCallback(() => onDelete(id), [id, onDelete]);
  const { brand, expiryMonth, expiryYear, last4 } = card;
  const imgSrc = `${STATIC_HOST}/credit-cards/${BrandName[brand] ? brand : "default"}.svg`;
  const showDelete = Boolean(onDelete);

  return (
    <ListItem disableGutters dense={dense}>
      <ListItemIcon>
        <img alt={`${brand} card`} src={imgSrc} width="50" />
      </ListItemIcon>
      <ListItemText
        primary={BrandName[brand]}
        secondary={
          <>
            Ending in <strong>{last4}</strong>, expires {expiryMonth < 10 ? "0" : ""}
            {expiryMonth}/{expiryYear}
          </>
        }
      />
      {showDelete && (
        <ListItemSecondaryAction>
          <Tooltip title="Remove">
            <IconButton edge="end" aria-label="delete" onClick={onDeleteClick}>
              {deleting ? <CircularProgress size={24} /> : <DeleteIcon fontSize="small" />}
            </IconButton>
          </Tooltip>
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
};

export default PaymentMethodItem;
