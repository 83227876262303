import { List as ListType } from "@arowana/graphql";
import {
  Box,
  Button,
  Fade,
  FormHelperText,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Clear } from "@material-ui/icons";
import { forwardRef, useMemo } from "react";
import { Link as RouterLink } from "react-router-dom";

import Routes from "../../../Constants/Routes";
import ListAutocomplete from "../../products/components/ListAutocomplete";

export type ListValue = Pick<ListType, "id" | "name">;

type ListsInputProps = {
  value: Array<ListValue>;
  onChange: (values: Array<ListValue>) => void;
  errorMsg?: string;
};

const ListsInput = forwardRef(({ value, onChange, errorMsg }: ListsInputProps, ref) => {
  const filterIds = useMemo(() => value.map(({ id }) => id));

  const onClearAll = () => onChange([]);

  return (
    <div ref={ref}>
      <ListAutocomplete filterIds={filterIds} onChange={list => onChange([...value, list])} />
      <List>
        {value.map(({ id, name }) => (
          <ListItem disableGutters key={id} divider>
            <ListItemText>
              <Link to={Routes.EDIT_LIST.replace(":id", id)} component={RouterLink}>
                {name}
              </Link>
            </ListItemText>
            <ListItemSecondaryAction>
              <Tooltip title="Clear">
                <IconButton aria-label="clear" edge="end" onClick={() => onChange(value.filter(v => id !== v.id))}>
                  <Clear />
                </IconButton>
              </Tooltip>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
        {value.length === 0 && <Typography align="center">No collections</Typography>}
      </List>
      {errorMsg && <FormHelperText error>{errorMsg}</FormHelperText>}
      <Box display="flex" justifyContent="flex-end">
        <Fade in={value.length > 0}>
          <Button color="secondary" onClick={onClearAll} size="small">
            Clear All
          </Button>
        </Fade>
      </Box>
    </div>
  );
});

export default ListsInput;
