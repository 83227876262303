import { ShippingWeightUnit } from "@arowana/graphql";
import { OnWheelBlur } from "@arowana/ui";
import { unitLabel } from "@arowana/util";
import { Grid, makeStyles, MenuItem, Select, TextField, Typography } from "@material-ui/core";
import PropTypes from "prop-types";

import FormTooltip from "../../../components/FormTooltip";

const useStyles = makeStyles(theme => ({
  helperText: {
    margin: theme.spacing(0, 1),
    width: "100%",
  },
  unitDropdown: {
    width: "100%",
  },
}));

const BaseWeight = ({ value, onChange, helperText }) => {
  const classes = useStyles();
  const handleChange = (e, key) => {
    onChange({
      ...value,
      [key]: e.target.value,
    });
  };

  return (
    <>
      <Grid item lg={3} md={4} sm={6} xs={12}>
        <Typography gutterBottom variant="subtitle1">
          Base weight{" "}
          <FormTooltip content="This is the weight of your packaging container including boxes, ice packs, or gel packs used in every order to determine a standard shipping fee." />
        </Typography>
        <TextField
          InputProps={{
            inputProps: {
              min: 0,
              step: 0.01,
            },
            onWheel: OnWheelBlur,
          }}
          error={Boolean(helperText)}
          fullWidth
          helperText={helperText}
          onChange={e => handleChange(e, "shippingBaseWeight")}
          type="number"
          value={value.shippingBaseWeight}
          variant="outlined"
        />
      </Grid>
      <Grid item lg={3} md={4} sm={6} xs={12}>
        <Typography gutterBottom variant="subtitle1">
          Base weight unit
        </Typography>
        <Select
          className={classes.unitDropdown}
          onChange={e => handleChange(e, "shippingBaseWeightUnit")}
          value={value.shippingBaseWeightUnit}
          variant="outlined"
        >
          {Object.values(ShippingWeightUnit).map(option => (
            <MenuItem key={`${option}-base-weight-unit`} value={option}>
              {unitLabel(option)}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Typography className={classes.helperText} color="textSecondary" variant="caption">
        Weight of default packaging used for carrier calculated shipping.
      </Typography>
    </>
  );
};

BaseWeight.defaultProps = {
  helperText: undefined,
  onChange: () => {},
  value: {},
};

BaseWeight.propTypes = {
  helperText: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.object,
};

export default BaseWeight;
