import { Link, makeStyles, Typography } from "@material-ui/core";
import IframeResizer from "iframe-resizer-react";
import { useContext, useEffect, useState } from "react";

import PageHeader from "../../../components/PageHeader";
import PaywallCard from "../../../components/PaywallCard";
import { FlagsmithContext } from "../../context/FlagsmithContext";

let URL: string;
switch (process.env.FRESHLINE_ENVIRONMENT) {
  case "staging":
    URL = "https://internal.freshlinestaging.com/embed/supplier/analytics";
    break;

  case "production":
    URL = "https://internal.freshlinemarket.com/embed/supplier/analytics";
    break;

  default:
    URL = "http://internal.freshline.ninja:7979/embed/supplier/analytics";
}
const ANALYTICS_IMG = "https://freshline.io/images/features/sales-analytics-01.png";

const useStyles = makeStyles(_theme => ({
  iframe: {
    border: 0,
    margin: 0,
    minWidth: "100%",
    padding: 0,
    width: "1px",
  },
}));

const Analytics = () => {
  const classes = useStyles();
  const [url, setUrl] = useState<string | undefined>();
  const flagsmith = useContext(FlagsmithContext);
  const hasAnalytics = flagsmith.hasFeature("supplier-analytics");

  useEffect(() => {
    const token = localStorage.getItem("auth_token");

    if (token) {
      setUrl(`${URL}?access_token=${token}`);
    }
  }, [setUrl]);

  return (
    <>
      <PageHeader stickyHeader title="Analytics" />
      {hasAnalytics ? (
        url && <IframeResizer inPageLinks src={url} scrolling={false} className={classes.iframe} />
      ) : (
        <PaywallCard title="Sales and operations analytics" imageUrl={ANALYTICS_IMG}>
          <Typography variant="body1">
            Track critical product, customer, and sales metrics with Freshline Analytics. Review data snapshots across
            to make better decisions across your wholesale and retail operations.{" "}
            <Link href="http://freshline.io/analytics" rel="noreferrer" target="_blank">
              Learn more.
            </Link>
          </Typography>
        </PaywallCard>
      )}
    </>
  );
};

export default Analytics;
