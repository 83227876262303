import { gql, useQuery } from "@apollo/client";
import { AvailabilitySchedulesSortField, Supplier } from "@arowana/graphql";
import { PaginatedTable, SupplierContext, usePagination } from "@arowana/ui";
import { DATALAYER } from "@arowana/util";
import { Box, Button, Link, Typography } from "@material-ui/core";
import { GridSortDirection } from "@material-ui/data-grid";
import { Alert } from "@material-ui/lab";
import moment from "moment-timezone";
import { useContext, useMemo, useState } from "react";
import { Link as RouterLink } from "react-router-dom";

import PageHeader from "../../components/PageHeader";
import Routes from "../../Constants/Routes";

const ROW_PER_PAGE = 50;

const AVAILABILITY_SCHEDULES = gql`
  query AvailabilitySchedules($sort: AvailabilitySchedulesSortInput, $page: PaginationInput) {
    currentSupplier {
      id
      availabilitySchedules(sort: $sort, page: $page) {
        edges {
          cursor
          node {
            id
            name
            descriptionText
            activeAfter
            createdAt
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          totalCount
        }
      }
    }
  }
`;

const getColumns = timezone => [
  {
    field: "id",
    hide: true,
  },
  {
    field: AvailabilitySchedulesSortField.NAME,
    headerName: "Name",
    renderCell: param => (
      <Link component={RouterLink} to={Routes.STORE_AVAILABILITIES_DETAILS.replace(":id", param.row.id)}>
        {param.row.name}
      </Link>
    ),
    width: 120,
  },
  {
    field: "descriptionText",
    headerName: "Internal description",
    sortable: false,
    width: 500,
  },
  {
    field: "activeAfter",
    headerName: "Active after",
    sortable: false,
    valueGetter: param =>
      param.row.activeAfter ? moment.tz(param.row.activeAfter, timezone).format("YYYY-MM-DD") : "Now",
    width: 160,
  },
  {
    field: AvailabilitySchedulesSortField.CREATED_AT,
    headerName: "Created at",
    valueGetter: param => moment.tz(param.row.createdAt, timezone).format("YYYY-MM-DD"),
    width: 160,
  },
];

const AvailabilitiesList = () => {
  const { timezone } = useContext(SupplierContext);

  const [sortState, setSortState] = useState({
    field: AvailabilitySchedulesSortField.CREATED_AT,
    sort: "desc" as GridSortDirection,
  });

  const columns = useMemo(() => getColumns(timezone), [timezone]);

  const { setPageInfo, resetPagination, pagination, page, onPageChange } = usePagination(ROW_PER_PAGE);
  const onSortChange = ({ sortModel }) => {
    resetPagination();
    setSortState(sortModel[0]);
  };

  const { data, loading } = useQuery<{ currentSupplier: Pick<Supplier, "id" | "availabilitySchedules"> }>(
    AVAILABILITY_SCHEDULES,
    {
      context: { source: DATALAYER },
      fetchPolicy: "cache-and-network",
      variables: {
        page: pagination,
        sort: {
          field: sortState.field,
          order: sortState.sort === "desc" ? -1 : 1,
        },
      },
    },
  );

  const connection = data?.currentSupplier?.availabilitySchedules;
  const count = connection?.pageInfo?.totalCount ?? 0;
  const rows = useMemo(() => connection?.edges?.map(({ node }) => node) ?? [], [connection]);

  return (
    <>
      <PageHeader
        stickyHeader
        title="Availability Overrides"
        primaryActions={
          <Button color="primary" component={RouterLink} to={Routes.STORE_AVAILABILITIES_CREATE} variant="contained">
            Create Schedule
          </Button>
        }
      />
      <Box marginBottom={2}>
        <Alert severity="info">
          Availability overrides can be used to adjust future product availability. For example, you can configure them
          to allow customers to place orders for holiday products further in advance than your store's default.
        </Alert>
      </Box>
      <PaginatedTable
        columns={columns}
        defaultContent="No overrides"
        loading={loading}
        onPageChange={onPageChange}
        onSortModelChange={onSortChange}
        page={page}
        pageSize={ROW_PER_PAGE}
        rows={rows}
        sortModel={[sortState]}
        totalCount={count}
      />
    </>
  );
};

export default AvailabilitiesList;
