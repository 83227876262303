import { MenuItem, Select, SelectProps, Typography } from "@material-ui/core";
import { SelectInputProps } from "@material-ui/core/Select/SelectInput";
import { ReactNode } from "react";

import ReportField from "../types/ReportField";

type ExportColumnSelectProps = SelectProps & {
  label?: ReactNode;
  value: string;
  valueFormatter: (fields: ReportField) => string;
  onChange: SelectInputProps["onChange"];
  fields: ReportField[];
  emptyItem?: ReactNode;
};

const ExportColumnSelect = ({
  label,
  value,
  valueFormatter,
  onChange,
  fields,
  emptyItem,
  ...rest
}: ExportColumnSelectProps) => {
  return (
    <>
      <Typography gutterBottom variant="subtitle2">
        {label}
      </Typography>
      <Select displayEmpty fullWidth margin="dense" onChange={onChange} value={value} variant="outlined" {...rest}>
        {rest.disabled && (
          <MenuItem value={""}>
            <em>No columns selected</em>
          </MenuItem>
        )}
        {!rest.disabled && emptyItem}
        {fields.map(field => {
          const fieldValue = valueFormatter(field);

          return (
            <MenuItem key={fieldValue} value={fieldValue}>
              {field.label}
            </MenuItem>
          );
        })}
      </Select>
    </>
  );
};

export default ExportColumnSelect;
