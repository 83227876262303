import { Box, makeStyles, Typography } from "@material-ui/core";

import amex from "../images/paymentSvg/amex.svg";
import diners from "../images/paymentSvg/diners.svg";
import discover from "../images/paymentSvg/discover.svg";
import jcb from "../images/paymentSvg/jcb.svg";
import mastercard from "../images/paymentSvg/mastercard.svg";
import unionpay from "../images/paymentSvg/unionpay.svg";
import visa from "../images/paymentSvg/visa.svg";

const CARD_IMAGES = {
  amex,
  diners,
  discover,
  jcb,
  mastercard,
  unionpay,
  visa,
};

const CARD_LABEL = {
  amex: "American Express",
  diners: "Diners Club",
  discover: "Discover",
  jcb: "JCB",
  mastercard: "MasterCard",
  unionpay: "Union Pay",
  visa: "VISA",
};

const useStyles = makeStyles(theme => ({
  paymentCard: {
    alignItems: "center",
    display: "flex",
  },
  paymentCardDetails: {
    transform: "translateY(-1px)",
  },
  paymentCardLabel: {
    transform: "translateY(2px)",
  },
  paymentCardLogo: {
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: theme.shape.borderRadius / 2,
    display: "inline-block",
    height: theme.spacing(4),
    marginRight: theme.spacing(2),
    padding: theme.spacing(0.5),
    transform: "translateY(2px)",
    width: 50,
  },
}));

interface PaymentCardProps {
  expiryMonth?: number;
  expiryYear?: number;
  last4?: string;
  type?: string;
}

const PaymentCard = ({ expiryMonth, expiryYear, last4, type, ...props }: PaymentCardProps) => {
  const classes = useStyles();
  const expiry =
    expiryMonth && expiryYear ? (
      <span>
        {" "}
        &bull; Expires {expiryMonth}/{expiryYear}
      </span>
    ) : null;

  return (
    <Box className={classes.paymentCard} {...props}>
      <img alt={`${type} card`} className={classes.paymentCardLogo} src={CARD_IMAGES[type?.toLowerCase()]} />
      <Box>
        <Typography className={classes.paymentCardLabel} component="p" variant="body1">
          {CARD_LABEL[type?.toLowerCase()]}
        </Typography>
        <Typography className={classes.paymentCardDetails} color="textSecondary" variant="body2">
          <span>Ending in {last4}</span>
          {expiry}
        </Typography>
      </Box>
    </Box>
  );
};

export default PaymentCard;
