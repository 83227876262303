import { ReactiveVar } from "@apollo/client";
import { PaymentMethod } from "@arowana/graphql";
import { Collapse, FormControl, FormLabel, MenuItem, Select, SelectProps } from "@material-ui/core";

import { NotificationVarType } from "../Notification";
import AccountPaymentMethodForm from "./AccountPaymentMethodForm";
import PaymentMethodItem from "./PaymentMethodItem";

type PaymentMethodSelectProps = {
  account: { id: string };
  showPaymentForm: boolean;
  paymentMethods: PaymentMethod[];
  onChange: SelectProps["onChange"];
  onAdd: (paymentMethod: Partial<PaymentMethod>) => void;
  onCancelAdd: () => void;
  value?: string;
  notificationVar: ReactiveVar<NotificationVarType>;
  label?: React.ReactNode;
};

export const PaymentMethodSelect = ({
  account,
  label = <FormLabel style={{ marginBottom: 8 }}>Payment method</FormLabel>,
  onAdd,
  onCancelAdd,
  onChange,
  paymentMethods,
  showPaymentForm,
  value,
  notificationVar,
}: PaymentMethodSelectProps) => {
  return (
    <FormControl fullWidth>
      {label}
      <Collapse in={!showPaymentForm}>
        <Select value={value} onChange={onChange} margin="dense" fullWidth variant="outlined">
          {paymentMethods?.map(pm => (
            <MenuItem key={pm.id} value={pm.id}>
              <PaymentMethodItem dense paymentMethod={pm} />
            </MenuItem>
          ))}
          <MenuItem value="add">Add payment method</MenuItem>
        </Select>
      </Collapse>
      <Collapse in={showPaymentForm}>
        <AccountPaymentMethodForm
          account={account}
          onAdd={onAdd}
          onAddCancel={onCancelAdd}
          notificationVar={notificationVar}
        />
      </Collapse>
    </FormControl>
  );
};

export default PaymentMethodSelect;
