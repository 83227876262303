import { OrderAudience } from "@arowana/graphql";
import { Box, FormControl, InputLabel, makeStyles, MenuItem, Select } from "@material-ui/core";

import { AUDIENCE_DISPLAY } from "../../../utils/audienceDisplay";
import { FilterParams } from "../pages/RecurringOrders";
import RecurringOrderListSearch from "./RecurringOrderListSearch";

interface FilterOption {
  label: string;
  value: string;
}

interface FiltersProps {
  filterParams: { [key in FilterParams]: string };
  updateFilter: (props: { [key in FilterParams]?: string }) => void;
}

const useStyles = makeStyles(theme => ({
  filters: {
    flex: "0 1 175px",
    [theme.breakpoints.down("sm")]: {
      flex: "0 1 45%",
    },
  },
  filtersContainer: {
    "& > *": {
      margin: theme.spacing(2, 0, 0, 2),
    },
    "display": "inline-flex",
    "flexFlow": "row wrap",
    "justifyContent": "flex-end",
    "margin": theme.spacing(-1, 0, 0, -2),
    "marginBottom": theme.spacing(2),
    "width": `calc(100% + ${theme.spacing(2)}px)`,
    [theme.breakpoints.down("sm")]: {
      "justify-content": "space-between",
    },
  },
}));

const ACTIVE_OPTIONS: FilterOption[] = [
  { label: "All", value: "ALL" },
  { label: "Active", value: "true" },
  { label: "Inactive", value: "false" },
];

const AUDIENCE_OPTIONS: FilterOption[] = [
  { label: "All", value: "ALL" },
  ...Object.values(OrderAudience).map(audience => ({
    disabled: audience === OrderAudience.WHOLESALE,
    label: AUDIENCE_DISPLAY[audience],
    value: audience,
  })),
];

const RecurringOrderFilters = ({ filterParams, updateFilter }: FiltersProps) => {
  const classes = useStyles();

  const { active = "ALL", audience = "ALL" } = filterParams ?? {};

  const onFilterChange = (key: FilterParams, event: React.ChangeEvent<HTMLSelectElement>) =>
    updateFilter({ [key]: event.target.value });

  return (
    <Box className={classes.filtersContainer} flex="3">
      <RecurringOrderListSearch
        label="Search by collection name"
        onChange={({ id }) => updateFilter({ recurringOrderListId: id })}
        placeholder="Type collection name..."
      />

      <FormControl className={classes.filters} variant="outlined">
        <InputLabel htmlFor="audience-select">Audience</InputLabel>
        <Select
          defaultValue="ALL"
          inputProps={{
            id: "audience-select",
          }}
          label="Audience"
          margin="dense"
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => onFilterChange(FilterParams.audience, e)}
          title="Audience"
          value={audience}
          variant="outlined"
        >
          {AUDIENCE_OPTIONS.map(({ label, value }) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl className={classes.filters} variant="outlined">
        <InputLabel htmlFor="active-select">Status</InputLabel>
        <Select
          defaultValue="ALL"
          inputProps={{
            id: "active-select",
          }}
          label="Active"
          margin="dense"
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => onFilterChange(FilterParams.active, e)}
          title="Active"
          value={active}
          variant="outlined"
        >
          {ACTIVE_OPTIONS.map(({ label, value }) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default RecurringOrderFilters;
