import { gql, useMutation, useQuery } from "@apollo/client";
import {
  Account,
  FulfillmentMethod,
  MutationToRetailCartUpdateArgs,
  Query,
  QueryToRetailCartArgs,
  RetailCart,
  Supplier,
} from "@arowana/graphql";
import { AddressSelect, CalculationLine, PaymentMethodSelect, SupplierContext } from "@arowana/ui";
import { DATALAYER } from "@arowana/util";
import {
  Box,
  Button,
  Collapse,
  Fade,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputAdornment,
  Link,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";
import { Alert, Skeleton } from "@material-ui/lab";
import moment from "moment";
import qs from "qs";
import { useContext, useEffect, useMemo, useState } from "react";
import { Prompt } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";

import FormCard from "../../../components/FormCard";
import FulfillmentChip from "../../../components/FulfillmentChip";
import Loader from "../../../components/Loader";
import PageHeader from "../../../components/PageHeader";
import Routes from "../../../Constants/Routes";
import amountUtility from "../../../utils/amountUtility";
import RadioSelector from "../../account/components/RadioSelector";
import CustomerAutocomplete from "../component/CustomerAutocomplete";
import OrderProductInputList from "../component/OrderProductInputList";
import WholesaleOrderProductInputList from "../component/WholesaleOrderProductInputList";

const ACCOUNT = gql`
  query Account($id: ID!) {
    account(id: $id) {
      id
      name
      email
      phone
      addresses {
        id
        label
        formattedAddress
        notes
        lngLat {
          coordinates
        }
      }
      paymentMethods {
        id
        card {
          brand
          last4
          expiryMonth
          expiryYear
        }
      }
    }
    currentSupplier {
      id
      locations {
        id
        active
        address {
          formattedAddress
        }
        fulfillmentMethod
        fulfillmentTimes {
          id
          label
        }
        name
      }
    }
  }
`;

export const RETAIL_CART_FRAGMENT = gql`
  fragment RetailCartFields on RetailCart {
    id
    canCheckout
    skipInventoryCheck
    updatedAt
    address {
      id
    }
    discountCode
    estimatedPreAuthorizationAmount
    fulfillmentDate
    fulfillmentDates {
      dates
      hasAllItems
      hasSomeItems
    }
    giftcardCode
    fulfillmentWindow {
      id
      label
    }
    lineItems {
      amount
      amountLabel
      product {
        id
        name
      }
      productId
      quantity
      variant {
        id
        caseSize
        name
        price
        quantity
        unit
      }
      variantId
    }
    location {
      id
      active
      cutOffHour
      description
      discountedDeliveryFee
      discountedDeliveryFeeThreshold
      fulfillmentMethod
      fulfillmentTimes {
        id
        label
      }
      minimumOrderAmount
      name
      tipsEnabled
    }
    locationId
    notes
    paymentMethodId
    tipAmount
    tipAmountLabel
    priceBreakdown {
      discounts {
        items {
          amount
          description
          itemType
        }
        total
      }
      fees {
        items {
          amount
          description
          itemType
        }
        total
      }
      subTotal
      subTotalLabel
      total
      totalLabel
    }
  }
`;

const RETAIL_CART = gql`
  ${RETAIL_CART_FRAGMENT}
  query RetailCart($accountId: ID!) {
    retailCartForAccount(accountId: $accountId) {
      ...RetailCartFields
    }
  }
`;

const RETAIL_CART_UPDATE = gql`
  ${RETAIL_CART_FRAGMENT}
  mutation RetailCartUpdate($input: RetailCartUpdateInput!) {
    retailCartUpdate(input: $input) {
      ...RetailCartFields
    }
  }
`;

const CHECKOUT = gql`
  mutation Checkout($id: ID!) {
    retailCartCheckout(id: $id) {
      id
    }
  }
`;

const DELIVERY_SHIPPING = FulfillmentMethod.delivery + ":" + FulfillmentMethod.shipping;

type AccountArgs = {
  id: string;
};

type AccountResponse = {
  account?: Pick<Account, "id" | "name" | "phone" | "addresses" | "paymentMethods">;
  currentSupplier?: Pick<Supplier, "id" | "locations">;
};

const RetailOrderCreate = ({ history }) => {
  const params = qs.parse(history.location.search, { ignoreQueryPrefix: true });
  const { timezone } = useContext(SupplierContext);

  const accountId = params?.customerId as string;
  const onAccountChange = ({ id }) =>
    history.replace({
      search: qs.stringify({
        ...params,
        customerId: id,
      }),
    });
  const onAccountRemove = () =>
    history.replace({
      search: null,
    });

  const { data: accountData } = useQuery<AccountResponse, AccountArgs>(ACCOUNT, {
    context: { source: DATALAYER },
    fetchPolicy: "cache-and-network",
    skip: !accountId,
    variables: {
      id: accountId,
    },
  });

  const account = accountData?.account;
  const supplier = accountData?.currentSupplier;
  const [pickupLocations, deliveryShippingLocations] = useMemo(() => {
    const pickup = [];
    const deliveryShipping = [];

    supplier?.locations?.forEach(loc => {
      if (loc.fulfillmentMethod === FulfillmentMethod.pickup) {
        pickup.push(loc);
      } else {
        deliveryShipping.push(loc);
      }
    });

    return [pickup, deliveryShipping];
  }, [supplier?.locations]);

  const fulfillmentOptions = useMemo(() => {
    return [
      {
        disabled: deliveryShippingLocations.length === 0,
        label: "Delivery/Shipping",
        value: DELIVERY_SHIPPING,
      },
      { disabled: pickupLocations.length === 0, label: "Pickup", value: FulfillmentMethod.pickup },
    ];
  }, [supplier?.locations, pickupLocations]);
  const accountURL = Routes.CUSTOMER_DETAILS.replace(":id", accountId);

  const { data: cartData, loading: loadingCart } = useQuery<{ retailCartForAccount: RetailCart }>(RETAIL_CART, {
    context: { source: DATALAYER },
    fetchPolicy: "cache-and-network",
    skip: !accountId,
    variables: {
      accountId,
    },
  });
  const cart = cartData?.retailCartForAccount;
  const { lineItems = [], notes = "", location: cartLocation } = cart ?? {};
  const { fees = [], subTotal = 0, total = 0, discounts = [] } = cart?.priceBreakdown ?? {};
  const hasLineItems = lineItems.length > 0;
  const lineItemsValues = useMemo(
    () =>
      lineItems.map(({ product, quantity, variant }) => ({
        caseSize: variant.caseSize,
        pricePerUnit: variant.price,
        productId: product.id,
        productName: product.name,
        quantity,
        unit: variant.unit,
        variantId: variant.id,
        variantName: variant.name,
      })),
    [lineItems],
  );

  const [cartUpdate] = useMutation<unknown, MutationToRetailCartUpdateArgs>(RETAIL_CART_UPDATE, {
    context: { source: DATALAYER },
    refetchQueries: ["RetailCart"],
  });

  const [submitOrder, { data: orderData, loading: isSubmitting, error }] = useMutation<{
    retailCartCheckout: Pick<RetailCart, "id">;
  }>(CHECKOUT, {
    context: { source: DATALAYER },
    onCompleted: ({ retailCartCheckout }) => {
      history.push(Routes.ORDER_DETAILS.replace(":id", retailCartCheckout.id));
    },
    variables: {
      id: cart?.id,
    },
  });
  const orderSuccess = Boolean(orderData?.retailCartCheckout?.id);

  // Product
  const onVariantAdd = variantId =>
    cartUpdate({
      variables: {
        input: {
          id: cart?.id,
          lineItems: [{ quantity: 1, variantId }],
        },
      },
    });

  const { callback: onVariantChange } = useDebouncedCallback((variantId, { quantity, pricePerUnit: priceOverride }) => {
    cartUpdate({
      variables: {
        input: {
          id: cart?.id,
          lineItems: [{ priceOverride, quantity, variantId }],
        },
      },
    });
  }, 500);

  const onVariantRemove = variantId =>
    cartUpdate({
      variables: {
        input: {
          id: cart?.id,
          lineItems: [{ quantity: 0, variantId }],
        },
      },
    });

  const [removingAll, setRemovingAll] = useState(false);
  const onRemoveAllProduct = () => {
    setRemovingAll(true);
    cartUpdate({
      variables: {
        input: {
          id: cart?.id,
          lineItems: lineItems.map(({ variantId, quantity }) => ({ quantity: 0, variantId })),
        },
      },
    }).finally(() => setRemovingAll(false));
  };

  const trackInventory = !cart?.skipInventoryCheck;
  const onTrackInventoryChange = event =>
    cartUpdate({
      variables: {
        input: {
          id: cart?.id,
          skipInventoryCheck: !event.target.checked,
        },
      },
    });

  // Fulfillment Loc
  const isPickup = cartLocation?.fulfillmentMethod === FulfillmentMethod.pickup;
  const fulfillmentMethodRadioValue = useMemo(() => {
    if (isPickup) {
      return cartLocation?.fulfillmentMethod;
    } else {
      return DELIVERY_SHIPPING;
    }
  }, [cartLocation?.fulfillmentMethod]);
  const fulfillmentLocations = isPickup ? pickupLocations : deliveryShippingLocations;

  const onFulfillmentMethodToggle = value => {
    let variables: MutationToRetailCartUpdateArgs;
    switch (value) {
      case DELIVERY_SHIPPING: {
        if (account.addresses?.length > 0) {
          variables = {
            input: {
              accountAddressId: account.addresses?.[0]?.id,
              id: cart?.id,
            },
          };
        } else {
          // default to some delivery location, if no address is on record
          variables = {
            input: {
              id: cart?.id,
              locationId: deliveryShippingLocations?.[0]?.id,
            },
          };
        }

        break;
      }

      case FulfillmentMethod.pickup: {
        variables = {
          input: {
            id: cart?.id,
            locationId: pickupLocations?.[0]?.id,
          },
        };
      }
    }

    cartUpdate({ variables });
  };

  const locationId = cart?.location?.id ?? "";
  const onLocationChange = event => {
    cartUpdate({
      variables: {
        input: {
          id: cart?.id,
          locationId: event?.target?.value,
        },
      },
    });
  };

  const onAccountAddressChange = event => {
    const accountAddressId = event?.target?.value;

    if (accountAddressId === "add") {
      history.push(Routes.CUSTOMER_DETAILS.replace(":id", account?.id));
    } else {
      cartUpdate({
        variables: {
          input: {
            accountAddressId,
            id: cart?.id,
          },
        },
      });
    }
  };

  // Fulfillment Time
  const fulfillmentWindowId = cart?.fulfillmentWindow?.id ?? "";
  const fulfillmentTimes = useMemo(
    () => supplier?.locations?.find(({ id }) => id === locationId)?.fulfillmentTimes ?? [],
    [supplier?.locations, locationId],
  );
  const onTimeChange = event => {
    cartUpdate({
      variables: {
        input: {
          fulfillmentWindow: {
            id: event?.target?.value,
          },
          id: cart?.id,
        },
      },
    });
  };

  // Fulfillment Date
  const fulfillmentDate = cart?.fulfillmentDate
    ? moment.tz(cart?.fulfillmentDate, timezone).format("YYYY-MM-DD")
    : null;
  const { callback: onDateChange } = useDebouncedCallback(
    event =>
      cartUpdate({
        variables: {
          input: {
            fulfillmentDate: moment.tz(event.target.value, timezone).hour(0).utc().toISOString(),
            id: cart?.id,
          },
        },
      }),
    500,
  );
  useEffect(() => {
    if (cart?.id && !fulfillmentDate) {
      cartUpdate({
        variables: {
          input: {
            fulfillmentDate: moment().hour(0).utc().toISOString(),
            id: cart?.id,
          },
        },
      });
    }
  }, [cart?.id, fulfillmentDate]);

  // Notes
  const { callback: onNoteChange } = useDebouncedCallback(event => {
    cartUpdate({
      variables: {
        input: {
          id: cart?.id,
          notes: event.target.value,
        },
      },
    });
  }, 500);

  // Payment
  const giftcardCodeApplied = cart?.giftcardCode ?? "";
  const onPaymentMethodChange = event => {
    const paymentMethodId = event?.target?.value;

    if (paymentMethodId === "add") {
      history.push(Routes.CUSTOMER_DETAILS.replace(":id", account?.id));
    } else {
      cartUpdate({
        variables: {
          input: {
            id: cart?.id,
            paymentMethodId,
          },
        },
      });
    }
  };

  const tipAmountValue = cart ? amountUtility.amountToDollarAndCents(cart.tipAmount ?? 0) : null;
  const { callback: onTipChange } = useDebouncedCallback(event => {
    cartUpdate({
      variables: {
        input: {
          id: cart?.id,
          tipAmount: amountUtility.dollarsAndCentsToAmount(event.target.value),
        },
      },
    });
  }, 500);

  // Checkout
  const canCheckout = cart?.canCheckout;
  const hasInactiveOrOutofStock = useMemo(
    () => hasLineItems && lineItems.some(({ product }) => product.outOfStock || !product.active),
    [hasLineItems, lineItems],
  );

  let controls;

  if (account) {
    controls = (
      <>
        <FormCard
          title="Products"
          action={
            <Fade in={lineItems.length > 0}>
              <Button
                color="secondary"
                variant="outlined"
                size="small"
                onClick={onRemoveAllProduct}
                disabled={removingAll}
              >
                Remove all
              </Button>
            </Fade>
          }
        >
          <OrderProductInputList
            value={lineItemsValues}
            onVariantAdd={onVariantAdd}
            onVariantChange={onVariantChange}
            onVariantRemove={onVariantRemove}
          />
          <FormControlLabel
            control={<Switch color="primary" checked={trackInventory} onChange={onTrackInventoryChange} />}
            label="Track inventory"
          />
          <Collapse in={trackInventory && hasInactiveOrOutofStock}>
            <Alert severity="warning">Some item(s) are inactive or out of stock, please proceed with caution</Alert>
          </Collapse>
        </FormCard>

        <FormCard title="Fulfillment">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <RadioSelector
                options={fulfillmentOptions}
                value={fulfillmentMethodRadioValue}
                onChange={onFulfillmentMethodToggle}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl component="fieldset" fullWidth margin="dense" variant="outlined" disabled={!isPickup}>
                <FormLabel id="locaiton-label" component="legend" htmlFor="location" style={{ marginBottom: 8 }}>
                  Service location
                </FormLabel>
                <Select labelId="location-label" id="location" value={locationId} onChange={onLocationChange}>
                  {fulfillmentLocations?.map(({ id, name, fulfillmentMethod, address }) => (
                    <MenuItem value={id} key={id}>
                      <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                        <div>
                          <Typography>{name}</Typography>
                          <Typography variant="body2">{address?.formattedAddress}</Typography>
                        </div>
                        <FulfillmentChip fulfillmentType={fulfillmentMethod} />
                      </Box>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {!isPickup && (
              <Grid item xs={12}>
                <FormLabel id="locaiton-label" component="legend" htmlFor="location" style={{ marginBottom: 8 }}>
                  Customer address
                </FormLabel>
                <AddressSelect
                  addresses={account?.addresses}
                  value={cart?.address?.id ?? ""}
                  onChange={onAccountAddressChange}
                />
              </Grid>
            )}
            <Grid item xs={6}>
              <FormControl component="fieldset" fullWidth margin="dense" variant="outlined">
                <FormLabel id="date-label" component="legend" htmlFor="date">
                  Fulfillment Date
                </FormLabel>
                {fulfillmentDate ? (
                  <TextField
                    id="date"
                    type="date"
                    variant="outlined"
                    margin="dense"
                    defaultValue={fulfillmentDate}
                    onChange={onDateChange}
                  />
                ) : (
                  <Skeleton variant="rect" width="100%" height={38} />
                )}
              </FormControl>
            </Grid>
            {fulfillmentTimes.length > 0 && (
              <Grid item xs={6}>
                <FormControl component="fieldset" fullWidth margin="dense" variant="outlined">
                  <FormLabel id="time-label" component="legend" htmlFor="location" style={{ marginBottom: 8 }}>
                    Time window
                  </FormLabel>
                  <Select
                    margin="dense"
                    labelId="time-label"
                    id="time"
                    value={fulfillmentWindowId}
                    onChange={onTimeChange}
                  >
                    {fulfillmentTimes.map(({ id, label }) => {
                      return (
                        <MenuItem key={id} value={id}>
                          {label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
            )}
            <Grid item xs={12}>
              <FormControl component="fieldset" fullWidth margin="dense" variant="outlined">
                <FormLabel id="notes-label" component="legend" htmlFor="notes">
                  Order notes (optional)
                </FormLabel>
                <TextField
                  id="notes"
                  fullWidth
                  maxRows={10}
                  minRows={5}
                  multiline
                  defaultValue={notes}
                  onChange={onNoteChange}
                  margin="dense"
                  variant="outlined"
                />
              </FormControl>
            </Grid>
          </Grid>
        </FormCard>

        <FormCard title="Payment">
          <Grid container spacing={2}>
            <Grid item sm={12} md={6}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <PaymentMethodSelect
                  account={account}
                  value={cart?.paymentMethodId ?? ""}
                  paymentMethods={account?.paymentMethods}
                  onChange={onPaymentMethodChange}
                  label={
                    <FormLabel component="legend" style={{ marginBottom: 8 }}>
                      Payment method
                    </FormLabel>
                  }
                />
              </FormControl>
            </Grid>
            <Grid item sm={12} md={6}>
              <FormControl component="fieldset" fullWidth margin="normal">
                <FormLabel component="legend">Tips</FormLabel>
                {tipAmountValue ? (
                  <TextField
                    defaultValue={tipAmountValue}
                    onChange={onTipChange}
                    InputProps={{
                      inputProps: {
                        min: 0,
                        step: 0.01,
                        type: "number",
                      },
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                    margin="dense"
                    name="tipAmount"
                    variant="outlined"
                  />
                ) : (
                  <Skeleton variant="rect" width="100%" height={38} />
                )}
              </FormControl>
            </Grid>
          </Grid>
        </FormCard>
      </>
    );
  }

  return (
    <>
      <Loader loading={isSubmitting && !error} />
      <Prompt
        message="The order is incomplete; are you sure you want to leave?"
        when={Boolean(cart) && !orderSuccess}
      />
      <PageHeader
        primaryActions={
          <Button color="primary" disabled={!canCheckout || loadingCart} onClick={submitOrder} variant="contained">
            Create
          </Button>
        }
        stickyHeader
        title="Create retail order"
      />
      <Grid container spacing={2} style={{ position: "relative" }}>
        <Grid item md={9} xs={12}>
          <FormCard
            title="Customer"
            action={
              <Fade in={Boolean(accountId)}>
                <Button size="small" variant="outlined" color="secondary" onClick={onAccountRemove}>
                  Remove
                </Button>
              </Fade>
            }
          >
            <Collapse in={!accountId}>
              <Typography gutterBottom>Please select a customer to continue</Typography>
              <CustomerAutocomplete onChange={onAccountChange} />
            </Collapse>
            <Collapse in={Boolean(account)}>
              <Typography variant="body1">
                <Link component={RouterLink} to={accountURL}>
                  {account?.name}
                </Link>{" "}
                • {account?.email}
              </Typography>
              <Typography variant="body1">{account?.phone}</Typography>
            </Collapse>
          </FormCard>
          {controls}
        </Grid>
        <Grid item md={3} xs={12} style={{ position: "sticky" }}>
          <FormCard title="Summary">
            <CalculationLine label="Subtotal" loading={loadingCart} cents={subTotal} />
            {fees?.items?.map(({ amount, itemType, description }, idx) => (
              <CalculationLine key={`${idx}_${itemType}`} label={description} loading={loadingCart} cents={amount} />
            ))}
            {discounts?.items?.map(({ amount, itemType, description }, idx) => (
              <CalculationLine key={`${idx}_${itemType}`} label={description} loading={loadingCart} cents={amount} />
            ))}
            <CalculationLine label="Total" loading={loadingCart} cents={total} variant="h6" />
          </FormCard>
        </Grid>
      </Grid>
    </>
  );
};

export default RetailOrderCreate;
