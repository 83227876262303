import { gql, useMutation, useQuery } from "@apollo/client";
import { ClientsSortField } from "@arowana/graphql";
import { PaginatedTable, QuickSearch, usePagination } from "@arowana/ui";
import { DATALAYER } from "@arowana/util";
import { Box, Button, Link, ListItemText, makeStyles, Typography } from "@material-ui/core";
import { GridAlignment, GridSortDirection } from "@material-ui/data-grid";
import moment from "moment-timezone";
import { useContext, useMemo, useState } from "react";
import { Link as RouterLink } from "react-router-dom";

import PageHeader from "../../../components/PageHeader";
import PaywallCard from "../../../components/PaywallCard";
import Routes from "../../../Constants/Routes";
import { FlagsmithContext } from "../../context/FlagsmithContext";
import ClientAutocomplete from "../components/ClientAutocomplete";

const CLIENTS = gql`
  query Clients($filter: ClientsFilterInput, $sort: ClientsSortInput, $page: PaginationInput) {
    currentSupplier {
      id
      timezone
      clients(filter: $filter, sort: $sort, page: $page) {
        edges {
          cursor
          node {
            id
            archivedAt
            address {
              id
              formattedAddress
            }
            approvedAt
            createdAt
            name
            notes
            phone
            repAccount {
              id
              name
            }
          }
        }
        pageInfo {
          count
          endCursor
          hasNextPage
          totalCount
        }
      }
    }
  }
`;

const CLIENT_UNARCHIVE = gql`
  mutation ClientUnrchive($id: ID!) {
    clientUnarchive(clientIds: [$id])
  }
`;

const WHOLESALE_IMG = "https://freshline.io/images/b2b-hero.png";

const COLUMNS = [
  {
    field: "id",
    hide: true,
  },
  {
    field: ClientsSortField.NAME,
    headerName: "Name",
    renderCell: param => (
      <Link component={RouterLink} to={Routes.WHOLESALE_CLIENT_DETAILS.replace(":id", param.row.id)}>
        {param.value}
      </Link>
    ),
    sortable: true,
    valueGetter: param => param.row.name,
    width: 250,
  },
  {
    field: ClientsSortField.PHONE,
    headerName: "Phone",
    sortable: false,
    valueGetter: param => param.row.phone,
    width: 150,
  },
  {
    field: ClientsSortField.APPROVED_AT,
    headerName: "Approved on",
    sortable: true,
    valueGetter: param => {
      const m = moment(param.row.approvedAt);
      const valid = m.isValid();

      return valid ? m.format("LL") : "Not approved";
    },
    width: 150,
  },
  {
    field: ClientsSortField.CREATED_AT,
    headerName: "Created on",
    sortable: true,
    valueGetter: param => {
      const m = moment(param.row.createdAt);
      const valid = m.isValid();

      return valid ? m.format("LL") : "";
    },
    width: 150,
  },
  {
    field: "repAccount",
    headerName: "Rep account",
    sortable: false,
    valueGetter: param => param.value?.name,
    width: 200,
  },
  {
    field: "address",
    flex: 1,
    headerName: "Address",
    sortable: false,
    valueGetter: param => param.value.formattedAddress,
  },
  {
    field: "notes",
    headerName: "Notes",
    sortable: false,
    width: 150,
  },
];
const defaultSortState = {
  field: ClientsSortField.APPROVED_AT,
  sort: "desc" as GridSortDirection,
};

const ROW_PER_PAGE = 100;

const useStyles = makeStyles(theme => ({
  search: {
    marginBottom: theme.spacing(2),
  },
}));

const Clients = ({ history }) => {
  const classes = useStyles();

  const flagsmith = useContext(FlagsmithContext);
  const hasWholesale = flagsmith.hasFeature("b2b");

  const [sortState, setSortState] = useState(defaultSortState);
  const { setPageInfo, resetPagination, pagination, page, onPageChange } = usePagination(ROW_PER_PAGE);

  const { loading: loadingDefault, data } = useQuery(CLIENTS, {
    context: { source: DATALAYER },
    fetchPolicy: "cache-and-network",
    onCompleted: response => {
      setPageInfo(response?.currentSupplier?.clients?.pageInfo);
    },
    onError: resetPagination,
    variables: {
      page: pagination,
      sort: {
        field: sortState.field,
        order: sortState.sort === "desc" ? -1 : 1,
      },
    },
  });

  const {
    setPageInfo: archivedSetPageInfo,
    resetPagination: archivedResetPagination,
    pagination: archivedPagination,
    page: archivedPage,
    onPageChange: archivedOnPageChange,
  } = usePagination(ROW_PER_PAGE);
  const { loading: loadingArchivedClients, data: archivedClients } = useQuery(CLIENTS, {
    context: { source: DATALAYER },
    fetchPolicy: "cache-and-network",
    onCompleted: response => {
      archivedSetPageInfo(response?.currentSupplier?.clients?.pageInfo);
    },
    onError: archivedResetPagination,
    variables: {
      filter: { archived: true },
      page: archivedPagination,
      sort: {},
    },
  });

  const totalCount = data?.currentSupplier?.clients?.pageInfo?.totalCount ?? 0;
  const archivedTotalCount = archivedClients?.currentSupplier?.clients?.pageInfo?.totalCount ?? 0;

  const onSortChange = ({ sortModel }) => {
    resetPagination();
    setSortState(sortModel[0]);
  };

  const rows = useMemo(
    () =>
      data?.currentSupplier?.clients?.edges.map(({ node }) => ({
        ...node,
      })) ?? [],
    [data?.currentSupplier?.clients],
  );
  const archivedRows = useMemo(
    () =>
      archivedClients?.currentSupplier?.clients?.edges.map(({ node }) => ({
        ...node,
      })) ?? [],
    [archivedClients?.currentSupplier?.clients],
  );

  const sortModel = sortState ? [sortState] : [];

  const onPageChangeExtra = params => {
    window.scrollTo(0, 0);
    onPageChange(params);
  };

  const handleUnarchiveClient = id => () => {
    clientUnarchive({
      variables: {
        id: id,
      },
    });
  };

  const [clientUnarchive, { loading: loadingClientUnarchive }] = useMutation(CLIENT_UNARCHIVE, {
    context: { source: DATALAYER },
    onCompleted: () => {
      notificationVar({
        message: "Business unarchived!",
        severity: "success",
      });
    },
    refetchQueries: ["Clients"],
  });

  const onSearchSelect = ({ id }) => history.push(Routes.WHOLESALE_CLIENT_DETAILS.replace(":id", id));

  const ARCHIVED_COLUMNS = [
    {
      field: "id",
      hide: true,
    },
    {
      field: ClientsSortField.NAME,
      flex: 1,
      headerName: "Name",
      sortable: false,
      valueGetter: param => param.row.name,
    },
    {
      field: "archivedAt",
      flex: 1,
      headerName: "Archived at",
      renderCell: param => moment(param.row.archivedAt).tz(archivedClients?.currentSupplier?.timezone).format("LLL"),
      sortable: false,
    },
    {
      align: "right",
      field: "active",
      headerName: "Unarchive",
      renderCell: param => (
        <Button onClick={handleUnarchiveClient(param.row.id)} variant="outlined" size="small">
          Unarchive
        </Button>
      ),
      sortable: false,
    },
  ];

  return (
    <>
      <PageHeader
        stickyHeader
        title="Businesses"
        primaryActions={
          <>
            <Button
              color="primary"
              variant="contained"
              component={RouterLink}
              to={Routes.WHOLESALE_CLIENT_CREATE}
              disabled={!hasWholesale}
            >
              Create Business
            </Button>
          </>
        }
      />
      {hasWholesale ? (
        <>
          <ClientAutocomplete className={classes.search} onChange={onSearchSelect} />

          <PaginatedTable
            columns={COLUMNS}
            defaultContent="No businesses"
            loading={loadingDefault}
            onPageChange={onPageChangeExtra}
            onSortModelChange={onSortChange}
            page={page}
            pageSize={ROW_PER_PAGE}
            rows={rows}
            sortModel={sortModel}
            totalCount={totalCount}
          />

          <Box my={4}>
            <Typography component="h3" variant="h6" gutterBottom>
              Archived businesses
            </Typography>
            <PaginatedTable
              columns={ARCHIVED_COLUMNS}
              defaultContent="No archived businesses"
              loading={loadingArchivedClients}
              onPageChange={archivedOnPageChange}
              page={archivedPage}
              pageSize={ROW_PER_PAGE}
              rows={archivedRows}
              totalCount={archivedTotalCount}
            />
          </Box>
        </>
      ) : (
        <PaywallCard title="Wholesale customers" imageUrl={WHOLESALE_IMG}>
          <Typography variant="body1">
            Freshline's B2B online ordering portal makes receiving and managing orders 10x more efficient for you and
            your wholesale customers.{" "}
            <Link href="http://freshline.io/b2b" rel="noreferrer" target="_blank">
              Learn more.
            </Link>
          </Typography>
        </PaywallCard>
      )}
    </>
  );
};

export default Clients;
