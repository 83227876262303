import { gql } from "@apollo/client";

const appShippingConnectUps = gql`
  mutation AppShippingConnectUps($input: ShippingConnectUpsInput!) {
    appShippingConnectUps(input: $input) {
      id
      name
      defaultServiceCode
      region
    }
  }
`;

export default appShippingConnectUps;
