const Audiences = [
  {
    id: "ALL",
    label: "All",
  },
  {
    id: "MARKETPLACE",
    label: "Retail",
  },
  {
    id: "WHOLESALE",
    label: "Wholesale",
  },
];

export default Audiences;
