import { gql, useMutation, useQuery } from "@apollo/client";
import { FEATURE_SUPPLIER_RECURRING_ORDERS_FOR_B2B } from "@arowana/flags";
import {
  CadenceType,
  FulfillmentMethod,
  ISOWeekDay,
  OrderAudience,
  RecurringOrderList,
  RecurringOrderListCreateInput,
  RecurringOrderListUpdateInput,
} from "@arowana/graphql";
import { OnWheelBlur } from "@arowana/ui";
import { DATALAYER } from "@arowana/util";
import { DevTool } from "@hookform/devtools";
import { useWorker, WORKER_STATUS } from "@koale/useworker";
import {
  Button,
  Checkbox,
  Collapse,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  InputAdornment,
  Link,
  makeStyles,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import capitalize from "lodash/capitalize";
import moment from "moment";
import { useContext, useEffect } from "react";
import { useMemo } from "react";
import { Controller, DeepMap, useFieldArray, useForm } from "react-hook-form";

import { notificationVar } from "../../../cache/notificationPolicy";
import DropdownSelect from "../../../components/DropdownSelect";
import FormCard from "../../../components/FormCard";
import FormTooltip from "../../../components/FormTooltip";
import Loader from "../../../components/Loader";
import PageHeader from "../../../components/PageHeader";
import RichTextFormComponent from "../../../components/RichTextFormComponent";
import Routes from "../../../Constants/Routes";
import { AUDIENCE_DISPLAY } from "../../../utils/audienceDisplay";
import SwitchToggle from "../../account/components/SwitchToggle";
import { AccountContext } from "../../context/AccountContext";
import { FlagsmithContext } from "../../context/FlagsmithContext";
import OverridesList, { FormMode } from "../../wholesale/components/OverridesList";
import { OverrideFieldArrayProps, OverrideFormProps } from "../../wholesale/pages/List";
import FulfillmentDays from "../components/FulfillmentDays";
import FulfillmentMethods from "../components/FulfillmentMethods";

const RECURRING_ORDER_LIST_FRAGMENT = gql`
  fragment RecurringOrderListFragment on RecurringOrderList {
    id
    active
    audience
    cadenceType
    description
    frequency
    fulfillmentDays
    fulfillmentMethods
    name
    overrides {
      id
      active
      name
      price
      productId
      variantId
      variant {
        caseSize
        id
        name
        unit
      }
    }
    public
    subscriberSkippable
    updatedAt
  }
`;

const RECURRING_ORDER_LIST = gql`
  query RecurringOrderList($id: ID!) {
    recurringOrderList(id: $id) {
      ...RecurringOrderListFragment
    }
  }
  ${RECURRING_ORDER_LIST_FRAGMENT}
`;

const RECURRING_ORDER_LIST_CREATE = gql`
  mutation RecurringOrderListCreate($input: RecurringOrderListCreateInput!) {
    recurringOrderListCreate(input: $input) {
      ...RecurringOrderListFragment
    }
  }
  ${RECURRING_ORDER_LIST_FRAGMENT}
`;

const RECURRING_ORDER_LIST_UPDATE = gql`
  mutation RecurringOrderListUpdate($input: RecurringOrderListUpdateInput!) {
    recurringOrderListUpdate(input: $input) {
      ...RecurringOrderListFragment
    }
  }
  ${RECURRING_ORDER_LIST_FRAGMENT}
`;

const useStyles = makeStyles(theme => ({
  bottomSpacing: {
    marginBottom: theme.spacing(2),
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: "none",
  },
  toggler: {
    display: "block",
    marginLeft: theme.spacing(-1),
  },
}));

type RecurringOverrideFormProps = Omit<OverrideFormProps, "hidePrice">;
export type RecurringOverrideFieldArrayProps = Omit<OverrideFieldArrayProps, "hidePrice">;

interface FormProps {
  active: boolean;
  audience: OrderAudience;
  cadenceType: CadenceType;
  description: string;
  frequency: number;
  fulfillmentDays: ISOWeekDay[];
  fulfillmentMethods: FulfillmentMethod[];
  name: string;
  overrides: RecurringOverrideFormProps[];
  private: boolean;
  subscriberSkippable: boolean;
}

const defaultValues: FormProps = {
  active: false,
  audience: OrderAudience.MARKETPLACE,
  cadenceType: CadenceType.WEEKLY,
  description: null,
  frequency: null,
  fulfillmentDays: [],
  fulfillmentMethods: [],
  name: null,
  overrides: [],
  private: false,
  subscriberSkippable: false,
};

const toFormData = ({
  active,
  audience,
  cadenceType,
  description,
  frequency,
  fulfillmentDays,
  fulfillmentMethods,
  name,
  overrides,
  public: isPublic,
  subscriberSkippable,
}: RecurringOrderList): FormProps => ({
  active,
  audience,
  cadenceType,
  description,
  frequency,
  fulfillmentDays,
  fulfillmentMethods,
  name,
  overrides:
    overrides.reduce((acc, override) => {
      if (override.name && override.variant) {
        const { active, id, name: productName, price, productId, variantId, variant } = override;
        const { caseSize, name: variantName, unit } = variant ?? {};

        const formOverride: RecurringOverrideFormProps = {
          active,
          caseSize,
          overrideId: id,
          price: parseFloat((price / 100).toFixed(2)),
          productId,
          productName,
          unit,
          variantId,
          variantName,
        };
        acc.push(formOverride);

        return acc;
      } else {
        return acc;
      }
    }, []) ?? [],
  private: !isPublic,
  subscriberSkippable,
});

const formDataToCreateInput = ({
  active,
  audience,
  cadenceType,
  description,
  frequency,
  fulfillmentDays,
  fulfillmentMethods,
  name,
  overrides,
  private: isPrivate,
  subscriberSkippable,
}: FormProps) => {
  return {
    variables: {
      input: {
        active,
        audience,
        cadenceType,
        description,
        frequency,
        fulfillmentDays,
        fulfillmentMethods,
        name,
        overrides:
          overrides.map(({ price, productId, variantId }) => ({
            price: Math.round(price * 100),
            productId,
            variantId,
          })) ?? [],
        public: !isPrivate,
        subscriberSkippable,
      } as RecurringOrderListCreateInput,
    },
  };
};

const formDataToUpdateInput = (
  { id, overrides }: RecurringOrderList,
  { active, description, name, overrides: currentOverrides, private: isPrivate, subscriberSkippable }: FormProps,
  {
    active: activeDirty,
    description: descriptionDirty,
    name: nameDirty,
    overrides: overridesDirty,
    private: privateDirty,
    subscriberSkippable: subscriberSkippableDirty,
  }: DeepMap<FormProps, true>,
) => {
  const overridesCreate = [];
  const overridesUpdate = [];
  const overridesDelete = [];

  if (overridesDirty) {
    const initialOverrides =
      overrides?.reduce((acc, cur) => {
        return {
          ...acc,
          [cur.id]: { price: cur.price },
        };
      }, {}) ?? {};

    const currentOverrideIds = new Set(currentOverrides.map(({ overrideId }) => overrideId));

    currentOverrides.forEach(({ overrideId, price, productName, variantName, productId, variantId }) => {
      const currentPriceInCents = Math.round(price * 100);

      if (!(overrideId in initialOverrides)) {
        overridesCreate.push({
          price: currentPriceInCents,
          productId,
          variantId,
        });
      } else if (initialOverrides[overrideId].price !== currentPriceInCents) {
        overridesUpdate.push({ id: overrideId, price: currentPriceInCents });
      } else if (productName === null || variantName === null) {
        // silently remove overrides that no longer have product/variant names
        overridesDelete.push({ id, reason: "removed due to missing product/variant" });
      }
    });

    Object.keys(initialOverrides).forEach(id => {
      if (!currentOverrideIds.has(id)) {
        overridesDelete.push({ id });
      }
    });
  }

  return {
    variables: {
      input: {
        ...(activeDirty && { active }),
        ...(descriptionDirty && { description }),
        id,
        ...(nameDirty && { name }),
        ...(overridesCreate.length > 0 && { overridesCreate }),
        ...(overridesDelete.length > 0 && { overridesDelete }),
        ...(overridesUpdate.length > 0 && { overridesUpdate }),
        ...(privateDirty && { public: !isPrivate }),
        ...(subscriberSkippableDirty && { subscriberSkippable }),
      } as RecurringOrderListUpdateInput,
    },
  };
};

const CADENCE_DISPLAY: { [key in CadenceType]: string } = {
  [CadenceType.WEEKLY]: "week",
};

const CADENCY_OPTIONS = Object.values(CadenceType).map(cadency => ({
  label: capitalize(cadency),
  value: cadency,
}));

const RecurringListDetails = ({ history, match }) => {
  const classes = useStyles();
  const flagsmith = useContext(FlagsmithContext);
  const hasRetail = flagsmith.hasFeature("b2c");
  const hasWholesale = flagsmith.hasFeature("b2b");
  const hasWholesaleRO = flagsmith.hasFeature(FEATURE_SUPPLIER_RECURRING_ORDERS_FOR_B2B);

  const recurringListId = match?.params?.id;
  const isCreateRecurringOrder = match?.url === Routes.SUBSCRIPTIONS_COLLECTIONS_CREATE;

  const audienceOptions = useMemo(() => {
    const options = [];

    if (hasRetail) {
      options.push({ label: AUDIENCE_DISPLAY[OrderAudience.MARKETPLACE], value: OrderAudience.MARKETPLACE });
    }

    if (hasWholesale) {
      options.push({
        disabled: !hasWholesaleRO,
        label: `${AUDIENCE_DISPLAY[OrderAudience.WHOLESALE]}`,
        value: OrderAudience.WHOLESALE,
      });
    }

    return options;
  }, [hasRetail, hasWholesale]);

  const {
    control,
    formState: { dirtyFields, errors, isDirty },
    handleSubmit,
    register,
    reset,
    watch,
    setValue,
  } = useForm<FormProps>({
    defaultValues,
  });
  const chosenCadence = watch("cadenceType", CadenceType.WEEKLY);
  const wholesaleSelected = watch("audience") === OrderAudience.WHOLESALE;

  useEffect(() => {
    if (wholesaleSelected) setValue("private", true);
  }, [wholesaleSelected]);

  // Delegate heavy computation to web worker
  const [populateFormData, { status: formDataStatus }] = useWorker(toFormData);
  const [populateUpdateInput, { status: updateInputStatus }] = useWorker(formDataToUpdateInput);
  const isProcessingData = formDataStatus === WORKER_STATUS.RUNNING || updateInputStatus === WORKER_STATUS.RUNNING;

  const {
    fields: overridesList,
    prepend,
    remove,
  } = useFieldArray({
    control,
    keyName: "fieldId",
    name: "overrides",
  });

  const { data, loading } = useQuery<{ recurringOrderList: RecurringOrderList }>(RECURRING_ORDER_LIST, {
    context: { source: DATALAYER },
    fetchPolicy: "cache-and-network",
    onCompleted: ({ recurringOrderList }) =>
      populateFormData(recurringOrderList).then(data => reset(data, { dirtyFields: false })),
    skip: isCreateRecurringOrder,
    variables: { id: recurringListId },
  });
  const { recurringOrderList } = data ?? {};

  const [recurringOrderListUpdate, { loading: recurringOrderListUpdateLoading }] = useMutation<
    { recurringOrderListUpdate: RecurringOrderList },
    { input: RecurringOrderListUpdateInput }
  >(RECURRING_ORDER_LIST_UPDATE, {
    context: { source: DATALAYER },
    onCompleted: () => {
      notificationVar({
        message: "Subscription collection updated!",
        severity: "success",
      });
    },
  });

  const [recurringOrderListCreate, { loading: recurringOrderListCreateLoading }] = useMutation<
    { recurringOrderListCreate: RecurringOrderList },
    { input: RecurringOrderListCreateInput }
  >(RECURRING_ORDER_LIST_CREATE, {
    context: { source: DATALAYER },
    onCompleted: data => {
      notificationVar({
        message: "Subscription collection created!",
        severity: "success",
      });
      history.replace(Routes.SUBSCRIPTIONS_COLLECTIONS_DETAILS.replace(":id", data.recurringOrderListCreate.id));
    },
  });

  const onSubmit = (formData: FormProps) => {
    const numOverrides = formData.overrides?.length;

    if (!numOverrides) {
      notificationVar({
        message: "Subscription collection must have at least 1 item!!",
        severity: "error",
      });

      return;
    } else if (numOverrides > 50) {
      notificationVar({
        message: "Subscription collections can only contain up to 50 products.",
        severity: "error",
      });

      return;
    }

    if (isCreateRecurringOrder) {
      recurringOrderListCreate(formDataToCreateInput(formData));
    } else {
      populateUpdateInput(recurringOrderList, formData, dirtyFields).then(recurringOrderListUpdate);
    }
  };

  const { supplier } = useContext(AccountContext);
  const retailURL = useMemo(
    () =>
      supplier?.url && recurringListId && recurringOrderList?.audience === "RETAIL"
        ? `${supplier?.url}/subscription-collections/${recurringListId}`
        : "",
    [supplier?.url, recurringListId, recurringOrderList?.audience],
  );

  return (
    <>
      <Loader
        loading={loading || isProcessingData || recurringOrderListUpdateLoading || recurringOrderListCreateLoading}
      />

      <PageHeader
        stickyHeader
        title={
          isCreateRecurringOrder
            ? "Create subscription collection"
            : `Subscription collection: ${recurringOrderList?.name}`
        }
        primaryActions={
          <>
            <Button color="primary" disabled={!isDirty} onClick={() => reset()} variant="outlined">
              Discard
            </Button>
            <Button
              color="primary"
              disabled={
                !isDirty || isProcessingData || recurringOrderListUpdateLoading || recurringOrderListCreateLoading
              }
              onClick={handleSubmit(onSubmit)}
              variant="contained"
            >
              Save
            </Button>
          </>
        }
      >
        {!isCreateRecurringOrder && (
          <Typography
            color="textSecondary"
            title={moment.utc(recurringOrderList?.updatedAt).format("LL")}
            variant="body2"
          >
            Last updated: {moment.utc(recurringOrderList?.updatedAt).fromNow()}
          </Typography>
        )}
      </PageHeader>

      {!isCreateRecurringOrder && retailURL && (
        <Typography
          className={classes.bottomSpacing}
          color="textSecondary"
          title="subscription-collection-url"
          variant="body2"
        >
          Collection URL:&nbsp;
          <Link className={classes.link} href={retailURL} target="_blank">
            {collectionURL}
          </Link>
        </Typography>
      )}

      <Grid className={classes.bottomSpacing} container spacing={2}>
        <Grid item xs={12} md={8}>
          <FormCard>
            <FormControl fullWidth margin="none">
              <FormLabel htmlFor="name">Name</FormLabel>
              <TextField
                autoComplete="off"
                error={Boolean(errors?.name)}
                fullWidth
                helperText={errors?.name?.message}
                id="name"
                inputRef={register({ required: "*required" })}
                margin="dense"
                name="name"
                variant="outlined"
              />
            </FormControl>

            <FormControl fullWidth margin="normal">
              <FormLabel htmlFor="description">Description</FormLabel>
              <RichTextFormComponent
                control={control}
                defaultValue={recurringOrderList?.description ?? null}
                helperText={errors?.description?.message}
                id="description"
                name="description"
                bounds="#root"
                required
              />
            </FormControl>
          </FormCard>

          <FormCard>
            <Controller
              as={
                <FulfillmentMethods
                  className={classes.bottomSpacing}
                  disabled={!isCreateRecurringOrder}
                  helperText={errors?.fulfillmentMethods?.message}
                />
              }
              control={control}
              name="fulfillmentMethods"
              rules={{ validate: value => value.length > 0 || "*required" }}
            />

            <Controller
              as={<FulfillmentDays disabled={!isCreateRecurringOrder} helperText={errors?.fulfillmentDays?.message} />}
              control={control}
              name="fulfillmentDays"
              rules={{ validate: value => value.length > 0 || "*required" }}
            />

            <FormHelperText>
              The subscription will repeat on the days selected above. <br /> For example, if you have selected Monday
              and Thursday as fulfillment day every 2 weeks. Two separate orders will be created <strong>3 days</strong>{" "}
              in advance. Monday's order will be created Friday, and Thursday's order will be created on Monday. And the
              next two orders will be created in 2 weeks.
            </FormHelperText>
          </FormCard>
        </Grid>

        <Grid item xs={12} md={4}>
          <FormCard>
            <Grid container direction="column">
              <Grid item>
                <Controller
                  as={<SwitchToggle />}
                  className={classes.toggler}
                  component={<Checkbox color="primary" />}
                  control={control}
                  label="Active"
                  labelPlacement="end"
                  name="active"
                />
              </Grid>
              <Collapse in={!wholesaleSelected}>
                <Grid item>
                  <Controller
                    as={<SwitchToggle />}
                    className={classes.toggler}
                    component={<Checkbox color="primary" />}
                    control={control}
                    label={
                      <>
                        Private{" "}
                        <FormTooltip
                          content={
                            isCreateRecurringOrder
                              ? "Making this subscription collection private will make it accessible only through a generated link."
                              : "Making this subscription collection private will make it accessible only through the link above."
                          }
                        />
                      </>
                    }
                    labelPlacement="end"
                    name="private"
                  />
                </Grid>
              </Collapse>
              <Grid item className={classes.bottomSpacing}>
                <Controller
                  as={<SwitchToggle />}
                  className={classes.toggler}
                  component={<Checkbox color="primary" />}
                  control={control}
                  label="Skippable"
                  labelPlacement="end"
                  name="subscriberSkippable"
                />
              </Grid>
              <Grid item className={classes.bottomSpacing}>
                <Controller
                  as={<DropdownSelect />}
                  control={control}
                  disabled={!isCreateRecurringOrder}
                  dropdownMargin="dense"
                  fullWidth
                  label="Audience"
                  name="audience"
                  options={audienceOptions}
                />
              </Grid>
              <Grid className={classes.bottomSpacing} item>
                <Controller
                  as={<DropdownSelect />}
                  control={control}
                  disabled={!isCreateRecurringOrder}
                  dropdownMargin="dense"
                  fullWidth
                  label="Cadence"
                  name="cadenceType"
                  options={CADENCY_OPTIONS}
                />
              </Grid>
              <Grid item>
                <Typography gutterBottom variant="subtitle1">
                  Frequency
                </Typography>
                <TextField
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">{`${CADENCE_DISPLAY[chosenCadence]}(s)`}</InputAdornment>
                    ),
                    inputProps: {
                      min: 0,
                      step: 1,
                    },
                    onWheel: OnWheelBlur,
                    startAdornment: <InputAdornment position="start">Repeat every</InputAdornment>,
                  }}
                  disabled={!isCreateRecurringOrder}
                  error={Boolean(errors?.frequency)}
                  fullWidth
                  helperText={errors?.frequency?.message}
                  inputRef={register({
                    required: "*required",
                    validate: value => {
                      const parsedFreq = parseFloat(value);

                      if (isNaN(parsedFreq) || !Number.isInteger(parsedFreq) || parsedFreq <= 0) {
                        return "Frequency must be a positive integer.";
                      }

                      return true;
                    },
                    valueAsNumber: true,
                  })}
                  name="frequency"
                  size="small"
                  type="number"
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </FormCard>
        </Grid>
      </Grid>

      <FormCard title="Products in subscription collection">
        <FormControl fullWidth margin="normal">
          <OverridesList
            errors={errors}
            mode={isCreateRecurringOrder ? FormMode.RECURRING_ORDER_CREATE : FormMode.RECURRING_ORDER_UPDATE}
            overrides={overridesList as RecurringOverrideFieldArrayProps[]}
            prepend={prepend}
            register={register}
            remove={remove}
          />
        </FormControl>
      </FormCard>

      {process.env.NODE_ENV === "development" && <DevTool control={control} />}
    </>
  );
};

export default RecurringListDetails;
