import { makeVar } from "@apollo/client";

type Notification = {
  severity: "error" | "info" | "success" | "warning";
  message: React.ReactNode;
};

export const notificationVar = makeVar<Notification | null>(null);

export default {
  notification: {
    read() {
      return notificationVar();
    },
  },
};
