import { FulfillmentMethod, OrderAudience } from "@arowana/graphql";
import { Box, FormControl, InputLabel, makeStyles, MenuItem, Select } from "@material-ui/core";
import capitalize from "lodash/capitalize";
import { useHistory } from "react-router-dom";

import Routes from "../../../Constants/Routes";
import { AUDIENCE_DISPLAY } from "../../../utils/audienceDisplay";
import { FilterParams } from "../pages/RecurringLists";
import RecurringOrderListSearch from "./RecurringOrderListSearch";

interface FilterOption {
  disabled?: boolean;
  label: string;
  value: string;
}

interface FiltersProps {
  filterParams: { [key in FilterParams]: string };
  updateFilter: (props: { [key in FilterParams]?: string }) => void;
}

const useStyles = makeStyles(theme => ({
  filters: {
    flex: "0 1 175px",
    [theme.breakpoints.down("sm")]: {
      flex: "0 1 45%",
    },
  },
  filtersContainer: {
    "& > *": {
      margin: theme.spacing(2, 0, 0, 2),
    },
    "display": "inline-flex",
    "flexFlow": "row wrap",
    "justifyContent": "flex-end",
    "margin": theme.spacing(-1, 0, 0, -2),
    "marginBottom": theme.spacing(2),
    "width": `calc(100% + ${theme.spacing(2)}px)`,
    [theme.breakpoints.down("sm")]: {
      "justify-content": "space-between",
    },
  },
}));

const ACTIVE_OPTIONS: FilterOption[] = [
  { label: "All", value: "ALL" },
  { label: "Active", value: "true" },
  { label: "Inactive", value: "false" },
];

const PUBLIC_OPTIONS: FilterOption[] = [
  { label: "All", value: "ALL" },
  { label: "Public", value: "true" },
  { label: "Private", value: "false" },
];

const AUDIENCE_OPTIONS: FilterOption[] = [
  { label: "All", value: "ALL" },
  ...Object.values(OrderAudience).map(audience => ({
    disabled: audience === OrderAudience.WHOLESALE,
    label: AUDIENCE_DISPLAY[audience],
    value: audience,
  })),
];

const FULFILLMENT_METHOD_OPTIONS: FilterOption[] = [
  { label: "All", value: "ALL" },
  ...Object.values(FulfillmentMethod).map(fulfillmentMethod => ({
    label: capitalize(fulfillmentMethod),
    value: fulfillmentMethod,
  })),
];

const Filters = ({ filterParams, updateFilter }: FiltersProps) => {
  const classes = useStyles();
  const history = useHistory();

  const { active = "ALL", audience = "ALL", fulfillmentMethod = "ALL", public: isPublic = "ALL" } = filterParams ?? {};

  const onFilterChange = (key: FilterParams, event: React.ChangeEvent<HTMLSelectElement>) =>
    updateFilter({ [key]: event.target.value });

  return (
    <Box className={classes.filtersContainer} flex="3">
      <RecurringOrderListSearch
        onChange={({ id }) => history.push(Routes.SUBSCRIPTIONS_COLLECTIONS_DETAILS.replace(":id", id))}
      />

      <FormControl className={classes.filters} variant="outlined">
        <InputLabel htmlFor="audience-select">Audience</InputLabel>
        <Select
          defaultValue="ALL"
          inputProps={{
            id: "audience-select",
          }}
          label="Audience"
          margin="dense"
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => onFilterChange(FilterParams.audience, e)}
          title="Audience"
          value={audience}
          variant="outlined"
        >
          {AUDIENCE_OPTIONS.map(({ disabled, label, value }) => (
            <MenuItem key={value} disabled={disabled} value={value}>
              {label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl className={classes.filters} variant="outlined">
        <InputLabel htmlFor="active-select">Status</InputLabel>
        <Select
          defaultValue="ALL"
          inputProps={{
            id: "active-select",
          }}
          label="Active"
          margin="dense"
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => onFilterChange(FilterParams.active, e)}
          title="Active"
          value={active}
          variant="outlined"
        >
          {ACTIVE_OPTIONS.map(({ label, value }) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl className={classes.filters} variant="outlined">
        <InputLabel htmlFor="public-select">Visibility</InputLabel>
        <Select
          defaultValue="ALL"
          inputProps={{
            id: "public-select",
          }}
          label="Public"
          margin="dense"
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => onFilterChange(FilterParams.public, e)}
          title="Public"
          value={isPublic}
          variant="outlined"
        >
          {PUBLIC_OPTIONS.map(({ label, value }) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl className={classes.filters} variant="outlined">
        <InputLabel htmlFor="fulfillment-method-select">Fulfillment method</InputLabel>
        <Select
          defaultValue="ALL"
          inputProps={{
            id: "fulfillment-method-select",
          }}
          label="Fulfillment method"
          margin="dense"
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => onFilterChange(FilterParams.fulfillmentMethod, e)}
          title="Fulfillment method"
          value={fulfillmentMethod}
          variant="outlined"
        >
          {FULFILLMENT_METHOD_OPTIONS.map(({ label, value }) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default Filters;
