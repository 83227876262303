/* eslint-disable no-unused-vars */
export default {
  Category: {
    fields: {
      tags: {
        merge(existing = [], incoming = []) {
          return incoming;
        },
      },
    },
  },
};
