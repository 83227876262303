import { gql } from "@apollo/client";

const UpdateSupplierOrder = gql`
  mutation UpdateSupplierOrder($input: OrderUpdateInput!, $sendCustomerEmail: Boolean) {
    Order: orderUpdate(input: $input, sendCustomerEmail: $sendCustomerEmail) {
      id
      supplier {
        id
        locations {
          _id
          name
        }
      }
      account {
        name
        email
        phone
        id
      }
      supplierName
      status
      invoiceNumber
      notes
      deliveryAddress {
        id
        current
        displayName
        addressLine
        postalCode
        country
        city
        province
        timezone
        deliveryInstructions
        unitNumber
      }
      deliveryType
      deliveryDate
      locationId
      lineItems {
        productId
        variantId
        productName
        subName
        quantity
        quantityOrdered
        pricePerUnit
        units
        inventoryCode
        caseSize
      }
      fulfilled
      fees {
        feeType
        amount
      }
      discount {
        name
        amount
        type
        issuer
        referrer
        discountId
      }
      preAuthorization {
        amount
        stripeInfo {
          customerToken
          supplierToken
          takeRate
          preAuthorizationToken
          paymentMethod {
            cardToken
            last4
            type
          }
        }
      }
      charge {
        amount
        date
        paymentMethod {
          cardToken
          last4
          type
        }
        stripeInfo {
          chargeId
        }
      }
      createdAt
      updatedAt
      total
      subTotal
      totalFees
      trackingCode
      fulfillmentTime {
        label
      }
    }
  }
`;

export default UpdateSupplierOrder;
