import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import React from "react";
import { CSVLink } from "react-csv";

const useStyles = makeStyles(() => ({
  link: {
    "@media print": {
      display: "none",
    },
    "textDecoration": "none",
  },
}));
const ExportCSVLink = ({ data, children, filename, ...props }) => {
  const styles = useStyles();

  return (
    <Button
      className={styles.link}
      color="primary"
      component={CSVLink}
      data={data}
      filename={filename}
      {...props}
      variant="contained"
    >
      {children}
    </Button>
  );
};

ExportCSVLink.propTypes = {
  children: PropTypes.node.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  filename: PropTypes.string.isRequired,
};

export default ExportCSVLink;
