export default {
  ProductVariant: {
    fields: {
      attributes: {
        merge: false,
      },
      images: {
        merge: false,
      },
      inventory: {
        merge: false,
      },
      lists: {
        merge(existing = [], incoming = []) {
          return incoming;
        },
      },
      productDeliveryDays: {
        merge: false,
      },
      tags: {
        merge: false,
      },
    },
  },
};
