import { gql, useQuery } from "@apollo/client";
import { CustomersSortField } from "@arowana/graphql";
import { PaginatedTable, usePagination } from "@arowana/ui";
import { DATALAYER } from "@arowana/util";
import { Box, Button, CircularProgress, Fade, IconButton, Link, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { GridSortDirection } from "@material-ui/data-grid";
import { Close, Search } from "@material-ui/icons";
import moment from "moment";
import PropTypes from "prop-types";
import qs from "qs";
import { useMemo, useState } from "react";
import { useContext } from "react";
import { Link as RouterLink } from "react-router-dom";

import PageHeader from "../../../components/PageHeader";
import TipBanner from "../../../components/TipBanner";
import Routes from "../../../Constants/Routes";
import useExportResource from "../../../hooks/useExportResource";
import { FlagsmithContext } from "../../context/FlagsmithContext";

const CUSTOMERS = gql`
  query Customers($filter: CustomersFilterInput!, $sort: CustomersSortInput!, $page: PaginationInput!) {
    currentSupplier {
      id
      customers(filter: $filter, sort: $sort, page: $page) {
        edges {
          cursor
          node {
            id
            createdAt
            email
            name
            phone
            updatedAt
          }
        }
        pageInfo {
          count
          endCursor
          hasNextPage
          totalCount
        }
      }
    }
  }
`;

const EXPORT_CUSTOMERS = gql`
  mutation ExportCustomers {
    url: exportSupplierCustomers(filter: {}, sort: [])
  }
`;

const useStyles = makeStyles(theme => ({
  filtersContainer: {
    "& > *": {
      margin: theme.spacing(2, 0, 0, 2),
    },
    "display": "inline-flex",
    "flexFlow": "row wrap",
    "justifyContent": "space-between",
    "margin": theme.spacing(-2, 0, 0, -2),
    "marginBottom": theme.spacing(2),
    "width": `calc(100% + ${theme.spacing(2)}px)`,
  },
  searchIcon: {
    marginRight: theme.spacing(1),
  },
}));

const getColumns = query => [
  {
    field: "id",
    hide: true,
  },
  {
    field: CustomersSortField.NAME,
    headerName: "Name",
    renderCell: param => (
      <Link component={RouterLink} to={Routes.CUSTOMER_DETAILS.replace(":id", param.row.id)}>
        {param.value}
      </Link>
    ),
    sortable: !query,
    valueGetter: param => param.row.name,
    width: 250,
  },
  {
    field: CustomersSortField.EMAIL,
    headerName: "Email",
    renderCell: param => (
      <Link component={RouterLink} to={Routes.CUSTOMER_DETAILS.replace(":id", param.row.id)}>
        {param.value}
      </Link>
    ),
    sortable: !query,
    valueGetter: param => param.row.email,
    width: 250,
  },
  {
    field: CustomersSortField.PHONE,
    headerName: "Phone",
    renderCell: param => (
      <Link component={RouterLink} to={Routes.CUSTOMER_DETAILS.replace(":id", param.row.id)}>
        {param.value}
      </Link>
    ),
    sortable: false,
    valueGetter: param => param.row.phone,
    width: 200,
  },
  {
    field: CustomersSortField.CREATED_AT,
    headerName: "Date added",
    sortable: !query,
    valueGetter: param => moment(param.row.createdAt).format("LL"),
    width: 150,
  },
];

const defaultSortState = {
  field: CustomersSortField.CREATED_AT,
  sort: "desc" as GridSortDirection,
};

const ROW_PER_PAGE = 100;

const CustomerList = ({ history }) => {
  const classes = useStyles();
  const queryParams = qs.parse(history.location.search, { ignoreQueryPrefix: true });
  const { query = "" } = queryParams;
  const hasSearchQuery = Boolean(query);
  const columns = useMemo(() => getColumns(query), [query]);
  const flagsmith = useContext(FlagsmithContext);
  const hasWholesale = flagsmith.hasFeature("b2b");
  const hasRetail = flagsmith.hasFeature("b2c");

  const [searchInput, setSearchInput] = useState(query);
  const onSearchInputChange = event => setSearchInput(event.target.value);
  const onSearchSubmit = ({ type, key }) => {
    if (type === "keydown" && key === "Enter") {
      updateQuery({ query: searchInput });
    }
  };
  const onSearchClear = () => {
    setSearchInput("");
    setSortState(defaultSortState);
    updateQuery({ query: undefined });
  };

  const [sortState, setSortState] = useState(defaultSortState);
  const { setPageInfo, resetPagination, pagination, page, onPageChange } = usePagination(ROW_PER_PAGE);

  const { loading: loadingDefault, data } = useQuery(CUSTOMERS, {
    context: { source: DATALAYER },
    fetchPolicy: "cache-and-network",
    onCompleted: response => {
      setPageInfo(response?.currentSupplier?.customers?.pageInfo);
    },
    onError: resetPagination,
    variables: {
      filter: {
        query: searchInput,
      },
      page: pagination,
      sort: {
        field: sortState?.field,
        order: sortState?.sort === "desc" ? -1 : 1,
      },
    },
  });

  const totalCount = data?.currentSupplier?.customers?.pageInfo?.totalCount ?? 0;

  const onSortChange = ({ sortModel }) => {
    resetPagination();
    setSortState(sortModel[0]);
  };

  const rows = useMemo(
    () =>
      data?.currentSupplier?.customers?.edges.map(({ node }) => ({
        id: node._id,
        ...node,
      })) ?? [],
    [data?.currentSupplier?.customers],
  );
  const sortModel = !query && sortState ? [sortState] : [];

  const updateQuery = keyValue => {
    history.replace({
      search: qs.stringify({
        ...queryParams,
        ...keyValue,
      }),
    });
    resetPagination();
  };

  const onPageChangeExtra = params => {
    window.scrollTo(0, 0);
    onPageChange(params);
  };

  const { ExportDownloadBanner, loadingExport, onExportClick } = useExportResource(EXPORT_CUSTOMERS);

  const handleCreateClick = () => history.push(Routes.CREATE_CUSTOMER);

  return (
    <>
      <PageHeader
        primaryActions={
          <>
            <Button
              color="primary"
              disabled={loadingExport}
              endIcon={loadingExport && <CircularProgress color="inherit" size={24} />}
              onClick={onExportClick()}
              variant="outlined"
            >
              Export
            </Button>
            <Button color="primary" onClick={handleCreateClick} variant="contained">
              Create
            </Button>
          </>
        }
        stickyHeader
        title="Customers"
      />
      {hasWholesale && (
        <TipBanner
          headerText={
            !hasRetail
              ? "This page is for managing individual account details for wholesale businesses"
              : "This page is for managing individual customers."
          }
          bodyText="Are you looking to manage businesses that can order from your wholesale store? Head over to the Businesses page."
        />
      )}
      {ExportDownloadBanner}

      <Box className={classes.filtersContainer} flex="3">
        <TextField
          InputProps={{
            endAdornment: (
              <Fade in={searchInput.length > 0} unmountOnExit>
                <IconButton aria-label="search" id="search-submit" onClick={onSearchClear} size="small">
                  <Close />
                </IconButton>
              </Fade>
            ),
            startAdornment: <Search className={classes.searchIcon} />,
          }}
          fullWidth
          label="Search"
          onChange={onSearchInputChange}
          onKeyDown={onSearchSubmit}
          placeholder="Search customers"
          value={searchInput}
          variant="outlined"
        />
      </Box>

      <PaginatedTable
        columns={columns}
        defaultContent={hasSearchQuery ? "No customers found" : "No customers"}
        loading={loadingDefault}
        onPageChange={onPageChangeExtra}
        onSortModelChange={onSortChange}
        page={page}
        pageSize={ROW_PER_PAGE}
        rows={rows}
        sortModel={sortModel}
        totalCount={totalCount}
      />
    </>
  );
};

CustomerList.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default CustomerList;
