import { Button, Grid, makeStyles, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import { Controller, useForm } from "react-hook-form";

import CategoryAndTags from "./FormComponents/CategoryAndTags";
const useStyles = makeStyles(theme => ({
  actionButtons: {
    marginTop: theme.spacing(2),
  },
}));

const CategoryTagsForm = ({ categoriesData, onClose, products, updateCategoryAndTags }) => {
  const classes = useStyles();

  const { control, errors, handleSubmit, formState } = useForm({
    defaultValues: {
      categoryAndTags: {
        categoryId: "",
        tags: [],
      },
    },
  });

  const onSubmit = data => {
    const variables = {
      input: products.map(({ id }) => ({
        categoryId: data.categoryAndTags.categoryId,
        id,
        tags: data.categoryAndTags.tags,
      })),
    };
    updateCategoryAndTags({ variables });
    onClose();
  };

  return (
    <>
      <Typography gutterBottom variant="subtitle1">
        Multiple Items
      </Typography>
      <Controller
        as={<CategoryAndTags />}
        categories={categoriesData}
        control={control}
        helperText={errors?.categoryAndTags}
        name="categoryAndTags"
        rules={{
          validate: {
            category: value => value.categoryId !== "" || "*required",
          },
        }}
      />
      <Grid className={classes.actionButtons} container justifyContent="flex-end" spacing={1}>
        <Grid item md={3} sm={6} xs={12}>
          <Button color="primary" fullWidth onClick={onClose}>
            Cancel
          </Button>
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <Button
            color="primary"
            disabled={!formState?.isDirty}
            fullWidth
            onClick={handleSubmit(onSubmit)}
            variant="contained"
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

CategoryTagsForm.defaultProps = {
  categoriesData: [],
  products: [],
};

CategoryTagsForm.propTypes = {
  categoriesData: PropTypes.array,
  onClose: PropTypes.func.isRequired,
  products: PropTypes.array,
  updateCategoryAndTags: PropTypes.func.isRequired,
};

export default CategoryTagsForm;
