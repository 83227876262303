/* eslint-disable no-underscore-dangle */
import Signature from "signature_pad";

// Weird hack to get scaling working properly!
// Refernce: https://github.com/szimek/signature_pad/issues/494#issuecomment-1083662927
// also needs to be in JS for accessing private func
export const SignatureExt = (canvas, ratio) => {
  const signPad = new Signature(canvas);
  const Point = signPad._createPoint(0, 0, 0).constructor;
  signPad._createPoint = (x, y, pressure) => {
    const rect = canvas.getBoundingClientRect();
    const sx = (((x - rect.left) / rect.width) * canvas.width) / ratio;
    const sy = (((y - rect.top) / rect.height) * canvas.height) / ratio;

    return new Point(sx, sy, pressure, new Date().getTime());
  };

  return signPad;
};
