import { Button, FormHelperText, Grid } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";

import FormCard from "../../../../components/FormCard";
import Routes from "../../../../Constants/Routes";

const Wholesale = ({ disabled }) => {
  return (
    <FormCard title="Wholesale">
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Button
            component={RouterLink}
            color="primary"
            disabled={disabled}
            fullWidth
            to={Routes.WHOLESALE_LISTS}
            variant="contained"
          >
            Add to Price List
          </Button>
          <FormHelperText id="pricelist">
            Make this product available for wholesale customers by adding it to a specific price list.
          </FormHelperText>
        </Grid>
      </Grid>
    </FormCard>
  );
};

export default Wholesale;
