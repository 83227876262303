import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
  cancelButton: {
    textDecoration: "none",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(2),
    top: theme.spacing(2),
  },
  container: {
    padding: theme.spacing(2),
  },
  modalTitle: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: theme.spacing(2),
    padding: 0,
  },
}));
/*
  To keep the component clean and easy to use,
  this component is ONLY for simple use cases -
  modal title, followed by modal content WITHOUT any interaction, a cancel button and a confirm button
  for the use cases that involve complicated interactions on the modal content, please do your own component
*/
const ConfirmationModal = ({
  cancelRequestButtonText,
  children,
  confirmRequestButtonText,
  confirmRequestOnGoing,
  isDangerAction,
  maxWidth,
  modalContent,
  modalNote,
  modalTitle,
  onCloseModal,
  onConfirmClick,
  onCancelClick,
  shouldOpenModal,
  showActionButtons,
  showCloseButton,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isXS = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Dialog
      classes={{ paper: classes.container }}
      disableEscapeKeyDown
      fullScreen={isXS}
      fullWidth
      maxWidth={maxWidth}
      onClose={(_, reason) => {
        if (reason !== "backdropClick") {
          onCloseModal();
        }
      }}
      open={shouldOpenModal}
    >
      {showCloseButton && (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onCloseModal} size="small">
          <CloseIcon />
        </IconButton>
      )}
      <DialogTitle className={classes.modalTitle}>{modalTitle}</DialogTitle>
      <DialogContent className={classes.container}>
        {modalContent && <Typography variant="subtitle1">{modalContent}</Typography>}
        {modalNote && <Typography variant="caption">{modalNote}</Typography>}
        {children}
      </DialogContent>
      {showActionButtons && (
        <DialogActions className={classes.container}>
          <Button
            autoFocus
            className={classes.cancelButton}
            color="primary"
            disabled={confirmRequestOnGoing}
            onClick={onCancelClick ?? onCloseModal}
          >
            {cancelRequestButtonText}
          </Button>
          <Button
            color={isDangerAction ? "secondary" : "primary"}
            disabled={confirmRequestOnGoing}
            onClick={onConfirmClick}
            variant="contained"
          >
            {confirmRequestOnGoing ? <CircularProgress color="inherit" size={24} /> : confirmRequestButtonText}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

ConfirmationModal.defaultProps = {
  cancelRequestButtonText: "Cancel",
  children: null,
  confirmRequestButtonText: "Yes",
  confirmRequestOnGoing: false,
  isDangerAction: false,
  maxWidth: "sm",
  modalContent: "",
  modalNote: "",
  modalTitle: "",
  onCancelClick: undefined,
  onCloseModal: () => {},
  onConfirmClick: () => {},
  shouldOpenModal: false,
  showActionButtons: true,
  showCloseButton: false,
};

ConfirmationModal.propTypes = {
  cancelRequestButtonText: PropTypes.string,
  children: PropTypes.node,
  confirmRequestButtonText: PropTypes.string,
  confirmRequestOnGoing: PropTypes.bool,
  isDangerAction: PropTypes.bool,
  maxWidth: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
  modalContent: PropTypes.node,
  modalNote: PropTypes.string,
  modalTitle: PropTypes.string,
  onCancelClick: PropTypes.func,
  onCloseModal: PropTypes.func,
  onConfirmClick: PropTypes.func,
  shouldOpenModal: PropTypes.bool,
  showActionButtons: PropTypes.bool,
  showCloseButton: PropTypes.bool,
};

export default ConfirmationModal;
