import { gql } from "@apollo/client";

const appShippingDestroy = gql`
  mutation AppShippingDestroy($id: String!) {
    appShippingDestroy(id: $id) {
      name
    }
  }
`;

export default appShippingDestroy;
