import { FormControl, FormControlProps, FormHelperText, MenuItem, Select, Typography } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import PropTypes from "prop-types";
import { forwardRef } from "react";

import TimeZones from "../constants/TimeZones";

type TimeZoneProps = {
  value: string;
  onChange?: SelectProps["onChange"];
  helperText?: React.ReactNode | undefined | null;
  error?: FormControlProps["error"];
};

const TimeZone = forwardRef<Select, TimeZoneProps>(({ value, onChange, helperText, error }, ref) => {
  return (
    <FormControl fullWidth variant="outlined" error={error}>
      <InputLabel id="time-zone-label">Time zone</InputLabel>
      <Select ref={ref} id="time-zone" label="Time Zone" onChange={onChange} value={value} variant="outlined">
        {TimeZones.map(timezone => (
          <MenuItem defaultValue={value} key={timezone.value} value={timezone.value}>
            {timezone.name}
          </MenuItem>
        ))}
        {process.env.FRESHLINE_ENVIRONMENT !== "production" && (
          <MenuItem defaultValue={value} value={"Pacific/Auckland"}>
            New Zealand Standard Time (NZST)
          </MenuItem>
        )}
      </Select>
      <FormHelperText>{helperText ?? "Used for order cutoffs or notifications."}</FormHelperText>
    </FormControl>
  );
});

TimeZone.displayName = "TimeZone";

export default TimeZone;
