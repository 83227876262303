import { Checkbox, FormLabel, Grid, makeStyles } from "@material-ui/core";
import { useContext } from "react";
import { Controller } from "react-hook-form";

import FormCard from "../../../../components/FormCard";
import FormTooltip from "../../../../components/FormTooltip";
import SwitchToggle from "../../../account/components/SwitchToggle";
import { FlagsmithContext } from "../../../context/FlagsmithContext";
import MultiSelectList from "../MultiSelectList";
import CategoryAndTags from "./CategoryAndTags";

const useStyles = makeStyles(theme => ({
  checkbox: {
    marginLeft: theme.spacing(-1.5),
    marginRight: 0.5,
  },
  hideB2C: { display: "none" },
}));

const DisplayAvailability = ({ control, errors, productCategories }) => {
  const classes = useStyles();
  const flagsmith = useContext(FlagsmithContext);
  const hasRetail = flagsmith.hasFeature("b2c");

  return (
    <FormCard title="Display & availability">
      <Grid container direction="column" spacing={1}>
        <Grid item>
          <Controller
            as={<SwitchToggle />}
            className={classes.checkbox}
            component={<Checkbox color="primary" />}
            control={control}
            label={
              <>
                Display on store{" "}
                <FormTooltip content="Unclicking this option will hide your product completely from your online store." />
              </>
            }
            labelPlacement="end"
            name="active"
            aria-describedby="active-helper"
          />
        </Grid>

        <Grid item>
          <Controller
            as={<SwitchToggle />}
            className={classes.checkbox}
            component={<Checkbox color="primary" />}
            control={control}
            label={
              <>
                Featured item{" "}
                <FormTooltip content="Featured items appear as a larger product card on the first row of your online store." />
              </>
            }
            labelPlacement="end"
            name="featured"
            aria-describedby="featured-helper"
          />
        </Grid>

        <Grid item>
          <Controller
            as={<SwitchToggle />}
            className={classes.checkbox}
            component={<Checkbox color="primary" />}
            control={control}
            label={
              <>
                On sale{" "}
                <FormTooltip content="On sale products display a reduced price compared to the 'compare at' price" />
              </>
            }
            labelPlacement="end"
            name="onSale"
            aria-describedby="onsale-helper"
          />
        </Grid>

        <Grid item>
          <Controller
            as={<CategoryAndTags />}
            categories={productCategories}
            control={control}
            helperText={errors?.categoryAndTags}
            name="categoryAndTags"
            rules={{
              validate: {
                category: value => value.categoryId !== "" || "*required",
                tags: value => value.tags.length > 0 || "*required",
              },
            }}
          />
        </Grid>

        <Grid item className={!hasRetail ? classes.hideB2C : ""}>
          <FormLabel>Collections</FormLabel>
          <Controller
            as={<MultiSelectList helperText="Add this product to a collection and it's shareable URL." />}
            control={control}
            name="lists"
          />
        </Grid>
      </Grid>
    </FormCard>
  );
};

export default DisplayAvailability;
