import { OnWheelBlur } from "@arowana/ui";
import { Box, Button, InputAdornment, makeStyles, TextField } from "@material-ui/core";
import { useState } from "react";

import DropdownSelect from "../../../components/DropdownSelect";

const useStyles = makeStyles(theme => ({
  textInput: { margin: 0 },
  textInputSection: {
    alignItems: "center",
    display: "flex",
    gap: theme.spacing(1),
  },
}));

interface OrderDaysInAdvanceProps {
  loading: boolean;
  onChange: (daysInAdvance: number) => void;
  value: number;
}

const DAYS_IN_ADVANCE_OPTIONS = [];
const DAYS_IN_ADVANCE_SET = new Set();

const default_options = [7, 14, 30];
for (const n of default_options) {
  DAYS_IN_ADVANCE_OPTIONS.push({ label: String(n), value: n });
  DAYS_IN_ADVANCE_SET.add(n);
}
DAYS_IN_ADVANCE_OPTIONS.push({ label: "Other", value: -1 });

const OrderDaysInAdvance = ({ loading, onChange, value }: OrderDaysInAdvanceProps) => {
  const classes = useStyles();
  const [daysInAdvance, setDaysInAdvance] = useState<number>(value);
  const [showDropdown, setShowDropdown] = useState<boolean>(true);

  let options = DAYS_IN_ADVANCE_OPTIONS;

  if (!DAYS_IN_ADVANCE_SET.has(value)) {
    options = [
      ...DAYS_IN_ADVANCE_OPTIONS.slice(0, DAYS_IN_ADVANCE_OPTIONS.length - 1),
      { label: `${value}`, value },
      { label: "Other", value: -1 },
    ];
  }

  const handleSaveDaysInAdvance = (daysInAdvance: number) => {
    if (daysInAdvance === -1) {
      setShowDropdown(false);
    } else {
      setShowDropdown(true);
      setDaysInAdvance(daysInAdvance);
      onChange(daysInAdvance);
    }
  };

  const handleCancel = () => {
    setDaysInAdvance(value);
    setShowDropdown(true);
  };

  const handleChangeCustomDays = (e: React.ChangeEvent<HTMLInputElement>) => {
    let daysInAdvance = parseInt(e.target.value);

    if (daysInAdvance > 365) daysInAdvance = 365;

    if (daysInAdvance <= 0) daysInAdvance = 1;

    setDaysInAdvance(daysInAdvance);
  };

  const handlePressEnterKey = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      handleSaveDaysInAdvance(daysInAdvance);
    }
  };

  if (!showDropdown) {
    return (
      <Box className={classes.textInputSection}>
        <TextField
          className={classes.textInput}
          fullWidth
          InputProps={{
            endAdornment: <InputAdornment position="end">days</InputAdornment>,
            inputProps: { max: 365, min: 1 },
            onWheel: OnWheelBlur,
          }}
          margin="dense"
          onChange={handleChangeCustomDays}
          onKeyUp={handlePressEnterKey}
          type="number"
          value={daysInAdvance}
          variant="outlined"
        />
        <Button color="primary" onClick={handleCancel} variant="outlined">
          Cancel
        </Button>
        <Button color="primary" onClick={() => handleSaveDaysInAdvance(daysInAdvance)} variant="contained">
          Save
        </Button>
      </Box>
    );
  }

  return (
    <DropdownSelect
      disabled={loading}
      dropdownMargin="dense"
      fullWidth
      onChange={e => handleSaveDaysInAdvance(e.target.value)}
      options={options}
      value={`${value}`}
    />
  );
};

export default OrderDaysInAdvance;
