import { gql } from "@apollo/client";

import LIST_FRAGMENT from "../fragment/list";

const list = gql`
  query List($listId: ID!) {
    list(_id: $listId) {
      ...ListFragment
    }
  }
  ${LIST_FRAGMENT}
`;

export default list;
