import { gql } from "@apollo/client";

const CATEGORY_FRAGMENT = gql`
  fragment CategoryFragment on Category {
    id
    name
    order
    tags {
      value
      label
    }
  }
`;

export default CATEGORY_FRAGMENT;
