import { DocumentNode, useMutation } from "@apollo/client";
import { DATALAYER } from "@arowana/util";
import { Box, Button, Collapse } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useMemo, useState } from "react";

const useExportResource = (
  mutation: DocumentNode,
  variables?: unknown,
  message = "Export is ready!",
  onExportComplete?: () => void,
) => {
  const [downloadUrl, setDownloadUrl] = useState<string | undefined>();

  const [exportResource, { loading: loadingExport }] = useMutation(mutation, {
    context: { source: DATALAYER },
  });
  const onExportClick = (onMenuClose?: () => void) => () => {
    setDownloadUrl(undefined);

    if (onMenuClose) {
      onMenuClose();
    }

    exportResource({
      onCompleted: data => {
        window.scrollTo(0, 0);
        setDownloadUrl(data.url);
      },
      variables,
    });
  };

  const ExportDownloadBanner = useMemo(
    () => (
      <Collapse in={Boolean(downloadUrl)} unmountOnExit>
        <Box mb={2}>
          <Alert
            action={
              <Button
                href={downloadUrl}
                color="primary"
                onClick={e => {
                  // Downloader without navigating away
                  e.preventDefault();
                  window.location.href = downloadUrl;

                  setDownloadUrl(undefined);

                  if (onExportComplete) {
                    onExportComplete();
                  }
                }}
                variant="outlined"
              >
                Download
              </Button>
            }
          >
            {message}
          </Alert>
        </Box>
      </Collapse>
    ),
    [downloadUrl],
  );

  return {
    ExportDownloadBanner,
    loadingExport,
    onExportClick,
  };
};

export default useExportResource;
