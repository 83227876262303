import { Box, makeStyles, Theme, Typography } from "@material-ui/core";
import { Variant } from "@material-ui/core/styles/createTypography";
import clsx from "clsx";
import { ReactNode } from "react";

import Currency from "../Currency";

const useStyles = makeStyles<Theme>(theme => ({
  calculationLine: {
    "&:first-child": {
      paddingTop: 0,
    },
    "&:last-child": {
      paddingBottom: 0,
    },
    "padding": theme.spacing(1, 0),
  },
  lineText: {
    fontWeight: 500,
  },
}));

type CalculationLineProps = {
  className?: string;
  label?: ReactNode;
  loading?: boolean;
  prefix?: ReactNode;
  cents?: number;
  variant?: Variant;
};

export const CalculationLine = ({
  className,
  label,
  cents,
  loading = false,
  prefix,
  variant = "body1",
}: CalculationLineProps) => {
  const classes = useStyles();

  return (
    <Box className={clsx(classes.calculationLine, className)} display="flex" justifyContent="space-between">
      <Typography className={classes.lineText} variant={variant}>
        {label}
      </Typography>
      <Currency
        cents={cents}
        className={classes.lineText}
        color={cents < 0 ? "primary" : "inherit"}
        fallback="NaN"
        loading={loading}
        prefix={prefix}
        variant={variant}
      />
    </Box>
  );
};

export default CalculationLine;
