import { gql, useMutation, useQuery } from "@apollo/client";
import { StaffAccountCreateInput, StaffAccountUpdateInput, Supplier } from "@arowana/graphql";
import { DATALAYER } from "@arowana/util";
import { Button, FormControl, FormLabel, makeStyles } from "@material-ui/core";
import { Controller, useForm } from "react-hook-form";

import { notificationVar } from "../../../cache/notificationPolicy";
import DropdownSelect from "../../../components/DropdownSelect";
import FormCard from "../../../components/FormCard";
import Loader from "../../../components/Loader";
import PageHeader from "../../../components/PageHeader";
import Routes from "../../../Constants/Routes";
import AccountCreateForm from "../../customers/components/AccountCreateForm";
import { CustomerCreateDefaultValues } from "../../customers/pages/CustomerCreate";

const useStyles = makeStyles(theme => ({
  role: {
    [theme.breakpoints.up("md")]: {
      paddingRight: theme.spacing(1),
      width: "50%",
    },
    marginTop: theme.spacing(1),
  },
}));

const STAFF_ACCOUNT_CREATE = gql`
  mutation StaffAccountCreate($input: StaffAccountCreateInput!) {
    staffAccountCreate(input: $input) {
      id
    }
  }
`;

const STAFF_ACCOUNT_UPDATE = gql`
  mutation StaffAccountUpdate($id: ID!, $input: StaffAccountUpdateInput!) {
    staffAccountUpdate(id: $id, input: $input) {
      id
    }
  }
`;

const SUPPLIER_ACCOUNTS = gql`
  query SupplierAccounts {
    currentSupplier {
      id
      accounts {
        account {
          id
          createdAt
          email
          name
          phone
        }
        roleId
      }
      roles {
        id
        description
        name
      }
    }
  }
`;

const StaffCreate = ({ history, match }) => {
  const classes = useStyles();
  const accountId = match?.params?.id;
  const isCreate = !accountId;

  const formMethods = useForm({ defaultValues: { ...CustomerCreateDefaultValues, roleId: "" } });
  const {
    formState: { isDirty },
    handleSubmit,
    reset,
  } = formMethods;

  const { data, loading } = useQuery<{ currentSupplier: Supplier }>(SUPPLIER_ACCOUNTS, {
    context: { source: DATALAYER },
    onCompleted: data => {
      if (!isCreate) {
        const staffAccount = data.currentSupplier.accounts.find(({ account }) => account.id === accountId);

        if (staffAccount) {
          const { account, roleId } = staffAccount;
          reset({
            ...account,
            roleId,
          });
        } else {
          history.replace(Routes.STAFF_CREATE);
        }
      }
    },
  });
  const roleOptions =
    data?.currentSupplier?.roles?.map(({ id, name, description }) => ({
      label: `${name} (${description})`,
      value: id,
    })) ?? [];

  const [accountCreate, { loading: creatingAccount }] = useMutation(STAFF_ACCOUNT_CREATE, {
    context: { source: DATALAYER },
    onCompleted: () => {
      notificationVar({
        message: "Staff account created!",
        severity: "success",
      });
      history.push(Routes.STAFF_LIST);
    },
  });

  const [accountUpdate, { loading: updatingAccount }] = useMutation(STAFF_ACCOUNT_UPDATE, {
    context: { source: DATALAYER },
    onCompleted: () => {
      notificationVar({
        message: "Staff account updated!",
        severity: "success",
      });
    },
    refetchQueries: ["SupplierAccounts"],
  });

  const handleDiscardClick = () => reset();
  const onSubmit = (input: StaffAccountCreateInput | StaffAccountUpdateInput) => {
    if (isCreate) {
      accountCreate({ variables: { input } });
    } else {
      const { password, ...rest } = input;
      accountUpdate({ variables: { id: accountId, input: { ...rest, ...(password && { password }) } } });
    }
  };

  return (
    <>
      <Loader loading={loading || creatingAccount || updatingAccount} />
      <PageHeader
        primaryActions={
          <>
            <Button color="primary" disabled={!isDirty} onClick={handleDiscardClick} variant="outlined">
              Discard
            </Button>
            <Button color="primary" disabled={!isDirty} onClick={handleSubmit(onSubmit)} variant="contained">
              Save
            </Button>
          </>
        }
        stickyHeader
        title={`${isCreate ? "Create" : "Edit"} staff account`}
      />
      <FormCard title="Account details">
        <AccountCreateForm formMethods={formMethods} isEdit={!isCreate} />
        <FormControl fullWidth margin="normal">
          <FormLabel htmlFor="roleId">Role</FormLabel>
          <Controller
            as={<DropdownSelect className={classes.role} dropdownMargin="dense" />}
            control={formMethods.control}
            helperText={formMethods.errors?.roleId?.message}
            name="roleId"
            options={roleOptions}
            rules={{ required: "*required" }}
          />
        </FormControl>
      </FormCard>
    </>
  );
};

export default StaffCreate;
