import { FEATURE_SUPPLIER_AVAILABILITY_SCHEDULE, FEATURE_SUPPLIER_RECURRING_ORDERS } from "@arowana/flags";
import { Badge, Collapse, Link, List, ListItem, ListItemIcon, ListItemText, makeStyles } from "@material-ui/core";
import {
  AccountBoxOutlined as AccountIcon,
  AppsOutlined as AppIcon,
  AssignmentOutlined as AssignmentIcon,
  Autorenew as AutorenewIcon,
  BarChart as BarChartIcon,
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
  HelpOutline as HelpOutlineIcon,
  ListAltOutlined as ListAltIcon,
  LocalOfferOutlined as LocalOfferIcon,
  LocalShippingOutlined,
  Payment as PaymentIcon,
  SettingsOutlined as SettingsIcon,
  ShoppingCartOutlined as ShoppingCartIcon,
} from "@material-ui/icons";
import clsx from "clsx";
import PropTypes from "prop-types";
import { forwardRef, Fragment, useContext, useMemo, useState } from "react";
import { NavLink } from "react-router-dom";

import Routes from "../../../Constants/Routes";
import { FlagsmithContext } from "../../context/FlagsmithContext";

const SIDEBAR_LINKS = [
  {
    icon: BarChartIcon,
    label: "Analytics",
    path: Routes.ANALYTICS,
  },
  {
    icon: ShoppingCartIcon,
    label: "Products",
    subLinks: [
      {
        label: "Products",
        path: Routes.PRODUCT_LIST,
      },
      {
        label: "Categories",
        path: Routes.CATEGORIES,
      },
      {
        label: "Collections",
        path: Routes.LISTS,
      },
      {
        label: "Gift Cards",
        path: Routes.GIFTCARDS,
      },
    ],
  },
  {
    icon: AssignmentIcon,
    label: "Orders",
    path: Routes.ORDER_LIST,
  },
  {
    icon: ListAltIcon,
    label: "Operations",
    subLinks: [
      {
        label: "Order Picklist",
        path: Routes.ORDERS_PICKLIST,
      },
      {
        label: "Product Picklist",
        path: Routes.PRODUCTS_PICKLIST,
      },
    ],
  },
  {
    icon: LocalShippingOutlined,
    label: "Wholesale",
    subLinks: [
      {
        label: "Price Lists",
        path: Routes.WHOLESALE_LISTS,
      },
      {
        label: "Businesses",
        path: Routes.WHOLESALE_CLIENTS,
      },
    ],
  },
  {
    feature: FEATURE_SUPPLIER_RECURRING_ORDERS,
    icon: AutorenewIcon,
    label: "Subscriptions",
    subLinks: [
      {
        label: "Collections",
        path: Routes.SUBSCRIPTIONS_COLLECTIONS,
      },
      {
        label: "Subscriptions",
        path: Routes.SUBSCRIPTIONS,
      },
    ],
  },
  {
    icon: AccountIcon,
    label: "Customers",
    path: Routes.CUSTOMERS,
  },
  {
    icon: LocalOfferIcon,
    label: "Promotions",
    path: Routes.PROMOTIONS,
  },
  {
    icon: AppIcon,
    label: "Apps",
    path: Routes.APPS,
  },
  {
    icon: PaymentIcon,
    label: "Payments",
    path: Routes.PAYMENTS,
  },
  {
    icon: SettingsIcon,
    label: "Store",
    subLinks: [
      {
        label: "General",
        path: Routes.STORE_GENERAL_SETTINGS,
      },
      {
        label: "Service Locations",
        path: Routes.STORE_LOCATIONS,
      },
      {
        feature: FEATURE_SUPPLIER_AVAILABILITY_SCHEDULE,
        label: "Availability Overrides",
        new: true,
        path: Routes.STORE_AVAILABILITIES,
      },
      {
        label: "Appearance",
        path: Routes.STORE_APPEARANCE,
      },
      {
        label: "Staff",
        path: Routes.STAFF_LIST,
      },
      {
        label: "Communication",
        path: Routes.STORE_COMMUNICATION,
      },
    ],
  },
  {
    icon: HelpOutlineIcon,
    label: "Help",
    path: Routes.HELP,
  },
];

const useStyles = makeStyles(theme => ({
  badge: {
    right: theme.spacing(-1.25),
    transform: "scale(0.75) translate(50%, -50%)",
  },
  content: {
    flex: "1 0 auto",
    paddingTop: theme.spacing(6),
  },
  footer: {
    flexShrink: 0,
  },
  icon: {
    marginRight: theme.spacing(1),
    minWidth: 0,
  },
  logo: {
    display: "block",
    margin: theme.spacing(6, 4, 4),
  },
  navItem: {
    "&.selected": {
      backgroundColor: theme.palette.grey[200],
      color: theme.palette.primary.main,
    },
    "cursor": "pointer",
  },
  navItemTypography: {
    fontWeight: 500,
  },
  nestedNavItem: {
    paddingLeft: theme.spacing(6),
  },
  root: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      height: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      height: window.innerHeight,
    },
  },
}));

const LinkBehavior = forwardRef(({ to, ...props }, ref) => (
  <Link
    activeClassName="selected"
    color="textPrimary"
    component={NavLink}
    exact
    ref={ref}
    to={to}
    underline="none"
    {...props}
  />
));

const Sidebar = ({ onCloseNavbar }) => {
  const classes = useStyles();
  const flagsmith = useContext(FlagsmithContext);

  const [expand, setExpand] = useState({
    Account: false,
    Operations: false,
  });
  const handleExpandClick = label => {
    setExpand({
      ...expand,
      [label]: !expand[label],
    });
  };

  const links = useMemo(
    () => SIDEBAR_LINKS.filter(link => (link?.feature ? flagsmith.hasFeature(link.feature) : true)),
    [flagsmith],
  );

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <List aria-label="main navigation" component="nav" dense>
          {links.map(link => {
            const hasSublink = link.subLinks?.length > 0;
            let expandIcon;

            if (hasSublink) {
              if (expand[link.label]) {
                expandIcon = <ExpandLessIcon />;
              } else {
                expandIcon = <ExpandMoreIcon />;
              }
            }

            const handleLinkClick = label => {
              if (hasSublink) {
                handleExpandClick(label);
              } else if (onCloseNavbar) {
                onCloseNavbar();
              }
            };

            return (
              <Fragment key={link.label}>
                <ListItem
                  className={classes.navItem}
                  component={hasSublink ? "div" : LinkBehavior}
                  dense
                  onClick={() => handleLinkClick(link.label)}
                  to={link.path}
                >
                  <ListItemIcon className={classes.icon}>
                    <link.icon />
                  </ListItemIcon>

                  <ListItemText
                    primary={
                      link.new ? (
                        <Badge
                          badgeContent="New!"
                          color="primary"
                          classes={{ anchorOriginTopRightRectangular: classes.badge }}
                        >
                          {link.label}
                        </Badge>
                      ) : (
                        link.label
                      )
                    }
                    primaryTypographyProps={{ className: classes.navItemTypography }}
                  />

                  {expandIcon}
                </ListItem>

                {hasSublink && (
                  <Collapse in={expand[link.label]} timeout="auto" unmountOnExit>
                    <List aria-label="sub navigation" component="nav" dense disablePadding>
                      {link.subLinks
                        .filter(subLink => (subLink?.feature ? flagsmith.hasFeature(subLink.feature) : true))
                        .map(subLink => (
                          <ListItem
                            activeClassName="selected"
                            className={clsx(classes.navItem, classes.nestedNavItem)}
                            component={LinkBehavior}
                            dense
                            key={subLink.path}
                            onClick={onCloseNavbar}
                            to={subLink.path}
                          >
                            <ListItemText
                              primary={
                                subLink.new ? (
                                  <Badge
                                    badgeContent="New!"
                                    color="primary"
                                    classes={{ anchorOriginTopRightRectangular: classes.badge }}
                                  >
                                    {subLink.label}
                                  </Badge>
                                ) : (
                                  subLink.label
                                )
                              }
                              primaryTypographyProps={{ className: classes.navItemTypography }}
                            />
                          </ListItem>
                        ))}
                    </List>
                  </Collapse>
                )}
              </Fragment>
            );
          })}
        </List>
      </div>
    </div>
  );
};

LinkBehavior.propTypes = {
  to: PropTypes.string.isRequired,
};

Sidebar.defaultProps = {
  onCloseNavbar: undefined,
};

Sidebar.propTypes = {
  onCloseNavbar: PropTypes.func,
};

export default Sidebar;
