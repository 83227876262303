import { QuickSearch } from "@arowana/ui";
import {
  Chip,
  FormControl,
  FormHelperText,
  FormLabel,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import clsx from "clsx";
import { useContext } from "react";

import { AccountContext } from "../../context/AccountContext";

interface ClientSearch {
  id: string;
  active: boolean;
  archived: boolean;
  name: string;
  phone: string;
  rep_account_id: string;
  supplier_id: string;
}

interface ClientAutocompleteProps {
  className?: string;
  filterIds?: Set<string>;
  helperText?: string;
  label?: string;
  onChange: (client: ClientSearch) => void;
  noOptionsText?: string;
  disableInactive?: boolean;
}

// add clients to a price list, wholesale order create
const ClientAutocomplete = ({
  className = "",
  filterIds = new Set(),
  helperText = "",
  label = "",
  onChange,
  noOptionsText = "No businesses match your search parameters, or they have already been added to this list",
  disableInactive = false,
}: ClientAutocompleteProps) => {
  const { supplier } = useContext(AccountContext);

  const filteredIds = [...filterIds].map(id => `id != "${id}"`).join(" AND ");

  return (
    <FormControl className={className} component="fieldset" fullWidth>
      {label && <FormLabel component="legend">{label}</FormLabel>}

      <QuickSearch
        getOptionLabel={(option: ClientSearch) => option?.name}
        index="clients"
        label=""
        margin="dense"
        noOptionsText={noOptionsText}
        onChange={onChange}
        placeholder="Start searching for a business"
        getOptionDisabled={({ active }) => disableInactive && !active}
        renderOption={({ id, name, phone, active, archived }: ClientSearch) => (
          <ListItemText
            key={id}
            primary={
              <>
                {name} {!active && <Chip color="secondary" label="No access" size="small" variant="outlined" />}{" "}
                {archived && <Chip color="secondary" label="Archived" size="small" variant="outlined" />}
              </>
            }
            secondary={phone}
          />
        )}
        searchParams={{
          filter: [`supplier_id = "${supplier.id}"`, filteredIds, "archived = false"],
          limit: 10,
          sort: ["active:desc"],
        }}
        supplierId={supplier.id}
      />

      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default ClientAutocomplete;
