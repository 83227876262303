import { useMutation, useQuery } from "@apollo/client";
import {
  Box,
  Button,
  Checkbox,
  Collapse,
  FormControlLabel,
  Grid,
  makeStyles,
  MenuItem,
  Typography,
} from "@material-ui/core";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import moment from "moment-timezone";
import qs from "qs";
import { useContext, useEffect, useMemo, useState } from "react";
import useDeepCompareEffect from "use-deep-compare-effect";

import { notificationVar } from "../../../cache/notificationPolicy";
import ConfirmationModal from "../../../components/ConfirmationModal";
import FormCard from "../../../components/FormCard";
import FormTooltip from "../../../components/FormTooltip";
import PageHeader from "../../../components/PageHeader";
import ordersExport from "../../../mutations/ordersExport";
import { supplierExportSettings } from "../../../queries/supplier";
import { AccountContext } from "../../context/AccountContext";
import ExportColumnSelect from "../components/ExportColumnSelect";
import ExportColumnSelection from "../components/ExportColumnSelection";
import ExportPreviewTable from "../components/ExportPreviewTable";
import ExportSortToggle, { SortOrder } from "../components/ExportSortToggle";

const useStyles = makeStyles(theme => ({
  divider: {
    marginBottom: theme.spacing(2),
  },
  goBackButton: {
    marginLeft: theme.spacing(-1),
    textDecoration: "none",
  },
  previewTitle: {
    alignItems: "center",
    display: "inline-flex",
  },
}));
const toSelectValue = ({ resource, path }) => `${resource}-${path}`;
const byPath =
  path =>
  ({ path: fieldPath }) =>
    path === fieldPath;

const newFieldsMapping = {
  "customer.email": "account.email",
  "customer.name": "account.name",
  "customer.phoneNumber": "account.phone",
};

const ExportSheet = ({ history, saveSettings = true }) => {
  const classes = useStyles();

  const {
    audience,
    from,
    fulfilled = "ALL",
    status,
    to,
  } = qs.parse(history.location.search, { ignoreQueryPrefix: true });
  const { supplier } = useContext(AccountContext);
  const { timezone } = supplier;
  const startDate = moment.tz(from, timezone).startOf("day").toISOString();
  const endDate = moment.tz(to, timezone).endOf("day").toISOString();

  const [selectedFields, setSelectedFields] = useState([]);
  const noSelectedFields = selectedFields.length === 0;
  const sortableFields = useMemo(() => selectedFields.filter(({ canSort }) => canSort), [selectedFields]);
  const groupableFields = useMemo(() => selectedFields.filter(({ canGroup }) => canGroup), [selectedFields]);
  const groupingDisabled = groupableFields.length === 0;
  const [primarySort, setPrimarySort] = useState("");
  const onPrimarySortChange = event => setPrimarySort(event.target.value);
  const [primarySortOrder, setPrimarySortOrder] = useState(SortOrder.ASC);
  const onPrimarySortOrderChange = (_, value) => setPrimarySortOrder(value);
  const secondarySortableFields = useMemo(
    () => sortableFields.filter(field => toSelectValue(field) !== primarySort),
    [primarySort, sortableFields],
  );
  const [secondarySort, setSecondarySort] = useState("");
  const onSecondarySortChange = event => setSecondarySort(event.target.value);
  const [secondarySortOrder, setSecondarySortOrder] = useState(SortOrder.ASC);
  const onSecondarySortOrderChange = (_, value) => setSecondarySortOrder(value);
  const [groupChecked, setGroupChecked] = useState(false);
  const onGroupChecked = event => setGroupChecked(event.target.checked);
  const [groupField, setGroupField] = useState("");
  const onGroupFieldChange = event => setGroupField(event.target.value);
  const {
    data: settingsResponse,
    loading: fetchingSettings,
    called,
  } = useQuery(supplierExportSettings, {
    fetchPolicy: "cache-and-network",
    onCompleted: ({ getSupplierAccount, ordersExportFields }) => {
      const { selectedFields = [], sortFields = [] } = getSupplierAccount?.exportSettings?.orders ?? {};
      // Filter out fields that are irrelevant / renamed
      const filteredFields = selectedFields.reduce((joined, { path }) => {
        const targetPath = newFieldsMapping[path] ?? path; // map customer to account if present
        const field = ordersExportFields.find(byPath(targetPath));

        if (field) {
          joined.push(field);
        }

        return joined;
      }, []);

      setSelectedFields(filteredFields);

      if (sortFields?.[0]) {
        const { field, direction } = sortFields[0];
        const fieldByPath = ordersExportFields.find(byPath(field.path));

        if (fieldByPath) {
          setPrimarySort(toSelectValue(fieldByPath));
          setPrimarySortOrder(direction);
        }
      }

      if (sortFields?.[1]) {
        const { field, direction } = sortFields[1];
        const fieldByPath = ordersExportFields.find(byPath(field.path));

        if (fieldByPath) {
          setSecondarySort(toSelectValue(fieldByPath));
          setSecondarySortOrder(direction);
        }
      }
    },
  });

  useDeepCompareEffect(() => {
    const isValid = sortableFields.some(field => toSelectValue(field) === primarySort);

    if (!isValid && called) {
      setPrimarySort(sortableFields.length === 0 ? "" : toSelectValue(sortableFields[0]));
    }
  }, [sortableFields]);

  useDeepCompareEffect(() => {
    const isValid = secondarySortableFields.some(field => toSelectValue(field) === secondarySort);

    if (!isValid && called) {
      setSecondarySort("");
    }
  }, [secondarySortableFields]);

  useEffect(() => {
    if (groupChecked && groupingDisabled) {
      setGroupChecked(false);
      setGroupField("");
    }
  }, [groupChecked, groupingDisabled]);

  useDeepCompareEffect(() => {
    const isValid = groupableFields.some(field => toSelectValue(field) === groupField);

    if (!isValid) {
      setGroupField(groupableFields.length === 0 ? "" : toSelectValue(groupableFields[0]));
    }
  }, [groupableFields]);

  const resetInput = () => {
    setSelectedFields([]);
    setPrimarySort("");
    setPrimarySortOrder(SortOrder.ASC);
    setSecondarySort("");
    setSecondarySortOrder(SortOrder.ASC);
  };

  const fields = useMemo(() => {
    const selected = selectedFields.map(({ path, resource }) => ({ path, resource }));
    const sortBy = [];
    const defaultSortableField = { path: sortableFields[0]?.path, resource: sortableFields[0]?.resource };

    if (primarySort) {
      sortBy.push({
        direction: primarySortOrder,
        field: selected.find(field => toSelectValue(field) === primarySort) ?? defaultSortableField,
      });
    }

    if (secondarySort) {
      sortBy.push({
        direction: secondarySortOrder,
        field: selected.find(field => toSelectValue(field) === secondarySort) ?? defaultSortableField,
      });
    }

    return {
      selected,
      sortBy,
    };
  }, [primarySort, primarySortOrder, secondarySort, secondarySortOrder, selectedFields]);

  const exportInput = {
    ...fields,
    audience,
    endDate,
    ...(fulfilled !== "ALL" && { fulfilled: fulfilled === "true" }),
    startDate,
    status, // must be uppercased with underscore
  };
  //Submit state
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [exportOrders, { loading: submitting }] = useMutation(ordersExport, {
    onCompleted: () => {
      setShowConfirmation(false);
      history.goBack();
      notificationVar({
        message: "Export is being generated. You will receive via email shortly.",
        severity: "success",
      });
    },
    refetchQueries: ["GetSupplierExportSettings"],
    variables: {
      input: exportInput,
      saveSettings,
    },
  });

  return (
    <>
      <Button
        className={classes.goBackButton}
        color="primary"
        onClick={() => history.goBack()}
        startIcon={<KeyboardArrowLeftIcon />}
      >
        Return
      </Button>
      <PageHeader
        primaryActions={
          <>
            <Button color="primary" disabled={noSelectedFields} onClick={resetInput} variant="outlined">
              Discard
            </Button>
            <Button color="primary" onClick={() => setShowConfirmation(true)} variant="contained">
              Export
            </Button>
          </>
        }
        stickyHeader
        title="Export"
      />
      <FormCard>
        <Box marginBottom={2}>
          <Typography variant="subtitle1">Columns</Typography>
          <Typography variant="body2">
            Select and reorder columns to appear in your export (top to bottom = left to right). See preview below.
          </Typography>
        </Box>
        <ExportColumnSelection
          fields={settingsResponse?.ordersExportFields ?? []}
          loading={fetchingSettings}
          onChange={setSelectedFields}
          value={selectedFields}
        />
        <Box marginBottom={2} marginTop={4}>
          <Typography gutterBottom variant="subtitle1">
            Sort columns <Typography variant="caption">(Select up to 2 columns to sort your export by)</Typography>
          </Typography>
          <Grid container spacing={2}>
            <Grid item md={4}>
              <ExportColumnSelect
                disabled={noSelectedFields}
                fields={sortableFields}
                label="Sort by:"
                onChange={onPrimarySortChange}
                value={primarySort}
                valueFormatter={toSelectValue}
              />
              <Collapse in={Boolean(primarySort)}>
                <ExportSortToggle onChange={onPrimarySortOrderChange} value={primarySortOrder} />
              </Collapse>
            </Grid>
            <Grid item md={4}>
              <ExportColumnSelect
                disabled={noSelectedFields}
                emptyItem={
                  <MenuItem value={""}>
                    <em>Select a column</em>
                  </MenuItem>
                }
                fields={secondarySortableFields}
                label="Then by:"
                onChange={onSecondarySortChange}
                value={secondarySort}
                valueFormatter={toSelectValue}
              />
              <Collapse in={Boolean(secondarySort)}>
                <ExportSortToggle onChange={onSecondarySortOrderChange} value={secondarySortOrder} />
              </Collapse>
            </Grid>
          </Grid>
        </Box>
        <Box hidden marginBottom={2}>
          <FormControlLabel
            control={
              <Checkbox checked={groupChecked} color="primary" disabled={groupingDisabled} onChange={onGroupChecked} />
            }
            label="Group"
            labelPlacement="end"
            value="end"
          />
          <Typography variant="caption">(Select a column to group entries and display on seperate sheets)</Typography>
          <Collapse in={groupChecked}>
            <Grid container spacing={2}>
              <Grid container direction="row" item md={4}>
                <ExportColumnSelect
                  fields={groupableFields}
                  label="Group by:"
                  onChange={onGroupFieldChange}
                  value={groupField}
                  valueFormatter={toSelectValue}
                />
              </Grid>
            </Grid>
          </Collapse>
        </Box>
      </FormCard>
      <FormCard>
        <Typography className={classes.previewTitle} gutterBottom variant="subtitle1">
          Preview <FormTooltip content="This preview is based on the data and column order selected above." />
        </Typography>
        <ExportPreviewTable input={exportInput} />
      </FormCard>
      <ConfirmationModal
        cancelRequestButtonText="Cancel"
        confirmRequestButtonText="Generate & Send"
        confirmRequestOnGoing={submitting}
        modalContent="The export will be emailed to the notification emails listed under Store → General."
        modalTitle="Export"
        onCloseModal={() => setShowConfirmation(false)}
        onConfirmClick={exportOrders}
        shouldOpenModal={showConfirmation}
      />
    </>
  );
};
export default ExportSheet;
