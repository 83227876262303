import { Grid, makeStyles } from "@material-ui/core";
import { cloneElement } from "react";

const useStyles = makeStyles(theme => ({
  authForm: {
    padding: theme.spacing(10, 2),
    position: "relative",
  },
  container: {
    height: "100vh",
    justifyContent: "center",
  },
}));
export const AuthContainer = ({ children }) => {
  const classes = useStyles();

  return (
    <Grid className={classes.container} container>
      <Grid item md={5} sm={8} xs={12}>
        {cloneElement(children, { className: classes.authForm })}
      </Grid>
    </Grid>
  );
};

export default AuthContainer;
