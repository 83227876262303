import { Order, OrderLineItem } from "@arowana/graphql";
import Dinero from "dinero.js";

import { notificationVar } from "../cache/notificationPolicy";

const calculateTotal = (order: Order, lineItems: OrderLineItem[]) => {
  const { fees, refunds, discount } = order;
  const subTotal = lineItems.reduce((acc, item) => acc + item.pricePerUnit * item.quantity, 0);
  const totalRefunds = (refunds || []).reduce((acc, refund) => acc + refund.amount, 0);
  const totalFees = (fees || []).reduce((acc, fee) => acc + fee.amount, 0);
  const totalDiscount = discount?.amount ?? 0;

  return subTotal + totalFees - totalRefunds - totalDiscount;
};

const isTotalWithinPreAuthTotal = (order: Order, lineItems: OrderLineItem[] = order.lineItems) => {
  const newTotal = Math.round(calculateTotal(order, lineItems));
  const preAuthTotal = Math.round(order.preAuthorization?.amount ?? 0);

  if (newTotal > preAuthTotal) {
    notificationVar({
      message: `Order total
        (${Dinero({ amount: newTotal }).toFormat("$0,0.00")})
        must be less than pre-auth total
        (${Dinero({ amount: preAuthTotal }).toFormat("$0,0.00")}).
      `,
      severity: "error",
    });

    return false;
  }

  return true;
};

export default isTotalWithinPreAuthTotal;
