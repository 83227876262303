import qs from "qs";

import { isLoggedInVar } from "../cache/authPolicy";
import Routes from "../Constants/Routes";
import client from "../services/apollo";

function Impersonate({ history }) {
  const params = qs.parse(history.location.search, { ignoreQueryPrefix: true });
  const token = params["token"];

  if (token) {
    // Logout
    localStorage.removeItem("auth_token");
    localStorage.removeItem("supplier_account");
    localStorage.removeItem("supplier_info");
    client.clearStore();

    // Login
    localStorage.setItem("auth_token", String(token));
    isLoggedInVar(true);
    history.push(Routes.PRODUCT_LIST);
  }

  return null;
}

export default Impersonate;
