import { gql } from "@apollo/client";

const INTEGRATIONS_FRAGMENT = gql`
  fragment IntegrationsFragment on Supplier {
    id
    integrations {
      mailchimp {
        apiKey
        serverPrefix
        signUpListId
        enableEmailCollection
      }
      drift {
        embedId
      }
      facebook {
        pixelKey
      }
      googleAnalytics {
        googleAnalyticsKey
      }
      googleTagManager {
        id
      }
      hotjar {
        hjid
      }
      shipengine {
        carriers {
          id
          defaultServiceCode
          name
          region
        }
      }
    }
  }
`;

export default INTEGRATIONS_FRAGMENT;
