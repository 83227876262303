import { OrderStatus } from "@arowana/graphql";

interface Status {
  color?: "default" | "primary" | "secondary";
  id: OrderStatus | "all";
  key: string;
  label: string;
}

const Statuses: Status[] = [
  {
    id: "all",
    key: "ALL",
    label: "All",
  },
  {
    color: "secondary",
    id: OrderStatus.cancelled,
    key: "CANCELLED",
    label: "Cancelled",
  },
  {
    color: "primary",
    id: OrderStatus.completed,
    key: "COMPLETED",
    label: "Completed",
  },
  {
    color: "primary",
    id: OrderStatus.delivered,
    key: "DELIVERED",
    label: "Delivered",
  },
  {
    color: "primary",
    id: OrderStatus.in_transit,
    key: "IN_TRANSIT",
    label: "In Transit",
  },
  {
    color: "primary",
    id: OrderStatus.paid,
    key: "PAID",
    label: "Paid",
  },

  {
    color: "secondary",
    id: OrderStatus.payment_failed,
    key: "PAYMENT_FAILED",
    label: "Payment Failed",
  },
  {
    color: "primary",
    id: OrderStatus.submitted,
    key: "SUBMITTED",
    label: "Upcoming",
  },
];

export default Statuses;
