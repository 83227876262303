import { gql, useMutation, useQuery } from "@apollo/client";
import { PromotionApplication, PromotionType } from "@arowana/graphql";
import { DATALAYER } from "@arowana/util";
import { Button, Grid, Typography } from "@material-ui/core";
import moment from "moment";
import { useContext, useMemo, useState } from "react";
import { useForm } from "react-hook-form";

import { notificationVar } from "../../../cache/notificationPolicy";
import ConfirmationModal from "../../../components/ConfirmationModal";
import Loader from "../../../components/Loader";
import PageHeader from "../../../components/PageHeader";
import Routes from "../../../Constants/Routes";
import { AccountContext } from "../../context/AccountContext";
import DiscountCodeSummary from "../component/DiscountCodeSummary";
import DiscountRulesLimits from "../component/DiscountRulesLimits";
import DiscountStatistics from "../component/DiscountStatistics";
import StoreRulesLimits from "../component/StoreRulesLimits";

const PROMOTION = gql`
  query Promotion($id: ID!) {
    promotion(id: $id) {
      id
      code
      disabled
      expiresAt
      maxAmountToUse
      maxAmountToUsePerAccount
      maxTimesToUse
      maxTimesToUsePerAccount
      rule {
        ... on PromotionRuleAmount {
          discountAmount
          minimumOrderAmount
        }
        ... on PromotionRulePercentage {
          discountMultiplier
          minimumOrderAmount
        }
      }
      startsAt
      type
      updatedAt
      usages {
        amount
      }
    }
  }
`;

const PROMOTION_CREATE = gql`
  mutation PromotionCreate($input: PromotionCreateInput!) {
    promotionCreate(input: $input) {
      id
    }
  }
`;

const PROMOTION_UPDATE = gql`
  mutation PromotionUpdate($input: PromotionUpdateInput!) {
    promotionUpdate(input: $input) {
      id
    }
  }
`;

const PromotionDetails = ({ history, match }) => {
  const editMode = Boolean(match.params.id);
  const [openModal, setOpenModal] = useState(false);
  const { supplier } = useContext(AccountContext);
  const { timezone } = supplier;

  const {
    control,
    errors,
    formState: { dirtyFields, isDirty },
    getValues,
    handleSubmit,
    register,
    reset,
    setError,
  } = useForm({
    defaultValues: {
      active: true,
      code: "",
      discountValue: 0,
      expiresAt: moment().endOf("year").format("YYYY-MM-DD"),
      maxAmountToUse: 0,
      maxAmountToUsePerAccount: 0,
      maxTimesToUse: 0,
      maxTimesToUsePerAccount: 0,
      minimumOrderAmount: 0,
      startsAt: moment().format("YYYY-MM-DD"),
      type: PromotionType.percentage,
    },
  });

  const { data, loading: isFetching } = useQuery(PROMOTION, {
    context: { source: DATALAYER },
    fetchPolicy: "cache-and-network",
    onCompleted: ({ promotion }) => {
      reset({
        active: !promotion.disabled,
        code: promotion.code,
        discountValue:
          promotion.type === PromotionType.amount
            ? parseFloat(((promotion.rule.discountAmount ?? 0) / 100).toFixed(2))
            : (promotion.rule.discountMultiplier ?? 0) * 100,
        expiresAt: moment(promotion.expiresAt).format("YYYY-MM-DD"),
        maxAmountToUse: parseFloat(((promotion.maxAmountToUse ?? 0) / 100).toFixed(2)),
        maxAmountToUsePerAccount: parseFloat(((promotion.maxAmountToUsePerAccount ?? 0) / 100).toFixed(2)),
        maxTimesToUse: promotion.maxTimesToUse ?? 0,
        maxTimesToUsePerAccount: promotion.maxTimesToUsePerAccount ?? 0,
        minimumOrderAmount: parseFloat(((promotion.rule.minimumOrderAmount ?? 0) / 100).toFixed(2)),
        startsAt: moment(promotion.startsAt).format("YYYY-MM-DD"),
        type: promotion.type,
      });
    },
    skip: !editMode,
    variables: {
      id: match.params.id,
    },
  });

  const { totalTimes, totalAmount } = useMemo(() => {
    const { usages } = data?.promotion ?? {};

    return {
      totalAmount: Math.abs(parseFloat(((usages?.reduce((acc, cur) => acc + cur.amount, 0) ?? 0) / 100).toFixed(2))),
      totalTimes: usages?.length ?? 0,
    };
  }, [data?.promotion]);

  const [updateDiscount, { loading: isUpdating }] = useMutation(PROMOTION_UPDATE, {
    context: { source: DATALAYER },
    onCompleted: () => {
      notificationVar({
        message: "Discount code updated!",
        severity: "success",
      });
    },
    onError: () => {},
    refetchQueries: ["Promotion"],
  });

  const [createDiscount, { loading: isCreating }] = useMutation(PROMOTION_CREATE, {
    context: { source: DATALAYER },
    onCompleted: response => {
      notificationVar({
        message: "Discount code created!",
        severity: "success",
      });
      history.replace(Routes.DISCOUNT_DETAILS.replace(":id", response.promotionCreate.id));
    },
  });

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const handleDeleteClick = () => {
    handleCloseModal();
    updateDiscount({ variables: { input: { disabled: true, id: match.params.id } } });
  };
  const handleDiscardClick = () => reset();
  const onSubmit = data => {
    if (data.startsAt > data.expiresAt) {
      setError("startsAt", {
        message: "start date must be earlier than end date",
        type: "manual",
      });

      return;
    }

    if (editMode) {
      const variables = {
        input: {
          id: match.params.id,
          ...(dirtyFields.code && { code: data.code }),
          ...(dirtyFields.active && { disabled: !data.active }),
          ...(dirtyFields.expiresAt && { expiresAt: moment(data.expiresAt, "YYYY-MM-DD").endOf("day").utc().format() }),
          ...(dirtyFields.maxAmountToUse && { maxAmountToUse: Math.round(data.maxAmountToUse * 100) }),
          ...(dirtyFields.maxAmountToUsePerAccount && {
            maxAmountToUsePerAccount: Math.round(data.maxAmountToUsePerAccount * 100),
          }),
          ...(dirtyFields.maxTimesToUse && { maxTimesToUse: data.maxTimesToUse }),
          ...(dirtyFields.maxTimesToUsePerAccount && { maxTimesToUsePerAccount: data.maxTimesToUsePerAccount }),
          ...((dirtyFields.minimumOrderAmount || dirtyFields.discountValue) && {
            rule: {
              ...(dirtyFields.minimumOrderAmount && {
                minimumOrderAmount: Math.round(data.minimumOrderAmount * 100),
              }),
              ...(dirtyFields.discountValue &&
                (data.type === PromotionType.amount
                  ? { discountAmount: Math.round(data.discountValue * 100) }
                  : { discountMultiplier: data.discountValue / 100 })),
            },
          }),
          ...(dirtyFields.startsAt && { startsAt: moment(data.startsAt, "YYYY-MM-DD").startOf("day").utc().format() }),
        },
      };

      updateDiscount({ variables });
    } else {
      const type = data.type;
      const variables = {
        input: {
          appliesTo: PromotionApplication.order,
          code: data.code,
          disabled: !data.active,
          expiresAt: moment(data.expiresAt, "YYYY-MM-DD").endOf("day").utc().format(),
          maxAmountToUse: Math.round(data.maxAmountToUse * 100),
          maxAmountToUsePerAccount: Math.round(data.maxAmountToUsePerAccount * 100),
          maxTimesToUse: data.maxTimesToUse,
          maxTimesToUsePerAccount: data.maxTimesToUsePerAccount,
          rule: {
            minimumOrderAmount: Math.round(data.minimumOrderAmount * 100),
            ...(type === PromotionType.amount && { discountAmount: Math.round(data.discountValue * 100) }),
            ...(type === PromotionType.percentage && { discountMultiplier: data.discountValue / 100 }),
          },
          startsAt: moment(data.startsAt, "YYYY-MM-DD").startOf("day").utc().format(),
          type,
        },
      };

      createDiscount({ variables });
    }
  };

  if (isFetching || isUpdating || isCreating) return <Loader />;

  return (
    <>
      <PageHeader
        primaryActions={
          <>
            <Button color="primary" disabled={!isDirty} onClick={handleDiscardClick} variant="outlined">
              Discard
            </Button>
            <Button color="primary" disabled={!isDirty} onClick={handleSubmit(onSubmit)} variant="contained">
              {editMode ? "Save" : "Create"}
            </Button>
          </>
        }
        stickyHeader
        title={editMode ? "Edit promotion" : "Create promotion"}
      >
        {editMode && (
          <Grid alignItems="center" container justifyContent="space-between">
            <Grid item>
              <Typography color="textSecondary" title={moment.tz(data?.promotion?.updatedAt, timezone).format("lll")}>
                Updated {moment.tz(data?.promotion?.updatedAt, timezone).fromNow()}
              </Typography>
            </Grid>
            <Grid item>
              <Grid container justifyContent="flex-end">
                <Button color="secondary" onClick={handleOpenModal} variant="text">
                  Delete
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
      </PageHeader>

      <Grid container spacing={2}>
        <Grid item md={8} xs={12}>
          <DiscountCodeSummary
            control={control}
            editMode={editMode}
            errors={errors}
            getValues={getValues}
            register={register}
          />
          <DiscountRulesLimits control={control} errors={errors} register={register} />
          <StoreRulesLimits errors={errors} register={register} />
        </Grid>
        <Grid item md={4} xs={12}>
          <DiscountStatistics totalAmount={totalAmount} totalTimes={totalTimes} />
        </Grid>
      </Grid>

      {editMode && (
        <ConfirmationModal
          cancelRequestButtonText="Cancel"
          confirmRequestButtonText="Deactivate"
          isDangerAction
          modalContent="Are you sure you want to deactivate this discount code?"
          modalNote="Note: the discount code might be used by some orders so you cannot delete it. We'll deactivate it for you."
          modalTitle="Deactivate discount code"
          onCloseModal={handleCloseModal}
          onConfirmClick={handleDeleteClick}
          shouldOpenModal={openModal}
        />
      )}
    </>
  );
};

export default PromotionDetails;
