import { List } from "@arowana/graphql";
import { QuickSearch, SearchAdornment, SupplierContext } from "@arowana/ui";
import { Chip, makeStyles, TextField, Typography } from "@material-ui/core";
import { useContext, useMemo } from "react";

import { AccountContext } from "../../context/AccountContext";

const useStyles = makeStyles(theme => ({
  selected: {
    margin: theme.spacing(0.5),
  },
}));

export interface ListSearch {
  description: string;
  id: string;
  name: string;
  supplier_id: string;
  thumbnail: string;
}

interface ListAutocompleteProps {
  onChange: (list: ListSearch) => void;
  onUnSelect?: () => void;
  selectedList?: List;
  filterIds?: string[];
}

// add black list to a location, search product lists
const ListAutocomplete = ({ onChange, onUnSelect, selectedList, filterIds = [] }: ListAutocompleteProps) => {
  const classes = useStyles();
  const { id: supplierId } = useContext(SupplierContext);

  const filter = useMemo(() => {
    const list = [`supplier_id = "${supplierId}"`];

    if (selectedList?.id) {
      list.push(`id != "${selectedList.id}"`);
    }

    filterIds.forEach(id => list.push(`id != "${id}"`));

    return list;
  }, [supplierId, selectedList, filterIds]);

  return (
    <QuickSearch
      index="lists"
      getOptionLabel={(option: ListSearch) => option.name}
      noOptionsText="No collections match your search parameters"
      onChange={onChange}
      renderInput={params => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <>
                <SearchAdornment />
                {Boolean(selectedList?.id) && (
                  <Chip className={classes.selected} color="primary" label={selectedList.name} onDelete={onUnSelect} />
                )}
              </>
            ),
          }}
          margin="dense"
          placeholder="Search collections"
          variant="outlined"
        />
      )}
      renderOption={(option: ListSearch) => <Typography>{option.name}</Typography>}
      searchParams={{
        filter,
        limit: 10,
      }}
      supplierId={supplierId}
    />
  );
};

export default ListAutocomplete;
