import { LeadTimes } from "@arowana/graphql";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  makeStyles,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import moment from "moment-timezone";
import { ChangeEvent, Fragment } from "react";

import FormTooltip from "../../../components/FormTooltip";

const useStyles = makeStyles(theme => ({
  deadline: {
    width: theme.spacing(20),
  },
}));

type LocationLeadTimeProps = {
  helperText?: string;
  value?: LeadTimes;
  onChange?: (val: LeadTimes) => void;
};

enum Weekday {
  Monday = 1,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
  Sunday,
}

const DAYS = [
  Weekday.Monday,
  Weekday.Tuesday,
  Weekday.Wednesday,
  Weekday.Thursday,
  Weekday.Friday,
  Weekday.Saturday,
  Weekday.Sunday,
];

const DAYS_IN_ADVANCE = Array.from({ length: 30 }, (_, i) => i + 1);

function LocationLeadTime({ helperText, value, onChange }: LocationLeadTimeProps) {
  const classes = useStyles();

  const handleDayChange = (day: Weekday) => {
    return (event: ChangeEvent<HTMLInputElement>) => {
      const leadTimes = { ...value };

      if (event.target.checked) {
        leadTimes[day] = 1;
      } else {
        delete leadTimes[day];
      }

      onChange(leadTimes);
    };
  };

  const handleLeadTimeChange = (day: Weekday) => {
    return (event: ChangeEvent<HTMLSelectElement>) => {
      const leadTimes = {
        ...value,
        [day]: event.target.value,
      };

      onChange(leadTimes);
    };
  };

  const getValue = (day: Weekday) => {
    if (day in value) {
      return value[day];
    }

    return 1;
  };

  return (
    <Grid container>
      <Grid item xs={6}>
        <Typography gutterBottom variant="subtitle1">
          Fulfillment dates{" "}
          <FormTooltip content="The chosen fulfillment dates below will be selectable by customers on checkout." />
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography gutterBottom variant="subtitle1">
          Order deadline{" "}
          <FormTooltip content="Example: to set a Monday 6PM deadline for Wednesday orders, (1) set the order cutoff hour to 6PM, (2) enable Wednesday as a fulfillment date, and (3) set the order deadline to 2 days before." />
        </Typography>
      </Grid>
      {helperText && (
        <Grid xs={12}>
          <FormHelperText error>{helperText}</FormHelperText>
        </Grid>
      )}

      {DAYS.map(day => (
        <Fragment key={`day-${day}`}>
          <Grid item xs={6}>
            <FormControlLabel
              value="end"
              control={<Checkbox checked={day in value} color="primary" onChange={handleDayChange(day)} />}
              label={moment().isoWeekday(day).format("dddd")}
              labelPlacement="end"
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl className={classes.deadline} variant="outlined" size="small">
              <Select value={getValue(day)} onChange={handleLeadTimeChange(day)}>
                <MenuItem value={0}>Same day</MenuItem>
                {DAYS_IN_ADVANCE.map(d => (
                  <MenuItem key={`day-${d}`} value={d}>
                    {d == 1 ? `${d} day` : `${d} days`} before
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Fragment>
      ))}
    </Grid>
  );
}

export default LocationLeadTime;
