import { Box, Button, CircularProgress, makeStyles, TextField } from "@material-ui/core";
import { useForm } from "react-hook-form";

import { usePasswordToggle } from "../hooks";

const useStyles = makeStyles(theme => ({
  link: {
    color: theme.palette.primary.main,
    cursor: "pointer",
    padding: theme.spacing(1, 0, 2, 0),
    textAlign: "left",
    textDecoration: "underline",
  },
  spacing: {
    marginTop: theme.spacing(2),
  },
}));

type LoginFormProps = {
  email: string;
  password: string;
};

type LoginProps = {
  isSubmitting: boolean;
  onForgotPasswordClick?: () => void;
  onLogin: (props: LoginFormProps) => void;
};

export const LoginForm = ({ isSubmitting, onForgotPasswordClick, onLogin }: LoginProps) => {
  const classes = useStyles();
  const defaultValues: LoginFormProps = {
    email: "",
    password: "",
  };
  const { watch, register, handleSubmit, errors } = useForm({
    defaultValues,
  });

  const { PasswordToggle, passwordInputType } = usePasswordToggle(watch);

  return (
    <form onSubmit={handleSubmit(onLogin)}>
      <TextField
        autoFocus
        error={!!errors.email}
        fullWidth
        helperText={errors?.email?.message}
        inputProps={{
          autoComplete: "on",
        }}
        inputRef={register({ required: "*required" })}
        label="Email address"
        name="email"
        type="email"
        variant="outlined"
      />

      <TextField
        className={classes.spacing}
        error={!!errors.password}
        fullWidth
        helperText={errors?.password?.message}
        inputRef={register({
          minLength: {
            message: " is too short — Minimum 6 characters.",
            value: 6,
          },
          required: "*required",
        })}
        InputProps={{ endAdornment: PasswordToggle }}
        label="Password"
        name="password"
        type={passwordInputType}
        variant="outlined"
      />

      {onForgotPasswordClick && (
        <Box className={classes.link} onClick={onForgotPasswordClick}>
          Forgot your password?
        </Box>
      )}

      <Button
        variant="contained"
        className={classes.spacing}
        color="primary"
        disabled={isSubmitting}
        fullWidth
        type="submit"
      >
        {isSubmitting ? <CircularProgress color="inherit" size={24} /> : "Log In"}
      </Button>
    </form>
  );
};

export default LoginForm;
