import { Accordion, AccordionDetails, AccordionSummary, Box, Button, makeStyles, Typography } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import ReactHtmlParser from "react-html-parser";

const useStyles = makeStyles(theme => ({
  faqAnswer: {
    backgroundColor: theme.palette.grey[100],
    borderBottomLeftRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius,
    flexDirection: "column",
  },
  faqContainer: {
    "&:before": { content: "none" },
    "border": "none",
    "boxShadow": "none",
    "padding": theme.spacing(1, 0),
  },
  faqTitleBlock: {
    backgroundColor: theme.palette.grey[200],
    borderRadius: theme.shape.borderRadius,
  },
  faqTitleExpandBlock: {
    backgroundColor: theme.palette.grey[200],
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
  },
  longWord: {
    wordBreak: "break-word",
  },
}));
const FaqDisplay = ({ faq, onDeleteFaq, onEditFaq }) => {
  const classes = useStyles();
  const formattedAnswer = useMemo(() => faq?.answer && ReactHtmlParser(faq?.answer), [faq?.answer]);

  return (
    <Accordion className={classes.faqContainer}>
      <AccordionSummary
        classes={{ expanded: classes.faqTitleExpandBlock, root: classes.faqTitleBlock }}
        expandIcon={<ExpandMoreIcon />}
      >
        <Typography className={classes.longWord} variant="subtitle2">
          {faq?.question}
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.faqAnswer}>
        <Typography className={clsx(classes.longWord, "ql-content")} variant="body2">
          {formattedAnswer}
        </Typography>
        <Box display="flex" justifyContent="flex-end" mt={1}>
          <Button color="secondary" onClick={onDeleteFaq}>
            Delete
          </Button>
          <Button color="primary" onClick={onEditFaq}>
            Edit
          </Button>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

FaqDisplay.propTypes = {
  faq: PropTypes.shape({
    answer: PropTypes.string.isRequired,
    question: PropTypes.string.isRequired,
  }).isRequired,
  onDeleteFaq: PropTypes.func.isRequired,
  onEditFaq: PropTypes.func.isRequired,
};

export default FaqDisplay;
