import React from "react";

import FeeItem from "./FeeItem";

const FeeItems = React.forwardRef(({ currencyFormatter, onChange, order, value }, ref) => {
  const onChangeItem = index => item => {
    onChange(
      Object.assign([], value, {
        [index]: item,
      }),
    );
  };
  const onRemoveItem = index => () => {
    const newValue = Object.assign([], value);

    newValue.splice(index, 1);

    onChange(newValue);
  };

  return (
    <>
      {value.map(({ open, isNew, ...rest }, index) => (
        <FeeItem
          currencyFormatter={currencyFormatter}
          index={index}
          value={rest}
          key={index}
          open={Boolean(open)}
          isNew={Boolean(isNew)}
          onChange={onChangeItem(index)}
          onRemove={onRemoveItem(index)}
          order={order}
        />
      ))}
    </>
  );
});

export default FeeItems;
