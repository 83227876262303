import { gql, useMutation, useQuery } from "@apollo/client";
import { WholesaleListsSortField } from "@arowana/graphql";
import { PaginatedTable, usePagination } from "@arowana/ui";
import { DATALAYER } from "@arowana/util";
import {
  Box,
  Button,
  Divider,
  Fade,
  Grid,
  IconButton,
  Link,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { GridSortDirection } from "@material-ui/data-grid";
import { Close, Search } from "@material-ui/icons";
import { ExpandLess, ExpandMore } from "@material-ui/icons/";
import moment from "moment";
import qs from "qs";
import { useContext, useMemo, useState } from "react";
import { Link as RouterLink } from "react-router-dom";

import { notificationVar } from "../../../cache/notificationPolicy";
import FormCard from "../../../components/FormCard";
import PageHeader from "../../../components/PageHeader";
import PaywallCard from "../../../components/PaywallCard";
import Routes from "../../../Constants/Routes";
import { FlagsmithContext } from "../../context/FlagsmithContext";
import DefaultWholesaleList from "../components/DefaultWholesaleList";

const WHOLESALE_LISTS = gql`
  query WholesaleLists($filter: WholesaleListsFilterInput, $sort: WholesaleListsSortInput, $page: PaginationInput) {
    currentSupplier {
      id
      wholesaleLists(filter: $filter, sort: $sort, page: $page) {
        edges {
          cursor
          node {
            id
            name
            createdAt
            updatedAt
          }
        }
        pageInfo {
          count
          endCursor
          hasNextPage
          totalCount
        }
      }
    }
  }
`;

const CREATE_LIST_ALL_PROD = gql`
  mutation CreateListWithProd($name: String!, $description: String!) {
    wholesaleListCreateWithAllProducts(name: $name, description: $description) {
      id
    }
  }
`;

const PRICELIST_IMG = "https://freshline.io/images/features/price-list-2.png";

const getColumns = (query: string | qs.ParsedQs | string[] | qs.ParsedQs[]) => [
  {
    field: "id",
    hide: true,
  },
  {
    field: WholesaleListsSortField.NAME,
    flex: 2,
    headerName: "Name",
    renderCell: param => (
      <Link component={RouterLink} to={Routes.WHOLESALE_LIST_DETAILS.replace(":id", param.row.id)}>
        {param.value}
      </Link>
    ),
    sortable: !query,
    valueGetter: param => param.row.name,
  },
  {
    field: WholesaleListsSortField.CREATED_AT,
    flex: 1,
    headerName: "Created on",
    sortable: !query,
    valueGetter: param => moment(param.row.createdAt).format("LL"),
  },
  {
    field: WholesaleListsSortField.UPDATED_AT,
    flex: 1,
    headerName: "Last updated on",
    sortable: !query,
    valueGetter: param => moment(param.row.updatedAt).format("LLL"),
  },
];

const useStyles = makeStyles(theme => ({
  createMenu: {
    marginTop: theme.spacing(6),
  },
  filtersContainer: {
    "& > *": {
      margin: theme.spacing(2, 0, 0, 2),
    },
    "display": "inline-flex",
    "flexFlow": "row wrap",
    "justifyContent": "space-between",
    "margin": theme.spacing(-2, 0, 0, -2),
    "marginBottom": theme.spacing(2),
    "width": `calc(100% + ${theme.spacing(2)}px)`,
  },
  searchIcon: {
    marginRight: theme.spacing(1),
  },
}));

const defaultSortState = {
  field: WholesaleListsSortField.UPDATED_AT,
  sort: "desc" as GridSortDirection,
};

const ROW_PER_PAGE = 100;

const Lists = ({ history }) => {
  const classes = useStyles();
  const flagsmith = useContext(FlagsmithContext);
  const hasWholesale = flagsmith.hasFeature("b2b");

  const queryParams = qs.parse(history.location.search, { ignoreQueryPrefix: true });
  const { query = "" } = queryParams;
  const hasSearchQuery = Boolean(query);

  const columns = useMemo(() => getColumns(query), [query]);

  const [searchInput, setSearchInput] = useState(query);
  const onSearchInputChange = event => setSearchInput(event.target.value);
  const onSearchSubmit = ({ type, key }) => {
    if (type === "keydown" && key === "Enter") {
      updateQuery({ query: searchInput });
    }
  };
  const onSearchClear = () => {
    setSearchInput("");
    setSortState(defaultSortState);
    updateQuery({ query: undefined });
  };

  const [sortState, setSortState] = useState(defaultSortState);
  const { setPageInfo, resetPagination, pagination, page, onPageChange } = usePagination(ROW_PER_PAGE);

  const { loading, data } = useQuery(WHOLESALE_LISTS, {
    context: { source: DATALAYER },
    fetchPolicy: "network-only",
    onCompleted: response => {
      setPageInfo(response?.currentSupplier?.wholesaleLists?.pageInfo);
    },
    onError: resetPagination,
    variables: {
      filter: {
        query: searchInput,
      },
      page: pagination,
      sort: {
        field: sortState?.field,
        order: sortState?.sort === "desc" ? -1 : 1,
      },
    },
  });

  const totalCount = data?.currentSupplier?.wholesaleLists?.pageInfo?.totalCount ?? 0;

  const onSortChange = ({ sortModel }) => {
    resetPagination();
    setSortState(sortModel[0]);
  };

  const rows = useMemo(
    () => data?.currentSupplier?.wholesaleLists?.edges?.map(({ node }) => node) ?? [],
    [data?.currentSupplier?.wholesaleLists],
  );

  const sortModel = !query && sortState ? [sortState] : [];

  const updateQuery = keyValue => {
    history.replace({
      search: qs.stringify({
        ...queryParams,
        ...keyValue,
      }),
    });
    resetPagination();
  };

  const onPageChangeExtra = params => {
    window.scrollTo(0, 0);
    onPageChange(params);
  };

  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const onCreateClick = event => setMenuAnchorEl(event.currentTarget);
  const onCreateMenuClose = () => setMenuAnchorEl(null);

  const handleCreateEmptyList = () => history.push(Routes.WHOLESALE_LIST_CREATE);

  const [createListWithProds, { loading: creatingList }] = useMutation(CREATE_LIST_ALL_PROD, {
    context: { source: DATALAYER },
    onCompleted: data => {
      const listId = data?.wholesaleListCreateWithAllProducts?.id;

      if (listId) {
        history.push(Routes.WHOLESALE_LIST_DETAILS.replace(":id", listId));
        notificationVar({
          message: "Price list created!",
          severity: "success",
        });
      }
    },
  });
  const onCreateListWithProducts = () => {
    onCreateMenuClose();
    createListWithProds({
      variables: {
        description: `Created on ${moment().format("LLL")}`,
        name: "New list with all products",
      },
    });
  };

  return (
    <>
      <PageHeader
        primaryActions={
          <>
            <Button
              color="primary"
              onClick={onCreateClick}
              variant="contained"
              endIcon={menuAnchorEl ? <ExpandLess fontSize="small" /> : <ExpandMore fontSize="small" />}
              disabled={creatingList || !hasWholesale}
            >
              {creatingList ? "Creating..." : "Create Price List"}
            </Button>
            <Menu
              anchorEl={menuAnchorEl}
              anchorOrigin={{
                horizontal: "right",
                vertical: "bottom",
              }}
              className={classes.createMenu}
              transformOrigin={{
                horizontal: "right",
                vertical: "top",
              }}
              onClose={onCreateMenuClose}
              open={Boolean(menuAnchorEl)}
            >
              <MenuItem onClick={handleCreateEmptyList}>Empty price list</MenuItem>
              <MenuItem onClick={onCreateListWithProducts}>Price list with all products</MenuItem>
            </Menu>
          </>
        }
        stickyHeader
        title="Price lists"
      />
      {hasWholesale ? (
        <>
          <FormCard title="Default price list">
            <DefaultWholesaleList />
          </FormCard>
          <Box my={2}>
            <Divider variant="middle" />
          </Box>
          <Box className={classes.filtersContainer} flex="3">
            <TextField
              InputProps={{
                endAdornment: (
                  <Fade in={searchInput.length > 0} unmountOnExit>
                    <IconButton aria-label="search" id="search-submit" onClick={onSearchClear} size="small">
                      <Close />
                    </IconButton>
                  </Fade>
                ),
                startAdornment: <Search className={classes.searchIcon} />,
              }}
              fullWidth
              label="Search"
              onChange={onSearchInputChange}
              onKeyDown={onSearchSubmit}
              placeholder="Search price lists"
              value={searchInput}
              variant="outlined"
            />
          </Box>
          <PaginatedTable
            columns={columns}
            defaultContent={hasSearchQuery ? "No price lists found" : "No price lists"}
            loading={loading}
            onPageChange={onPageChangeExtra}
            onSortModelChange={onSortChange}
            page={page}
            pageSize={ROW_PER_PAGE}
            rows={rows}
            sortModel={sortModel}
            totalCount={totalCount}
          />
        </>
      ) : (
        <PaywallCard title="Custom price lists" imageUrl={PRICELIST_IMG}>
          <Typography variant="body1">
            Set custom pricing and availability by customer group. Ditch the old PDF product catalog with a modern,
            mobile-friendly alternative.{" "}
            <Link href="http://freshline.io/price-list-product-catalog" rel="noreferrer" target="_blank">
              Learn more.
            </Link>
          </Typography>
        </PaywallCard>
      )}
    </>
  );
};

export default Lists;
