import { OnWheelBlur } from "@arowana/ui";
import { unitLabel } from "@arowana/util";
import { Box, FormLabel, Grid, InputAdornment, makeStyles, TextField, Typography } from "@material-ui/core";
import { Controller, useWatch } from "react-hook-form";

import DropdownSelect from "../../../../components/DropdownSelect";
import FormCard from "../../../../components/FormCard";
import FormTooltip from "../../../../components/FormTooltip";
import RichTextFormComponent from "../../../../components/RichTextFormComponent";
import { VARIANT_FIELDS } from "../../pages/ProductDetails";
import ProductImages from "./ProductImages";

const useStyles = makeStyles(theme => ({
  checkbox: {
    "& .MuiButtonBase-root": {
      padding: theme.spacing(0.5),
    },
    "marginBottom": theme.spacing(0.25),
    "marginRight": 0,
  },
  description: { margin: theme.spacing(1, 0) },
}));

const ProductSummary = ({ control, errors, register, showFirstOption, unitOptions }) => {
  const classes = useStyles();
  const defaultVariant = useWatch({
    control,
    defaultValue: VARIANT_FIELDS,
    name: "variant",
  });

  return (
    <FormCard>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormLabel htmlFor="name" required>
            Name
          </FormLabel>
          <TextField
            error={Boolean(errors?.name)}
            fullWidth
            helperText={errors?.name?.message}
            inputRef={register({
              required: "*required",
            })}
            name="name"
            id="name"
            size="small"
            margin="dense"
            variant="outlined"
          />
        </Grid>

        {showFirstOption && (
          <Grid item xs={12}>
            <input name="variant.id" type="hidden" ref={register} defaultValue={defaultVariant.id} />
            <FormLabel htmlFor="variant-name" required>
              Subname
            </FormLabel>
            <TextField
              defaultValue={defaultVariant.name}
              error={Boolean(errors?.variant?.name)}
              fullWidth
              helperText={errors?.variant?.name?.message}
              inputRef={register({
                required: "*required",
              })}
              name="variant.name"
              id="variant-name"
              size="small"
              margin="dense"
              variant="outlined"
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <FormLabel htmlFor="description" required>
            Description
          </FormLabel>
          <RichTextFormComponent
            className={classes.description}
            control={control}
            helperText={errors?.description?.message}
            name="description"
            id="description"
            defaultValue=""
            required
          />
        </Grid>

        <Grid item xs={12}>
          <FormLabel htmlFor="product-images">Product images</FormLabel>
          <Controller
            as={<ProductImages />}
            control={control}
            helperText={errors?.productImages?.message}
            name="productImages"
          />
        </Grid>

        {showFirstOption && (
          <>
            <Grid item xs={12} md={6}>
              <FormLabel htmlFor="variant-price" required>
                Price <FormTooltip content="Enter the selling price per chosen unit of measure here." />
              </FormLabel>
              <TextField
                defaultValue={defaultVariant.price}
                error={Boolean(errors?.variant?.price)}
                fullWidth
                size="small"
                margin="dense"
                helperText={errors?.variant?.price?.message}
                InputProps={{
                  endAdornment: defaultVariant.unit ? (
                    <InputAdornment position="end">
                      <Typography color="textSecondary">per {unitLabel(defaultVariant.unit)}</Typography>
                    </InputAdornment>
                  ) : null,
                  inputProps: {
                    min: 0,
                    step: 0.01,
                  },
                  onWheel: OnWheelBlur,
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
                inputRef={register({
                  min: {
                    message: "invalid value",
                    value: 0,
                  },
                  required: "*required",
                })}
                name="variant.price"
                id="variant-price"
                type="number"
                variant="outlined"
                aria-describedby="variant-price-helper"
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <FormLabel htmlFor="variant-case-size" required>
                Case size & unit{" "}
                <FormTooltip content="Enter the case size or sale increments for this product. For example, if you sell by the fillet and the average piece is 1.5 pounds, enter '1.5'." />
              </FormLabel>
              <TextField
                defaultValue={defaultVariant.caseSize}
                error={Boolean(errors?.variant?.caseSize) || Boolean(errors?.variant?.unit)}
                fullWidth
                helperText={errors?.variant?.caseSize?.message ?? errors?.variant?.unit?.message}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Controller
                        as={<DropdownSelect />}
                        variant="standard"
                        control={control}
                        defaultValue={defaultVariant.unit}
                        disableUnderline
                        fullWidth
                        name="variant.unit"
                        options={unitOptions}
                        rules={{ required: "*required" }}
                      />
                    </InputAdornment>
                  ),
                  inputProps: {
                    min: 0.01,
                    step: 0.01,
                  },
                  onWheel: OnWheelBlur,
                }}
                inputRef={register({
                  min: {
                    message: "invalid value",
                    value: 0.01,
                  },
                  required: "*required",
                })}
                name="variant.caseSize"
                id="variant-case-size"
                aria-describedby="variant-case-size-helper"
                size="small"
                margin="dense"
                type="number"
                variant="outlined"
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <FormLabel htmlFor="variant-unit-cost">
                Cost{" "}
                <FormTooltip content="Enter your unit cost per chosen unit of measure here. This used for internal purposes only and is not displayed to customers." />
              </FormLabel>
              <TextField
                defaultValue={defaultVariant.cost}
                error={Boolean(errors?.variant?.cost)}
                fullWidth
                helperText={errors?.variant?.cost?.message}
                InputProps={{
                  endAdornment: defaultVariant.unit ? (
                    <InputAdornment position="end">
                      <Typography color="textSecondary">per {unitLabel(defaultVariant.unit)}</Typography>
                    </InputAdornment>
                  ) : null,
                  inputProps: {
                    min: 0,
                    step: 0.01,
                  },
                  onWheel: OnWheelBlur,
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
                inputRef={register({
                  min: {
                    message: "invalid value",
                    value: 0,
                  },
                })}
                name="variant.cost"
                id="variant-unit-cost"
                size="small"
                margin="dense"
                type="number"
                variant="outlined"
              />
            </Grid>
          </>
        )}

        <Grid item md={6} xs={12}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <FormLabel htmlFor="original-price">
              Compare at price{" "}
              <FormTooltip content="Enter a price that is higher than the current selling price to show a strikethrough sale price on your store." />
            </FormLabel>
          </Box>

          <TextField
            error={Boolean(errors?.originalPrice)}
            fullWidth
            helperText={errors?.originalPrice?.message}
            InputProps={{
              endAdornment: defaultVariant.unit ? (
                <InputAdornment position="end">
                  <Typography color="textSecondary">per {unitLabel(defaultVariant.unit)}</Typography>
                </InputAdornment>
              ) : null,
              inputProps: {
                min: 0,
                step: 0.01,
              },
              onWheel: OnWheelBlur,
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            inputRef={register({
              min: {
                message: "invalid value",
                value: 0,
              },
            })}
            name="originalPrice"
            id="original-price"
            aria-describedby="original-price-helper"
            margin="dense"
            size="small"
            type="number"
            variant="outlined"
          />
        </Grid>
      </Grid>
    </FormCard>
  );
};

export default ProductSummary;
