import { gql } from "@apollo/client";

const PRODUCT_FRAGMENT = gql`
  fragment ProductFragment on Product {
    id
    active
    categoryId
    daysToCancel
    description
    featured
    fulfillmentDays {
      orderBy
      fulfilledOn
    }
    images {
      original
      x256
      x512
      x896
      x1664
    }
    name
    onSale
    originalPrice
    showInventory
    tags
    trackInventory
    updatedAt
    variants {
      id
      caseSize
      cost
      name
      outOfStock
      price
      quantity
      shippingWeight
      shippingWeightUnit
      sku
      unit
    }
  }
`;

const ProductCreate = gql`
  mutation ProductCreate($input: ProductCreateInput!, $images: [FileUpload!]) {
    productCreate(input: $input, images: $images) {
      id
    }
  }
`;

const ProductUpdate = gql`
  mutation ProductUpdate($input: ProductUpdateInput!, $images: [FileUpload!]) {
    productUpdate(input: $input, images: $images) {
      ...ProductFragment
      lists {
        _id
        name
      }
    }
  }
  ${PRODUCT_FRAGMENT}
`;

const ProductUpdateMany = gql`
  mutation ProductUpdateMany($input: [ProductUpdateInput!]!) {
    productUpdateMany(input: $input) {
      ...ProductFragment
    }
  }
  ${PRODUCT_FRAGMENT}
`;

const ProductQuery = gql`
  query Product($id: ObjectId!) {
    product(id: $id) {
      ...ProductFragment
      lists {
        _id
        name
      }
    }
  }
  ${PRODUCT_FRAGMENT}
`;

const ProductDelete = gql`
  mutation ProductDelete($id: ObjectId!) {
    productDelete(id: $id) {
      id
    }
  }
`;

const ProductDeleteMany = gql`
  mutation ProductDeleteMany($ids: [ObjectId!]!) {
    productDeleteMany(ids: $ids) {
      id
    }
  }
`;

export { ProductCreate, ProductDelete, ProductDeleteMany, ProductQuery, ProductUpdate, ProductUpdateMany };
