import { Box, Card, makeStyles, Theme, Typography } from "@material-ui/core";
import { HTMLProps, MutableRefObject, useEffect, useLayoutEffect, useRef } from "react";
import Signature from "signature_pad";
import { useDebouncedCallback } from "use-debounce";

import { SignatureExt } from "./SignatureExt";

type SignaturePadProps = {
  canvasProps?: Omit<HTMLProps<HTMLCanvasElement>, "ref">;
  padRef?: MutableRefObject<Signature>;
  disabled?: boolean;
};

type StyleProps = {
  disabled?: boolean;
};

const useStyles = makeStyles<Theme, StyleProps>(theme => ({
  canvas: ({ disabled }) => ({
    display: "block",
    height: "100%",
    pointerEvents: disabled ? "none" : "auto",
    width: "100%",
  }),
  hint: {
    alignItems: "center",
    color: theme.palette.text.hint,
    display: "flex",
    height: "100%",
    justifyContent: "center",
    left: 0,
    pointerEvents: "none",
    position: "absolute",
    top: 0,
    width: "100%",
  },
  root: {
    border: `1px solid ${theme.palette.text.hint}`,
    height: "150px",
    position: "relative",
    width: "100%",
  },
}));

export const SignaturePad = (props: SignaturePadProps) => {
  const rootRef = useRef<HTMLElement | null>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const classes = useStyles({ disabled: props.disabled ?? false });

  const ratio = Math.max(window.devicePixelRatio || 1, 1);
  const { canvasProps, padRef } = props;

  const { callback: resizeCanvas } = useDebouncedCallback(() => {
    const canvas = canvasRef?.current;
    const root = rootRef?.current;

    if (canvas && root) {
      canvas.width = canvas.offsetWidth * ratio;
      canvas.height = canvas.offsetHeight * ratio;
      canvas.getContext("2d").scale(ratio, ratio);

      const pad = padRef?.current;

      if (pad && !pad.isEmpty()) {
        const data = pad.toData();
        pad.clear();
        pad.fromData(data);
        console.log(canvas.getBoundingClientRect());
      }
    }
  }, 300);

  useEffect(() => {
    const canvas = canvasRef?.current;

    if (canvas) {
      resizeCanvas();
      const signPad = SignatureExt(canvas, ratio);

      if (padRef) {
        padRef.current = signPad;
      }
    }
  }, [canvasRef, padRef, ratio, resizeCanvas]);

  useLayoutEffect(() => {
    window.addEventListener("resize", resizeCanvas);

    return () => {
      if (resizeCanvas) {
        window.removeEventListener("resize", resizeCanvas);
      }
    };
  }, [resizeCanvas]);

  return (
    <Card ref={rootRef} className={classes.root}>
      <canvas {...canvasProps} className={classes.canvas} ref={canvasRef}></canvas>
      <Box className={classes.hint}>
        <Typography variant="h2" color="inherit">
          Sign Here
        </Typography>
      </Box>
    </Card>
  );
};

export default SignaturePad;
