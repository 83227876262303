import { Button, makeStyles, Typography } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  button: {
    "&:hover": {
      backgroundColor: "#303030",
    },
    "backgroundColor": "#000",
    "color": "#FFF",
  },
  container: {
    marginTop: theme.spacing(2),
    textAlign: "center",
  },
  content: {
    fontWeight: 500,
    padding: theme.spacing(2, 3),
  },
  title: {
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
    "background": "-webkit-linear-gradient(135deg, #126D49, #68a1e2)",
    "fontWeight": "bold",
  },
}));

export const ErrorPage = ({
  className,
  content,
  handleGoBack,
  title,
}: {
  className?: string;
  content: string;
  handleGoBack: () => void;
  title: string;
}) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.container, className)}>
      <Typography className={classes.title} variant="h2">
        {title}
      </Typography>
      <Typography className={classes.content} variant="body1">
        {content}
      </Typography>
      <Button className={classes.button} onClick={handleGoBack} variant="contained">
        Back to Home Page
      </Button>
    </div>
  );
};
