import { gql, useMutation } from "@apollo/client";
import {
  FEATURE_SUPPLIER_ORDER_SIGNATURE,
  FEATURE_SUPPLIER_RETAIL_ORDER_SIGNATURE,
  FEATURE_SUPPLIER_STRIPE_INVOICES,
} from "@arowana/flags";
import {
  FulfillmentMethod,
  Order,
  OrderAudience,
  OrderLineItem,
  OrderPaymentCollectionMethod,
  OrderStatus,
} from "@arowana/graphql";
import { DATALAYER, unitLabel } from "@arowana/util";
import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Collapse,
  Grid,
  InputAdornment,
  Link,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Create } from "@material-ui/icons";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import FulfillmentChip from "apps/supplier/src/components/FulfillmentChip";
import LocationChip from "apps/supplier/src/components/LocationChip";
import StatusChip from "apps/supplier/src/components/StatusChip";
import clsx from "clsx";
import Mathjs from "mathjs-expression-parser";
import moment from "moment";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import { Link as RouterLink } from "react-router-dom";

import { notificationVar } from "../../../../cache/notificationPolicy";
import Quantity from "../../../../components/Quantity";
import Routes from "../../../../Constants/Routes";
import UpdateOrder from "../../../../queries/UpdateOrder";
import isTotalWithinPreAuthTotal from "../../../../utils/verifyTotal";
import { FlagsmithContext } from "../../../context/FlagsmithContext";

const ORDER_FULFILL = gql`
  mutation PicklistOrderFulfill($id: ID!, $sendInvoice: Boolean! = false, $status: OrderStatus) {
    orderFulfill(id: $id, input: { sendInvoice: $sendInvoice, status: $status }) {
      id
      fulfilled
      stripeInvoice {
        id
        status
        URL
        PdfURL
      }
    }
  }
`;

const DELIVERY_INSTRUCTIONS_MAX_HEIGHT = 36;

const useStyles = makeStyles(theme => ({
  accountInfoSection: {
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.grey[400]}`,
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1, 2),
  },
  cellPrint: {
    "@media print": {
      fontSize: 12,
      paddingBottom: theme.spacing(0.5),
      paddingTop: theme.spacing(0.5),
    },
  },
  collapsed: {
    "&::after": {
      background: `linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%)`,
      content: '""',
      height: "100%",
      left: 0,
      position: "absolute",
      top: 0,
      width: "100%",
    },
  },
  deliveryInstructions: {
    "& > *:first-child": {
      marginTop: 0,
    },
    "& > *:last-child": {
      marginBottom: 0,
    },
  },
  deliveryInstructionsContainer: {
    cursor: "pointer",
    overflow: "hidden",
    position: "relative",
  },
  fulfillmentInfoIcon: {
    color: "rgba(0, 0, 0, 0.54)",
    marginLeft: theme.spacing(1),
  },
  orderDetails: {
    "& > .MuiBox-root": {
      margin: theme.spacing(1, 2),
    },
  },
  packingCell: {
    "@media print": {
      minWidth: 175,
      paddingBottom: theme.spacing(0.5),
      paddingTop: theme.spacing(0.5),
    },
    "minWidth": 235,
    "textAlign": "right",
    "width": "20%",
  },
  packingCellInput: {
    width: "100%",
  },
  root: {
    "@media print": {
      pageBreakAfter: "always",
    },
    "marginBottom": theme.spacing(4),
    "padding": theme.spacing(1),
  },
  signatureButton: {
    marginRight: theme.spacing(2),
  },
  tableBody: {
    "& > tr:last-child > td": {
      borderBottom: "none",
    },
  },
  tableContainer: {
    padding: theme.spacing(1, 0),
  },
  tableFooter: {
    "@media print": {
      display: "none",
    },
    "backgroundColor": theme.palette.grey[100],
    "borderBottomLeftRadius": theme.shape.borderRadius,
    "borderBottomRightRadius": theme.shape.borderRadius,
    "borderTop": `1px solid ${theme.palette.divider}`,
    "display": "flex",
    "flexDirection": "row",
    "justifyContent": "space-between",
    "margin": theme.spacing(-1),
    "padding": theme.spacing(2),
  },
  tableHeading: {
    background: theme.palette.grey[100],
    borderBottom: `1px solid ${theme.palette.divider}`,
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
    margin: theme.spacing(-1),
    marginBottom: 0,
    padding: theme.spacing(1, 2),
  },
  totalRow: {
    "@media print": {
      display: "flex",
      flexDirection: "column",
    },
    "display": "none",
    "margin": theme.spacing(2),
    "textAlign": "right",
  },
  updateLoader: {
    position: "absolute",
  },
  updateTextLoading: {
    visibility: "hidden",
  },
}));

interface OrderInput {
  lineItems: OrderLineItem[];
  trackingCode?: string;
}

const OrderItem = ({ order, location, timezone, onRequestSignature }) => {
  const classes = useStyles();

  const deliveryInstructionsRef = useRef<HTMLInputElement>();
  const [isExpanded, setIsExpanded] = useState(false);
  const [orderInput, setOrderInput] = useState<OrderInput>({ lineItems: undefined });
  const flagsmith = useContext(FlagsmithContext);
  const showSignatureButton =
    (order?.audience === OrderAudience.MARKETPLACE && flagsmith.hasFeature(FEATURE_SUPPLIER_RETAIL_ORDER_SIGNATURE)) ||
    (order?.audience === OrderAudience.WHOLESALE && flagsmith.hasFeature(FEATURE_SUPPLIER_ORDER_SIGNATURE));
  const hasInvoices = flagsmith.hasFeature(FEATURE_SUPPLIER_STRIPE_INVOICES);
  const canSendInvoice = hasInvoices && order.paymentCollectionMethod != OrderPaymentCollectionMethod.AUTOMATIC;

  const {
    account,
    address,
    audience,
    clientId,
    fulfilled,
    fulfillmentDate,
    fulfillmentMethod,
    fulfillmentInstructions,
    fulfillmentTime,
    id,
    invoiceNumber,
    lineItems,
    notes,
    payeeEmail,
    payeeName,
    status,
    total,
    trackingCode,
  } = order as Order;

  const disabled = useMemo(
    () =>
      status !== OrderStatus.in_transit &&
      status !== OrderStatus.submitted &&
      status !== OrderStatus.completed &&
      status !== OrderStatus.payment_failed,
    [status],
  );
  const deliveryInstructions = useMemo(
    () => (address?.notes ? ReactHtmlParser(address.notes) : null),
    [address?.notes],
  );
  const fulfillmentNotes = useMemo(
    () => (fulfillmentInstructions ? ReactHtmlParser(fulfillmentInstructions) : null),
    [fulfillmentInstructions],
  );
  const wholesaleOrder = audience === OrderAudience.WHOLESALE;
  const shouldCollapseDeliveryInstructions =
    (deliveryInstructionsRef?.current?.clientHeight ?? 0) > DELIVERY_INSTRUCTIONS_MAX_HEIGHT;

  useEffect(() => {
    setOrderInput({
      lineItems: lineItems.map(({ quantity, ...rest }) => ({
        quantity: Number(Quantity({ amount: quantity })),
        ...rest,
      })),
    });
  }, [lineItems]);

  const [mutateOrder, { loading: updateOrderLoading }] = useMutation(UpdateOrder, {
    onCompleted: () => {
      notificationVar({
        message: "The packed weights have been updated",
        severity: "success",
      });
    },
    onError: () => {
      notificationVar({
        message: "Unable to update packed weights",
        severity: "error",
      });
    },
    refetchQueries: ["OrdersPicklist"],
  });

  const [mutateOrderFulfilled, { loading: fulfilling }] = useMutation(ORDER_FULFILL, {
    context: { source: DATALAYER },
    fetchPolicy: "no-cache",
    onCompleted: () => {
      notificationVar({
        message: "Order fulfilled!",
        severity: "success",
      });
    },
    onError: () => {
      notificationVar({
        message: "Unable to update order fulfillment status.",
        severity: "error",
      });
    },
    refetchQueries: ["OrdersPicklist"],
  });

  const handleFulfillOrder = (sendInvoice = false) => {
    mutateOrderFulfilled({
      variables: {
        id: order.id,
        sendInvoice,
        status: sendInvoice ? OrderStatus.completed : null,
      },
    });
  };

  const handleTrackingCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    setOrderInput({
      ...orderInput,
      trackingCode: value,
    });
  };

  const handleSetQuantityInputDisplay = (inputProductId: string, inputVariantId: string) => event => {
    const lineItems = orderInput.lineItems.map(({ productId, variantId, quantity, ...rest }) => {
      if (inputProductId === productId && inputVariantId === variantId) {
        return { productId, quantity: event.target.value, variantId, ...rest };
      }

      return {
        productId,
        quantity,
        variantId,
        ...rest,
      };
    });

    setOrderInput({
      ...orderInput,
      lineItems,
    });
  };

  const handleChangeQuantity = (inputProductId, inputVariantId) => event => {
    if (event.type !== "blur" && event.key !== "Enter") {
      return;
    }

    const value = Number(
      Quantity({
        amount: Mathjs.eval(event.target.value),
      }),
    );

    handleSetQuantityInputDisplay(inputProductId, inputVariantId)({ target: { value } });
  };

  const handleSave = () => {
    if (audience === OrderAudience.MARKETPLACE) {
      if (!isTotalWithinPreAuthTotal(order, orderInput.lineItems)) {
        return;
      }
    }

    mutateOrder({
      variables: {
        input: {
          id,
          ...orderInput,
          lineItems: orderInput.lineItems.map(({ productId, variantId, quantity }) => ({
            productId,
            quantity,
            variantId,
          })),
        },
        sendCustomerEmail: false,
      },
    });
  };

  return (
    <Paper className={classes.root} variant="outlined">
      <div className={classes.tableHeading}>
        <Box mt={1} mb={2}>
          <Typography component="h3" variant="h6">
            <Link component={RouterLink} to={`/orders/${id}`}>
              <strong>Order #{invoiceNumber}</strong>
            </Link>
            <StatusChip status={status} />
            <FulfillmentChip fulfillmentType={fulfillmentMethod} />
            <LocationChip location={location} />
          </Typography>
        </Box>

        <Grid container>
          <Grid className={classes.accountInfoSection} item md={5} xs={12}>
            <Typography variant="subtitle2">
              <Link
                component={RouterLink}
                target="_blank"
                to={
                  wholesaleOrder
                    ? Routes.WHOLESALE_CLIENT_DETAILS.replace(":id", clientId)
                    : Routes.CUSTOMER_DETAILS.replace(":id", account.id)
                }
              >
                {payeeName}
              </Link>
            </Typography>
            <Typography component="span" variant="body2">
              {address?.formattedAddress}
            </Typography>
            {payeeEmail && (
              <Typography component="div" variant="body2">
                {payeeEmail}
              </Typography>
            )}
            {account.phone && (
              <Typography component="div" variant="body2">
                {account.phone}
              </Typography>
            )}
            {deliveryInstructions && (
              <>
                <Box mt={1} display="block" displayPrint="none">
                  {shouldCollapseDeliveryInstructions ? (
                    <Box
                      className={clsx(classes.deliveryInstructionsContainer, isExpanded || classes.collapsed)}
                      onClick={() => setIsExpanded(!isExpanded)}
                      title="Click to expand delivery instructions"
                    >
                      <Collapse in={isExpanded} collapsedHeight={DELIVERY_INSTRUCTIONS_MAX_HEIGHT}>
                        <Typography
                          className={classes.deliveryInstructions}
                          color="textSecondary"
                          component="div"
                          ref={deliveryInstructionsRef}
                          variant="body2"
                        >
                          {deliveryInstructions}
                        </Typography>
                      </Collapse>
                    </Box>
                  ) : (
                    <Typography color="textSecondary" component="div" ref={deliveryInstructionsRef} variant="body2">
                      {deliveryInstructions}
                    </Typography>
                  )}
                </Box>
                <Box display="none" displayPrint="block">
                  {deliveryInstructions}
                </Box>
              </>
            )}
          </Grid>

          <Grid item xs={12} md={7}>
            <Box className={classes.orderDetails} display="flex" flexDirection="row" flexWrap="wrap">
              <Box>
                <Typography variant="subtitle2">Fulfillment date</Typography>
                <Typography>{moment.tz(fulfillmentDate, timezone).format("ll")}</Typography>
              </Box>

              <Box>
                <Typography variant="subtitle2">Fulfillment window</Typography>
                <Typography>{fulfillmentTime?.label ?? <em>None</em>}</Typography>
              </Box>

              <Box>
                <Typography variant="subtitle2">Order notes</Typography>{" "}
                <Typography>{notes || <em>None</em>}</Typography>
              </Box>

              <Box>
                <Typography variant="subtitle2">Fulfillment instructions</Typography>{" "}
                {fulfillmentNotes ? (
                  <Typography className="ql-content">{fulfillmentNotes}</Typography>
                ) : (
                  <Typography>
                    <em>None</em>
                  </Typography>
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </div>

      <TableContainer className={classes.tableContainer}>
        <Table>
          <TableHead>
            <TableRow className={classes.cellPrint}>
              <TableCell className={classes.cellPrint}>Product</TableCell>
              <TableCell className={classes.cellPrint}>SKU</TableCell>
              <TableCell className={classes.cellPrint}>Ordered</TableCell>
              <TableCell className={classes.cellPrint}>Packed</TableCell>
            </TableRow>
          </TableHead>

          <TableBody className={classes.tableBody}>
            {orderInput?.lineItems?.map(
              ({
                cases,
                caseSize,
                productId,
                productName,
                quantity,
                quantityOrdered,
                sku,
                subName,
                unit,
                variantId,
              }) => (
                <TableRow className={classes.cellPrint} key={variantId}>
                  <TableCell className={classes.cellPrint}>
                    <Typography variant="caption">{productName}</Typography>
                    <br />
                    <Typography variant="caption">{subName}</Typography>
                  </TableCell>
                  <TableCell className={classes.cellPrint}>
                    <code>{sku}</code>
                  </TableCell>
                  <TableCell className={classes.cellPrint}>
                    {hasInvoices ? cases : (quantityOrdered / caseSize).toFixed(2)}
                    {" × "}
                    <Quantity amount={caseSize} unit={unit} />
                    <br />
                    <Typography variant="caption">
                      Total: <Quantity amount={quantityOrdered} unit={unit} />
                    </Typography>
                  </TableCell>
                  {disabled ? (
                    <TableCell className={classes.cellPrint}>
                      {fulfilled ? <Quantity amount={quantity} unit={unit} /> : <em>{unitLabel(unit, quantity)}</em>}
                    </TableCell>
                  ) : (
                    <TableCell className={classes.packingCell}>
                      <TextField
                        InputProps={{
                          endAdornment: <InputAdornment position="end">{unitLabel(unit)}</InputAdornment>,
                          inputProps: {
                            min: caseSize,
                          },
                          onBlur: handleChangeQuantity(productId, variantId),
                          onKeyPress: handleChangeQuantity(productId, variantId),
                        }}
                        className={classes.packingCellInput}
                        onChange={handleSetQuantityInputDisplay(productId, variantId)}
                        size="small"
                        type="text"
                        value={quantity}
                        variant="outlined"
                      />
                    </TableCell>
                  )}
                </TableRow>
              ),
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Box className={classes.totalRow}>
        <Typography variant="body1">{location?.name}</Typography>
        <Typography variant="body1">Total: ${(total / 100).toFixed(2)}</Typography>
      </Box>

      <Box className={classes.tableFooter} displayPrint="none">
        <Grid container spacing={2}>
          <Grid container item xs={12} sm={8} alignItems="center">
            <Box display="flex" alignItems="center" justifyContent="flex-end">
              <Box mr={2}>
                <Typography variant="body2">{order.fulfilled ? "Fulfilled" : "Unfulfilled"}</Typography>
              </Box>
              <Box>
                {canSendInvoice ? (
                  <Tooltip arrow title="This will mark the order as completed.">
                    <Button
                      variant="outlined"
                      color="primary"
                      disabled={order.fulfilled}
                      onClick={() => handleFulfillOrder(true)}
                    >
                      Fulfill & Invoice
                    </Button>
                  </Tooltip>
                ) : (
                  <Button
                    variant="outlined"
                    color="primary"
                    disabled={order.fulfilled}
                    onClick={() => handleFulfillOrder()}
                  >
                    Fulfill
                  </Button>
                )}
              </Box>
              {fulfilling && (
                <Box mx={2}>
                  <CircularProgress size={22} />
                </Box>
              )}
            </Box>
            {!order.fulfilled && (
              <Tooltip arrow title="Fulfilling an order will send an email update to the customer.">
                <InfoOutlinedIcon className={classes.fulfillmentInfoIcon} fontSize="small" />
              </Tooltip>
            )}

            {(fulfillmentMethod === FulfillmentMethod.shipping || trackingCode) && (
              <Box mx={1}>
                <TextField
                  defaultValue={trackingCode}
                  label="Tracking code"
                  onChange={handleTrackingCodeChange}
                  size="small"
                  type="text"
                  variant="outlined"
                />
              </Box>
            )}
          </Grid>

          <Grid container item xs={12} sm={4} justifyContent="flex-end">
            {showSignatureButton && (
              <Button
                className={classes.signatureButton}
                color="primary"
                variant="outlined"
                onClick={onRequestSignature}
                startIcon={<Create />}
              >
                Signature
              </Button>
            )}

            <Button
              color="primary"
              disabled={disabled || updateOrderLoading || fulfilling}
              onClick={handleSave}
              variant="contained"
            >
              <span className={updateOrderLoading ? classes.updateTextLoading : ""}>Update</span>
              {updateOrderLoading && <CircularProgress className={classes.updateLoader} color="inherit" size={20} />}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default OrderItem;
