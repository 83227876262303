import { gql, useMutation } from "@apollo/client";
import { AddressForm } from "@arowana/ui";
import { DATALAYER } from "@arowana/util";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { Edit as EditIcon } from "@material-ui/icons";
import { useCallback, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";

import { notificationVar } from "../../../cache/notificationPolicy";

const useStyles = makeStyles(theme => ({
  dialogContainer: {
    [theme.breakpoints.only("xs")]: {
      paddingTop: theme.spacing(7),
    },
  },
}));

type FormInputs = {
  address1: string;
  address2: string;
  city: string;
  country: string;
  label: string;
  postalCode: string;
  region: string;
  notes: string;
  sublocality: string;
};

const ADDRESS_UPDATE = gql`
  mutation ClientUpdate($input: ClientUpdateInput!) {
    clientUpdate(input: $input) {
      id
      address {
        id
        address1
        address2
        sublocality
        city
        country
        formattedAddress
        label
        notes
        postalCode
        region
      }
    }
  }
`;

const toDefatulAddress = address => ({
  address1: address.address1,
  address2: address.address2,
  city: address.city,
  country: address.country,
  label: address.label || "Business address",
  notes: address.notes,
  postalCode: address.postalCode,
  region: address.region,
  sublocality: address.sublocality,
});

const ClientAddressItem = ({ address, clientId }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const _isMounted = useRef(true);
  const htmlId = address.id || "NEW";
  const [open, setOpen] = useState(false);
  useEffect(() => {
    return () => {
      _isMounted.current = false;
    };
  }, []);
  const { control, handleSubmit, reset, setValue } = useForm<FormInputs>({
    defaultValues: toDefatulAddress(address),
  });

  const closeDialog = () => {
    if (_isMounted.current) {
      setOpen(false);
    }
  };

  const [updateAddress, { loading: updateAddressLoading }] = useMutation(ADDRESS_UPDATE, {
    context: { source: DATALAYER },
    onCompleted: () => {
      closeDialog();
      notificationVar({
        message: "Address updated!",
        severity: "success",
      });
    },
  });

  const onReset = useCallback(() => reset(toDefatulAddress(address)), [address, reset]);

  useEffect(() => {
    if (address) {
      onReset();
    }
  }, [address, onReset]);

  const handleCancel = () => {
    onReset();

    closeDialog();
  };

  const onSubmit = data => {
    updateAddress({
      variables: {
        input: {
          address: {
            id: address.id,
            ...data,
          },
          id: clientId,
        },
      },
    });
  };

  return (
    <>
      <ListItem disableGutters>
        <ListItemText
          primary={address.label}
          secondary={
            <>
              {address.formattedAddress}
              {address.notes && (
                <>
                  <br />
                  Notes: {address.notes}
                </>
              )}
            </>
          }
        />
        <ListItemSecondaryAction>
          <IconButton edge="end" onClick={() => setOpen(true)}>
            <EditIcon fontSize="small" />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>

      <Dialog
        className={classes.dialogContainer}
        fullScreen={isMobile}
        fullWidth
        maxWidth="sm"
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            setOpen(false);
          }
        }}
        open={open}
        aria-labelledby={`form-dialog-title-${htmlId}`}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle id={`form-dialog-title-${htmlId}`}>Edit address</DialogTitle>
          {updateAddressLoading && <LinearProgress />}
          <DialogContent dividers>
            <AddressForm
              control={control}
              htmlId={htmlId}
              onReset={onReset}
              setValue={setValue}
              country={address?.country}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancel} color="primary" variant="outlined" tabIndex="0">
              Cancel
            </Button>
            <Button color="primary" variant="contained" tabIndex="0" type="submit" disabled={updateAddressLoading}>
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default ClientAddressItem;
