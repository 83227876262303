import { OnWheelBlur } from "@arowana/ui";
import { FormControl, FormHelperText, FormLabel, Grid, InputAdornment, TextField, Typography } from "@material-ui/core";
import { UseFormMethods } from "react-hook-form";

import FormCard from "../../../components/FormCard";

interface StoreRulesLimitsProps {
  errors: UseFormMethods["errors"];
  register: UseFormMethods["register"];
}

const StoreRulesLimits = ({ errors, register }: StoreRulesLimitsProps) => (
  <FormCard title="Store rules & limits">
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography color="textSecondary" variant="body2">
          Set aggregate discount rules and limits across your entire store. NOTE: values below must be greater than the
          values set in Discount rules & limits.
        </Typography>
      </Grid>
      <Grid item md={6} xs={12}>
        <FormControl fullWidth margin="none">
          <FormLabel htmlFor="max-amount-to-use-per-account">Max. discount per customer</FormLabel>
          <TextField
            InputProps={{
              inputProps: {
                min: 0,
                step: 0.01,
              },
              onWheel: OnWheelBlur,
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            aria-describedby="max-amount-to-use-per-account-helper"
            error={Boolean(errors?.maxAmountToUsePerAccount)}
            fullWidth
            helperText={errors?.maxAmountToUsePerAccount?.message}
            id="max-amount-to-use-per-account"
            inputRef={register({
              min: {
                message: "invalid value",
                value: 0,
              },
              required: "*required",
              valueAsNumber: true,
            })}
            margin="normal"
            name="maxAmountToUsePerAccount"
            size="small"
            type="number"
            variant="outlined"
          />
          <FormHelperText id="max-amount-to-use-per-account-helper">
            Max discount per customer, across all of their orders.
          </FormHelperText>
        </FormControl>
      </Grid>

      <Grid item md={6} xs={12}>
        <FormControl fullWidth margin="none">
          <FormLabel htmlFor="max-amount-to-use">Max. total discount</FormLabel>
          <TextField
            InputProps={{
              inputProps: {
                min: 0,
                step: 0.01,
              },
              onWheel: OnWheelBlur,
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            aria-describedby="max-amount-to-use-helper"
            error={Boolean(errors?.maxAmountToUse)}
            fullWidth
            helperText={errors?.maxAmountToUse?.message}
            id="max-amount-to-use"
            inputRef={register({
              min: {
                message: "invalid value",
                value: 0,
              },
              required: "*required",
              valueAsNumber: true,
            })}
            margin="normal"
            name="maxAmountToUse"
            size="small"
            type="number"
            variant="outlined"
          />
          <FormHelperText id="max-amount-to-use-helper">
            Max discount per store, across all customer orders.
          </FormHelperText>
        </FormControl>
      </Grid>
    </Grid>

    <Grid container spacing={2}>
      <Grid item md={6} xs={12}>
        <FormControl fullWidth margin="none">
          <FormLabel htmlFor="max-times-to-use-per-account">Max. uses per customer</FormLabel>
          <TextField
            InputProps={{
              inputProps: {
                min: 1,
                step: 1,
              },
              onWheel: OnWheelBlur,
            }}
            aria-describedby="max-times-to-use-per-account-helper"
            error={Boolean(errors?.maxTimesToUsePerAccount)}
            fullWidth
            helperText={errors?.maxTimesToUsePerAccount?.message}
            id="max-times-to-use-per-account"
            inputRef={register({
              required: "*required",
              validate: value => Number.isInteger(Number(value)) || "must be an integer",
              valueAsNumber: true,
            })}
            margin="normal"
            name="maxTimesToUsePerAccount"
            size="small"
            type="number"
            variant="outlined"
          />
          <FormHelperText id="max-times-to-use-per-account-helper">
            Max times discount can be used by one customer.
          </FormHelperText>
        </FormControl>
      </Grid>
      <Grid item md={6} xs={12}>
        <FormLabel htmlFor="max-times-to-use">Max. total uses</FormLabel>
        <TextField
          InputProps={{
            inputProps: {
              min: 1,
              step: 1,
            },
            onWheel: OnWheelBlur,
          }}
          aria-describedby="max-times-to-use-helper"
          error={Boolean(errors?.maxTimesToUse)}
          fullWidth
          helperText={errors?.maxTimesToUse?.message}
          id="max-times-to-use"
          inputRef={register({
            required: "*required",
            validate: value => Number.isInteger(Number(value)) || "must be an integer",
            valueAsNumber: true,
          })}
          margin="normal"
          name="maxTimesToUse"
          size="small"
          type="number"
          variant="outlined"
        />
        <FormHelperText id="max-times-to-use-helper">
          Max times discount can be used in total, across all customers.
        </FormHelperText>
      </Grid>
    </Grid>
  </FormCard>
);

export default StoreRulesLimits;
