import { FulfillmentMethod } from "@arowana/graphql";
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel } from "@material-ui/core";
import capitalize from "lodash/capitalize";
import { useContext } from "react";

import { AccountContext } from "../../context/AccountContext";

interface FulfillmentMethodOptionProps {
  checked: Set<FulfillmentMethod>;
  fulfillmentMethod: FulfillmentMethod;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const FulfillmentMethodOption = ({ checked, fulfillmentMethod, onChange }: FulfillmentMethodOptionProps) => (
  <FormControlLabel
    control={
      <Checkbox checked={checked.has(fulfillmentMethod)} color="primary" name={fulfillmentMethod} onChange={onChange} />
    }
    label={capitalize(fulfillmentMethod)}
  />
);

interface FulfillmentMethodsProps {
  className: string;
  disabled: boolean;
  helperText: string;
  onChange?: (value: FulfillmentMethod[]) => void;
  value?: FulfillmentMethod[];
}

const FulfillmentMethods = ({ className, disabled, helperText, onChange, value }: FulfillmentMethodsProps) => {
  const { supplier } = useContext(AccountContext);
  const shippingEnabled = supplier?.shippingEnabled;
  let methodOptions: FulfillmentMethod[] = Object.values(FulfillmentMethod);

  if (!shippingEnabled) {
    methodOptions = methodOptions.filter(method => method !== FulfillmentMethod.shipping);
  }

  const checked = new Set(value);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const toggledFulfillmentMethod = event.target.name as FulfillmentMethod;

    if (event.target.checked) {
      checked.add(toggledFulfillmentMethod);
    } else {
      checked.delete(toggledFulfillmentMethod);
    }

    onChange(Array.from(checked));
  };

  return (
    <FormControl className={className} disabled={disabled} fullWidth>
      <FormLabel htmlFor="fulfillmentMethods">Fulfillment methods</FormLabel>
      <FormGroup row>
        {methodOptions.map(fulfillmentMethod => (
          <FulfillmentMethodOption
            key={fulfillmentMethod}
            checked={checked}
            fulfillmentMethod={fulfillmentMethod}
            onChange={handleChange}
          />
        ))}
      </FormGroup>
      {helperText && <FormHelperText error>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default FulfillmentMethods;
