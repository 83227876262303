import { useCallback, useState } from "react";

interface StateFunction {
  addItem: (item: string) => void;
  clearItems: () => void;
  items: Set<string>;
  removeItem: (item: string) => void;
}

export const useSetState = (defaultState: Set<string> = new Set()): StateFunction => {
  const [state, setState] = useState<Set<string>>(defaultState);
  const add = useCallback((item: string) => {
    setState(prev => {
      prev.add(item);

      return prev;
    });
  }, []);
  const remove = useCallback((item: string) => {
    setState(prev => {
      const set = new Set(Array.from(prev).filter(i => i !== item));

      return set;
    });
  }, []);
  const clear = useCallback(() => setState(new Set()), []);

  return { addItem: add, clearItems: clear, items: state, removeItem: remove };
};

export default useSetState;
