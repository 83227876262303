import { gql, useMutation, useQuery } from "@apollo/client";
import { DATALAYER } from "@arowana/util";
import {
  Box,
  Button,
  Chip,
  Grid,
  Hidden,
  Link,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import FulfillmentChip from "apps/supplier/src/components/FulfillmentChip";
import moment from "moment";
import { useContext } from "react";
import { Link as RouterLink } from "react-router-dom";

import { notificationVar } from "../../../cache/notificationPolicy";
import DropdownSelect from "../../../components/DropdownSelect";
import FormCard from "../../../components/FormCard";
import Loader from "../../../components/Loader";
import PageHeader from "../../../components/PageHeader";
import Routes from "../../../Constants/Routes";
import supplierUpdateSettings from "../../../queries/supplierUpdateSettings";
import { FlagsmithContext } from "../../context/FlagsmithContext";
import OrderDaysInAdvance from "../components/OrderDaysInAdvance";

const LOCATIONS = gql`
  query DashboardSupplierLocations {
    supplier: currentSupplier {
      id
      defaultLocationId
      orderDaysInAdvance
      locations {
        id
        active
        cutOffHour
        fulfillmentMethod
        sameDayCutoff
        minimumOrderAmountLabel
        name
        leadTimes
      }
    }
  }
`;

const useStyles = makeStyles(theme => ({
  cardTitle: {
    fontSize: "1.05rem",
    fontWeight: 600,
    letterSpacing: theme.typography.h6.letterSpacing,
    lineHeight: theme.typography.h6.lineHeight,
  },
  closedChip: { marginLeft: theme.spacing(1) },
  createZoneButton: { marginTop: theme.spacing(4) },
  noZoneTitle: { marginBottom: theme.spacing(3) },
  noZonesContainer: {
    backgroundColor: theme.palette.grey[100],
    padding: theme.spacing(4),
  },
  orderDaysInAdvance: { marginTop: theme.spacing(2) },
  sidebar: {
    [theme.breakpoints.up("md")]: {
      height: "fit-content",
      position: "sticky",
      top: theme.spacing(15),
    },
    [theme.breakpoints.down("sm")]: {
      order: 1,
    },
    order: 2,
  },
  tableContainer: {
    backgroundColor: theme.palette.background.paper,
    borderColor: theme.palette.divider,
    borderRadius: theme.shape.borderRadius,
    borderStyle: "solid",
    borderWidth: 1,
    marginBottom: theme.spacing(2),
    width: "100%",
  },
  zoneImg: {
    borderRadius: theme.spacing(1),
    display: "block",
    height: "auto",
    marginLeft: theme.spacing(1.5),
    maxHeight: 250,
    maxWidth: 400,
    objectFit: "cover",
    width: "auto",
  },
  zones: {
    order: 1,
    [theme.breakpoints.down("sm")]: {
      order: 2,
    },
  },
}));

const Zones = () => {
  // TODO: remove flagsmith when retail cart is ready
  const flagsmith = useContext(FlagsmithContext);
  const hasOrderDaysInAdvance = flagsmith.hasFeature("supplier-order-days-in-advance");

  const classes = useStyles();
  const [updateSupplier, { loading: isSaving }] = useMutation(supplierUpdateSettings, {
    onCompleted: () => {
      notificationVar({
        message: "Info updated!",
        severity: "success",
      });
    },
  });

  const { data, loading } = useQuery(LOCATIONS, {
    context: { source: DATALAYER },
    fetchPolicy: "cache-and-network",
  });

  const handleDefaultLocationChange = e => {
    const defaultLocationId = e.target.value;
    const variables = {
      input: {
        defaultLocationId,
      },
      supplierId: data?.supplier?.id,
    };
    updateSupplier({ variables });
  };

  const handleChangeOrderDaysInAdvance = orderDaysInAdvance => {
    const variables = {
      input: {
        orderDaysInAdvance,
      },
      supplierId: data?.supplier?.id,
    };
    updateSupplier({ variables });
  };

  let content;

  if (data?.supplier?.locations?.length) {
    content = (
      <>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8} xl={9} className={classes.zones}>
            <TableContainer className={classes.tableContainer}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Fulfillment method</TableCell>
                    <TableCell>Fulfillment days</TableCell>
                    <TableCell>Order cutoff</TableCell>
                    <TableCell align="right">Minimum order</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.supplier.locations.map(location => (
                    <TableRow key={location.id}>
                      <TableCell>
                        <Link component={RouterLink} to={Routes.STORE_LOCATIONS_DETAIL.replace(":id", location.id)}>
                          <Typography variant="body1">
                            {location.name}{" "}
                            {location.active || (
                              <Chip
                                className={classes.closedChip}
                                color="secondary"
                                label="Closed"
                                size="small"
                                variant="outlined"
                              />
                            )}
                          </Typography>
                        </Link>
                      </TableCell>
                      <TableCell>
                        <FulfillmentChip fulfillmentType={location.fulfillmentMethod} />
                      </TableCell>
                      <TableCell>
                        {Object.keys(location.leadTimes)
                          .map(d => moment().isoWeekday(parseInt(d, 10)).format("ddd"))
                          .join(", ")}
                      </TableCell>
                      <TableCell>{moment().hour(location.cutOffHour).minute(0).format("LT")}</TableCell>
                      <TableCell align="right">{location.minimumOrderAmountLabel}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12} md={4} xl={3} className={classes.sidebar}>
            <FormCard>
              <section>
                <Typography variant="h3" className={classes.cardTitle} gutterBottom>
                  Default service location
                </Typography>
                <DropdownSelect
                  disabled={isSaving}
                  dropdownMargin="dense"
                  fullWidth
                  onChange={handleDefaultLocationChange}
                  options={data?.supplier?.locations?.map(({ id, active, name }) => ({
                    label: (
                      <Typography>
                        {name}
                        {!active && (
                          <Chip
                            className={classes.closedChip}
                            color="secondary"
                            label="Closed"
                            size="small"
                            variant="outlined"
                          />
                        )}
                      </Typography>
                    ),
                    value: id,
                  }))}
                  value={data.supplier.defaultLocationId}
                />
                <Typography color="textSecondary" variant="caption">
                  Set the default service location that new customers, or shoppers without saved addresses will browse
                  from.
                </Typography>
              </section>

              {hasOrderDaysInAdvance && (
                <section className={classes.orderDaysInAdvance}>
                  <Typography variant="h3" className={classes.cardTitle} gutterBottom>
                    Order days in advance
                  </Typography>
                  <OrderDaysInAdvance
                    loading={isSaving}
                    onChange={handleChangeOrderDaysInAdvance}
                    value={data?.supplier?.orderDaysInAdvance}
                  />
                  <Typography color="textSecondary" variant="caption">
                    Allow customers to choose fulfillment dates into the future, up to this value during the checkout
                    process.
                  </Typography>
                </section>
              )}
            </FormCard>
          </Grid>
        </Grid>
      </>
    );
  } else if (!loading) {
    content = (
      <Paper className={classes.noZonesContainer}>
        <Box alignItems="center" display="flex" justifyContent="space-between">
          <Box>
            <Typography className={classes.noZoneTitle} gutterBottom variant="h6">
              Get started with zones
            </Typography>
            <Typography>
              Set up your delivery, shipping, and/or pickup zones, along with zone-specific minimum orders, delivery
              fees, and available products.
            </Typography>
            <Button
              className={classes.createZoneButton}
              color="primary"
              component={RouterLink}
              to={Routes.STORE_LOCATIONS_CREATE}
              variant="contained"
            >
              Create Zone
            </Button>
          </Box>
          <Hidden smDown>
            <img alt="zone" className={classes.zoneImg} src="/images/demo_zone.png" />
          </Hidden>
        </Box>
      </Paper>
    );
  }

  if (loading) return <Loader />;

  return (
    <>
      <PageHeader
        primaryActions={
          <Button color="primary" component={RouterLink} to={Routes.STORE_LOCATIONS_CREATE} variant="contained">
            Create Service Location
          </Button>
        }
        stickyHeader
        title="Service locations"
      />
      {content}
    </>
  );
};

export default Zones;
