/* eslint-disable @typescript-eslint/no-explicit-any */
import { CustomQuantityCounter, QuickSearch } from "@arowana/ui";
import {
  Box,
  Chip,
  FormControl,
  IconButton,
  Link,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import { useContext } from "react";
import { Link as RouterLink } from "react-router-dom";

import Routes from "../../../Constants/Routes";
import amountUtility from "../../../utils/amountUtility";
import { AccountContext } from "../../context/AccountContext";

const useStyles = makeStyles(theme => ({
  empty: {
    backgroundColor: theme.palette.grey[200],
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1, 0),
  },
  table: {
    minWidth: 500,
  },
  tableContainer: {
    margin: theme.spacing(3, 0),
    maxHeight: 500,
  },
}));

const LineItemRow = ({ lineItem, onChange, wholesaleListId }) => {
  const { overrideId, product, quantity, totalAmount } = lineItem;
  const { id, variants, name, subName, active, outOfStock } = product ?? {};
  const { price, caseSize, unit } = variants?.[0] ?? {};

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const onQuantityInputChange = q => onChange(overrideId, { quantity: q - quantity });
  const onItemRemove = () => onChange(overrideId, { quantity: -quantity });

  return (
    <TableRow>
      <TableCell component="th" scope="row" style={{ minWidth: 250 }}>
        <Link component={RouterLink} to={Routes.PRODUCT.replace(":id", id)}>
          <Typography>{name}</Typography>
        </Link>
        <Typography variant="caption">{subName}</Typography>
      </TableCell>
      <TableCell style={{ minWidth: 72 }}>
        <Chip
          color={active ? "primary" : "secondary"}
          label={active ? "Active" : "Inactive"}
          style={{ width: "72px" }}
        />
      </TableCell>
      <TableCell style={{ minWidth: 110 }}>{outOfStock ? "Out of Stock" : "In Stock"}</TableCell>
      <TableCell style={{ minWidth: 120 }}>
        <Link
          component={RouterLink}
          to={{
            hash: "#" + overrideId,
            pathname: Routes.WHOLESALE_LIST_DETAILS.replace(":id", wholesaleListId),
          }}
        >
          ${amountUtility.amountToDollarAndCents(parseInt(price, 10))}
        </Link>
      </TableCell>
      <TableCell style={{ minWidth: 200 }}>
        <CustomQuantityCounter
          caseSize={caseSize}
          unit={unit}
          value={quantity}
          onChange={onQuantityInputChange}
          fullWidth
        />
      </TableCell>
      <TableCell style={{ minWidth: 120 }}>
        ${amountUtility.amountToDollarAndCents(parseInt(totalAmount, 10))}
      </TableCell>
      <TableCell align="center">
        <IconButton aria-label="delete" color="primary" onClick={onItemRemove} size={isMobile ? "small" : "medium"}>
          <Delete />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

const WholesaleOrderProductInputList = ({ value, wholesaleList, onProductAdd, onProductChange }) => {
  const classes = useStyles();
  const filteredIds = value?.map(({ overrideId }) => `id != "${overrideId}"`)?.join(" AND ") ?? "";
  const { supplier } = useContext(AccountContext);

  const wholesaleListId = wholesaleList?.id;

  return (
    <>
      <Typography>
        Price list:{" "}
        <Link component={RouterLink} to={Routes.WHOLESALE_LIST_DETAILS.replace(":id", wholesaleListId)}>
          {wholesaleList?.name}
        </Link>
      </Typography>
      {wholesaleListId && (
        <FormControl fullWidth margin="dense">
          <QuickSearch
            fullWidth
            label="Add product from price list"
            index="product_variant_overrides"
            getOptionLabel={({ product_name }) => product_name}
            onChange={({ id }) => onProductAdd(id)}
            renderOption={(option, _state) => (
              <Box>
                <Typography variant="body2">{option.product_name}</Typography>
                <Typography color="textSecondary" variant="caption">
                  {option.variant_name}
                </Typography>
              </Box>
            )}
            noOptionsText="No products found"
            searchParams={{
              filter: [`supplier_id = "${supplier.id}"`, `wholesale_list_id = "${wholesaleListId}"`, filteredIds],
              limit: 10,
            }}
            supplierId={supplier.id}
          />
        </FormControl>
      )}
      <TableContainer className={classes.tableContainer}>
        <Table className={classes.table} stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Product</TableCell>
              <TableCell>Visbility</TableCell>
              <TableCell>Stock status</TableCell>
              <TableCell>Price per unit</TableCell>
              <TableCell>Quantity</TableCell>
              <TableCell>Total</TableCell>
              <TableCell>Remove</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {value.map(lineItem => (
              <LineItemRow
                key={`${lineItem?.overrideId}`}
                lineItem={lineItem}
                wholesaleListId={wholesaleListId}
                onChange={onProductChange}
              />
            ))}
          </TableBody>
        </Table>
        {value.length === 0 && (
          <Box className={classes.empty} textAlign="center">
            <Typography>No products selected</Typography>
          </Box>
        )}
      </TableContainer>
    </>
  );
};

export default WholesaleOrderProductInputList;
