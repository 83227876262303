import {
  Button,
  ButtonProps,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";

type CancelRecurringOrderDialogProps = DialogProps & {
  loading?: boolean;
  onConfirm: ButtonProps["onClick"];
};

export const CancelRecurringOrderDialog = ({
  loading = false,
  onConfirm,
  ...rest
}: CancelRecurringOrderDialogProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const onCancelClick = e => rest?.onClose(e, "escapeKeyDown");

  return (
    <Dialog fullScreen={isMobile} fullWidth maxWidth="sm" keepMounted={false} {...rest}>
      <DialogTitle>Cancel subscription</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Cancelling subscription will stop all future orders. Any remaining unfulfilled orders will be processed unless
          cancelled explicitly.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} variant="outlined" color="primary" onClick={onCancelClick}>
          Keep subscription
        </Button>
        <Button variant="contained" color="primary" disabled={loading} onClick={onConfirm}>
          {loading ? <CircularProgress size={24} /> : "Cancel subscription"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CancelRecurringOrderDialog;
