import { CircularProgress, Fade, InputAdornment } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Search } from "@material-ui/icons";

const useStyles = makeStyles(() => ({
  searchAdornment: {
    position: "relative",
  },
  searchLoading: {
    left: 3,
    position: "absolute",
    top: -9,
  },
}));

export type SearchAdornmentProps = {
  loading?: boolean;
};

export const SearchAdornment = ({ loading = false }: SearchAdornmentProps) => {
  const classes = useStyles();

  return (
    <InputAdornment className={classes.searchAdornment} position="end">
      <Fade in={loading}>
        <CircularProgress className={classes.searchLoading} color="primary" size={13} thickness={7} />
      </Fade>
      <Search />
    </InputAdornment>
  );
};

export default SearchAdornment;
