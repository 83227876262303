import { Box, FormLabel, makeStyles } from "@material-ui/core";
import { CardElement, CardElementProps } from "@stripe/react-stripe-js";
import clsx from "clsx";
import { ReactNode, useContext, useMemo } from "react";

import { SupplierContext } from "../context";

type CreditCardStylesProps = {
  darkModeEnabled?: boolean;
  fontFamily?: string;
};

const getCreditCardStyles = ({ fontFamily, darkModeEnabled = false }: CreditCardStylesProps) => ({
  style: {
    base: {
      "::placeholder": {
        color: "#aab7c4",
      },
      "color": darkModeEnabled ? "white" : "black",
      "fontFamily": fontFamily,
      "fontSize": "16px",
      "fontSmoothing": "antialiased",
    },
    invalid: {
      color: "#B00020",
      iconColor: "#B00020",
    },
  },
});

export type PaymentCardElementProps = {
  className?: string;
  label?: ReactNode;
} & CardElementProps;

const useStyles = makeStyles(theme => ({
  label: {
    marginBottom: theme.spacing(2),
  },
  payment: {
    border: `1px solid #BDBDBD`,
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(2),
    padding: "10.5px",
  },
}));

// Wrapper component for stripe's CardElement
export const PaymentCardElement = ({ label, className, options, ...props }: PaymentCardElementProps) => {
  const classes = useStyles();
  const { uiSettings } = useContext(SupplierContext);
  const cardOptions = useMemo(() => {
    const styles = getCreditCardStyles({
      darkModeEnabled: uiSettings?.darkModeEnabled,
      fontFamily: uiSettings?.fontFamily,
    });

    return {
      ...styles,
      ...options,
    };
  }, [options, uiSettings?.darkModeEnabled, uiSettings?.fontFamily]);

  return (
    <>
      {label && <FormLabel className={classes.label}>{label}</FormLabel>}
      <Box className={clsx(classes.payment, className)}>
        <CardElement options={cardOptions} {...props} />
      </Box>
    </>
  );
};

export default PaymentCardElement;
