import { FEAT_SUPPLIER_NEW_CREATE_RETAIL_ORDER, FEATURE_SUPPLIER_RECURRING_ORDERS } from "@arowana/flags";
import { Supplier } from "@arowana/graphql";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useMemo } from "react";
import { IntlProvider } from "react-intl";
import { Redirect, Route, Switch } from "react-router-dom";

import Routes from "../../Constants/Routes";
import AccountDetails from "../account/pages/AccountDetails";
import Store from "../account/Store";
import Analytics from "../analytics/pages";
import AppDetails from "../apps/pages/AppDetails";
import Apps from "../apps/pages/Apps";
import Unauthorized from "../auth/pages/Unauthorized";
import { AccountContext, AccountProvider } from "../context/AccountContext";
import { FlagsmithContext, FlagsmithProvider } from "../context/FlagsmithContext";
import CustomerCreate from "../customers/pages/CustomerCreate";
import CustomerDetails from "../customers/pages/CustomerDetails";
import Customers from "../customers/pages/CustomerList";
import { NotFoundPage, ServiceUnavailablePage } from "../errorPages";
import GiftcardCreate from "../giftcards/pages/GiftcardCreate";
import GiftcardDetails from "../giftcards/pages/GiftcardDetails";
import GiftCards from "../giftcards/pages/giftcards";
import Help from "../help/Help";
import ExportSheet from "../operations/pages/ExportSheet";
import Operations from "../operations/pages/Operations";
import OrderDetails from "../orders/pages/OrderDetails/OrderDetails";
import OrderList from "../orders/pages/OrderList";
import RetailOrderCreate from "../orders/pages/RetailOrderCreate";
import WholesaleOrderCreate from "../orders/pages/WholesaleOrderCreate";
import Payments from "../payments/Payments";
import BulkImportProducts from "../products/pages/BulkImportProducts";
import CategoryDetails from "../products/pages/CategoryDetails";
import CategoryList from "../products/pages/CategoryList";
import ListDetails from "../products/pages/ListDetails";
import ListList from "../products/pages/ListList";
import ProductDetails from "../products/pages/ProductDetails";
import ProductList from "../products/pages/ProductList";
import PromotionDetails from "../promotions/pages/PromotionDetails";
import Promotions from "../promotions/pages/Promotions";
import RecurringFeatureNotEnabled from "../recurring/pages/RecurringFeatureNotEnabled";
import RecurringListDetails from "../recurring/pages/RecurringListDetails";
import RecurringLists from "../recurring/pages/RecurringLists";
import RecurringOrderCreate from "../recurring/pages/RecurringOrderCreate";
import RecurringOrderDetails from "../recurring/pages/RecurringOrderDetails";
import RecurringOrders from "../recurring/pages/RecurringOrders";
import StaffCreate from "../staff/pages/StaffCreate";
import StaffList from "../staff/pages/StaffList";
import WholesaleClient from "../wholesale/pages/Client";
import WholesaleClientCreate from "../wholesale/pages/ClientCreate";
import WholesaleClients from "../wholesale/pages/Clients";
import WholesaleList from "../wholesale/pages/List";
import WholesaleLists from "../wholesale/pages/Lists";
import DashboardContainer from "./components/DashboardContainer";

const StripeElement = ({ children, supplier }: { children: React.ReactElement; supplier: Supplier }) => {
  const stripePromise = useMemo(
    () =>
      loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY, {
        stripeAccount: supplier?.paymentSettings?.stripeAccountId,
      }),
    [supplier?.paymentSettings?.stripeAccountId],
  );

  return <Elements stripe={stripePromise}>{children}</Elements>;
};

const DashboardRoutes = () => (
  <AccountProvider>
    <AccountContext.Consumer>
      {({ supplier }) => (
        <StripeElement supplier={supplier}>
          <FlagsmithProvider
            environment={process.env.FRESHLINE_ENVIRONMENT}
            flagsmithEnvironmentId={process.env.REACT_APP_FLAGSMITH_ENVIRONMENT_ID}
            supplier={supplier}
          >
            <FlagsmithContext.Consumer>
              {flagsmith => {
                const hasOnboarded = flagsmith.hasFeature("supplier-onboarded");
                const browserLocales = navigator.languages === undefined ? [navigator.language] : navigator.languages;
                let locale = "en";

                if (browserLocales?.length > 0) {
                  locale = browserLocales[0].trim();
                }

                const hasRecurringOrderFeature = flagsmith.hasFeature(FEATURE_SUPPLIER_RECURRING_ORDERS);
                const hasNewCreateRetailOrder = flagsmith.hasFeature(FEAT_SUPPLIER_NEW_CREATE_RETAIL_ORDER);

                const SubscriptionRoute = ({ path, Component }) => (
                  <Route
                    exact
                    path={path}
                    render={props =>
                      hasRecurringOrderFeature ? (
                        <Component {...props} />
                      ) : (
                        <Redirect to={Routes.SUBSCRIPTIONS_FEATURE_NOT_ENABLED} />
                      )
                    }
                  />
                );

                return (
                  <IntlProvider locale={locale} defaultLocale="en">
                    <DashboardContainer>
                      <Switch>
                        <Route component={Unauthorized} exact path={Routes.UNAUTHORIZED} />
                        <Route component={ProductList} exact path={Routes.PRODUCT_LIST} />
                        <Route component={OrderList} exact path={Routes.ORDER_LIST} />
                        <Route component={ExportSheet} exact path={Routes.ORDERS_EXPORT} />
                        {hasNewCreateRetailOrder && (
                          <Route component={RetailOrderCreate} exact path={Routes.ORDER_CREATE} />
                        )}
                        <Route component={WholesaleOrderCreate} exact path={Routes.ORDER_CREATE_WHOLESALE} />
                        <Route component={Operations} path={Routes.OPERATIONS} />
                        <Route component={Apps} exact path={Routes.APPS} />
                        <Route component={AppDetails} path={Routes.APPS_SETTINGS} />
                        <Route component={CustomerCreate} exact path={Routes.CREATE_CUSTOMER} />
                        <Route component={CustomerDetails} path={Routes.CUSTOMER_DETAILS} />
                        <Route component={Customers} path={Routes.CUSTOMERS} />
                        <Route component={GiftcardCreate} exact path={Routes.GIFTCARD_CREATE} />
                        <Route component={GiftcardDetails} exact path={Routes.GIFTCARD_DETAILS} />
                        <Route component={GiftCards} exact path={Routes.GIFTCARDS} />
                        <Route component={Promotions} exact path={Routes.PROMOTIONS} />
                        <Route
                          exact
                          path={Routes.SUBSCRIPTIONS_FEATURE_NOT_ENABLED}
                          render={() =>
                            hasRecurringOrderFeature ? (
                              <Redirect to={Routes.NOT_FOUND} />
                            ) : (
                              <RecurringFeatureNotEnabled />
                            )
                          }
                        />
                        <SubscriptionRoute
                          path={[Routes.SUBSCRIPTIONS_COLLECTIONS_CREATE, Routes.SUBSCRIPTIONS_COLLECTIONS_DETAILS]}
                          Component={RecurringListDetails}
                        />
                        <SubscriptionRoute path={Routes.SUBSCRIPTIONS_COLLECTIONS} Component={RecurringLists} />
                        <SubscriptionRoute path={Routes.SUBSCRIPTIONS_CREATE} Component={RecurringOrderCreate} />
                        <SubscriptionRoute path={Routes.SUBSCRIPTIONS_DETAILS} Component={RecurringOrderDetails} />
                        <SubscriptionRoute path={Routes.SUBSCRIPTIONS} Component={RecurringOrders} />
                        <Route component={WholesaleClients} exact path={Routes.WHOLESALE_CLIENTS} />
                        <Route component={WholesaleClientCreate} exact path={Routes.WHOLESALE_CLIENT_CREATE} />
                        <Route component={WholesaleClient} path={Routes.WHOLESALE_CLIENT_DETAILS} />
                        <Route component={WholesaleLists} exact path={Routes.WHOLESALE_LISTS} />
                        <Route
                          component={WholesaleList}
                          path={[Routes.WHOLESALE_LIST_CREATE, Routes.WHOLESALE_LIST_DETAILS]}
                        />
                        <Route component={AccountDetails} path={Routes.ACCOUNT_DETAILS} />
                        <Route component={Store} path={[Routes.STORE, "/account"]} />
                        <Route component={Analytics} path={Routes.ANALYTICS} />
                        <Route component={Payments} path={Routes.PAYMENTS} />
                        <Route component={Help} path={Routes.HELP} />
                        <Route component={ProductDetails} path={[Routes.PRODUCT_CREATE_NEW, Routes.PRODUCT]} />
                        <Route component={BulkImportProducts} path={Routes.BULK_IMPORT_PRODUCTS} />
                        <Route component={PromotionDetails} path={[Routes.CREATE_DISCOUNT, Routes.DISCOUNT_DETAILS]} />
                        <Route component={OrderDetails} path={Routes.ORDER_DETAILS} />
                        <Route component={CategoryList} exact path={Routes.CATEGORIES} />
                        <Route component={CategoryDetails} path={[Routes.CREATE_CATEGORY, Routes.EDIT_CATEGORY]} />
                        <Route component={ListList} exact path={Routes.LISTS} />
                        <Route component={ListDetails} path={[Routes.CREATE_LIST, Routes.EDIT_LIST]} />
                        <Redirect from={Routes.CREATE_PRODUCT} to={Routes.PRODUCT_CREATE_NEW} />
                        <Route component={StaffList} exact path={Routes.STAFF_LIST} />
                        <Route component={StaffCreate} exact path={[Routes.STAFF_CREATE, Routes.STAFF]} />
                        <Route exact path="/">
                          {/* If flagsmith onboarded redirect to product list, otherwise redirect to help */}
                          {hasOnboarded ? <Redirect to={Routes.PRODUCT_LIST} /> : <Redirect to={Routes.HELP} />}
                        </Route>
                        <Route exact path={Routes.NOT_FOUND} component={NotFoundPage} />
                        <Route exact path={Routes.SERVICE_UNAVAILABLE_PAGE} component={ServiceUnavailablePage} />
                        <Redirect from="*" to={Routes.NOT_FOUND} />
                      </Switch>
                    </DashboardContainer>
                  </IntlProvider>
                );
              }}
            </FlagsmithContext.Consumer>
          </FlagsmithProvider>
        </StripeElement>
      )}
    </AccountContext.Consumer>
  </AccountProvider>
);

export default DashboardRoutes;
