import { gql, useMutation } from "@apollo/client";
import { Supplier } from "@arowana/graphql";
import { DATALAYER } from "@arowana/util";
import { Button, CircularProgress, FormControl, FormLabel, Grid, LinearProgress, makeStyles } from "@material-ui/core";
import { Controller, useForm } from "react-hook-form";

import { notificationVar } from "../../../cache/notificationPolicy";
import DropdownSelect from "../../../components/DropdownSelect";
import FormCard from "../../../components/FormCard";
import CustomerAutocomplete from "../../orders/component/CustomerAutocomplete";

const useStyles = makeStyles(theme => ({
  addAccountButton: {
    [theme.breakpoints.up("md")]: {
      marginBottom: theme.spacing(1.5),
      marginTop: theme.spacing(4),
    },
  },
  spacing: {
    marginTop: theme.spacing(1),
  },
}));

const ADD_TO_STAFF_ACCOUNT = gql`
  mutation AddToStaffAccount($input: AddToStaffAccountInput!) {
    addToStaffAccount(input: $input) {
      id
    }
  }
`;

const AddToStaffAccount = ({ data, loading }: { data: Supplier; loading: boolean }) => {
  const classes = useStyles();

  const filteredIds = new Set(data?.accounts?.map(({ account }) => account.id) ?? []);

  const roleOptions =
    data?.roles?.map(({ id, description, name }) => ({
      label: `${name} (${description})`,
      value: id,
    })) ?? [];

  const { control, errors, handleSubmit, reset, watch } = useForm({
    defaultValues: {
      account: "",
      roleId: "",
    },
  });

  const hasValidInput = watch("account") && watch("roleId");

  const [addToStaffAccount, { loading: addingToAccount }] = useMutation(ADD_TO_STAFF_ACCOUNT, {
    context: { source: DATALAYER },
    onCompleted: () => {
      reset();

      notificationVar({
        message: "Staff account added!",
        severity: "success",
      });
    },
    refetchQueries: ["Staff"],
  });

  const onAddStaffAccount = data => {
    const {
      account: { id: accountId },
      roleId,
    } = data;

    addToStaffAccount({ variables: { input: { accountId, roleId } } });
  };

  return (
    <FormCard title="Add staff account">
      {loading && <LinearProgress />}
      <Grid container spacing={2}>
        <Grid item xs={12} md={5}>
          <FormControl fullWidth margin="dense">
            <FormLabel htmlFor="account">Search account</FormLabel>
            <Controller
              as={
                <CustomerAutocomplete
                  filteredIds={filteredIds}
                  noOptionsText="No accounts match your search parameters, or they have already been added to the staff account"
                />
              }
              control={control}
              name="account"
              rules={{ required: true }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={5}>
          <FormControl fullWidth margin="dense">
            <FormLabel htmlFor="roleId">Role</FormLabel>
            <Controller
              as={<DropdownSelect className={classes.spacing} dropdownMargin="dense" options={roleOptions} />}
              control={control}
              helperText={errors?.roleId?.message}
              name="roleId"
              rules={{ required: "*required" }}
            />
          </FormControl>
        </Grid>
        <Grid className={classes.addAccountButton} item xs={12} md={2}>
          <Button
            color="primary"
            disabled={!hasValidInput || addingToAccount}
            onClick={handleSubmit(onAddStaffAccount)}
            variant="contained"
          >
            {addingToAccount ? <CircularProgress color="inherit" size={24} /> : "Add"}
          </Button>
        </Grid>
      </Grid>
    </FormCard>
  );
};

export default AddToStaffAccount;
