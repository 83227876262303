import { ReactiveVar } from "@apollo/client";
import { Client, OrderAudience, RecurringOrder } from "@arowana/graphql";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { Alert, Skeleton } from "@material-ui/lab";

import { FulfillmentAddressSelect } from "../Address";
import { NotificationVarType } from "../Notification";
import { PaymentMethodSelect } from "../Payment";
import useRecurringOrderState from "./useRecurringOrderState";

type UpdateRecurringOrderDialogProps = DialogProps & {
  accountId?: string;
  client?: Pick<Client, "id" | "address">;
  recurringOrder: RecurringOrder;
  loading?: boolean;
  refetchAccount?: (() => void) | null;
  skipAccountQuery: boolean;
  onSave: (value: { addressId?: string; paymentMethodId: string; pickupLocationId?: string }) => void;
  notificationVar: ReactiveVar<NotificationVarType>;
};

export const UpdateRecurringOrderDialog = ({
  accountId = null,
  client = null,
  loading = false,
  notificationVar,
  onSave,
  recurringOrder,
  refetchAccount = null,
  skipAccountQuery,
  ...rest
}: UpdateRecurringOrderDialogProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));

  const { audience, recurringOrderList, address, pickupLocationId, fulfillmentMethod } = recurringOrder ?? {};
  const isRetail = audience === OrderAudience.MARKETPLACE;
  const isWholesale = audience === OrderAudience.WHOLESALE;

  const { addressInputProps, fetchingAccount, hasValidInput, inputState, paymentInputProps } = useRecurringOrderState({
    accountId,
    fulfillmentMethod,
    initialDeliveryAddressId: address?.id,
    initialPickupLocationId: pickupLocationId,
    recurringOrderList,
    refetchAccount,
    skipAccountQuery,
  });

  const onCancelClick = e => rest?.onClose(e, "escapeKeyDown");
  const onSaveClick = () => {
    const state = { ...inputState };

    if (isWholesale) {
      // since payment method is not supported
      delete state.paymentMethodId;
    }

    onSave(state);
  };

  return (
    <Dialog fullScreen={isMobile} fullWidth maxWidth="sm" keepMounted={false} {...rest}>
      <DialogTitle>Update subscription</DialogTitle>
      <DialogContent>
        <Box marginBottom={2}>
          {fetchingAccount ? (
            <Skeleton variant="rect" height={60} width="100%" />
          ) : (
            <FulfillmentAddressSelect
              {...addressInputProps}
              {...(Boolean(client) &&
                isWholesale && {
                  deliveryAddresses: [client.address],
                  showAddOption: false,
                })}
            />
          )}
        </Box>
        {isRetail && (
          <Box marginBottom={2}>
            {fetchingAccount ? (
              <Skeleton variant="rect" height={60} width="100%" />
            ) : (
              <PaymentMethodSelect {...paymentInputProps} notificationVar={notificationVar} />
            )}
          </Box>
        )}
        <Box marginBottom={1}>
          <Alert severity="info">Changes are only applied to future orders</Alert>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button disabled={loading || fetchingAccount} variant="outlined" color="primary" onClick={onCancelClick}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={loading || fetchingAccount || !hasValidInput}
          onClick={onSaveClick}
        >
          {loading ? <CircularProgress size={24} /> : "Save"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateRecurringOrderDialog;
