import { QuickSearch } from "@arowana/ui";
import { Chip, FormHelperText, makeStyles, Typography } from "@material-ui/core";
import React, { useContext } from "react";

import { AccountContext } from "../../context/AccountContext";
import { ListSearch } from "./ListAutocomplete";

const useStyles = makeStyles(theme => ({
  selectedList: {
    margin: theme.spacing(0.5),
  },
  selectedLists: {
    margin: theme.spacing(0, -0.5),
    padding: 0,
  },
}));

type MultiSelectListProps = {
  helperText?: string;
  onChange?: (prop: ListSearch[]) => void;
  value?: ListSearch[];
};

// assign collections to a product
const MultiSelectList = React.forwardRef(({ helperText, onChange, value }: MultiSelectListProps) => {
  const classes = useStyles();
  const { supplier } = useContext(AccountContext);

  const filterIds = value?.map(existingList => `id != "${existingList.id}"`)?.join(" AND ") ?? "";

  const handleDeleteList = (listId: string) => {
    const index = value.findIndex(list => list.id === listId);
    onChange([...value.slice(0, index), ...value.slice(index + 1)]);
  };
  const handleAddList = (list: ListSearch) => {
    onChange([...value, list]);
  };

  return (
    <>
      <QuickSearch
        getOptionLabel={(option: ListSearch) => option.name}
        index="lists"
        label=""
        margin="dense"
        noOptionsText="No collections match your search parameters, or they have already been added to this list"
        onChange={(list: ListSearch) => handleAddList(list)}
        placeholder="Search collections"
        renderOption={(option: ListSearch) => <Typography>{option.name}</Typography>}
        searchParams={{
          filter: [`supplier_id = "${supplier.id}"`, filterIds],
          limit: 10,
        }}
        supplierId={supplier.id}
      />

      {helperText && <FormHelperText>{helperText}</FormHelperText>}

      <ul className={classes.selectedLists}>
        {value.map(list => (
          <Chip
            key={list.id}
            component="li"
            className={classes.selectedList}
            color="primary"
            label={list.name}
            onDelete={() => handleDeleteList(list.id)}
          />
        ))}
      </ul>
    </>
  );
});

export default MultiSelectList;
