import { CircularProgress, LinearProgress, makeStyles, Typography } from "@material-ui/core";
import { DataGrid, GridOverlay } from "@material-ui/data-grid";
import clsx from "clsx";
import PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
  table: {
    "& .MuiDataGrid-cell": {
      overflowX: "clip",
    },
    "& .MuiDataGrid-colCellWrapper": {
      background: theme.palette.grey[50],
    },
    "& .MuiDataGrid-columnHeader:focus": {
      outline: "none",
    },
    "& .MuiDataGrid-columnHeader--sortable": {
      color: theme.palette.primary.main,
    },
    "& .MuiDataGrid-columnHeader--sortable:hover": {
      background: theme.palette.grey[200],
    },
    "& .MuiDataGrid-columnSeparator": {
      display: "none",
    },
    "& .MuiDataGrid-columnsContainer, & .MuiDataGrid-colCellWrapper": {
      borderTopLeftRadius: theme.shape.borderRadius,
      borderTopRightRadius: theme.shape.borderRadius,
    },
    "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus": {
      outline: "none",
    },
  },
  tableContainer: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(2),
    width: "100%",
  },
}));

export const LoadingOverlay = () => (
  <GridOverlay>
    <div style={{ position: "absolute", top: 0, width: "100%" }}>
      <LinearProgress color="primary" />
    </div>
    <div style={{ bottom: -44, left: 16, position: "absolute", width: "100%" }}>
      <CircularProgress color="primary" size={24} />
    </div>
  </GridOverlay>
);

export const NoRowsOverlay = ({ message, hasRows }) =>
  !hasRows && (
    <GridOverlay>
      <Typography variant="h6">{message}</Typography>
    </GridOverlay>
  );

const PaginatedTable = ({
  columns,
  checkboxSelection,
  className = "",
  defaultContent,
  loading,
  onPageChange,
  onSortModelChange,
  page,
  paginationMode,
  pageSize,
  rows,
  selectionModel,
  sortModel,
  sortingMode,
  totalCount,
  onSelectionModelChange,
  ...rest
}) => {
  const classes = useStyles();
  const hasRows = rows?.length > 0;

  return (
    <div className={clsx(classes.tableContainer, className)}>
      <DataGrid
        autoHeight
        checkboxSelection={checkboxSelection}
        className={classes.table}
        columns={columns}
        components={{
          LoadingOverlay,
          NoRowsOverlay,
        }}
        componentsProps={{ noRowsOverlay: { hasRows, message: defaultContent } }}
        disableColumnFilter
        disableColumnMenu
        disableColumnSelector
        disableSelectionOnClick
        hideFooterPagination={totalCount === 0}
        loading={loading}
        onPageChange={onPageChange}
        onSelectionModelChange={onSelectionModelChange}
        onSortModelChange={onSortModelChange}
        page={page}
        pageSize={pageSize}
        pagination
        paginationMode={paginationMode}
        rowCount={totalCount ?? rows.length}
        rows={rows}
        rowsPerPageOptions={[]}
        selectionModel={selectionModel}
        sortModel={sortModel}
        sortingMode={sortingMode}
        sortingOrder={["desc", "asc"]}
        {...rest}
      />
    </div>
  );
};

PaginatedTable.defaultProps = {
  checkboxSelection: false,
  className: "",
  defaultContent: "No data",
  loading: false,
  onPageChange: () => {},
  onSelectionModelChange: () => {},
  onSortModelChange: () => {},
  page: 1,
  pageSize: 100,
  paginationMode: "server",
  selectionModel: [],
  sortModel: [],
  sortingMode: "server",
  totalCount: undefined,
};

PaginatedTable.propTypes = {
  checkboxSelection: PropTypes.bool,
  className: PropTypes.string,
  columns: PropTypes.array.isRequired,
  defaultContent: PropTypes.string,
  loading: PropTypes.bool,
  onPageChange: PropTypes.func,
  onSelectionModelChange: PropTypes.func,
  onSortModelChange: PropTypes.func,
  page: PropTypes.number,
  pageSize: PropTypes.number,
  paginationMode: PropTypes.oneOf(["server", "client"]),
  rows: PropTypes.array.isRequired,
  selectionModel: PropTypes.array,
  sortModel: PropTypes.array,
  sortingMode: PropTypes.oneOf(["server", "client"]),
  totalCount: PropTypes.number,
};

export default PaginatedTable;
