import { gql, useQuery } from "@apollo/client";
import { Account, Supplier } from "@arowana/graphql";
import { SupplierContext } from "@arowana/ui";
import { DATALAYER } from "@arowana/util";
import { createContext } from "react";

import { isLoggedInVar } from "../../cache/authPolicy";
import logout from "../auth/logout";

export const CurrentAccount = gql`
  query CurrentAccount {
    currentAccount {
      id
      name
      email
    }
    currentSupplier {
      accounts {
        account {
          id
          email
          name
        }
      }
      currency
      id
      identifier
      locations {
        id
        active
        cutOffHour
        deliveryFee
        description
        discountedDeliveryFee
        discountedDeliveryFeeThreshold
        geometry {
          coordinates
        }
        leadTimes
        name
        minimumOrderAmount
        tipsEnabled
        fulfillmentMethod
        fulfillmentTimes {
          id
          label
        }
        address {
          id
          formattedAddress
        }
      }
      name
      paymentSettings {
        # DO NOT REMOVE THIS, required for Stripe Elements
        stripeAccountId
      }
      shippingEnabled # DO NOT REMOVE THIS
      supportEmail
      supportPhoneNumber
      timezone
      url
      urlName
    }
  }
`;

interface AccountContextProps {
  account?: Account;
  supplier?: Supplier;
}

export const AccountContext = createContext<AccountContextProps>({});

export const AccountProvider = ({ children }) => {
  const { data } = useQuery<{
    currentSupplier?: Supplier;
    currentAccount?: Account;
  }>(CurrentAccount, {
    context: { source: DATALAYER },
    onCompleted: ({ currentAccount, currentSupplier }) => {
      localStorage.setItem("supplier_account", JSON.stringify(currentAccount));
      localStorage.setItem("supplier_info", JSON.stringify(currentSupplier));
    },
    onError: ({ graphQLErrors }) => {
      if (graphQLErrors?.[0]?.extensions?.response?.status === 403) {
        logout();
      }
    },
    skip: !isLoggedInVar(),
  });

  return (
    <AccountContext.Provider
      value={{
        account: data?.currentAccount,
        supplier: data?.currentSupplier,
      }}
    >
      <SupplierContext.Provider value={data?.currentSupplier}>{Boolean(data) && children}</SupplierContext.Provider>
    </AccountContext.Provider>
  );
};
