import { IconButtonProps } from "@material-ui/core";
import { useCallback, useState } from "react";
import { UseFormMethods } from "react-hook-form";

import { PasswordToggle } from "../Form";

export const usePasswordToggle = (
  watch: UseFormMethods["watch"],
  fieldName = "password",
  buttonProps?: IconButtonProps,
) => {
  const toggleVisible = Boolean(watch(fieldName, ""));
  const [showPassword, setShowPassword] = useState(false);
  const onToggle = useCallback(() => setShowPassword(!showPassword), [showPassword]);

  return {
    PasswordToggle: (
      <PasswordToggle
        visible={toggleVisible}
        showPassword={showPassword}
        onToggle={onToggle}
        buttonProps={buttonProps}
      />
    ),
    passwordInputType: showPassword ? "text" : "password",
  };
};

export default usePasswordToggle;
