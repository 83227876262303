import { gql, useMutation, useQuery } from "@apollo/client";
import { FEATURE_SUPPLIER_RECURRING_ORDERS_FOR_B2B, FEATURE_SUPPLIER_STRIPE_INVOICES } from "@arowana/flags";
import { Client, OrderAudience, RecurringOrder, RecurringOrderCreateInput, RecurringOrderList } from "@arowana/graphql";
import {
  CalculationLine,
  Currency,
  FulfillmentAddressSelect,
  PaymentMethodSelect,
  ProductQuantityCounter,
  SupplierContext,
  useRecurringOrderState,
} from "@arowana/ui";
import { DATALAYER, unitLabel } from "@arowana/util";
import {
  Box,
  Button,
  Collapse,
  Fade,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Link,
  makeStyles,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Add, Check, Remove } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import qs from "qs";
import { useContext, useMemo, useState } from "react";
import { Controller, FormProvider, useFieldArray, useForm, useFormContext } from "react-hook-form";
import { Link as RouterLink, Prompt } from "react-router-dom";

import { notificationVar } from "../../../cache/notificationPolicy";
import FormCard from "../../../components/FormCard";
import Loader from "../../../components/Loader";
import PageHeader from "../../../components/PageHeader";
import Routes from "../../../Constants/Routes";
import { FlagsmithContext } from "../../context/FlagsmithContext";
import CustomerAutocomplete from "../../orders/component/CustomerAutocomplete";
import ClientAutocomplete from "../../wholesale/components/ClientAutocomplete";
import RecurringOrderListSearch, { RecurringOrderListSearchResult } from "../components/RecurringOrderListSearch";

const ACCOUNT = gql`
  query AccountForRO($id: ID!) {
    account(id: $id) {
      id
      name
      email
      phone
    }
  }
`;

const CLIENT = gql`
  query ClientForRO($id: ID!) {
    client(id: $id) {
      id
      name
      phone
      owner {
        id
        email
      }
      netTerms
      address {
        id
        label
        formattedAddress
        notes
        lngLat {
          coordinates
        }
      }
    }
  }
`;

const CREATE_REUCRRING_ORDER = gql`
  mutation CreateRecurringOrder($input: RecurringOrderCreateInput!) {
    recurringOrderCreate(input: $input) {
      id
    }
  }
`;

const RECURRING_ORDER_LIST = gql`
  query RecurringOrdeList($id: ID!) {
    recurringOrderList(id: $id) {
      id
      name
      description
      descriptionText
      cadence
      fulfillmentDays
      fulfillmentMethods
      fulfillmentMethodsLabel
      slug
      products {
        overrideId
        product {
          id
          name
          active
          category {
            id
            name
            tags {
              label
              value
            }
          }
          subname
          description
          images {
            x256
            x512
            x896
            x1664
          }
          variants {
            name
            caseSize
            price
            unit
          }
          displayUnit
        }
      }
    }
  }
`;

const useStyles = makeStyles(theme => ({
  audienceInput: {
    marginBottom: theme.spacing(2),
  },
  listMeta: {
    marginBottom: theme.spacing(1),
  },
  name: {
    [theme.breakpoints.only("xs")]: {
      fontSize: "0.8rem",
    },
  },
  product: {
    [theme.breakpoints.only("xs")]: {
      flexDirection: "column",
    },
  },
  subName: {
    [theme.breakpoints.only("xs")]: {
      fontSize: "0.75rem",
    },
  },
}));

const ProductRow = ({ overrideId, index, product }) => {
  const classes = useStyles();
  const { register, control } = useFormContext();

  const images = product.images?.[0];
  const srcSet = useMemo(
    () => `${encodeURI(images?.x256 ?? IMG_NOT_FOUND)} 1x, ${encodeURI(images?.x512 ?? IMG_NOT_FOUND)} 2x,`,
    [images],
  );
  const variant = product.variants[0] ?? {};
  const { name: subname, caseSize, unit, price } = variant;

  return (
    <TableRow>
      <TableCell>
        <Box className={classes.product} display="flex">
          <Box display="flex" flexDirection="column">
            <Link to={`${Routes.PRODUCT.replace(":id", product?.id)}`} component={RouterLink}>
              <Typography className={classes.name} variant="body1">
                {product.name}
              </Typography>
              <Typography className={classes.subName} gutterBottom color="textSecondary" variant="body2">
                {subname}
              </Typography>
            </Link>
            <Typography variant="body2">{unitLabel(unit, caseSize)}</Typography>
          </Box>
        </Box>
      </TableCell>
      <TableCell align="center">{product.active && <Check color="primary" />}</TableCell>
      <TableCell>
        <Currency cents={price} unit={unit} />
      </TableCell>
      <TableCell>
        <input name={`lineItems[${index}].overrideId`} type="hidden" defaultValue={overrideId} ref={register()} />
        <Controller
          defaultValue={0}
          control={control}
          name={`lineItems[${index}].quantity`}
          render={({ onChange, value }) => (
            <ProductQuantityCounter onChange={onChange} value={value} caseSize={caseSize} minValue={0} unit={unit} />
          )}
        />
      </TableCell>
    </TableRow>
  );
};

const RecurringOrderCreate = ({ history }) => {
  const classes = useStyles();
  const params = qs.parse(history.location.search, { ignoreQueryPrefix: true });

  const flagsmith = useContext(FlagsmithContext);
  const hasWholesale = flagsmith.hasFeature("b2b");
  const hasWholesaleRO = flagsmith.hasFeature(FEATURE_SUPPLIER_RECURRING_ORDERS_FOR_B2B);
  const hasInvoices = flagsmith.hasFeature(FEATURE_SUPPLIER_STRIPE_INVOICES);

  const { wholesalePaymentSettings } = useContext(SupplierContext);
  const wholesaleCCEnabled = wholesalePaymentSettings?.creditCardEnabled;

  // Lookup account
  const clientId = params?.clientId as string;
  const accountId = params?.customerId as string;
  const customerSelected = Boolean(accountId || clientId);

  const { accountURL, clientURL, defaultAudience } = useMemo(
    () => ({
      accountURL: Routes.CUSTOMER_DETAILS.replace(":id", accountId),
      clientURL: Routes.WHOLESALE_CLIENT_DETAILS.replace(":id", clientId),
      defaultAudience: clientId ? OrderAudience.WHOLESALE : OrderAudience.MARKETPLACE,
    }),
    [accountId, clientId],
  );
  const [audience, setAudience] = useState(defaultAudience);
  const onAudienceChange = event => setAudience(event.target.value);
  const isRetail = audience === OrderAudience.MARKETPLACE;
  const isWholesale = audience === OrderAudience.WHOLESALE;

  const onAccountChange = ({ id }) =>
    history.replace({
      search: qs.stringify({
        ...params,
        customerId: id,
      }),
    });
  const onClientChange = ({ id }) =>
    history.replace({
      search: qs.stringify({
        ...params,
        clientId: id,
      }),
    });
  const onCustomerRemove = () =>
    history.replace({
      search: null,
    });

  const { data: accountData, loading: loadingAccount } = useQuery<
    { account: Pick<Account, "id" | "name" | "email" | "phone"> },
    { id: string }
  >(ACCOUNT, {
    context: { source: DATALAYER },
    fetchPolicy: "cache-and-network",
    skip: !accountId,
    variables: {
      id: accountId,
    },
  });
  const account = accountData?.account;

  const { data: clientData, loading: loadingClient } = useQuery<
    { client: Pick<Client, "id" | "name" | "phone"> },
    { id: string }
  >(CLIENT, {
    context: { source: DATALAYER },
    fetchPolicy: "cache-and-network",
    skip: !clientId,
    variables: {
      id: clientId,
    },
  });
  const client = clientData?.client;

  // RecurringOrdeList
  const roListId = params?.collectionId as string;
  const onROListChange = (hit: RecurringOrderListSearchResult) =>
    history.replace({
      search: qs.stringify({
        ...params,
        collectionId: hit?.id,
      }),
    });
  const onROListRemove = () =>
    history.replace({
      search: qs.stringify({
        ...params,
        collectionId: null,
      }),
    });

  const roListURL = Routes.SUBSCRIPTIONS_COLLECTIONS.replace(":id", roListId);

  const { data: listData, loading: loadingList } = useQuery<{ recurringOrderList: RecurringOrder }, { id: string }>(
    RECURRING_ORDER_LIST,
    {
      context: { source: DATALAYER },
      fetchPolicy: "no-cache",
      onCompleted: () => onClearAll(),
      skip: !roListId,
      variables: {
        id: roListId,
      },
    },
  );
  const list = (listData?.recurringOrderList ?? {}) as Partial<RecurringOrderList>;
  const hasList = roListId && Boolean(list);
  const { id, name, cadence, products = [] } = list;

  // Form input
  const zeroedLineItems = useMemo(() => products.map(({ overrideId }) => ({ overrideId, quantity: 0 })), [products]);
  const methods = useForm({
    defaultValues: {
      lineItems: zeroedLineItems,
    },
  });
  useFieldArray({
    control: methods.control,
    name: "lineItems",
  });
  const onClearAll = () => methods.setValue("lineItems", zeroedLineItems);
  const formLineItems = methods.watch("lineItems", []);

  const lineItems = formLineItems.filter(li => li.quantity > 0);
  const hasItems = lineItems.length > 0;
  const hasInactiveItems = useMemo(() => {
    if (lineItems.length > 0) {
      const selectedOverrideId = new Set(lineItems.map(({ overrideId }) => overrideId));

      return products.some(({ overrideId, product }) => !product.active && selectedOverrideId.has(overrideId));
    }

    return false;
  }, [lineItems]);

  const onIncrementAll = () =>
    methods.setValue(
      "lineItems",
      formLineItems.map(li => ({
        ...li,
        quantity: li.quantity + 1,
      })),
    );
  const onDecrementAll = () =>
    methods.setValue(
      "lineItems",
      formLineItems.map(li => ({
        ...li,
        quantity: Math.max(li.quantity - 1, 0),
      })),
    );

  // Retail Account related inputs
  const { addressInputProps, fetchingAccount, hasValidInput, inputState, paymentInputProps } = useRecurringOrderState({
    accountId,
    recurringOrderList: list,
    skipAccountQuery: false,
  });

  // Subtotal
  const lineItemMap = useMemo(() => {
    const map = new Map();
    formLineItems.forEach(li => map.set(li.overrideId, li.quantity));

    return map;
  }, [lineItems]);

  const subTotal = useMemo(() => {
    let sub = 0;
    products?.forEach(({ overrideId, product }) => {
      const variant = product.variants[0];
      const { caseSize, price } = variant ?? {};

      const quantity = lineItemMap?.get(overrideId) ?? 0;
      const quantityOrdered = quantity * caseSize;
      const lineItemTotal = Math.round(price * quantityOrdered);
      sub += lineItemTotal ?? 0;
    });

    return sub;
  }, [lineItemMap, products]);

  // Subscribe
  const canSubscribe = hasItems && hasValidInput;

  const [createRecurringOrder, { loading: subscribing, data: createData }] = useMutation<
    { recurringOrderCreate: Pick<RecurringOrder, "id"> },
    { input: RecurringOrderCreateInput }
  >(CREATE_REUCRRING_ORDER, {
    context: { source: DATALAYER },
    onCompleted: data => {
      history.replace(Routes.SUBSCRIPTIONS_DETAILS.replace(":id", data.recurringOrderCreate.id));
    },
    refetchQueries: ["RecurringOrders"],
  });

  const onSubscribe = () => {
    const { addressId, paymentMethodId, pickupLocationId } = inputState;
    const input = isRetail
      ? {
          accountId,
          addressId,
          paymentMethodId,
          pickupLocationId,
        }
      : {
          addressId,
          clientId,
          pickupLocationId,
        };

    createRecurringOrder({
      variables: {
        input: {
          ...input,
          lineItems,
          listId: list.id,
        },
      },
    });
  };

  const customerInput = (
    <>
      <Collapse in={isRetail ? !accountId : !clientId}>
        <Typography gutterBottom>Please select a {isRetail ? "customer" : "businesss"}</Typography>
        {isRetail ? (
          <CustomerAutocomplete onChange={onAccountChange} />
        ) : (
          <ClientAutocomplete disableInactive onChange={onClientChange} />
        )}
      </Collapse>
      <Collapse in={isRetail ? Boolean(account) : Boolean(client)}>
        <Typography variant="body1">
          <Link component={RouterLink} to={isRetail ? accountURL : clientURL}>
            {isRetail ? account?.name : client?.name}
          </Link>
          {" • "}
          {isRetail ? account?.email : client?.owner?.email}
        </Typography>
        <Typography variant="body1">{isRetail ? account?.phone : client?.phone}</Typography>
      </Collapse>
    </>
  );

  const orderInput = customerSelected && (
    <>
      <FormCard
        title={
          hasList ? (
            <>
              Subscribing to{" "}
              <Link component={RouterLink} to={roListURL}>
                {list.name}
              </Link>
            </>
          ) : (
            "Subscription collection"
          )
        }
        action={
          <Fade in={hasList}>
            <Button size="small" variant="outlined" color="secondary" onClick={onROListRemove}>
              Remove
            </Button>
          </Fade>
        }
      >
        <Collapse in={!hasList}>
          <Typography gutterBottom>Please select a subscription collection</Typography>
          <RecurringOrderListSearch audience={audience} onChange={onROListChange} />
        </Collapse>
        <Collapse in={hasList}>
          <Grid className={classes.listMeta} container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="subtitle1">Cadence</Typography>
              <Typography variant="body2">{cadence}</Typography>
            </Grid>
          </Grid>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Product</TableCell>
                <TableCell width="200px">Display on store</TableCell>
                <TableCell width="10%">Price</TableCell>
                <TableCell width="240px" align="center">
                  <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Tooltip title="Decrement all">
                      <span>
                        <IconButton disabled={!hasItems} size="small" color="primary" onClick={onDecrementAll}>
                          <Remove />
                        </IconButton>
                      </span>
                    </Tooltip>
                    Quantity
                    <Tooltip title="Increment all">
                      <span>
                        <IconButton size="small" color="primary" onClick={onIncrementAll}>
                          <Add />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </Box>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {products?.map(({ overrideId, product }, index) => (
                <ProductRow key={overrideId} index={index} overrideId={overrideId} product={product} />
              ))}
            </TableBody>
          </Table>
          <Collapse in={hasInactiveItems}>
            <Box mt={2}>
              <Alert severity="warning">Some added item(s) are inactive, please proceed with caution</Alert>
            </Box>
          </Collapse>
        </Collapse>
      </FormCard>
    </>
  );

  const fulfillmentInput = (
    <FormCard title="Fulfillment">
      <FulfillmentAddressSelect
        {...addressInputProps}
        // Override inputs for wholesale
        {...(isWholesale && {
          deliveryAddresses: [client?.address],
          showAddOption: false,
        })}
      />
    </FormCard>
  );

  const paymentInput = isRetail ? (
    <FormCard title="Payment">
      <PaymentMethodSelect label={null} {...paymentInputProps} notificationVar={notificationVar} />
    </FormCard>
  ) : hasInvoices ? (
    <FormCard title="Payment">Invoice will be generated as during order fulfillment</FormCard>
  ) : wholesaleCCEnabled ? (
    <FormCard title="Payment">Credit card is not supported at the moment</FormCard>
  ) : null;

  return (
    <>
      <Loader loading={loadingAccount || loadingList || subscribing} />
      <Prompt
        message="The subscription is incomplete; are you sure you want to leave?"
        when={hasList ? hasItems && !createData : false}
      />
      <FormProvider {...methods}>
        <PageHeader
          primaryActions={
            <Button color="primary" disabled={!canSubscribe} onClick={null} variant="contained" onClick={onSubscribe}>
              Subscribe
            </Button>
          }
          stickyHeader
          title="Create subscription"
        />
        <Grid container spacing={2} style={{ position: "relative" }}>
          <Grid item md={9} xs={12}>
            <FormCard
              title={clientId ? "Business" : "Customer"}
              action={
                <Fade in={customerSelected}>
                  <Button size="small" variant="outlined" color="secondary" onClick={onCustomerRemove}>
                    Remove
                  </Button>
                </Fade>
              }
            >
              {hasWholesale && hasWholesaleRO && (
                <Collapse in={!customerSelected}>
                  <FormControl className={classes.audienceInput} fullWidth variant="outlined">
                    <InputLabel>Audeince</InputLabel>
                    <Select
                      label="Audience"
                      defaultValue={defaultAudience}
                      onChange={onAudienceChange}
                      fullWidth
                      variant="outlined"
                    >
                      <MenuItem value={OrderAudience.MARKETPLACE}>Retail</MenuItem>
                      <MenuItem value={OrderAudience.WHOLESALE}>Wholesale</MenuItem>
                    </Select>
                  </FormControl>
                </Collapse>
              )}
              {customerInput}
            </FormCard>
            {orderInput}
            {hasList && fulfillmentInput}
            {hasList && paymentInput}
          </Grid>
          <Grid item md={3} xs={12} style={{ position: "sticky" }}>
            <FormCard title="Summary">
              <CalculationLine label="Subtotal" cents={subTotal} />
            </FormCard>
          </Grid>
        </Grid>
      </FormProvider>
    </>
  );
};

export default RecurringOrderCreate;
