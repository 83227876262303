import React from "react";

import FedEx from "./FedEx";
import Purolator from "./Purolator";
import UPS from "./UPS";
const CarrierForm = ({ carrier, clearErrors, control, errors, register, setValue, watch }) => {
  switch (carrier) {
    case "fedex":
      return (
        <FedEx clearErrors={clearErrors} control={control} errors={errors} register={register} setValue={setValue} />
      );

    case "purolator":
      return <Purolator errors={errors} register={register} />;

    case "ups":
      return (
        <UPS
          clearErrors={clearErrors}
          control={control}
          errors={errors}
          register={register}
          setValue={setValue}
          watch={watch}
        />
      );

    default:
      <FedEx clearErrors={clearErrors} control={control} errors={errors} register={register} setValue={setValue} />;
  }
};

export default CarrierForm;
