import { Box, Button, FormHelperText, Link, makeStyles, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { useRef } from "react";
import { DragDropContext } from "react-beautiful-dnd";

import ImageGallery from "../ImageGallery";

const useStyles = makeStyles(theme => ({
  helperText: {
    marginTop: theme.spacing(1),
    width: "100%",
  },
  imageButton: {
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
    "backgroundColor": theme.palette.primary.main,
    "color": theme.palette.background.default,
    "display": "block",
    "margin-right": theme.spacing(0.5),
  },
  imageContainer: {
    border: `1px solid ${theme.palette.grey[400]}`,
    borderRadius: theme.shape.borderRadius,
    margin: theme.spacing(2, 0),
    padding: theme.spacing(1, 1, 0, 0),
  },
  root: { marginTop: theme.spacing(1) },
}));

const ProductImages = React.forwardRef(({ value, onChange, helperText }, ref) => {
  const classes = useStyles();
  const hiddenFileInput = useRef(null);
  const imagesArr = value?.images ?? [];
  const selectedFiles = value?.files ?? [];
  const handleOpenSelectFileDialog = () => hiddenFileInput.current.click();
  const handleSelectFile = event => {
    const currentImages = [...imagesArr];
    const currentImageFiles = [...selectedFiles];
    // eslint-disable-next-line no-unsafe-optional-chaining
    const files = [...event?.target?.files];

    if (files?.length > 0) {
      files.forEach(file => {
        const imageObj = {
          data: file,
          original: URL.createObjectURL(file),
        };
        currentImageFiles.push(imageObj);
        currentImages.push({ original: imageObj.original });
      });

      if (onChange) {
        onChange({ files: currentImageFiles, images: currentImages });
      }
    }
  };
  const handleDragEnd = result => {
    const { destination, source, reason } = result;

    if (
      !destination ||
      !source ||
      reason === "CANCEL" ||
      (destination.droppableId === source.droppableId && destination.index === source.index)
    ) {
      return;
    }

    const currentImages = [...imagesArr];
    const droppedImage = currentImages[source.index];
    currentImages.splice(source.index, 1);
    currentImages.splice(destination.index, 0, droppedImage);

    if (onChange) {
      onChange({ files: selectedFiles, images: currentImages });
    }
  };
  const handleDeleteImage = original => {
    const imgIdx = imagesArr.findIndex(i => i.original === original);
    const currentImages = [...imagesArr];

    if (imgIdx > -1) {
      currentImages.splice(imgIdx, 1);
    }

    const fileIdx = selectedFiles.findIndex(i => i.original === original);
    const currentImageFiles = [...selectedFiles];

    if (fileIdx > -1) {
      currentImageFiles.splice(fileIdx, 1);
    }

    if (onChange) {
      onChange({ files: currentImageFiles, images: currentImages });
    }
  };

  return (
    <div className={classes.root}>
      <Box display="flex" justifyContent="flex-start">
        <Button className={classes.imageButton} onClick={handleOpenSelectFileDialog} size="small" variant="contained">
          Add Image
        </Button>
        <Button
          href="https://unsplash.com?utm_source=freshline.io&utm_medium=dashboard&utm_campaign=referral"
          target="_blank"
          rel="noopener noreferrer"
        >
          Download Royalty-Free Images
        </Button>
      </Box>

      <input
        accept="image/*"
        aria-describedby="product-images-helper-text"
        id="product-images"
        multiple
        onChange={handleSelectFile}
        ref={hiddenFileInput}
        style={{ display: "none" }}
        type="file"
      />
      {helperText && (
        <FormHelperText className={classes.helperText} error id="product-images-helper-text">
          {helperText}
        </FormHelperText>
      )}
      <DragDropContext onDragEnd={result => handleDragEnd(result)}>
        <div className={classes.imageContainer} innerRef={ref}>
          <ImageGallery deleteImage={handleDeleteImage} droppableId="list" images={imagesArr} />
        </div>
      </DragDropContext>
    </div>
  );
});

ProductImages.propTypes = {
  helperText: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.object,
};

ProductImages.defaultProps = {
  helperText: undefined,
  onChange: undefined,
  value: undefined,
};

export default ProductImages;
