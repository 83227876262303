import { FormControl, FormLabel, Grid, Link, makeStyles, TextField, Typography } from "@material-ui/core";
import React from "react";

import FormTooltip from "../../../components/FormTooltip";
import AppForm from "../components/AppForm";

const defaultValues = {
  hjid: "",
};
const useStyles = makeStyles(theme => ({
  instructionSection: {
    backgroundColor: theme.palette.grey[50],
    borderRadius: theme.shape.borderRadius,
    marginTop: theme.spacing(6),
    padding: theme.spacing(2),
  },
}));

const Hotjar = () => {
  const classes = useStyles();
  const isInstalled = fields => Boolean(fields?.hjid);

  return (
    <AppForm appId="hotjar" defaultValues={defaultValues} header="Hotjar" isInstalled={isInstalled}>
      {({ formMethods: { errors, register }, installed }) => (
        <FormControl component="fieldset" fullWidth margin="normal">
          <FormLabel component="legend" htmlFor="hjid">
            Hotjar ID{" "}
            <FormTooltip content="Enhance your website and customer service with a live chat service for your customers." />
          </FormLabel>
          <TextField
            disabled={installed}
            error={Boolean(errors?.hjid)}
            helperText={errors?.hjid?.message}
            id="hjid"
            inputProps={{
              maxLength: 30,
            }}
            inputRef={register({
              required: "*required",
            })}
            margin="dense"
            multiline
            name="hjid"
            variant="outlined"
          />
          <Typography color="textSecondary" variant="caption">
            i.e. 9656301
          </Typography>
          <Grid className={classes.instructionSection} xs={12}>
            <Typography gutterBottom variant="subtitle2">
              Installation Guide
            </Typography>
            <Typography color="textSecondary" variant="body2">
              <ol>
                <li>
                  <Link href="https://insights.hotjar.com/site/list" target="_blank">
                    Navigate to your Sites list on Hotjar
                  </Link>
                </li>
                <li>Click on the Tracking Code button for the Site you want to track.</li>
                <li>
                  Click on <b>Copy to Clipboard</b>.
                </li>
                <li>
                  Paste the code onto notepad or word document. Look for a key called <b>hjid</b> and copy the numerical
                  value. For example: hjid:<b>9656301</b>,hjsv:6. Copy the bolded value.
                </li>
                <li>Paste the code into the Hotjar Key input.</li>
                <li>Click Save to finalize your details.</li>
              </ol>
            </Typography>
          </Grid>
        </FormControl>
      )}
    </AppForm>
  );
};

export default Hotjar;
