import { useMutation, useQuery } from "@apollo/client";
import { Button, Divider, makeStyles, Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";

import { notificationVar } from "../../../cache/notificationPolicy";
import ConfirmationModal from "../../../components/ConfirmationModal";
import DropdownSelect from "../../../components/DropdownSelect";
import FormCard from "../../../components/FormCard";
import Loader from "../../../components/Loader";
import PageHeader from "../../../components/PageHeader";
import Routes from "../../../Constants/Routes";
import appShippingCarrierServices from "../../../queries/appShippingCarrierServices";
import appShippingDestroy from "../../../queries/appShippingDestroy";
import appShippingUpdate from "../../../queries/appShippingUpdate";

const useStyles = makeStyles(theme => ({
  spacing: {
    margin: theme.spacing(2, 0),
  },
  uninstallButton: {
    marginTop: theme.spacing(2),
  },
}));

const ShippingRates = ({ match, history }) => {
  const carrierId = match?.params?.id;
  // defaultServiceCode is undefined if accessing this page by redirecting from the add-shipping form
  const { carrierName, defaultServiceCode } = history.location.state;

  if (!carrierId) {
    history.goBack();
  }
  const classes = useStyles();
  const [openModal, setOpenModal] = useState(false);
  const { control, formState, handleSubmit, reset } = useForm({
    defaultValues: { defaultServiceCode },
  });
  useEffect(() => {
    reset({ defaultServiceCode });
  }, [defaultServiceCode, reset]);
  const { data, loading: isFetching } = useQuery(appShippingCarrierServices, {
    variables: { id: carrierId },
  });
  const options =
    data?.appShippingCarrierServices?.map(({ name, serviceCode }) => ({
      label: name,
      value: serviceCode,
    })) ?? [];
  const [updateShipping, { loading: isUpdating }] = useMutation(appShippingUpdate, {
    onCompleted: data => {
      notificationVar({
        message: `${carrierName} updated!`,
        severity: "success",
      });
      history.replace({
        ...history.location,
        state: {
          carrierName,
          defaultServiceCode: data.appShippingUpdate.defaultServiceCode,
        },
      });
    },
  });
  const [deleteShipping, { loading: isDeleting }] = useMutation(appShippingDestroy, {
    onCompleted: () => {
      notificationVar({
        message: `${carrierName} uninstalled!`,
        severity: "success",
      });
      history.push(Routes.APPS);
    },
    refetchQueries: ["GetSupplierIntegrations"],
  });

  const handleDiscardClick = () => reset();
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const handleDeleteClick = () => {
    handleCloseModal();
    deleteShipping({ variables: { id: carrierId } });
  };
  const onSubmit = data =>
    updateShipping({
      variables: {
        input: {
          defaultServiceCode: data.defaultServiceCode,
          id: carrierId,
        },
      },
    });

  if (!data || isFetching || isDeleting || isUpdating) return <Loader />;

  return (
    <>
      <PageHeader
        homePage={Routes.APPS}
        primaryActions={
          <>
            <Button color="primary" disabled={!formState?.isDirty} onClick={handleDiscardClick} variant="outlined">
              Discard
            </Button>
            <Button color="primary" disabled={!formState?.isDirty} onClick={handleSubmit(onSubmit)} variant="contained">
              Save
            </Button>
          </>
        }
        stickyHeader
        title="Shipping Integration"
      />
      <FormCard>
        <Typography variant="h6">{carrierName}</Typography>
        <Divider />
        <Controller
          as={<DropdownSelect />}
          className={classes.spacing}
          control={control}
          label="Service Type"
          name="defaultServiceCode"
          options={options}
        />
        <Typography variant="subtitle1">
          To make edits to your shipping account information, please uninstall and refill the application form.
        </Typography>
        <Button className={classes.uninstallButton} color="secondary" onClick={handleOpenModal} variant="outlined">
          Uninstall
        </Button>
      </FormCard>
      <ConfirmationModal
        cancelRequestButtonText="Cancel"
        confirmRequestButtonText="Uninstall"
        isDangerAction
        modalContent="Are you sure you want to uninstall this app?"
        modalNote="Note: This action cannot be reversed."
        modalTitle={`Uninstall ${carrierName}`}
        onCloseModal={handleCloseModal}
        onConfirmClick={handleDeleteClick}
        shouldOpenModal={openModal}
      />
    </>
  );
};

export default ShippingRates;
