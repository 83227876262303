import { FormControlLabel, Switch, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";

const SwitchToggle = React.forwardRef(
  ({ className, component, describeStatus, disabled, label, labelPlacement, onChange, value }, ref) => {
    const handleChange = e => onChange(e.target.checked);

    return (
      <>
        <FormControlLabel
          checked={value}
          className={className}
          control={component}
          disabled={disabled}
          inputRef={ref}
          label={label}
          labelPlacement={labelPlacement}
          onChange={handleChange}
        />
        {describeStatus && (
          <Typography color={value ? "primary" : "textSecondary"} component="span" variant="body2">
            {value ? "On" : "Off"}
          </Typography>
        )}
      </>
    );
  },
);

SwitchToggle.defaultProps = {
  className: undefined,
  component: <Switch color="primary" />,
  describeStatus: false,
  disabled: false,
  label: undefined,
  labelPlacement: "start",
  onChange: () => {},
  value: false,
};

SwitchToggle.propTypes = {
  className: PropTypes.string,
  component: PropTypes.node,
  describeStatus: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.node,
  labelPlacement: PropTypes.oneOf(["bottom", "top", "end", "start"]),
  onChange: PropTypes.func,
  value: PropTypes.bool,
};

export default SwitchToggle;
