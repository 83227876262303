import { Fade, IconButton, TextField } from "@material-ui/core";
import { Close, Search } from "@material-ui/icons";
import qs from "qs";
import { useState } from "react";

type OnUpdateQueryProps = {
  query: string | qs.ParsedQs | string[] | qs.ParsedQs[];
};

type ProductSearchProps = {
  query: string | qs.ParsedQs | string[] | qs.ParsedQs[];
  onUpdateQuery: (props: OnUpdateQueryProps) => void;
};

const ProductSearch = ({ query, onUpdateQuery }: ProductSearchProps) => {
  const [searchInput, setSearchInput] = useState(query);
  const onSearchInputChange = event => setSearchInput(event.target.value);
  const onSearchSubmit = ({ type, key }) => {
    if (type === "keydown" && key === "Enter") {
      onUpdateQuery({ query: searchInput });
    }
  };
  const onSearchClear = () => {
    setSearchInput("");
    onUpdateQuery({ query: undefined });
  };

  return (
    <TextField
      InputProps={{
        endAdornment: (
          <Fade in={searchInput.length > 0} unmountOnExit>
            <IconButton aria-label="search" id="search-submit" onClick={onSearchClear} size="small">
              <Close />
            </IconButton>
          </Fade>
        ),
        startAdornment: <Search />,
      }}
      fullWidth
      label="Search"
      onChange={onSearchInputChange}
      onKeyDown={onSearchSubmit}
      placeholder="Search products"
      value={searchInput}
      variant="outlined"
    />
  );
};

export default ProductSearch;
