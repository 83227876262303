import { gql } from "@apollo/client";

import INTEGRATIONS_FRAGMENT from "../fragment/integrations";

const supplierIntegrations = gql`
  query GetSupplierIntegrations {
    getSupplierAccount {
      ...IntegrationsFragment
    }
  }
  ${INTEGRATIONS_FRAGMENT}
`;

const supplierExportSettings = gql`
  query GetSupplierExportSettings {
    getSupplierAccount {
      exportSettings {
        orders {
          selectedFields {
            resource
            path
          }
          sortFields {
            field {
              resource
              path
            }
            direction
          }
        }
      }
    }
    ordersExportFields {
      resource
      path
      label
      canSort
      canGroup
    }
  }
`;

export { supplierExportSettings, supplierIntegrations };
