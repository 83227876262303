import { Account } from "@arowana/graphql";
import { QuickSearch } from "@arowana/ui";
import { Box, Chip, makeStyles, Typography } from "@material-ui/core";
import { useContext } from "react";

import { AccountContext } from "../../context/AccountContext";

const useStyles = makeStyles(() => ({
  chip: {
    width: "fit-content",
  },
}));

interface AccountSearch {
  email: string;
  id: string;
  name: string;
  notes: string;
  supplier_id: string;
}

interface CustomerAutocompleteProps {
  filteredIds?: Set<string>;
  noOptionsText?: string;
  onChange?: (account: AccountSearch) => void;
  value?: Account;
}

// add staff account, manual order
const CustomerAutocomplete = ({
  filteredIds = new Set(),
  noOptionsText = "No customers found",
  onChange,
  value,
}: CustomerAutocompleteProps) => {
  const classes = useStyles();
  const { supplier } = useContext(AccountContext);

  const filterAccountIds = [...filteredIds].map(id => `id != "${id}"`).join(" AND ");

  const handleChange = (customer: AccountSearch) => {
    onChange(customer);
  };

  const handleDeleteCustomer = () => {
    onChange(null);
  };

  return (
    <>
      <QuickSearch
        getOptionLabel={option => `${option?.name} - ${option?.email}`}
        index="accounts"
        label=""
        margin="dense"
        noOptionsText={noOptionsText}
        onChange={handleChange}
        renderOption={(option: AccountSearch) => (
          <Box>
            <Typography variant="body2">{option.name}</Typography>
            <Typography variant="subtitle2">{option.email}</Typography>
          </Box>
        )}
        searchParams={{
          filter: [`supplier_id = "${supplier.id}"`, filterAccountIds],
          limit: 10,
        }}
        supplierId={supplier.id}
      />

      {value && (
        <Chip
          className={classes.chip}
          color="primary"
          label={`${value.name} - ${value.email}`}
          onDelete={handleDeleteCustomer}
        />
      )}
    </>
  );
};

export default CustomerAutocomplete;
