import { PromotionType } from "@arowana/graphql";
import { FormControl, FormHelperText, FormLabel, Grid, makeStyles, TextField, Typography } from "@material-ui/core";
import { Controller, UseFormMethods } from "react-hook-form";

import FormCard from "../../../components/FormCard";
import RadioSelector from "../../account/components/RadioSelector";
import SwitchToggle from "../../account/components/SwitchToggle";

const DISPLAY_DISCOUNT_TYPE = {
  [PromotionType.percentage]: "Percentage",
  [PromotionType.amount]: "Fixed amount",
};

const options = [
  { label: "Percentage", value: PromotionType.percentage },
  { label: "Fixed amount", value: PromotionType.amount },
];

const useStyles = makeStyles(theme => ({
  codeName: {
    textTransform: "uppercase",
  },
  status: {
    marginLeft: 0,
    marginRight: theme.spacing(0.5),
  },
}));

interface DiscountCodeSummaryType {
  control: UseFormMethods["control"];
  editMode: boolean;
  errors: UseFormMethods["errors"];
  getValues: UseFormMethods["getValues"];
  register: UseFormMethods["register"];
}

const DiscountCodeSummary = ({ control, editMode, errors, getValues, register }: DiscountCodeSummaryType) => {
  const classes = useStyles();

  return (
    <FormCard>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <FormControl fullWidth margin="none">
            <FormLabel htmlFor="code">Discount code</FormLabel>
            <TextField
              InputProps={{
                classes: {
                  input: classes.codeName,
                },
              }}
              aria-describedby="code-helper"
              error={Boolean(errors?.code)}
              fullWidth
              helperText={errors?.code?.message}
              id="code"
              inputRef={register({ required: "*required", setValueAs: v => v.toUpperCase() })}
              margin="normal"
              name="code"
              size="small"
              variant="outlined"
            />
            <FormHelperText id="code-helper">Customer will enter this discount code at checkout.</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item>
          <Controller
            aria-describedby="active-helper"
            as={<SwitchToggle />}
            className={classes.status}
            control={control}
            describeStatus
            id="active"
            label="Status"
            name="active"
          />
          <FormHelperText id="active-helper">Edits can only be made while discount is inactive.</FormHelperText>
        </Grid>

        <Grid container item spacing={2}>
          <Grid item md={6} xs={12}>
            <FormControl fullWidth margin="none">
              <FormLabel htmlFor="startsAt">Start date</FormLabel>
              <TextField
                error={Boolean(errors?.startsAt)}
                fullWidth
                helperText={errors?.startsAt?.message}
                id="startsAt"
                inputRef={register({ required: "*required" })}
                margin="normal"
                name="startsAt"
                size="small"
                type="date"
                variant="outlined"
              />
            </FormControl>
          </Grid>
          <Grid item md={6} xs={12}>
            <FormLabel htmlFor="expiresAt">Expiry date</FormLabel>
            <TextField
              error={Boolean(errors?.expiresAt)}
              fullWidth
              helperText={errors?.expiresAt?.message}
              id="expiresAt"
              inputRef={register({ required: "*required" })}
              margin="normal"
              name="expiresAt"
              size="small"
              type="date"
              variant="outlined"
            />
          </Grid>
        </Grid>
        <Grid item>
          <Typography gutterBottom variant="subtitle1">
            Type of discount
          </Typography>
          <div style={{ display: editMode ? "none" : "block" }}>
            <Controller as={<RadioSelector />} control={control} name="type" options={options} />
          </div>
          <div style={{ display: editMode ? "block" : "none" }}>
            <Typography variant="body2">{DISPLAY_DISCOUNT_TYPE[getValues("type")]}</Typography>
          </div>
        </Grid>
      </Grid>
    </FormCard>
  );
};

export default DiscountCodeSummary;
