import { IconButton, makeStyles, Typography } from "@material-ui/core";
import { Add as AddIcon, Remove as RemoveIcon } from "@material-ui/icons";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  button: {
    flexGrow: 0,
  },
  container: {
    alignItems: "center",
    border: `2px solid ${theme.palette.text.secondary}`,
    borderRadius: 4,
    display: "flex",
    transition: theme.transitions.create("border"),
    width: "100%",
  },
  containerActive: {
    border: `2px solid ${theme.palette.primary.main}`,
  },
  disabled: {
    alignItems: "center",
    border: `2px solid ${theme.palette.action.disabled}`,
    borderRadius: 4,
    color: theme.palette.action.disabled,
    display: "flex",
    width: "100%",
  },
  value: {
    flexGrow: 1,
    textAlign: "center",
  },
}));

export interface CounterProps {
  display: (value: number) => string;
  value: number;
  onChange: (quantity: number) => void;
  disabled: boolean;
  size: "small" | "medium";
  minValue: number;
}

const DELTA = 1;

export const Counter = ({ display, value, onChange, disabled, size, minValue = 1 }: CounterProps) => {
  const classes = useStyles();
  const isMin = value === minValue;
  const increment = () => {
    const newValue = value + DELTA;

    onChange(newValue);
  };
  const decrement = () => {
    const newValue = value - DELTA;

    onChange(newValue);
  };
  const typograpyVariant = size === "medium" ? "body1" : "body2";

  return (
    <div
      className={clsx(classes.container, {
        [classes.disabled]: disabled,
        [classes.containerActive]: value > minValue,
      })}
    >
      <IconButton
        className={classes.button}
        color="primary"
        disabled={isMin || disabled}
        onClick={decrement}
        size={size}
        title="Decrement"
        aria-label="decrement"
      >
        <RemoveIcon fontSize="inherit" />
      </IconButton>
      <Typography className={classes.value} variant={typograpyVariant}>
        {display(value)}
      </Typography>
      <IconButton
        className={classes.button}
        color="primary"
        disabled={disabled}
        onClick={increment}
        size={size}
        title="Increment"
        aria-label="increment"
      >
        <AddIcon fontSize="inherit" />
      </IconButton>
    </div>
  );
};

export default Counter;
