import { ApolloProvider } from "@apollo/client";
import { HttpsRedirect, Notification } from "@arowana/ui";
import { Router } from "react-router";
import { Route, Switch } from "react-router-dom";

import { notificationVar } from "./cache/notificationPolicy";
import Impersonate from "./components/Impersonate";
import PrivateRoute from "./components/PrivateRoute";
import Routes from "./Constants/Routes";
import history from "./history";
import LoginPage from "./modules/auth/pages/Login";
import ResetPasswordPage from "./modules/auth/pages/ResetPassword";
import SignupPage from "./modules/auth/pages/Signup";
import DashboardRoutes from "./modules/dashboard/DashboardRoutes";
import client from "./services/apollo";

function App() {
  return (
    <HttpsRedirect>
      <ApolloProvider client={client}>
        <Notification horizontal="center" notificationVar={notificationVar} />
        <Router history={history}>
          <Switch>
            <Route component={Impersonate} path={Routes.IMPERSONATE} />
            <Route component={LoginPage} path={Routes.LOGIN_PATH} />
            <Route component={SignupPage} path={Routes.SIGNUP_PATH} />
            <Route component={ResetPasswordPage} path={Routes.RESET_PASSWORD} />
            <PrivateRoute component={DashboardRoutes} />
          </Switch>
        </Router>
      </ApolloProvider>
    </HttpsRedirect>
  );
}

export default App;
