import { ProductUnit } from "@arowana/graphql";
import { unitLabel } from "@arowana/util";
import { useCallback } from "react";

import Counter, { CounterProps } from "./Counter";

type ProductQuantityCounterProps = {
  caseSize: number;
  unit: ProductUnit;
  disabled?: CounterProps["disabled"];
  value?: CounterProps["value"];
  onChange: CounterProps["onChange"];
  size?: CounterProps["size"];
  minValue?: CounterProps["minValue"];
};

export const ProductQuantityCounter = ({
  caseSize,
  value,
  onChange,
  disabled = false,
  size = "medium",
  unit,
  minValue = 1,
}: ProductQuantityCounterProps) => {
  const display = useCallback(
    cases => {
      const displayValue = (cases * caseSize).toFixed(2).replace(/0+$/, "").replace(/\.$/, "");

      return `${displayValue} ${unitLabel(unit)}`;
    },
    [caseSize, unit],
  );

  return (
    <Counter display={display} value={value} onChange={onChange} minValue={minValue} disabled={disabled} size={size} />
  );
};

export default ProductQuantityCounter;
