import { gql, useMutation, useQuery } from "@apollo/client";
import { Supplier, SupplierSettingsInput } from "@arowana/graphql";
import { DATALAYER } from "@arowana/util";
import { Button, Grid, TextField, Typography } from "@material-ui/core";
import { Controller, useForm } from "react-hook-form";

import { notificationVar } from "../../../cache/notificationPolicy";
import FormCard from "../../../components/FormCard";
import Loader from "../../../components/Loader";
import MultiChips from "../../../components/MultiChips";
import PageHeader from "../../../components/PageHeader";

const COMMUNICATION_FRAGMENT = gql`
  fragment CommunicationFragment on Supplier {
    emailSettings {
      accountOrderCreateText
    }
    notificationSettings {
      emails
    }
  }
`;

const COMMUNICATION = gql`
  query Communication {
    currentSupplier {
      id
      ...CommunicationFragment
    }
  }
  ${COMMUNICATION_FRAGMENT}
`;

const UPDATE_COMMUNICATION = gql`
  mutation UpdateCommunication($input: SupplierSettingsInput!) {
    supplierUpdateSettings(input: $input) {
      id
      ...CommunicationFragment
    }
  }
  ${COMMUNICATION_FRAGMENT}
`;

interface FormProps {
  accountOrderCreateText: string;
  emails: string[];
}

const Communication = () => {
  const {
    register,
    handleSubmit,
    control,
    formState: { isDirty, dirtyFields },
    reset,
  } = useForm<FormProps>({
    defaultValues: {
      accountOrderCreateText: "",
      emails: [],
    },
  });

  const { loading: isFetching } = useQuery<{ currentSupplier: Supplier }>(COMMUNICATION, {
    context: { source: DATALAYER },
    fetchPolicy: "cache-and-network",
    onCompleted: data => {
      const { currentSupplier } = data;

      reset({
        accountOrderCreateText: currentSupplier.emailSettings?.accountOrderCreateText ?? "",
        emails: currentSupplier.notificationSettings?.emails ?? [],
      });
    },
  });

  const [updateSupplier, { loading: isSaving }] = useMutation<
    { supplierUpdateSettings: Supplier },
    { input: SupplierSettingsInput }
  >(UPDATE_COMMUNICATION, {
    context: { source: DATALAYER },
    onCompleted: () => {
      notificationVar({
        message: "Account updated!",
        severity: "success",
      });
    },
    refetchQueries: ["Communication"],
  });

  const handleDiscardClick = () => reset();

  const onSubmit = (data: FormProps) => {
    const variables = {
      input: {
        ...(dirtyFields.accountOrderCreateText && {
          emailSettings: { accountOrderCreateText: data.accountOrderCreateText },
        }),
        ...(dirtyFields.emails && {
          notificationSettings: { emails: data.emails },
        }),
      },
    };

    updateSupplier({ variables });
  };

  return (
    <>
      {(isFetching || isSaving) && <Loader />}

      <PageHeader
        primaryActions={
          <>
            <Button color="primary" disabled={!isDirty} onClick={handleDiscardClick} variant="outlined">
              Discard
            </Button>
            <Button color="primary" disabled={!isDirty} onClick={handleSubmit(onSubmit)} variant="contained">
              Save
            </Button>
          </>
        }
        stickyHeader
        title="Communication"
      />

      <FormCard>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography gutterBottom variant="subtitle1">
              Custom Order Confirmation Message
            </Typography>
            <TextField fullWidth inputRef={register} multiline name="accountOrderCreateText" variant="outlined" />
            <Typography color="textSecondary" variant="caption">
              This message will appear on order confirmation emails received by customers.
            </Typography>
          </Grid>

          <Grid item>
            <Typography gutterBottom variant="subtitle1">
              Recipients
            </Typography>
            <Controller as={<MultiChips />} control={control} fullWidth name="emails" type="email" />
            <Typography color="textSecondary" variant="caption">
              Manage what members of your team receive email notifications.
            </Typography>
          </Grid>
        </Grid>
      </FormCard>
    </>
  );
};

export default Communication;
