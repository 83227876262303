import { Box, Button, makeStyles, TextField, Typography } from "@material-ui/core";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { useForm } from "react-hook-form";

import RichTextFormComponent from "../../../components/RichTextFormComponent";

const useStyles = makeStyles(theme => ({
  answerForm: { marginTop: theme.spacing(1) },
  cancelUpdate: { marginRight: theme.spacing(1) },
  editFaqContainer: {
    backgroundColor: theme.palette.grey[100],
    border: `1px solid ${theme.palette.grey[400]}`,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1, 2),
  },
  editForm: {
    margin: theme.spacing(1, 0),
  },
}));
const FaqForm = ({ faq, onSubmit, onCancelEditFaq, isAddFaq }) => {
  const classes = useStyles();
  const {
    control,
    handleSubmit,
    errors,
    register,
    reset,
    formState: { isSubmitSuccessful },
  } = useForm({
    defaultValues: {
      answer: isAddFaq ? "" : faq?.answer ?? "",
      question: isAddFaq ? "" : faq?.question ?? "",
    },
  });

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
  }, [isSubmitSuccessful, reset]);

  let renderAction;

  if (isAddFaq) {
    renderAction = (
      <Box display="flex" justifyContent="flex-end" mt={1}>
        <Button color="primary" onClick={handleSubmit(onSubmit)} variant="outlined">
          Add Question
        </Button>
      </Box>
    );
  } else {
    renderAction = (
      <Box display="flex" justifyContent="flex-end" mt={1}>
        <Button className={classes.cancelUpdate} color="primary" onClick={onCancelEditFaq}>
          Cancel
        </Button>
        <Button color="primary" onClick={handleSubmit(onSubmit)} variant="outlined">
          Update
        </Button>
      </Box>
    );
  }

  return (
    <Box className={clsx(classes.editForm, { [classes.editFaqContainer]: !isAddFaq })}>
      <Typography gutterBottom variant="subtitle2">
        Question
      </Typography>
      <TextField
        InputProps={{
          inputProps: {
            style: {
              paddingBottom: "0",
              paddingTop: "0",
            },
          },
        }}
        error={!!errors.question}
        fullWidth
        helperText={errors?.question?.message}
        inputRef={register({ required: "*required" })}
        multiline
        name="question"
        placeholder="Question"
        variant="outlined"
      />
      <Typography className={classes.answerForm} gutterBottom variant="subtitle2">
        Answer
      </Typography>
      <RichTextFormComponent
        control={control}
        helperText={errors?.answer?.message}
        name="answer"
        placeholder="Answer"
        required
      />
      {renderAction}
    </Box>
  );
};

FaqForm.defaultProps = {
  faq: undefined,
  isAddFaq: false,
  onCancelEditFaq: undefined,
};

FaqForm.propTypes = {
  faq: PropTypes.object,
  isAddFaq: PropTypes.bool,
  onCancelEditFaq: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
};

export default FaqForm;
