import { useLazyQuery } from "@apollo/client";
import { usePrevious } from "@arowana/ui";
import {
  Box,
  Fade,
  LinearProgress,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import qs from "qs";
import useDeepCompareEffect from "use-deep-compare-effect";

import ordersExportPreview from "../../../queries/ordersExportPreview";

const useStyles = makeStyles(theme => ({
  tableContainer: {
    minHeight: 470,
    overscrollBehaviorX: "none",
  },
  tableHead: {
    backgroundColor: theme.palette.grey[50],
  },
  tableHeadCell: {
    whiteSpace: "nowrap",
  },
}));

type ExportPreviewTableProps = {
  input: {
    audience: string | string[] | qs.ParsedQs | qs.ParsedQs[];
    endDate: string;
    selected: { path: string; resource: string }[];
    sortBy: { direction: string; field: string }[];
    startDate: string;
    status: string | string[] | qs.ParsedQs | qs.ParsedQs[];
  };
};

const ExportPreviewTable = ({ input }: ExportPreviewTableProps) => {
  const classes = useStyles();
  const [fetchPreview, { data: previewResponse, loading: loadingPreview }] = useLazyQuery(ordersExportPreview, {
    nextFetchPolicy: "network-only",
  });
  const previousResponse = usePrevious(previewResponse?.ordersExportPreview);
  const { headers = [], rows = [] } = (previewResponse?.ordersExportPreview || previousResponse) ?? {};
  const noInput = input.selected.length === 0;
  const noData = rows.length === 0;

  useDeepCompareEffect(() => {
    if (!noInput) {
      fetchPreview({
        variables: {
          input,
        },
      });
    }
  }, [input]);

  return (
    <TableContainer className={classes.tableContainer}>
      <Fade in={loadingPreview}>
        <LinearProgress />
      </Fade>
      {noInput && <Box>Please select a column</Box>}
      {!noInput && !noData && (
        <Table aria-label="data preview table">
          <TableHead className={classes.tableHead}>
            <TableRow>
              {headers?.map(title => (
                <TableCell className={classes.tableHeadCell} key={title}>
                  {title}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(({ index, data }) => (
              <TableRow key={`preview-row-${index}`}>
                {data?.map((value, index) => (
                  <TableCell key={index}>{value}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </TableContainer>
  );
};

export default ExportPreviewTable;
