import { gql, useMutation } from "@apollo/client";
import { DATALAYER } from "@arowana/util";
import {
  AppBar,
  Button,
  Chip,
  Divider,
  Drawer,
  Hidden,
  IconButton,
  Link,
  makeStyles,
  Menu,
  MenuItem,
  Popover,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import {
  AccountCircleOutlined,
  ArrowDropDown as ArrowDropDownIcon,
  ErrorOutline as ErrorIcon,
  KeyboardArrowLeft as KeyboardArrowLeftIcon,
  Menu as MenuIcon,
} from "@material-ui/icons";
import Alert from "@material-ui/lab/Alert";
import Routes from "apps/supplier/src/Constants/Routes";
import PropTypes from "prop-types";
import { forwardRef, useContext, useState } from "react";
import { Link as RouterLink, LinkProps as RouterLinkProps } from "react-router-dom";

import logo from "../../../../public/images/freshline-logo-square.svg";
import logout from "../../auth/logout";
import { AccountContext } from "../../context/AccountContext";
import { FlagsmithContext } from "../../context/FlagsmithContext";
import Sidebar from "./Sidebar";

const CONNECT_STRIPE = gql`
  mutation connectStripe {
    supplierConnectStripe
  }
`;

const drawerWidth = 200;
const useStyles = makeStyles(theme => ({
  accountMenuButton: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    [theme.breakpoints.only("xs")]: {
      "& .MuiButton-startIcon": {
        margin: 0,
      },
      "minWidth": 0,
      "padding": 0,
    },
  },
  appBar: {
    "@media print": {
      display: "none",
    },
    "backgroundColor": theme.palette.background.paper,
    "borderLeft": "none",
    "borderRight": "none",
    "borderTop": "none",
    "zIndex": theme.zIndex.drawer + 1,
  },
  closeButton: {
    left: theme.spacing(2),
    position: "absolute",
    top: theme.spacing(1),
  },
  content: {
    "@media print": {
      margin: "0 !important",
      maxWidth: "100% !important",
      padding: "0 !important",
      width: "100% !important",
    },
    [theme.breakpoints.up("sm")]: {
      marginLeft: drawerWidth,
      maxWidth: `calc(100% - ${drawerWidth}px)`,
    },
    "flexGrow": 1,
    "maxWidth": "100%",
    "minHeight": "100vh",
    "padding": theme.spacing(2, 4),
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100vw",
      padding: theme.spacing(2, 1, 4, 1),
    },
    [theme.breakpoints.up("lg")]: {
      padding: theme.spacing(2, 8),
    },
  },
  divider: {
    margin: theme.spacing(0.5, 0),
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      flexShrink: 0,
      width: drawerWidth,
    },
    "@media print": {
      display: "none",
    },
  },
  drawerPaper: {
    "@media print": {
      display: "none",
    },
    "backgroundColor": theme.palette.background.default,
    "width": drawerWidth,
  },
  logo: {
    display: "inline-block",
    marginRight: theme.spacing(1),
    transform: "translateY(2px)",
  },
  paper: {
    padding: theme.spacing(1),
  },
  popover: {
    pointerEvents: "none",
  },
  stripeWarning: {
    "& > .MuiAlert-icon": {
      alignItems: "center",
    },
    "marginTop": theme.spacing(5),
    "padding": theme.spacing(0, 2, 0, 0.5),
  },
  testModeCardChip: {
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.warning.contrastText,
    marginRight: theme.spacing(1),
  },
  title: {
    flexGrow: 1,
    paddingLeft: theme.spacing(0),
    [theme.breakpoints.only("xs")]: {
      padding: 0,
      paddingRight: theme.spacing(1),
      textAlign: "center",
      transform: "translateX(-8px)",
    },
  },
  titleText: {
    "&:hover": {
      textDecoration: "none",
    },
    "color": theme.palette.text.primary,
    "fontWeight": "bold",
    "textDecoration": "none",
  },
}));

const DashboardContainer = ({ children }) => {
  const theme = useTheme();
  const classes = useStyles();
  const isExtraSmall = useMediaQuery(theme.breakpoints.down("xs"));
  const { account, supplier } = useContext(AccountContext);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [testModeAnchorEl, setTestModeAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const testModeOpen = Boolean(testModeAnchorEl);

  const [connectStripe, { loading }] = useMutation(CONNECT_STRIPE, {
    context: { source: DATALAYER },
    onCompleted: data => {
      const url = data.supplierConnectStripe;

      if (url) {
        window.location.href = url;
      }
    },
  });

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    handleClose();
    logout();
  };

  const handleTestModePopoverOpen = event => {
    setTestModeAnchorEl(event.currentTarget);
  };

  const handleTestModePopoverClose = () => {
    setTestModeAnchorEl(null);
  };

  const flagsmith = useContext(FlagsmithContext);
  const hasRetail = flagsmith.hasFeature("b2c");
  const hasWholesale = flagsmith.hasFeature("b2b");

  const MuiRouterLink = forwardRef<unknown, Omit<RouterLinkProps, "to"> & { href: RouterLinkProps["to"] }>(
    (props, ref) => {
      const { href, ...other } = props;

      return <Link component={RouterLink} ref={ref} to={href} {...other} />;
    },
  );

  return (
    <>
      <AppBar className={classes.appBar} position="fixed" variant="outlined">
        <Toolbar variant="dense">
          <Hidden smUp>
            <IconButton aria-label="open drawer" edge="start" onClick={handleDrawerToggle} size="small">
              <MenuIcon />
            </IconButton>
          </Hidden>
          <Typography component="h1" className={classes.title} variant="body1">
            <img alt="Freshline" className={classes.logo} src={logo} width="24" />
            <Link className={classes.titleText} component={RouterLink} to={Routes.PRODUCT_LIST}>
              {supplier?.name ?? "Dashboard"}
            </Link>
          </Typography>
          {window.location.origin.endsWith(".freshlinestaging.com") && (
            <>
              <Chip
                aria-haspopup="true"
                aria-owns={open ? "test-mode-popover" : undefined}
                className={classes.testModeCardChip}
                icon={<ErrorIcon />}
                label="Test Mode"
                onClick={handleTestModePopoverOpen}
                onMouseEnter={handleTestModePopoverOpen}
                onMouseLeave={handleTestModePopoverClose}
                size="small"
              />
              <Popover
                anchorEl={testModeAnchorEl}
                anchorOrigin={{
                  horizontal: "right",
                  vertical: "bottom",
                }}
                className={classes.popover}
                classes={{
                  paper: classes.paper,
                }}
                disableRestoreFocus
                id="test-mode-popover"
                onClose={handleTestModePopoverClose}
                open={testModeOpen}
                transformOrigin={{
                  horizontal: "left",
                  vertical: "top",
                }}
              >
                <Typography>
                  Please note that this is pre-release software and for testing purposes only. Changes to data may be
                  lost periodically
                </Typography>
              </Popover>
            </>
          )}

          <div>
            <Button
              aria-controls="menu-appbar"
              aria-haspopup="true"
              aria-label="account of current user"
              className={classes.accountMenuButton}
              color="inherit"
              onClick={handleMenu}
              startIcon={<AccountCircleOutlined />}
            >
              <Hidden xsDown>
                {account?.name} <ArrowDropDownIcon />
              </Hidden>
            </Button>
            <Menu
              anchorEl={anchorEl}
              anchorOrigin={{
                horizontal: "right",
                vertical: "top",
              }}
              id="menu-appbar"
              keepMounted
              onClose={handleClose}
              open={open}
              transformOrigin={{
                horizontal: "right",
                vertical: "top",
              }}
            >
              {hasRetail && (
                <MenuItem component={Link} href={supplier?.url} target="_blank" underline="none">
                  View Retail Store
                </MenuItem>
              )}
              {hasWholesale && (
                <MenuItem component={Link} href={`${supplier?.url}/b2b`} target="_blank" underline="none">
                  View Wholesale Store
                </MenuItem>
              )}
              <Divider className={classes.divider} light />
              <MenuItem
                component={Link}
                href="https://blog.freshline.io/tag/product-update/"
                target="_blank"
                underline="none"
              >
                Product Updates
              </MenuItem>
              <MenuItem component={MuiRouterLink} href={Routes.HELP}>
                Help Guide
              </MenuItem>
              <MenuItem
                component={Link}
                href="https://blog.freshline.io/tag/resources/"
                target="_blank"
                underline="none"
              >
                Resources
              </MenuItem>
              <Divider className={classes.divider} light />
              <MenuItem component={MuiRouterLink} href={Routes.ACCOUNT_DETAILS}>
                My Account
              </MenuItem>
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        anchor="left"
        classes={{
          paper: classes.drawerPaper,
          root: classes.drawer,
        }}
        onClose={handleDrawerToggle}
        open={mobileOpen}
        variant={isExtraSmall ? "temporary" : "permanent"}
      >
        <Hidden smUp>
          <IconButton className={classes.closeButton} onClick={handleDrawerToggle}>
            <KeyboardArrowLeftIcon />
          </IconButton>
        </Hidden>
        <Sidebar onCloseNavbar={handleDrawerToggle} />
      </Drawer>
      <main className={classes.content}>
        {supplier?.requiresStripeConnect && (
          <Alert
            action={
              <Button disabled={loading} onClick={() => connectStripe()} size="small" variant="contained">
                <Typography variant="caption">Set up</Typography>
              </Button>
            }
            className={classes.stripeWarning}
            severity="warning"
            variant="outlined"
          >
            <Typography variant="caption">
              <strong>Stripe Connect required.</strong> Please note that you must first connect your bank account to
              Stripe to enable credit cards, ACH, and invoicing on Freshline.
            </Typography>
          </Alert>
        )}
        {children}
      </main>
    </>
  );
};

DashboardContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DashboardContainer;
