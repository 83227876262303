import { gql, useQuery } from "@apollo/client";
import { DATALAYER } from "@arowana/util";
import { Link, ListItemText, TextField, Typography } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { forwardRef, useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useDebounce } from "use-debounce";

import Routes from "../../../Constants/Routes";

const LIST_LOOKUP = gql`
  query supplierListLookup($pagination: PaginationInput, $query: String) {
    currentSupplier {
      id
      lists(page: $pagination, filter: { query: $query }) {
        edges {
          cursor
          node {
            id
            name
            identifier
          }
        }
      }
    }
  }
`;

const LinkAutocomplete = forwardRef(({ value, onChange, inputRef, error, helperText, ...rest }, ref) => {
  const [inputValue, setInputValue] = useState("");
  const [debouncedInputValue] = useDebounce(inputValue, 300);
  const isURI = inputValue.startsWith("https") || inputValue.startsWith("mailto");
  const hasInput = (debouncedInputValue?.length ?? 0) >= 3 && !isURI;
  const { data, loading } = useQuery(LIST_LOOKUP, {
    context: { source: DATALAYER },
    skip: !hasInput,
    variables: {
      pagination: {
        first: 20,
      },
      query: debouncedInputValue,
    },
  });
  const options =
    data?.currentSupplier?.lists?.edges?.map(({ node }) => ({
      id: node.id,
      name: (
        <>
          <strong>Product List:</strong> {node.name}
        </>
      ),
      value: `/lists/${node?.identifier}`,
    })) ?? [];

  useEffect(() => {
    if (value !== inputValue) {
      setInputValue(value);
    }
  }, [setInputValue, inputValue, value]);

  const onInputChange = (_, newInputValue, reason) => {
    if (reason === "clear") {
      onChange("");
    } else {
      onChange(newInputValue);
    }
  };

  return (
    <Autocomplete
      freeSolo
      getOptionLabel={option => {
        // Value selected with enter, right from the input
        if (typeof option === "string") {
          return option;
        }

        return option?.value;
      }}
      getOptionSelected={(option, selectedValue) => option.id === selectedValue?.id}
      inputValue={inputValue}
      loading={loading}
      onChange={(_, newValue) => {
        if (newValue) {
          onChange(newValue.value);
          setInputValue(newValue.value);
        }
      }}
      onInputChange={onInputChange}
      options={options}
      ref={ref}
      renderInput={params => (
        <>
          <TextField
            placeholder="Start searching for a Collection or enter your desired URL (e.g. https://freshline.io/about)"
            {...params}
            error={error}
            helperText={helperText}
            margin="dense"
            variant="outlined"
          />
          <Typography color="textSecondary" variant="caption">
            Tip: Add a Collection to your navigation by{" "}
            <Link component={RouterLink} to={Routes.LISTS}>
              creating one through the Collections page
            </Link>
            .
          </Typography>
        </>
      )}
      renderOption={({ id, name, value }) => <ListItemText key={id} primary={name} secondary={value}></ListItemText>}
      value={value}
      {...rest}
    />
  );
});

export default LinkAutocomplete;
