import { Ascending, Descending } from "@arowana/ui";
import { FormControlLabel, makeStyles, Radio, RadioGroup } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles(() => ({
  root: {
    flexDirection: "row",
  },
  sortOrderLabel: {
    display: "inline-flex",
  },
}));

export enum SortOrder {
  ASC = "ascending",
  DESC = "descending",
}

type ExportSortToggleProps = {
  value: SortOrder;
  onChange: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void;
};

const ExportSortToggle = ({ value, onChange }: ExportSortToggleProps) => {
  const classes = useStyles();

  return (
    <RadioGroup
      className={classes.root}
      aria-label="sort by order"
      name="primarySortOrder"
      onChange={onChange}
      value={value}
    >
      <FormControlLabel
        classes={{
          label: classes.sortOrderLabel,
        }}
        control={<Radio color="primary" />}
        label={
          <>
            <Ascending /> <span>Ascending</span>
          </>
        }
        value={SortOrder.ASC}
      />
      <FormControlLabel
        classes={{
          label: classes.sortOrderLabel,
        }}
        control={<Radio color="primary" />}
        label={
          <>
            <Descending /> <span>Descending</span>
          </>
        }
        value={SortOrder.DESC}
      />
    </RadioGroup>
  );
};

export default ExportSortToggle;
