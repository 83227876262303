/* eslint-disable @typescript-eslint/no-explicit-any */
import "react-quill/dist/quill.snow.css";

import { FormHelperText, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { StringMap } from "quill";
import { forwardRef } from "react";
import { Controller } from "react-hook-form";
import ReactQuill from "react-quill";

const useStyles = makeStyles<Theme, StyleProps>(theme => ({
  editor: ({ hasToolbar }) => ({
    "& > .ql-container": {
      borderBottomLeftRadius: theme.shape.borderRadius,
      borderBottomRightRadius: theme.shape.borderRadius,
      borderTopLeftRadius: hasToolbar ? 0 : theme.shape.borderRadius,
      borderTopRightRadius: hasToolbar ? 0 : theme.shape.borderRadius,
    },
    "& > .ql-toolbar": {
      borderTopLeftRadius: theme.shape.borderRadius,
      borderTopRightRadius: theme.shape.borderRadius,
    },
    "marginBottom": theme.spacing(0.5),
    "marginTop": theme.spacing(1),
  }),
}));

interface StyleProps {
  hasToolbar: boolean;
}

interface QuillEditorProps {
  value?: string;
  onChange?: (value: string) => void;
  helperText?: string;
  className?: string;
  modules?: StringMap;
}

export const QuillEditor = forwardRef<ReactQuill, QuillEditorProps>(
  ({ value, onChange, helperText, className, children, modules, ...props }, ref) => {
    const hasToolbar = modules?.toolbar?.length > 0;
    const classes = useStyles({ hasToolbar });

    return (
      <>
        <ReactQuill
          className={clsx(classes.editor, className)}
          modules={modules}
          onChange={onChange}
          placeholder=""
          ref={ref}
          theme="snow"
          value={value}
          {...props}
        />
        {helperText && <FormHelperText error>{helperText}</FormHelperText>}
      </>
    );
  },
);

interface RichTextFormComponentProps {
  bounds?: string | HTMLElement;
  className?: string;
  control: any;
  defaultValue?: string;
  helperText?: string;
  id?: string;
  modules?: StringMap;
  name: string;
  placeholder?: string;
  required?: boolean;
}

const RichTextFormComponent = ({
  bounds = document.body,
  className = "",
  control,
  defaultValue = "",
  helperText,
  id = "rich-textarea",
  modules = {
    clipboard: {
      matchVisual: false,
    },
    toolbar: [
      [{ size: [false, "large", "huge"] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
      ["link"],
    ],
  },
  name,
  placeholder = undefined,
  required = false,
  ...props
}: RichTextFormComponentProps) => (
  <Controller
    as={<QuillEditor />}
    bounds={bounds}
    className={className}
    control={control}
    defaultValue={defaultValue}
    helperText={helperText}
    id={id}
    modules={modules}
    name={name}
    placeholder={placeholder}
    rules={{
      ...(required && {
        validate: value => {
          if (!value) {
            return "*required";
          }

          const { length } = value.replace(/<(.|\n)*?>/g, "").trim();

          return length >= 1 || "*required";
        },
      }),
    }}
    {...props}
  />
);

export default RichTextFormComponent;
