import { FormHelperText, FormLabel, makeStyles } from "@material-ui/core";
import { Controller } from "react-hook-form";

import DropdownSelect from "../../../../components/DropdownSelect";
import FormCard from "../../../../components/FormCard";
import FormTooltip from "../../../../components/FormTooltip";

const OPTIONS = [
  { label: "Not cancellable", value: -1 },
  { label: "The day of", value: 0 },
  { label: "1 day before", value: 1 },
];
for (let i = 2; i <= 30; i++) {
  OPTIONS.push({ label: `${i} days before`, value: i });
}

const useStyles = makeStyles(theme => ({
  spacing: {
    marginTop: theme.spacing(1),
  },
}));

const Cancellation = ({ control }) => {
  const classes = useStyles();

  return (
    <FormCard title="Cancellation">
      <FormLabel htmlFor="product-cancellation" required>
        Cancellation window{" "}
        <FormTooltip content="Select a value to prevent orders containing this product from being cancelled within the above time window before the order fulfillment date." />
      </FormLabel>
      <Controller
        as={<DropdownSelect />}
        className={classes.spacing}
        control={control}
        defaultValue={1}
        dropdownMargin="dense"
        id="product-cancellation"
        fullWidth
        name="daysToCancel"
        options={OPTIONS}
        rules={{ required: "*required" }}
      />
    </FormCard>
  );
};

export default Cancellation;
