import { DocumentNode, gql, useMutation, useQuery } from "@apollo/client";
import { FEATURE_SUPPLIER_APP_FIELDS } from "@arowana/flags";
import { AppField, AppFieldResourceType, AppFieldSortField, AppFieldType, AppFieldUpdateInput } from "@arowana/graphql";
import { PaginatedTable, usePagination } from "@arowana/ui";
import { DATALAYER } from "@arowana/util";
import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { GridAlignment, GridSortItem } from "@material-ui/data-grid";
import { Edit as EditIcon } from "@material-ui/icons";
import CloseIcon from "@material-ui/icons/Close";
import capitalize from "lodash/capitalize";
import moment from "moment";
import { useContext, useMemo, useState } from "react";

import { notificationVar } from "../cache/notificationPolicy";
import { AccountContext } from "../modules/context/AccountContext";
import { FlagsmithContext } from "../modules/context/FlagsmithContext";
import FormCard from "./FormCard";

const APP_FIELD_FRAGMENT = gql`
  fragment AppFieldFragment on AppField {
    id
    appID
    createdAt
    fieldDescription
    fieldKey
    fieldName
    fieldValue
    fieldType
    isUserMutable
    resourceID
    resourceType
    supplierId
    updatedAt
  }
`;

const APP_FIELD_UPDATE = gql`
  mutation AppFieldUpdate($input: AppFieldUpdateInput!) {
    appFieldUpdate(input: $input) {
      ...AppFieldFragment
    }
  }
  ${APP_FIELD_FRAGMENT}
`;

const getColumns = (handleOpenEditAppFieldModal, timezone, classes) => [
  {
    field: "id",
    hide: true,
  },
  {
    field: AppFieldSortField.FIELD_NAME,
    headerName: "Field name",
    valueGetter: param => param.row.fieldName,
    width: 200,
  },
  {
    field: "fieldValue",
    headerName: "Field value",
    renderCell: param => (
      <>
        {param.value}
        <Tooltip title="Edit">
          <IconButton className={classes.editButton} disabled={!param.row.isUserMutable} size="small">
            <EditIcon
              className={classes.editButtonIcon}
              onClick={() => handleOpenEditAppFieldModal(param.row as AppField)}
            />
          </IconButton>
        </Tooltip>
      </>
    ),
    sortable: false,
    width: 180,
  },
  {
    field: AppFieldSortField.UPDATED_AT,
    headerName: "Updated at",
    valueGetter: param => moment.tz(param.row.updatedAt, timezone).format("L"),
    width: 140,
  },
  {
    align: "right" as GridAlignment,
    field: AppFieldSortField.CREATED_AT,
    headerAlign: "right" as GridAlignment,
    headerName: "Created at",
    valueGetter: param => moment.tz(param.row.createdAt, timezone).format("L"),
    width: 140,
  },
];

const useStyles = makeStyles(theme => ({
  checkbox: {
    marginLeft: theme.spacing(-1),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(2),
    top: theme.spacing(2),
  },
  dialogContainer: {
    [theme.breakpoints.only("xs")]: {
      paddingTop: theme.spacing(7),
    },
  },
  editButton: {
    marginLeft: theme.spacing(0.5),
  },
  editButtonIcon: {
    fontSize: 18,
  },
  spacing: {
    marginTop: theme.spacing(1),
  },
}));

const ROWS_PER_PAGE = 10;

const defaultSortState: GridSortItem = {
  field: AppFieldSortField.FIELD_NAME,
  sort: "desc",
};

const getQuery = (resourceType: AppFieldResourceType) => {
  return gql`
    query ${capitalize(
      resourceType,
    )}AppFields($id: ID!, $filter: AppFieldFilterInput, $page: PaginationInput!, $sort: AppFieldSortInput) {
      resource: ${resourceType}(id: $id) {
        id
        appFields(filter: $filter, sort: $sort, page: $page) {
          edges {
            cursor
            node {
              ...AppFieldFragment
            }
          }
          pageInfo {
            count
            endCursor
            hasNextPage
            totalCount
          }
        }
      }
    }
    ${APP_FIELD_FRAGMENT}
  `;
};

interface AppFieldsProps {
  id: string;
  resourceType: AppFieldResourceType;
}

interface AppFieldFormProps {
  appField: AppField;
  onCancel: (prop: void) => void;
  onUpdate: (prop: AppField) => void;
}

const AppFieldForm = ({ appField, onCancel, onUpdate }: AppFieldFormProps) => {
  const classes = useStyles();
  const [errorText, setErrorText] = useState<string>();
  const [value, setValue] = useState(appField.fieldValue);

  const handleChangeTextField = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (errorText) {
      setErrorText(null);
    }

    setValue(e.target.value);
  };

  const handleChangeBooleanField = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.checked);
  };

  const handleSave = () => {
    const { fieldType } = appField;

    if (fieldType === AppFieldType.BOOL) {
      onUpdate({ ...appField, fieldValue: value });
    } else {
      let parsedValue = value.trim();

      if (!parsedValue) {
        setErrorText("*required");

        return;
      } else if (fieldType === AppFieldType.FLOAT) {
        parsedValue = parseFloat(parsedValue);

        if (isNaN(parsedValue)) {
          setErrorText("Please provide a number, e.g, 1.23");

          return;
        }
      } else if (fieldType === AppFieldType.INT) {
        parsedValue = parseFloat(parsedValue);

        if (isNaN(parsedValue) || !Number.isInteger(parsedValue)) {
          setErrorText("Please provide an integer, e.g, 100");

          return;
        }

        parsedValue = parseInt(parsedValue);
      }

      onUpdate({ ...appField, fieldValue: parsedValue });
    }
  };

  const handleKeyUpEvent = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      handleSave();
    }
  };

  return (
    <>
      <div>
        <Typography variant="body2">{appField.fieldName}</Typography>
        <Typography gutterBottom variant="caption">
          {appField.fieldDescription}
        </Typography>
      </div>

      {appField.fieldType === AppFieldType.BOOL ? (
        <Checkbox
          checked={value}
          className={classes.checkbox}
          color="primary"
          onChange={handleChangeBooleanField}
          size="small"
        />
      ) : (
        <TextField
          defaultValue={appField.fieldValue}
          error={Boolean(errorText)}
          fullWidth
          helperText={errorText}
          InputProps={{
            endAdornment: <InputAdornment position="end">{appField.fieldType}</InputAdornment>,
          }}
          margin="dense"
          onChange={handleChangeTextField}
          onKeyUp={handleKeyUpEvent}
          type={
            appField.fieldType === AppFieldType.FLOAT || appField.fieldType === AppFieldType.INT ? "number" : "text"
          }
          value={value}
          variant="outlined"
        />
      )}

      <Grid className={classes.spacing} container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Button
            color="primary"
            disabled={appField.fieldValue === value}
            fullWidth
            onClick={() => onCancel()}
            variant="outlined"
          >
            Cancel
          </Button>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Button
            color="primary"
            disabled={appField.fieldValue === value}
            fullWidth
            onClick={handleSave}
            variant="contained"
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

const AppFields = ({ id, resourceType }: AppFieldsProps) => {
  const classes = useStyles();
  const flagsmith = useContext(FlagsmithContext);
  const appFieldsEnabled = flagsmith.hasFeature(FEATURE_SUPPLIER_APP_FIELDS);

  if (!appFieldsEnabled) {
    return null;
  }

  const theme = useTheme();
  const { supplier } = useContext(AccountContext);
  const { timezone } = supplier;
  const [editAppField, setEditAppField] = useState<AppField>();
  const [sortState, setSortState] = useState(defaultSortState);
  const { setPageInfo, resetPagination, pagination, page, onPageChange } = usePagination(ROWS_PER_PAGE);
  const query: DocumentNode = useMemo(() => getQuery(resourceType), [resourceType]);

  if (!query) {
    return null;
  }

  const { data, loading } = useQuery(query, {
    context: { source: DATALAYER },
    onCompleted: data => {
      setPageInfo(data?.resource?.appFields.pageInfo);
    },
    variables: {
      id,
      page: pagination,
      sort: sortState && {
        field: sortState.field,
        order: sortState.sort === "desc" ? -1 : 1,
      },
    },
  });

  const totalCount = data?.resource?.appFields.pageInfo.totalCount ?? 0;
  const rows = data?.resource?.appFields.edges.map(({ node }) => node) ?? [];

  const [updateAppField] = useMutation<{ appFieldUpdate: AppField }, { input: AppFieldUpdateInput }>(APP_FIELD_UPDATE, {
    context: { source: DATALAYER },
    onCompleted: () => {
      notificationVar({
        message: "App field updated!",
        severity: "success",
      });
    },
  });

  const handleOpenEditAppFieldModal = (data: AppField): void => setEditAppField(data);

  // Table sort / pagination
  const columns = useMemo(() => getColumns(handleOpenEditAppFieldModal, timezone, classes), [classes, timezone]);
  const sortModel = sortState ? [sortState] : [];

  const onSortChange = ({ sortModel }) => {
    resetPagination();
    setSortState(sortModel[0]);
  };

  const handleUpdate = (appField: AppField) => {
    setEditAppField(null);

    updateAppField({
      variables: {
        input: {
          id: appField.id,
          value: appField.fieldValue,
        },
      },
    });
  };

  return (
    <FormCard title="App fields">
      <PaginatedTable
        columns={columns}
        defaultContent="No app fields"
        loading={loading}
        onPageChange={onPageChange}
        onSortModelChange={onSortChange}
        page={page}
        pageSize={ROWS_PER_PAGE}
        rows={rows}
        sortModel={sortModel}
        totalCount={totalCount}
      />

      <Dialog
        aria-labelledby="form-dialog-title-edit-app-field"
        className={classes.dialogContainer}
        fullScreen={useMediaQuery(theme.breakpoints.only("xs"))}
        fullWidth
        maxWidth="sm"
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            setEditAppField(null);
          }
        }}
        open={Boolean(editAppField)}
      >
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={() => setEditAppField(null)}
          size="small"
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle id={`form-dialog-title-edit-app-field`}>Edit app field</DialogTitle>
        <DialogContent dividers>
          {editAppField && (
            <AppFieldForm appField={editAppField} onCancel={() => setEditAppField(null)} onUpdate={handleUpdate} />
          )}
        </DialogContent>
      </Dialog>
    </FormCard>
  );
};

export default AppFields;
