/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Product, ProductStockStatus, Variant } from "@arowana/graphql";
import { IMG_NOT_FOUND } from "@arowana/util";
import { Box, Grid, IconButton, makeStyles, Theme, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import { Skeleton } from "@material-ui/lab";
import Head from "next/head";
import Script from "next/script";
import { ChangeEvent, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from "react";
import ReactHtmlParser from "react-html-parser";

import { SupplierContext } from "../context";
import PageHead from "../PageHead";
import ProductImage from "./ProductImage";
import ProductPrice from "./ProductPrice";
import ProductTags from "./ProductTags";

export interface ProductPriceProps {
  hidePrice?: boolean;
  isOnSale: boolean;
  isSingleVariant: boolean;
  originalPrice: number;
  selectedVariant: Variant;
}
export interface VariantSelectProps {
  onVariantChange: (event: ChangeEvent<HTMLInputElement>) => void;
  selectedVariant: Variant;
  variants: Variant[];
}

export interface ProductQuantityCounterProps {
  onChangeQuantity: (cases: number) => void;
  cases: number;
  selectedVariant: Variant;
}
export interface ProductDetailsProps {
  addToCartButton: ({ cases, selectedVariant }: { cases: number; selectedVariant: Variant }) => ReactNode;
  inventory?: (selectedVariant: Variant) => ReactNode;
  onClose?: () => void;
  onTagFilter?: (props: any) => void;
  product: Product;
  productPrice?: (props: ProductPriceProps) => ReactNode;
  productQuantityCounter: (props: ProductQuantityCounterProps) => ReactNode;
  variantSelect?: (props: VariantSelectProps) => ReactNode;
}
interface StyleProps {
  isModal: boolean;
}
const useStyles = makeStyles<Theme, StyleProps>(theme => ({
  closeButton: {
    "&:hover": {
      backgroundColor: theme.palette.background.paper,
    },
    "backgroundColor": theme.palette.background.paper,
    "color": theme.palette.grey[500],
    "position": "absolute",
    "right": theme.spacing(1),
    "top": theme.spacing(1),
    "zIndex": 2,
    [theme.breakpoints.only("xs")]: {
      position: "fixed",
    },
  },
  fieldLoading: {
    borderRadius: theme.shape.borderRadius,
    height: theme.spacing(6),
    width: "100%",
  },
  fullWidth: { width: "100%" },
  root: ({ isModal }) =>
    isModal && {
      overflowX: "hidden",
      position: "relative",
    },
  section: ({ isModal }) => ({
    marginBottom: theme.spacing(2),
    [theme.breakpoints.only("xs")]: {
      "&:first-child": {
        marginTop: theme.spacing(1),
      },
      "padding": isModal ? theme.spacing(0, 2) : 0,
    },
  }),
  tagContainer: {
    padding: theme.spacing(1),
  },
  tagRow: {
    margin: theme.spacing(1, 0),
  },
}));

export const ProductDetails = ({
  addToCartButton,
  inventory,
  onClose,
  onTagFilter,
  product,
  productPrice = ProductPrice,
  productQuantityCounter,
  variantSelect,
}: ProductDetailsProps) => {
  const isModal = typeof onClose === "function";
  const classes = useStyles({ isModal });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const supplierData = useContext(SupplierContext);
  // no padding for mobile OR non modal layout
  const rootPadding = isMobile || !isModal ? 0 : theme.spacing(8, 3, 2);
  const {
    stockStatus,
    category,
    cheapestInStockVariant,
    cheapestVariant,
    description,
    descriptionText,
    displayPrice,
    id,
    identifier,
    images,
    name,
    onSale,
    originalPrice,
    tags,
    variants,
  } = product ?? {};

  const isOnSale = onSale && typeof originalPrice === "number";
  const isSingleVariant = variants?.length === 1;
  const [cases, setCases] = useState<number>(1);
  const [selectedVariantId, setSelectedVariantId] = useState<string>(cheapestInStockVariant?.id || cheapestVariant?.id);
  useEffect(
    () => setSelectedVariantId(cheapestInStockVariant?.id || cheapestVariant?.id),
    [cheapestInStockVariant?.id, cheapestVariant?.id],
  );
  const selectedVariant = useMemo(
    () => variants?.find(({ id }) => id === selectedVariantId),
    [selectedVariantId, variants],
  );
  const formattedDescription = useMemo(() => description && ReactHtmlParser(description), [description]);

  const onVariantChange = (event: React.ChangeEvent<HTMLInputElement>) => setSelectedVariantId(event.target.value);
  const onChangeQuantity = useCallback((cases: number) => setCases(cases), []);

  return (
    <>
      {product && (
        <Head>
          <Script
            dangerouslySetInnerHTML={{
              __html: JSON.stringify({
                "@context": "https://schema.org",
                "@type": "Product",
                "brand": supplierData.name,
                "description": descriptionText?.substring(0, 500),
                "image": images?.[0]?.original ?? IMG_NOT_FOUND,
                "name": name,
                "offers": [
                  {
                    "@type": "Offer",
                    "availability": stockStatus === ProductStockStatus.OUT_OF_STOCK ? "OutOfStock" : "InStock",
                    "itemCondition": "new",
                    "price": (displayPrice / 100).toFixed(2),
                    "priceCurrency": supplierData.currency.toUpperCase(),
                  },
                ],
                "productID": id,
                "url": `${supplierData.url}/products/${id}/${identifier}`,
              }),
            }}
            id="manual-json-ld"
            strategy="afterInteractive"
            type="application/ld+json"
          />
        </Head>
      )}
      <Box className={classes.root} padding={rootPadding}>
        {isModal && (
          <IconButton className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        )}
        {product && (
          <>
            <PageHead description={descriptionText} image={images?.[0]?.original} title={product?.name} />
            <Grid container direction={isMobile ? "column" : "row"} spacing={isMobile ? 0 : 2}>
              <ProductImage isMobile={isMobile} product={product} />
              <Grid container item sm={6} alignContent="flex-start">
                <Grid className={classes.section} container item spacing={1}>
                  <Grid item sm={7} xs={12}>
                    <Typography variant="h6">{name}</Typography>
                    <Typography color="textSecondary" gutterBottom={Boolean(inventory)} variant="body2">
                      {selectedVariant ? selectedVariant.name : <Skeleton variant="text" />}
                    </Typography>
                    {Boolean(inventory) && (selectedVariant ? inventory(selectedVariant) : <Skeleton variant="text" />)}
                  </Grid>
                  <Grid container item sm={5} xs={12} justifyContent="flex-end">
                    {selectedVariant ? (
                      productPrice({ isOnSale, isSingleVariant, originalPrice, selectedVariant })
                    ) : (
                      <Typography variant="h5" className={classes.fullWidth}>
                        <Skeleton variant="text" />
                      </Typography>
                    )}
                  </Grid>
                </Grid>
                {!isSingleVariant && (
                  <Grid className={classes.section} container item>
                    {selectedVariant ? (
                      variantSelect && variantSelect({ onVariantChange, selectedVariant, variants })
                    ) : (
                      <Skeleton className={classes.fieldLoading} variant="rect" />
                    )}
                  </Grid>
                )}
                <Grid className={classes.section} container item spacing={1}>
                  <Grid item xs={12} md={6}>
                    {selectedVariant ? (
                      productQuantityCounter({ cases, onChangeQuantity, selectedVariant })
                    ) : (
                      <Skeleton className={classes.fieldLoading} variant="rect" />
                    )}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    {selectedVariant ? (
                      addToCartButton({ cases, selectedVariant })
                    ) : (
                      <Skeleton className={classes.fieldLoading} variant="rect" />
                    )}
                  </Grid>
                </Grid>
                <Grid alignItems="flex-start" className={classes.tagRow} container justifyContent="space-between">
                  <Grid className={classes.tagContainer} container item wrap="wrap" xs={10}>
                    <ProductTags category={category} onTagFilter={onTagFilter} tags={tags} />
                  </Grid>
                </Grid>
                <Grid className={classes.section} item>
                  <Typography className="ql-content" component="div" variant="body2">
                    {formattedDescription ?? descriptionText}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </Box>
    </>
  );
};

export default ProductDetails;
