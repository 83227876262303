import { OrderAudience } from "@arowana/graphql";
import { QuickSearch } from "@arowana/ui";
import { ListItemText } from "@material-ui/core";
import { useContext } from "react";

import { AccountContext } from "../../context/AccountContext";

export interface RecurringOrderListSearchResult {
  description: string;
  id: string;
  name: string;
  slug: string;
  supplier_id: string;
}

interface RecurringOrderListSearchProps {
  className?: string;
  label?: string;
  onChange: (recurringOrderList: { id: string }) => void;
  placeholder?: string;
}

const RecurringOrderListSearch = ({
  className,
  label = "Search",
  onChange,
  placeholder = "Search here...",
  audience = OrderAudience.MARKETPLACE,
}: RecurringOrderListSearchProps) => {
  const { supplier } = useContext(AccountContext);

  return (
    <QuickSearch
      className={className}
      fullWidth
      getOptionLabel={(option: RecurringOrderListSearchResult) => option?.name}
      label={label}
      index="recurring_order_lists"
      onChange={onChange}
      placeholder={placeholder}
      renderOption={({ id, description, name }) => <ListItemText key={id} primary={name} secondary={description} />}
      searchParams={{
        filter: [`supplier_id = "${supplier.id}" AND audience = "${audience}"`],
        limit: 10,
      }}
      supplierId={supplier.id}
    />
  );
};

export default RecurringOrderListSearch;
