import { gql, useMutation, useQuery } from "@apollo/client";
import {
  FEATURE_SUPPLIER_ADD_ACCOUNT_TO_BUSINESS,
  FEATURE_SUPPLIER_CREATE_WHOLESALE_ORDER,
  VALUE_B2B_MAX_PAYMENT_METHODS,
} from "@arowana/flags";
import { AppFieldResourceType, OrdersSortField } from "@arowana/graphql";
import { Dollar, PaginatedTable, PaymentMethodSection, usePagination } from "@arowana/ui";
import { DATALAYER } from "@arowana/util";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  List,
  ListItemText,
  makeStyles,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import { GridAlignment, GridSortItem } from "@material-ui/data-grid";
import { FileCopyOutlined, OpenInNewRounded } from "@material-ui/icons";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Autocomplete } from "@material-ui/lab";
import Alert from "@material-ui/lab/Alert";
import clsx from "clsx";
import moment from "moment";
import { useCallback, useContext, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Link as RouterLink } from "react-router-dom";
import { useDebounce } from "use-debounce";

import { notificationVar } from "../../../cache/notificationPolicy";
import AppFields from "../../../components/AppFields";
import DropdownSelect from "../../../components/DropdownSelect";
import FormCard from "../../../components/FormCard";
import Loader from "../../../components/Loader";
import NetTerms from "../../../components/NetTerms";
import PageHeader from "../../../components/PageHeader";
import Routes from "../../../Constants/Routes";
import SwitchToggle from "../../account/components/SwitchToggle";
import { AccountContext } from "../../context/AccountContext";
import { FlagsmithContext } from "../../context/FlagsmithContext";
import Statuses from "../../orders/constants/orderStatus";
import AccountAutocomplete, { AccountSearch } from "../components/AccountAutocomplete";
import ClientAddressItem from "../components/ClientAddressItem";

const CLIENT_FRAGMENT = gql`
  fragment ClientFragment on Client {
    id
    archivedAt
    netTerms
    name
    notes
    phone
    address {
      id
      address1
      address2
      sublocality
      city
      country
      formattedAddress
      label
      notes
      postalCode
      region
    }
    active
    accounts {
      id
      name
      email
    }
    owner {
      id
      name
      email
    }
    paymentMethods {
      id
      card {
        brand
        expiryMonth
        expiryYear
        last4
      }
    }
    repAccountId
    wholesaleList {
      id
      name
    }
  }
`;

const CLIENT = gql`
  query Client($id: ID!, $page: PaginationInput!, $sort: OrdersSortInput) {
    client(id: $id) {
      ...ClientFragment
      orders(sort: $sort, page: $page) {
        edges {
          cursor
          node {
            id
            fulfillmentDate
            invoiceNumber
            status
            total
          }
        }
        pageInfo {
          count
          endCursor
          hasNextPage
          totalCount
        }
      }
    }
  }
  ${CLIENT_FRAGMENT}
`;

const WHOLESALE_LISTS = gql`
  query WholesaleLists($filter: WholesaleListsFilterInput, $sort: WholesaleListsSortInput, $page: PaginationInput) {
    currentSupplier {
      id
      wholesaleLists(filter: $filter, sort: $sort, page: $page) {
        edges {
          node {
            id
            name
          }
        }
      }
      wholesalePaymentSettings {
        creditCardEnabled
      }
    }
  }
`;

const CLIENT_UPDATE = gql`
  mutation ClientUpdate($input: ClientUpdateInput!) {
    clientUpdate(input: $input) {
      ...ClientFragment
    }
  }
  ${CLIENT_FRAGMENT}
`;

const CLIENT_ADD_ACCOUNT = gql`
  mutation ClientAddAccount($clientId: ID!, $accountId: ID!) {
    clientAddAccount(clientId: $clientId, accountId: $accountId) {
      id
      accounts {
        id
        name
        email
      }
    }
  }
`;

const CLIENT_DELETE_ACCOUNT = gql`
  mutation ClientDeleteAccount($clientId: ID!, $accountId: ID!) {
    clientDeleteAccount(clientId: $clientId, accountId: $accountId) {
      id
      accounts {
        id
        name
        email
      }
    }
  }
`;

const CLIENT_ARCHIVE = gql`
  mutation ClientArchive($id: ID!) {
    clientArchive(clientIds: [$id])
  }
`;

const CLIENT_UNARCHIVE = gql`
  mutation ClientUnrchive($id: ID!) {
    clientUnarchive(clientIds: [$id])
  }
`;

// Payments
const PAYMENT_SETUP_INTENT = gql`
  mutation ClientSetupIntent($input: ClientPaymentSetupIntentInput!) {
    clientPaymentSetupIntent(input: $input) {
      clientSecret
    }
  }
`;

const PAYMENT_CREATE = gql`
  mutation ClientCreatePaymentMethod($input: ClientPaymentMethodCreateInput!) {
    clientPaymentMethodCreate(input: $input) {
      id
      card {
        brand
        expiryMonth
        expiryYear
        last4
      }
    }
  }
`;

const PAYMENT_DELETE = gql`
  mutation ClientDeletePaymentMethod($input: ClientPaymentMethodDeleteInput!) {
    clientPaymentMethodDelete(input: $input) {
      id
      card {
        brand
        expiryMonth
        expiryYear
        last4
      }
    }
  }
`;

const GENERATE_MAGIC_SIGN_IN_LINK = gql`
  mutation GenerateWholesaleMagicSignInLink($clientId: ID!, $accountId: ID!) {
    url: generateMagicSignInLinkForWholesale(clientId: $clientId, accountId: $accountId)
  }
`;

const createdPaymentmethodFn = data => data?.clientPaymentMethodCreate;

const clientSecretFn = data => data?.clientPaymentSetupIntent?.clientSecret;

const onPaymentCompleted = operation => {
  let notification;

  if (operation === "create") {
    notification = {
      message: "Payment method added!",
      severity: "success",
    };
  } else if (operation === "delete") {
    notification = {
      message: "Payment method removed!",
      severity: "success",
    };
  }

  notification && notificationVar(notification);
};

const onPaymentError = operation => {
  notificationVar({
    message: `Unable to ${operation} payment for client`,
    severity: "error",
  });
};

const useStyles = makeStyles(theme => ({
  accessToggler: {
    marginLeft: theme.spacing(-1),
    marginTop: theme.spacing(2),
  },
  account: {
    "&:not(:last-child)": {
      marginBottom: theme.spacing(2),
    },
  },
  actions: {
    "& > :not(:last-child)": {
      marginRight: theme.spacing(2),
    },
    "display": "flex",
    "justifyContent": "flex-end",
    "marginTop": theme.spacing(2),
  },
  spaceBetween: {
    display: "flex",
    justifyContent: "space-between",
  },
  spacing: {
    marginBottom: theme.spacing(2),
  },
}));

const ROWS_PER_PAGE = 20;

const getColumns = ({ timezone }) => [
  {
    field: "id",
    hide: true,
  },
  {
    field: OrdersSortField.INVOICE_NUMBER,
    headerName: "Order #",
    renderCell: param => (
      <Link component={RouterLink} to={Routes.ORDER_DETAILS.replace(":id", param.row.id)}>
        {param.value}
      </Link>
    ),
    sortable: true,
    valueGetter: param => param.row.invoiceNumber,
    width: 120,
  },
  {
    field: "status",
    headerName: "Status",
    sortable: false,
    valueGetter: param => Statuses.find(status => status.id === param.value)?.label,
    width: 140,
  },
  {
    field: OrdersSortField.FULFILLMENT_DATE,
    headerName: "Fulfillment date",
    sortable: true,
    valueGetter: param => moment.tz(param.row.fulfillmentDate, timezone).format("L"),
    width: 190,
  },
  {
    align: "right" as GridAlignment,
    field: "total",
    headerName: "Total",
    renderCell: param => <Dollar amount={param.value} />,
    sortable: false,
    type: "number",
  },
];

const defaultSortState: GridSortItem = {
  field: OrdersSortField.INVOICE_NUMBER,
  sort: "desc",
};

export const NilID = "000000000000000000000000";

const NO_PRICE_LIST = {
  label: "No price list",
  value: NilID,
};

const NO_REP_ACCOUNT = {
  label: "No rep account",
  value: NilID,
};

const Client = ({ match, history }) => {
  const classes = useStyles();
  const flagsmith = useContext(FlagsmithContext);
  const hasWholesale = flagsmith.hasFeature("b2b");
  const maxPaymentMethods = flagsmith.getValue(VALUE_B2B_MAX_PAYMENT_METHODS);
  const hasWholesaleOrderCreate = flagsmith.hasFeature(FEATURE_SUPPLIER_CREATE_WHOLESALE_ORDER);
  const hasAddAccountToBusiness = flagsmith.hasFeature(FEATURE_SUPPLIER_ADD_ACCOUNT_TO_BUSINESS);

  if (!hasWholesale) {
    history.push(Routes.PRODUCT_LIST);
  }

  const { id } = match.params;

  if (!id) {
    history.push(Routes.WHOLESALE_CLIENTS);
  }

  const { supplier } = useContext(AccountContext);
  const { timezone } = supplier;

  const [updatingNetTerms, setUpdatingNetTerms] = useState(false);
  const [sortState, setSortState] = useState(defaultSortState);
  const { setPageInfo, resetPagination, pagination, page, onPageChange } = usePagination(ROWS_PER_PAGE);
  const {
    control,
    errors,
    formState: { dirtyFields, isDirty },
    handleSubmit,
    register,
    reset,
  } = useForm({
    defaultValues: {
      active: false,
      name: "",
      notes: "",
      phone: "",
      repAccountId: "",
      wholesaleList: null,
    },
  });

  const { data, loading } = useQuery(CLIENT, {
    context: { source: DATALAYER },
    fetchPolicy: "cache-and-network",
    onCompleted: ({ client }) => {
      reset({
        active: client.active,
        name: client.name,
        notes: client.notes,
        phone: client.phone,
        repAccountId: client.repAccountId ?? null,
        wholesaleList: client.wholesaleList
          ? {
              label: client.wholesaleList.name,
              value: client.wholesaleList.id,
            }
          : null,
      });

      setPageInfo(client.orders.pageInfo);
    },
    onError: () => history.push(Routes.WHOLESALE_CLIENTS),
    variables: {
      id,
      page: pagination,
      sort: sortState && {
        field: sortState.field,
        order: sortState.sort === "desc" ? -1 : 1,
      },
    },
  });

  const [wholesaleListQuery, setWholesaleListQuery] = useState("");
  const [dbWholesaleListQuery] = useDebounce(wholesaleListQuery, 300);

  const { data: wholesaleListData, loading: wholesaleListLoading } = useQuery(WHOLESALE_LISTS, {
    context: { source: DATALAYER },
    variables: {
      filter: { query: dbWholesaleListQuery === NO_PRICE_LIST.label ? "" : dbWholesaleListQuery },
      page: { first: 50 },
    },
  });

  const creditCardEnabled = wholesaleListData?.currentSupplier?.wholesalePaymentSettings?.creditCardEnabled;

  const wholesaleListsOptions = useMemo(() => {
    const options =
      wholesaleListData?.currentSupplier?.wholesaleLists?.edges?.map(({ node: { id, name } }) => ({
        label: name,
        value: id,
      })) ?? [];

    return [NO_PRICE_LIST, ...options];
  }, [wholesaleListData?.currentSupplier?.wholesaleLists?.edges]);

  const repAccountOptions = useMemo(() => {
    const options =
      supplier?.accounts?.map(supplierAccount => {
        const {
          account: { id, email, name },
        } = supplierAccount;

        return {
          label: <ListItemText primary={name} secondary={email} />,
          value: id,
        };
      }) ?? [];

    return [{ ...NO_REP_ACCOUNT, label: <>{NO_REP_ACCOUNT.label}</> }, ...options];
  }, []);

  const [clientUpdate, { loading: clientUpdateLoading }] = useMutation(CLIENT_UPDATE, {
    context: { source: DATALAYER },
    onCompleted: () => {
      notificationVar({
        message: "Business updated!",
        severity: "success",
      });
    },
    refetchQueries: ["Client", "Clients"],
  });

  const [clientAddAccount, { loading: clientAddAccountLoading }] = useMutation(CLIENT_ADD_ACCOUNT, {
    context: { source: DATALAYER },
    onCompleted: () => {
      notificationVar({
        message: "Account added to business!",
        severity: "success",
      });
    },
    refetchQueries: ["Client"],
  });

  const [clientDeleteAccount, { loading: clientDeleteAccountLoading }] = useMutation(CLIENT_DELETE_ACCOUNT, {
    context: { source: DATALAYER },
    onCompleted: () => {
      notificationVar({
        message: "Account removed from business!",
        severity: "success",
      });
    },
    refetchQueries: ["Client"],
  });

  const [clientArchive, { loading: loadingClientArchive }] = useMutation(CLIENT_ARCHIVE, {
    context: { source: DATALAYER },
    onCompleted: () => {
      history.push(Routes.WHOLESALE_CLIENTS);
      notificationVar({
        message: "Business archived!",
        severity: "success",
      });
    },
  });

  const [clientUnarchive, { loading: loadingClientUnarchive }] = useMutation(CLIENT_UNARCHIVE, {
    context: { source: DATALAYER },
    onCompleted: () => {
      notificationVar({
        message: "Business unarchived!",
        severity: "success",
      });
    },
    refetchQueries: ["Client"],
  });

  const clientApproved = useMemo(() => Boolean(data?.client?.active), [data]);
  const totalCount = data?.client?.orders.pageInfo.totalCount ?? 0;
  const rows = data?.client?.orders.edges.map(({ node }) => node) ?? [];
  const columns = useMemo(() => getColumns({ timezone }), [timezone]);
  const sortModel = sortState ? [sortState] : [];

  const onSortChange = ({ sortModel }) => {
    resetPagination();
    setSortState(sortModel[0]);
  };

  const handleDiscardClick = () => reset();

  const onSubmit = data => {
    clientUpdate({
      variables: {
        input: {
          id,
          ...(dirtyFields.active && { active: data.active }),
          ...(dirtyFields.name && { name: data.name }),
          ...(dirtyFields.notes && { notes: data.notes }),
          ...(dirtyFields.phone && { phone: data.phone }),
          ...(dirtyFields.wholesaleList && !data.wholesaleList?.value
            ? { wholesaleListId: NO_PRICE_LIST.value }
            : { wholesaleListId: data.wholesaleList?.value }),
          ...(dirtyFields.repAccountId && (!data.repAccountId || data.repAccountId === NO_REP_ACCOUNT.value)
            ? { _removeRepAccountId: true }
            : { repAccountId: data.repAccountId }),
        },
      },
    });
  };

  // Payment Func
  const setupVariables = useMemo(() => ({ input: { clientId: id } }), [id]);
  const createVariablesFn = useCallback(paymentMethodId => ({ input: { clientId: id, paymentMethodId } }), [id]);
  const deleteVariablesFn = useCallback(
    paymentId => ({ input: { clientId: id, paymentId, reason: "supplier update for client" } }),
    [id],
  );

  // NetTerms Func
  const handleChangeNetTerms = (netTerms: number) => {
    setUpdatingNetTerms(true);

    clientUpdate({
      variables: {
        input: {
          id,
          netTerms,
        },
      },
    }).finally(() => setUpdatingNetTerms(false));
  };

  const [accountMenuId, setAccountMenuId] = useState(null);
  const [accountMenuAnchor, setAccountMenuAnchor] = useState(null);
  const handleAccountMenuOpen = (accountId: string) => event => {
    setAccountMenuId(accountId);
    setAccountMenuAnchor(event.currentTarget);
  };
  const handleAccountMenuClose = () => {
    setAccountMenuId(null);
    setAccountMenuAnchor(null);
  };
  const accountMenuOpen = (accountId: string) => {
    return Boolean(accountMenuAnchor) && accountId == accountMenuId;
  };

  const [magicSignInDialogOpen, setMagicSignInLinkDialogOpen] = useState(false);
  const [magicSignInLink, setMagicSignInLink] = useState(null);
  const [genAuthLink, { loading: genAuthLinkLoading }] = useMutation(GENERATE_MAGIC_SIGN_IN_LINK, {
    context: { source: DATALAYER },
  });

  const handleAccountMagicSignInLink = (accountId: string) => {
    genAuthLink({
      onCompleted(data) {
        setMagicSignInLink(data?.url);
      },
      variables: {
        accountId,
        clientId: id,
      },
    });
    setMagicSignInLinkDialogOpen(true);
  };
  const handleMagicSignLinkDialogClose = () => {
    setMagicSignInLink(null);
    setMagicSignInLinkDialogOpen(false);
  };
  const handleCopyMagicSignInLink = () => {
    if (magicSignInLink) {
      navigator.clipboard.writeText(magicSignInLink).then(() =>
        notificationVar({
          duration: 2000,
          message: "Link copied!",
          severity: "success",
        }),
      );
    }
  };

  const handleAddAccount = (accountId: string) =>
    clientAddAccount({
      variables: {
        accountId,
        clientId: id,
      },
    });

  const handleDeleteAccount = (accountId: string) =>
    clientDeleteAccount({
      variables: {
        accountId,
        clientId: id,
      },
    });

  const handleArchiveClient = () => {
    clientArchive({
      variables: {
        id: id,
      },
    });
  };

  const handleUnarchiveClient = () => {
    clientUnarchive({
      variables: {
        id: id,
      },
    });
  };

  let archiveUnarchiveButton = null;

  if (data?.client) {
    if (data.client.archivedAt) {
      archiveUnarchiveButton = (
        <Button variant="outlined" onClick={handleUnarchiveClient}>
          Unarchive
        </Button>
      );
    } else {
      archiveUnarchiveButton = (
        <Button variant="outlined" onClick={handleArchiveClient}>
          Archive
        </Button>
      );
    }
  }

  return (
    <>
      <Loader loading={loading} />

      <PageHeader
        stickyHeader
        title={data?.client?.name ?? "Wholesale client"}
        primaryActions={
          <>
            {archiveUnarchiveButton}
            <Button color="primary" disabled={!isDirty} onClick={handleDiscardClick} variant="outlined">
              Discard
            </Button>
            <Button
              color="primary"
              disabled={!isDirty || clientUpdateLoading}
              onClick={handleSubmit(onSubmit)}
              variant="contained"
            >
              Save
            </Button>
          </>
        }
      />
      {!loading && !clientApproved && (
        <Alert severity="warning" className={classes.spacing}>
          This client has not yet been approved to access your wholesale store.
        </Alert>
      )}
      <Grid container spacing={2}>
        <Grid item md={8} xs={12}>
          <FormCard title="Business info">
            <FormControl fullWidth margin="none">
              <FormLabel htmlFor="name">Business name</FormLabel>
              <TextField
                autoComplete="off"
                error={Boolean(errors?.name)}
                fullWidth
                helperText={errors?.name?.message}
                id="name"
                inputRef={register({ required: "*required" })}
                margin="dense"
                name="name"
                variant="outlined"
              />
            </FormControl>

            <FormControl fullWidth margin="normal">
              <FormLabel htmlFor="phone">Phone number</FormLabel>
              <TextField
                autoComplete="off"
                error={Boolean(errors?.phone)}
                fullWidth
                helperText={errors?.phone?.message}
                id="phone"
                inputRef={register({ required: "*required" })}
                margin="dense"
                name="phone"
                type="tel"
                variant="outlined"
              />
            </FormControl>

            <FormControl fullWidth margin="none" className={classes.spacing}>
              <FormLabel>Price list</FormLabel>
              <Controller
                id="wholesaleList"
                name="wholesaleList"
                control={control}
                render={({ onChange, value }) => (
                  <Autocomplete
                    value={value}
                    onChange={(_, newValue, reason) => {
                      if (reason === "select-option") {
                        onChange(newValue);
                      } else if (reason === "clear") {
                        onChange(NO_PRICE_LIST.value);
                      }
                    }}
                    noOptionsText={wholesaleListLoading ? "Searching..." : "No price lists found"}
                    options={wholesaleListsOptions}
                    getOptionLabel={option => option.label}
                    getOptionSelected={(option, value) => option.value == value.value}
                    inputValue={wholesaleListQuery}
                    onInputChange={(_, newInputValue) => setWholesaleListQuery(newInputValue)}
                    renderInput={params => (
                      <TextField
                        {...params}
                        margin="dense"
                        helperText="Search for a wholesale price list"
                        placeholder={NO_PRICE_LIST.label}
                        variant="outlined"
                      />
                    )}
                    renderOption={({ label, value }) => (
                      <Box key={value} display="flex" width="100%" justifyContent="space-between">
                        <ListItemText primary={label} />
                        {value != NilID && (
                          <IconButton
                            title="View price list"
                            size="small"
                            edge="end"
                            aria-label="View price list"
                            component={RouterLink}
                            to={Routes.WHOLESALE_LIST_DETAILS.replace(":id", value)}
                            target="_blank"
                          >
                            <OpenInNewRounded />
                          </IconButton>
                        )}
                      </Box>
                    )}
                  />
                )}
              />
            </FormControl>

            <FormControl fullWidth margin="none">
              <Controller
                as={<DropdownSelect />}
                control={control}
                autoWidth={false}
                dropdownMargin="dense"
                helperText={errors?.repAccountId?.message}
                id="rep-account"
                label={<FormLabel htmlFor="rep-account">Rep account</FormLabel>}
                name="repAccountId"
                options={repAccountOptions}
              />
            </FormControl>

            <FormControl fullWidth margin="none">
              <Controller
                as={<SwitchToggle />}
                className={classes.accessToggler}
                component={<Checkbox color="primary" />}
                control={control}
                defaultValue={false}
                id="active"
                label={
                  <>
                    <FormLabel htmlFor="active">Access</FormLabel>
                    <Typography variant="caption" component="p">
                      Disabling access will prevent the client from viewing prices or creating orders.
                    </Typography>
                  </>
                }
                labelPlacement="end"
                name="active"
                type="checkbox"
              />
            </FormControl>
          </FormCard>

          <FormCard
            title="Orders"
            action={
              hasWholesaleOrderCreate ? (
                <Button
                  color="primary"
                  component={RouterLink}
                  size="small"
                  variant="outlined"
                  to={`${Routes.ORDER_CREATE_WHOLESALE}?clientId=${id}`}
                >
                  Create Order
                </Button>
              ) : null
            }
          >
            <PaginatedTable
              columns={columns}
              defaultContent="No orders"
              loading={loading}
              onPageChange={onPageChange}
              onSortModelChange={onSortChange}
              page={page}
              pageSize={ROWS_PER_PAGE}
              rows={rows}
              sortModel={sortModel}
              totalCount={totalCount}
            />
          </FormCard>

          <AppFields id={id} resourceType={AppFieldResourceType.client} />
        </Grid>

        <Grid item md={4} xs={12}>
          <FormCard title="Address">
            <List disablePadding>
              <ClientAddressItem address={data?.client?.address ?? {}} clientId={id} />
            </List>
          </FormCard>

          <FormCard title="Accounts">
            {hasAddAccountToBusiness ? (
              <>
                <AccountAutocomplete
                  className={classes.spacing}
                  disabled={clientAddAccountLoading}
                  filterIds={new Set(data?.client?.accounts?.map(({ id }) => id) ?? [])}
                  label="Add access"
                  onChange={(account: AccountSearch) => handleAddAccount(account.id)}
                />
                {data?.client?.accounts.map(({ id, name, email }) => (
                  <Box className={clsx(classes.account, classes.spaceBetween)} key={id}>
                    <Box>
                      <Link component={RouterLink} to={`/customers/${id}`}>
                        <Typography>{name}</Typography>
                      </Link>
                      <Typography>{email}</Typography>
                    </Box>

                    <IconButton
                      aria-label="more"
                      aria-controls="long-menu"
                      aria-haspopup="true"
                      onClick={handleAccountMenuOpen(id)}
                    >
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      id={`account-menu-${id}`}
                      anchorEl={accountMenuAnchor}
                      keepMounted
                      open={accountMenuOpen(id)}
                      onClose={handleAccountMenuClose}
                    >
                      <MenuItem
                        onClick={() => {
                          handleAccountMenuClose();
                          handleAccountMagicSignInLink(id);
                        }}
                      >
                        Create Magic Sign-In Link
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          handleAccountMenuClose();
                          handleDeleteAccount(id);
                        }}
                      >
                        <Typography color="secondary">Remove Account</Typography>
                      </MenuItem>
                    </Menu>
                  </Box>
                ))}
              </>
            ) : (
              data?.client?.accounts.map(({ id, name, email }) => (
                <Box className={classes.account} key={id}>
                  <Link component={RouterLink} to={`/customers/${id}`}>
                    <Typography>{name}</Typography>
                  </Link>
                  <Typography>{email}</Typography>
                </Box>
              ))
            )}
          </FormCard>

          <FormCard title="Net terms">
            <NetTerms
              fullWidth
              loading={updatingNetTerms}
              onChange={handleChangeNetTerms}
              value={data?.client?.netTerms ?? ""}
            />
          </FormCard>

          <FormCard title="Notes">
            <Typography variant="body2" gutterBottom>
              These notes are private and will not be seen by the business.
            </Typography>
            <FormControl fullWidth margin="none">
              <TextField
                autoComplete="off"
                error={Boolean(errors?.notes)}
                fullWidth
                helperText={errors?.notes?.message}
                id="notes"
                inputRef={register()}
                margin="dense"
                multiline
                rows={4}
                name="notes"
                variant="outlined"
              />
            </FormControl>
          </FormCard>

          {creditCardEnabled && (
            <FormCard title="Payment methods">
              <PaymentMethodSection
                defaultMessageAlign="inherit"
                maxPaymentMethods={maxPaymentMethods as number}
                loading={loading}
                paymentMethods={data?.client?.paymentMethods}
                cacheQuery={CLIENT}
                cacheDataField="client"
                cacheQueryVariables={{
                  id,
                  page: pagination,
                  sort: sortState && {
                    field: sortState.field,
                    order: sortState.sort === "desc" ? -1 : 1,
                  },
                }}
                createdPaymentmethodFn={createdPaymentmethodFn}
                setupMutation={PAYMENT_SETUP_INTENT}
                setupVariables={setupVariables}
                createMutation={PAYMENT_CREATE}
                createVariablesFn={createVariablesFn}
                deleteMutation={PAYMENT_DELETE}
                deleteVariablesFn={deleteVariablesFn}
                onCompleted={onPaymentCompleted}
                onError={onPaymentError}
                clientSecretFn={clientSecretFn}
              />
            </FormCard>
          )}
        </Grid>
      </Grid>

      <Dialog
        open={magicSignInDialogOpen}
        onClose={handleMagicSignLinkDialogClose}
        aria-labelledby="magic-sign-in-dialog-title"
        aria-describedby="magic-sign-in-dialog-description"
      >
        <DialogTitle id="magic-sign-in-dialog-title">Magic sign-in link</DialogTitle>
        <DialogContent>
          <DialogContentText id="magic-sign-in-dialog-description">
            The link below will automatically log in the account to your wholesale store. <strong>DO NOT</strong> share
            this link publicly.
          </DialogContentText>
          <TextField
            id="magic-sign-in-link"
            type="text"
            aria-disabled="true"
            fullWidth
            variant="outlined"
            value={magicSignInLink}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {magicSignInLink ? (
                    <Button
                      color="primary"
                      variant="outlined"
                      startIcon={<FileCopyOutlined />}
                      size="small"
                      onClick={handleCopyMagicSignInLink}
                    >
                      Copy
                    </Button>
                  ) : (
                    <CircularProgress color="inherit" size={24} />
                  )}
                </InputAdornment>
              ),
            }}
            inputProps={{
              onFocus: event => event.target.select(),
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleMagicSignLinkDialogClose} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Client;
