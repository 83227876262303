import { gql, useMutation, useQuery } from "@apollo/client";
import { ProductsSortField } from "@arowana/graphql";
import { Dollar, PaginatedTable, usePagination } from "@arowana/ui";
import { DATALAYER, unitLabel } from "@arowana/util";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  Link,
  makeStyles,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { GridSortItem } from "@material-ui/data-grid";
import {
  Assignment as AssignmentIcon,
  DeleteOutline as DeleteOutlineIcon,
  Edit as EditIcon,
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
} from "@material-ui/icons/";
import moment from "moment-timezone";
import pluralize from "pluralize";
import qs from "qs";
import { useContext, useMemo, useState } from "react";
import { Link as RouterLink, RouteComponentProps } from "react-router-dom";

import { notificationVar } from "../../../cache/notificationPolicy";
import ConfirmationModal from "../../../components/ConfirmationModal";
import PageHeader from "../../../components/PageHeader";
import Routes from "../../../Constants/Routes";
import useExportResource from "../../../hooks/useExportResource";
import { ListAddProducts, ListRemoveProducts } from "../../../mutations/List";
import { ProductDeleteMany, ProductUpdateMany } from "../../../queries/products";
import SupplierProductVariants from "../../../queries/supplierProductVariants";
import { AccountContext } from "../../context/AccountContext";
import CategoryTagsForm from "../components/CategoryTagsForm";
import DeliveryScheduleForm from "../components/DeliveryScheduleForm";
import ProductSearch from "../components/ProductSearch";
import QuickEditForm from "../components/QuickEditForm";

const EXPORT_PRODUCTS = gql`
  mutation ExportProducts {
    url: exportSupplierProducts
  }
`;

const BULK_UPDATE_VARIABLES = gql`
  query BulkUpdateVariables {
    categories {
      id
      name
      order
      tags {
        value
        label
      }
    }
    currentSupplier {
      taxableProductsList {
        id
      }
    }
  }
`;

enum ModalType {
  AddToTaxableList = "addToTaxableList",
  BulkActivate = "display",
  BulkDeactivate = "hide",
  BulkDelete = "delete",
  BulkSetInStock = "inStock",
  BulkSetOutOfStock = "outOfStock",
  BulkUpdateCategoryTags = "categoryTags",
  BulkUpdateDeliveryDays = "deliveryDays",
  QuickUpdate = "quickEdits",
  RemoveFromTaxableList = "removeFromTaxableList",
}

type ModalDescription = {
  title: string;
  type: ModalType;
  confirmText?: string;
  note?: string;
  content?: string;
  isDangerAction?: boolean;
};

type ModalList = {
  [type in ModalType]: ModalDescription;
};

const modals: ModalList = {
  [ModalType.AddToTaxableList]: {
    confirmText: "Add",
    content: "Add product(s) to taxable collection?",
    note: "Note: Tax will be collected on these products when users purchase them.",
    title: "Add to Taxable Collection",
    type: ModalType.AddToTaxableList,
  },
  [ModalType.BulkUpdateCategoryTags]: {
    title: "Edit categories and tags",
    type: ModalType.BulkUpdateCategoryTags,
  },
  [ModalType.BulkDelete]: {
    confirmText: "Delete",
    content: "Are you sure you want to delete the selected product(s)?",
    isDangerAction: true,
    note: "Note: This action cannot be reversed.",
    title: "Delete Products",
    type: ModalType.BulkDelete,
  },
  [ModalType.BulkUpdateDeliveryDays]: {
    title: "Edit Fulfillment Schedule",
    type: ModalType.BulkUpdateDeliveryDays,
  },
  [ModalType.BulkActivate]: {
    confirmText: "Display",
    content: "Are you sure you want to display the selected product(s)?",
    note: "Note: Item will be available on the store.",
    title: "Display Products",
    type: ModalType.BulkActivate,
  },
  [ModalType.BulkDeactivate]: {
    confirmText: "Hide",
    content: "Are you sure you want to hide the selected product(s)?",
    note: "Note: Item won't be purchasable.",
    title: "Hide Products",
    type: ModalType.BulkDeactivate,
  },
  [ModalType.QuickUpdate]: {
    title: "Quick edits",
    type: ModalType.QuickUpdate,
  },
  [ModalType.RemoveFromTaxableList]: {
    confirmText: "Remove",
    content: "Remove product(s) from taxable collection?",
    note: "Note: Tax will NOT be collected on these products when users purchase them.",
    title: "Remove from Taxable Collection",
    type: ModalType.RemoveFromTaxableList,
  },
  [ModalType.BulkSetInStock]: {
    content: "The selected products will be available for purchase if the visibility is active.",
    title: "Set in stock",
    type: ModalType.BulkSetInStock,
  },
  [ModalType.BulkSetOutOfStock]: {
    content: "The selected products will be unavailable for purchase.",
    title: "Set out of stock",
    type: ModalType.BulkSetOutOfStock,
  },
};

type BulkOption = {
  description: string;
  name: string;
  type: ModalType;
};

const bulkOptions: BulkOption[] = [
  {
    description: "Change service dates, order lead times",
    name: "Edit fulfillment schedule",
    type: ModalType.BulkUpdateDeliveryDays,
  },
  {
    description: "Adjust categories and tags for selected items",
    name: "Edit categories and tags",
    type: ModalType.BulkUpdateCategoryTags,
  },
  {
    description: "Ensure selected items are shown on display",
    name: "Display on store",
    type: ModalType.BulkActivate,
  },
  {
    description: "Ensure selected items are hidden from display",
    name: "Hide from store",
    type: ModalType.BulkDeactivate,
  },
  {
    description: "Set the selected items' status as in stock",
    name: "Set in stock",
    type: ModalType.BulkSetInStock,
  },
  {
    description: "Set the selected items' status as out of stock",
    name: "Set out of stock",
    type: ModalType.BulkSetOutOfStock,
  },
  {
    description: "Automatically apply taxes to selected items",
    name: "Add to taxable collection",
    type: ModalType.AddToTaxableList,
  },
  {
    description: "Make selected items non-taxable",
    name: "Remove from taxable collection",
    type: ModalType.RemoveFromTaxableList,
  },
];

const useStyles = makeStyles(theme => ({
  actionButtons: {
    textAlign: "right",
    [theme.breakpoints.down("sm")]: {
      textAlign: "left",
    },
  },
  actionsContainer: {
    marginBottom: theme.spacing(1),
  },
  bulkActionItem: {
    display: "block",
  },
  bulkActionMenu: {
    marginTop: theme.spacing(7),
  },
  bulkActionTitle: {
    padding: theme.spacing(0, 2, 1, 2),
  },
}));

const DISPLAY_STATUS = {
  IN_STOCK: "In Stock",
  MIXED_STOCK: "Mixed Stock",
  OUT_OF_STOCK: "Out of Stock",
};

const getColumns = query => [
  {
    field: "id",
    hide: true,
  },
  {
    field: ProductsSortField.NAME,
    headerName: "Product",
    renderCell: param => (
      <Link component={RouterLink} to={Routes.PRODUCT.replace(":id", param.row.id)}>
        <Typography gutterBottom variant="body2">
          {param.row.name}
        </Typography>
        <Typography component="p" variant="caption">
          {param.row.variants.length === 1 ? param.row.subName : "Multiple variants"}
        </Typography>
      </Link>
    ),
    sortable: !query,
    width: 250,
  },
  {
    field: "displayPrice",
    headerName: "Price",
    renderCell: param => <Dollar prefix={param.row.variants.length > 1 && "From "} amount={param.value} />,
    sortable: false,
    width: 120,
  },
  {
    field: "totalInventory",
    headerName: "Inventory",
    renderCell: param => {
      const obj = param.value;
      const keys = Object.keys(obj);

      if (keys.length == 0) {
        return "N/A";
      } else {
        return (
          <>
            {keys.map(unit => (
              <div key={unit}>
                {obj[unit]} {unit}
              </div>
            ))}
          </>
        );
      }
    },
    sortable: false,
    width: 120,
  },
  {
    field: "displayUnit",
    headerName: "Unit",
    renderCell: param => unitLabel(param.value),
    sortable: false,
    width: 120,
  },
  {
    field: ProductsSortField.CATEGORY,
    headerName: "Category",
    renderCell: param => (
      <Link component={RouterLink} to={Routes.EDIT_CATEGORY.replace(":id", param.row.category.id)}>
        {param.row.category.name}
      </Link>
    ),
    sortable: !query,

    width: 140,
  },
  {
    field: ProductsSortField.ACTIVE,
    headerName: "Visibility",
    renderCell: param => (
      <Chip
        color={param.row.active ? "primary" : "secondary"}
        label={param.row.active ? "Active" : "Inactive"}
        style={{ width: "72px" }}
      />
    ),
    sortable: !query,
    width: 150,
  },
  {
    field: "stockStatus",
    headerName: "Status",
    renderCell: param => (
      <Typography color={param.value === "OUT_OF_STOCK" ? "secondary" : "primary"} variant="body2">
        {DISPLAY_STATUS[param.value]}
      </Typography>
    ),
    sortable: false,
    width: 100,
  },
  {
    field: ProductsSortField.UPDATED_AT,
    headerName: "Last updated",
    sortable: !query,
    valueGetter: param => moment(param.row.updatedAt).format("YYYY-MM-DD"),
    width: 150,
  },
];

const ROW_PER_PAGE = 100;
const defaultSortState: GridSortItem = {
  field: ProductsSortField.NAME,
  sort: "asc",
};

const ProductList = ({ history }: RouteComponentProps) => {
  const classes = useStyles();

  const queryParams = qs.parse(history.location.search, { ignoreQueryPrefix: true });
  const { query = "" } = queryParams;
  const hasSearchQuery = Boolean(query);
  const columns = useMemo(() => getColumns(query), [query]);

  const { supplier } = useContext(AccountContext);
  const { id: supplierId } = supplier;

  const [anchorEl, setAnchorEl] = useState(null);
  const [sortState, setSortState] = useState(defaultSortState);
  const [selectionModel, setSelectionModel] = useState([]);
  const [openModal, setOpenModal] = useState<ModalDescription>(null);
  const { setPageInfo, resetPagination, pagination, page, pageInfo, onPageChange } = usePagination(ROW_PER_PAGE);

  const { data, loading, refetch } = useQuery(SupplierProductVariants, {
    context: { source: DATALAYER },
    fetchPolicy: "network-only",
    onCompleted: response => {
      window.scrollTo({ behavior: "instant", top: 0 });
      setPageInfo(response?.supplier?.products?.pageInfo);
    },
    onError: resetPagination,
    variables: {
      filter: hasSearchQuery
        ? {
            query,
          }
        : undefined,
      page: {
        ...pagination,
        // offset is only applicable to search results (due to meilisearch only support offset pagination)
        offset: page * ROW_PER_PAGE,
      },
      sort: sortState && {
        field: sortState.field,
        order: sortState.sort === "desc" ? -1 : 1,
      },
    },
  });

  const totalCount = pageInfo?.totalCount ?? 0;

  const { data: variablesData } = useQuery(BULK_UPDATE_VARIABLES, {
    context: { source: DATALAYER },
  });
  const categories = variablesData?.categories ?? [];
  const taxableProductsListId = variablesData?.currentSupplier?.taxableProductsList?.id;
  const hasTaxableProductsList = Boolean(taxableProductsListId);

  const [updateMany, { loading: bulkUpdateLoading }] = useMutation(ProductUpdateMany, {
    onCompleted: () => {
      notificationVar({
        message: `${pluralize("Product", selectedProducts.length)} updated`,
        severity: "success",
      });
    },
  });
  const [deleteMany, { loading: bulkDeleteLoading }] = useMutation(ProductDeleteMany, {
    onCompleted: () => {
      notificationVar({
        message: `${pluralize("Product", selectedProducts.length)} deleted!`,
        severity: "success",
      });

      handleCloseModal();
    },
    onError: () => {
      handleCloseModal();
    },
  });
  const [addProductsToTaxableList] = useMutation(ListAddProducts, {
    onCompleted: () => {
      notificationVar({
        message: "Added products to taxable collection!",
        severity: "success",
      });
    },
    refetchQueries: ["SupplierTaxableProductsList"],
  });
  const [removeProductsFromTaxableList] = useMutation(ListRemoveProducts, {
    onCompleted: () => {
      notificationVar({
        message: "Removed products from taxable collection!",
        severity: "success",
      });
    },
    refetchQueries: ["SupplierTaxableProductsList"],
  });

  const { ExportDownloadBanner, loadingExport, onExportClick } = useExportResource(EXPORT_PRODUCTS);

  const rows = useMemo(() => data?.supplier?.products?.edges.map(({ node }) => node) ?? [], [data?.supplier?.products]);

  const selectedProducts = rows.filter(({ id }) => selectionModel.includes(id));
  const handleSortChange = ({ sortModel }) => {
    resetPagination();
    setSortState(sortModel[0]);
  };
  const handleSelectionChange = newSelection => setSelectionModel(newSelection.selectionModel);
  const handleBulkActionsClick = event => setAnchorEl(event.currentTarget);
  const handleCloseBulkActions = () => setAnchorEl(null);
  const handleCreateProductClick = () => history.push(Routes.PRODUCT_CREATE_NEW);
  const handleUpdateQuery = keyValue => {
    history.replace({
      search: qs.stringify({
        ...queryParams,
        ...keyValue,
      }),
    });
    setSortState(defaultSortState);
    resetPagination();
  };
  const handleOpenModal = type => setOpenModal(modals[type]);
  const handleCloseModal = () => setOpenModal(null);
  const handleMenuClick = type => {
    handleOpenModal(type);
    handleCloseBulkActions();
  };
  const handleConfirmClick = type => {
    const productIds = selectedProducts.map(({ id }) => id);

    if (type !== ModalType.BulkDelete) {
      handleCloseModal();
    }

    if (type === ModalType.BulkDelete) {
      deleteMany({
        variables: {
          ids: productIds,
        },
      });

      resetPagination();

      if (page === 0) {
        // if page is 0,
        // resetPagination does not make any change,
        // have to refetch manually
        refetch();
      }
    } else if (type === ModalType.BulkDeactivate) {
      updateMany({
        variables: {
          input: productIds.map(id => ({ active: false, id })),
        },
      });
    } else if (type === ModalType.BulkActivate) {
      updateMany({
        variables: {
          input: productIds.map(id => ({ active: true, id })),
        },
      });
    } else if (type === ModalType.BulkSetInStock) {
      updateMany({
        variables: {
          input: selectedProducts.map(({ id, variants }) => ({
            id,
            variants: variants.map(({ __typename, ...variant }) => ({ ...variant, outOfStock: false })),
          })),
        },
      });
    } else if (type === ModalType.BulkSetOutOfStock) {
      updateMany({
        variables: {
          input: selectedProducts.map(({ id, variants }) => ({
            id,
            variants: variants.map(({ __typename, ...variant }) => ({ ...variant, outOfStock: true })),
          })),
        },
      });
    } else if (type === ModalType.AddToTaxableList) {
      if (hasTaxableProductsList) {
        addProductsToTaxableList({
          variables: {
            _id: taxableProductsListId,
            productIds,
          },
        });
      }
    } else if (type === ModalType.RemoveFromTaxableList) {
      if (hasTaxableProductsList) {
        removeProductsFromTaxableList({
          variables: {
            _id: taxableProductsListId,
            productIds,
          },
        });
      }
    }
  };

  return (
    <>
      <PageHeader
        primaryActions={
          <>
            <Button
              aria-controls="products-export-menu"
              aria-haspopup="true"
              color="primary"
              disabled={loadingExport}
              endIcon={loadingExport && <CircularProgress color="inherit" size={24} />}
              onClick={onExportClick()}
              variant="outlined"
            >
              Export
            </Button>
            <Button color="primary" onClick={handleCreateProductClick} variant="contained">
              Create Product
            </Button>
          </>
        }
        stickyHeader
        title="Products"
      />
      {ExportDownloadBanner}
      <Grid alignItems="center" className={classes.actionsContainer} container spacing={1}>
        <Grid item md={6} xs={12}>
          <ProductSearch query={query} onUpdateQuery={handleUpdateQuery} />
        </Grid>
        <Grid className={classes.actionButtons} item md={6} xs={12}>
          <Button
            color="primary"
            disabled={selectedProducts.length === 0}
            onClick={handleBulkActionsClick}
            variant="text"
          >
            <EditIcon fontSize="small" /> Bulk Actions{" "}
            {anchorEl ? <ExpandLessIcon fontSize="small" /> : <ExpandMoreIcon fontSize="small" />}
          </Button>
          <Menu
            anchorEl={anchorEl}
            className={classes.bulkActionMenu}
            onClose={handleCloseBulkActions}
            open={Boolean(anchorEl)}
          >
            <Box className={classes.bulkActionTitle}>
              <Typography variant="subtitle1">Apply Bulk Action</Typography>
              <Typography color="textSecondary" variant="caption">
                {pluralize("item", selectedProducts.length, true)} selected
              </Typography>
            </Box>
            <Divider />
            {bulkOptions.map(({ name, description, type }) => (
              <MenuItem
                className={classes.bulkActionItem}
                disabled={
                  (type === ModalType.AddToTaxableList || type === ModalType.RemoveFromTaxableList) &&
                  !hasTaxableProductsList
                }
                key={name}
                onClick={() => handleMenuClick(type)}
              >
                <Typography variant="body2">{name}</Typography>
                <Typography color="textSecondary" variant="caption">
                  {description}
                </Typography>
              </MenuItem>
            ))}
          </Menu>
          <Button
            color="primary"
            disabled={selectedProducts.length === 0}
            onClick={() => handleOpenModal(ModalType.QuickUpdate)}
            variant="text"
          >
            <AssignmentIcon fontSize="small" /> Quick Edits
          </Button>
          <Button
            color="primary"
            disabled={selectedProducts.length === 0}
            onClick={() => handleOpenModal(ModalType.BulkDelete)}
            variant="text"
          >
            <DeleteOutlineIcon fontSize="small" /> Delete
          </Button>
        </Grid>
      </Grid>

      <PaginatedTable
        checkboxSelection
        columns={columns}
        defaultContent={hasSearchQuery ? "No products found" : "No products"}
        loading={loading}
        onPageChange={onPageChange}
        onSelectionModelChange={handleSelectionChange}
        onSortModelChange={handleSortChange}
        page={page}
        pageSize={ROW_PER_PAGE}
        rows={rows}
        selectionModel={selectionModel}
        sortModel={sortState ? [sortState] : []}
        totalCount={totalCount}
      />

      {openModal && (
        <ConfirmationModal
          cancelRequestButtonText="Cancel"
          confirmRequestButtonText={openModal.confirmText}
          confirmRequestOnGoing={bulkUpdateLoading || bulkDeleteLoading}
          isDangerAction={openModal.isDangerAction}
          maxWidth={openModal.type === ModalType.QuickUpdate ? "lg" : "sm"}
          modalContent={openModal.content}
          modalNote={openModal.note}
          modalTitle={openModal.title}
          onCloseModal={handleCloseModal}
          onConfirmClick={() => handleConfirmClick(openModal.type)}
          shouldOpenModal
          showActionButtons={
            openModal.type === ModalType.QuickUpdate ||
            openModal.type === ModalType.BulkUpdateDeliveryDays ||
            openModal.type === ModalType.BulkUpdateCategoryTags
              ? false
              : true
          }
        >
          {openModal.type === ModalType.QuickUpdate && (
            <QuickEditForm onClose={handleCloseModal} products={selectedProducts} quickUpdate={updateMany} />
          )}
          {openModal.type === ModalType.BulkUpdateCategoryTags && (
            <CategoryTagsForm
              categoriesData={categories}
              onClose={handleCloseModal}
              products={selectedProducts}
              updateCategoryAndTags={updateMany}
            />
          )}
          {openModal.type === ModalType.BulkUpdateDeliveryDays && (
            <DeliveryScheduleForm onClose={handleCloseModal} products={selectedProducts} updateDelivery={updateMany} />
          )}
        </ConfirmationModal>
      )}
    </>
  );
};

export default ProductList;
