import { gql, useMutation, useQuery } from "@apollo/client";
import {
  FulfillmentMethod,
  Location,
  LocationCreateInput,
  LocationUpdateInput,
  ShippingWeightUnit,
} from "@arowana/graphql";
import { DATALAYER } from "@arowana/util";
import { DevTool } from "@hookform/devtools";
import { Box, Button, Link, makeStyles, Tooltip, Typography } from "@material-ui/core";
import capitalize from "lodash/capitalize";
import moment from "moment";
import { useContext, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Link as RouterLink } from "react-router-dom";

import { notificationVar } from "../../../cache/notificationPolicy";
import FormCard from "../../../components/FormCard";
import Loader from "../../../components/Loader";
import PageHeader from "../../../components/PageHeader";
import Routes from "../../../Constants/Routes";
import { centsToDollars, dollarsToCents } from "../../../utils/dollarCentConvert";
import latLngUtility from "../../../utils/latLngUtility";
import { FlagsmithContext } from "../../context/FlagsmithContext";
import Blacklist from "../components/Blacklist";
import FulfillmentDetails from "../components/FulfillmentDetails";
import LocationSummary from "../components/LocationSummary";
import RadioSelector from "../components/RadioSelector";
import ZoneMap from "../components/ZoneMap";

const PaymentCaptureOptions = [
  {
    label: (
      <>
        <Typography variant="body2">Auth at create & capture at fulfill</Typography>
        <Typography color="textSecondary" variant="caption">
          The credit card will be authorized when the order is created and charged when the order is fulfilled.
        </Typography>
      </>
    ),
    value: "3",
  },
  {
    label: (
      <>
        <Typography variant="body2">Auth & capture at fulfill</Typography>
        <Typography color="textSecondary" variant="caption">
          The credit card will be authorized and charged when the order is fulfilled.
        </Typography>
      </>
    ),
    value: "2",
  },
  {
    label: (
      <>
        <Typography variant="body2">Auth & capture at create</Typography>
        <Typography color="textSecondary" variant="caption">
          The credit card will be authorized and charged when the order is created.
        </Typography>
        <Typography variant="body2">
          Note: you will not be able to edit the order line items, totals, discounts, or fees.
        </Typography>
      </>
    ),
    value: "1",
  },
];

const LOCATION_FRAGMENT = gql`
  fragment LocationFragment on Location {
    id
    active
    address {
      id
      address1
      address2
      city
      country
      label
      lngLat {
        coordinates
      }
      notes
      postalCode
      region
      sublocality
    }
    blackList {
      id
      name
    }
    cutOffHour
    deliveryFee
    description
    discountedDeliveryFee
    discountedDeliveryFeeThreshold
    freeDeliveryAmount
    fulfillmentMethod
    fulfillmentTimes {
      id
      label
    }
    geometry {
      coordinates
    }
    leadTimes
    minimumOrderAmount
    name
    paymentSettings {
      captureAt
    }
    shippingBaseWeight
    shippingBaseWeightUnit
    tipsEnabled
  }
`;

const LOCATION = gql`
  query SupplierLocation($id: ID!, $isCreate: Boolean = false) {
    location(id: $id) @skip(if: $isCreate) {
      ...LocationFragment
    }
  }
  ${LOCATION_FRAGMENT}
`;

const LOCATION_UPDATE = gql`
  mutation LocationUpdate($input: LocationUpdateInput!) {
    supplierUpdateLocation(input: $input) {
      ...LocationFragment
    }
  }
  ${LOCATION_FRAGMENT}
`;

const LOCATION_CREATE = gql`
  mutation LocationCreate($input: LocationCreateInput!) {
    supplierCreateLocation(input: $input) {
      ...LocationFragment
    }
  }
  ${LOCATION_FRAGMENT}
`;

const useStyles = makeStyles(theme => ({
  uaProducts: {
    marginTop: theme.spacing(4),
  },
}));

/*
  FOR THE MAP
    FulfillmentMethod.shipping ALWAYS has an address, it has A marker (markerLabel = Origin) & A polygon (user provided)
    FulfillmentMethod.pickup ALWAYS has an address, it has A marker (markerLabel = Pickup) & A polygon (user provided / auto generated)
    FulfillmentMethod.delivery has NO address, NO marker (markerLabel = undefined) and NO polygon
*/
const ZoneDetail = ({ match, history }) => {
  const classes = useStyles();
  const flagsmith = useContext(FlagsmithContext);
  const hasRetail = flagsmith.hasFeature("b2c");
  const locationId = match.params.id;
  const isCreate = match.path === Routes.STORE_LOCATIONS_CREATE;
  const [polygonRef, setPolygonRef] = useState(null);
  const [polygonPaths, setPolygonPaths] = useState(null);
  const [mapError, setMapError] = useState(false);
  const {
    clearErrors,
    control,
    formState: { dirtyFields },
    errors,
    handleSubmit,
    register,
    reset,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      active: true,
      address: {
        address1: null,
        address2: null,
        city: null,
        country: null,
        label: null,
        lngLat: {
          coordinates: [],
          type: null,
        },
        notes: null,
        postalCode: null,
        region: null,
        regionCode: null,
        sublocality: null,
      },
      blackList: {},
      cutOffHour: 0,
      deliveryFee: null,
      deliveryInstructions: null,
      discountedDeliveryFee: null,
      discountedDeliveryFeeThreshold: null,
      freeDeliveryAmount: null,
      fulfillmentMethod: FulfillmentMethod.delivery,
      fulfillmentTimes: [],
      leadTimes: {},
      minimumOrderAmount: 0,
      name: null,
      paymentCapture: "3", // CaptureAtFulfillWithPreauth
      shippingBaseWeightObject: {
        shippingBaseWeight: 0,
        shippingBaseWeightUnit: ShippingWeightUnit.POUND,
      },
      tipsEnabled: false,
    },
  });

  const chosenFulfillmentMethod = watch("fulfillmentMethod", FulfillmentMethod.delivery);

  const { data: locationInfo, loading: isFetching } = useQuery<{
    location?: Location;
  }>(LOCATION, {
    context: { source: DATALAYER },
    fetchPolicy: "cache-and-network",
    variables: {
      id: locationId || "DUMMY",
      isCreate: !locationId,
    },
  });

  const [updateLocation, { loading: isSaving }] = useMutation<
    { supplierUpdateLocation: Location },
    { input: LocationUpdateInput }
  >(LOCATION_UPDATE, {
    context: { source: DATALAYER },
    onCompleted: () => {
      notificationVar({
        message: "Service location updated!",
        severity: "success",
      });
    },
    refetchQueries: ["SupplierLocation"],
  });

  const [createLocation, { loading: isCreating }] = useMutation<
    { supplierCreateLocation: Location },
    { input: LocationCreateInput }
  >(LOCATION_CREATE, {
    context: { source: DATALAYER },
    onCompleted: () => {
      notificationVar({
        message: "Service location created!",
        severity: "success",
      });
      history.replace(Routes.STORE_LOCATIONS);
    },
    refetchQueries: ["DashboardSupplierLocations"],
  });

  const { locationDetails } = useMemo(() => {
    const { location } = locationInfo || {};

    return {
      locationDetails: location,
    };
  }, [locationInfo]);

  useEffect(() => {
    if (locationDetails) {
      setPolygonPaths(
        locationDetails.geometry?.coordinates?.[0]?.[0] && [
          locationDetails.geometry.coordinates[0][0].map(arr => latLngUtility.toLatLng(arr)),
        ],
      );

      const {
        active,
        address,
        blackList,
        cutOffHour,
        deliveryFee,
        description,
        discountedDeliveryFee,
        discountedDeliveryFeeThreshold,
        freeDeliveryAmount,
        fulfillmentMethod,
        fulfillmentTimes,
        leadTimes,
        minimumOrderAmount,
        name,
        paymentSettings,
        shippingBaseWeight,
        shippingBaseWeightUnit,
        tipsEnabled,
      } = locationDetails;

      reset({
        active,
        address,
        blackList: blackList ?? {},
        cutOffHour: cutOffHour ?? 0,
        deliveryFee: Number.isFinite(deliveryFee) ? centsToDollars(deliveryFee) : null,
        deliveryInstructions: description ?? null,
        discountedDeliveryFee: Number.isFinite(discountedDeliveryFee) ? centsToDollars(discountedDeliveryFee) : null,
        discountedDeliveryFeeThreshold: Number.isFinite(discountedDeliveryFeeThreshold)
          ? centsToDollars(discountedDeliveryFeeThreshold)
          : null,
        freeDeliveryAmount: Number.isFinite(freeDeliveryAmount) ? centsToDollars(freeDeliveryAmount) : null,
        fulfillmentMethod,
        fulfillmentTimes: (fulfillmentTimes ?? []).map(({ id, label }) => {
          const [startTime, endTime] = label.split("—");

          const startClock = startTime.substring(startTime.length - 2).toUpperCase();
          const startHour = parseInt(startTime.substring(0, startTime.length - 2));

          const endClock = endTime.substring(endTime.length - 2).toUpperCase();
          const endHour = parseInt(endTime.substring(0, endTime.length - 2));

          return {
            endClock,
            endHour,
            id,
            startClock,
            startHour,
          };
        }),
        leadTimes: leadTimes ?? {},
        minimumOrderAmount: centsToDollars(minimumOrderAmount),
        name,
        paymentCapture: paymentSettings?.captureAt ? `${paymentSettings.captureAt}` : "3",
        shippingBaseWeightObject: {
          shippingBaseWeight: shippingBaseWeight ?? 0,
          shippingBaseWeightUnit: shippingBaseWeightUnit ?? ShippingWeightUnit.POUND,
        },
        tipsEnabled,
      });
    }
  }, [reset, locationDetails]);

  const onSubmit = data => {
    const {
      active,
      address,
      blackList,
      cutOffHour,
      deliveryFee,
      deliveryInstructions,
      discountedDeliveryFee,
      discountedDeliveryFeeThreshold,
      freeDeliveryAmount,
      fulfillmentMethod,
      fulfillmentTimes,
      leadTimes,
      minimumOrderAmount,
      name,
      paymentCapture,
      shippingBaseWeightObject,
      tipsEnabled,
    } = data;

    const {
      active: activeDirty,
      address: addressDirty,
      blackList: blackListDirty,
      cutOffHour: cutOffHourDirty,
      deliveryFee: deliveryFeeDirty,
      deliveryInstructions: deliveryInstructionsDirty,
      discountedDeliveryFee: discountedDeliveryFeeDirty,
      discountedDeliveryFeeThreshold: discountedDeliveryFeeThresholdDirty,
      freeDeliveryAmount: freeDeliveryAmountDirty,
      fulfillmentMethod: fulfillmentMethodDirty,
      fulfillmentTimes: fulfillmentTimesDirty,
      leadTimes: leadTimesDirty,
      minimumOrderAmount: minimumOrderAmountDirty,
      name: nameDirty,
      paymentCapture: paymentCaptureDirty,
      shippingBaseWeightObject: shippingBaseWeightObjectDirty,
      tipsEnabled: tipsEnabledDirty,
    } = dirtyFields;

    const geometryLocations =
      chosenFulfillmentMethod === FulfillmentMethod.shipping || chosenFulfillmentMethod === FulfillmentMethod.delivery;

    if (geometryLocations && !polygonRef) {
      setMapError(true);

      return;
    }

    // newly created polygon is in polygonPaths and polygonRef
    // edited polygon (both existing and new one) is in polygonRef
    // so always use polygonRef as source of truth
    let geometry = null;

    if (polygonRef) {
      const coords = [];
      const paths = polygonRef.getPaths();

      paths.forEach(path => {
        const coord = [];

        path.forEach(ll => {
          coord.push([ll.lng(), ll.lat()]);
        });

        // close polygon loop
        if (coord.length && coord[0][0] !== coord[coord.length - 1][0] && coord[0][1] !== coord[coord.length - 1][1]) {
          coord.push([...coord][0]);
        }

        coords.push(coord);
      });
      geometry = { coordinates: [coords] };
    }

    const shippingLocation = chosenFulfillmentMethod === FulfillmentMethod.shipping;
    const hasDescription = deliveryInstructions?.replace(/<(.|\n)*?>/g, "").trim().length ?? false;
    const addressInput = address?.address1
      ? {
          address1: address.address1,
          address2: address.address2,
          city: address.city,
          country: address.country,
          label: address.label || `${chosenFulfillmentMethod} address`,
          notes: address.notes,
          postalCode: address.postalCode,
          region: address.region,
          sublocality: address.sublocality,
        }
      : null;

    if (isCreate) {
      const input = {
        active,
        address: addressInput,
        ...(hasRetail &&
          blackList.id && {
            blackList: blackList.id,
          }),
        cutOffHour,
        ...(!isNaN(deliveryFee) && {
          deliveryFee: dollarsToCents(deliveryFee),
        }),
        ...(hasDescription && {
          description: deliveryInstructions,
        }),
        ...(!isNaN(discountedDeliveryFee) && {
          discountedDeliveryFee: dollarsToCents(discountedDeliveryFee),
        }),
        ...(!isNaN(discountedDeliveryFeeThreshold) && {
          discountedDeliveryFeeThreshold: dollarsToCents(discountedDeliveryFeeThreshold),
        }),
        ...(!isNaN(freeDeliveryAmount) && {
          freeDeliveryAmount: dollarsToCents(freeDeliveryAmount),
        }),
        fulfillmentMethod,
        ...(fulfillmentTimes.length > 0 && {
          fulfillmentTimes: fulfillmentTimes.map(({ endClock, endHour, startClock, startHour }) => ({
            endTime: moment(`${endHour}${endClock}`, ["hA"]).hour(),
            startTime: moment(`${startHour}${startClock}`, ["hA"]).hour(),
          })),
        }),
        ...(geometryLocations && { geometry }),
        leadTimes,
        minimumOrderAmount: dollarsToCents(minimumOrderAmount),
        name,
        ...(shippingLocation && {
          ...shippingBaseWeightObject,
        }),
        paymentSettings: {
          captureAt: parseInt(paymentCapture ?? "3"),
        },
        tipsEnabled: hasRetail ? tipsEnabled : false,
      };

      createLocation({ variables: { input } });
    } else {
      const updatedFulfillmentTimes = [];

      if (fulfillmentTimesDirty) {
        const originalFulfillmentTimeIds = new Set(locationDetails?.fulfillmentTimes?.map(({ id }) => id) ?? []);

        const newFulfillmentTimes = [];
        const exsitingFulfillmentTimes = [];
        fulfillmentTimes.forEach(f => {
          if (!f.id) {
            newFulfillmentTimes.push(f);
          } else {
            exsitingFulfillmentTimes.push(f);
          }
        });
        const exsitingFulfillmentTimesMap = new Map();
        exsitingFulfillmentTimes.forEach(f => exsitingFulfillmentTimesMap.set(f.id, f));

        for (const fId of originalFulfillmentTimeIds) {
          if (!exsitingFulfillmentTimesMap.has(fId)) {
            updatedFulfillmentTimes.push({ _remove: true, id: fId });
          } else {
            const { endHour, endClock, startHour, startClock } = exsitingFulfillmentTimesMap.get(fId);
            updatedFulfillmentTimes.push({
              _remove: false,
              endTime: moment(`${endHour}${endClock}`, ["hA"]).hour(),
              id: fId,
              startTime: moment(`${startHour}${startClock}`, ["hA"]).hour(),
            });
          }
        }

        newFulfillmentTimes.forEach(({ endClock, endHour, startClock, startHour }) => {
          updatedFulfillmentTimes.push({
            _remove: false,
            endTime: moment(`${endHour}${endClock}`, ["hA"]).hour(),
            startTime: moment(`${startHour}${startClock}`, ["hA"]).hour(),
          });
        });
      }

      const input = {
        id: locationId,
        ...(activeDirty && { active }),
        ...(addressDirty && {
          address: {
            _remove: !address?.address1,
            address: addressInput,
          },
        }),
        ...(blackListDirty &&
          hasRetail && {
            blackList: {
              _remove: !blackList.id,
              id: blackList.id ?? null,
            },
          }),
        ...(cutOffHourDirty && { cutOffHour }),
        ...(deliveryFeeDirty && {
          deliveryFee: {
            _remove: isNaN(deliveryFee),
            fee: isNaN(deliveryFee) ? null : dollarsToCents(deliveryFee),
          },
        }),
        ...(deliveryInstructionsDirty && {
          description: {
            _remove: !hasDescription,
            description: hasDescription ? deliveryInstructions : null,
          },
        }),
        ...(discountedDeliveryFeeDirty && {
          discountedDeliveryFee: {
            _remove: isNaN(discountedDeliveryFee),
            fee: isNaN(discountedDeliveryFee) ? null : dollarsToCents(discountedDeliveryFee),
          },
        }),
        ...(discountedDeliveryFeeThresholdDirty && {
          discountedDeliveryFeeThreshold: {
            _remove: isNaN(discountedDeliveryFeeThreshold),
            fee: isNaN(discountedDeliveryFeeThreshold) ? null : dollarsToCents(discountedDeliveryFeeThreshold),
          },
        }),
        ...(freeDeliveryAmountDirty && {
          freeDeliveryAmount: {
            _remove: isNaN(freeDeliveryAmount),
            freeDeliveryAmount: isNaN(freeDeliveryAmount) ? null : dollarsToCents(freeDeliveryAmount),
          },
        }),
        ...(fulfillmentMethodDirty && {
          fulfillmentMethod,
        }),
        ...(fulfillmentTimesDirty && {
          fulfillmentTimes: updatedFulfillmentTimes,
        }),
        ...(geometryLocations && { geometry }),
        ...(leadTimesDirty && { leadTimes }),
        ...(minimumOrderAmountDirty && { minimumOrderAmount: dollarsToCents(minimumOrderAmount) }),
        ...(nameDirty && { name }),
        ...(paymentCaptureDirty && { paymentSettings: { captureAt: parseInt(paymentCapture ?? "3") } }),
        ...(shippingBaseWeightObjectDirty &&
          shippingLocation && {
            ...shippingBaseWeightObject,
          }),
        ...(tipsEnabledDirty &&
          hasRetail && {
            tipsEnabled,
          }),
      };

      updateLocation({ variables: { input } });
    }
  };

  const handleDiscardClick = () => {
    reset();
    setPolygonPaths(
      locationDetails?.geometry?.coordinates?.[0]?.[0] && [
        locationDetails.geometry.coordinates[0][0].map(arr => latLngUtility.toLatLng(arr)),
      ],
    );
    setMapError(false);
  };

  if (isFetching || isSaving || isCreating) return <Loader />;

  let title = "Service location";

  if (isCreate) {
    title = "New service location";
  } else if (locationDetails) {
    const { active, name } = locationDetails;
    title = `${name}${active ? "" : " (closed)"}`;
  }

  return (
    <>
      <PageHeader
        homePage={Routes.STORE_LOCATIONS}
        primaryActions={
          <>
            <Button color="primary" onClick={handleDiscardClick} variant="outlined">
              Discard
            </Button>
            <Button color="primary" onClick={handleSubmit(onSubmit)} variant="contained">
              Save
            </Button>
          </>
        }
        stickyHeader
        title={title}
      />

      <LocationSummary control={control} errors={errors} register={register} />

      <FormCard title="Payment settings">
        <Typography gutterBottom variant="subtitle1">
          Payment capture
        </Typography>
        <Controller as={<RadioSelector />} control={control} name="paymentCapture" options={PaymentCaptureOptions} />
      </FormCard>

      <FormCard title={`${capitalize(chosenFulfillmentMethod)} details`}>
        <Box mb={2}>
          <ZoneMap
            control={control}
            fulfillmentMethod={chosenFulfillmentMethod}
            polygonPaths={polygonPaths}
            setMapError={setMapError}
            setPolygonPaths={setPolygonPaths}
            setPolygonRef={setPolygonRef}
          />
          {mapError && (
            <Typography color="secondary" variant="caption">
              *Please draw your service area.
            </Typography>
          )}
        </Box>
        <FulfillmentDetails
          address={locationDetails?.address}
          clearErrors={clearErrors}
          control={control}
          discountedDeliveryFee={locationDetails?.discountedDeliveryFee}
          discountedDeliveryFeeThreshold={locationDetails?.discountedDeliveryFeeThreshold}
          errors={errors}
          fulfillmentMethod={chosenFulfillmentMethod}
          register={register}
          setValue={setValue}
        />
      </FormCard>

      {hasRetail && (
        <FormCard className={classes.uaProducts} title="Unavailable collections">
          <Tooltip
            arrow
            title="By creating a list you are able to apply specific product rules like taxation or zone-based availability."
          >
            <Typography color="textSecondary" gutterBottom variant="body2">
              Select a collection of exempt products from being sold in this location. Don't have a collection yet?{" "}
              <Link component={RouterLink} to={Routes.LISTS}>
                Create a Collection here.
              </Link>
            </Typography>
          </Tooltip>
          <Controller as={<Blacklist />} control={control} name="blackList" />
        </FormCard>
      )}

      {process.env.NODE_ENV === "development" && <DevTool control={control} />}
    </>
  );
};

export default ZoneDetail;
