import { usePasswordToggle } from "@arowana/ui";
import { FormControl, FormLabel, Grid, Link, makeStyles, TextField, Typography } from "@material-ui/core";
import React from "react";

import FormTooltip from "../../../components/FormTooltip";
import AppForm from "../components/AppForm";

const defaultValues = {
  password: "",
  userName: "",
};
const useStyles = makeStyles(theme => ({
  instructionSection: {
    backgroundColor: theme.palette.grey[50],
    borderRadius: theme.shape.borderRadius,
    marginTop: theme.spacing(6),
    padding: theme.spacing(2),
  },
}));

const PasswordInput = ({ formMethods: { errors, register, watch } }) => {
  const { PasswordToggle, passwordInputType } = usePasswordToggle(watch);

  return (
    <FormControl component="fieldset" fullWidth margin="normal">
      <FormLabel component="legend" htmlFor="password">
        Password
      </FormLabel>
      <TextField
        error={Boolean(errors?.password)}
        helperText={errors?.password?.message}
        id="password"
        inputProps={{
          maxLength: 30,
        }}
        inputRef={register({
          required: "*required",
        })}
        margin="dense"
        name="password"
        variant="outlined"
        type={passwordInputType}
        InputProps={{ endAdornment: PasswordToggle }}
      />
    </FormControl>
  );
};

const isInstalled = fields => Boolean(fields?.userName);

const Wise = () => {
  const classes = useStyles();

  return (
    <AppForm
      appId="wise"
      defaultValues={defaultValues}
      header="Wise Systems"
      uninstallPrompt="All associated app-fields (Wise Task IDs) will be removed as well. Are you sure you want to uninstall this app?"
      isInstalled={isInstalled}
    >
      {({ formMethods, installed }) => (
        <>
          <FormControl component="fieldset" fullWidth margin="normal">
            <FormLabel component="legend" htmlFor="userName">
              {installed ? "Connected as" : "User name (or email)"}
            </FormLabel>
            <TextField
              error={Boolean(formMethods?.errors?.userName)}
              helperText={formMethods?.errors?.userName?.message}
              id="userName"
              inputProps={{
                maxLength: 30,
              }}
              inputRef={formMethods.register({
                required: "*required",
              })}
              disabled={installed}
              margin="dense"
              name="userName"
              variant="outlined"
            />
          </FormControl>
          {!installed && <PasswordInput formMethods={formMethods} />}
        </>
      )}
    </AppForm>
  );
};

export default Wise;
