import { Checkbox } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";

const Featured = ({ value, onChange }) => {
  const handleChange = e => onChange(e.target.checked);

  return <Checkbox checked={value} color="primary" onChange={handleChange} />;
};

Featured.defaultProps = {
  onChange: () => {},
  value: false,
};

Featured.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.bool,
};

export default Featured;
