import { Variant } from "@arowana/graphql";
import { Button, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  addToCartBtn: {
    color: "white",
    padding: theme.spacing(1.75, 0),
  },
}));

export interface AddToCartButtonProps {
  isInCart?: boolean;
  onClick: () => void;
  selectedVariant: Variant;
  loading?: boolean;
}

const getButtonText = ({ isInCart, loading, selectedVariant }) => {
  if (selectedVariant?.outOfStock) {
    return "Out of Stock";
  }

  if (isInCart) {
    return "Add More to Cart";
  }

  if (loading) {
    return "Adding to Cart...";
  }

  return "Add to Cart";
};

export const AddToCartButton = ({
  isInCart = false,
  onClick,
  selectedVariant,
  loading = false,
}: AddToCartButtonProps) => {
  const classes = useStyles();

  return (
    <Button
      className={classes.addToCartBtn}
      color="primary"
      disabled={loading || selectedVariant?.outOfStock}
      fullWidth
      onClick={onClick}
      variant="contained"
    >
      {getButtonText({ isInCart, loading, selectedVariant })}
    </Button>
  );
};

export default AddToCartButton;
