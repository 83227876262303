import { gql } from "@apollo/client";

export const PRODUCT_FRAGMENT = gql`
  fragment ProductFragment on Product {
    active
    category {
      id
      name
      tags {
        label
        value
      }
    }
    cheapestInStockVariant {
      id
    }
    cheapestVariant {
      id
    }
    description
    descriptionText
    displayPrice
    featured
    fulfillmentDays {
      fulfilledOn
      orderBy
    }
    id
    identifier
    images {
      original
      x256
      x512
      x896
      x1664
    }
    name
    onSale
    originalPrice
    showInventory
    stockStatus
    subname
    tags
    trackInventory
    variants {
      caseSize
      id
      name
      outOfStock
      price
      quantity
      unit
    }
  }
`;
