/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/style-prop-object */
import { ProductUnit } from "@arowana/graphql";
import { unitLabel } from "@arowana/util";
import { makeStyles, Typography, TypographyProps } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import clsx from "clsx";
import { useContext } from "react";
import { FormattedNumber } from "react-intl";

import { SupplierContext } from "../context";

const useStyles = makeStyles(theme => ({
  loading: {
    display: "inline-block",
  },
  originalAmount: {
    color: theme.palette.text.secondary,
    textDecoration: "line-through",
  },
}));

interface CurrencyProps extends Omit<TypographyProps, "prefix"> {
  cents?: number;
  className?: string;
  component?: React.ElementType;
  fallback?: string;
  hidePrice?: boolean;
  loading?: boolean;
  loadingWidth?: number;
  loadingClassName?: string;
  prefix?: React.ReactNode;
  strikethrough?: boolean;
  suffix?: string;
  unit?: ProductUnit;
}

export const Currency = ({
  cents,
  className,
  component = "span",
  fallback = "N/A",
  hidePrice = false,
  loading,
  loadingWidth = 40,
  loadingClassName,
  prefix,
  strikethrough = false,
  suffix,
  unit,
  variant = "body2",
  ...remainingProps
}: CurrencyProps) => {
  const classes = useStyles();
  const classnames = clsx(className, { [classes.originalAmount]: strikethrough });
  const { currency } = useContext(SupplierContext);

  const formattedAmount = Number.isInteger(cents) ? (
    <FormattedNumber currency={currency} style="currency" value={parseFloat((cents / 100).toFixed(2))} />
  ) : (
    fallback
  );

  let content;

  if (loading) {
    content = <Skeleton className={clsx(classes.loading, loadingClassName)} width={loadingWidth} />;
  } else if (hidePrice) {
    content = "Market Price";
  } else {
    content = (
      <>
        {prefix || ""}
        {formattedAmount}
        {unit ? `/${unitLabel(unit)}` : ""}
        {suffix ? ` ${suffix}` : ""}
      </>
    );
  }

  return (
    <Typography className={classnames} component={component} variant={variant} {...remainingProps}>
      {content}
    </Typography>
  );
};

export default Currency;
