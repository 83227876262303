import { Chip, ChipProps } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  root: {
    border: `1px solid ${theme.palette.grey[800]}`,
    fontWeight: "normal",
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
}));

type LocationChipProps = {
  location?: {
    name?: string;
  };
} & ChipProps;

const LocationChip = ({ location, ...rest }: LocationChipProps) => {
  const classes = useStyles();

  return <Chip className={classes.root} label={location?.name ?? "Unknown location"} size="small" {...rest} />;
};

export default LocationChip;
