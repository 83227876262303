/* eslint-disable @typescript-eslint/no-explicit-any */
import { gql, useMutation, useQuery } from "@apollo/client";
import {
  FEATURE_SUPPLIER_ORDER_SIGNATURE,
  FEATURE_SUPPLIER_RETAIL_ORDER_SIGNATURE,
  FEATURE_SUPPLIER_STRIPE_INVOICES,
} from "@arowana/flags";
import {
  AppFieldResourceType,
  Location,
  Order,
  OrderAudience,
  OrderLineItem,
  OrderPaymentCollectionMethod,
  OrderStatus,
  Supplier,
} from "@arowana/graphql";
import { AddressForm, OnWheelBlur } from "@arowana/ui";
import { DATALAYER } from "@arowana/util";
import { DevTool } from "@hookform/devtools";
import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputAdornment,
  Link,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { Alert, Skeleton } from "@material-ui/lab";
import FulfillmentChip from "apps/supplier/src/components/FulfillmentChip";
import { capitalize, isEqual, omit } from "lodash";
import moment from "moment-timezone";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Prompt } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import { useDebounce } from "use-debounce";

import { notificationVar } from "../../../../cache/notificationPolicy";
import AppFields from "../../../../components/AppFields";
import ConfirmationModal from "../../../../components/ConfirmationModal";
import DropdownSelect from "../../../../components/DropdownSelect";
import ExportMenu from "../../../../components/ExportMenu";
import FieldTitle from "../../../../components/FieldTitle";
import FormCard from "../../../../components/FormCard";
import Loader from "../../../../components/Loader";
import PageHeader from "../../../../components/PageHeader";
import RichTextFormComponent from "../../../../components/RichTextFormComponent";
import Routes from "../../../../Constants/Routes";
import useExportResource from "../../../../hooks/useExportResource";
import { AUDIENCE_DISPLAY } from "../../../../utils/audienceDisplay";
import { AccountContext } from "../../../context/AccountContext";
import { FlagsmithContext } from "../../../context/FlagsmithContext";
import OrderSignatureModal from "../../../operations/components/OrderSignatureModal";
import DiscountItems from "./DiscountItems";
import FeeItems from "./FeeItems";
import FulfillMenu from "./FulfillMenu";
import LineItems from "./LineItems";
import OrderCharge from "./OrderCharge.graphql";
import ServiceLocation from "./ServiceLocation";

const ADDRESS_FRAGMENT = gql`
  fragment AddressFragment on Address {
    id
    address1
    address2
    city
    country
    label
    notes
    postalCode
    region
    sublocality
  }
`;

const ORDER = gql`
  query Order($id: ID!, $accountId: ID!) {
    order(id: $id) {
      id
      account {
        id
        email
        name
        notes
        paymentMethods {
          id
          card {
            brand
            expiryMonth
            expiryYear
            last4
          }
        }
        phone
      }
      address {
        ...AddressFragment
      }
      audience
      client {
        id
        name
        phone
        wholesaleListId
      }
      charge {
        amount
      }
      createdAt
      discounts {
        id
        amount
        description
      }
      fees {
        id
        amount
        description
        feeType
      }
      fulfilled
      fulfillmentDate
      fulfillmentInstructions
      fulfillmentMethod
      fulfillmentTime {
        id
        label
      }
      invoiceNumber
      isCancellable(accountId: $accountId)
      isEditable(accountId: $accountId)
      lineItems {
        id
        cases
        caseSize
        pricePerUnit
        productId
        productImage
        productName
        productVariantOverrideId
        quantity
        sku
        subName
        unit
        variantId
      }
      locationId
      notes
      payeeName
      paymentCollectionMethod
      paymentMethod {
        last4
      }
      preAuthorization {
        amount
      }
      recurringOrderId
      refunds {
        id
        amount
        description
      }
      status
      stripeInvoice {
        id
        status
        URL
        PdfURL
      }
      subTotal
      total
      totalDiscounts
      totalRefunds
      trackingCode
      updatedAt
    }
    currentSupplier {
      id
      locations {
        id
        active
        cutOffHour
        fulfillmentMethod
        fulfillmentTimes {
          id
          label
        }
        name
        address {
          ...AddressFragment
        }
      }
    }
  }
  ${ADDRESS_FRAGMENT}
`;

const ORDER_UPDATE = gql`
  mutation OrderUpdate($input: OrderUpdateInput!) {
    orderUpdate(input: $input) {
      id
    }
  }
`;

const ORDER_CANCEL = gql`
  mutation OrderCancel($id: ID!, $sendAccountEmail: Boolean!) {
    orderCancel(id: $id, sendAccountEmail: $sendAccountEmail) {
      id
    }
  }
`;

const ORDER_FULFILL = gql`
  mutation OrderFulfill($id: ID!, $sendInvoice: Boolean! = false, $status: OrderStatus) {
    orderFulfill(id: $id, input: { sendInvoice: $sendInvoice, status: $status }) {
      id
      fulfilled
      stripeInvoice {
        id
        status
        URL
        PdfURL
      }
    }
  }
`;

const ORDER_REFUND = gql`
  mutation OrderRefund($id: ID!, $input: OrderRefundInput!) {
    orderRefund(id: $id, input: $input) {
      id
      refunds {
        id
        amount
        description
      }
    }
  }
`;

const EXPORT_ORDER = gql`
  mutation ExportOrder($id: ID!, $format: ExportFormat! = XLSX) {
    url: exportOrder(id: $id, format: $format)
  }
`;

function getLocales() {
  const browserLocales = navigator.languages === undefined ? [navigator.language] : navigator.languages;

  if (!browserLocales) {
    return undefined;
  }

  return browserLocales.map(locale => {
    return locale.trim();
  });
}
const LOCALES = getLocales();

const STATUS_UPDATE = {
  completed: "Completed",
  in_transit: "In Transit",
  paid: "Paid",
  payment_failed: "Payment Failed",
  submitted: "Upcoming",
};

const STATUS_OPTIONS = Object.keys(STATUS_UPDATE).map(value => ({ label: STATUS_UPDATE[value], value }));

enum WARNING_TYPE {
  PRE_AUTH_EXCEEDS,
  STATUS_CHANGE,
}

const PreAuthExceedsWarning = () => (
  <>
    <Typography variant="body2" gutterBottom>
      The existing pre-authorization will be cancelled as the new total exceeds the previous payment hold. It may take
      up 2 business days for the authorization to disappear from the card statement.
    </Typography>
    <Typography variant="body2">
      Please note that this order must be manually processed by clicking "Charge Now" after the order is finalized.
    </Typography>
  </>
);

const StatusChangeWarning = ({
  invoiceNumber,
  newStatus,
  oldStatus,
}: {
  invoiceNumber: number;
  newStatus: string;
  oldStatus: string;
}) => (
  <Typography variant="body2">
    You are about to change the order status of {<strong>Order {invoiceNumber}</strong>} from{" "}
    {<strong>{oldStatus}</strong>} to {<strong>{newStatus}</strong>}.
  </Typography>
);

const useStyles = makeStyles(theme => ({
  "@global": {
    ".ql-editor": {
      "&.ql-blank::before": {
        color: theme.palette.grey[500],
        fontStyle: "normal",
      },
      "color": theme.typography.body1.color,
      "fontFamily": theme.typography.fontFamily,
      "fontSize": theme.typography.body1.fontSize,
    },
  },
  "actions": {
    "& > button + button": {
      marginLeft: theme.spacing(1.5),
    },
    "marginTop": theme.spacing(1),
  },
  "addressForm": {
    marginTop: theme.spacing(1),
  },
  "delivery": {
    borderColor: theme.palette.delivery.main,
    color: theme.palette.delivery.main,
  },
  "divider": {
    margin: theme.spacing(1, 0),
  },
  "formLabel": {
    marginBottom: theme.spacing(1),
  },
  "paymentButton": {
    whiteSpace: "nowrap",
  },
  "pickup": {
    borderColor: theme.palette.pickup.main,
    color: theme.palette.pickup.main,
  },
  "secondaryActionBar": {
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "space-between",
    },
  },
  "shipping": {
    borderColor: theme.palette.shipping.main,
    color: theme.palette.shipping.main,
  },
  "titleChip": {
    marginLeft: theme.spacing(0.5),
  },
  "warningList": {
    "& > li:not(:last-child)": {
      marginBottom: theme.spacing(2),
    },
    "margin": 0,
    "paddingLeft": theme.spacing(1.5),
  },
}));

const resetWithData = (
  reset: (values?: Record<string, any>, options?: Record<string, boolean>) => void,
  order: Order,
  timezone: string,
) => {
  const discounts = order.discounts?.map(discount => ({ ...discount, _remove: false })) ?? [];
  const fees = order.fees?.map(fee => ({ ...fee, _remove: false })) ?? [];
  const addressLabel = order.address.label || `${capitalize(order.fulfillmentMethod)} address`;

  reset({
    address: { ...order.address, label: addressLabel },
    discounts,
    fees,
    fulfillmentDate: moment.tz(order.fulfillmentDate, timezone).format("YYYY-MM-DD"),
    fulfillmentInstructions: order.fulfillmentInstructions,
    fulfillmentMethod: order.fulfillmentMethod,
    fulfillmentTime: order.fulfillmentTime?.id,
    lineItems: order.lineItems,
    notes: order.notes,
    status: order.status,
    trackingCode: order.trackingCode,
  });
};

const OrderDetails = ({ match }): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();
  const isXS = useMediaQuery(theme.breakpoints.down("sm"));
  const [preAuthExpires, setPreAuthExpires] = useState(false);
  const [openUnSavedChangesModal, setOpenUnSavedChangesModal] = useState(false);
  const flagsmith = useContext(FlagsmithContext);
  const hasInvoices = flagsmith.hasFeature(FEATURE_SUPPLIER_STRIPE_INVOICES);

  const {
    control,
    handleSubmit,
    errors,
    formState: { dirtyFields, isDirty },
    register,
    reset,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      address: {
        address1: "",
        address2: "",
        city: "",
        country: "",
        label: "",
        notes: "",
        postalCode: "",
        region: "",
        sublocality: "",
      },
      discounts: [],
      fees: [],
      fulfillmentDate: "",
      fulfillmentInstructions: "",
      fulfillmentMethod: "",
      fulfillmentTime: null,
      lineItems: [],
      notes: "",
      status: "",
      trackingCode: "",
    },
  });
  const discounts = watch("discounts", []);
  const fees = watch("fees", []);
  const fulfillmentTime = watch("fulfillmentTime");
  const lineItems = watch("lineItems");
  const statusNew = watch("status");
  const [fulfillmentDate] = useDebounce(watch("fulfillmentDate"), 500);
  const fulfillmentMethod = watch("fulfillmentMethod");

  const handleAppendDiscount = () => {
    setValue("discounts", [
      ...discounts,
      {
        _remove: false,
        amount: 0,
        description: "Custom discount",
        isNew: true,
        open: true,
      },
    ]);
  };
  const handleAppendFee = () => {
    setValue("fees", [
      ...fees,
      {
        _remove: false,
        amount: 0,
        description: "Handling fee",
        feeType: "handlingFee",
        isNew: true,
        open: true,
      },
    ]);
  };

  const {
    account: { id: accountId },
    supplier: { currency, timezone },
  } = useContext(AccountContext);

  const { data, loading } = useQuery<{ order: Order; currentSupplier: Pick<Supplier, "id" | "locations"> }>(ORDER, {
    context: { source: DATALAYER },
    fetchPolicy: "network-only",
    onCompleted: data => {
      resetWithData(reset, data?.order ?? {}, timezone);
      setDefaultStates();
    },
    variables: {
      accountId,
      id: match.params.id,
    },
  });

  const [cancelOrder] = useMutation(ORDER_CANCEL, {
    context: { source: DATALAYER },
    onCompleted: () => {
      notificationVar({
        message: "Order cancelled!",
        severity: "success",
      });
    },
    refetchQueries: ["Order"],
  });

  const [fulfillOrder, { loading: fulfilling }] = useMutation(ORDER_FULFILL, {
    context: { source: DATALAYER },
    onCompleted: () => {
      notificationVar({
        message: "Order fulfilled!",
        severity: "success",
      });
    },
    refetchQueries: ["Order"],
  });

  const [updateOrder] = useMutation(ORDER_UPDATE, {
    context: { source: DATALAYER },
    onCompleted: () => {
      notificationVar({
        message: "Order updated!",
        severity: "success",
      });
    },
    refetchQueries: ["Order"],
  });

  const [refundOrder, { loading: loadingRefundOrder }] = useMutation(ORDER_REFUND, {
    context: { source: DATALAYER },
    onCompleted: () => {
      notificationVar({
        message: "Order refunded!",
        severity: "success",
      });
    },
    refetchQueries: ["Order"],
  });

  const [orderCharge, { loading: loadingOrderCharge }] = useMutation(OrderCharge, {
    context: { source: DATALAYER },
    onCompleted: data => {
      if (data.order.status === OrderStatus.paid) {
        notificationVar({
          message: "Payment succeeded!",
          severity: "success",
        });
      } else {
        notificationVar({
          message: "Payment failed, please contact support: support@freshline.io",
          severity: "error",
        });
      }
    },
    refetchQueries: ["Order"],
    variables: {
      id: match.params.id,
    },
  });

  const order: Order = data?.order ?? {};
  const account = order?.account;
  const client = order?.client;
  const createdAt = order?.createdAt;
  const orderStatus = order?.status;
  const isWholesaleOrder = order?.audience === OrderAudience.WHOLESALE;
  const isRetailOrder = order?.audience === OrderAudience.MARKETPLACE;
  const momentFulfillmentDate = moment.tz(fulfillmentDate, timezone);
  const originalDiscounts = order?.discounts?.reduce((acc, cur) => ({ [cur.id]: cur, ...acc }), {}) ?? {};
  const originalFees = order?.fees?.reduce((acc, cur) => ({ [cur.id]: cur, ...acc }), {}) ?? {};
  const title = order?.invoiceNumber ? `Order #${order.invoiceNumber}` : "Order details";
  const recurringOrderTitle = order?.recurringOrderId ? `Subscription #${order?.recurringOrderId.substring(18)}` : "";
  const fulfillmentStatus = order?.fulfilled ? "Fulfilled" : "Unfulfilled";
  const originalLineItems = order?.lineItems?.reduce((acc, cur) => ({ [cur.id]: cur, ...acc }), {});
  const preAuthExpiryDate = moment.tz(createdAt, timezone).add(7, "days");
  const canSendInvoice = hasInvoices && order?.paymentCollectionMethod != OrderPaymentCollectionMethod.AUTOMATIC;

  const currencyFormatter = new Intl.NumberFormat(LOCALES?.[0] ?? "en-US", {
    currency: currency || "USD",
    style: "currency",
  });

  // An order can be charged if it has a payment and it is not already `paid` or `payment_failed`
  const orderIsNotFree = (order?.total ?? 0) > 0;
  const hasPaymentInfo = Boolean(order?.paymentMethod?.last4);
  const preAuthAmount = order?.preAuthorization?.amount ?? 0;

  const showChargeNowButton =
    !loading &&
    orderIsNotFree &&
    hasPaymentInfo &&
    orderStatus !== OrderStatus.cancelled &&
    orderStatus !== OrderStatus.paid &&
    // don't show charge now if payment_failed, Retry Payment will show up instead!
    orderStatus !== OrderStatus.payment_failed;

  useEffect(() => {
    if (order?.isEditable && preAuthAmount > 0 && momentFulfillmentDate.isAfter(preAuthExpiryDate)) {
      setPreAuthExpires(true);
    }
  }, [momentFulfillmentDate, order?.isEditable, preAuthAmount, preAuthExpiryDate]);

  // Excel Export
  const { ExportDownloadBanner, loadingExport, onExportClick } = useExportResource(
    EXPORT_ORDER,
    { id: order?.id },
    "Excel is ready!",
  );

  // PDF Export
  const {
    ExportDownloadBanner: PDFDownloadBanner,
    loadingExport: loadingInvoice,
    onExportClick: onExportPDFClick,
  } = useExportResource(EXPORT_ORDER, { format: "PDF", id: order?.id }, "PDF is ready!");

  const [orderLocation, setOrderLocation] = useState<Location>();
  const [cancelOrderOpen, setCancelOrderOpen] = useState(false);
  const [cancelOrderSendCustomerEmail, setCancelOrderSendCustomerEmail] = useState(true);
  const [refundOpen, setRefundOpen] = useState(false);
  const [refundAmount, setRefundAmount] = useState<string | undefined>();
  const [refundDescription, setRefundDescription] = useState<string | undefined>();
  const [statesDirty, setStatesDirty] = useState(false);
  const [openWarningModal, setOpenWarningModal] = useState({
    [WARNING_TYPE.PRE_AUTH_EXCEEDS]: false,
    [WARNING_TYPE.STATUS_CHANGE]: false,
  });

  const handleCancelOrderOpen = () => {
    setCancelOrderOpen(true);
  };
  const handleCancelOrderCancel = () => {
    setCancelOrderOpen(false);
  };
  const handleCancelOrderDone = () => {
    setCancelOrderOpen(false);
    cancelOrder({
      variables: {
        id: match.params.id,
        sendAccountEmail: isWholesaleOrder ? true : cancelOrderSendCustomerEmail,
      },
    });
  };
  const handleCancelOrderSendCustomerEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCancelOrderSendCustomerEmail(event.target.checked);
  };
  const handleRefundAdd = () => {
    setRefundAmount("0.00");
    setRefundDescription(undefined);
    setRefundOpen(true);
  };
  const handleRefundAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRefundAmount(event.target.value);
  };
  const handleRefundDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRefundDescription(event.target.value);
  };
  const handleRefundCancel = () => {
    setRefundOpen(false);
    setRefundAmount("0.00");
    setRefundDescription(undefined);
  };
  const handleRefundDone = () => {
    const amount = Math.round(parseFloat(refundAmount) * 100);

    setRefundOpen(false);
    setRefundAmount("0.00");
    setRefundDescription(undefined);

    refundOrder({
      variables: {
        id: match.params.id,
        input: {
          amount,
          description: refundDescription,
        },
      },
    });
  };
  const handleFulfillOrder = (sendInvoice = false) => {
    fulfillOrder({
      variables: {
        id: match.params.id,
        sendInvoice,
        status: sendInvoice ? OrderStatus.completed : null,
      },
    });
  };

  // Siganture
  const showSignatureButton =
    (isWholesaleOrder && flagsmith.hasFeature(FEATURE_SUPPLIER_ORDER_SIGNATURE)) ||
    (isRetailOrder && flagsmith.hasFeature(FEATURE_SUPPLIER_RETAIL_ORDER_SIGNATURE));
  const [showSignatureModal, setShowSignature] = useState<boolean>(false);
  const handleGetSignature = () => setShowSignature(true);
  const onSignatureModalClose = () => setShowSignature(false);

  const locations = data?.currentSupplier?.locations;
  const subTotal = lineItems.reduce((total, { quantity, pricePerUnit, cases, caseSize }) => {
    if (hasInvoices) {
      return total + cases * caseSize * pricePerUnit;
    } else {
      return total + quantity * pricePerUnit;
    }
  }, 0);
  const refundsTotal = useMemo(() => {
    return (order?.refunds ?? []).reduce((total, { amount }) => total - amount, 0);
  }, [order?.refunds]);
  const discountsTotal = discounts.reduce((total, { amount }) => total + amount, 0);
  const feesTotal = fees.reduce((total, { amount }) => total + amount, 0);
  const total = Math.max(0, subTotal + discountsTotal + refundsTotal + feesTotal);
  const totalAvaliableDiscount = subTotal + feesTotal + discountsTotal;

  const hasUnSavedChanges = order?.isEditable && (isDirty || statesDirty);

  const setDefaultStates = useCallback(() => {
    const locationId = order.locationId;
    const location = locations?.find(({ id }) => id === locationId);

    if (location) {
      setOrderLocation(location);
    }

    setStatesDirty(false);
  }, [locations, order]);

  const handleOrderCharge = () => {
    if (hasUnSavedChanges) {
      setOpenUnSavedChangesModal(true);
    } else {
      orderCharge();
    }
  };
  const handleResetAddress = () => setValue("address", order?.address, { shouldDirty: isDirty });
  const handleDiscardClick = () => {
    resetWithData(reset, data?.order ?? {}, timezone);
    setDefaultStates();
  };

  const onPreSubmit = () => {
    if (orderStatus !== statusNew || (isRetailOrder && total > preAuthAmount)) {
      setOpenWarningModal({
        [WARNING_TYPE.PRE_AUTH_EXCEEDS]: isRetailOrder && total > preAuthAmount,
        [WARNING_TYPE.STATUS_CHANGE]: orderStatus !== statusNew,
      });
    } else {
      handleSubmit(onSubmit)();
    }
  };

  const onConfirmSubmit = () => {
    setOpenWarningModal({
      [WARNING_TYPE.PRE_AUTH_EXCEEDS]: false,
      [WARNING_TYPE.STATUS_CHANGE]: false,
    });
    handleSubmit(onSubmit)();
  };

  const onSubmit = formData => {
    const allProductsRemoved = formData.lineItems.every((item: OrderLineItem) => item.quantity === 0);

    if (allProductsRemoved) {
      notificationVar({
        message:
          "There must be at least one product in the order to save. Please cancel the order or add a product to it instead.",
        severity: "error",
      });

      return;
    }

    const changedLineItems = [];

    formData.lineItems
      // filter out newly added products that are removed before submit
      .filter((item: OrderLineItem) => item.id || item.quantity > 0)
      .forEach((item: OrderLineItem) => {
        if (!item.id) {
          // newly added products
          changedLineItems.push({
            _remove: false,
            cases: item.cases,
            price: item.pricePerUnit,
            productId: item.productId,
            productVariantOverrideId: item.productVariantOverrideId,
            quantity: item.quantity,
            variantId: item.variantId,
          });
        } else if (
          item.cases !== originalLineItems[item.id].cases ||
          item.pricePerUnit !== originalLineItems[item.id].pricePerUnit ||
          item.quantity !== originalLineItems[item.id].quantity
        ) {
          // existing products
          changedLineItems.push({
            _remove: item.quantity === 0,
            cases: item.cases,
            price: item.pricePerUnit,
            productId: item.productId,
            productVariantOverrideId: item.productVariantOverrideId,
            // If Stripe invoicing is enabled, case count is probably updated at this point
            // but quantity isn't. Recompute the proper quantity based on the same calculation
            // done in the frontend at the "adjust case count" prompt.
            quantity: hasInvoices ? item.cases * item.caseSize : item.quantity,
            variantId: item.variantId,
          });
        }
      });

    const changedFees = [];
    fees.forEach(({ id, amount, description, feeType, _remove }) => {
      if (
        !id ||
        _remove ||
        originalFees[id].amount !== amount ||
        originalFees[id].description !== description ||
        originalFees[id].feeType !== feeType
      ) {
        changedFees.push({
          ...(id && { id }),
          _remove,
          amount,
          description,
          itemType: feeType,
        });
      }
    });

    const changedDiscounts = [];
    discounts.forEach(({ id, amount, description, _remove }) => {
      if (
        !id ||
        _remove ||
        originalDiscounts[id].amount !== amount ||
        originalDiscounts[id].description !== description
      ) {
        changedDiscounts.push({
          ...(id && { id }),
          _remove,
          amount,
          description,
        });
      }
    });

    const hasFulfillmentInstructions =
      formData.fulfillmentInstructions?.replace(/<(.|\n)*?>/g, "").trim().length ?? false;

    const variables = {
      input: {
        id: order.id,
        ...(!isEqual(omit(order.address, ["id", "__typename"]), formData.address) && { address: formData.address }),
        ...(changedDiscounts.length > 0 && { discounts: changedDiscounts }),
        ...(changedFees.length > 0 && { fees: changedFees }),
        ...(dirtyFields.fulfillmentDate && {
          fulfillmentDate: moment.tz(formData.fulfillmentDate, timezone).hour(0).utc().toISOString(),
        }),
        ...(dirtyFields.fulfillmentInstructions && {
          fulfillmentInstructions: {
            _remove: !hasFulfillmentInstructions,
            fulfillmentInstructions: hasFulfillmentInstructions ? formData.fulfillmentInstructions : null,
          },
        }),
        ...(order.fulfillmentMethod !== fulfillmentMethod && { fulfillmentMethod }),
        ...(changedLineItems.length > 0 && { lineItems: changedLineItems }),
        ...(order.locationId !== orderLocation.id && { locationId: orderLocation.id }),
        ...(dirtyFields.notes && { notes: formData.notes }),
        ...(dirtyFields.trackingCode &&
          typeof formData.trackingCode === "string" && { trackingCode: formData.trackingCode }),
        ...(orderStatus in STATUS_UPDATE && orderStatus !== statusNew && { status: formData.status }),
      },
    };

    if (!fulfillmentTime && order.fulfillmentTime?.id) {
      variables.input["fulfillmentWindow"] = {
        _remove: true,
      };
    } else if (fulfillmentTime && order.fulfillmentTime?.id !== fulfillmentTime) {
      variables.input["fulfillmentWindow"] = {
        id: fulfillmentTime,
      };
    }

    updateOrder({ variables });
  };

  return (
    <>
      <Loader loading={loading || loadingOrderCharge || loadingRefundOrder} />

      <Prompt message="This order has unsaved changes, are you sure you want to leave?" when={hasUnSavedChanges} />

      <PageHeader
        primaryActions={
          <>
            <Button color="primary" disabled={!hasUnSavedChanges} onClick={handleDiscardClick} variant="outlined">
              Discard
            </Button>
            <Button color="primary" disabled={!hasUnSavedChanges} onClick={onPreSubmit} variant="contained">
              Save
            </Button>
          </>
        }
        stickyHeader
        documentTitle={title}
        title={
          <Typography variant="h5">
            <strong>{title}</strong>
            <Chip className={classes.titleChip} label={fulfillmentStatus} size="small" variant="outlined" />
            <FulfillmentChip fulfillmentType={fulfillmentMethod} />
            <Chip label={AUDIENCE_DISPLAY[order?.audience]} size="small" variant="outlined" color="primary" />
          </Typography>
        }
      >
        <Grid alignItems="center" container className={classes.secondaryActionBar} spacing={1}>
          <Grid item>
            {recurringOrderTitle && (
              <Typography variant="body2">
                <Link to={Routes.SUBSCRIPTIONS_DETAILS.replace(":id", order?.recurringOrderId)} component={RouterLink}>
                  {recurringOrderTitle}
                </Link>
              </Typography>
            )}
            {order ? (
              <Typography color="textSecondary" variant="body2">
                {order?.recurringOrderId ? "Generated" : "Placed"} {moment.utc(createdAt).fromNow()} (
                <time>{moment.utc(createdAt).tz(timezone).format("LLL")}</time>)
              </Typography>
            ) : (
              <Skeleton animation="wave" width="300px" />
            )}
          </Grid>
          <Grid item>
            <Grid container justifyContent="flex-end" spacing={1}>
              {showSignatureButton && (
                <Grid item>
                  <Button color="primary" onClick={handleGetSignature} variant="text">
                    Get Signature
                  </Button>
                </Grid>
              )}
              <Grid item>
                <ExportMenu
                  buttonProps={{
                    variant: "text",
                  }}
                  label="Download"
                  loading={loadingInvoice || loadingExport}
                  menuId="order-export-menu"
                  renderMenu={onMenuClose => [
                    <MenuItem key="export-pdf" onClick={onExportPDFClick(onMenuClose)}>
                      Download Order PDF
                    </MenuItem>,
                    <MenuItem key="export qbo" onClick={onExportClick(onMenuClose)}>
                      Export to Excel
                    </MenuItem>,
                  ]}
                />
              </Grid>
              <Grid item>
                {canSendInvoice ? (
                  <Tooltip arrow title="This will mark the order as completed.">
                    <Button
                      color="primary"
                      onClick={() => handleFulfillOrder(/*sendInvoice=*/ true)}
                      variant="contained"
                      disabled={order.fulfilled}
                    >
                      Fulfill & Invoice
                    </Button>
                  </Tooltip>
                ) : (
                  <Button
                    color="primary"
                    onClick={() => handleFulfillOrder()}
                    variant="contained"
                    disabled={order.fulfilled}
                  >
                    Fulfill
                  </Button>
                )}
              </Grid>
              <Grid item>
                {order?.isCancellable && (
                  <>
                    <Button color="secondary" onClick={handleCancelOrderOpen} variant="contained">
                      Cancel
                    </Button>

                    <Dialog
                      open={cancelOrderOpen}
                      onClose={() => setCancelOrderOpen(false)}
                      aria-labelledby="form-dialog-title-cancel-order"
                      fullScreen={isXS}
                    >
                      <DialogTitle id="form-dialog-title-cancel-order">Cancel order</DialogTitle>
                      <DialogContent dividers>
                        <Typography>
                          Are you sure you want to cancel this order? You cannot undo this action.
                        </Typography>
                        {!isWholesaleOrder && (
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={cancelOrderSendCustomerEmail}
                                onChange={handleCancelOrderSendCustomerEmail}
                              />
                            }
                            label={`Send cancellation email to ${account ? account.email : "the customer"}?`}
                          />
                        )}
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleCancelOrderCancel} color="primary" variant="outlined" tabIndex="0">
                          Nevermind
                        </Button>
                        <Button onClick={handleCancelOrderDone} color="secondary" variant="contained" tabIndex="0">
                          Cancel Order
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </PageHeader>
      {ExportDownloadBanner}
      {PDFDownloadBanner}
      <Grid component="main" container direction="row" role="main" spacing={2}>
        <Grid item md={8} xs={12}>
          <FormCard>
            {!loading ? (
              <Controller
                as={({ value, onChange }) => (
                  <LineItems
                    currencyFormatter={currencyFormatter}
                    order={order}
                    onChange={onChange}
                    onRefundLineItem={(amount, description) => {
                      setRefundAmount(amount);
                      setRefundDescription(description);
                      setRefundOpen(true);
                    }}
                    value={value}
                  />
                )}
                control={control}
                defaultValue={lineItems}
                name="lineItems"
              />
            ) : (
              <>
                <Skeleton animation="wave" width="100%" />
                <Skeleton animation="wave" width="100%" />
                <Skeleton animation="wave" width="100%" />
                <Skeleton animation="wave" width="100%" />
              </>
            )}
          </FormCard>

          <FormCard title="Payment">
            {preAuthExpires && (
              <Box mb={2}>
                <Alert severity="warning">
                  This order has a payment authorization expiry date ({preAuthExpiryDate.format("MMMM Do")}) that ends
                  prior to the new fulfillment date. You will need to manually charge this order by clicking "Retry
                  Payment" after the authorization expires.
                </Alert>
              </Box>
            )}

            {orderStatus === OrderStatus.payment_failed && (
              <Box mb={2}>
                <Alert
                  action={
                    <Button
                      className={classes.paymentButton}
                      color="secondary"
                      onClick={handleOrderCharge}
                      size="small"
                      variant="contained"
                    >
                      Retry Payment
                    </Button>
                  }
                  severity="error"
                >
                  <strong>Payment failed</strong>
                </Alert>
              </Box>
            )}

            {showChargeNowButton && (
              <Box mb={2}>
                <Alert
                  action={
                    <Button
                      className={classes.paymentButton}
                      onClick={handleOrderCharge}
                      size="small"
                      variant="contained"
                    >
                      Charge Now
                    </Button>
                  }
                  severity="info"
                >
                  {isRetailOrder ? (
                    <>
                      This order is scheduled to be automatically charged on {momentFulfillmentDate.format("MMMM Do")}.
                      If you charge now you will not be able to make further changes to the order.
                    </>
                  ) : (
                    <>
                      If you charge now you will not be able to make further changes to the order. Please ensure your
                      order has been fulfilled.
                    </>
                  )}
                </Alert>
              </Box>
            )}

            <List dense disablePadding>
              <ListItem dense disableGutters>
                <ListItemText primary="Subtotal" />
                <ListItemSecondaryAction>
                  {currencyFormatter.format(parseFloat((subTotal / 100).toFixed(2)))}
                </ListItemSecondaryAction>
              </ListItem>

              <Divider className={classes.divider} component="li" variant="middle" />

              {loading ? (
                <>
                  <Skeleton animation="wave" width="100%" />
                  <Skeleton animation="wave" width="100%" />
                  <Skeleton animation="wave" width="100%" />
                </>
              ) : (
                <Controller
                  as={<FeeItems />}
                  control={control}
                  currencyFormatter={currencyFormatter}
                  defaultValue={fees}
                  order={order}
                  name="fees"
                />
              )}

              {loading ? (
                <>
                  <Skeleton animation="wave" width="100%" />
                  <Skeleton animation="wave" width="100%" />
                  <Skeleton animation="wave" width="100%" />
                </>
              ) : (
                <Controller
                  as={<DiscountItems />}
                  control={control}
                  currencyFormatter={currencyFormatter}
                  defaultValue={discounts}
                  order={order}
                  maxDiscount={totalAvaliableDiscount}
                  name="discounts"
                />
              )}

              {order?.refunds?.map(refund => {
                return (
                  <ListItem dense disableGutters key={refund.id}>
                    <ListItemText>
                      <Typography component="span" variant="body2">
                        Refund
                      </Typography>
                      {refund.description && (
                        <Typography color="textSecondary" component="span" variant="body2">
                          {" "}
                          ({refund.description})
                        </Typography>
                      )}{" "}
                    </ListItemText>
                    <ListItemSecondaryAction>
                      <Typography color="secondary" component="span" variant="body2">
                        {currencyFormatter.format(refund.amount / 100)}
                      </Typography>
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })}

              {(Boolean(fees?.filter(({ _remove }) => !_remove)?.length) ||
                Boolean(discounts?.filter(({ _remove }) => !_remove)?.length) ||
                order?.refunds?.length > 0) && <Divider className={classes.divider} component="li" variant="middle" />}

              <ListItem dense disableGutters>
                <ListItemText primary="Total" />
                <ListItemSecondaryAction>
                  {currencyFormatter.format(parseFloat((total / 100).toFixed(2)))}
                </ListItemSecondaryAction>
              </ListItem>

              <Divider className={classes.divider} component="li" variant="middle" />

              <ListItem dense disableGutters>
                <ListItemText primary="Authorized on customer card" />
                <ListItemSecondaryAction>{currencyFormatter.format(preAuthAmount / 100)}</ListItemSecondaryAction>
              </ListItem>
              <ListItem dense disableGutters>
                <ListItemText primary="Paid by customer" />
                <ListItemSecondaryAction>
                  {currencyFormatter.format((order?.charge?.amount ?? 0) / 100)}
                </ListItemSecondaryAction>
              </ListItem>
              <ListItem dense disableGutters>
                <ListItemText primary="Balance (customer owes)" />
                <ListItemSecondaryAction>
                  {orderStatus === OrderStatus.cancelled
                    ? currencyFormatter.format(0)
                    : currencyFormatter.format(Math.max(0, (total - (order?.charge?.amount ?? 0)) / 100))}
                </ListItemSecondaryAction>
              </ListItem>
            </List>

            <Typography className={classes.actions} component="div" variant="body2" color="textPrimary">
              {orderStatus === OrderStatus.paid ? (
                <Button color="secondary" size="small" variant="outlined" onClick={handleRefundAdd}>
                  Add refund
                </Button>
              ) : (
                order?.isEditable && (
                  <ButtonGroup color="primary" size="small" aria-label="payment actions">
                    <Button onClick={handleAppendFee}>Add fee</Button>
                    <Button
                      disabled={totalAvaliableDiscount <= 0}
                      onClick={handleAppendDiscount}
                      title={
                        totalAvaliableDiscount <= 0 ? "This order is free and cannot be discounted further" : undefined
                      }
                    >
                      Add discount
                    </Button>
                  </ButtonGroup>
                )
              )}
            </Typography>

            {order.stripeInvoice && (
              <Box mt={2}>
                <Divider className={classes.divider} variant="middle" />
                <Box display="flex" alignItems="center" justifyContent="flex-end">
                  <Box mx={2}>
                    <Typography variant="body2">
                      Invoice status: <strong>{order.stripeInvoice?.status}</strong>
                    </Typography>
                  </Box>
                  {order.stripeInvoice?.URL && (
                    <Button
                      color="primary"
                      variant="outlined"
                      href={order.stripeInvoice?.URL}
                      target="_blank"
                      endIcon={<ExitToAppIcon />}
                    >
                      View Invoice
                    </Button>
                  )}
                </Box>
              </Box>
            )}

            <Dialog
              open={refundOpen}
              onClose={() => setRefundOpen(false)}
              aria-labelledby="form-dialog-title-add-refund"
              fullScreen={isXS}
            >
              <DialogTitle id="form-dialog-title-add-refund">Add refund</DialogTitle>
              <DialogContent dividers>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      InputProps={{
                        onWheel: OnWheelBlur,
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      }}
                      autoFocus
                      label="Amount"
                      fullWidth
                      size="small"
                      type="number"
                      variant="outlined"
                      value={refundAmount}
                      required
                      onChange={handleRefundAmountChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Description"
                      fullWidth
                      size="small"
                      multiline={true}
                      type="text"
                      variant="outlined"
                      value={refundDescription}
                      onChange={handleRefundDescriptionChange}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleRefundCancel} color="primary" variant="outlined" tabIndex="0">
                  Cancel
                </Button>
                <Button onClick={handleRefundDone} color="secondary" variant="contained" tabIndex="0">
                  Refund
                </Button>
              </DialogActions>
            </Dialog>
          </FormCard>

          <AppFields id={match.params.id} resourceType={AppFieldResourceType.order} />
        </Grid>

        <Grid item md={4} xs={12}>
          <FormCard title={isWholesaleOrder ? "Business" : "Customer"}>
            {account ? (
              <>
                <FieldTitle title={isWholesaleOrder ? client.name : account.name} />
                <Typography>{isWholesaleOrder ? client.phone : account.phone}</Typography>
                <Typography>{account.email}</Typography>
                {account.notes && (
                  <Box mt={1}>
                    <Typography color="textSecondary" variant="body2">
                      {account.notes}
                    </Typography>
                  </Box>
                )}

                <Typography className={classes.actions} component="div" variant="body1" color="textPrimary">
                  <Link
                    component={RouterLink}
                    target="_blank"
                    to={
                      isWholesaleOrder
                        ? Routes.WHOLESALE_CLIENT_DETAILS.replace(":id", client.id)
                        : Routes.CUSTOMER_DETAILS.replace(":id", account.id)
                    }
                  >
                    View {isWholesaleOrder ? "business" : "customer"}
                  </Link>
                </Typography>
              </>
            ) : (
              <>
                <Skeleton animation="wave" width="100%" />
                <Skeleton animation="wave" width="100%" />
                <Skeleton animation="wave" width="100%" />
              </>
            )}
          </FormCard>

          <FormCard title="Status">
            {order ? (
              <>
                {order.status === OrderStatus.cancelled ? (
                  <Typography gutterBottom variant="body1">
                    Cancelled.
                  </Typography>
                ) : (
                  <Controller
                    as={<DropdownSelect />}
                    control={control}
                    defaultValue={OrderStatus.submitted}
                    disabled={!order.isEditable}
                    dropdownMargin="dense"
                    fullWidth
                    name="status"
                    options={STATUS_OPTIONS}
                    rules={{ required: "*required" }}
                  />
                )}
                {!order.isEditable && (
                  <Typography variant="body2">
                    The order status cannot be changed once it is marked as <code>paid</code> or <code>cancelled</code>.
                  </Typography>
                )}
              </>
            ) : (
              <Skeleton animation="wave" width="100%" />
            )}
          </FormCard>

          <FormCard title="Notes">
            {order ? (
              <TextField
                inputRef={register}
                fullWidth
                margin="dense"
                multiline
                name="notes"
                placeholder="Add order notes"
                variant="outlined"
              />
            ) : (
              <Skeleton animation="wave" width="100%" />
            )}
          </FormCard>

          <FormCard title="Service location">
            {orderLocation ? (
              <ServiceLocation
                disabled={!order?.isEditable}
                loading={loading}
                locations={locations}
                orderLocation={orderLocation}
                setOrderLocation={setOrderLocation}
                setStatesDirty={setStatesDirty}
                setValue={setValue}
              />
            ) : (
              <Skeleton animation="wave" width="100%" />
            )}

            <Box mt={1}>
              <Typography color="textSecondary" id="service-location-select-helper" variant="body2">
                To change the fulfillment type (e.g. delivery, pickup, shipping) please select the appropriate service
                location.
              </Typography>
            </Box>
          </FormCard>

          <FormCard title="Fulfillment">
            <Box mb={2}>
              <FormControl fullWidth margin="none">
                <FormLabel className={classes.formLabel} htmlFor="fulfillment-method">
                  This order is scheduled for
                </FormLabel>
                <Controller
                  id="fulfillment-method"
                  control={control}
                  name="fulfillmentMethod"
                  defaultValue={fulfillmentMethod}
                  rules={{ required: true }}
                  as={
                    <DropdownSelect
                      ariaDescribedby="fulfillment-method-select"
                      disabled={!order?.isEditable}
                      dropdownMargin="dense"
                      error={Boolean(errors?.fulfillmentMethod)}
                      fullWidth
                      helperText={errors?.fulfillmentMethod?.message}
                      options={[
                        { label: "Delivery", value: "delivery" },
                        { label: "Pickup", value: "pickup" },
                        { label: "Shipping", value: "shipping" },
                      ]}
                    />
                  }
                />
              </FormControl>
            </Box>

            {orderLocation ? (
              <>
                <Box mt={1}>
                  <FormControl fullWidth margin="none">
                    <FormLabel htmlFor="address-select">Fulfillment address</FormLabel>
                    <AddressForm
                      addresskey="address"
                      className={classes.addressForm}
                      control={control}
                      onReset={handleResetAddress}
                      setValue={setValue}
                      errors={errors?.address}
                      country={order?.address?.country}
                      inputClearText="Reset to initial address"
                    />
                  </FormControl>
                </Box>

                <Box mt={2}>
                  {order ? (
                    <FormControl fullWidth margin="none">
                      <FormLabel htmlFor="fulfillment-instructions">Fulfillment instructions</FormLabel>
                      <RichTextFormComponent
                        control={control}
                        defaultValue={order?.fulfillmentInstructions ?? ""}
                        id="fulfillment-instructions"
                        name="fulfillmentInstructions"
                        bounds="#root"
                        modules={{
                          clipboard: {
                            matchVisual: false,
                          },
                          toolbar: false,
                        }}
                        placeholder="Add fulfillment instructions"
                      />
                    </FormControl>
                  ) : (
                    <Skeleton animation="wave" width="100%" />
                  )}
                </Box>

                <Box mt={1}>
                  <FormControl fullWidth margin="none">
                    <FormLabel htmlFor="fulfillment-date">Fulfillment date</FormLabel>
                    <TextField
                      disabled={!order?.isEditable}
                      fullWidth
                      margin="dense"
                      name="fulfillmentDate"
                      id="fulfillment-date"
                      type="date"
                      variant="outlined"
                      inputRef={register({ required: "*required" })}
                      error={Boolean(errors?.fulfillmentDate)}
                      helperText={errors?.fulfillmentDate?.message}
                    />
                  </FormControl>
                </Box>

                <Box mt={1} display={orderLocation.fulfillmentTimes?.length > 0 ? "block" : "none"}>
                  <FormControl fullWidth margin="none">
                    <FormLabel className={classes.formLabel} htmlFor="fulfillment-time">
                      Time window
                    </FormLabel>
                    <Controller
                      id="fulfillment-time"
                      control={control}
                      name="fulfillmentTime"
                      defaultValue={fulfillmentTime}
                      as={
                        <DropdownSelect
                          disabled={!order?.isEditable}
                          dropdownMargin="dense"
                          error={Boolean(errors?.fulfillmentTime)}
                          fullWidth
                          helperText={errors?.fulfillmentTime?.message}
                          options={orderLocation.fulfillmentTimes?.map(({ id, label }) => ({ label, value: id }))}
                        />
                      }
                    />
                  </FormControl>
                </Box>

                {(fulfillmentMethod === "shipping" || order?.trackingCode) && (
                  <Box mt={1}>
                    <FormControl fullWidth margin="none">
                      <FormLabel htmlFor="tracking-code">Tracking code</FormLabel>
                      <TextField
                        inputRef={register}
                        fullWidth
                        margin="dense"
                        id="tracking-code"
                        name="trackingCode"
                        placeholder="Add tracking code"
                        variant="outlined"
                      />
                    </FormControl>
                  </Box>
                )}
              </>
            ) : (
              <>
                <Skeleton animation="wave" width="100%" />
                <Skeleton animation="wave" width="100%" />
                <Skeleton animation="wave" width="100%" />
              </>
            )}
          </FormCard>
        </Grid>
      </Grid>

      <ConfirmationModal
        cancelRequestButtonText="Cancel"
        confirmRequestButtonText="Save"
        isDangerAction
        modalTitle="Warning"
        onCloseModal={() =>
          setOpenWarningModal({
            [WARNING_TYPE.PRE_AUTH_EXCEEDS]: false,
            [WARNING_TYPE.STATUS_CHANGE]: false,
          })
        }
        onConfirmClick={onConfirmSubmit}
        shouldOpenModal={
          openWarningModal[WARNING_TYPE.PRE_AUTH_EXCEEDS] || openWarningModal[WARNING_TYPE.STATUS_CHANGE]
        }
      >
        {openWarningModal[WARNING_TYPE.PRE_AUTH_EXCEEDS] && openWarningModal[WARNING_TYPE.STATUS_CHANGE] ? (
          <ul className={classes.warningList}>
            <li>
              <StatusChangeWarning
                invoiceNumber={order?.invoiceNumber}
                oldStatus={STATUS_UPDATE[orderStatus]}
                newStatus={STATUS_UPDATE[statusNew]}
              />
            </li>
            <li>
              <PreAuthExceedsWarning />
            </li>
          </ul>
        ) : openWarningModal[WARNING_TYPE.STATUS_CHANGE] ? (
          <StatusChangeWarning
            invoiceNumber={order?.invoiceNumber}
            oldStatus={STATUS_UPDATE[orderStatus]}
            newStatus={STATUS_UPDATE[statusNew]}
          />
        ) : (
          <PreAuthExceedsWarning />
        )}
      </ConfirmationModal>

      <Dialog
        open={openUnSavedChangesModal}
        onClose={() => setOpenUnSavedChangesModal(false)}
        aria-labelledby="unsaved-change-warning"
        aria-describedby="unsaved-change"
      >
        <DialogTitle id="unsaved-change-warning">Unsaved changes</DialogTitle>
        <DialogContent>
          <DialogContentText id="unsaved-change">
            This order has unsaved changes - you must click save prior to charging the payment.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenUnSavedChangesModal(false)} color="primary" autoFocus variant="contained">
            OK
          </Button>
        </DialogActions>
      </Dialog>

      {showSignatureButton && (
        <OrderSignatureModal order={showSignatureModal ? order : null} onClose={onSignatureModalClose} />
      )}

      {process.env.NODE_ENV === "development" && <DevTool control={control} />}
    </>
  );
};

export default OrderDetails;
