import { gql, useQuery } from "@apollo/client";
import { FEATURE_SUPPLIER_APPS_WISE } from "@arowana/flags";
import { DATALAYER } from "@arowana/util";
import { makeStyles } from "@material-ui/core";
import { useContext, useMemo } from "react";

import FormCard from "../../../components/FormCard";
import Loader from "../../../components/Loader";
import PageHeader from "../../../components/PageHeader";
import { supplierIntegrations } from "../../../queries/supplier";
import { FlagsmithContext } from "../../context/FlagsmithContext";
import AppCard from "../components/AppCard";

const useStyles = makeStyles(theme => ({
  appCard: {
    padding: theme.spacing(2),
  },
}));

const ALL_APPS = {
  drift: {
    header: "Drift",
    imgSrc: "/images/drift_logo.png",
    isInstalled: obj => obj?.embedId,
    subheader: "Integrated live chat for increased customer experience and loyalty.",
  },
  facebook: {
    header: "Facebook Pixel",
    imgSrc: "/images/facebook_logo.svg",
    isInstalled: obj => obj?.pixelKey,
    subheader: "Measure customer actions, build audiences and unlock optimization tools.",
  },
  googleAnalytics: {
    header: "Google Analytics",
    imgSrc: "/images/google_analytics_logo.svg",
    isInstalled: obj => obj?.googleAnalyticsKey,
    subheader: "Measure your advertising ROI as well as track your website audiences.",
  },
  googleTagManager: {
    header: "Google Tag Manager",
    imgSrc: "/images/google_tag_manager_logo.svg",
    isInstalled: obj => obj?.id,
    subheader: "Add and update your own tags for conversion tracking, site analytics, remarketing, and more",
  },
  hotjar: {
    header: "Hotjar",
    imgSrc: "/images/hotjar_logo.png",
    isInstalled: obj => obj?.hjid,
    subheader: "User monitoring and recording to see how visitors are really shopping.",
  },
  shipping: {
    isInstalled: obj => obj?.carriers?.length > 0,
    shipengine: true,
  },
  wise: {
    flag: FEATURE_SUPPLIER_APPS_WISE,
    header: "Wise Systems",
    imgSrc: "/images/wise_logo.svg",
    isInstalled: obj => obj?.userName,
    subheader: "Optimize your last mile delivery with Wise System",
  },
};

const byInstalled = (x, y) => (x.installed && y.installed ? x.key.localeCompare(y.key) : x.installed ? -1 : 1);

const getDisplayNameAndLogo = name => {
  switch (name) {
    case "fedex":
      return { header: "FedEx", imgSrc: "/images/fedex_logo.svg" };

    case "purolator":
      return { header: "Purolator", imgSrc: "/images/purolator_logo.svg" };

    case "ups":
      return { header: "UPS", imgSrc: "/images/ups_logo.svg" };

    default:
      return { header: "Ship Engine", imgSrc: "/images/shipengine_logo.svg" };
  }
};

const getCarrierInfo = carriers => {
  const { header, imgSrc } = getDisplayNameAndLogo(carriers?.[0]?.name);

  return {
    carrierId: carriers?.[0]?.id,
    defaultServiceCode: carriers?.[0]?.defaultServiceCode,
    header,
    imgSrc,
    installed: carriers?.length > 0,
    subheader: "Enable carrier shipping estimates by weight and location.",
  };
};

export const INTEGRATIONS_FRAGMENT = gql`
  fragment IntegrationsFragment on SupplierIntegrations {
    mailchimp {
      apiKey
      serverPrefix
      signUpListId
      enableEmailCollection
    }
    drift {
      embedId
    }
    facebook {
      pixelKey
    }
    googleAnalytics {
      googleAnalyticsKey
    }
    googleTagManager {
      id
    }
    hotjar {
      hjid
    }
    shipengine {
      carriers {
        id: carrierID
        defaultServiceCode
        name
        region
      }
    }
    wise {
      userName
    }
  }
`;

export const INTEGRATIONS = gql`
  query SupplierIntegrations {
    currentSupplier {
      id
      integrations {
        ...IntegrationsFragment
      }
    }
  }
  ${INTEGRATIONS_FRAGMENT}
`;

const Apps = () => {
  const classes = useStyles();
  const flagsmith = useContext(FlagsmithContext);

  const { data, loading } = useQuery(INTEGRATIONS, {
    context: { source: DATALAYER },
    fetchPolicy: "cache-and-network",
  });

  const { integrations } = data?.currentSupplier ?? {};
  const apps = useMemo(() => {
    return Object.entries(ALL_APPS)
      .filter(([, { flag }]) => (flag ? flagsmith.hasFeature(flag) : true))
      .map(([key, { isInstalled, shipengine, ...rest }]) => ({
        installed: isInstalled(integrations?.[key]),
        key,
        ...(shipengine && getCarrierInfo(integrations?.shipengine?.carriers)),
        ...rest,
      }))
      ?.sort(byInstalled);
  }, [integrations]);

  return (
    <>
      {loading && <Loader />}
      <PageHeader stickyHeader title="Apps" />
      {apps.map(({ carrierId, header, imgSrc, installed, key, defaultServiceCode, subheader }) => (
        <FormCard className={classes.appCard} key={key}>
          <AppCard
            appId={key}
            carrierId={carrierId}
            defaultServiceCode={defaultServiceCode}
            header={header}
            imgSrc={imgSrc}
            installed={installed}
            subheader={subheader}
          />
        </FormCard>
      ))}
    </>
  );
};

export default Apps;
