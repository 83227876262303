import { Address, Location } from "@arowana/graphql";
import { Button, Fade, FormControl, FormLabel, makeStyles } from "@material-ui/core";
import { SyncOutlined as FlipIcon } from "@material-ui/icons";

import AddressSelect from "./AddressSelect";
import NewAddressForm from "./NewAddressForm";
import PickupLocationSelect from "./PickupLocationSelect";

const useStyles = makeStyles(theme => ({
  label: {
    alignItems: "baseline",
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: theme.spacing(1),
  },
}));

type FulfillmentAddressSelectProps = {
  account: { id: string };
  isPickup: boolean;
  pickupLocations: Location[];
  onPickupLocationChange: (event) => void;
  pickupLocationId: string;
  showAddressForm: boolean;
  deliveryAvailable: boolean;
  deliveryAddressId: string;
  deliveryAddresses: Address[];
  onDeliveryAddressChange: (event) => void;
  pickupAvailable: boolean;
  onFulfillmentMethodToggle: () => void;
  onAdded: (address: Address) => void;
  onCancelAdd: () => void;
  showAddOption?: boolean;
};

export const FulfillmentAddressSelect = ({
  account,
  isPickup,
  pickupLocations,
  onPickupLocationChange,
  pickupLocationId,
  showAddressForm,
  deliveryAvailable,
  deliveryAddressId,
  deliveryAddresses,
  onDeliveryAddressChange,
  pickupAvailable,
  onFulfillmentMethodToggle,
  onAdded,
  onCancelAdd,
  showAddOption = true,
}: FulfillmentAddressSelectProps) => {
  const classes = useStyles();

  let input;

  if (isPickup) {
    input = (
      <PickupLocationSelect locations={pickupLocations} onChange={onPickupLocationChange} value={pickupLocationId} />
    );
  } else if (!showAddressForm && deliveryAvailable) {
    input = (
      <AddressSelect
        addresses={deliveryAddresses}
        value={deliveryAddressId}
        onChange={onDeliveryAddressChange}
        showAddOption={showAddOption}
      />
    );
  } else if (showAddressForm || !deliveryAddresses) {
    input = (
      <NewAddressForm
        account={account}
        hasExistingAddress={deliveryAddresses?.length > 0}
        onSuccess={onAdded}
        onCancel={onCancelAdd}
      />
    );
  }

  return (
    <FormControl fullWidth margin="dense" variant="outlined">
      <FormLabel className={classes.label}>
        {isPickup ? "Pickup" : "Delivery"} address
        <Fade in={pickupAvailable && deliveryAvailable && !showAddressForm}>
          <Button size="small" onClick={onFulfillmentMethodToggle} startIcon={<FlipIcon />}>
            Switch to {isPickup ? "delivery" : "pickup"}
          </Button>
        </Fade>
      </FormLabel>
      {input}
    </FormControl>
  );
};

export default FulfillmentAddressSelect;
