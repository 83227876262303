import { MaskedField, phoneMask } from "@arowana/ui";
import { Grid, TextField, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import { Controller } from "react-hook-form";

import Address from "../../account/components/Address";
const validatePhone = value => !/_/gi.test(value);

const GeneralFields = ({ clearErrors, control, errors, register, setValue, showMeterNumber }) => {
  return (
    <>
      <Grid item sm={6} xs={12}>
        <Typography gutterBottom variant="subtitle1">
          Email
        </Typography>
        <TextField
          error={Boolean(errors?.email)}
          fullWidth
          helperText={errors?.email?.message}
          inputRef={register({
            required: "*required",
          })}
          name="email"
          variant="outlined"
        />
      </Grid>
      <Grid item sm={6} xs={12}>
        <Typography gutterBottom variant="subtitle1">
          Phone number
        </Typography>
        <Controller
          allowEmptyFormatting
          as={MaskedField}
          control={control}
          defaultValue=""
          displayType="input"
          error={Boolean(errors?.phone)}
          format={phoneMask}
          fullWidth
          helperText={errors?.phone?.message}
          isNumericString
          mask="_"
          name="phone"
          rules={{
            required: "*required",
            validate: validatePhone,
          }}
          type="tel"
        />
      </Grid>
      <Grid item sm={6} xs={12}>
        <Typography gutterBottom variant="subtitle1">
          Account number
        </Typography>
        <TextField
          error={Boolean(errors?.accountNumber)}
          fullWidth
          helperText={errors?.accountNumber?.message}
          inputRef={register({
            required: "*required",
          })}
          name="accountNumber"
          variant="outlined"
        />
        <Typography color="textSecondary" variant="caption">
          Find this information by clicking here after logging into your{" "}
          <a
            href="https://www.ups.com/us/en/services/knowledge-center/article.page?kid=art16f23c85d4a#:~:text=You%20can%20find%20your%20UPS,up%20to%20UPS%20Billing%20Center"
            rel="noreferrer"
            target="_blank"
          >
            UPS
          </a>{" "}
          or{" "}
          <a
            href="https://ask.fedex.com/help/en-nl/account-payments/forgot-account-number#:~:text=Your%20account%20number%20is%20a,you%20with%20your%20account%20number"
            rel="noreferrer"
            target="_blank"
          >
            FedEx Account
          </a>
          .
        </Typography>
      </Grid>
      <Grid item sm={6} xs={12}>
        <Typography gutterBottom variant="subtitle1">
          Company name
        </Typography>
        <TextField
          error={Boolean(errors?.company)}
          fullWidth
          helperText={errors?.company?.message}
          inputRef={register({
            required: "*required",
          })}
          name="company"
          variant="outlined"
        />
      </Grid>

      <Grid item sm={6} xs={12}>
        <Typography gutterBottom variant="subtitle1">
          First name
        </Typography>
        <TextField
          error={Boolean(errors?.firstName)}
          fullWidth
          helperText={errors?.firstName?.message}
          inputRef={register({
            required: "*required",
          })}
          name="firstName"
          variant="outlined"
        />
      </Grid>
      <Grid item sm={6} xs={12}>
        <Typography gutterBottom variant="subtitle1">
          Last name
        </Typography>
        <TextField
          error={Boolean(errors?.lastName)}
          fullWidth
          helperText={errors?.lastName?.message}
          inputRef={register({
            required: "*required",
          })}
          name="lastName"
          variant="outlined"
        />
      </Grid>
      {showMeterNumber && (
        <Grid item xs={12}>
          <Typography gutterBottom variant="subtitle1">
            FedEx Meter number
          </Typography>
          <TextField
            error={Boolean(errors?.meterNumber)}
            fullWidth
            helperText={errors?.meterNumber?.message}
            inputRef={register({
              required: "*required",
            })}
            name="meterNumber"
            variant="outlined"
          />
          <Typography color="textSecondary" variant="caption">
            Find this information by following the{" "}
            <a
              href="https://support.shippingeasy.com/hc/en-us/articles/203087899-Find-my-FedEx-Meter-Number-How-To"
              rel="noreferrer"
              target="_blank"
            >
              the instructions here
            </a>
            .
          </Typography>
        </Grid>
      )}
      <Address
        clearErrors={clearErrors}
        control={control}
        errors={errors}
        hasCountryCode
        hasProvinceCode
        register={register}
        setValue={setValue}
        streetAddressLabel="Street address"
      />
    </>
  );
};

GeneralFields.defaultProps = {
  showMeterNumber: false,
};

GeneralFields.propTypes = {
  clearErrors: PropTypes.func.isRequired,
  control: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  register: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  showMeterNumber: PropTypes.bool,
};

export default GeneralFields;
