import { Grid, Typography } from "@material-ui/core";
import pluralize from "pluralize";

import FormCard from "../../../components/FormCard";

interface DiscountStatisticsProps {
  totalAmount: number;
  totalTimes: number;
}

const DiscountStatistics = ({ totalAmount, totalTimes }: DiscountStatisticsProps) => {
  return (
    <FormCard title="Usage statistics">
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography gutterBottom variant="subtitle1">
            Total uses
          </Typography>
          <Typography variant="body2">{pluralize("time", totalTimes, true)}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography gutterBottom variant="subtitle1">
            Total budget used
          </Typography>
          <Typography variant="body2">${totalAmount}</Typography>
        </Grid>
      </Grid>
    </FormCard>
  );
};

export default DiscountStatistics;
