import { makeStyles, Typography } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles(theme => ({
  subtitle: { fontWeight: 500 },
}));
const FieldTitle = ({ title, tooltip = null }) => {
  const classes = useStyles();

  return (
    <Typography className={classes.subtitle} gutterBottom>
      {title} {tooltip}
    </Typography>
  );
};

export default FieldTitle;
