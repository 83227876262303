import { Checkbox, FormControl, FormHelperText, Grid, Link, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { Controller } from "react-hook-form";

import SwitchToggle from "../../account/components/SwitchToggle";
import GeneralFields from "./GeneralFields";

const useStyles = makeStyles(theme => ({
  switch: {
    marginLeft: 0,
  },
}));

const FedEx = ({ clearErrors, control, errors, register, setValue }) => {
  const classes = useStyles();

  return (
    <FormControl component="fieldset" fullWidth margin="normal">
      <Grid container spacing={2}>
        <GeneralFields
          clearErrors={clearErrors}
          control={control}
          errors={errors}
          register={register}
          setValue={setValue}
          showMeterNumber
        />
        <Grid item xs={12}>
          <Typography variant="subtitle1">Terms of service</Typography>
          <Controller
            as={<SwitchToggle />}
            className={classes.switch}
            component={<Checkbox color="primary" />}
            control={control}
            defaultValue={false}
            label={
              <Typography variant="body2">
                By setting up a FedEx account, you and your users agree to the{" "}
                <Link
                  href="https://www.fedex.com/content/dam/fedex-com/irc/legal/eula.pdf"
                  rel="noreferrer"
                  target="_blank"
                >
                  EULA Terms of Use.
                </Link>
              </Typography>
            }
            labelPlacement="end"
            name="agreeToEula"
            rules={{ required: "*required" }}
            type="checkbox"
          />
          <FormHelperText error>{errors?.agreeToEula?.message}</FormHelperText>
        </Grid>
      </Grid>
    </FormControl>
  );
};

export default FedEx;
