const getDefaultFulfillmentDays = () => [
  {
    checked: false,
    fulfilledOn: 1,
    orderBy: 7,
    orderByOptions: [7, 1, 2, 3, 4, 5, 6],
  },
  {
    checked: false,
    fulfilledOn: 2,
    orderBy: 1,
    orderByOptions: [1, 2, 3, 4, 5, 6, 7],
  },
  {
    checked: false,
    fulfilledOn: 3,
    orderBy: 2,
    orderByOptions: [2, 3, 4, 5, 6, 7, 1],
  },
  {
    checked: false,
    fulfilledOn: 4,
    orderBy: 3,
    orderByOptions: [3, 4, 5, 6, 7, 1, 2],
  },
  {
    checked: false,
    fulfilledOn: 5,
    orderBy: 4,
    orderByOptions: [4, 5, 6, 7, 1, 2, 3],
  },
  {
    checked: false,
    fulfilledOn: 6,
    orderBy: 5,
    orderByOptions: [5, 6, 7, 1, 2, 3, 4],
  },
  {
    checked: false,
    fulfilledOn: 7,
    orderBy: 6,
    orderByOptions: [6, 7, 1, 2, 3, 4, 5],
  },
];

const populateFulfillmentDays = value => {
  const formValue = getDefaultFulfillmentDays();

  value.forEach(entry => {
    const day = formValue[entry.fulfilledOn - 1];
    day.orderBy = entry.orderBy;
    day.checked = true;
    formValue[entry.fulfilledOn - 1] = day;
  });

  return formValue;
};

export { getDefaultFulfillmentDays, populateFulfillmentDays };
