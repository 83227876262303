import { usePasswordToggle } from "@arowana/ui";
import { FormControl, FormLabel, Grid, TextField } from "@material-ui/core";

const AccountCreateForm = ({ formMethods, isEdit = false }) => {
  const { errors, register, watch } = formMethods;
  const { PasswordToggle, passwordInputType } = usePasswordToggle(watch, "password", { size: "small" });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <FormControl fullWidth margin="normal">
          <FormLabel htmlFor="name">Name</FormLabel>
          <TextField
            autoComplete="off"
            error={Boolean(errors?.name)}
            fullWidth
            helperText={errors?.name?.message}
            id="name"
            inputRef={register({ required: "*required" })}
            margin="dense"
            name="name"
            required
            variant="outlined"
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={6}>
        <FormControl fullWidth margin="normal">
          <FormLabel htmlFor="email">Email</FormLabel>
          <TextField
            autoComplete="off"
            error={Boolean(errors?.email)}
            fullWidth
            helperText={errors?.email?.message}
            id="email"
            inputRef={register({ required: "*required" })}
            margin="dense"
            name="email"
            required
            variant="outlined"
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={6}>
        <FormControl fullWidth margin="normal">
          <FormLabel htmlFor="phone">Phone</FormLabel>
          <TextField
            autoComplete="off"
            error={Boolean(errors?.phone)}
            fullWidth
            helperText={errors?.phone?.message}
            id="phone"
            inputRef={register({ required: "*required" })}
            margin="dense"
            name="phone"
            required
            type="tel"
            variant="outlined"
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} md={6}>
        <FormControl fullWidth margin="normal">
          <FormLabel htmlFor="password">Password</FormLabel>
          <TextField
            autoComplete="off"
            error={Boolean(errors?.password)}
            fullWidth
            helperText={errors?.password?.message}
            id="password"
            inputRef={isEdit ? register : register({ required: "*required" })}
            InputProps={{ endAdornment: PasswordToggle }}
            margin="dense"
            name="password"
            required={!isEdit}
            placeholder={isEdit ? "Leave blank if unchanged" : ""}
            type={passwordInputType}
            variant="outlined"
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default AccountCreateForm;
