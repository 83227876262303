import { Box, Button, makeStyles, Typography } from "@material-ui/core";
import { AddCircleOutline as AddCircleOutlineIcon } from "@material-ui/icons";
import React, { useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import FormCard from "../../../components/FormCard";
import NavLink from "./NavLink";
import NavLinkForm from "./NavLinkForm";

const useStyles = makeStyles(theme => ({
  linkCard: {
    padding: theme.spacing(2, 4),
  },
  linkRow: {
    marginBottom: theme.spacing(2),
  },
}));
const DynamicLinks = ({ label, className, value, onChange }) => {
  const classes = useStyles();
  const links = value.slice();
  const [editingIndex, setEditingIndex] = useState(-1);
  const [openAddLinkForm, setOpenAddLinkForm] = useState(false);
  const linkEditing = editingIndex > -1;
  const handleAddLink = () => setOpenAddLinkForm(true);
  const handleCancelAddLink = () => setOpenAddLinkForm(false);
  const handleRemoveLink = index => {
    links.splice(index, 1);
    onChange(links);
  };
  const handleEditLink = index => setEditingIndex(index);
  const handleCloseForm = () => setEditingIndex(-1);
  const handleReorderLinks = result => {
    // illegal drop
    if (!result?.destination || !result?.source || result?.reason === "CANCEL") return;

    const sourceIdx = result.source.index ?? -1;
    const destIdx = result.destination.index ?? -1;

    // illegal drop or drop to the original place
    if (sourceIdx < 0 || destIdx < 0 || sourceIdx === destIdx) return;

    const movedLink = links.splice(sourceIdx, 1)[0];
    links.splice(destIdx, 0, movedLink);
    onChange(links);
  };
  const handleUpdateLink = editedLink => {
    links[editingIndex] = editedLink;
    setEditingIndex(-1);
    onChange(links);
  };
  const handleSubmitLink = link => {
    links.push(link);
    setOpenAddLinkForm(false);
    onChange(links);
  };

  return (
    <Box className={className}>
      <Typography gutterBottom variant="subtitle1">
        {label}
      </Typography>

      <DragDropContext onDragEnd={handleReorderLinks}>
        <Droppable droppableId={label}>
          {providedFromContainer => (
            <div {...providedFromContainer.droppableProps} ref={providedFromContainer.innerRef}>
              {links.map((link, index) => (
                <Draggable
                  draggableId={`${label}_${index}_draggable_link`}
                  index={index}
                  isDragDisabled={linkEditing}
                  key={`${label}_${index}_draggable_link`}
                >
                  {(providedFromItem, snapshot) => (
                    <Box
                      className={classes.linkRow}
                      key={`${label}_${index}`}
                      ref={providedFromItem.innerRef}
                      {...providedFromItem.draggableProps}
                    >
                      <FormCard className={classes.linkCard}>
                        {editingIndex === index ? (
                          <NavLinkForm link={link} onCloseForm={handleCloseForm} onSubmit={handleUpdateLink} />
                        ) : (
                          <NavLink
                            dragHandleProps={providedFromItem.dragHandleProps}
                            link={link}
                            linkEditing={linkEditing}
                            onEditLink={() => handleEditLink(index)}
                            onRemoveLink={() => handleRemoveLink(index)}
                          />
                        )}
                      </FormCard>
                    </Box>
                  )}
                </Draggable>
              ))}
              {providedFromContainer.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {openAddLinkForm && (
        <FormCard className={classes.linkCard}>
          <NavLinkForm onCloseForm={handleCancelAddLink} onSubmit={handleSubmitLink} />
        </FormCard>
      )}
      <Button
        color="primary"
        disabled={linkEditing || openAddLinkForm}
        fullWidth
        onClick={handleAddLink}
        startIcon={<AddCircleOutlineIcon />}
        variant="outlined"
      >
        Add Menu Item
      </Button>
    </Box>
  );
};

export default DynamicLinks;
