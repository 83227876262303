import { ErrorPage } from "@arowana/ui";
import { makeStyles } from "@material-ui/core";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(8),
  },
}));

export const NotFoundPage = () => {
  const classes = useStyles();
  const history = useHistory();
  useEffect(() => {
    document.title = "404: Page Not Found";
  }, []);

  return (
    <ErrorPage
      className={classes.container}
      content="Unfortunately, the page you are trying to reach is no longer available. We apologize for the inconvenience."
      handleGoBack={() => history.push("/")}
      title="Page not found"
    />
  );
};
