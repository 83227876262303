import { gql, useMutation, useQuery } from "@apollo/client";
import {
  AvailabilitySchedule,
  AvailabilityScheduleCreateInput,
  AvailabilityScheduleDeleteInput,
  AvailabilityScheduleUpdateInput,
  DateRangeInput,
} from "@arowana/graphql";
import { SupplierContext } from "@arowana/ui";
import { DATALAYER } from "@arowana/util";
import { DevTool } from "@hookform/devtools";
import {
  Box,
  Button,
  Collapse,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  makeStyles,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@material-ui/core";
import { etEE } from "@material-ui/core/locale";
import moment, { Moment } from "moment-timezone";
import { useContext, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Prompt } from "react-router-dom";

import { notificationVar } from "../../cache/notificationPolicy";
import ConfirmationModal from "../../components/ConfirmationModal";
import FormCard from "../../components/FormCard";
import Loader from "../../components/Loader";
import PageHeader from "../../components/PageHeader";
import RichTextFormComponent from "../../components/RichTextFormComponent";
import Routes from "../../Constants/Routes";
import SwitchToggle from "../account/components/SwitchToggle";
import { DateRangeFormValue } from "./components/DateRangeFormDialog";
import DateRangesInput from "./components/DateRangesInput";
import ListsInput, { ListValue } from "./components/ListsInput";
import LocationsInput, { LocationValue } from "./components/LocationsInput";

const AVAILABILITY_SCHEDULE_FRAGMENT = gql`
  fragment AvailabilityScheduleFragment on AvailabilitySchedule {
    id
    activeAfter
    allowed {
      start
      end
    }
    blocked {
      start
      end
    }
    description
    name
    lists {
      id
      name
    }
    locations {
      id
      name
      fulfillmentMethod
    }
    updatedAt
  }
`;

const FIND = gql`
  query AvailabilitySchedule($id: ID!) {
    availabilitySchedule(id: $id) {
      ...AvailabilityScheduleFragment
    }
  }
  ${AVAILABILITY_SCHEDULE_FRAGMENT}
`;

const CREATE = gql`
  mutation AvailabilityScheduleCreate($input: AvailabilityScheduleCreateInput!) {
    availabilityScheduleCreate(input: $input) {
      id
    }
  }
`;

const UPDATE = gql`
  mutation AvailabilityScheduleUpdate($input: AvailabilityScheduleUpdateInput!) {
    availabilityScheduleUpdate(input: $input) {
      ...AvailabilityScheduleFragment
    }
  }
  ${AVAILABILITY_SCHEDULE_FRAGMENT}
`;

const DELETE = gql`
  mutation AvailabilityScheduleDelete($input: AvailabilityScheduleDeleteInput!) {
    availabilityScheduleDelete(input: $input) {
      id
    }
  }
`;

const useStyles = makeStyles(theme => ({
  minHeight: {
    minHeight: 150,
  },
  quill: {
    marginBottom: theme.spacing(0.5),
    marginTop: theme.spacing(1),
  },
}));

interface FormProps {
  activeAfter: string;
  allowed: Array<DateRangeFormValue>;
  blocked: Array<DateRangeFormValue>;
  description: string;
  lists: Array<ListValue>;
  locations: Array<LocationValue>;
}

const DEFAULT: FormProps = {
  activeAfter: moment().format("YYYY-MM-DD"),
  allowed: [],
  blocked: [],
  description: "",
  lists: [],
  locations: [],
};

const toFormData = (availabilitySchedule: AvailabilitySchedule, timezone: string): FormProps => ({
  activeAfter: (availabilitySchedule.activeAfter
    ? moment.tz(availabilitySchedule.activeAfter, timezone)
    : moment()
  ).format("YYYY-MM-DD"),
  allowed: availabilitySchedule.allowed ?? [],
  blocked: availabilitySchedule.blocked ?? [],
  description: availabilitySchedule.description ?? "",
  lists: availabilitySchedule.lists ?? [],
  locations: availabilitySchedule.locations ?? [],
  name: availabilitySchedule.name,
});

const toCreateInput = (formData: FormProps, timezone: string): AvailabilityScheduleCreateInput => {
  return {
    activeAfter: moment.tz(formData.activeAfter, timezone).format(),
    allowed: formData.allowed,
    blocked: formData.blocked,
    description: formData.description,
    listIds: formData.lists.map(({ id }) => id),
    locationIds: formData.locations.map(({ id }) => id),
    name: formData.name,
  };
};

const toUpdateInput = (id: string, formData: FormProps, timezone: string): AvailabilityScheduleUpdateInput => ({
  id,
  ...toCreateInput(formData, timezone),
});

const AvailabilitiesDetails = ({ history, match }) => {
  const classes = useStyles();
  const { timezone } = useContext(SupplierContext);

  const id = match.params?.id;
  const isCreate = !id;

  const {
    handleSubmit,
    control,
    register,
    reset,
    watch,
    formState: { errors, isDirty },
  } = useForm<FormProps>({
    defaultValues: DEFAULT,
  });
  const allowedLength = watch<Array<DateRangeInput>>("allowed").length;
  const blockedLength = watch<Array<DateRangeInput>>("blocked").length;
  const listLength = watch<Array>("lists").length;
  const locationLength = watch<Array>("locations").length;

  const { data, loading } = useQuery<{ availabilitySchedule: AvailabilitySchedule }>(FIND, {
    context: { source: DATALAYER },
    onCompleted: ({ availabilitySchedule }) =>
      reset(toFormData(availabilitySchedule, timezone), { dirtyFields: false, touched: false }),
    skip: isCreate,
    variables: { id },
  });
  const updatedAt: Moment | null = useMemo(() => {
    const ts = data?.availabilitySchedule?.updatedAt;

    return ts ? moment.utc(ts) : null;
  }, []);

  const [create, { loading: creating }] = useMutation<
    { availabilityScheduleCreate: Pick<AvailabilitySchedule | "id"> },
    { input: AvailabilityScheduleCreateInput }
  >(CREATE, {
    context: { source: DATALAYER },
    onCompleted: ({ availabilityScheduleCreate }) => {
      notificationVar({
        message: "Availability override created!",
        severity: "success",
      });
      history.replace(Routes.STORE_AVAILABILITIES_DETAILS.replace(":id", availabilityScheduleCreate.id));
    },
  });

  const [update, { loading: updating }] = useMutation<
    { availabilityScheduleUpdate: AvailabilitySchedule },
    { input: AvailabilityScheduleUpdateInput }
  >(UPDATE, {
    context: { source: DATALAYER },
    onCompleted: () => {
      notificationVar({
        message: "Availability override updated!",
        severity: "success",
      });
    },
  });

  const [_delete, { loading: deleting }] = useMutation<
    { availabilityScheduleDelete: Pick<AvailabilitySchedule | "id"> },
    { input: AvailabilityScheduleDeleteInput }
  >(DELETE, {
    context: { source: DATALAYER },
    onCompleted: ({ availabilityScheduleDelete }) => {
      if (availabilityScheduleDelete?.id) {
        notificationVar({
          message: "Availability override deleted!",
          severity: "success",
        });
        history.replace(Routes.STORE_AVAILABILITIES);
        setClickedSave(false);
      }
    },
    variables: {
      input: {
        id,
        reason: "deleted by supplier",
      },
    },
  });

  const [clickedSave, setClickedSave] = useState(false);
  const hasUnSavedChanges = isDirty && !clickedSave;

  const onSubmit = (formData: FormProps) => {
    setClickedSave(true);

    if (isCreate) {
      create({
        variables: {
          input: toCreateInput(formData, timezone),
        },
      });
    } else {
      update({
        variables: {
          input: toUpdateInput(id, formData, timezone),
        },
      });
    }
  };

  const onDiscard = () => {
    if (isCreate) {
      reset(DEFAULT);
    } else if (data) {
      reset(toFormData(data.availabilitySchedule, timezone), { dirtyFields: false });
    }
  };

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const onDeleteModalClose = () => setShowDeleteModal(false);
  const onDelete = () => setShowDeleteModal(true);
  const onDeleteConfirm = () => _delete();

  const isLoading = loading || creating || updating || deleting;

  return (
    <>
      <Loader loading={isLoading} />
      <Prompt
        message={`${
          isCreate ? "The override is incomplete" : "Your changes are not saved"
        }; are you sure you want to leave?`}
        when={hasUnSavedChanges}
      />
      <PageHeader
        stickyHeader
        title={`${isCreate ? "Create" : "Update"} availability override`}
        primaryActions={
          <>
            <Button color="primary" disabled={!isDirty} onClick={onDiscard} variant="outlined">
              Discard
            </Button>
            <Button
              color="primary"
              disabled={!isDirty || isLoading}
              onClick={handleSubmit(onSubmit)}
              variant="contained"
            >
              Save
            </Button>
          </>
        }
      >
        {updatedAt && (
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography color="textSecondary" title={updatedAt.format("LL")} variant="body2">
              Last updated {updatedAt.fromNow()}
            </Typography>
            <Button color="secondary" disabled={deleting} onClick={onDelete}>
              Delete
            </Button>
          </Box>
        )}
      </PageHeader>

      <FormCard>
        <FormControl fullWidth margin="dense">
          <FormLabel htmlFor="name">Name</FormLabel>
          <TextField
            autoComplete="off"
            error={Boolean(errors?.name)}
            fullWidth
            helperText={errors?.name?.message}
            id="name"
            inputRef={register({ required: "*required" })}
            margin="dense"
            name="name"
            placeholder="Early Holiday Orders"
            variant="outlined"
          />
        </FormControl>
        <FormControl fullWidth margin="normal">
          <FormLabel htmlFor="description">Internal description</FormLabel>
          <RichTextFormComponent
            aria-describedby="description-helper"
            className={classes.quill}
            control={control}
            helperText={errors?.description?.message}
            id="description"
            name="description"
            bounds="#root"
            required
          />
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="activeAfter">Active after</FormLabel>
          <TextField
            type="date"
            error={Boolean(errors?.activeAfter)}
            helperText={errors?.activeAfter?.message}
            id="activeAfter"
            inputRef={register({ required: "*required" })}
            inputProps={{
              min: DEFAULT.activeAfter,
            }}
            name="activeAfter"
            variant="outlined"
            margin="dense"
          />
        </FormControl>
      </FormCard>
      <Grid container spacing={2}>
        <Grid item md={6} xs={12}>
          <FormCard title="Allowed dates">
            <Controller
              as={DateRangesInput}
              control={control}
              errorMsg={errors?.allowed?.message}
              rules={{
                required: true,
                validate: v => blockedLength > 0 || v.length > 0 || "*required (either allowed or blocked)",
              }}
              name="allowed"
            />
          </FormCard>
        </Grid>
        <Grid item md={6} xs={12}>
          <FormCard title="Blocked dates">
            <Controller
              as={DateRangesInput}
              control={control}
              errorMsg={errors?.blocked?.message}
              rules={{
                required: true,
                validate: v => allowedLength > 0 || v.length > 0 || "*required (either allowed or blocked)",
              }}
              name="blocked"
            />
          </FormCard>
        </Grid>
        <Grid item md={6} xs={12}>
          <FormCard className={classes.minHeight} title="Product collections">
            <Controller
              as={ListsInput}
              control={control}
              errorMsg={errors?.lists?.message}
              rules={{
                required: true,
                validate: v =>
                  locationLength > 0 || v.length > 0 || "*required (either collections or service locations)",
              }}
              name="lists"
            />
          </FormCard>
        </Grid>
        <Grid item md={6} xs={12}>
          <FormCard className={classes.minHeight} title="Service locations">
            <Controller
              as={LocationsInput}
              control={control}
              errorMsg={errors?.locations?.message}
              rules={{
                required: true,
                validate: v => listLength > 0 || v.length > 0 || "*required (either collections or service locations)",
              }}
              name="locations"
            />
          </FormCard>
        </Grid>
      </Grid>
      <ConfirmationModal
        cancelRequestButtonText="Cancel"
        confirmRequestButtonText="Delete"
        isDangerAction
        modalContent="Are you sure you want to delete this availability override?"
        modalNote="Note: This action cannot be reversed."
        modalTitle="Delete availability override"
        onCloseModal={onDeleteModalClose}
        onConfirmClick={onDeleteConfirm}
        shouldOpenModal={showDeleteModal}
      />
      {process.env.NODE_ENV === "development" && <DevTool control={control} />}
    </>
  );
};

export default AvailabilitiesDetails;
