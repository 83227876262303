import flagsmith, { IFlagsmith } from "flagsmith";
import { createContext, useEffect, useState } from "react";

export const FlagsmithContext = createContext<IFlagsmith>(undefined);

export const FlagsmithProvider = ({ children, environment, supplier, flagsmithEnvironmentId }) => {
  const flagsmithIdentity = `${environment}/supplier/${supplier.id}`;
  const [initialized, setInitialized] = useState(false);

  useEffect(async () => {
    if (initialized) {
      return;
    }

    try {
      await flagsmith.identify(flagsmithIdentity, {
        name: supplier.name,
        resource: "supplier",
      });

      flagsmith.init({
        api: "https://flagsmith.freshlinemarket.com/api/v1/",
        defaultFlags: {
          b2b: { enabled: false, value: null },
          b2c: { enabled: true, value: null },
        },
        enableAnalytics: true,
        environmentID: flagsmithEnvironmentId,
        // TODO: Handle dynamic changes
        onChange: (_prevFlags, _params) => {},
        onError: error => {
          console.error(error);
        },
      });

      await flagsmith.getFlags().then(() => {
        setInitialized(true);
      });
    } catch (error) {
      console.error(error);
    }
  }, [initialized]);

  // TODO: not ideal, but will do for now
  return <FlagsmithContext.Provider value={flagsmith}>{initialized && children}</FlagsmithContext.Provider>;
};
