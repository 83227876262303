"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.detectDomainLocale = void 0;
const detectDomainLocale = (...args)=>{
    if (process.env.__NEXT_I18N_SUPPORT) {
        return require('../shared/lib/i18n/detect-domain-locale').detectDomainLocale(...args);
    }
};
exports.detectDomainLocale = detectDomainLocale;

if ((typeof exports.default === 'function' || (typeof exports.default === 'object' && exports.default !== null)) && typeof exports.default.__esModule === 'undefined') {
  Object.defineProperty(exports.default, '__esModule', { value: true });
  Object.assign(exports.default, exports);
  module.exports = exports.default;
}

