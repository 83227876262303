/* eslint-disable @typescript-eslint/no-explicit-any */
import { ProductUnit } from "@arowana/graphql";
import { unitLabel } from "@arowana/util";
import { makeStyles, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import clsx from "clsx";
import Dinero from "dinero.js";
import { forwardRef, useMemo } from "react";

const useStyles = makeStyles(theme => ({
  loading: {
    display: "inline-block",
  },
  originalAmount: {
    color: theme.palette.text.secondary,
    textDecoration: "line-through",
  },
}));

type DollarProps = {
  amount?: any;
  className?: string;
  fallback?: string;
  format?: string;
  loading?: boolean;
  loadingClassName?: string;
  strikethrough?: boolean;
  prefix?: string;
  color?: "initial" | "inherit" | "primary" | "secondary" | "textPrimary" | "textSecondary" | "error";
  component?: string;
  suffix?: string;
  units?: ProductUnit;
  variant?:
    | "inherit"
    | "button"
    | "overline"
    | "caption"
    | "subtitle2"
    | "h1"
    | "h2"
    | "h3"
    | "h4"
    | "h5"
    | "h6"
    | "subtitle1"
    | "body1"
    | "body2"
    | "srOnly";
};

export const Dollar = forwardRef<HTMLDivElement, DollarProps>(
  (
    {
      className,
      loadingClassName,
      format = "$0,0.00",
      prefix,
      suffix,
      amount,
      units,
      variant = "subtitle2",
      loading,
      strikethrough = false,
      fallback,
      ...remainingProps
    },
    ref,
  ) => {
    const classes = useStyles();
    const classnames = clsx(className, { [classes.originalAmount]: strikethrough });
    const formattedAmount = useMemo(
      () => (Number.isInteger(amount) ? `${Dinero({ amount }).toFormat(format)}` : fallback),
      [amount, fallback, format],
    );
    let content;

    if (loading) {
      content = <Skeleton className={clsx(classes.loading, loadingClassName)} width={40} />;
    } else {
      content = (
        <>
          {prefix}
          {formattedAmount}
          {units ? `/${unitLabel(units)}` : ""}
          {suffix ? ` ${suffix}` : ""}
        </>
      );
    }

    return (
      <Typography className={classnames} ref={ref} variant={variant} {...remainingProps}>
        {content}
      </Typography>
    );
  },
);

export default Dollar;
