import { ProductUnit } from "@arowana/graphql";
import { unitLabel } from "@arowana/util";
import { IconButton, makeStyles, TextField, TextFieldProps, Typography } from "@material-ui/core";
import { Add as AddIcon, Remove as RemoveIcon } from "@material-ui/icons";
import { useCallback, useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";

import { OnWheelBlur } from "../utils";

const useStyles = makeStyles(theme => ({
  adorned: {
    padding: 0,
  },
  input: {
    "&:focus": {
      borderBottom: `1px solid ${theme.palette.primary.main}`,
    },
    "border": "none",
    "borderBottom": `1px solid ${theme.palette.text.secondary}`,
    "borderRadius": 0,
    "flexBasis": "60%",
    "padding": theme.spacing(0.5, 0),
    "textAlign": "center",
  },
  inputRoot: {
    borderRadius: theme.shape.borderRadius,
    display: "flex",
    justifyContent: "space-between",
  },
  unitText: {
    flex: "1 0 30%",
    padding: "15px 0",
    textAlign: "center",
  },
}));

type CustomQuantityCounterProps = {
  caseSize: number;
  unit?: ProductUnit;
  value?: number; // cases
  onChange: (cases: number) => void;
  onChangeWait?: number; // wait duration in ms
} & Omit<TextFieldProps, "value" | "onChange" | "type" | "variant">;

const MAX_CASE = 1000;
const MIN_CASE = 1;

export const CustomQuantityCounter = ({
  caseSize,
  color = "primary",
  value: cases,
  onChange,
  disabled = false,
  size = "medium",
  unit,
  onChangeWait = 400,
  ...props
}: CustomQuantityCounterProps) => {
  const classes = useStyles();

  const [displayValue, setDisplayValue] = useState(cases * caseSize);

  const minQty = caseSize * MIN_CASE;
  const isMin = cases === MIN_CASE;
  const isMax = cases === MAX_CASE;

  const decrement = () => {
    const value = Math.max(displayValue - minQty, minQty);
    setDisplayValue(value);
    debouncedOnChange(value);
  };
  const increment = () => {
    const value = Math.min(displayValue + minQty, caseSize * MAX_CASE);
    setDisplayValue(value);
    debouncedOnChange(value);
  };

  useEffect(() => {
    // update display value based on external state
    setDisplayValue(cases * caseSize);
  }, [caseSize, cases]);

  const { callback: debouncedOnChange } = useDebouncedCallback<CustomQuantityCounterProps["onChange"]>(parsedVal => {
    if (isNaN(parsedVal)) {
      return;
    }

    const parsedCases = Math.ceil(parsedVal / caseSize);
    const adjustedCases = parsedCases < MIN_CASE ? MIN_CASE : Math.min(MAX_CASE, parsedCases);
    onChange(adjustedCases);

    if (displayValue !== adjustedCases) {
      setDisplayValue(adjustedCases * caseSize);
    }
  }, onChangeWait);

  const onChangeInternal = useCallback<TextFieldProps["onChange"]>(
    event => {
      const parsedVal = Number.parseFloat(event.target.value);
      // update input value immediately for UI changes
      setDisplayValue(parsedVal);
      // we don't want to block user input. so debounce cases changes (adjustment etc)
      debouncedOnChange(parsedVal);
    },
    [debouncedOnChange],
  );

  const onBlur = event => {
    if (event.target.value === "") {
      onChange(MIN_CASE);
      setDisplayValue(minQty);
    }
  };

  return (
    <TextField
      color={color}
      disabled={disabled}
      fullWidth
      inputProps={{
        min: caseSize,
        step: caseSize,
      }}
      InputProps={{
        classes: {
          adornedStart: classes.adorned,
          input: classes.input,
          root: classes.inputRoot,
        },
        endAdornment: (
          <>
            <Typography className={classes.unitText} variant={size === "small" ? "caption" : "body1"}>
              {unitLabel(unit)}
            </Typography>
            <IconButton color="primary" disabled={isMax || disabled} onClick={increment} size={size}>
              <AddIcon fontSize="inherit" />
            </IconButton>
          </>
        ),
        onWheel: OnWheelBlur,
        startAdornment: (
          <IconButton color="primary" disabled={isMin || disabled} onClick={decrement} size={size}>
            <RemoveIcon fontSize="inherit" />
          </IconButton>
        ),
      }}
      onChange={onChangeInternal}
      onBlur={onBlur}
      size={size}
      type="number"
      value={displayValue}
      variant="outlined"
      {...props}
    />
  );
};

export default CustomQuantityCounter;
