const toLatLng = c => c && { lat: c[1], lng: c[0] };
const coordsToPaths = coords => {
  if (!Array.isArray(coords)) return undefined;

  const lastIndex = coords.length - 1;

  if (coords[0][0] === coords[lastIndex][0] && coords[0][1] === coords[lastIndex][1]) {
    const filtered = [...coords];
    filtered.pop();

    return filtered.map(toLatLng);
  }

  return coords.map(toLatLng);
};
const calculateCenterFromPath = path => {
  const x = path.map(p => p.lat);
  const y = path.map(p => p.lng);
  const centerX = (Math.min(...x) + Math.max(...x)) / 2;
  const centerY = (Math.min(...y) + Math.max(...y)) / 2;

  return { lat: centerX, lng: centerY };
};
const latLngUtility = {
  calculateCenterFromPath,
  coordsToPaths,
  toLatLng,
};

export default latLngUtility;
