import { makeVar } from "@apollo/client";

export const isLoggedInVar = makeVar(Boolean(window.localStorage.getItem("auth_token")));

export default {
  isLoggedIn: {
    read() {
      return isLoggedInVar();
    },
  },
};
