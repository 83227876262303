import { FormHelperText } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";

const ColorPicker = ({ className, helperText, onChange, value }) => {
  const handleChange = e => onChange(e.target.value);

  return (
    <>
      <input
        aria-describedby="color-picker"
        className={className}
        id="color-picker"
        name="primaryColor"
        onChange={handleChange}
        style={{ cursor: "pointer" }}
        type="color"
        value={value}
      />
      {helperText && (
        <FormHelperText error id="color-picker">
          {helperText}
        </FormHelperText>
      )}
    </>
  );
};

ColorPicker.defaultProps = {
  helperText: "",
  onChange: () => {},
  value: "",
};

ColorPicker.propTypes = {
  helperText: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
};

export default ColorPicker;
