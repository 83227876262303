import { gql, useMutation, useQuery } from "@apollo/client";
import { DATALAYER } from "@arowana/util";
import { Button, Card, CardContent, Chip, Grid, makeStyles, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { useContext } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import { notificationVar } from "../../../cache/notificationPolicy";
import Loader from "../../../components/Loader";
import PageHeader from "../../../components/PageHeader";
import Routes from "../../../Constants/Routes";
import { AccountContext } from "../../context/AccountContext";
import CategoryCard from "../components/CategoryCard";
import NoCategoryCard from "../components/NoCategoryCard";
import { CategoryUpdate } from "./CategoryDetails";

const useStyles = makeStyles(theme => ({
  testModeCard: {
    borderColor: theme.palette.warning.dark,
    marginBottom: theme.spacing(2),
  },
  testModeCardChip: {
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.warning.contrast,
    marginRight: theme.spacing(2),
  },
  testModeCardContent: {
    display: "flex",
    flexDirection: "row",
  },
}));

export const CATEGORIES = gql`
  query GetSupplierCategories {
    categories {
      id
      name
      order
      tags {
        value
        label
      }
    }
  }
`;

const CategoryList = ({ history }) => {
  const classes = useStyles();
  const { supplier } = useContext(AccountContext);
  const { id: supplierId } = supplier;

  const { data, loading } = useQuery(CATEGORIES, {
    context: { source: DATALAYER },
    fetchPolicy: "no-cache",
  });

  const categories = data?.categories ?? [];

  const [updateCategory] = useMutation(CategoryUpdate, {
    context: { source: DATALAYER },
    onCompleted: () => {
      notificationVar({
        message: "Category updated!",
        severity: "success",
      });
    },
    refetchQueries: ["GetSupplierCategories"],
  });
  const handleClickAddCategoryButton = () => history.push(Routes.CREATE_CATEGORY);
  const handleReorderCategory = result => {
    // illegal drop
    if (!result?.destination || !result?.source || result?.reason === "CANCEL" || !result?.draggableId) return;

    const sourceIdx = result.source.index ?? -1;
    const destIdx = result.destination.index ?? -1;

    // illegal drop or drop to the original place
    if (sourceIdx < 0 || destIdx < 0 || sourceIdx === destIdx) return;

    updateCategory({
      variables: {
        input: {
          id: result.draggableId,
          order: destIdx,
          swapOrderIndex: sourceIdx,
        },
      },
    });
  };

  let renderedContent;

  if (loading) {
    renderedContent = <Loader />;
  } else if (categories.length > 0) {
    renderedContent = (
      <DragDropContext onDragEnd={handleReorderCategory}>
        <Droppable droppableId="category_container">
          {providedFromContainer => (
            <div {...providedFromContainer.droppableProps} ref={providedFromContainer.innerRef}>
              {categories.map((category, index) => (
                <Draggable draggableId={category.id} index={index} key={category.id}>
                  {(providedFromItem, snapshot) => (
                    <div
                      ref={providedFromItem.innerRef}
                      {...providedFromItem.draggableProps}
                      {...providedFromItem.dragHandleProps}
                      style={{ ...providedFromItem.draggableProps.style, opacity: snapshot.isDragging ? 0.7 : 1 }}
                    >
                      <CategoryCard category={category} history={history} />
                    </div>
                  )}
                </Draggable>
              ))}
              {providedFromContainer.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  } else {
    renderedContent = <NoCategoryCard onClickAddCategoryButton={handleClickAddCategoryButton} />;
  }

  return (
    <>
      <PageHeader
        primaryActions={
          <Button color="primary" onClick={handleClickAddCategoryButton} variant="contained">
            Create Category
          </Button>
        }
        stickyHeader
        title="Categories"
      />
      <Card className={classes.testModeCard} elevation={0} variant="outlined">
        <CardContent className={classes.testModeCardContent}>
          <Chip className={classes.testModeCardChip} label="Tip" />
          <Grid>
            <Typography variant="subtitle2">Categories help organize your products on your store</Typography>
            <Typography variant="caption">
              {" "}
              Re-order your categories by dragging and dropping them in the desired position. Changes will be reflected
              on your store's menu shortly(~5 mins).
            </Typography>
          </Grid>
        </CardContent>
      </Card>
      {renderedContent}
    </>
  );
};

CategoryList.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default CategoryList;
