/* eslint-disable @typescript-eslint/no-explicit-any */
import { ProductUnit } from "@arowana/graphql";
import { CustomQuantityCounter, OnWheelBlur } from "@arowana/ui";
import {
  Box,
  FormControl,
  IconButton,
  InputAdornment,
  Link,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import { useMemo } from "react";
import { Link as RouterLink } from "react-router-dom";

import Routes from "../../../Constants/Routes";
import { centsToDollars, dollarsToCents } from "../../../utils/dollarCentConvert";
import VariantAutocomplete, { VariantSearch } from "./VariantAutocomplete";

const useStyles = makeStyles(theme => ({
  empty: {
    backgroundColor: theme.palette.grey[200],
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1, 0),
  },
  table: {
    minWidth: 500,
  },
  tableContainer: {
    margin: theme.spacing(3, 0),
    maxHeight: 500,
  },
}));

interface ProductRowProps extends OrderProduct {
  onChange: (variantId: string, params: any) => void;
  onRemove: (variantId: string) => void;
}

const ProductRow = ({
  caseSize,
  pricePerUnit,
  productId,
  productName,
  quantity,
  unit,
  variantId,
  variantName,
  onChange,
  onRemove,
}: ProductRowProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  const onProductPriceChange = event => {
    if (event.target.value) {
      let newPrice = dollarsToCents(event.target.value);

      if (newPrice < 0) {
        newPrice = 0;
      }

      onChange(variantId, { pricePerUnit: newPrice, quantity });
    } else {
      onChange(variantId, { pricePerUnit: undefined });
    }
  };

  const onQuantityInputChange = newQuantity => onChange(variantId, { quantity: newQuantity });

  const onProductRemoved = () => onRemove(variantId);

  return (
    <TableRow>
      <TableCell component="th" scope="row" style={{ minWidth: 120 }}>
        <Link component={RouterLink} to={Routes.PRODUCT.replace(":id", productId)}>
          <Typography>{productName}</Typography>
        </Link>
        <Typography variant="caption">{variantName}</Typography>
      </TableCell>
      <TableCell style={{ minWidth: 120 }}>
        <TextField
          InputProps={{
            inputProps: {
              min: 0,
              step: 0.01,
            },
            onWheel: OnWheelBlur,
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          defaultValue={centsToDollars(pricePerUnit)}
          fullWidth
          onChange={onProductPriceChange}
          type="number"
          variant="outlined"
        />
      </TableCell>
      <TableCell style={{ minWidth: 100 }}>
        <CustomQuantityCounter caseSize={caseSize} unit={unit} value={quantity} onChange={onQuantityInputChange} />
      </TableCell>
      <TableCell align="center">
        <IconButton aria-label="delete" color="primary" onClick={onProductRemoved} size={isMobile ? "small" : "medium"}>
          <Delete />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

interface OrderProduct {
  caseSize: number;
  pricePerUnit: number;
  productId: string;
  productName: string;
  quantity: number;
  unit: ProductUnit;
  variantId: string;
  variantName: string;
}

interface OrderProductInputListProps {
  value: OrderProduct[];
  onChange?: (products: OrderProduct[]) => void;
  onVariantAdd?: (variantId) => void;
  onVariantChange?: (variantId, formValue: Record<string, any>) => void;
  onVariantRemove?: (variantId) => void;
}

const noop = () => {};

const OrderProductInputList = ({
  value = [],
  onChange = noop,
  onVariantAdd = noop,
  onVariantChange = noop,
  onVariantRemove = noop,
}: OrderProductInputListProps) => {
  const classes = useStyles();
  const filterIds = useMemo(() => new Set(value.map(({ variantId }) => variantId)), [value]);

  const onProductAdd = (toAddProduct: VariantSearch): void => {
    onVariantAdd(toAddProduct.id);
    onChange([
      ...value,
      {
        caseSize: toAddProduct.case_size,
        pricePerUnit: toAddProduct.price,
        productId: toAddProduct.product_id,
        productName: toAddProduct.product_name,
        quantity: toAddProduct.case_size,
        unit: toAddProduct.unit,
        variantId: toAddProduct.id,
        variantName: toAddProduct.name,
      },
    ]);
  };

  const onProductValueChange = (variantId: string, formValue: Record<string, any>) => {
    onVariantChange(variantId, formValue);

    const productIdx = value.findIndex(product => product.variantId === variantId);
    const newValue = [...value];
    newValue[productIdx] = {
      ...newValue[productIdx],
      ...formValue,
    };

    onChange(newValue);
  };

  const onProductRemove = (variantId: string): void => {
    onVariantRemove(variantId);

    const newValue = value.filter(product => product.variantId !== variantId);

    onChange(newValue);
  };

  return (
    <>
      <FormControl fullWidth margin="normal">
        <VariantAutocomplete excludeVariantIds={filterIds} label="Add products" onChange={onProductAdd} />
      </FormControl>
      <TableContainer className={classes.tableContainer}>
        <Table className={classes.table} stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Product</TableCell>
              <TableCell>Price per unit</TableCell>
              <TableCell>Quantity</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {value.map(entry => (
              <ProductRow
                key={entry?.variantId}
                {...entry}
                onChange={onProductValueChange}
                onRemove={onProductRemove}
              />
            ))}
          </TableBody>
        </Table>

        {value.length === 0 && (
          <Box className={classes.empty} textAlign="center">
            <Typography>No products selected</Typography>
          </Box>
        )}
      </TableContainer>
    </>
  );
};

export default OrderProductInputList;
