import { gql } from "@apollo/client";

const ordersExportPreview = gql`
  query ordersExportPreview($input: ReportSettingsInput!) {
    ordersExportPreview(input: $input) {
      headers
      rows {
        index
        data
      }
    }
  }
`;

export default ordersExportPreview;
