import { Controller } from "react-hook-form";

import FormCard from "../../../../components/FormCard";
import FulfillmentDays, { FulfillmentDaysType } from "./FulfillmentDays";

const Fulfillment = ({ control, errors }) => {
  return (
    <FormCard title="Fulfillment schedule">
      <Controller
        as={<FulfillmentDays />}
        control={control}
        helperText={errors?.fulFillmentDays?.message}
        name="fulfillmentDays"
        rules={{
          validate: (rows: FulfillmentDaysType[]) =>
            rows.some(row => row.checked) || "*select at least one fulfillment day",
        }}
      />
    </FormCard>
  );
};

export default Fulfillment;
