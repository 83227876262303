import { OnWheelBlur, usePasswordToggle } from "@arowana/ui";
import { Box, Button, Grid, Hidden, makeStyles, TextField, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import { useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import slugify from "slugify";

import Loader from "../../../components/Loader";
import Logo from "../../../components/Logo";
import signUpImage from "../../../images/loginPageImages/signUp.jpg";
import Currency from "./FormComponents/Currency";
import TimeZone from "./FormComponents/TimeZone";

const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const useStyles = makeStyles(theme => ({
  container: {
    margin: "auto",
    padding: theme.spacing(8),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  fullHeightSection: {
    height: "100%",
  },
  input: {
    marginBottom: theme.spacing(2),
    width: "100%",
  },
  inputDisabled: {
    backgroundColor: theme.palette.grey[200],
    borderRadius: theme.shape.borderRadius,
  },
  loginImage: {
    height: "100vh",
    objectFit: "cover",
    width: "100%",
  },
  signUpMessage: {
    marginBottom: theme.spacing(4),
  },
  welcomeMessage: {
    paddingTop: theme.spacing(3),
  },
}));

const SignupSection = ({ handleSignup, loading }) => {
  const classes = useStyles();

  const { register, handleSubmit, control, errors, watch } = useForm();
  const { PasswordToggle, passwordInputType } = usePasswordToggle(watch);

  const storeName = watch("name");
  const storeURL = useMemo(() => {
    if (!storeName) {
      return "Your Store URL";
    }

    const storeSlug = slugify(storeName, { lower: true, strict: true });

    return `${storeSlug}.freshlinemarket.com`;
  }, [storeName]);

  const onSubmitForm = signupData => {
    handleSignup({
      variables: {
        input: {
          currency: signupData.currency,
          email: signupData.email.toLowerCase(),
          name: signupData.name,
          password: signupData.password,
          paymentHoldMultiplier: signupData.paymentHoldMultiplier,
          phone: signupData.phone,
          stripeSettings: {
            stripeConnectToken: "",
          },
          supportEmail: signupData.email,
          supportPhoneNumber: signupData.phone,
          takePercentage: signupData.takePercentage,
          timeZone: signupData.timeZone,
        },
      },
    });
  };

  return (
    <>
      <Loader loading={loading} />
      <Grid className={classes.fullHeightSection} container>
        <Grid className={classes.container} item md={6} sm={12}>
          <Logo />
          <Typography className={classes.welcomeMessage} gutterBottom variant="h6">
            Get started with a free account
          </Typography>
          <Typography className={classes.signUpMessage}>
            Build and launch wholesale or retail e-commerce with a 14-day free trial.
          </Typography>
          <Box maxWidth={500}>
            <form onSubmit={handleSubmit(onSubmitForm)}>
              <TextField
                className={classes.input}
                error={Boolean(errors?.email)}
                helperText={errors?.email?.message}
                inputRef={register({
                  pattern: {
                    message: "Invalid Email address",
                    value: EMAIL_REGEX,
                  },
                  required: "*required",
                })}
                label="Email address"
                name="email"
                type="email"
                variant="outlined"
              />
              <TextField
                className={classes.input}
                error={Boolean(errors?.password)}
                helperText={errors?.password?.message}
                inputRef={register({
                  required: "*required",
                })}
                InputProps={{ endAdornment: PasswordToggle }}
                label="Password"
                name="password"
                type={passwordInputType}
                variant="outlined"
              />
              <Grid container direction="row" spacing={1}>
                <Grid item md={6} sm={6} xs={12}>
                  <TextField
                    className={classes.input}
                    error={Boolean(errors?.name)}
                    helperText={errors?.name?.message}
                    inputRef={register({
                      required: "*required",
                    })}
                    label="Company name"
                    name="name"
                    type="text"
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} sm={6} xs={12}>
                  <TextField
                    className={classes.input}
                    error={Boolean(errors?.phone)}
                    helperText={errors?.phone?.message}
                    inputRef={register({
                      required: "*required",
                    })}
                    label="Phone number"
                    name="phone"
                    type="tel"
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              <Box display="none">
                <TextField
                  InputProps={{
                    inputProps: {
                      max: 2,
                      min: 1,
                      step: 0.1,
                      value: 1.1,
                    },
                    onWheel: OnWheelBlur,
                  }}
                  className={classes.input}
                  error={Boolean(errors?.paymentHoldMultiplier)}
                  helperText={errors?.paymentHoldMultiplier?.message}
                  inputRef={register({
                    max: {
                      message: "Payment hold multiplier must be less than 2",
                      value: 2,
                    },
                    min: {
                      message: "Payment hold multiplier must be greater than 1",
                      value: 1,
                    },
                    required: "*required",
                    valueAsNumber: true,
                  })}
                  label="Payment Hold Multiplier"
                  name="paymentHoldMultiplier"
                  type="number"
                  variant="outlined"
                />
                <TextField
                  InputProps={{
                    inputProps: {
                      max: 1,
                      min: 0,
                      step: 0.01,
                      value: 0.03,
                    },
                    onWheel: OnWheelBlur,
                  }}
                  className={classes.input}
                  error={Boolean(errors?.takePercentage)}
                  helperText={errors?.takePercentage?.message}
                  inputRef={register({
                    max: {
                      message: "Take percentage must be less than 1",
                      value: 1,
                    },
                    min: {
                      message: "Take percentage must be greater than 0",
                      value: 0,
                    },
                    required: "*required",
                    valueAsNumber: true,
                  })}
                  label="Take Percentage"
                  name="takePercentage"
                  type="number"
                  variant="outlined"
                />
              </Box>
              <Grid container direction="row" spacing={1}>
                <Grid item xs={6}>
                  <Controller
                    as={<Currency />}
                    control={control}
                    defaultValue={""}
                    fullWidth
                    rules={{
                      required: "*required",
                    }}
                    name="currency"
                    error={Boolean(errors?.currency)}
                    helperText={errors?.currency?.message}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    as={<TimeZone />}
                    control={control}
                    defaultValue={""}
                    fullWidth
                    rules={{
                      required: "*required",
                    }}
                    name="timeZone"
                    error={Boolean(errors?.timeZone)}
                    helperText={errors?.timeZone?.message}
                  />
                </Grid>
              </Grid>
              <Box mb={2}>
                <TextField
                  className={classes.inputDisabled}
                  disabled
                  fullWidth
                  value={storeURL}
                  name="urlName"
                  type="text"
                  variant="outlined"
                />
                <Typography color="textSecondary" variant="caption">
                  Your store will be live at this link. This can be changed later in settings.
                </Typography>
              </Box>
            </form>
            <Button color="primary" fullWidth onClick={handleSubmit(onSubmitForm)} variant="contained">
              Next
            </Button>
          </Box>
        </Grid>
        <Hidden smDown>
          <Grid className={classes.fullHeightSection} item md={6}>
            <img alt="signUpImage" className={classes.loginImage} src={signUpImage} />
          </Grid>
        </Hidden>
      </Grid>
    </>
  );
};

SignupSection.propTypes = {
  handleSignup: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default SignupSection;
