import { gql, useMutation } from "@apollo/client";
import { BrandName } from "@arowana/ui";
import { DATALAYER } from "@arowana/util";
import {
  CircularProgress,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
} from "@material-ui/core";
import { Delete as DeleteIcon } from "@material-ui/icons";

import { notificationVar } from "../cache/notificationPolicy";
import amex from "../images/paymentSvg/amex.svg";
import diners from "../images/paymentSvg/diners.svg";
import discover from "../images/paymentSvg/discover.svg";
import jcb from "../images/paymentSvg/jcb.svg";
import mastercard from "../images/paymentSvg/mastercard.svg";
import unionpay from "../images/paymentSvg/unionpay.svg";
import visa from "../images/paymentSvg/visa.svg";

const PAYMENT_METHOD_DELETE = gql`
  mutation AccountPaymentMethodDelete($id: ID!, $input: AccountPaymentMethodDeleteInput!) {
    accountPaymentMethodDelete(id: $id, input: $input) {
      id
      card {
        brand
        expiryMonth
        expiryYear
        last4
      }
    }
  }
`;

const CARD_IMAGES: { [key in BrandName]: string } = {
  amex,
  diners,
  discover,
  jcb,
  mastercard,
  unionpay,
  visa,
};

const PaymentMethodItem = ({ account, paymentMethod: { card, id }, ...props }) => {
  const [deleteCard, { loading }] = useMutation(PAYMENT_METHOD_DELETE, {
    context: { source: DATALAYER },
    onCompleted: () => {
      notificationVar({
        message: "Payment method removed!",
        severity: "success",
      });
    },
    update: cache => {
      cache.evict({ id: cache.identify({ __typename: "PaymentMethod", id }) });
      cache.gc();
    },
  });
  const handleDelete = () => {
    deleteCard({
      variables: {
        id: account.id,
        input: {
          id,
          reason: "Deleted by supplier.",
        },
      },
    });
  };
  let secondary = `Ending in ${card.last4}`;

  if (card.expiryMonth && card.expiryYear) {
    secondary += ` - Expires ${card.expiryMonth}/${card.expiryYear}`;
  }

  return (
    <ListItem disableGutters {...props}>
      <ListItemIcon>
        <img alt={`${card.brand} card`} src={CARD_IMAGES[card.brand.toLowerCase()]} width="50" />
      </ListItemIcon>
      <ListItemText primary={BrandName[card.brand]} secondary={secondary} />
      <ListItemSecondaryAction>
        {loading ? (
          <CircularProgress />
        ) : (
          <IconButton edge="end" aria-label="delete" onClick={handleDelete}>
            <DeleteIcon fontSize="small" />
          </IconButton>
        )}
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default PaymentMethodItem;
