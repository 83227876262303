const TimeZones = [
  {
    id: 1,
    name: "Alaska Standard Time (AKST)",
    value: "America/Anchorage",
  },
  {
    id: 2,
    name: "Pacific Standard Time (PST)",
    value: "America/Los_Angeles",
  },
  {
    id: 3,
    name: "Mountain Standard Time (MST)",
    value: "America/Denver",
  },
  {
    id: 4,
    name: "Central Standard Time (CST)",
    value: "America/Chicago",
  },
  {
    id: 5,
    name: "Eastern Standard Time (EST)",
    value: "America/New_York",
  },
  {
    id: 6,
    name: "Atlantic Standard Time (AST)",
    value: "America/Halifax",
  },
  {
    id: 7,
    name: "Newfoundland Standard Time (NST)",
    value: "America/St_Johns",
  },
  {
    id: 8,
    name: "Hawaii Standard Time (HST)",
    value: "Pacific/Honolulu",
  },
];

export default TimeZones;
