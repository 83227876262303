import { FulfillmentMethod } from "@arowana/graphql";
import { makeStyles, Menu, MenuItem, Typography, useTheme } from "@material-ui/core";
import {
  DrawingManager,
  GoogleMap,
  InfoBox,
  Marker,
  OverlayView,
  Polygon,
  StandaloneSearchBox,
} from "@react-google-maps/api";
import { useCallback, useEffect, useMemo, useState } from "react";
import { UseFormMethods, useWatch } from "react-hook-form";

import latLngUtility from "../../../utils/latLngUtility";
import SearchInput from "./SearchInput";

const DEFAULT_MAP_HEIGHT = 500;
const HALF_MENU_HEIGHT = 26;
const DEFAULT_CENTER = { lat: 40, lng: -100 };
const useStyles = makeStyles(theme => ({
  mapInstruction: {
    paddingLeft: theme.spacing(2),
  },
  markerLabel: {
    "&:after": {
      borderLeft: "4px solid transparent",
      borderRight: "4px solid transparent",
      borderTop: `4px solid ${theme.palette.primary.main}`,
      // inner triangle
      content: '""',
      height: 0,
      left: "calc(50% - 4px)",
      position: "absolute",
      top: "100%",
      width: 0,
      zIndex: 2,
    },
    "&:before": {
      borderLeft: "5px solid transparent",
      borderRight: "5px solid transparent",
      borderTop: "5px solid white",
      // outter triangle
      content: '""',
      height: 0,
      left: "calc(50% - 5px)",
      position: "absolute",
      top: "100%",
      width: 0,
    },
    "backgroundColor": theme.palette.primary.main,
    "border": "1px solid white",
    "borderRadius": theme.spacing(1),
    "color": "white",
    "fontSize": "12px",
    "marginBottom": theme.spacing(2),
    "overflow": "visible",
    "padding": theme.spacing(0.5),
    "position": "relative",
    "textAlign": "center",
  },
}));

const MapInstruction = () => {
  const classes = useStyles();

  return (
    <>
      <Typography variant="subtitle1">Create a zone</Typography>
      <ol className={classes.mapInstruction}>
        <li>Select the draw tool to create points on the map.</li>
        <li>Draw the perimeter of the zone and ensure to close the shape.</li>
        <li>Right click to delete a specific point.</li>
        <li>Drag any points to expand or collapse your service area.</li>
      </ol>
    </>
  );
};

interface LatLng {
  lat: number;
  lng: number;
}

interface ZoneMapProps {
  control: UseFormMethods["control"];
  fulfillmentMethod: FulfillmentMethod;
  polygonPaths: [LatLng[]];
  setMapError: (error: boolean) => void;
  setPolygonPaths: (polygonPath: [LatLng[]]) => void;
  setPolygonRef: (polugonRef: google.maps.Polygon) => void;
}

const ZoneMap = ({
  control,
  fulfillmentMethod,
  polygonPaths,
  setMapError,
  setPolygonPaths,
  setPolygonRef,
}: ZoneMapProps) => {
  const theme = useTheme();
  const classes = useStyles();
  const pickupLocation = fulfillmentMethod === FulfillmentMethod.pickup;
  const PIXEL_OFFSET = window.google?.maps ? new window.google.maps.Size(-23, -27) : undefined;
  const MARKER_STYLE = {
    path: window.google?.maps?.SymbolPath?.CIRCLE,
    scale: 0,
  };
  // marker is the pin on the map
  // markerLabel is the text on the marker
  // markerLocation is the {lat lng} to pin the marker
  const markerLabel = pickupLocation ? "Pickup" : "Origin";

  const address = useWatch({
    control,
    name: "address",
  });

  // Determine marker locaiton
  const markerLocation = useMemo(() => {
    if (address?.lngLat?.coordinates?.length) {
      const [lng, lat] = address.lngLat.coordinates;

      if (lat && lng) {
        return {
          lat: parseFloat(lat),
          lng: parseFloat(lng),
        };
      }
    }

    return null;
  }, [address?.lngLat?.coordinates]);

  const [menuEl, setMenuEl] = useState(null);
  const [menuStyle, setMenuStyle] = useState(null);
  const [onVertexDelete, setOnVertexDelete] = useState(null);
  const [mapCenter, setMapCenter] = useState<{ lat: number; lng: number }>(null);
  const [map, setMap] = useState(null);
  const [polygon, setPolygon] = useState(null);
  const [overlay, setOverlay] = useState(null);
  const [searchBox, setSearchBox] = useState(null);
  const [drawingManager, setDrawingManager] = useState(null);

  // Initialize center
  useEffect(() => {
    let center;

    if (polygonPaths) {
      const polygonPoints = polygonPaths[0].slice();

      if (markerLocation) {
        polygonPoints.push(markerLocation);
      }
      center = latLngUtility.calculateCenterFromPath(polygonPoints);
    } else if (markerLocation) {
      center = markerLocation;
    } else {
      center = DEFAULT_CENTER;
    }

    setMapCenter(center);
  }, [markerLocation?.lat, markerLocation?.lng, polygonPaths]);

  // Initialize map polygon
  useEffect(() => {
    if (map && polygon) {
      const bounds = new window.google.maps.LatLngBounds();
      polygon.getPaths().forEach(path => path.forEach(latlng => bounds.extend(latlng)));
      map.fitBounds(bounds);
    }
  }, [map, polygon]);

  const handleCloseMenu = () => setMenuEl(null);
  const onMapMounted = useCallback(mapRef => {
    setMap(mapRef);
  }, []);
  const onMapUnmounted = useCallback(() => {
    setMap(null);
  }, []);
  const onOverlayMounted = useCallback(overlayRef => {
    setOverlay(overlayRef);
  }, []);
  const onOverlayUnmounted = useCallback(() => {
    setOverlay(null);
  }, []);
  const onSearchBoxMounted = useCallback(searBoxRef => {
    setSearchBox(searBoxRef);
  }, []);
  const onSearchBoxUnmounted = useCallback(() => {
    setSearchBox(null);
  }, []);
  const onPolygonMounted = useCallback(
    polygonRef => {
      setPolygon(polygonRef);
      setPolygonRef(polygonRef);
    },
    [map, setPolygonRef],
  );
  const onPolygonUnmounted = useCallback(() => {
    setPolygon(null);
  }, []);
  const onDrawingManagerMounted = useCallback(drawingManagerRef => {
    setDrawingManager(drawingManagerRef);
  }, []);
  const onDrawingManagerUnmounted = useCallback(() => {
    setDrawingManager(null);
  }, []);
  const handlePolygonRightClick = event => {
    const { vertex, latLng } = event;

    if (Number.isInteger(vertex)) {
      const length = polygon?.getPath()?.getLength();
      // use overlay to get correct pixels
      const position = overlay?.getProjection()?.fromLatLngToContainerPixel(latLng);
      const menuDom = document.getElementsByClassName("gm-style")[0];

      // Allow vertex removal for more than 3 points only
      if (polygon && length > 3) {
        const handleVertexDelete = () => {
          setMenuEl(null);
          polygon.getPath().removeAt(vertex);
        };

        setMenuEl(menuDom);
        setMenuStyle({
          left: position.x,
          top: -DEFAULT_MAP_HEIGHT / 2 + HALF_MENU_HEIGHT + position.y,
        });
        setOnVertexDelete(() => handleVertexDelete);
      }
    }
  };
  const handlePlacesChanged = () => {
    const places = searchBox.getPlaces();
    const bounds = new window.google.maps.LatLngBounds();

    places.forEach(place => {
      if (place.geometry.viewport) {
        bounds.union(place.geometry.viewport);
      } else {
        bounds.extend(place.geometry.location);
      }
    });
    const nextMarkers = places.map(place => ({
      position: place.geometry.location,
    }));
    const nextCenter = nextMarkers?.[0]?.position ?? mapCenter;
    setMapCenter(nextCenter);
    map.fitBounds(bounds);
  };
  const handlePolygonComplete = newPolygon => {
    const newPolygonPaths = newPolygon.getPaths();

    // Disable drawing tools after completing first polygon
    drawingManager.setOptions({
      drawingControlOptions: {
        drawingModes: [],
        position: window.google.maps.ControlPosition.TOP_RIGHT,
      },
      drawingMode: null,
    });

    const coords = [];
    newPolygonPaths.forEach(path => {
      const coord = [];

      path.forEach(ll => {
        coord.push([ll.lng(), ll.lat()]);
      });

      // close polygon
      if (coord.length && coord[0][0] !== coord[coord.length - 1][0] && coord[0][1] !== coord[coord.length - 1][1]) {
        coord.push([...coord][0]);
      }

      coords.push(coord);
    });

    const arrayOfLatLngObject = latLngUtility.coordsToPaths(coords[0]);
    setPolygonPaths(arrayOfLatLngObject && [arrayOfLatLngObject]);
    setMapError(false);

    // Note: since drawingManager also render its polygon upon completion
    // Let <Polygon> handle the rendering only
    newPolygon.setMap(null);
  };

  return (
    <>
      {!pickupLocation && <MapInstruction />}
      <GoogleMap
        center={mapCenter}
        mapContainerStyle={{ borderRadius: theme.spacing(1), height: DEFAULT_MAP_HEIGHT }}
        onLoad={onMapMounted}
        onUnmount={onMapUnmounted}
        options={{
          fullscreenControl: false,
          mapTypeControl: false,
          streetViewControl: false,
        }}
        zoom={8}
      >
        <OverlayView
          mapPaneName={OverlayView.OVERLAY_LAYER}
          onLoad={onOverlayMounted}
          onUnmount={onOverlayUnmounted}
          position={mapCenter}
        >
          <Menu anchorEl={menuEl} keepMounted onClose={handleCloseMenu} open={Boolean(menuEl)} style={menuStyle}>
            <MenuItem onClick={onVertexDelete}>Delete</MenuItem>
          </Menu>
        </OverlayView>

        <StandaloneSearchBox
          onLoad={onSearchBoxMounted}
          onPlacesChanged={handlePlacesChanged}
          onUnmount={onSearchBoxUnmounted}
        >
          <SearchInput />
        </StandaloneSearchBox>

        {!polygonPaths && !pickupLocation && (
          <DrawingManager
            onLoad={onDrawingManagerMounted}
            onPolygonComplete={handlePolygonComplete}
            onRightClick={handlePolygonRightClick}
            onUnmount={onDrawingManagerUnmounted}
            options={{
              drawingControl: true,
              drawingControlOptions: {
                drawingModes: [window.google.maps.drawing.OverlayType.POLYGON],
                position: window.google.maps.ControlPosition.TOP_RIGHT,
              },
              polygonOptions: {
                clickable: true,
                draggable: true,
                editable: true,
                fillColor: theme.palette.grey[500],
                geodesic: true,
                strokeColor: theme.palette.primary.main,
                visible: true,
                zIndex: 1,
              },
            }}
            setMap={GoogleMap}
          />
        )}

        {polygonPaths && !pickupLocation && (
          <Polygon
            draggable
            editable
            onLoad={onPolygonMounted}
            onRightClick={handlePolygonRightClick}
            onUnmount={onPolygonUnmounted}
            options={{
              fillColor: theme.palette.grey[500],
              geodesic: true,
              strokeColor: theme.palette.primary.main,
            }}
            paths={polygonPaths}
          />
        )}

        {markerLocation && markerLabel && (
          <Marker icon={MARKER_STYLE} position={markerLocation}>
            <InfoBox options={{ closeBoxURL: "", pixelOffset: PIXEL_OFFSET }}>
              <div className={classes.markerLabel}>{markerLabel}</div>
            </InfoBox>
          </Marker>
        )}
      </GoogleMap>
    </>
  );
};

export default ZoneMap;
