import { gql, useMutation, useQuery } from "@apollo/client";
import { SignaturePad, SupplierContext, svgProcessing } from "@arowana/ui";
import { DATALAYER } from "@arowana/util";
import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  Dialog,
  Grid,
  IconButton,
  Link,
  makeStyles,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import moment from "moment";
import { ChangeEventHandler, Fragment, useContext, useMemo, useRef, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import Signature from "signature_pad";

import { notificationVar } from "../../../cache/notificationPolicy";
import FormCard from "../../../components/FormCard";
import Quantity from "../../../components/Quantity";
import Routes from "../../../Constants/Routes";

const SIGNATURE = gql`
  query GetSignature($id: ID!) {
    order(id: $id) {
      signature {
        id
        createdAt
        data
        name
      }
    }
  }
`;

const SUBMIT_SIGNATURE = gql`
  mutation submitSignature($id: ID!, $data: String!, $name: String) {
    orderAddSignature(id: $id, data: $data, name: $name) {
      id
      signature {
        id
        data
        name
      }
    }
  }
`;

const useStyles = makeStyles(theme => ({
  bottomSection: {
    [theme.breakpoints.only("xs")]: {
      marginTop: "auto",
    },
    marginTop: theme.spacing(2),
  },
  buttons: {
    "& > *:not(:first-child)": {
      marginLeft: theme.spacing(1),
    },
    "marginTop": theme.spacing(2),
  },
  card: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    overflowY: "scroll",
  },
  nameInput: {
    marginTop: theme.spacing(1),
  },
  signed: {
    border: `1px solid ${theme.palette.text.hint}`,
    borderRadius: theme.shape.borderRadius,
    height: 150,
    objectFit: "contain",
    width: "100%",
  },
}));

const OrderSignatureModal = ({ order, onClose }) => {
  const classes = useStyles();
  const { timezone } = useContext(SupplierContext);

  const { payeeName, invoiceNumber, lineItems = [] } = order ?? {};
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const open = Boolean(order);
  const orderURL = useMemo(() => Routes.ORDER_DETAILS.replace(":id", order?.id), [order?.id]);

  const padRef = useRef<Signature>();
  const [name, setName] = useState<string>("");
  const onNameChange: ChangeEventHandler<HTMLInputElement> = e => setName(e.target.value);

  const { data, loading } = useQuery(SIGNATURE, {
    context: { source: DATALAYER },
    fetchPolicy: "cache-and-network",
    skip: !order?.id,
    variables: {
      id: order?.id,
    },
  });
  const hasSignature = Boolean(data?.order?.signature);
  const signedDate = data?.order?.signature?.createdAt
    ? moment.utc(data?.order?.signature?.createdAt).tz(timezone).format("LLL")
    : "";
  const signerName = data?.order?.signature?.name;

  const [submit, { loading: submitting }] = useMutation(SUBMIT_SIGNATURE, {
    context: { source: DATALAYER },
    refetchQueries: ["GetSignature"],
  });
  const onSaveClick = () => {
    if (padRef?.current.isEmpty()) {
      notificationVar({ message: `No signature was recoreded`, severity: "warning" });

      return;
    }

    const data = padRef?.current.toDataURL("image/svg+xml");
    const formattedData = svgProcessing(data, `s-${order?.id}`);

    submit({
      variables: {
        data: formattedData,
        id: order?.id,
        name,
      },
    });
  };

  const onDialogClose = (_, reason) => {
    if (reason === "backdropClick" && hasSignature) {
      onClose();
    }
  };

  const disableInput = hasSignature || loading || submitting;

  return (
    <Dialog fullWidth fullScreen={isMobile} open={open} onClose={onDialogClose}>
      <FormCard
        className={classes.card}
        loading={loading || submitting}
        title={
          <>
            {payeeName}
            <br />
            <Typography variant="subtitle2" component="p">
              <Link component={RouterLink} to={orderURL} onClick={onClose}>
                Order# {invoiceNumber}
              </Link>
            </Typography>
          </>
        }
        action={
          <>
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </>
        }
      >
        <Grid container spacing={1}>
          <Grid item xs={10}>
            <Typography>Items</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography>Quantity</Typography>
          </Grid>
          {lineItems?.map(({ productId, variantId, productName, subName, quantity, unit }) => (
            <Fragment key={`${productId}:${variantId}`}>
              <Grid item xs={10}>
                <Typography>{productName}</Typography>
                <Typography color="textSecondary">{subName}</Typography>
              </Grid>
              <Grid container item xs={2} alignItems="center">
                <Quantity amount={quantity} unit={unit} />
              </Grid>
            </Fragment>
          ))}
        </Grid>
        <Collapse className={classes.bottomSection} in={hasSignature}>
          <Box textAlign="center">
            <img className={classes.signed} src={data?.order?.signature?.data}></img>
          </Box>
          <Typography>
            Signed on: {signedDate}
            {signerName && (
              <>
                {" "}
                by <strong>{signerName}</strong>
              </>
            )}
          </Typography>
          <Typography variant="body2">
            This signature will be displayed on the order's PDF, which can be downloaded on the{" "}
            <Link component={RouterLink} to={orderURL} onClick={onClose}>
              Order
            </Link>{" "}
            page.
          </Typography>
        </Collapse>
        <Collapse className={classes.bottomSection} in={!hasSignature} unmountOnExit>
          <SignaturePad disabled={disableInput} padRef={padRef} />
          <TextField
            className={classes.nameInput}
            variant="outlined"
            onChange={onNameChange}
            fullWidth
            margin="dense"
            placeholder="Name (optional)"
            inputProps={{
              maxlength: 50,
            }}
          />
          <Box className={classes.buttons} display="flex" justifyContent="space-between">
            <Button
              disabled={disableInput}
              fullWidth
              color="secondary"
              variant="outlined"
              onClick={() => padRef?.current.clear()}
            >
              Clear
            </Button>
            <Button disabled={disableInput} fullWidth color="primary" variant="contained" onClick={onSaveClick}>
              {submitting ? <CircularProgress color="inherit" size={24} /> : "Save"}
            </Button>
          </Box>
        </Collapse>
      </FormCard>
    </Dialog>
  );
};

export default OrderSignatureModal;
