/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-array-index-key */
import { Box, Fab, Grow, makeStyles } from "@material-ui/core";
import { ChevronLeft, ChevronRight, FiberManualRecord } from "@material-ui/icons";
import clsx from "clsx";
import React from "react";
import MultiCarousel from "react-multi-carousel";

import { useDisableFocus } from "../hooks";
import { CarouselSlide } from "./CarouselSlide";

const DEFAULT_FAB_SIZE = 26;
const OFFSET = DEFAULT_FAB_SIZE / 2;
const useStyles = makeStyles((theme: any) => ({
  buttonContainer: {
    left: `calc(${OFFSET / 2}px + ${theme.spacing(3)}px)`,
    pointerEvents: "none",
    position: "absolute",
    top: `calc(50% - ${OFFSET}px)`,
    transition: "top 100ms",
    width: `calc(100% - ${OFFSET + theme.spacing(6)}px)`,
    [theme.breakpoints.only("xs")]: {
      display: "none",
    },
  },
  carouselRoot: {
    position: "relative",
  },
  customDot: {
    color: theme.palette.grey[200],
    cursor: "pointer",
    transition: theme.transitions.create(["color"]),
  },
  customDotActive: {
    color: theme.palette.primary.main,
  },
  dotSize: {
    fontSize: "1rem",
  },
  dotsContainer: {
    bottom: theme.spacing(2),
  },
  fab: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.primary.main,
    height: DEFAULT_FAB_SIZE,
    minHeight: DEFAULT_FAB_SIZE,
    pointerEvents: "auto",
    width: DEFAULT_FAB_SIZE,
  },
  pushRight: {
    [theme.breakpoints.up("sm")]: {
      left: "40%",
    },
  },
}));
const responsiveConfig = {
  all: {
    breakpoint: { max: 5000, min: 0 },
    items: 1,
  },
};

type ButtonGroupProps = {
  carouselState?: any;
  next?: (...args: any[]) => any;
  previous?: (...args: any[]) => any;
};

const ButtonGroup = ({ next, previous, carouselState }: ButtonGroupProps) => {
  const classes = useStyles();
  const { currentSlide, slidesToShow, totalItems } = carouselState;
  const showPrev = currentSlide > 0;
  const showNext = currentSlide + slidesToShow < totalItems;

  return (
    <Box className={classes.buttonContainer} display="flex" justifyContent="space-between">
      <Grow in={showPrev}>
        <Fab className={classes.fab} aria-label="previous" color="default" onClick={previous} size="small">
          <ChevronLeft />
        </Fab>
      </Grow>
      <Grow in={showNext}>
        <Fab className={classes.fab} aria-label="next" color="default" onClick={next} size="small">
          <ChevronRight />
        </Fab>
      </Grow>
    </Box>
  );
};

type DotsGroupProps = {
  active?: boolean;
  index?: number;
  numDots?: number;
  onClick?: (...args: any[]) => any;
};

const DotsGroup = ({ active, index, numDots, onClick }: DotsGroupProps) => {
  const classes = useStyles();
  const dots = Array(numDots).fill(<FiberManualRecord className={classes.dotSize} />);

  return (
    <Box
      component="li"
      className={clsx(classes.customDot, {
        [classes.customDotActive]: active,
      })}
      onClick={onClick}
      role="button"
      aria-label={`View slide ${index + 1}`}
    >
      {React.Children.toArray(dots)[index]}
    </Box>
  );
};

type HeroCarouselProps = {
  displayType: string;
  slides: any[];
};

const HeroCarouselItem = props => {
  const [ref, disableFocus] = useDisableFocus();

  return <CarouselSlide ref={ref} disableFocus={disableFocus} {...props} />;
};

export function HeroCarousel({ displayType, slides }: HeroCarouselProps) {
  const classes = useStyles();
  const isHalf = displayType === "half";

  if (slides.length === 0) {
    return <CarouselSlide displayType={displayType} forCarousel />;
  }

  return (
    <div className={classes.carouselRoot}>
      <MultiCarousel
        arrows={false}
        autoPlay
        autoPlaySpeed={5000}
        customButtonGroup={<ButtonGroup />}
        customDot={<DotsGroup numDots={slides.length} />}
        dotListClass={clsx(classes.dotsContainer, { [classes.pushRight]: isHalf })}
        draggable
        infinite
        keyBoardControl={false}
        renderButtonGroupOutside
        renderDotsOutside={false}
        responsive={responsiveConfig}
        showDots
        swipeable
      >
        {slides.map((slide, index) => (
          <HeroCarouselItem displayType={displayType} forCarousel key={`slide-preview-${index}`} slide={slide} />
        ))}
      </MultiCarousel>
    </div>
  );
}

export default HeroCarousel;
