import { muiTheme } from "@arowana/ui";
import CssBaseline from "@material-ui/core/CssBaseline";
import { MuiThemeProvider } from "@material-ui/core/styles";
import ReactDOM from "react-dom";

import App from "./App";

ReactDOM.render(
  <MuiThemeProvider theme={muiTheme({})}>
    <CssBaseline />
    <App />
  </MuiThemeProvider>,
  document.getElementById("root"),
);
