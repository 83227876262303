import { gql, useMutation, useQuery } from "@apollo/client";
import { PromotionsSortField } from "@arowana/graphql";
import { Dollar, usePagination } from "@arowana/ui";
import { DATALAYER } from "@arowana/util";
import { Box, Button, CircularProgress, makeStyles } from "@material-ui/core";
import { GridAlignment } from "@material-ui/data-grid";
import Routes from "apps/supplier/src/Constants/Routes";
import moment from "moment-timezone";
import qs from "qs";
import { useContext, useMemo, useState } from "react";
import { Link as RouterLink } from "react-router-dom";

import { notificationVar } from "../../../cache/notificationPolicy";
import PageHeader from "../../../components/PageHeader";
import PaginatedTable from "../../../components/PaginatedTable";
import TipBanner from "../../../components/TipBanner";
import { AccountContext } from "../../context/AccountContext";
import { FlagsmithContext } from "../../context/FlagsmithContext";
import PromotionsSearch from "../../promotions/component/PromotionsSearch";

const useStyles = makeStyles(theme => ({
  cell: {
    "& .MuiDataGrid-cell": {
      paddingLeft: theme.spacing(2),
    },
  },
  row: {
    cursor: "pointer",
  },
}));

const GIFT_CARDS = gql`
  query GiftCards($page: PaginationInput, $sort: PromotionsSortInput, $filter: PromotionsFilterInput) {
    currentSupplier {
      promotions(page: $page, sort: $sort, filter: $filter) {
        edges {
          cursor
          node {
            id
            code
            createdAt
            disabled
            maxAmountToUse
            purchaserEmail
            recipientEmail
            usages {
              amount
            }
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          totalCount
        }
      }
    }
  }
`;

const GIFT_CARG_UPDATE = gql`
  mutation GiftCardUpdate($input: PromotionUpdateInput!) {
    promotionUpdate(input: $input) {
      id
      disabled
    }
  }
`;

interface GiftCardUpdateInput {
  id: string;
  disabled: boolean;
}

const getColumns = (query: string, updateGiftCard: (input: GiftCardUpdateInput) => void, updatingId: string) => [
  {
    field: "id",
    hide: true,
  },
  {
    field: PromotionsSortField.CODE,
    headerName: "Giftcard",
    sortable: !query,
    valueGetter: param => param.row.code,
    width: 180,
  },
  {
    field: PromotionsSortField.CREATED_AT,
    headerName: "Created date",
    sortable: !query,
    valueGetter: param => moment(param.row.createdAt).format("YYYY-MM-DD"),
    width: 150,
  },
  {
    field: "maxAmountToUse",
    headerName: "Value",
    renderCell: param => <Dollar amount={param.value} />,
    sortable: false,
    width: 120,
  },
  {
    field: "usages",
    headerName: "Remaining",
    renderCell: param => (
      <Dollar amount={param.row.maxAmountToUse - param.value.reduce((acc, cur) => acc + Math.abs(cur.amount), 0)} />
    ),
    sortable: false,
    width: 120,
  },
  {
    field: "recipientEmail",
    headerName: "Recipient",
    sortable: false,
    width: 200,
  },
  {
    field: "purchaserEmail",
    headerName: "Purchaser",
    sortable: false,
    width: 200,
  },
  {
    align: "right" as GridAlignment,
    field: "disabled",
    flex: 1,
    headerName: " ",
    renderCell: param => (
      <Button
        color={!param.row.disabled ? "secondary" : "primary"}
        disabled={updatingId === param.row.id}
        onClick={() => updateGiftCard({ disabled: !param.row.disabled, id: param.row.id })}
        size="small"
        variant="outlined"
      >
        {updatingId === param.row.id ? (
          <CircularProgress color="inherit" size={24} />
        ) : param.row.disabled ? (
          "Enable"
        ) : (
          "Disable"
        )}
      </Button>
    ),
    sortable: false,
  },
];

const ROW_PER_PAGE = 25;

const defaultSortState = {
  field: PromotionsSortField.CREATED_AT,
  sort: "desc",
};

const GiftCards = ({ history }) => {
  const classes = useStyles();
  const {
    supplier: { url },
  } = useContext(AccountContext);
  const queryParams = qs.parse(history.location.search, { ignoreQueryPrefix: true });
  const { query = "" } = queryParams;
  const flagsmith = useContext(FlagsmithContext);
  const hasRetail = flagsmith.hasFeature("b2c");
  const [updatingId, setUpdatingId] = useState<string>("");

  const [updateGiftCard] = useMutation(GIFT_CARG_UPDATE, {
    context: { source: DATALAYER },
    onCompleted: () => {
      notificationVar({
        message: "Giftcard updated!",
        severity: "success",
      });
    },
  });

  const onUpdateGiftCard = (input: GiftCardUpdateInput) => {
    setUpdatingId(input.id);
    updateGiftCard({ variables: { input } }).finally(() => setUpdatingId(""));
  };

  const columns = useMemo(() => getColumns(query, onUpdateGiftCard, updatingId), [query, onUpdateGiftCard, updatingId]);

  const [sortState, setSortState] = useState(defaultSortState);
  const { setPageInfo, resetPagination, pagination, page, onPageChange } = usePagination(ROW_PER_PAGE);

  const { loading, data } = useQuery(GIFT_CARDS, {
    context: { source: DATALAYER },
    fetchPolicy: "network-only",
    onCompleted: response => {
      setPageInfo(response?.currentSupplier?.promotions?.pageInfo);
    },
    onError: resetPagination,
    variables: {
      filter: {
        ...(query && { query }),
        type: ["giftcard"],
      },
      page: pagination,
      sort: sortState && {
        field: sortState.field,
        order: sortState.sort === "desc" ? -1 : 1,
      },
    },
  });

  const totalCount = data?.currentSupplier?.promotions?.pageInfo?.totalCount ?? 0;
  const rows = data?.currentSupplier?.promotions?.edges.map(({ node }) => node) ?? [];

  const handleSortChange = ({ sortModel }) => {
    resetPagination();
    setSortState(sortModel[0]);
  };

  const handleUpdateQuery = keyValue => {
    history.replace({
      search: qs.stringify({
        ...queryParams,
        ...keyValue,
      }),
    });
    setSortState(defaultSortState);
    resetPagination();
  };

  return (
    <>
      <PageHeader
        stickyHeader
        title="Giftcards"
        primaryActions={
          <>
            <Button color="primary" href={`${url}/giftcard`} target="_blank" variant="text">
              View Giftcard Page
            </Button>
            <Button color="primary" component={RouterLink} to={Routes.GIFTCARD_CREATE} variant="contained">
              Create Giftcard
            </Button>
          </>
        }
      />
      {!hasRetail && (
        <TipBanner
          headerText="Gift cards are only used for your retail online store."
          bodyText="Please note that if you do not have a retail online store, Gift Cards won't be accessible by online shoppers."
        />
      )}
      <Box mb={2}>
        <PromotionsSearch onUpdateQuery={handleUpdateQuery} placeholder="Search gift cards" query={query} />
      </Box>
      <PaginatedTable
        classes={{
          row: classes.row,
        }}
        className={classes.cell}
        columns={columns}
        defaultContent="No gift cards"
        loading={loading}
        onPageChange={onPageChange}
        onRowClick={({ row: { id } }) => history.push(Routes.GIFTCARD_DETAILS.replace(":id", id))}
        onSortModelChange={handleSortChange}
        page={page}
        pageSize={ROW_PER_PAGE}
        rows={rows}
        sortModel={sortState ? [sortState] : []}
        totalCount={totalCount}
      />
    </>
  );
};

export default GiftCards;
