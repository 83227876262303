import { Divider, FormControl, FormLabel, Grid, Link, makeStyles, TextField, Typography } from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import { Controller } from "react-hook-form";

import FormTooltip from "../../../components/FormTooltip";
import SwitchToggle from "../../account/components/SwitchToggle";
import AppForm from "../components/AppForm";

const defaultValues = {
  apiKey: "",
  enableEmailCollection: false,
  serverPrefix: "",
  signUpListId: "",
};
const useStyles = makeStyles(theme => ({
  disableInputs: {
    opacity: 0.7,
    pointerEvents: "none",
  },
  displayToggler: {
    marginLeft: 0,
    marginRight: theme.spacing(1),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  instructionSection: {
    backgroundColor: theme.palette.grey[50],
    borderRadius: theme.shape.borderRadius,
    marginTop: theme.spacing(6),
    padding: theme.spacing(2),
  },
}));
const MailChimp = () => {
  const classes = useStyles();
  const isInstalled = fields => Boolean(fields?.apiKey);

  return (
    <AppForm appId="mailchimp" defaultValues={defaultValues} header="Mailchimp" isInstalled={isInstalled}>
      {({ formMethods: { control, errors, register }, installed }) => (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <FormControl component="fieldset" fullWidth margin="normal">
              <FormLabel component="legend" htmlFor="apiKey">
                Mailchimp API Key
              </FormLabel>
              <TextField
                className={clsx({ [classes.disableInputs]: installed })}
                error={Boolean(errors?.apiKey)}
                helperText={errors?.apiKey?.message}
                id="apiKey"
                inputRef={register({
                  required: "*required",
                })}
                margin="dense"
                name="apiKey"
                variant="outlined"
              />
              <Typography color="textSecondary" variant="caption">
                i.e. f0532888271ef89d863d048248293ff3-us13
              </Typography>
            </FormControl>
          </Grid>
          <Grid item md={6} xs={12}>
            <FormControl component="fieldset" fullWidth margin="normal">
              <FormLabel component="legend" htmlFor="serverPrefix">
                Server Prefix{" "}
                <FormTooltip content="This prefix will appear prior to 'admin.mailchimp.com'. Examples include 'us7' or 'us13'." />
              </FormLabel>
              <TextField
                className={clsx({ [classes.disableInputs]: installed })}
                error={Boolean(errors?.serverPrefix)}
                helperText={errors?.serverPrefix?.message}
                id="serverPrefix"
                inputProps={{
                  maxLength: 4,
                }}
                inputRef={register({
                  required: "*required",
                })}
                margin="dense"
                name="serverPrefix"
                variant="outlined"
              />
              <Typography color="textSecondary" variant="caption">
                i.e. us13
              </Typography>
            </FormControl>
          </Grid>
          <Grid item md={6} xs={12}>
            <FormControl component="fieldset" fullWidth margin="normal">
              <FormLabel component="legend" htmlFor="signUpListId">
                Audience ID{" "}
                <FormTooltip content="New customers or sign ups will exclusively be added to this specific audience list." />
              </FormLabel>
              <TextField
                className={clsx({ [classes.disableInputs]: installed })}
                error={Boolean(errors?.signUpListId)}
                helperText={errors?.signUpListId?.message}
                id="signUpListId"
                inputProps={{
                  maxLength: 15,
                }}
                inputRef={register({
                  required: "*required",
                })}
                margin="dense"
                name="signUpListId"
                variant="outlined"
              />
              <Typography color="textSecondary" variant="caption">
                i.e. 25dc55feb6
              </Typography>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Divider className={classes.divider} />
            <Typography gutterBottom variant="h6">
              Subscription Pop Up
            </Typography>
            <Controller
              as={<SwitchToggle />}
              className={classes.displayToggler}
              control={control}
              describeStatus
              label="Display on shop"
              name="enableEmailCollection"
              type="checkbox"
            />
            <Typography color="textSecondary" variant="body2">
              Visitors will see a pop-up when entering the website and a subscription form above your website's footer.
            </Typography>
          </Grid>
          <Grid className={classes.instructionSection} xs={12}>
            <Typography gutterBottom variant="subtitle2">
              Installation Guide
            </Typography>
            <Typography color="textSecondary" variant="body2">
              <ol>
                <li>
                  <Link href="https://admin.mailchimp.com/account/api" target="_blank">
                    Navigate to Account → Extras → API Keys in Mailchimp.
                  </Link>
                </li>
                <li>
                  Click <b>Create A Key</b>.
                </li>
                <li>
                  Take the full API Key value (i.e. f0532888271ef89d863d048248293ff3-us13) and paste this into the
                  Mailchimp API Key input.
                </li>
                <li>Copy and paste the value (i.e. us13) at the end of your API Key into the Server Prefix input.</li>
                <li>
                  <Link href="https://us13.admin.mailchimp.com/audience/" target="_blank">
                    Navigate to your Audience Page
                  </Link>
                </li>
                <li>
                  Click <b>Manage Audience → Settings.</b>
                </li>
                <li>
                  Scroll to the bottom of the page to find the <b>Unique id for audience input</b>. Paste this value
                  into the Audience ID input.
                </li>
                <li>Click Save to finalize your details.</li>
              </ol>
            </Typography>
          </Grid>
        </Grid>
      )}
    </AppForm>
  );
};

export default MailChimp;
