import { FormControl, FormLabel, Grid, Link, makeStyles, TextField, Typography } from "@material-ui/core";
import React from "react";

import FormTooltip from "../../../components/FormTooltip";
import AppForm from "../components/AppForm";

const defaultValues = {
  pixelKey: "",
};
const useStyles = makeStyles(theme => ({
  instructionSection: {
    backgroundColor: theme.palette.grey[50],
    borderRadius: theme.shape.borderRadius,
    marginTop: theme.spacing(6),
    padding: theme.spacing(2),
  },
}));

const Facebook = () => {
  const classes = useStyles();
  const isInstalled = fields => Boolean(fields?.pixelKey);

  return (
    <AppForm appId="facebook" defaultValues={defaultValues} header="Facebook Pixel" isInstalled={isInstalled}>
      {({ formMethods: { errors, register }, installed }) => (
        <FormControl component="fieldset" fullWidth margin="normal">
          <FormLabel component="legend" htmlFor="pixelKey">
            Facebook Pixel ID{" "}
            <FormTooltip content="Facebook pixel lets you track your customers' behavior, helping you optimize your ads, ROI, and conversions. Use this data to retarget visitors and boost sales." />
          </FormLabel>
          <TextField
            disabled={installed}
            error={Boolean(errors?.pixelKey)}
            helperText={errors?.pixelKey?.message}
            id="pixelKey"
            inputRef={register({
              required: "*required",
            })}
            margin="dense"
            name="pixelKey"
            variant="outlined"
          />
          <Typography color="textSecondary" variant="caption">
            i.e. 916242283832729
          </Typography>
          <Grid className={classes.instructionSection} xs={12}>
            <Typography gutterBottom variant="subtitle2">
              Installation Guide
            </Typography>
            <Typography color="textSecondary" variant="body2">
              <ol>
                <li>
                  <Link href="https://www.facebook.com/events_manager/" target="_blank">
                    Navigate to your Facebook Events Manager.
                  </Link>
                </li>
                <li>
                  Choose the relevant Data Source and copy the <b>Pixel ID</b> on the right hand side (i.e.
                  916242283832729).
                </li>
                <li>Paste this value into the Facebook Pixel ID input.</li>
                <li>Click Save to finalize your details.</li>
              </ol>
            </Typography>
          </Grid>
        </FormControl>
      )}
    </AppForm>
  );
};

export default Facebook;
