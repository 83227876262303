import { Box, CircularProgress, Fade, InputAdornment, makeStyles, TextField, Typography } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import { useEffect, useMemo, useState } from "react";
import usePlacesAutocomplete, { getGeocode, getLatLng } from "use-places-autocomplete";

const formatStreetAddress = ({ streetNumber = "", streetName = "", premise = "", userInput = "" }) => {
  switch (true) {
    // Prefer userInput since geocoding can be inaccurate
    case Boolean(userInput.trim()):
      return userInput;

    case Boolean(streetNumber.trim()):
      return `${streetNumber} ${streetName}`;

    case Boolean(premise.trim()):
      return premise;
  }

  throw Error("No street address found");
};

export const formatAddressComponents = (addressComponents: google.maps.GeocoderAddressComponent[], userInput = "") => {
  const shortName = tag => addressComponents.find(comp => comp.types.includes(tag))?.short_name ?? "";
  const longName = tag => addressComponents.find(comp => comp.types.includes(tag))?.long_name ?? "";

  return {
    city: longName("locality"),
    country: longName("country"),
    countryCode: shortName("country"),
    postalCode: shortName("postal_code"),
    province: longName("administrative_area_level_1"),
    provinceCode: shortName("administrative_area_level_1"),
    streetAddress: formatStreetAddress({
      premise: longName("premise"),
      streetName: longName("route"),
      streetNumber: shortName("street_number"),
      userInput,
    }),
    sublocality: longName("sublocality"),
  };
};

const useStyles = makeStyles(theme => ({
  noOptions: {
    display: "none",
  },
  option: {
    "&:not(:last-of-type)": {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  },
  optionsPaper: {
    boxShadow: theme.shadows[5],
  },
  searchAdornment: {
    position: "relative",
  },
  searchLoading: {
    left: 3,
    position: "absolute",
    top: -9,
  },
}));

const targetType = new Set(["premise", "street_address"]);
const optionsFilter = options => options.filter(option => option?.types?.some(type => targetType.has(type)));
const getRenderOption =
  className =>
  ({ structured_formatting: formatting }) => {
    const {
      main_text_matched_substrings: matchedSubstrings,
      main_text: mainText,
      secondary_text: secondaryText,
    } = formatting;
    let main = mainText;

    if (matchedSubstrings?.length > 0) {
      const { length, offset } = matchedSubstrings[0];
      const matched = mainText.substring(offset, offset + length);
      const remaining = mainText.substring(offset + length);
      main = (
        <>
          <b>{matched}</b>
          {remaining}
        </>
      );
    }

    return (
      <Box className={className}>
        <Typography variant="body2">{main}</Typography>
        <Typography color="textSecondary" variant="caption">
          {secondaryText}
        </Typography>
      </Box>
    );
  };
const SearchAdornment = ({ loading }) => {
  const classes = useStyles();

  return (
    <InputAdornment className={classes.searchAdornment} position="end">
      <Fade in={loading}>
        <CircularProgress className={classes.searchLoading} color="primary" size={13} thickness={7} />
      </Fade>
      <Search />
    </InputAdornment>
  );
};

type AddressFieldsProps = {
  lat: number;
  lng: number;
  city: string;
  country: string;
  province: string;
  postalCode: string;
  streetAddress: string;
};

type GooglePlacesFieldProps = {
  clearText?: string;
  error?: boolean;
  helperText?: string;
  inputAutoComplete?: string;
  label?: string;
  name?: string;
  onChange?: (value: string) => void;
  onReset: () => void;
  onSelect: (props: AddressFieldsProps) => void;
  placeholder?: string;
  value?: string;
  required?: boolean;
};

export const GooglePlacesField = ({
  clearText = "Clear",
  error = false,
  helperText,
  inputAutoComplete = "new-password",
  label = "Street address",
  name,
  onChange,
  onReset,
  onSelect,
  placeholder = "Start typing to see suggested results…",
  value,
  required = false,
}: GooglePlacesFieldProps) => {
  const classes = useStyles();

  const {
    value: inputValue,
    suggestions: { data: options, loading: loadingSuggestion },
    setValue: setInputValue,
  } = usePlacesAutocomplete({
    debounce: 500,
    requestOptions: {
      componentRestrictions: { country: ["US", "CA", "NZ"] },
      types: ["address"],
    },
  });

  useEffect(() => {
    if (value !== inputValue) {
      setInputValue(value);
    }
  }, [setInputValue, inputValue, value]);

  const [optionValue, setOptionValue] = useState(null);
  const [isGeocoding, setGeocoding] = useState(false);
  const isLoading = isGeocoding || loadingSuggestion;
  const handleInputChange = (event, changedValue, reason) => {
    if (reason === "clear") {
      onChange("");
      setOptionValue(null);
      onReset();
    } else if (reason === "reset") {
      // Only keep input value if user click / hit enter on dropdown option
      if (event?.key === "Enter" || event?.target?.tagName === "LI") {
        onChange(changedValue);
      }
    } else {
      onChange(changedValue);
    }

    return changedValue;
  };
  const handleSelectChange = async (_, option, reason) => {
    if (reason === "select-option") {
      const { description, structured_formatting: formatting } = option;
      setInputValue(formatting.main_text, false);
      setOptionValue(option);
      setGeocoding(true);

      const [geocode] = await getGeocode({ address: description });
      const { lat, lng } = await getLatLng(geocode);

      if (geocode && lat && lng) {
        const addressComponents = formatAddressComponents(geocode.address_components, formatting.main_text);
        onSelect({
          lat,
          lng,
          ...addressComponents,
        });
        setGeocoding(false);
      }
    }
  };
  const renderOption = useMemo(() => getRenderOption(classes.option), [classes.option]);

  return (
    <Autocomplete
      autoComplete
      classes={{
        noOptions: classes.noOptions,
        option: classes.option,
        paper: classes.optionsPaper,
      }}
      filterOptions={optionsFilter}
      filterSelectedOptions
      getOptionLabel={option => (typeof option === "string" ? option : option?.structured_formatting?.main_text)}
      getOptionSelected={() => false} // display all options, regardless of selected or not
      inputValue={inputValue}
      loading={isLoading}
      onChange={handleSelectChange}
      onInputChange={handleInputChange}
      clearText={clearText}
      options={options}
      renderInput={({ InputProps, inputProps, ...rest }) => (
        <TextField
          {...rest}
          InputProps={{
            ...InputProps,
            inputProps: {
              ...inputProps,
              autoComplete: inputAutoComplete,
            },
            startAdornment: <SearchAdornment loading={isLoading} />,
          }}
          error={error}
          fullWidth
          helperText={helperText}
          label={label}
          name={name}
          placeholder={placeholder}
          variant="outlined"
          required={required}
        />
      )}
      renderOption={renderOption}
      value={optionValue}
    />
  );
};

export default GooglePlacesField;
