import { Button, makeStyles, Typography } from "@material-ui/core";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import clsx from "clsx";
import PropTypes from "prop-types";
import { isValidElement, useContext, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";

import { AccountContext } from "../modules/context/AccountContext";

const useStyles = makeStyles(theme => ({
  content: {
    padding: theme.spacing(2, 0, 0),
  },
  fixedHeading: {
    "@media print": {
      display: "none",
    },
    "backgroundColor": theme.palette.background.default,
    "borderBottom": `1px solid ${theme.palette.grey[300]}`,
    "marginBottom": theme.spacing(2),
    "position": "sticky",
    "top": 0,
    "zIndex": 1000,
    [theme.breakpoints.down("sm")]: {
      position: "relative",
    },
  },
  goBackButton: {
    marginLeft: theme.spacing(-1),
    textDecoration: "none",
  },
  headerContainer: {
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1, 0),
    },
  },
  heading: {
    "@media print": {
      display: "none",
    },
    "alignItems": "center",
    "display": "flex",
    "flexDirection": "row",
    [theme.breakpoints.down("sm")]: {
      alignItems: "flex-start",
      flexDirection: "column",
    },
  },
  headingActions: {
    "& > *:not(:first-child)": {
      marginLeft: theme.spacing(1),
    },
    [theme.breakpoints.down("sm")]: {
      flex: 1,
      margin: theme.spacing(2, 0, 0),
    },
    "flex": "0 0 auto",
    "marginLeft": "auto",
  },
  offset: theme.mixins.toolbar,
  subtitle: {
    fontWeight: 500,
  },
}));

const PageHeader = ({
  children,
  className = "",
  primaryActions = null,
  secondaryActions = null,
  secondaryClassName = "",
  title,
  documentTitle,
  titleSecondary = null,
  stickyHeader,
  homePage = null,
}) => {
  const classes = useStyles();
  const hasContent = Boolean(titleSecondary || secondaryActions || children);
  const { supplier } = useContext(AccountContext);

  useEffect(() => {
    if (process.env.NODE_ENV === "development" && !documentTitle && typeof title !== "string") {
      alert("Provided page `title` is not a string, please provide separate prop `docoumentTitle`");
    }

    document.title = `${supplier.name} | ${documentTitle ?? title} | Freshline`;
  }, [documentTitle, supplier.name, title]);

  return (
    <header className={clsx(classes.headerContainer, { [classes.fixedHeading]: stickyHeader })}>
      {stickyHeader && <div className={classes.offset} />}
      {homePage && (
        <Button
          className={classes.goBackButton}
          color="primary"
          component={RouterLink}
          startIcon={<KeyboardArrowLeftIcon />}
          to={homePage}
        >
          Return
        </Button>
      )}
      <div className={classes.heading}>
        {isValidElement(title) ? (
          title
        ) : (
          <Typography variant="h5">
            <strong>{title}</strong>
          </Typography>
        )}
        {primaryActions && <div className={clsx(classes.headingActions, className)}>{primaryActions}</div>}
      </div>
      {hasContent && (
        <div className={classes.content}>
          {Boolean(titleSecondary || secondaryActions) && (
            <div className={clsx(classes.heading, secondaryClassName)}>
              <Typography className={classes.subtitle} variant="h6">
                {titleSecondary}
              </Typography>
              {secondaryActions && <div className={classes.headingActions}>{secondaryActions}</div>}
            </div>
          )}
          <div>{children}</div>
        </div>
      )}
    </header>
  );
};

PageHeader.propTypes = {
  children: PropTypes.node,
  documentTitle: PropTypes.string,
  primaryActions: PropTypes.node,
  secondaryActions: PropTypes.node,
  secondaryClassName: PropTypes.string,
  stickyHeader: PropTypes.bool,
  title: PropTypes.node.isRequired,
  titleSecondary: PropTypes.node,
};

PageHeader.defaultProps = {
  children: null,
  documentTitle: null,
  primaryActions: null,
  secondaryActions: null,
  secondaryClassName: "",
  stickyHeader: false,
  titleSecondary: null,
};

export default PageHeader;
