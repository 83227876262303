/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Button, Card, makeStyles, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import clsx from "clsx";
import { forwardRef } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

const CARD_HEIGHT = 550;
const CARD_HEIGHT_MOBILE = 425;
const CARD_HEIGHT_SMALL = 325;
const CARD_HEIGHT_LARGE = 625;
const IMAGE_WIDTH = "40%";
const useStyles = makeStyles(theme => ({
  fullImageCard: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    height: CARD_HEIGHT_SMALL,
    justifyContent: "space-around",
    padding: theme.spacing(3, 2),
    position: "relative",
  },
  fullImageCardCarousel: {
    height: CARD_HEIGHT_LARGE,
    padding: theme.spacing(9, 10),
    [theme.breakpoints.down("md")]: {
      height: CARD_HEIGHT,
      padding: theme.spacing(9, 2),
    },
    [theme.breakpoints.only("xs")]: {
      height: CARD_HEIGHT_MOBILE,
    },
  },
  fullStyleText: {
    textAlign: "center",
    zIndex: 1,
  },
  header: {
    backgroundColor: "rgba(255, 255, 255, 0.5)",
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.grey[900],
    fontWeight: 600,
    margin: "auto",
    padding: theme.spacing(1, 3),
    width: "fit-content",
    [theme.breakpoints.only("xs")]: {
      fontSize: theme.typography.h4.fontSize,
    },
  },
  imageBackground: {
    height: "100%",
    left: 0,
    objectFit: "cover",
    position: "absolute",
    top: 0,
    width: "100%",
  },
  imageSide: {
    height: "100%",
    objectFit: "cover",
    width: "100%",
  },
  linkButton: {
    width: "fit-content",
    zIndex: 1,
  },
  sideContentContainer: {
    display: "flex",
    flexDirection: "column",
    height: CARD_HEIGHT_SMALL,
    justifyContent: "space-around",
    overflowY: "auto",
    padding: theme.spacing(2),
    width: `calc(100% - ${IMAGE_WIDTH})`,
    [theme.breakpoints.only("xs")]: {
      alignItems: "center",
      textAlign: "center",
      width: "100%",
    },
  },
  sideContentContainerCarousel: {
    height: CARD_HEIGHT,
    padding: theme.spacing(2, 4, 5, 4),
    [theme.breakpoints.only("xs")]: {
      height: CARD_HEIGHT_SMALL,
    },
  },
  sideImageContainer: {
    height: CARD_HEIGHT_SMALL,
    width: IMAGE_WIDTH,
    [theme.breakpoints.only("xs")]: {
      width: "100%",
    },
  },
  sideImageContainerCarousel: {
    height: CARD_HEIGHT,
    [theme.breakpoints.only("xs")]: {
      height: CARD_HEIGHT_SMALL,
    },
  },
  slideBackground: {
    backgroundColor: theme.palette.background.paper,
  },
  spacingContent: {
    justifyContent: "center",
  },
  stripCard: {
    border: "none",
    borderRadius: 0,
  },
  subheader: {
    backgroundColor: "rgba(255, 255, 255, 0.5)",
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.grey[900],
    margin: "auto",
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    padding: theme.spacing(0, 3),
    width: "fit-content",
    [theme.breakpoints.only("xs")]: {
      fontSize: theme.typography.h5.fontSize,
    },
  },
  thickWeight: { fontWeight: 600 },
  thinHeader: {
    "color": theme.palette.background.paper,
    "fontWeight": 700,
    "margin": "auto",
    "padding": theme.spacing(1),
    "text-shadow": `0px 0px 10px ${theme.palette.text.primary}`,
    "width": "fit-content",
    [theme.breakpoints.only("xs")]: {
      fontSize: theme.typography.h3.fontSize,
    },
  },
  thinImageCard: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    height: CARD_HEIGHT_SMALL,
    justifyContent: "space-around",
    padding: theme.spacing(3, 2),
    position: "relative",
  },
  thinImageCardCarousel: {
    height: CARD_HEIGHT_SMALL,
    padding: theme.spacing(9, 10),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(9, 2),
    },
  },
  thinSubheader: {
    "color": theme.palette.background.paper,
    "fontWeight": 500,
    "margin": "auto",
    "marginBottom": theme.spacing(2),
    "marginTop": theme.spacing(2),
    "padding": theme.spacing(0, 1),
    "text-shadow": `0px 0px 10px ${theme.palette.text.primary}`,
    "width": "fit-content",
    [theme.breakpoints.only("xs")]: {
      fontSize: theme.typography.h5.fontSize,
    },
  },
  titleLength: {
    "-webkit-box-orient": "vertical",
    "-webkit-line-clamp": 4,
    "display": "-webkit-box",
    "overflow": "hidden",
  },
}));

type CarouselSlideProps = {
  displayType: string;
  forCarousel?: boolean;
  slide?: any;
  disableFocus?: boolean;
};

export const CarouselSlide = forwardRef<HTMLDivElement, CarouselSlideProps>(
  ({ displayType, forCarousel = false, slide, disableFocus }, ref) => {
    const classes = useStyles();
    const theme = useTheme();
    const isFull = displayType === "full";
    const isThin = displayType === "thin";
    const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
    const hasSlide = Boolean(slide);
    const headerVariant = forCarousel ? "h2" : "h4";
    const descriptionVariant = forCarousel ? "h5" : "body2";
    const renderedHeader = hasSlide ? slide.header : "Header text";
    const renderedDescription = hasSlide ? slide.description : "Subheader text";
    let renderedButton;

    if (hasSlide) {
      if (slide.link?.text && slide.link?.url) {
        renderedButton = (
          <Button
            className={classes.linkButton}
            color="primary"
            href={slide.link.url}
            size={forCarousel ? "medium" : "small"}
            variant="contained"
            tabIndex={disableFocus ? -1 : null}
            aria-label={slide.link.text}
          >
            {slide.link.text}
          </Button>
        );
      }
    } else {
      renderedButton = (
        <Button className={classes.linkButton} color="primary" variant="contained" tabIndex={disableFocus ? -1 : null}>
          Button
        </Button>
      );
    }

    let renderedSlide;

    if (isFull || isThin) {
      renderedSlide = (
        <Box
          className={clsx({
            [classes.fullImageCard]: isFull,
            [classes.thinImageCard]: isThin,
            [classes.slideBackground]: !hasSlide || !forCarousel,
            [classes.spacingContent]: !renderedButton,
            [classes.fullImageCardCarousel]: isFull && forCarousel,
            [classes.thinImageCardCarousel]: isThin && forCarousel,
          })}
        >
          {hasSlide && (
            <LazyLoadImage
              alt="background"
              className={classes.imageBackground}
              onError={event => {
                const target = event.target as HTMLImageElement;

                if (target.src !== "/images/img_not_found.svg") {
                  // eslint-disable-next-line no-param-reassign
                  target.src = "/images/img_not_found.svg";
                }
              }}
              src={slide.imageObject?.image}
            />
          )}

          <Box className={classes.fullStyleText}>
            <Typography
              className={clsx(
                {
                  [classes.header]: isFull,
                  [classes.thinHeader]: isThin,
                },
                classes.titleLength,
              )}
              variant={headerVariant}
            >
              {renderedHeader}
            </Typography>
            <Typography
              className={clsx(
                {
                  [classes.subheader]: isFull,
                  [classes.thinSubheader]: isThin,
                },
                classes.titleLength,
              )}
              variant={descriptionVariant}
              component="p"
            >
              {renderedDescription}
            </Typography>
          </Box>

          {renderedButton}
        </Box>
      );
    } else {
      renderedSlide = (
        <Box display="flex" flexDirection={isMobile ? "column" : "row"}>
          <Box
            className={clsx(classes.sideImageContainer, {
              [classes.slideBackground]: !hasSlide,
              [classes.sideImageContainerCarousel]: forCarousel,
            })}
          >
            {hasSlide && (
              <LazyLoadImage
                alt="background"
                className={classes.imageSide}
                onError={event => {
                  const target = event.target as HTMLImageElement;

                  if (target.src !== "/images/img_not_found.svg") {
                    // eslint-disable-next-line no-param-reassign
                    target.src = "/images/img_not_found.svg";
                  }
                }}
                src={slide.imageObject?.image}
              />
            )}
          </Box>
          <Box
            className={clsx(classes.sideContentContainer, {
              [classes.slideBackground]: !forCarousel,
              [classes.spacingContent]: !renderedButton,
              [classes.sideContentContainerCarousel]: forCarousel,
            })}
          >
            <Box>
              <Typography
                gutterBottom
                variant={headerVariant}
                className={clsx(classes.thickWeight, classes.titleLength)}
              >
                {renderedHeader}
              </Typography>
              <Typography variant={descriptionVariant} className={classes.titleLength}>
                {renderedDescription}
              </Typography>
            </Box>

            {renderedButton}
          </Box>
        </Box>
      );
    }

    return (
      <Card ref={ref} className={clsx({ [classes.stripCard]: forCarousel })} variant="outlined">
        {renderedSlide}
      </Card>
    );
  },
);

CarouselSlide.displayName = "CarouselSlide";

export default CarouselSlide;
