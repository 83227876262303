import { Supplier } from "@arowana/graphql";
import PhoneNumber from "awesome-phonenumber";
import Dinero from "dinero.js";
import { createContext, ReactNode, useEffect, useMemo } from "react";

type SupplierPlus = Partial<Supplier> & {
  // local fields
  formattedSupportPhone?: string;
};

export const SupplierContext = createContext<SupplierPlus>({});

type SupplierProviderProps = {
  supplierData: Partial<Supplier>;
  children: ReactNode;
};

export const SupplierProvider = ({ supplierData, children }: SupplierProviderProps) => {
  // TODO: Temp solution, these should comeback from server anyways
  // trying to avoid backporting complicated fields on backend
  const formattedSupportPhone = useMemo(() => {
    if (!supplierData?.supportPhoneNumber) return "";

    const parsed = new PhoneNumber(supplierData.supportPhoneNumber, supplierData.country);

    return parsed.getNumber("international");
  }, [supplierData?.country, supplierData?.supportPhoneNumber]);

  const memoized = useMemo(
    () => ({
      ...supplierData,
      formattedSupportPhone,
    }),
    [supplierData, formattedSupportPhone],
  );

  useEffect(() => {
    if (!supplierData?.currency) return;

    switch (supplierData.currency) {
      case "cad":
        Dinero.globalLocale = "en-CA";
        break;

      case "usd":
        Dinero.globalLocale = "en-US";
        break;

      case "nzd":
        Dinero.globalLocale = "en-NZ";
        break;
    }
    Dinero.defaultCurrency = supplierData.currency;
  }, [supplierData?.currency]);

  return <SupplierContext.Provider value={memoized}>{children}</SupplierContext.Provider>;
};
