import { OnWheelBlur } from "@arowana/ui";
import {
  Box,
  Button,
  Checkbox,
  Grid,
  InputAdornment,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import PropTypes from "prop-types";
import { memo, useEffect, useMemo, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";

import amountUtility from "../../../utils/amountUtility";
import Featured from "./FormComponents/Featured";
import ProductUnit from "./FormComponents/ProductUnit";

const NUM_DUMMY_ROWS = 5;

const useStyles = makeStyles(theme => ({
  actionButtons: {
    marginTop: theme.spacing(2),
  },
}));

const DummyRow = () => (
  <TableRow>
    <TableCell>
      <Skeleton variant="text" />
    </TableCell>
    <TableCell>
      <Skeleton variant="rect" width={20} height={20} />
    </TableCell>
    <TableCell>
      <Skeleton variant="rect" width={20} height={20} />
    </TableCell>
    <TableCell>
      <Skeleton variant="rect" width={20} height={20} />
    </TableCell>
    <TableCell>
      <Skeleton variant="text" />
    </TableCell>
    <TableCell>
      <Skeleton variant="text" />
    </TableCell>
    <TableCell>
      <Skeleton variant="text" />
    </TableCell>
    <TableCell>
      <Skeleton variant="rect" width={20} height={20} />
    </TableCell>
    <TableCell>
      <Skeleton variant="text" />
    </TableCell>
  </TableRow>
);

const ProductRow = ({ clearErrors, field, index, register, control, errors }) => (
  <TableRow>
    <TableCell style={{ minWidth: 120 }}>
      <Typography variant="body2">{field.productName}</Typography>
      <Typography variant="caption">{field.variantName}</Typography>
    </TableCell>
    <TableCell>
      <Box display={field.isFirstOption ? "inherit" : "none"}>
        <Controller
          as={({ value, onChange }) => {
            return <Checkbox checked={value} color="primary" onChange={e => onChange(e.target.checked)} />;
          }}
          control={control}
          defaultValue={field.active}
          name={`products[${index}].active`}
        />
      </Box>
    </TableCell>
    <TableCell>
      <Box display={field.isFirstOption ? "inherit" : "none"}>
        <Controller
          as={<Featured />}
          control={control}
          defaultValue={field.featured}
          name={`products[${index}].featured`}
        />
      </Box>
    </TableCell>
    <TableCell>
      <Box display={field.isFirstOption ? "inherit" : "none"}>
        <Controller
          as={({ value, onChange }) => {
            return (
              <Checkbox
                checked={value}
                color="primary"
                onChange={e => {
                  clearErrors(`products[${index}].inventory`);
                  onChange(e.target.checked);
                }}
              />
            );
          }}
          control={control}
          defaultValue={field.trackInventory}
          name={`products[${index}].trackInventory`}
        />
      </Box>
    </TableCell>
    <TableCell style={{ minWidth: 120 }}>
      <TextField
        InputProps={{
          inputProps: {
            min: 0,
            step: 0.01,
          },
          onWheel: OnWheelBlur,
        }}
        defaultValue={field.inventory}
        error={Boolean(errors?.products?.[index]?.inventory)}
        fullWidth
        helperText={errors?.products?.[index]?.inventory?.message}
        inputRef={register}
        name={`products[${index}].inventory`}
        size="small"
        type="number"
        variant="outlined"
      />
    </TableCell>
    <TableCell style={{ minWidth: 140 }}>
      <TextField
        InputProps={{
          inputProps: {
            min: 0,
            step: 0.01,
          },
          onWheel: OnWheelBlur,
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
        }}
        defaultValue={field.price}
        error={Boolean(errors?.products?.[index]?.price)}
        fullWidth
        helperText={errors?.products?.[index]?.price?.message}
        inputRef={register({
          required: "*required",
        })}
        name={`products[${index}].price`}
        size="small"
        type="number"
        variant="outlined"
      />
    </TableCell>
    <TableCell style={{ minWidth: 140 }}>
      <TextField
        InputProps={{
          inputProps: {
            min: 0,
            step: 0.01,
          },
          onWheel: OnWheelBlur,
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
        }}
        defaultValue={field.unitCost}
        error={Boolean(errors?.products?.[index]?.unitCost)}
        fullWidth
        helperText={errors?.products?.[index]?.unitCost?.message}
        inputRef={register({
          min: {
            message: "must be at least 0",
            value: 0,
          },
        })}
        name={`products[${index}].unitCost`}
        size="small"
        type="number"
        variant="outlined"
      />
    </TableCell>
    <TableCell>
      <Controller
        as={({ value, onChange }) => {
          return <Checkbox checked={value} color="primary" onChange={e => onChange(e.target.checked)} />;
        }}
        control={control}
        defaultValue={field.outOfStock}
        name={`products[${index}].outOfStock`}
      />
    </TableCell>
    <TableCell style={{ minWidth: 140 }}>
      <TextField
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Controller
                as={<ProductUnit />}
                control={control}
                defaultValue={field.unit}
                name={`products[${index}].unit`}
                rules={{ required: "*required" }}
              />
            </InputAdornment>
          ),
          inputProps: {
            min: 0,
            step: 0.01,
          },
          onWheel: OnWheelBlur,
        }}
        defaultValue={field.caseSize}
        error={Boolean(errors?.products?.[index]?.caseSize)}
        fullWidth
        helperText={errors?.products?.[index]?.caseSize?.message}
        inputRef={register({
          required: "*required",
        })}
        name={`products[${index}].caseSize`}
        size="small"
        type="number"
        variant="outlined"
      />
    </TableCell>

    <Box display="none">
      <TextField defaultValue={field.productId} inputRef={register} name={`products[${index}].productId`} />
      <TextField defaultValue={field.variantId} inputRef={register} name={`products[${index}].variantId`} />
      <TextField defaultValue={field.variantName} inputRef={register} name={`products[${index}].variantName`} />
      <TextField defaultValue={field.sku} inputRef={register} name={`products[${index}].sku`} />
      <TextField defaultValue={field.shippingWeight} inputRef={register} name={`products[${index}].shippingWeight`} />
      <TextField
        defaultValue={field.shippingWeightUnit}
        inputRef={register}
        name={`products[${index}].shippingWeightUnit`}
      />
    </Box>
  </TableRow>
);

const MemorizedProductRow = memo(ProductRow);

const QuickEditForm = ({ products, onClose, quickUpdate }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const initialValues = useMemo(() => {
    const formValues = [];
    products.forEach(product => {
      product.variants.forEach(
        ({ caseSize, quantity, outOfStock, price, name, unit, cost, id, __typename, ...rest }, index) => {
          formValues.push({
            active: product.active,
            caseSize: caseSize,
            featured: Boolean(product.featured),
            inventory: quantity ?? "",
            isFirstOption: index === 0,
            outOfStock: Boolean(outOfStock),
            price: amountUtility.amountToDollarAndCents(price),
            productId: product.id,
            productName: product.name,
            trackInventory: product.trackInventory,
            unit,
            unitCost: Number.isNaN(parseFloat(cost)) ? "" : amountUtility.amountToDollarAndCents(cost),
            variantId: id,
            variantName: name,
            ...rest,
          });
        },
      );
    });

    return formValues;
  }, [products]);

  const { clearErrors, control, register, errors, handleSubmit, formState, setError } = useForm({
    defaultValues: {
      products: initialValues,
    },
  });
  const { fields } = useFieldArray({
    control,
    name: "products",
  });

  useEffect(() => {
    const timer = setTimeout(() => setLoading(false), 0);

    return () => clearTimeout(timer);
  });

  const onSubmit = data => {
    const invalidQuantityIndex = [];
    const reformatData = {};
    data.products.forEach(
      (
        {
          active,
          caseSize,
          featured,
          inventory,
          outOfStock,
          price,
          productId,
          trackInventory,
          unit,
          unitCost,
          variantId,
          variantName,
          shippingWeight,
          shippingWeightUnit,
          sku,
        },
        index,
      ) => {
        if (trackInventory && Number.isNaN(parseFloat(inventory))) {
          invalidQuantityIndex.push(index);
        }

        const variant = {
          caseSize: parseFloat(caseSize),
          cost: Number.isNaN(parseFloat(unitCost)) ? null : amountUtility.dollarsAndCentsToAmount(parseFloat(unitCost)),
          id: variantId,
          name: variantName,
          outOfStock,
          price: amountUtility.dollarsAndCentsToAmount(price),
          quantity: Number.isNaN(parseFloat(inventory)) ? null : parseFloat(inventory),
          shippingWeight: parseFloat(shippingWeight),
          shippingWeightUnit,
          sku,
          unit,
        };

        if (productId in reformatData) {
          reformatData[productId].variants.push(variant);
        } else {
          reformatData[productId] = {
            active,
            featured,
            trackInventory,
            variants: [variant],
          };
        }
      },
    );

    if (invalidQuantityIndex.length > 0) {
      invalidQuantityIndex.forEach(index =>
        setError(`products[${index}].inventory`, {
          message: "*required",
          type: "manual",
        }),
      );

      return;
    }

    const variables = {
      input: Object.keys(reformatData).map(productId => ({
        id: productId,
        ...reformatData[productId],
      })),
    };
    quickUpdate({ variables });
    onClose();
  };

  return (
    <>
      <TableContainer>
        <Table size="small" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Product</TableCell>
              <TableCell>Active</TableCell>
              <TableCell>Featured</TableCell>
              <TableCell>Track inventory</TableCell>
              <TableCell>Inventory</TableCell>
              <TableCell>Price</TableCell>
              <TableCell>Unit cost</TableCell>
              <TableCell>Out of stock</TableCell>
              <TableCell>Case size</TableCell>
            </TableRow>
          </TableHead>
          {loading ? (
            <TableBody>{new Array(NUM_DUMMY_ROWS).fill(<DummyRow />)}</TableBody>
          ) : (
            <TableBody>
              {fields.map((field, index) => (
                <MemorizedProductRow
                  clearErrors={clearErrors}
                  control={control}
                  errors={errors}
                  field={field}
                  index={index}
                  key={field.id}
                  register={register}
                />
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <Grid className={classes.actionButtons} container justifyContent="flex-end" spacing={1}>
        <Grid item md={3} sm={6} xs={12}>
          <Button color="primary" fullWidth onClick={onClose}>
            Cancel
          </Button>
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <Button
            color="primary"
            disabled={!formState?.isDirty}
            fullWidth
            onClick={handleSubmit(onSubmit)}
            variant="contained"
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

QuickEditForm.defaultProps = {
  products: [],
};

QuickEditForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  products: PropTypes.array,
  quickUpdate: PropTypes.func.isRequired,
};

export default QuickEditForm;
