import { ProductUnit } from "@arowana/graphql";
import { OnWheelBlur } from "@arowana/ui";
import { unitLabel } from "@arowana/util";
import {
  Box,
  Button,
  Checkbox,
  Collapse,
  FormControlLabel,
  IconButton,
  InputAdornment,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import { Add as AddIcon, DragIndicator } from "@material-ui/icons";
import clsx from "clsx";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Controller, useWatch } from "react-hook-form";

import DropdownSelect from "../../../../components/DropdownSelect";
import FormCard from "../../../../components/FormCard";
import SwitchToggle from "../../../account/components/SwitchToggle";
import { SHIPPING_WEIGHT_UNITS } from "./InventoryShipping";

const useStyles = makeStyles(theme => ({
  checkbox: {
    margin: 0,
  },
  colContent: {
    verticalAlign: "baseline",
  },
  colDualContent: {
    verticalAlign: "top",
  },
  colWidth: {
    minWidth: 200,
  },
  colWidthOutofStock: {
    minWidth: 120,
  },
  optionsTable: {
    margin: theme.spacing(2, 0),
  },
  orderCol: {
    "& > .handle": {
      opacity: 0,
      transition: theme.transitions.create("opacity"),
    },
    "& > .index": {
      left: 0,
      margin: "auto",
      opacity: 1,
      position: "absolute",
      right: 0,
      top: theme.spacing(3),
      transition: theme.transitions.create("opacity"),
    },
    "&.dragging > .handle": {
      opacity: 1,
    },
    "&.dragging > .index": {
      opacity: 0,
    },
    "&:hover > .handle": {
      opacity: 1,
    },
    "&:hover > .index": {
      opacity: 0,
    },
    "position": "relative",
  },
}));

const getItemStyle = (isDragging, draggableStyle) => ({
  ...draggableStyle,
  display: isDragging ? "table" : "table-row",
  position: isDragging ? "fixed" : "inherit",
  userSelect: "none",
});

const ProductRow = ({
  itemKey,
  includeId,
  canDelete,
  control,
  errors,
  field,
  index,
  register,
  remove,
  unitOptions,
}) => {
  const classes = useStyles();

  const unit = useWatch({
    control,
    defaultValue: "",
    name: `variants[${index}].unit`,
  }) as ProductUnit;

  return (
    <Draggable draggableId={itemKey} index={index}>
      {(providedFromItem, snapshot) => (
        <TableRow
          hover
          ref={providedFromItem.innerRef}
          {...providedFromItem.draggableProps}
          style={getItemStyle(snapshot.isDragging, providedFromItem.draggableProps.style)}
        >
          <TableCell className={clsx(classes.orderCol, { dragging: snapshot.isDragging })} align="center">
            <div className="index">{index + 1}.</div>
            <IconButton
              className="handle"
              aria-label="drag"
              color="primary"
              title="drag"
              size="small"
              {...providedFromItem.dragHandleProps}
            >
              <DragIndicator />
            </IconButton>
          </TableCell>
          <TableCell className={classes.colContent}>
            {includeId && (
              // Must include this to avoid changing variant ID when updating product
              <input defaultValue={field.id} hidden type="text" name={`variants[${index}].id`} ref={register()} />
            )}
            <TextField
              defaultValue={field.name}
              error={Boolean(errors?.variants?.[index]?.name)}
              fullWidth
              helperText={errors?.variants?.[index]?.name?.message}
              inputRef={register({
                required: "*required",
              })}
              name={`variants[${index}].name`}
              size="small"
              margin="dense"
              variant="outlined"
            />
          </TableCell>
          <TableCell className={classes.colDualContent}>
            <Box display="flex">
              <TextField
                defaultValue={field.caseSize}
                error={Boolean(errors?.variants?.[index]?.caseSize)}
                fullWidth
                helperText={errors?.variants?.[index]?.caseSize?.message}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Controller
                        as={<DropdownSelect />}
                        control={control}
                        defaultValue={field.unit}
                        variant="standard"
                        error={Boolean(errors?.variants?.[index]?.unit)}
                        fullWidth
                        helperText={errors?.variants?.[index]?.unit?.message}
                        name={`variants[${index}].unit`}
                        options={unitOptions}
                        rules={{ required: "*required" }}
                      />
                    </InputAdornment>
                  ),
                  inputProps: {
                    min: 0.01,
                    step: 0.01,
                  },
                  onWheel: OnWheelBlur,
                  style: {
                    padding: 0,
                  },
                }}
                inputRef={register({
                  min: {
                    message: "invalid value",
                    value: 0.01,
                  },
                  required: "*required",
                })}
                name={`variants[${index}].caseSize`}
                size="small"
                margin="dense"
                type="number"
                variant="outlined"
              />
            </Box>
          </TableCell>
          <TableCell className={classes.colContent}>
            <TextField
              defaultValue={field.price}
              error={Boolean(errors?.variants?.[index]?.price)}
              fullWidth
              helperText={errors?.variants?.[index]?.price?.message}
              InputProps={{
                endAdornment: unit && (
                  <InputAdornment position="end">
                    <Typography variant="caption" color="textSecondary">
                      per {unitLabel(unit)}
                    </Typography>
                  </InputAdornment>
                ),
                inputProps: {
                  min: 0,
                  step: 0.01,
                },
                onWheel: OnWheelBlur,
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
              inputRef={register({
                min: {
                  message: "invalid value",
                  value: 0,
                },
                required: "*required",
              })}
              name={`variants[${index}].price`}
              size="small"
              margin="dense"
              type="number"
              variant="outlined"
            />
          </TableCell>
          <TableCell className={classes.colContent}>
            <TextField
              defaultValue={field.cost}
              error={Boolean(errors?.variants?.[index]?.cost)}
              fullWidth
              helperText={errors?.variants?.[index]?.cost?.message}
              InputProps={{
                endAdornment: unit && (
                  <InputAdornment position="end">
                    <Typography variant="caption" color="textSecondary">
                      per {unitLabel(unit)}
                    </Typography>
                  </InputAdornment>
                ),
                inputProps: {
                  min: 0,
                  step: 0.01,
                },
                onWheel: OnWheelBlur,
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
              inputRef={register({
                min: {
                  message: "invalid value",
                  value: 0,
                },
              })}
              name={`variants[${index}].cost`}
              size="small"
              margin="dense"
              type="number"
              variant="outlined"
            />
          </TableCell>
          <TableCell align="center">
            <Controller
              as={<SwitchToggle />}
              className={classes.checkbox}
              component={<Checkbox color="primary" tabIndex={-1} />}
              control={control}
              defaultValue={field.outOfStock}
              name={`variants[${index}].outOfStock`}
            />
          </TableCell>
          <TableCell className={classes.colContent}>
            <TextField
              defaultValue={field.sku}
              error={Boolean(errors?.variants?.[index]?.sku)}
              fullWidth
              helperText={errors?.variants?.[index]?.sku?.message}
              name={`variants[${index}].sku`}
              size="small"
              margin="dense"
              inputRef={register()}
              variant="outlined"
            />
          </TableCell>
          <TableCell className={classes.colContent}>
            <TextField
              defaultValue={field.quantity}
              error={Boolean(errors?.variants?.[index]?.quantity)}
              fullWidth
              helperText={errors?.variants?.[index]?.quantity?.message}
              InputProps={{
                endAdornment: unit && (
                  <InputAdornment position="end">
                    <Typography variant="caption" color="textSecondary">
                      {unitLabel(unit)}
                    </Typography>
                  </InputAdornment>
                ),
                inputProps: {
                  min: 0,
                  step: 0.01,
                },
                onWheel: OnWheelBlur,
              }}
              inputRef={register({
                min: {
                  message: "invalid value",
                  value: 0,
                },
              })}
              name={`variants[${index}].quantity`}
              size="small"
              margin="dense"
              type="number"
              variant="outlined"
            />
          </TableCell>
          <TableCell className={classes.colDualContent}>
            <Box display="flex">
              <TextField
                defaultValue={field.shippingWeight}
                error={Boolean(errors?.variants?.[index]?.shippingWeight)}
                fullWidth
                helperText={errors?.variants?.[index]?.shippingWeight?.message}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Controller
                        as={<DropdownSelect />}
                        control={control}
                        defaultValue={field.shippingWeightUnit}
                        error={Boolean(errors?.variants?.[index]?.shippingWeightUnit)}
                        variant="standard"
                        fullWidth
                        helperText={errors?.variants?.[index]?.shippingWeightUnit?.message}
                        name={`variants[${index}].shippingWeightUnit`}
                        options={SHIPPING_WEIGHT_UNITS}
                        rules={{ required: "*required" }}
                      />
                    </InputAdornment>
                  ),
                  inputProps: {
                    min: 0,
                    step: 0.1,
                  },
                  onWheel: OnWheelBlur,
                  style: {
                    padding: 0,
                  },
                }}
                inputRef={register({
                  required: "*required",
                })}
                name={`variants[${index}].shippingWeight`}
                size="small"
                margin="dense"
                type="number"
                variant="outlined"
              />
            </Box>
          </TableCell>
          {canDelete && (
            <TableCell>
              <Button color="secondary" onClick={() => remove(index)}>
                Delete
              </Button>
            </TableCell>
          )}
        </TableRow>
      )}
    </Draggable>
  );
};

const Options = ({
  control,
  errors,
  fields,
  onAddOption,
  onRemoveOptions,
  register,
  remove,
  swap,
  showOptionsTable,
  unitOptions,
  includeId,
}) => {
  const classes = useStyles();
  const canDelete = fields.length > 1;
  const handleCheck = async e => {
    if (e.target.checked) {
      onAddOption();
    } else {
      onRemoveOptions();
    }
  };

  const onDragEnd = ({ source, destination }) => swap(source.index, destination.index);

  return (
    <FormCard title="Variants">
      <FormControlLabel
        control={<Checkbox checked={showOptionsTable} onChange={handleCheck} color="primary" />}
        label="This product has multiple variants, like sizes or flavors"
      />

      <Typography color="textSecondary" component="p" variant="caption">
        You must provide a separate name, price, case size, and unit for each item.
      </Typography>

      <Collapse in={showOptionsTable} unmountOnExit>
        <TableContainer className={classes.optionsTable}>
          <Table stickyHeader size="small">
            <TableHead>
              <TableRow>
                <TableCell>No.</TableCell>
                <TableCell className={classes.colWidth}>
                  Title <span aria-hidden="true">*</span>
                </TableCell>
                <TableCell className={classes.colWidth}>
                  Case size <span aria-hidden="true">*</span>
                </TableCell>
                <TableCell className={classes.colWidth}>
                  Price <span aria-hidden="true">*</span>
                </TableCell>
                <TableCell className={classes.colWidth}>Unit cost</TableCell>
                <TableCell className={classes.colWidthOutofStock}>
                  Out of stock <span aria-hidden="true">*</span>
                </TableCell>
                <TableCell className={classes.colWidth}>SKU</TableCell>
                <TableCell className={classes.colWidth}>Inventory</TableCell>
                <TableCell className={classes.colWidth}>
                  Shipping weight <span aria-hidden="true">*</span>
                </TableCell>
                {canDelete && <TableCell></TableCell>}
              </TableRow>
            </TableHead>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="options-table">
                {({ droppableProps, innerRef, placeholder }) => (
                  <TableBody {...droppableProps} ref={innerRef}>
                    {fields.map((field, index) => (
                      <ProductRow
                        key={field.key}
                        includeId={includeId}
                        canDelete={canDelete}
                        control={control}
                        errors={errors}
                        field={field}
                        index={index}
                        itemKey={field.key}
                        register={register}
                        remove={remove}
                        unitOptions={unitOptions}
                      />
                    ))}
                    {placeholder}
                  </TableBody>
                )}
              </Droppable>
            </DragDropContext>
          </Table>
        </TableContainer>
        <Button color="primary" startIcon={<AddIcon />} onClick={onAddOption}>
          Add Another Option
        </Button>
      </Collapse>
    </FormCard>
  );
};

export default Options;
