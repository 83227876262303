import { Button, Grid, makeStyles, Typography } from "@material-ui/core";
import React, { useContext } from "react";

import { SupplierContext } from "../context";
import Link from "../Links/Link";

const useStyles = makeStyles(theme => ({
  spacing: { marginTop: theme.spacing(2) },
}));

type GeneralSidebarProps = {
  showFaqLink?: boolean;
  faqLink?: string;
};

export const GeneralSidebar = ({ showFaqLink = true, faqLink = "/faq" }: GeneralSidebarProps) => {
  const classes = useStyles();
  const { supportEmail, supportPhoneNumber } = useContext(SupplierContext);

  return (
    <>
      <Typography variant="h6">We&apos;re here to help!</Typography>

      <Typography className={classes.spacing} variant="body2">
        Shopping with us is easy - all of your personal info, order modification, and order history needs can be managed
        online.
      </Typography>

      <Grid className={classes.spacing} container justifyContent="space-between" spacing={1}>
        {showFaqLink && (
          <Grid item sm={supportEmail ? 6 : 12} xs={12}>
            <Link href={faqLink} passHref underline="none">
              <Button fullWidth variant="outlined">
                FAQs
              </Button>
            </Link>
          </Grid>
        )}
        {supportEmail && (
          <Grid item sm={6} xs={12}>
            <Button fullWidth href={`mailto:${supportEmail}`} variant="outlined">
              Email
            </Button>
          </Grid>
        )}
      </Grid>

      {supportPhoneNumber && (
        <Typography className={classes.spacing} variant="body2">
          Looking for more support? <Link href={`tel:${supportPhoneNumber}`}>Give us a call!</Link>
        </Typography>
      )}
    </>
  );
};

export default GeneralSidebar;
