import { gql, useMutation, useQuery } from "@apollo/client";
import { Supplier } from "@arowana/graphql";
import { DATALAYER } from "@arowana/util";
import { Box, Button, Grid, makeStyles, TextField, Typography } from "@material-ui/core";
import isValidHttpUrl from "apps/supplier/src/utils/isValidHttpUrl";
import EmailValidator from "email-validator";
import { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";

import { notificationVar } from "../../../cache/notificationPolicy";
import FormCard from "../../../components/FormCard";
import Loader from "../../../components/Loader";
import PageHeader from "../../../components/PageHeader";
import RichTextFormComponent from "../../../components/RichTextFormComponent";
import Routes from "../../../Constants/Routes";
import supplierUpdateSettings from "../../../queries/supplierUpdateSettings";
import { FlagsmithContext } from "../../context/FlagsmithContext";
import LogoUpload from "../components/LogoUpload";

const GENERAL_SETTINGS = gql`
  query GeneralSettings {
    currentSupplier {
      id
      name
      facebookLink
      identifier
      instagramLink
      supportEmail
      supportPhoneNumber
      timezone
      twitterLink
      uiSettings {
        description
        logoUrl
      }
      urlName
      youtubeLink
    }
  }
`;

const useStyles = makeStyles(theme => ({
  spacing: {
    marginBottom: theme.spacing(3),
  },
}));

const validateLink = value => (value ? isValidHttpUrl(value) || "*Invalid link" : null);

const General = () => {
  const classes = useStyles();
  const [isLoading, setLoading] = useState(true);
  const flagsmith = useContext(FlagsmithContext);
  const hasRetail = flagsmith.hasFeature("b2c");
  const { register, handleSubmit, control, errors, formState, reset } = useForm({
    defaultValues: {
      description: "",
      facebookLink: "",
      instagramLink: "",
      logo: {
        file: null,
        image: undefined,
      },
      name: "",
      supportEmail: "",
      supportPhoneNumber: "",
      timeZone: "N/A",
      twitterLink: "",
      url: "N/A",
      youtubeLink: "",
    },
  });

  const { data: response, loading: isFetching } = useQuery(GENERAL_SETTINGS, {
    context: { source: DATALAYER },
  });
  const supplier = response?.currentSupplier as Supplier;

  const [updateSupplier, { loading: isSaving }] = useMutation(supplierUpdateSettings, {
    onCompleted: () => {
      notificationVar({
        message: "Account updated!",
        severity: "success",
      });
    },
    onError: () => {},
  });

  useEffect(() => {
    if (!isFetching && supplier) {
      reset({
        description: supplier?.uiSettings?.description ?? "",
        facebookLink: supplier?.facebookLink ?? "",
        instagramLink: supplier?.instagramLink ?? "",
        logo: {
          file: null,
          image: supplier?.uiSettings?.logoUrl,
        },
        name: supplier?.name ?? "",
        supportEmail: supplier?.supportEmail ?? "",
        supportPhoneNumber: supplier?.supportPhoneNumber ?? "",
        timeZone: supplier?.timezone ?? "N/A",
        twitterLink: supplier?.twitterLink ?? "",
        url: supplier?.urlName ?? `https://${supplier?.identifier}.freshlinemarket.com`,
        youtubeLink: supplier?.youtubeLink ?? "",
      });

      // manually control loading after reset is done
      // or user will see blank forms flashing
      setLoading(false);
    }
  }, [isFetching, reset, supplier]);

  const handleDiscardClick = () => reset();

  const onSubmit = formData => {
    const variables = {
      input: {
        facebookLink: formData.facebookLink,
        instagramLink: formData.instagramLink,
        name: formData.name,
        supportEmail: formData.supportEmail,
        supportPhoneNumber: formData.supportPhoneNumber,
        twitterLink: formData.twitterLink,
        uiSettings: {
          description: formData.description,
        },
        youtubeLink: formData.youtubeLink,
      },
      logo: formData.logo.file,
      supplierId: supplier?.id,
    };
    updateSupplier({ variables });
  };

  return (
    <>
      {(isLoading || isSaving) && <Loader />}

      <PageHeader
        primaryActions={
          <>
            <Button color="primary" disabled={!formState?.isDirty} onClick={handleDiscardClick} variant="outlined">
              Discard
            </Button>
            <Button color="primary" disabled={!formState?.isDirty} onClick={handleSubmit(onSubmit)} variant="contained">
              Save
            </Button>
          </>
        }
        stickyHeader
        title="General"
      />

      <FormCard>
        <Box mb={3}>
          <Typography gutterBottom variant="subtitle1">
            Company logo
          </Typography>
          <Controller
            as={<LogoUpload />}
            control={control}
            helperText={errors?.logo?.message}
            name="logo"
            rules={{ validate: value => Boolean(value.image || value.file) || "*required" }}
          />
          <Typography color="textSecondary" variant="caption">
            This will appear to customers via your store, emails, and marketing materials.
          </Typography>
        </Box>

        <Grid className={classes.spacing} container item justifyContent="space-between" spacing={2}>
          <Grid item xs={12}>
            <Typography gutterBottom variant="subtitle1">
              Company Name
            </Typography>
            <TextField fullWidth inputRef={register} name="name" variant="outlined" />
          </Grid>
        </Grid>

        <Box mb={3}>
          <Typography gutterBottom variant="subtitle1">
            Company description
          </Typography>
          <RichTextFormComponent
            control={control}
            helperText={errors?.description?.message}
            name="description"
            required
          />
          <Box>
            <Typography color="textSecondary" variant="caption">
              This description will appear with your company name on search engines. Add a detailed, relevant
              description for better SEO.
            </Typography>
          </Box>
        </Box>

        <Grid className={classes.spacing} container item justifyContent="space-between" spacing={2}>
          <Grid item md={6} xs={12}>
            <Typography gutterBottom variant="subtitle1">
              Website URL
            </Typography>
            <TextField disabled fullWidth inputRef={register} name="url" variant="outlined" />
            <Box>
              <Typography color="textSecondary" variant="caption">
                Set up a custom website URL by emailing support@freshline.io.
              </Typography>
            </Box>
          </Grid>
          <Grid item md={6} xs={12}>
            <Typography gutterBottom variant="subtitle1">
              Time zone
            </Typography>
            <TextField disabled fullWidth inputRef={register} name="timeZone" variant="outlined" />
          </Grid>
        </Grid>

        <Button component="a" href={Routes.RESET_PASSWORD} variant="contained">
          Reset Password
        </Button>
      </FormCard>

      {hasRetail && (
        <FormCard title="Social information">
          <Grid container item justifyContent="space-between" spacing={2}>
            <Grid item md={6} xs={12}>
              <Typography gutterBottom variant="subtitle1">
                Facebook link
              </Typography>
              <TextField
                error={Boolean(errors.facebookLink)}
                fullWidth
                helperText={errors.facebookLink?.message}
                inputRef={register({ validate: validateLink })}
                name="facebookLink"
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography gutterBottom variant="subtitle1">
                Instagram link
              </Typography>
              <TextField
                error={Boolean(errors.instagramLink)}
                fullWidth
                helperText={errors.instagramLink?.message}
                inputRef={register({ validate: validateLink })}
                name="instagramLink"
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography gutterBottom variant="subtitle1">
                Twitter link
              </Typography>
              <TextField
                error={Boolean(errors.twitterLink)}
                fullWidth
                helperText={errors.twitterLink?.message}
                inputRef={register({ validate: validateLink })}
                name="twitterLink"
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography gutterBottom variant="subtitle1">
                YouTube link
              </Typography>
              <TextField
                error={Boolean(errors.youtubeLink)}
                fullWidth
                helperText={errors.youtubeLink?.message}
                inputRef={register({ validate: validateLink })}
                name="youtubeLink"
                variant="outlined"
              />
            </Grid>
          </Grid>
          <Box mt={2}>
            <Typography color="textSecondary" variant="caption">
              Add social links to display social icons across your site. Copy and paste a URL for a profile page into
              each field. For example https://instagram.com/yourhandle.
            </Typography>
          </Box>
        </FormCard>
      )}

      <FormCard title="Contact information">
        <Grid container item justifyContent="space-between" spacing={2}>
          <Grid item md={6} xs={12}>
            <Typography gutterBottom variant="subtitle1">
              Support email
            </Typography>
            <TextField
              error={Boolean(errors?.supportEmail)}
              fullWidth
              helperText={errors?.supportEmail?.message}
              inputRef={register({
                validate: value => !value || EmailValidator.validate(value) || "*invalid email address",
              })}
              name="supportEmail"
              type="email"
              variant="outlined"
            />
            <Typography color="textSecondary" variant="caption">
              This will be displayed to customers on your shop.
            </Typography>
          </Grid>
          <Grid item md={6} xs={12}>
            <Typography gutterBottom variant="subtitle1">
              Support phone
            </Typography>
            <TextField
              type="tel"
              fullWidth
              inputRef={register}
              name="supportPhoneNumber"
              error={Boolean(errors?.supportPhoneNumber)}
              helperText={errors?.supportPhoneNumber?.message}
              variant="outlined"
            />
          </Grid>
        </Grid>
      </FormCard>
    </>
  );
};

export default General;
