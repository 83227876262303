import { Fade, IconButton, TextField } from "@material-ui/core";
import { Close, Search } from "@material-ui/icons";
import { useEffect, useState } from "react";
import { useDebounce } from "use-debounce";

interface PromotionSearchProps {
  onUpdateQuery: (prop: { query?: string }) => void;
  placeholder?: string;
  query?: string;
}

const PromotionsSearch = ({ onUpdateQuery, placeholder = "Search discount codes", query }: PromotionSearchProps) => {
  const [searchInput, setSearchInput] = useState(query);
  const [debouncedInputValue] = useDebounce(searchInput, 300);
  useEffect(() => onUpdateQuery({ query: debouncedInputValue }), [debouncedInputValue]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => setSearchInput(event.target.value);

  const handleClear = () => {
    setSearchInput("");
    onUpdateQuery({ query: undefined });
  };

  return (
    <TextField
      InputProps={{
        endAdornment: (
          <Fade in={searchInput.length > 0} unmountOnExit>
            <IconButton aria-label="search" id="search-submit" onClick={handleClear} size="small">
              <Close />
            </IconButton>
          </Fade>
        ),
        startAdornment: <Search />,
      }}
      fullWidth
      label="Search"
      onChange={handleChange}
      placeholder={placeholder}
      value={searchInput}
      variant="outlined"
    />
  );
};

export default PromotionsSearch;
