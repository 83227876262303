import { OnWheelBlur } from "@arowana/ui";
import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  Link,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import clsx from "clsx";
import React, { useEffect, useState } from "react";

import { notificationVar } from "../../../../cache/notificationPolicy";

const useStyles = makeStyles(() =>
  createStyles({
    hideDiscount: { display: "none" },
    removeButton: {
      justifySelf: "flex-start",
      marginRight: "auto",
    },
  }),
);

const DiscountItem = ({
  currencyFormatter,
  index,
  onChange: setDiscount,
  onRemove,
  open: initiallyOpen = false,
  isNew,
  value: discount,
  order,
  maxDiscount,
}) => {
  const classes = useStyles();

  const originalAmount = Math.abs(discount.amount / 100).toFixed(2);
  const displayMaxDiscount = ((maxDiscount - discount.amount) / 100).toFixed(2);
  const [amount, setAmount] = useState(originalAmount);
  const [description, setDescription] = useState<string | undefined>(discount.description);
  const [open, setOpen] = useState(initiallyOpen);
  const theme = useTheme();
  const isXS = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    setAmount(originalAmount);
    setDescription(discount.description);
  }, [discount, originalAmount]);

  const handleAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAmount(event.target.value);
  };
  const handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDescription(event.target.value);
  };

  const handleOpen = () => setOpen(true);
  const handleCancel = () => {
    if (isNew) {
      onRemove();
    }
    setOpen(false);
  };
  const handleDone = () => {
    if (parseFloat(amount) > parseFloat(displayMaxDiscount)) {
      notificationVar({
        message: `Discount must not exceed $${displayMaxDiscount}!`,
        severity: "error",
      });
    } else {
      setOpen(false);
      setDiscount({
        _remove: false,
        amount: Math.abs(Math.round(parseFloat(amount) * 100)) * -1,
        description,
        id: discount.id,
      });
    }
  };
  const handleRemove = () => {
    if (!discount.id) {
      onRemove();
    } else {
      setDiscount({
        _remove: true,
        amount: 0,
        description,
        id: discount.id,
      });
    }

    setOpen(false);
  };

  return (
    <div className={clsx({ [classes.hideDiscount]: discount._remove })}>
      <ListItem dense disableGutters>
        <ListItemText>
          <Typography component="span" variant="body2">
            Discount
          </Typography>
          {discount.description && (
            <Typography color="textSecondary" component="span" variant="body2">
              {" "}
              ({discount.description})
            </Typography>
          )}{" "}
          {order.isEditable && (
            <Link component="button" onClick={handleOpen} variant="body2">
              Edit
            </Link>
          )}
        </ListItemText>
        <ListItemSecondaryAction>
          <Typography color="secondary" component="span" variant="body2">
            {currencyFormatter.format(parseFloat(originalAmount) * -1)}
          </Typography>
        </ListItemSecondaryAction>
      </ListItem>
      <Dialog
        open={open}
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            setOpen(false);
          }
        }}
        aria-labelledby={`form-dialog-title-${index}`}
        fullScreen={isXS}
      >
        <DialogTitle id={`form-dialog-title-${index}`}>Edit discount</DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                InputProps={{
                  onWheel: OnWheelBlur,
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
                inputProps={{ max: displayMaxDiscount, min: 0 }}
                autoFocus
                label="Amount"
                fullWidth
                size="small"
                type="number"
                variant="outlined"
                value={amount}
                required
                onChange={handleAmountChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Description"
                fullWidth
                size="small"
                multiline={true}
                type="text"
                variant="outlined"
                value={description}
                onChange={handleDescriptionChange}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {!isNew && (
            <Button
              className={classes.removeButton}
              onClick={handleRemove}
              color="secondary"
              variant="outlined"
              tabIndex="-1"
            >
              Remove
            </Button>
          )}
          <Button onClick={handleCancel} color="primary" variant="outlined" tabIndex="0">
            Cancel
          </Button>
          <Button onClick={handleDone} color="primary" variant="contained" tabIndex="0">
            Done
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DiscountItem;
