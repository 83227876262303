import { gql } from "@apollo/client";

const ProductCreateMany = gql`
  mutation ProductCreateMany($input: [ProductCreateManyInput!]!) {
    productCreateMany(input: $input) {
      id
    }
  }
`;

export default ProductCreateMany;
