import { PageInfo } from "@arowana/graphql";
import { useState } from "react";

// Use this in conjunction with paginated fields

// and material-ui data grid
export function usePagination(rowPerPage) {
  // via API
  const [pageInfo, setPageInfo] = useState<Partial<PageInfo>>({});

  // Internal pagination state
  const [afterCursor, setAfterCursor] = useState<string | undefined>();
  const [page, setPage] = useState(0);
  const [cursorLookup, setCursorLookup] = useState<Record<string, string>>({});

  const resetPagination = () => {
    setPage(0);
    setCursorLookup({});
    setAfterCursor(undefined);
    setPageInfo({});
  };

  const onPageChange = params => {
    if (params.page > page) {
      setCursorLookup(exisitng => ({ ...exisitng, [params.page]: pageInfo?.endCursor }));
      setAfterCursor(pageInfo?.endCursor);
    } else {
      setAfterCursor(cursorLookup[params.page]);
    }

    setPage(params.page);
  };

  const pagination = {
    after: afterCursor,
    first: rowPerPage,
  };

  return {
    onPageChange,
    page,
    pageInfo,
    pagination,
    resetPagination,
    setPageInfo,
  };
}

export default usePagination;
