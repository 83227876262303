import { Box, Button, FormHelperText, makeStyles } from "@material-ui/core";
import PublishIcon from "@material-ui/icons/Publish";
import PropTypes from "prop-types";
import React, { useRef } from "react";

const useStyles = makeStyles(theme => ({
  addLogoButton: {
    margin: "auto",
    textDecoration: "none",
    width: "fit-content",
  },
  errorContainer: {
    border: `1px dashed ${theme.palette.error.main}`,
  },
  logo: {
    display: "block",
    marginBottom: theme.spacing(2),
    maxHeight: 100,
    maxWidth: 200,
    objectFit: "cover",
  },
  logoButtonLabel: {
    display: "block",
  },
  logoContent: props => ({
    border: `1px dashed ${props.error ? theme.palette.error.main : theme.palette.grey[400]}`,
    borderRadius: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    height: `calc(100% - ${theme.spacing(6.9)}px )`,
    justifyContent: "center",
    marginBottom: theme.spacing(0.5),
    marginTop: theme.spacing(1),
    padding: theme.spacing(2),
  }),
  replaceLogoButton: {
    width: "fit-content",
  },
}));
const LogoUpload = React.forwardRef(({ value, onChange, helperText }, ref) => {
  const { image: imgUrl } = value;
  const classes = useStyles({ error: Boolean(helperText) });
  const fileInput = useRef(null);
  const handleImageButtonClicked = () => {
    fileInput.current.click();
  };
  const handleImageSelection = event => {
    const chosenFile = event?.target?.files?.[0];

    if (chosenFile) {
      onChange({
        file: chosenFile,
        image: URL.createObjectURL(chosenFile),
      });
    }
  };

  return (
    <>
      <Box className={classes.logoContent}>
        {imgUrl && (
          <img
            alt="chosen to upload"
            className={classes.logo}
            onError={event => {
              if (event.target.src !== "/images/img_not_found.svg") {
                // eslint-disable-next-line no-param-reassign
                event.target.src = "/images/img_not_found.svg";
              }
            }}
            src={imgUrl}
            title="image chosen"
          />
        )}

        {imgUrl ? (
          <Button
            className={classes.replaceLogoButton}
            color="primary"
            onClick={handleImageButtonClicked}
            variant="outlined"
          >
            Replace
          </Button>
        ) : (
          <Button
            classes={{
              label: classes.logoButtonLabel,
              root: classes.addLogoButton,
            }}
            color="primary"
            onClick={handleImageButtonClicked}
          >
            <PublishIcon />
            <br />
            Add
          </Button>
        )}
        <input accept="image/*" hidden onChange={handleImageSelection} ref={fileInput} type="file" />
      </Box>

      <FormHelperText error>{helperText}</FormHelperText>
    </>
  );
});

LogoUpload.defaultProps = {
  helperText: undefined,
  onChange: undefined,
  value: {
    file: undefined,
    image: undefined,
  },
};

LogoUpload.propTypes = {
  helperText: PropTypes.node,
  onChange: PropTypes.func,
  value: PropTypes.shape({
    file: PropTypes.object,

    image: PropTypes.string,
  }),
};

export default LogoUpload;
